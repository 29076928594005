/* eslint-disable */
import {
    Grid, TextField, Autocomplete, Input, InputAdornment
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {LoadingButton} from "@mui/lab";
import {getAccountsDataByType, setAccountSelectorData} from "../../utils/utilities";
import AccountSelector from "../../components/Common/AccountSelector";
import {DatePicker} from "antd";
import {useTheme} from "@mui/material/styles";


const rewardsType = {
    "AVAILABLE": ["ALL", "SAVED"],
    "JOINED": ["ALL", "ONGOING", "YET_TO_START", "SHUTDOWN"],
    "RESULTS": ["ALL"],
}


export default function GetAllRewards() {
    const localUser = window.localStorage.getItem('userEmailId');
    const [userData, setUserData] = useState([]);
    const [rewardData, setRewardData] = useState([]);
    const [selectedType, setSelectedType] = useState("");
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [selectedSubType, setSelectedSubType] = useState("");
    const [otp, setOTP] = useState("");
    const [selectedAccountsData, setSelectedAccountsData] = useState([]);
    const [flag, setFlag] = useState(1);
    const {RangePicker} = DatePicker;
    const theme = useTheme();
    const isLight = theme.palette.mode === 'light';


    const handleSelectedAccount = async (event, value) => {
        setRewardData([]);
        if (value[0]) {
            setFlag(0);
        } else {
            setFlag(1);
        }

        var tempIsAllAcFlipkart = true
        for (const selectedAc of value) {
            if (selectedAc.AcType && (selectedAc.AcType !== "flipkart")) {
                tempIsAllAcFlipkart = false
            }
            if (selectedAc.value.length < 9 && (selectedAc.value !== "flipkart")) {
                tempIsAllAcFlipkart = false
            }
        }
        const response = await setAccountSelectorData(value, userData);
        setSelectedAccountsData(response[1]);
    };
    const getdate = (value, dateString) => {
        // console.log(value);

        const [startDateString, endDateString] = dateString;

        // console.log(startDateString, endDateString)
        const [day1, month1, year1] = startDateString.split("/");
        const [day2, month2, year2] = endDateString.split("/");
        if (day1 && month1 && year1 && day2 && month2 && year2) {
            const formattedStartDate = `20${year1}-${month1}-${day1}`;
            const formattedEndDate = `20${year2}-${month2}-${day2}`;
            setFromDate(formattedStartDate);
            setToDate(formattedEndDate);
            // console.log(formattedStartDate, formattedEndDate);
        } else {
            setFromDate(null);
            setToDate(null);
        }
    }

    // get account selector options by type and account data
    useEffect(() => {
        getAccountsDataByType('flipkart', false, false).then((filteredData) => {
            setUserData(filteredData);
        });
    }, []);


    return (
        <>
            <Grid container direction="row" spacing={5}
                  sx={{paddingBottom: '15px', mt: 2, justifyContent: 'start', maxWidth: "550px"}}>
                <Grid item xs={12} sm={12} md={12}>
                    <AccountSelector allData={userData} onChangeHandler={handleSelectedAccount} autoWidth/>
                </Grid>

                <Grid item xs={3} sm={3} md={3}>
                    <strong>Select date:</strong>
                </Grid>
                <Grid item xs={9} sm={9} md={9}>
                    <RangePicker
                        style={{width: "100%"}}
                        width={"100%"}
                        fullWidth
                        popperPlacement="bottom"
                        format={'DD/MM/YY'}
                        onChange={(value, event) => getdate(value, event)}
                        className={'createDateRangePicker'}
                        pupClassName={'createDateRangePicker'}
                        {...(!isLight && {
                            className: 'date-picker-dark',
                        })}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>

                    <Autocomplete
                        id="rewardsType"
                        onChange={(event, value) => {
                            setSelectedType(value);
                        }}
                        value={selectedType}
                        options={Object.keys(rewardsType)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Select Reward Type*"
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <Autocomplete
                        id="rewardsSubType"
                        onChange={(event, value) => {
                            setSelectedSubType(value)
                        }}
                        value={selectedSubType}
                        options={rewardsType[selectedType] || []}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Select Reward Sub Type*"
                            />
                        )}
                    />
                </Grid>



                <Grid item xs={12} sm={12} md={12}>
                    <Grid container justifyContent={'end'}>
                        <LoadingButton variant={'contained'} sx={{mt: 1}} onClick={() => {
                        }}
                                       id="flipkartGetAllRewards"
                                       disabled={!selectedSubType || !selectedType}
                        >
                            Get all rewards
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Grid>
            <Input id="rewardsType" value={selectedType} type="hidden"/>
            <Input id="rewardsSubType" value={selectedSubType} type="hidden"/>
            <Input id="selectedAccountsData" value={JSON.stringify(selectedAccountsData)} type="hidden"/>
            <Input id="selectedFromDate" value={fromDate} type="hidden"/>
            <Input id="selectedToDate" value={toDate} type="hidden"/>

        </>
    );
}
