import { Autocomplete, Box, TextField, Grid, ImageList, ImageListItem, ImageListItemBar } from '@mui/material';
import { Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { CreatePostAPICall, MyProduct } from '../../../utils/apis';
import useAuth from '../../../hooks/useAuth';




const MasterSkuAutocomplete = ({
  TpMailId = '',
  SupplierId = '',
  AcType = '',
  index = 0,
  masterSkuName = '',
  masterSkuData = [],
  onSetMasterSku = () => { },
}) => {
  const { user } = useAuth();
  const localUser = window.localStorage.getItem('userEmailId');
  const { enqueueSnackbar } = useSnackbar();
  const [skuOptions, setSkuOptions] = useState([]);
  const [skuSearchValue, setSkuSearchValue] = useState('');
  const [linkedSku,setLinkedSku]=useState([]);

  // in autocomplete select data and link sku api call
  const onLinkSku = async (value) => {
    const body = {
      username: user?.email ? user.email : localUser,
      tpEmailId: TpMailId,
      subAccountId: SupplierId,
      acType: AcType,
      skuName: value.skuName,
      imageLink: value.imageLink,
      masterSku: masterSkuName,
      actionType: 'linkSku',
      subPanel: 'masterSku',
    };

    const response = await CreatePostAPICall(enqueueSnackbar, MyProduct, body);
    if (response) {
      enqueueSnackbar(response.data.body);
      setSkuSearchValue('');
      setSkuOptions([]);
      const tempMasterSkuData = [...masterSkuData];
      const masterSkuIndex = tempMasterSkuData.findIndex((sku) => sku.sort_key === masterSkuName);

      if (masterSkuIndex > -1) {
        tempMasterSkuData[masterSkuIndex].linked_skus += 1;
        // console.log(masterSkuData,response.body);
        // console.log(1);
        console.log(tempMasterSkuData);
        // onSetMasterSku(tempMasterSkuData);
        setLinkedSku([...linkedSku,{imageLink:body.imageLink,skuName:body.skuName,sort_key:AcType.concat("#", TpMailId, "#", SupplierId) }])
      }
    }
  };

  // in autocomplete search sku name and api call get sku name data
  const onGetSkuData = async (skuName) => {
    if (skuName.length > 0) {
      setSkuSearchValue(skuName);

      const body = {
        username: user?.email ? user.email : localUser,
        tpEmailId: TpMailId,
        subAccountId: SupplierId,
        acType: AcType,
        sku: skuName,
        actionType: 'getHint',
        subPanel: 'masterSku',
      };

      const response = await CreatePostAPICall(enqueueSnackbar, MyProduct, body);

      if (response.data?.body?.length > 0) {
        setSkuOptions(response.data?.body);
      }
    }
  };

  // filter linked skus
  const AvailableLinkedSkus = () => {
    let linkedSkuls;
    if (masterSkuData[0]) {
      if (masterSkuData[0].allLinkedSkus) {
        linkedSkuls =
          masterSkuData[0].allLinkedSkus.filter((data) => {

            if (data.sort_key.includes(AcType.concat("#", TpMailId, "#", SupplierId))) {
              return true;
            }
            return false;
          });
          linkedSkuls.forEach(element => {
            if (!linkedSku.includes(element)) {
              linkedSku.push(element);
            }
          });
          // console.log(linkedSku);
      }
    }
    // console.log(linkedSkuls);
    return (linkedSku.length > 0) ?
      <ImageList sx={{ height: 250 }} gap={8} cols={3} >
        {
          linkedSku.map((value) => {
            if (value.sort_key.includes(AcType.concat("#", TpMailId, "#", SupplierId))) {
              return <ImageListItem>
                {value.imageLink ?
                  <img
                    src={`${value.imageLink}?w=100&fit=crop `}
                    alt={value.skuName}
                    style={{ height: 100, width: 100 }}
                    loading="lazy" /> : null}
                <ImageListItemBar
                  subtitle={value.skuName}
                  style={{ width: 100 }}
                  position="below" />
              </ImageListItem>

            }
            return null
          })
        }
      </ImageList> : null
  }


  return (
    <>
      <AvailableLinkedSkus />
      <Autocomplete
        key={index}
        onChange={(event, skuName) => {
          if (skuName) {
            onLinkSku(skuName);
          }
        }}
        onInputChange={(event, skuName, reason) => {
          if (reason === 'reset') {
            setSkuSearchValue('');
            setSkuOptions([]);
            return;
          }
          if (event) {
            onGetSkuData(skuName);
          }
        }}
        getOptionLabel={(sku) => sku.skuName || skuSearchValue}
        disablePortal
        id={`master-sku-select`}
        // defaultValue={["linkedSkul"]}
        // ChipComponent={["linkedSkul"]}
        options={skuOptions}
        sx={{ width: 350 }}
        value={skuSearchValue}
        renderInput={(params) => {
          return <TextField {...params} label="Select SKU" />
        }}
        renderOption={(props, option, { selected }) => {
          return (
            <li {...props}>
              <Box display={'flex'} flexDirection={'row'}>
                {option?.imageLink && (
                  <img src={option.imageLink} alt="" width={70} height={70} style={{ borderRadius: 10 }} />
                )}

                <Box display={'flex'} ml={3} flexDirection={'column'} style={{ alignSelf: 'center' }}>
                  <Typography color={'text.primary'} style={{ alignSelf: 'center' }}>
                    {option.skuName}
                  </Typography>
                </Box>
              </Box>
            </li>
          );

        }}
        clearOnBlur
      />
    </>
  );
};

export default MasterSkuAutocomplete;
