/* eslint-disable */
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CardActionArea, Grid, Paper, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import Divider from '@mui/material/Divider';
import { useState } from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { StyledTableCell } from '../../../utils/utilities';
import Iconify from '../../../components/Iconify';
import OrderReportDetails from './OrderReportDetails';

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

export default function MonthlyReportCard(props) {
    const [isMonthWiseCardExpanded, setIsMonthWiseCardExpanded] = useState(false);
    const [officeExpenseToBeEnteredByUsers, setOfficeExpenseToBeEnteredByUsers] = useState([]);

    const monthCards = {
        meesho: {
            RevenueSummary: {
                TotalRevenue: 0,
                TotalInvestment: 0,
                TotalProfitAndLoss: 0,
                TotalAds: 0,
                ProfitAndLossWithAds: 0,
            },
            OrderSummary: {
                TotalSentOrders: 0,
                TotalReturnOrders: 0,
                TotalCancelOrders: 0,
                TotalNormalOrders: 0,
                TotalPaidOrders: 0,
                TotalOutstandingOrders: 0,
                TotalNotListedOrders: 0,
            },
            ReturnSummary: {
                TotalCustomerReturn: 0,
                TotalCourierReturn: 0,
                TotalCancel: 0,
                TotalReceivedOrders: 0,
                TotalNotReceivedOrders: 0,
                TotalReturnAndLoss: 0,
                TotalNotReceivedLoss: 0,
            },
        },
    };

    const platformNames = [];

    const platform = {};

    // Rev Summary
    let TotalRevenue = 0;
    let TotalInvestment = 0;
    let TotalProfitAndLoss = 0;
    let TotalAds = 0;
    let ProfitAndLossWithAds = 0;

    // OrderSummary
    let TotalSentOrders = 0;
    let TotalReturnOrders = 0;
    let TotalCancelOrders = 0;
    let TotalNormalOrders = 0;
    let TotalPaidOrders = 0;
    let TotalOutstandingOrders = 0;
    let TotalNotListedOrders = 0;

    //   Return summary
    let TotalCustomerReturn = 0;
    let TotalCourierReturn = 0;
    let TotalCancel = 0;
    let TotalReceivedOrders = 0;
    let TotalClaimOrders = 0;
    let TotalNotReceivedOrders = 0;
    let TotalReturnAndLoss = 0;
    let TotalNotReceivedLoss = 0;
    const calculatePlatformTotals = (data, platform) => {
        let totalAccounts = 0;
        let totalOrder = 0;
        let totalRTO = 0;
        let totalRTN = 0;
        let totalCNL = 0;
        let totalNOR = 0;
        let totalAds = 0;
        let totalInvestment = 0;
        let totalProfitAndLoss = 0;

        data.forEach((x) => {
            // console.log('x :', x);
            if (x.acType === platform) {
                totalAccounts += 1;

                // Check if the necessary properties exist before accessing them
                if (x.totalSent && x.totalSent.total && x.totalSent.total.odr !== undefined) {
                    totalOrder += x.totalSent.total.odr;
                }

                if (x.noOfReturns && x.noOfReturns.rto && x.noOfReturns.rto.odr !== undefined) {
                    totalRTO += x.noOfReturns.rto.odr;
                }

                if (x.noOfReturns && x.noOfReturns.rtn && x.noOfReturns.rtn.odr !== undefined) {
                    totalRTN += x.noOfReturns.rtn.odr;
                }

                if (x.noOfReturns && x.noOfReturns.cnl && x.noOfReturns.cnl.odr !== undefined) {
                    totalCNL += x.noOfReturns.cnl.odr;
                }

                if (x.noOfReturns && x.noOfReturns.nor && x.noOfReturns.nor.odr !== undefined) {
                    totalNOR += x.noOfReturns.nor.odr;
                }

                if (x.netTotal) {
                    totalInvestment += x.netTotal.investment;
                    totalProfitAndLoss += x.netTotal.profitLoss;
                    totalAds += x.netTotal.totalAds
                }


            }
        });

        return {
            TotalAccounts: totalAccounts,
            TotalOrder: totalOrder,
            TotalRTO: totalRTO,
            TotalRTN: totalRTN,
            TotalCNL: totalCNL,
            TotalNOR: totalNOR,
            TotalAds: totalAds,
            TotalInvestment: totalInvestment,
            TotalProfitAndLoss: totalProfitAndLoss,
        };
    };
    props.data.forEach((x) => {
        if (!x.netTotal) return;

        if (!platformNames[x.acType]) {
            platformNames.push(x.acType);
            platform[x.acType] = {
                platform: x.acType,
                TotalAccounts: 0,
                TotalOrder: 0,
                TotalRTO: 0,
                TotalRTN: 0,
                TotalCNL: 0,
                TotalNOR: 0,
                TotalInvestment: 0,
                TotalProfitAndLoss: 0,
            };
        }
        // Revenue Summary
        TotalRevenue += x.netTotal.listedPayment;
        TotalInvestment +=
            parseFloat(x.payment?.paid?.investment?.toFixed(2)) +
            parseFloat(x.payment?.outstanding?.investment?.toFixed(2)) +
            parseFloat(x.payment?.notListed?.investment?.toFixed(2)) +
            parseFloat(x.payment?.claim?.total?.investment?.toFixed(2)) +
            parseFloat(x.totalReturn?.wrongReceived?.total?.investment?.toFixed(2)) +
            x.totalReturn?.notReceived?.total.investment +
            x.totalReturn?.received?.total.investment -
            x.totalReturn?.received?.total.investment;
        // TotalProfitAndLoss = TotalRevenue - TotalInvestment;
        TotalProfitAndLoss += x.netTotal.profitLoss
        TotalAds += x.netTotal.totalAds;
        ProfitAndLossWithAds = TotalProfitAndLoss + TotalAds;

        // Order Summary
        TotalSentOrders += x.totalSent.total.odr;
        TotalReturnOrders += x.noOfReturns.rtn.odr + x.noOfReturns.rto.odr;
        TotalCancelOrders += x.noOfReturns.cnl.odr;
        TotalNormalOrders += x.noOfReturns.nor.odr;
        TotalPaidOrders += x.payment.paid.odr;
        TotalOutstandingOrders += x.payment.outstanding.odr;
        TotalNotListedOrders += x.payment.notListed.odr;
        // Return summary
        TotalCustomerReturn += x.noOfReturns.rtn.odr;
        TotalCourierReturn += x.noOfReturns.rto.odr;
        TotalCancel += x.noOfReturns.cnl.odr;
        TotalReceivedOrders += x.noOfReturns.total.recOdr;
        TotalClaimOrders += x.payment.claim.total.odr;
        TotalNotReceivedOrders += x.totalReturn.notReceived.total.odr;
        TotalReturnAndLoss +=
            x.payment.claim.rto.profitLoss +
            x.payment.claim.rtn.profitLoss +
            x.payment.claim.cnl.profitLoss +
            x.payment.claim.nor.profitLoss +
            x.totalReturn.wrongReceived.rto.profitLoss +
            x.totalReturn.wrongReceived.rtn.profitLoss +
            x.totalReturn.wrongReceived.cnl.profitLoss +
            x.totalReturn.wrongReceived.nor.profitLoss +
            x.totalReturn.received.rto.profitLoss +
            x.totalReturn.received.rtn.profitLoss +
            x.totalReturn.received.cnl.profitLoss +
            x.totalReturn.received.nor.profitLoss;
        TotalNotReceivedLoss +=
            x.totalReturn.notReceived.rtn.profitLoss +
            x.totalReturn.notReceived.rto.profitLoss +
            x.totalReturn.notReceived.cnl.profitLoss;

        platformNames.forEach((platformName) => {
            platform[platformName] = calculatePlatformTotals(props.data, platformName);
        });
    });
    monthCards.meesho.RevenueSummary.TotalRevenue = TotalRevenue.toFixed(2);
    monthCards.meesho.RevenueSummary.TotalInvestment = TotalInvestment.toFixed(2);
    monthCards.meesho.RevenueSummary.TotalProfitAndLoss = TotalProfitAndLoss.toFixed(2);
    monthCards.meesho.RevenueSummary.TotalAds = TotalAds.toFixed(2);
    monthCards.meesho.RevenueSummary.ProfitAndLossWithAds = ProfitAndLossWithAds.toFixed(2);
    // Order Summary
    monthCards.meesho.OrderSummary.TotalSentOrders = TotalSentOrders;
    monthCards.meesho.OrderSummary.TotalReturnOrders = TotalReturnOrders;
    monthCards.meesho.OrderSummary.TotalCancelOrders = TotalCancelOrders;
    monthCards.meesho.OrderSummary.TotalNormalOrders = TotalNormalOrders;
    monthCards.meesho.OrderSummary.TotalPaidOrders = TotalPaidOrders;
    monthCards.meesho.OrderSummary.TotalOutstandingOrders = TotalOutstandingOrders;
    monthCards.meesho.OrderSummary.TotalNotListedOrders = TotalNotListedOrders;
    // Return summary
    monthCards.meesho.ReturnSummary.TotalCustomerReturn = TotalCustomerReturn;
    monthCards.meesho.ReturnSummary.TotalCourierReturn = TotalCourierReturn;
    monthCards.meesho.ReturnSummary.TotalCancel = TotalCancel;
    monthCards.meesho.ReturnSummary.TotalReceivedOrders = TotalReceivedOrders;
    monthCards.meesho.ReturnSummary.TotalClaimOrders = TotalClaimOrders;
    monthCards.meesho.ReturnSummary.TotalNotReceivedOrders = TotalNotReceivedOrders;
    monthCards.meesho.ReturnSummary.TotalReturnAndLoss = TotalReturnAndLoss;
    monthCards.meesho.ReturnSummary.TotalNotReceivedLoss = TotalNotReceivedLoss;

    // State for real time calculation of Expense
    const [netProfitAndLoss, setNetProfitAndLoss] = useState(monthCards.meesho.RevenueSummary.ProfitAndLossWithAds);
    const handleExpandClick = () => {
        setIsMonthWiseCardExpanded(!isMonthWiseCardExpanded);
    };
    const oldNpl = monthCards.meesho.RevenueSummary.ProfitAndLossWithAds;
    // Handle Expenses
    const handleExpense = (event) => {
        // use to set user entered Expanses
        if (event.target.value === '') {
            setOfficeExpenseToBeEnteredByUsers('');
            setNetProfitAndLoss(oldNpl);
        } else {
            setOfficeExpenseToBeEnteredByUsers(event.target.value);
            // calculate net profit / Loss and set using setNetProfitAndLoss state
            setNetProfitAndLoss((oldNpl - event.target.value).toFixed(2));
        }
    };

    // For inner table theme
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    return (
        <Card sx={{ mb: 5 }}>
            <CardActionArea>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div" style={{ width: '100%' }}>
                        <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                            Month Report {props.data[0].reRange}
                            <IconButton
                                color="primary"
                                aria-label="Refresh Reports"
                                component="label"
                                onClick={() => {
                                    props.onRefreshMethod(props.data[0].reRange);
                                }}
                                sx={{ marginTop: '-8px' }}
                            >
                                <Iconify icon={'material-symbols:refresh'} width={25} height={25} />
                            </IconButton>
                        </div>
                    </Typography>
                    <Divider orientation="horizontal" flexItem />
                    <br />
                    <Grid
                        container
                        spacing={{ xs: 3, md: 5 }}
                        columns={{ sm: 4, md: 12 }}
                        style={{ justifyContent: 'center', wordSpacing: '5px' }}
                    >
                        <Grid item xs={4} sm={4} md={4}>
                            <Typography gutterBottom variant="subtitle2">
                                Revenue Summary
                            </Typography>
                            <div
                                style={{
                                    backgroundColor: '#beebc942',
                                    borderRadius: '16px',
                                    padding: '10px',
                                    display: 'flex',
                                    justifyContent: 'space-around',
                                }}
                            >
                                <Typography gutterBottom variant="subtitle2" component="div">
                                    <>
                                        <div>Total Revenue :-</div>
                                        <div style={{ margin: '0px 0px 7px 0px' }}>Total Net Investment :-</div>
                                        <Divider sx={{ borderStyle: 'dashed', borderBottomWidth: 2 }} />
                                        <div>Gross Profit/Loss :-</div>
                                        <br />
                                        <div>Total Ads Cost :-</div>
                                        <div style={{ padding: '10px 0px 10px 0px' }}>Total Office Expense :-</div>
                                        <Divider sx={{ borderStyle: 'dashed', borderBottomWidth: 2 }} />
                                        <div>Net Profit/Loss :-</div>
                                    </>
                                </Typography>
                                <Typography gutterBottom variant="subtitle2" component="div" style={{ textAlign: 'end' }}>
                                    <div>{monthCards.meesho.RevenueSummary.TotalRevenue} ₹</div>
                                    <div style={{ margin: '0px 0px 7px 0px' }}>{monthCards.meesho.RevenueSummary.TotalInvestment} ₹</div>
                                    <Divider sx={{ borderStyle: 'dashed', borderBottomWidth: 2 }} />
                                    <div>{monthCards.meesho.RevenueSummary.TotalProfitAndLoss} ₹</div>
                                    <br />
                                    <div>{monthCards.meesho.RevenueSummary.TotalAds} ₹</div>
                                    <div style={{ padding: '10px 0px 10px 0px' }}>
                                        <TextField
                                            id="standard-number"
                                            type="number"
                                            InputProps={{
                                                endAdornment: <InputAdornment position="start">₹</InputAdornment>,
                                            }}
                                            value={officeExpenseToBeEnteredByUsers}
                                            onChange={handleExpense}
                                            variant="standard"
                                            style={{ marginTop: '0px', width: '92px', height: '10px', padding: '0px' }}
                                            focused
                                        />
                                    </div>
                                    <span>{netProfitAndLoss} </span>₹
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4}>
                            <Typography gutterBottom variant="subtitle2">
                                Order Summary
                            </Typography>
                            <div
                                style={{
                                    backgroundColor: '#a66a6a36',
                                    borderRadius: '16px',
                                    padding: '10px',
                                    display: 'flex',
                                    justifyContent: 'space-around',
                                }}
                            >
                                <Typography gutterBottom variant="subtitle2" component="div">
                                    <Typography style={{ margin: '5px 0px 5px 0px' }} variant="subtitle1">
                                        By Count
                                    </Typography>
                                    <Divider sx={{ borderStyle: 'dashed', borderBottomWidth: 2 }} />
                                    <div>Total Sent :-</div>
                                    <div>Total Return Orders:-</div>
                                    <div>Total Cancel :-</div>
                                    <div>Total Normal :-</div>
                                    <Typography style={{ margin: '5px 0px 5px 0px' }} variant="subtitle1">
                                        By Payment
                                    </Typography>
                                    <Divider sx={{ borderStyle: 'dashed', borderBottomWidth: 2 }} />
                                    <div>Total Paid :-</div>
                                    <div>Total Outstanding :-</div>
                                    <div>Total Not-listed :-</div>
                                </Typography>
                                <Typography gutterBottom variant="subtitle2" component="div" style={{ textAlign: 'end' }}>
                                    <br />
                                    <div style={{ margin: '15px 0px 0px 0px' }}>{monthCards.meesho.OrderSummary.TotalSentOrders}</div>
                                    <div>{monthCards.meesho.OrderSummary.TotalReturnOrders}</div>
                                    <div>{monthCards.meesho.OrderSummary.TotalCancelOrders}</div>
                                    <div>{monthCards.meesho.OrderSummary.TotalNormalOrders}</div>
                                    <br />
                                    <div style={{ margin: '13px 0px 0px 0px' }}>{monthCards.meesho.OrderSummary.TotalPaidOrders}</div>
                                    <div>{monthCards.meesho.OrderSummary.TotalOutstandingOrders}</div>
                                    <div>{monthCards.meesho.OrderSummary.TotalNotListedOrders}</div>
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4}>
                            <Typography gutterBottom variant="subtitle2">
                                Return Summary
                            </Typography>
                            <div
                                style={{
                                    backgroundColor: '#98a5df30',
                                    borderRadius: '16px',
                                    padding: '10px',
                                    display: 'flex',
                                    justifyContent: 'space-around',
                                }}
                            >
                                <Typography gutterBottom variant="subtitle2" component="div">
                                    <Typography style={{ margin: '5px 0px 5px 0px' }} variant="subtitle1">
                                        Total Return
                                    </Typography>
                                    <Divider sx={{ borderStyle: 'dashed', borderBottomWidth: 2 }} />
                                    <>
                                        <div>Total Customer Return :-</div>
                                        <div>Total RTO :-</div>
                                        <div>Total Cancel :-</div>
                                        <Divider
                                            sx={{ borderStyle: 'dashed', borderBottomWidth: 2 }}
                                            style={{ margin: '5px 0px 5px 0px' }}
                                        />
                                        <div>Total Received :-</div>
                                        <div>Total Claim Received Orders:-</div>
                                        <div>Total Not Received :-</div>
                                        <Divider
                                            sx={{ borderStyle: 'dashed', borderBottomWidth: 2 }}
                                            style={{ margin: '5px 0px 5px 0px' }}
                                        />
                                        <div>Total Return Loss :-</div>
                                        <div>Total Not Received Loss :-</div>
                                    </>
                                </Typography>
                                <Typography gutterBottom variant="subtitle2" component="div">
                                    <>
                                        <div style={{ margin: '37px 0px 0px 0px' }}>
                                            {monthCards.meesho.ReturnSummary.TotalCustomerReturn}
                                        </div>
                                        <div>{monthCards.meesho.ReturnSummary.TotalCourierReturn}</div>
                                        <div>{monthCards.meesho.ReturnSummary.TotalCancel}</div>
                                        <Divider
                                            sx={{ borderStyle: 'dashed', borderBottomWidth: 2 }}
                                            style={{ margin: '5px 0px 5px 0px' }}
                                        />
                                        <div>{monthCards.meesho.ReturnSummary.TotalReceivedOrders}</div>
                                        <div>{monthCards.meesho.ReturnSummary.TotalClaimOrders}</div>
                                        <div>{monthCards.meesho.ReturnSummary.TotalNotReceivedOrders}</div>
                                        <Divider
                                            sx={{ borderStyle: 'dashed', borderBottomWidth: 2 }}
                                            style={{ margin: '5px 0px 5px 0px' }}
                                        />
                                        <div>{monthCards.meesho.ReturnSummary.TotalReturnAndLoss.toFixed(2)} ₹</div>
                                        <div>{monthCards.meesho.ReturnSummary.TotalNotReceivedLoss.toFixed(2)} ₹</div>
                                    </>
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                </CardContent>
            </CardActionArea>
            <CardActions disableSpacing>
                <ExpandMore
                    expand={isMonthWiseCardExpanded}
                    onClick={handleExpandClick}
                    aria-expanded={isMonthWiseCardExpanded}
                    aria-label="More Details"
                >
                    <ExpandMoreIcon />
                </ExpandMore>
            </CardActions>
            <Collapse in={isMonthWiseCardExpanded} timeout="auto" unmountOnExit>
                <CardContent>
                    <Typography gutterBottom variant="subtitle2">
                        Platform Wise
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell rowSpan={2} align="center">
                                        Platform
                                    </StyledTableCell>
                                    <StyledTableCell rowSpan={2} align="center">
                                        Total Accounts
                                    </StyledTableCell>
                                    <StyledTableCell rowSpan={2} align="center">
                                        Total Orders
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={4} align="center">
                                        Total Returns
                                    </StyledTableCell>
                                    <StyledTableCell rowSpan={2} align="center">
                                        Total ADS
                                    </StyledTableCell>
                                    <StyledTableCell rowSpan={2} align="center">
                                        Total Investment
                                    </StyledTableCell>
                                    <StyledTableCell rowSpan={2} align="center">
                                        Total Profit
                                    </StyledTableCell>
                                </TableRow>
                                <TableRow>
                                    <StyledTableCell align="center">RTO</StyledTableCell>
                                    <StyledTableCell align="center">RTN</StyledTableCell>
                                    <StyledTableCell align="center">Cancel</StyledTableCell>
                                    <StyledTableCell align="center">Normal</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Object.keys(platform).map((platformName) => (
                                    platform[platformName].TotalAccounts !== 0 && (
                                        <StyledTableRow key={platformName} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <StyledTableCell align="center">{platformName}</StyledTableCell>
                                            <StyledTableCell align="center">{platform[platformName].TotalAccounts}</StyledTableCell>
                                            <StyledTableCell align="center">{platform[platformName].TotalOrder}</StyledTableCell>
                                            <StyledTableCell align="center">{platform[platformName].TotalRTO}</StyledTableCell>
                                            <StyledTableCell align="center">{platform[platformName].TotalRTN}</StyledTableCell>
                                            <StyledTableCell align="center">{platform[platformName].TotalCNL}</StyledTableCell>
                                            <StyledTableCell align="center">{platform[platformName].TotalNOR}</StyledTableCell>
                                            <StyledTableCell align="center">
                                                {platform[platformName].TotalAds.toFixed(2)} ₹
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {platform[platformName].TotalInvestment.toFixed(2)} ₹
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {platform[platformName].TotalProfitAndLoss.toFixed(2)} ₹
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    )
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <br />
                    <Typography gutterBottom variant="subtitle2">
                        All Order Reports
                    </Typography>
                    <OrderReportDetails key={props?.data[0]?.reRange?.toString()} data={props.data} />
                </CardContent>
            </Collapse>
        </Card>
    );
}