/* eslint-disable */
import {styled} from '@mui/material/styles';
import {isArray} from 'lodash';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import moment from 'moment';
import DialogTitle from '@mui/material/DialogTitle';
import * as AWS from 'aws-sdk';
import {isObject} from 'util';
import React from 'react';
import Switch from '@mui/material/Switch';
import Iconify from '../components/Iconify';
import {IDENTITY_POOL_ID, PAYMENT_UPLOAD_BT_ACCOUNT, REGION} from './Constants';
import * as xlsx from 'xlsx';
import { CreatePostAPICall, MyLinkedAccount } from './apis';

const credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: IDENTITY_POOL_ID,
});

export default function getS3ImageURL(bucketName, key) {
    return `https://${bucketName}.s3.ap-south-1.amazonaws.com/${key}`
}

AWS.config.update({region: REGION, credentials});

const s3 = new AWS.S3({region: REGION, credentials});

export const isDesktopEnvironment = process.env.REACT_APP_ENV.includes('DESKTOP');

export const isSuperAdminEnvironment = process.env.REACT_APP_ENV.includes('SUPER_ADMIN');

export const isSuperAdminDesktopEnvironment = process.env.REACT_APP_ENV.includes('SUPER_ADMIN_DESKTOP');

export const isAdmin = process.env.REACT_APP_ENV.includes('ADMIN');

export const isSuperEnvironment = process.env.REACT_APP_ENV.includes('SUPER');

// Constant value section
export const CancelChequeBucketName = 'canceled-cheque-data-buiss';

// To Find tpEmailId, subAccountId, cName, identifier, acType , sellerId
export const getUserAccountDetails = async (data = []) => {
    const userAccountData = {
        tpEmailids: [],
        cName: [],
        identifiers: [],
        supplierIds: [],
        sellerIds: [],
        acTypes: [],
        isMaskingEnabled: [],
    };
    if (data.length > 0) {

        data.forEach((item) => {
            userAccountData.tpEmailids.push(item.Email);
            userAccountData.cName.push(item.cName ?? "NA");
            userAccountData.identifiers.push(item.identifier);
            userAccountData.supplierIds.push(item.value);
            userAccountData.sellerIds.push(item.SellerId);
            userAccountData.acTypes.push(item.AcType);
            userAccountData.isMaskingEnabled.push(item.isMaskingEnabled ?? false);
        });
    } else {
        const accountData = await getAccountsData();
        // console.log(accountData)
        if (accountData && accountData.length !== 0) {
            accountData.forEach((item) => {
                userAccountData.tpEmailids.push(item.Account.Email);
                userAccountData.cName.push(item.Account.Name ?? "NA");
                userAccountData.identifiers.push(item.identifier);
                userAccountData.supplierIds.push(item.Account.SupplierId);
                userAccountData.sellerIds.push(item.SellerId);
                userAccountData.acTypes.push(item.AcType);
                userAccountData.isMaskingEnabled.push(item.isMaskingEnabled);
            });
        }
    }
    return userAccountData;
};


const discount = 0

const discountByOrder ={
    // "10000":5,
    // "15000":5,
    // "20000":10,
    // "50000":15,
    // "100000":18,
    // "200000": 20
}

const getDiscountByOrder =(orders,Price,PriceWithGST)=> {
    const dis = (discountByOrder[`${orders}`] || discount)
    if (dis>0){
        return {
            OfferOnDiscountPrice: dis,
            finalDiscountedPrice: (Price-((Price*dis)/100)),
            finalGST:(PriceWithGST-((PriceWithGST*dis)/100))
        }
    }else {
        return {}
    }

}

export const GetPaymentOptions = [
    {
        Id: 1,
        Price: 2250,
        DiscountedPrice: 2250,
        Orders: 1500,
        PerOrderCost: 1.5,
        PriceWithGST: 2655,
        InWords: 'Indian Rupee Two Thousand Six Hundred Fifty Five Only',
        IsChecked: true,
        ...getDiscountByOrder(1500,2250,2655)
    },
    {
        Id: 2,
        Price: 7500,
        DiscountedPrice: 6250,
        Orders: 5000,
        PerOrderCost: 1.25,
        PriceWithGST: 7375,
        InWords: 'Indian Rupee Seven Thousand Three Hundred Seventy Five Only',
        IsChecked: false,
        ...getDiscountByOrder(5000,6250,7375)
    },
    {
        Id: 3,
        Price: 12500,
        DiscountedPrice: 11000,
        Orders: 10000,
        PerOrderCost: 1.1,
        PriceWithGST: 12980,
        InWords: 'Indian Rupee Twelve Thousand Nine Hundred Eighty Only',
        IsChecked: false,
        ...getDiscountByOrder(10000,11000,12980)
    },
    {
        Id: 4,
        Price: 18750,
        DiscountedPrice: 15000,
        Orders: 15000,
        PerOrderCost: 1.0,
        PriceWithGST: 17700,
        InWords: 'Indian Rupee Seventeen Thousand Seven Hundred Only',
        IsChecked: false,
        ...getDiscountByOrder(15000,15000,17700)
    },
    {
        Id: 5,
        Price: 25000,
        DiscountedPrice: 18000,
        Orders: 20000,
        PerOrderCost: 0.9,
        PriceWithGST: 21240,
        InWords: 'Indian Rupee Twenty One Thousand Two Hundred Forty Only',
        IsChecked: false,
        ...getDiscountByOrder(20000,18000,21240)
    },
    {
        Id: 6,
        Price: 62500,
        DiscountedPrice: 40000,
        Orders: 50000,
        PerOrderCost: 0.8,
        PriceWithGST: 47200,
        InWords: 'Indian Rupee Forty Seven Thousand Two Hundred Only',
        IsChecked: false,
        ...getDiscountByOrder(50000,40000,47200)
    },
    {
        Id: 7,
        Price: 125000,
        DiscountedPrice: 70000,
        Orders: 100000,
        PerOrderCost: 0.7,
        PriceWithGST: 82600,
        InWords: 'Indian Rupee Eighty Two Thousand Six Hundred Only',
        IsChecked: false,
        ...getDiscountByOrder(100000,70000,82600)
    },
    {
        Id: 8,
        Price: 250000,
        DiscountedPrice: 120000,
        Orders: 200000,
        PerOrderCost: 0.6,
        PriceWithGST: 141600,
        InWords: 'Indian Rupee One Lakh and Forty One Thousand Six Hundred Only',
        IsChecked: false,
        ...getDiscountByOrder(200000,120000,141600)
    },
];

export const playSuccessSound = async () => {
    const audio = new Audio();
    audio.src = 'https://bmappassets.s3.ap-south-1.amazonaws.com/sucesssound.mpeg';
    audio.load();
    await audio.play();
};

export const playFailSound = async () => {
    const audio = new Audio();
    audio.src = 'https://bmappassets.s3.ap-south-1.amazonaws.com/failsound.mpeg';
    audio.load();
    await audio.play();
};

export const SystemColor = {
    error: '#FF0000',
    success: '#21AE8C',
    originColor: '#7635dc',
};

export const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: 'rgba(118, 53, 220, 0.08)',
        borderTopLeftRadius: '0px !important',
        borderTopRightRadius: '0px !important',
        borderBottomLeftRadius: '0px !important',
        borderBottomRightRadius: '0px !important',
        // color: theme.palette.common.white,
        boxShadow: `inset 0px 0 0 !important`,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

export const KeyValuePairTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: 'rgba(118, 53, 220, 0.08)',
        borderTopLeftRadius: '0px !important',
        borderTopRightRadius: '0px !important',
        borderBottomLeftRadius: '0px !important',
        borderBottomRightRadius: '0px !important',
        // color: theme.palette.common.white,
        boxShadow: `inset 0px 0 0 !important`,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        backgroundColor: 'rgba(118, 53, 220, 0.08)',
        paddingTop: '10px',
        paddingBottom: '5px',
    },
}));

export const handleOnClickCopy = (data, enqueueSnackbar) => {
    navigator.clipboard.writeText(data);
    enqueueSnackbar('Copied!');
};

export const BootstrapDialog = styled(Dialog)(({theme}) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export const BootstrapDialogTitle = (props) => {
    const {children, onClose, ...other} = props;

    return (
        <DialogTitle sx={{m: 0, p: 2}} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export const CapitalizeFirstLetter = (str) => (str.length ? str.charAt(0).toUpperCase() + str.slice(1) : str);

export const JSONToCSVConvertor = (JSONData, fileName, ShowLabel) => {
    // If JSONData is not an object then JSON.parse will parse the JSON string in an Object

    const allKeys = [...new Set(JSONData.flatMap(Object.keys))];
    const tempJSONData = JSONData.map((otherentry) =>
        Object.fromEntries(allKeys.map((key) => [key, otherentry[key] || '']))
    );

    const arrData = typeof tempJSONData !== 'object' ? JSON.parse(tempJSONData) : tempJSONData;

    let CSV = '';

    // This condition will generate the Label/Header
    if (ShowLabel) {
        let row = '';

        // This loop will extract the label from 1st index of on array
        for (const index in arrData[0]) {
            // Now convert each value to string and comma-seprated
            row += `${index.toUpperCase()},`;
        }

        row = row.slice(0, -1);

        // append Label row with line break
        CSV += `${row}\r\n`;
    }

    // 1st loop is to extract each row
    for (let i = 0; i < arrData.length; i++) {
        let row = '';

        // 2nd loop will extract each column and convert it in string comma-seprated

        for (const index in arrData[i]) {

            if (Array.isArray(arrData[i][index])) {
                let paymentString = '';
                const paymentInfoArray = arrData[i][index];

                for (let arrayIndex = 0; arrayIndex < paymentInfoArray.length; arrayIndex++) {
                    const element = paymentInfoArray[arrayIndex];

                    if (isObject(element)) {
                        const objectKeys = Object.keys(element);
                        const answer = objectKeys.map((value) => [`${value}:${element[value]}`]);
                        paymentString += `${answer} | `;
                    } else {
                        paymentString += `${element} | `;
                    }
                }

                row += `"${paymentString}",`;
            } else if (isObject(arrData[i][index])) {
                const objectKeys = Object.keys(arrData[i][index]);
                const answer = objectKeys.map((value) => [`${value}:${arrData[i][index][value]}`]);
                row += `"${answer}",`;
            } else {
                row += `"${arrData[i][index]}",`.toString();
            }
        }

        row.slice(0, row.length - 1);

        // add a line break after each row
        CSV += `${row}\r\n`;
    }

    if (CSV === '') {
        alert('Invalid data');
        return;
    }

    // Initialize file format you want csv or xls
    const uri = `data:text/csv;charset=utf-8,${escape(CSV)}`;

    // Now the little tricky part.
    // you can use either>> window.open(uri);
    // but this will not work in some browsers
    // or you will not get the correct file extension

    // this trick will generate a temp <a /> tag
    const link = document.createElement('a');
    link.href = uri;

    // set the visibility hidden so it will not effect on your web-layout
    link.style = 'visibility:hidden';
    link.download = `${fileName}.csv`;

    // this part will append the anchor tag and remove it after automatic click
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export async function saveAccountData(data) {
    await localStorage.setItem('accountData', JSON.stringify(data));
    getAccountsData();
}

export async function getAccountsData() {
    return new Promise((resolve) => {
        let tempArray = [];
        const accountData = localStorage.getItem('accountData');

        if (accountData && accountData !== 'undefined') {
            try {
                tempArray = JSON.parse(accountData);
            } catch (error) {
                console.error('Error parsing account data:', error);
            }
        }
        resolve(tempArray);
    });
}
export const SaveUserExistingAcTypes = (accounts) => {
    const avaiAc = ["meesho", "flipkart", "glowroad", "shopdeck", "jiomart", "snapdeal", "myntra", "amazon", "limeroad" ,"citymall"]
    const existAcTypes = [];
    avaiAc.forEach((value) => {
        if (accounts.some((e) => e.AcType === value)) {
            existAcTypes.push(value);
        }
    })
    window.localStorage.setItem('existingAcType', JSON.stringify(existAcTypes));
};

export async function getAccountsDataByType(platFormType, isSet = false,isSelectAll = true) {
    const filteredData = [];
    if(isSelectAll){
        filteredData.push({value: 'all', name: 'Select All'})
    }
    const data = await getAccountsData();
    const allRange = [];
    if (data && data.length !== 0) {
        if (isSet) {
            data.forEach((all) => {
                if (allRange[`${all.AcType}`] === undefined) {
                    filteredData.push({
                        value: `${all.AcType}`,
                        name: `Select All ${CapitalizeFirstLetter(all.AcType)}`
                    });
                    allRange[`${all.AcType}`] = [];
                }
                allRange[`${all.AcType}`].push(all);
            });
        }
        if (platFormType === 'all') {
            data.forEach((value) => {
                filteredData.push({
                    value: value.Account.SupplierId,
                    name: `${CapitalizeFirstLetter(value.AcType)} - ${value.Account.Email} - ${value.Account.Name}`,
                    AcType: value.AcType,
                    Email: value.Account.Email,
                    cName: value.Account.Name,
                    Mobile_No: value.Mobile_No,
                    Image_Link: value.Image_Link,
                    identifier: value.identifier,
                    LocationName: value.LocationName,
                    accountStatus: value.accountStatus,
                    isMaskingEnabled: (value.isMaskingEnabled ? value.isMaskingEnabled : false),
                });
            });
        } else {
            data.forEach((value) => {
                if (value.AcType === platFormType) {
                        filteredData.push({
                            value: value.Account.SupplierId,
                            name: `${CapitalizeFirstLetter(value.AcType)} - ${value.Account.Email} - ${value.Account.Name}`,
                            AcType: value.AcType,
                            Email: value.Account.Email,
                            cName: value.Account.Name,
                            Mobile_No: value.Mobile_No,
                            Image_Link: value.Image_Link,
                            LBHW: value.LBHW,
                            identifier: value.identifier,
                            SellerId: value.SellerId,
                            LocationName: value.LocationName,
                            accountStatus: value.accountStatus,
                            isMaskingEnabled: (value.isMaskingEnabled ? value.isMaskingEnabled : false),
                        });
                }
            });
            const countfiltered = data.filter((element) => {
                return element.AcType === platFormType;
            });

            if (countfiltered?.length === 0) {
                filteredData.pop();
            }
        }
    }
    // console.log(filteredData);
    const filteredData2 = filteredData.filter(item => {
        if (isSuperAdminEnvironment) {
            return true;
        }  else if(item.name.includes('All')){
            return  true;
        } else{
            return item.accountStatus === "ACTIVATE";
        }
    });

    return filteredData2;
}

export async function setAccountSelectorData(value, userData) {
    const filterData = [];
    const selectedAccounts = [];
    const selectedOptions = value;
    const RetData = [];

    if (selectedOptions.length > 0) {
        for (const selectAll of selectedOptions) {
            if (selectAll.value !== 'all') {
                userData.forEach((item) => {
                    if (item.AcType === selectAll.value) {
                        selectedAccounts.push(item);
                    }
                });
            } else {
                userData.forEach((item) => {
                    if (item.AcType !== undefined) selectedAccounts.push(item);
                });
            }
        }
        selectedOptions.forEach((item) => {
            filterData.push(item);
            if (item.AcType !== undefined) {
                selectedAccounts.push(item);
            }
        });
    }

    RetData.push(filterData);
    RetData.push(selectedAccounts);

    return RetData;
}

export async function uploadFileS3(file, name, key, bucketName, enqueueSnackbar) {
    const putParams = {
        Body: file,
        Bucket: bucketName,
        Key: key,
    };
    return new Promise((resolve) => {
        s3.putObject(putParams, (err, data) => {
            if (err) {
                // console.log(err);
                enqueueSnackbar("Can't Upload File.", {variant: 'error'});
            }
            resolve(data);
        });
    });
}



export async function uploadSyncFileS3(file,username, tp_email, subAccount, companyName, fileType, fileName,acType,enqueueSnackbar){
    const token = Math.floor(Date.now() / 1000)
    const putParams = {
        Body: file,
        Bucket: PAYMENT_UPLOAD_BT_ACCOUNT,
        Key: `Pending/${username}/${acType}/${tp_email}/${subAccount}/${companyName}/${token}/${fileType}/${fileName}`,
    };
    return new Promise((resolve) => {
        s3.putObject(putParams, (err, data) => {
            if (err) {
                // console.log(err);
                enqueueSnackbar("Can't Upload File.", {variant: 'error'});
            }
            resolve(data);
        });
    });
}

export async function downloadS3File(file, isPdf, bucketName, enqueueSnackbar) {
    const params = {
        Bucket: bucketName,
        Key: file,
        ResponseCacheControl: 'no-cache',
    };

    if (file) {
        const myParam = file.split('/');
        const fileNameWithExt = (myParam && myParam[myParam.length - 1]) || '';
        const splitedFileNameWithExt = fileNameWithExt.split('.');
        const fileExt = splitedFileNameWithExt[splitedFileNameWithExt.length - 1];
        const fileName = `${splitedFileNameWithExt[0]}_${Date.now()}.${fileExt}`;

        s3.getObject(params, async (err, data) => {
            if (err) {
                enqueueSnackbar('File not Found.', {variant: 'error'});
            } else if (data.Body && fileName !== '') {
                await downloadBlob(data.Body, fileName, isPdf);
            } else {
                enqueueSnackbar('Data body not found.', {variant: 'error'});
            }
        });
    }
}
export async function getS3FileURL(file, bucketName) {
    const params = {
        Bucket: bucketName,
        Key: file,
        ResponseCacheControl: 'no-cache, no-store, must-revalidate',
    };

    try{
        if (file) {
            const output = await s3.getObject(params).promise();
            const file1 = new Blob([output.Body], {
                type: 'image/*',
            });
            // console.log("file1",file1)
            const fileURL = URL.createObjectURL(file1);
            return fileURL;
        }
    }
    catch (e){
        console.log("ERROR=>",e.toString())
        return getS3ImageURL(bucketName,file)
    }

}


export async function getFileSize(bucketName, fileKey, enqueueSnackbar) {
    const params = {
        Bucket: bucketName,
        Key: fileKey,
    };

    try {
        const headObjectOutput = await s3.headObject(params).promise();
        return formatFileSize(headObjectOutput.ContentLength);
    } catch (error) {
        enqueueSnackbar(`Error getting file size: ${error}`, {variant: 'error'});
        ;
        return null;
    }
};

export function formatFileSize(bytes) {
    if (bytes >= 1024 * 1024 * 1024) {
        return (bytes / (1024 * 1024 * 1024)).toFixed(2) + ' GB';
    } else if (bytes >= 1024 * 1024) {
        return (bytes / (1024 * 1024)).toFixed(2) + ' MB';
    } else if (bytes >= 1024) {
        return (bytes / 1024).toFixed(2) + ' KB';
    } else {
        return bytes + ' bytes';
    }
};

export const downloadBlob = (blobString, fileName, isPDf) => {
    const file = new Blob([blobString], {
        type: isPDf ? 'application/pdf' : 'image/*',
    });
    const fileURL = URL.createObjectURL(file);
    const link = document.createElement('a');
    link.href = fileURL;
    link.download = fileName;
    link.click();
};

export const changeDateFormat = (cell) => {
    if (cell.includes('.000Z')) {
        const newDateformat = new Date(cell.split('.')[0]).toLocaleDateString('en-us', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        });
        return newDateformat;
    } else {
        const newDateformat = new Date(cell).toLocaleDateString('en-us', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        });
        return newDateformat;
    }
};

export const fullDateShow = (isoDateString,isFull) => {
    if (!isoDateString || isoDateString === ' - ' || isoDateString === '-' || isoDateString === 'NA' || isoDateString === undefined) {
        return '-';
    }
    let datee = new Date(isoDateString);

    if (isNaN(datee.getTime())) {
        return '-';
    }
    // Parse the input date string into a Date object
    let date = new Date(isoDateString);

    // Extract day, month, year, hours, minutes, and seconds components
    let day = date.getDate();
    let month = date.getMonth() + 1; // JavaScript's months are 0-based, so add 1
    let year = date.getFullYear();
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let seconds = date.getSeconds();

    // Pad single-digit day, month, hours, minutes, and seconds values with leading zeros
    let formattedDay = day < 10 ? `0${day}` : day;
    let formattedMonth = month < 10 ? `0${month}` : month;
    let formattedHours = hours < 10 ? `0${hours}` : hours;
    let formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    let formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

    // Create the formatted date string in "dd-mm-yyyy hh:mm:ss" format
    return isFull ? `${formattedDay}-${formattedMonth}-${year} ${formattedHours}:${formattedMinutes}:${formattedSeconds}` : changeDateFormat(isoDateString);

}

export const GetTodayDateTime = () => {
    return moment().format('DD-MM-YYYY_HH:mm');
};

export const isDate = (sDate) => {
    if (typeof sDate === 'number') {
        if (sDate === 0) {
            return 0;
        }
    } else {
        if (sDate && sDate.toString() === parseInt(sDate, 10).toString()) return false;
        const tryDate = new Date(sDate);
        return tryDate && tryDate.toString() !== 'NaN' && tryDate.toString() !== 'Invalid Date';
    }
    return 0;
};

export function removeDuplicates(myArr, prop) {
    return myArr.filter((obj, pos, arr) => arr.map((mapObj) => mapObj[prop]).indexOf(obj[prop]) === pos);
}

export function isValidUrl(_string) {
    const matchPattern = /^(?:\w+:)?\/\/([^\s]+\.\S{2}|localhost[?\d]*)\S*$/;
    return matchPattern.test(_string);
}

export const changeDataFormat = (cell) => {
    if (cell) {
        return cell.toString().toLowerCase() === 'true' ? (
            <>
                <div style={{color: '#029a02', display: 'inline-flex', verticalAlign: 'middle', alignItems: 'center'}}>
                    <Iconify icon={'bi:check-circle-fill'} width={20} height={20}/>
                </div>
            </>
        ) : (
            <>
                <div style={{color: '#fd0000', display: 'inline-flex', verticalAlign: 'middle', alignItems: 'center'}}>
                    <Iconify icon={'material-symbols:cancel'} width={20} height={20}/>
                </div>
            </>
        );
    }
    return (
        <>
            <div style={{color: '#fd0000', display: 'inline-flex', verticalAlign: 'middle', alignItems: 'center'}}>
                <Iconify icon={'material-symbols:cancel'} width={20} height={20}/>
            </div>
        </>
    );
};

export const checkIfValueIsBoolean = (value) => {
    if (value === 'True' || value === 'False' || value === true || value === false) {
        return true;
    }
    return false;
};

const onDoubleClickDownload = async (event, row) => {
    if (event.detail === 2 && isDesktopEnvironment) {
        const element = document.createElement('a');
        element.href = row.original?.image_link;
        const url = row.original?.image_link;
        const imageType = url.split('/').at(-1).split('.').at(-1);
        element.download = `${row.original?.sku}.${imageType}`;
        element.click();
    }
};

export const shortOrderArray = (data) => {
    let count = 1;
    const reOrderArray = [];
    for (let i = 0; i < data.length; i++) {
        const element = data[i];
        element.order = count;
        reOrderArray.push(element);
        count++;
    }

    return reOrderArray;
};

export const getUniqueArray = (myArray) => {
    return myArray.filter((thing, index, self) => index === self.findIndex((t) => t.id === thing.id));
};

export const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({theme}) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

export const Android12Switch = styled(Switch)(({ theme, isTransferred  }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
        backgroundColor: isTransferred==='DataTransferred' ? 'blue' : 'grey',
        borderRadius: 22 / 2,
        '&::before, &::after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 16,
            height: 16,
        },
        '&::before': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
              theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
            left: 12,
        },
        '&::after': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
              theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M19,13H5V11H19V13Z" /></svg>')`,
            right: 12,
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        width: 16,
        height: 16,
        margin: 2,
    },
    // Disable the switch if it is in the "transferred" state
    pointerEvents: isTransferred==='DataTransferred' ? 'none' : 'auto',
    // Add a visual indication of disabled state
    opacity: isTransferred==='DataTransferred' ? 0.5 : 1,
}));

export const ExcelDateToJSDate = (date) => {
    if (date?.includes(' ')) {
        const newDate = date.split(' ');
        if (newDate[1].includes('-')) {
            return moment(newDate[1], 'DD-MM-YYYY').format('YYYY-MM-DD');
        } else if (newDate[1].includes('/')) {
            return moment(newDate[1], 'DD/MM/YYYY').format('YYYY-MM-DD');
        }
    }
    // else {
    //   const newDate = date.split('/');
    //   if (newDate[2].length === 2) {
    //     return moment(date, 'DD/MM/YY').format('YYYY-MM-DD');
    //   }
    // }
    return moment(date).format('YYYY-MM-DD');
};

export const ReadExcelFile = (excelFile, sheetNumber, findSheetName = '') => {
    const data = excelFile.target.result;
    const workbook = xlsx.read(data, {type: 'array', cellDates: true});
    let sheetName = '';
    if (findSheetName.length > 0) {
        sheetName = workbook.SheetNames.find((x) => x.toLowerCase() === findSheetName.toLowerCase());
    } else {
        sheetName = workbook.SheetNames[sheetNumber];
    }
    const worksheet = workbook.Sheets[sheetName];
    return xlsx.utils.sheet_to_json(worksheet, {
        header: 1,
        dateNF: 'yyyy-mm-dd',
        raw: false,
    });
};

export const CreateExcelFile = (excelData, sheetName, isShowlabel) => {
    console.log(excelData);
    const XLSX = require('xlsx');

    const data = excelData;
    const header = Object.keys(data[0]).map(key => key.toUpperCase());
    const sheetData = [header, ...data.map(obj => Object.values(obj))];

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(sheetData);
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

    XLSX.writeFile(workbook, `${sheetName}.xlsx`);
};


export const initializeInstruction = () => {
    const defaultPreferences = {
        dashboard: true,
        myLinkedAccount: true,
        syncOrder: true,
        utilitiesMeesho: true,
        utilitiesFlipkart: true,
        utilitiesGlowroad: true,
        utilitiesCityMall: true,
        utilitiesSnapdeal: true,
        utilitiesLimeroad: true,
        utilitiesCR: true,
        orderAnalysis: true,
        masterSku: true,
        exploreSku: true,
        claimIssueM: true,
        claimIssueF: true,
        claimIssueG: true,
        claimIssueR: true,
        claimIssueAT: true,
        scanOrder: true,
        monthlyReport: true,
    };

    const storedPreferences = JSON.parse(localStorage.getItem('userInstruction'));
    if (!storedPreferences || Object.keys(storedPreferences).length !== Object.keys(defaultPreferences).length) {
        localStorage.setItem('userInstruction', JSON.stringify(defaultPreferences));
    }
}

export const updateInstruction = (key, value) => {
    const storedPreferences = JSON.parse(localStorage.getItem('userInstruction'));
    storedPreferences[key] = value;
    localStorage.setItem('userInstruction', JSON.stringify(storedPreferences));
}
export const checkStatusOfInstruction = (key) => {
    const defaultPreferences = {
        dashboard: true,
        myLinkedAccount: true,
        syncOrder: true,
        utilitiesMeesho: true,
        utilitiesFlipkart: true,
        utilitiesGlowroad: true,
        utilitiesCityMall: true,
        utilitiesSnapdeal: true,
        utilitiesLimeroad: true,
        utilitiesCR: true,
        orderAnalysis: true,
        masterSku: true,
        exploreSku: true,
        claimIssueM: true,
        claimIssueF: true,
        claimIssueG: true,
        claimIssueR: true,
        claimIssueAT: true,
        scanOrder: true,
        monthlyReport: true,
    };

    let storedPreferences = JSON.parse(localStorage.getItem('userInstruction'));

    if (!storedPreferences || Object.keys(storedPreferences).length !== Object.keys(defaultPreferences).length) {
        storedPreferences = defaultPreferences;
        localStorage.setItem('userInstruction', JSON.stringify(storedPreferences));
    }

    return storedPreferences[key];
};


export const getBillDetailsDateRange = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // Months are zero-indexed

    // Format the current date to "DD/MM/YY"
    const formatTwoDigits = (number) => (number < 10 ? `0${number}` : `${number}`);
    const formattedCurrentDate = `${formatTwoDigits(currentDate.getDate())}/${formatTwoDigits(currentMonth)}/${currentYear.toString().slice(-2)}`;

    // Get the first day of the current month
    const startDate = `01/${formatTwoDigits(currentMonth)}/${currentYear.toString().slice(-2)}`;

    // Get the current date
    const endDate = formattedCurrentDate;

    return {
        start_date: startDate,
        end_date: endDate,
    };
}
