// CommonDialog.js
import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import {
    Autocomplete,
    Box,
    Divider,
    FormControl,
    FormControlLabel,
    Grid, Radio,
    RadioGroup,
    TextField,
    useTheme
} from "@mui/material";
import {DatePicker, Typography} from "antd";
import {useSnackbar} from "notistack";
import ReactJson from "react-json-view";
import useAuth from "../../../hooks/useAuth";
import {CapitalizeFirstLetter, getAccountsDataByType, setAccountSelectorData} from "../../../utils/utilities";
import {CreatePostAPICall, MyLinkedAccount, V2Management} from "../../../utils/apis";
import AccountSelector from "../../../components/Common/AccountSelector";

export default function CommonDialog({
                                         open,
                                         title,
                                         meeshoAcs,
                                         close,
                                         allAc,
                                         closeOnSubmit = true,
                                         actionType,
                                         oldAccountToTD,
                                         userDataAll,
                                         btnName,
                                     }) {
    const theme = useTheme();
    const {enqueueSnackbar} = useSnackbar();
    const isLight = theme.palette.mode === 'light';
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const {RangePicker} = DatePicker;
    const {user} = useAuth();
    const localUser = window.localStorage.getItem('userEmailId');
    const [selectedAcMgt, setSelectedAcMgt] = useState({});
    const [selectStateCondition, setSelectStateCondition] = useState('');
    const [skuName, setSkuName] = useState('');
    const [skuSize, setSkuSize] = useState('');
    const [addState, setAddState] = useState('');
    const [addCName, setAddCName] = useState('');
    const [skuSelfCost, setSkuSelfCost] = useState('');
    const [selectedSubAcToTD, setSelectedSubAcToTD] = useState({});
    const [supplierIds, setSupplierIds] = useState([]);
    const [tpEmail, setTpEmail] = useState([]);
    const [createdPayloadJSON, setCreatedPayloadJSON] = useState({});
    const [platformName, setplatformName] = useState({});
    const [filterUserData, setFilterUserData] = useState([]);
    const [selectedAccountsData, setSelectedAccountsData] = useState([]);
    const [userData, setUserData] = useState([]);
    const [allAcToTD, setAllAcToTD] = useState([]);
    const [acMgtActionType, setAcMgtActionType] = useState(null);
    const [newAccountToTD, setNewAccountToTD] = useState([]);
    const [selectedSubAc, setSelectedSubAc] = useState({});
    const selectStateOptionsArray = [
        'Others',
        'Andhra Pradesh',
        'Gujarat',
        'Andaman & Nicobar Island',
        'Telangana',
        'Arunanchal Pradesh',
        'Assam',
        'Uttar Pradesh',
        'Karnataka',
        'Uttarakhand',
        'Bihar',
        'Chhattisgarh',
        'Chandigarh',
        'Tamil Nadu',
        'Madhya Pradesh',
        'Rajasthan',
        'Maharashtra',
        'Dadara & Nagar Haveli',
        'Daman & Diu',
        'Delhi',
        'Goa',
        'Jharkhand',
        'Odisha',
        'Haryana',
        'Himachal Pradesh',
        'Jammu & Kashmir',
        'Kerala',
        'West Bengal',
        'Manipur',
        'Meghalaya',
        'Mizoram',
        'Punjab',
        'Nagaland',
        'Puducherry',
        'Sikkim',
        'Tripura',
    ];

    const onAction = async () => {
        const body = {
            username: user?.email ? user.email : localUser,
            tpEmailId: selectedAcMgt.Email,
            subAccountId: selectedAcMgt.value,
            acType: selectedAcMgt.AcType,
            actionType: 'accountMgt',
            action: acMgtActionType,
            panel: 'admin',
            subPanel: 'adminTools',
        };
        const response = await CreatePostAPICall(enqueueSnackbar, V2Management, body);
        if (response) {
            enqueueSnackbar(`Account ${acMgtActionType} Successfully`);
        }
    };
    const setLocalStorageDataTransfer = async (accountName) => {
        setNewAccountToTD(accountName);
        // setFetchOrderData([]);
        const response = await CreatePostAPICall(enqueueSnackbar, MyLinkedAccount, {
            username: accountName,
            actionType: 'getAll',
        });
        if (response && response.data && response.data.body && response.data.statusCode === 200) {
            const objData = [];
            response.data.body.forEach((item) => {
                const cell = item;
                const newData = {};
                newData.Account = {
                    Name: cell.Name,
                    Email: cell.Email,
                    SupplierId: cell.SupplierId,
                };
                newData.no = cell.no;
                newData.AcType = cell.AcType;
                objData.push(newData);
            });
            const allac = [];
            objData.forEach((item) => {
                if (item.AcType === selectedSubAc.AcType) {

                    allac.push({
                        value: item.Account.SupplierId,
                        label: `${CapitalizeFirstLetter(item.AcType)} - ${item.Account.Email} - ${item.Account.Name}`,
                        AcType: item.AcType,
                        Email: item.Account.Email,
                    });
                }
            });
            // console.log(allac)
            setAllAcToTD(allac);
        }
    };
    const transferData = async () => {
        const body = {
            actionType: 'transferAccountData',
            username_from: localUser,
            username_to: newAccountToTD,
            acType: selectedSubAc.AcType,
            oldTpEmailId: selectedSubAc.Email,
            oldSubAccountId: selectedSubAc.value,
            newTpEmailId: selectedSubAcToTD.Email,
            newSubAccountId: selectedSubAcToTD.value,
            startDate,
            endDate,
            panel: 'admin',
            subPanel: 'adminTools',
        };
        const response = await CreatePostAPICall(enqueueSnackbar, V2Management, body);
        if (response) {
            enqueueSnackbar(response.data.body);
        }
    };


    const onPlatformChange = async (platform) => {
        setplatformName(platform);
        const response = await CreatePostAPICall(enqueueSnackbar, MyLinkedAccount, {
            username: user?.email ? user.email : localUser,
            actionType: 'getAll',
        });
        const newArr = [];
        response.data.body.forEach((value) => {
            if (value.AcType === platform) {
                newArr.push({
                    value: value.SupplierId,
                    label: `${CapitalizeFirstLetter(value.AcType)} - ${value.Email} - ${value.SupplierId}`,
                    AcType: value.AcType,
                    Email: value.Email,
                });
            }
        });
        setFilterUserData(newArr);
    };

    const onFilterSelectedAccount = (value) => {
        const supplierIdData = [];
        const tpemailData = [];
        value.forEach((item) => {
            supplierIdData.push(item.value);
            tpemailData.push(item.Email);
        });
        setSupplierIds(supplierIdData);
        setTpEmail(tpemailData);
    };

    const getdate = (value, dateString) => {
        setStartDate(dateString[0]);
        setEndDate(dateString[1]);
    };

    const generatePayLoad = () => {
        const payloadJSON = {
            username: user?.email ? user.email : localUser,
            tpEmailIds: tpEmail,
            subAccountIds: supplierIds,
            acType: platformName,
        };
        if (startDate !== '') {
            payloadJSON.start_date = startDate
        }
        if (endDate !== '') {
            payloadJSON.end_date = endDate
        }
        if (selectedAcMgt.value !== undefined) {
            payloadJSON.syncDataType = selectedAcMgt.value
        }
        setCreatedPayloadJSON(payloadJSON);
    };
    const selectStateOptions = [];


    selectStateOptionsArray.forEach((value) => {
        selectStateOptions.push({
            value,
            label: value,
        });
    });
    const platformOption = [
        {
            value: 'meesho',
            label: 'Meesho',
        },
        {
            value: 'flipkart',
            label: 'Flipkart',
        },
        {
            value: 'glowroad',
            label: 'Glowroad',
        },
        {
            value: 'shopdeck',
            label: 'Shopdeck',
        },
        {
            value: 'snapdeal',
            label: 'Snapdeal',
        },
        {
            value: 'jiomart',
            label: 'Jiomart',
        },
        {
            value: 'myntra',
            label: 'Myntra',
        },
        {
            value: 'amazon',
            label: 'Amazon',
        },
    ];

    const syncOption = [
        {
            value: 'RTS',
            label: 'RTS',
        },
        {
            value: 'RTN',
            label: 'RTN',
        },
        {
            value: 'CNL',
            label: 'CNL',
        },
        {
            value: 'PAYMENT_PAID',
            label: 'PAYMENT_PAID',
        },
        {
            value: 'PAYMENT_OUT',
            label: 'PAYMENT_OUT',
        },
        {
            value: 'ADS_COST',
            label: 'ADS_COST',
        },
        {
            value: 'TICKET_RESPONSE',
            label: 'TICKET_RESPONSE',
        },
        {
            value: 'SHIPPED',
            label: 'SHIPPED',
        },
    ];

    const clear = async () => {
        setSkuName('');
        setSelectedAccountsData([]);
        setSkuName('');
        setStartDate('')
        setEndDate('')
        setSkuSize('');
        setAddCName('');
        setSkuSelfCost('');
        setCreatedPayloadJSON({});
    }
    const sendStateValue = async (row) => {
        const body = {
            actionType: 'stateMapping',
            keyState: row.keyState,
            State: row.State.value,
            panel: 'admin',
            subPanel: 'adminTools',
        };
        const response = await CreatePostAPICall(enqueueSnackbar, V2Management, body);
        if (response) {
            enqueueSnackbar('State Name Updated');
            setAddState('');
        }
    };
    const sendValue = async () => {
        const body = {
            username: user?.email ? user.email : localUser,
            tpEmailId: selectedAcMgt.Email,
            subAccountId: selectedAcMgt.value,
            newCname: addCName,
            actionType: 'meeshoCnameUpdate',
            panel: 'admin',
            subPanel: 'adminTools',
        };

        const response = await CreatePostAPICall(enqueueSnackbar, V2Management, body);
        if (response) {
            enqueueSnackbar('Company Name Updated');
            setAddCName('');
        }
    };

    const updateSelfCost = async () => {
        const body = {
            username: user?.email ? user.email : localUser,
            panel: 'admin',
            subPanel: 'adminTools',
            actionType: 'updateSelfCost',
            tpEmailId: selectedAcMgt.Email,
            subAccountId: selectedAcMgt.value,
            acType: selectedAcMgt.AcType,
            skuName,
            skuSize,
            skuSelfCost,
            dateRange: {from: startDate, to: endDate},
        };
        const response = await CreatePostAPICall(enqueueSnackbar, V2Management, body);
        if (response) {
            enqueueSnackbar(response.data.body);
            setSkuName('');
            setSkuSize('');
            setSkuSelfCost('');
        }
    };
    const extList = ["PAYMENT_NOT_RECEIVED", "DELI_Not_Rec", "PENDI_Not_Rec", "DPWU_Received"]
    const extractToReport = async () => {
        const promises = [];

        for (let i = 0; i < selectedAccountsData.length; i += 1) {
            const acType = selectedAccountsData[i].AcType;
            const subAccountId = selectedAccountsData[i].value;
            const tpEmailId = selectedAccountsData[i].Email;

            for (let j = 0; j < extList.length; j += 1) {
                const payloadForExtractToReport = {
                    "username": user?.email ? user.email : localUser,
                    "subAccountId": subAccountId,
                    "tpEmailId": tpEmailId,
                    "extractType": extList[j],
                    "acType": acType,
                    "actionType": "extractToReport"
                };
                promises.push(CreatePostAPICall(enqueueSnackbar, V2Management, payloadForExtractToReport));
            }
        }
        await Promise.all(promises);
        close()
        enqueueSnackbar("Order Extraction Started For Claim")
    };


    const handleSelectedAccount = async (event, value) => {
        const response = await setAccountSelectorData(value, userData);
        setSelectedAccountsData(response[1]);
    };

    useEffect(() => {
        getAccountsDataByType('all', true).then((filteredData) => {
            setUserData(filteredData);
        });
    }, []);

    return (
        <Dialog
            fullWidth
            maxWidth={false}
            open={open}
            onClose={() => {
                clear()
                close()
            }}
            PaperProps={{
                sx: {maxWidth: 720},
            }}
        >
            <DialogTitle style={{marginBottom: "1rem"}}>{title}</DialogTitle>

            <DialogContent>
                {actionType === "Update Meesho Company Name" &&
                    <Box
                        sx={{
                            display: 'grid',
                            rowGap: 2,
                            p: 1.5,
                        }}
                    >
                        <Autocomplete
                            id="update-meesho-company-name"
                            disableClearable
                            onChange={(event, value) => {
                                setSelectedAcMgt(value);
                            }}
                            options={meeshoAcs.map((option) => option)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Select Meesho Account*"
                                    InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                    }}
                                />
                            )}
                        />
                        <TextField
                            id="outlined-basic-1"
                            label="Enter Company Name"
                            variant="outlined"
                            value={addCName}
                            onChange={(event) => {
                                setAddCName(event.target.value);
                            }}
                        />
                    </Box>
                }

                {actionType === "Transfer Data" &&
                    <Box
                        sx={{
                            display: 'grid',
                            rowGap: 2,
                            p: 1.5,
                        }}
                    >
                        <RangePicker
                            getPopupContainer={(triggerNode) => {
                                return triggerNode.parentNode;
                            }}
                            format={'DD/MM/YYYY'}
                            className={'createDateRangePicker'}
                            pupClassName={'createDateRangePicker'}
                            onChange={(value, event) => getdate(value, event)}
                            {...(!isLight && {
                                className: 'date-picker-dark',
                            })}
                        />
                        <Autocomplete
                            id="name"
                            disableClearable
                            onChange={(event, value) => {
                                setSelectedSubAc(value);
                            }}
                            options={allAc.map((option) => option)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Select Sub Account*"
                                    InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                    }}
                                />
                            )}
                        />

                        <Autocomplete
                            id="free-solo-3-demo"
                            disableClearable
                            onChange={async (event, accountName) => {
                                await setLocalStorageDataTransfer(accountName);
                            }}
                            options={userDataAll.map((option) => option)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Select Account To Tansfer*"
                                    InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                    }}
                                />
                            )}
                        />

                        <Autocomplete
                            id="name2"
                            disableClearable
                            onChange={(event, value) => {
                                // console.log(value)
                                setSelectedSubAcToTD(value);
                            }}
                            options={allAcToTD.map((option) => option)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Select Sub Account To Transfer*"
                                    InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                    }}
                                />
                            )}
                        />

                    </Box>
                }



                {actionType === "State Mapping" && <Box
                    sx={{
                        display: 'grid',
                        rowGap: 2,
                        p: 1.5,
                    }}
                >
                    <Autocomplete
                        id="Select-State"
                        disableClearable
                        onChange={(event, value) => {
                            setSelectStateCondition(value);
                        }}
                        options={selectStateOptions.map((option) => option)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Select State*"
                                InputProps={{
                                    ...params.InputProps,
                                    type: 'search',
                                }}
                            />
                        )}
                    />
                    <TextField
                        id="outlined-basic-2"
                        label="Enter New State"
                        variant="outlined"
                        value={addState}
                        onChange={(event) => {
                            setAddState(event.target.value);
                        }}
                    />
                </Box>}

                {actionType === "Account Management" && (
                    <Box
                        sx={{
                            display: 'grid',
                            rowGap: 2,
                            p: 1.5,
                        }}
                    >
                        <Autocomplete
                            id="Select-Account"
                            disableClearable
                            onChange={(event, value) => {
                                setSelectedAcMgt(value);
                            }}
                            options={allAc.map((option) => option)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Select Account*"
                                    InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                    }}
                                />
                            )}
                        />

                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="radio-buttons-group"
                        >
                            <div style={{display: 'flex'}}>
                                <FormControlLabel
                                    value="Disable"
                                    control={<Radio/>}
                                    label="Disable"
                                    onChange={() => {
                                        setAcMgtActionType('disable');
                                    }}
                                />
                                <FormControlLabel
                                    value="Delete"
                                    control={<Radio/>}
                                    label="Delete"
                                    onChange={() => {
                                        setAcMgtActionType('delete');
                                    }}
                                />
                            </div>
                        </RadioGroup>
                    </Box>)}

                {actionType === "Update SelfCost" && <Box
                    sx={{
                        display: 'grid',
                        rowGap: 2,
                        p: 1.5,
                    }}
                >
                    <FormControl sx={{width: '100%'}}>
                        <Autocomplete
                            id="update-self-cost-v2"
                            disableClearable
                            onChange={(event, value) => {
                                setSelectedAcMgt(value);
                            }}
                            options={allAc.map((option) => option)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Select Account*"
                                    InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                    }}
                                />
                            )}
                        />
                    </FormControl>

                    <RangePicker
                        getPopupContainer={(triggerNode) => {
                            return triggerNode.parentNode;
                        }}
                        format={'DD/MM/YYYY'}
                        className={'createDateRangePicker'}
                        pupClassName={'createDateRangePicker'}
                        onChange={(value, event) => getdate(value, event)}
                        {...(!isLight && {
                            className: 'date-picker-dark',
                        })}
                    />

                    <TextField
                        id="outlined-basic-sku"
                        label="Enter SKU"
                        variant="outlined"
                        value={skuName}
                        onChange={(event) => {
                            setSkuName(event.target.value);
                        }}
                    />

                    <TextField
                        id="outlined-basic-sku-size"
                        label="Enter SKU Size"
                        variant="outlined"
                        value={skuSize}
                        onChange={(event) => {
                            setSkuSize(event.target.value);
                        }}
                    />

                    <TextField
                        id="outlined-basic-self-cost"
                        label="Enter New SelfCost"
                        variant="outlined"
                        value={skuSelfCost}
                        onChange={(event) => {
                            setSkuSelfCost(event.target.value);
                        }}
                    />
                </Box>}

                {actionType === "Generate Payload" && <Box
                    sx={{
                        display: 'grid',
                        rowGap: 2,
                        p: 1.5,
                    }}
                >
                    <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 1, md: 3}}>
                        <Grid item xs={6}>
                            <Autocomplete
                                id="Select-Platform"
                                disableClearable
                                onChange={(event, value) => {
                                    onPlatformChange(value.value);
                                }}
                                options={platformOption}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Select Platform Type"
                                        InputProps={{
                                            ...params.InputProps,
                                            type: 'search',
                                        }}
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <ReactJson src={createdPayloadJSON}/>
                        </Grid>
                    </Grid>

                    <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 1, md: 3}}>
                        <Grid item xs={6}>
                            <Autocomplete
                                multiple
                                id="Select-ac"
                                onChange={(e, value) => {
                                    onFilterSelectedAccount(value, e);
                                }}
                                options={filterUserData.map((option) => option)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Select Account"
                                        InputProps={{
                                            ...params.InputProps,
                                            type: 'search',
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>

                    <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 1, md: 3}}>
                        <Grid item xs={6}>
                            <Autocomplete
                                id="Select-type"
                                disableClearable
                                onChange={(event, value) => {
                                    setSelectedAcMgt(value);
                                }}
                                options={syncOption.map((option) => option)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Select Sync Type"
                                        InputProps={{
                                            ...params.InputProps,
                                            type: 'search',
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>

                    <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 1, md: 3}}>
                        <Grid item xs={6}>
                            <span style={{marginRight: '9%'}}>Select Date:</span>
                            <RangePicker
                                getPopupContainer={(triggerNode) => {
                                    return triggerNode.parentNode;
                                }}
                                format={'DD/MM/YY'}
                                className={'createDateRangePicker'}
                                pupClassName={'createDateRangePicker'}
                                onChange={(value, event) => getdate(value, event)}
                                {...(!isLight && {
                                    className: 'date-picker-dark',
                                })}
                            />
                        </Grid>
                    </Grid>
                </Box>}

                {actionType === "Extract To Report" && <Box
                    sx={{
                        display: 'grid',
                        rowGap: 2,
                        p: 1.5,
                    }}
                >
                    <AccountSelector allData={userData} onChangeHandler={handleSelectedAccount}/>
                </Box>}


            </DialogContent>

            <DialogActions>
                <Button variant="outlined" onClick={() => {
                    clear()
                    close()
                }}>
                    Cancel
                </Button>
                <LoadingButton type="submit" variant="contained" onClick={() => {

                    if (closeOnSubmit) {
                        clear()
                        close()
                    }

                    if (actionType === 'Update Meesho Company Name') {
                        sendValue({
                            newCname: setAddCName,
                        })
                    } else if (actionType === "State Mapping") {
                        sendStateValue({
                            State: selectStateCondition,
                            keyState: addState,
                        })
                    } else if (actionType === "Update SelfCost") {
                        updateSelfCost()
                    } else if (actionType === "Generate Payload") {
                        generatePayLoad()
                    } else if (actionType === "Extract To Report") {
                        extractToReport()
                    } else if (actionType === "Account Management") {
                        onAction()
                    }else if (actionType === "Transfer Data") {
                        transferData()
                    }

                }}>
                    {btnName}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}
