/* eslint-disable */
import {Autocomplete, Button, TextField} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DatePicker } from 'antd';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import DataGridTable from '../../../../components/Common/DataGridTable';
import { CreatePostAPICall, V2Management } from '../../../../utils/apis';
import {changeDataFormat, CreateExcelFile} from '../../../../utils/utilities';
import { JSONToCSVConvertor } from '../../../../utils/utilities';
import useAuth from '../../../../hooks/useAuth';
import axios from 'axios';
import FormControl from "@mui/material/FormControl";

const TodayTransaction = () => {
  const { user } = useAuth();
  const localUser = window.localStorage.getItem('userEmailId');

  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';
  const { enqueueSnackbar } = useSnackbar();
  const { RangePicker } = DatePicker;
  const [todayTransactionData, setTodayTransactionData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const onHandleChangeText = (cell, value) => {
    const cloneOriginalData = [...todayTransactionData];
    cloneOriginalData[cell.row.original.Reason] = value;
    setTodayTransactionData(cloneOriginalData);
  };
  const reasons = [
    'CALL NOT RECEIVED',
    'NOT USE WITH NO ISSUE',
    'WILL USE',
    'CALL US WHEN HE/SHE WANTS TO USE',
    'NOT USE BUT DO NOT DEACTIVATE',
    'DEACTIVATE',
    'PACKAGE PRICE IS HIGH',
    'HE/SHE WANTS TICKET MANAGEMENT',
    'MEESHO SELLING CLOSE',
    'NUMBER SWITCH OFF',
    'CUT THE CALL',
  ];
  const todayTXN = [
    {
      accessorKey: 'No',
      header: 'No',
      size: 10,
    },
    {
      accessorKey: 'Username',
      header: 'User Name',
      size: 30,
    },
    {
      accessorKey: 'Date',
      header: 'Date',
      size: 30,
    },
    {
      accessorKey: 'Add Type',
      header: 'Add Type',
      size: 20,
    },
    {
      accessorKey: 'Balance Before',
      header: 'Balance Before',
      size: 20,
    },
    {
      accessorKey: 'Balance After',
      header: 'Balance After',
      size: 20,
    },
    {
      accessorKey: 'Is Verified',
      header: 'Is Verified',
      size: 20,
      Cell: (cell) => changeDataFormat(cell.row.original?.['Is Verified']),
    },
    {
      accessorKey: 'Is Bill Generated',
      header: 'Is Bill Generated',
      size: 20,
      Cell: (cell) => changeDataFormat(cell.row.original?.['Is Bill Generated']),
    },
    {
      accessorKey: 'Bill No',
      header: 'Bill No',
      size: 20,
    },
    {
      accessorKey: 'Token',
      header: 'Token',
      size: 30,
    },
    {
      accessorKey: 'Reason',
      header: 'Reason',
      size: 300,
      Cell: ({ cell }) => (
          <>
            <FormControl fullWidth>
              <Autocomplete
                  limitTags={2}
                  id="multiple-limit-tags"
                  options={reasons}
                  defaultValue={cell.row.original?.Reason}
                  renderInput={(params) => <TextField {...params} label={'Reasons'} />}
                  onChange={async (e, newValue) => {
                    const body = {
                      actionType: 'updateReason',
                      username: cell.row.original.Username,
                      reason: newValue,
                      panel: 'admin',
                      subPanel: 'adminManagement',
                    };
                    const response = await CreatePostAPICall(enqueueSnackbar, V2Management, body);
                    if (response) {
                      enqueueSnackbar('Reason Added Successfully.');
                      onHandleChangeText(cell, newValue);
                    }
                    setDataLoading(false);
                  }}
              />
            </FormControl>
          </>
      ),
    },
    {
      accessorKey: 'Transactions Actions',
      header: 'Transactions Actions',
      size: 20,
      Cell: (cell) => (
        <>
          <Button
            sx={{ marginRight: '10px' }}
            variant="outlined"
            onClick={() => {
              makeAccountEnableDisable('enable_ac', cell.row.original.Username);
            }}
          >
            Make Verified
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              makeAccountEnableDisable('disable_ac', cell.row.original.Username);
            }}
          >
            Mark as Billed
          </Button>
        </>
      ),
    },
  ];

  const getTodayTransactionData = async () => {
    setDataLoading(true);

    const body = {
      actionType: 'getAllBalanceTransactions',
      panel: 'admin',
      subPanel: 'adminManagement',
      subAction: 'defaultData',
    };

    setTodayTransactionData([]);
    const response = await CreatePostAPICall(enqueueSnackbar, V2Management, body);
    if (response) {
      setTodayTransactionData(response.data.body);
    }
    setDataLoading(false);
  };

  useEffect(() => {
    getTodayTransactionData();
  }, []);

  const getUserTXNTodayBalance = async (value, date) => {
    if (date[0] === '') {
      setTodayTransactionData([]);
      return null;
    }
    setDataLoading(true);
    const body = {
      username: user?.email ? user.email : localUser,
      filter: 'Date',
      actionType: 'getAllBalanceTransactions',
      start_date: date[0],
      end_date: date[1],
      panel: 'admin',
      subPanel: 'adminManagement',
    };
    const response = await axios.post(V2Management, body);
    if (response) {
      setTodayTransactionData(response.data.body);
    }
    setDataLoading(false);
  };

  const exportToCsvData = () => {
    if (todayTransactionData.length !== 0) {
      CreateExcelFile(todayTransactionData, 'Today transaction', true);
    }
  };

  return (
    <>
      <div style={{ diplay: 'flex' }}>
        <span style={{ marginRight: '20px' }}>Select Transaction Date :</span>
        <RangePicker
          className={'createDateRangePicker'}
          pupClassName={'createDateRangePicker'}
          format={'YYYY-MM-DD'}
          onChange={(value, event) => getUserTXNTodayBalance(value, event)}
          {...(!isLight && {
            className: 'date-picker-dark',
          })}
        />
      </div>
      <div style={{ textAlign: 'end', marginBottom: '15px' }}>
        <Button
          variant="contained"
          onClick={() => {
            exportToCsvData();
          }}
        >
          Export Excel
        </Button>
      </div>

      <DataGridTable columns={todayTXN} data={todayTransactionData} sorting isLoading={dataLoading} />
    </>
  );
};

export default TodayTransaction;
