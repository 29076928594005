/* eslint-disable */
import { Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import DataGridTable from '../../../../components/Common/DataGridTable';
import { CreatePostAPICall, V2Management } from '../../../../utils/apis';
import {CreateExcelFile, JSONToCSVConvertor} from '../../../../utils/utilities';

const UserNotification = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [userNotitficationData, setUserNotitficationData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);

  const userNotificationColumns = [
    {
      accessorKey: 'Id',
      header: 'Id',
      size: 20,
    },
    {
      accessorKey: 'Username',
      header: 'User Name',
      size: 40,
    },
    {
      accessorKey: 'Priority',
      header: 'Priority',
      size: 30,
    },
    {
      accessorKey: 'Date',
      header: 'Date',
      size: 40,
    },
    {
      accessorKey: 'Message',
      header: 'Message',
      size: 40,
    },
    {
      accessorKey: 'Fetch Type',
      header: 'Fetch Type',
      size: 30,
    },
    {
      accessorKey: 'Notification Actions',
      header: 'Notification Actions',
      size: 20,
      Cell: (cell) => (
        <Button
          variant="outlined"
          onClick={() => {
            removeNotifications(cell.row.original.Username, cell.row.original.Type);
          }}
        >
          Remove
        </Button>
      ),
    },
  ];

  const removeNotifications = async (Username, Type) => {
    const body = {
      username: Username,
      action: 'removeNotification',
      type: Type,
      panel: 'admin',
      subPanel: 'adminManagement',
      actionType: 'getUsersNotifications',
    };
    const response = await CreatePostAPICall(enqueueSnackbar, V2Management, body);
    if (response) {
      enqueueSnackbar('User Notification Deleted');
      setUserNotitficationData(response.data.body);
    }
    setDataLoading(false);
  };

  const getUserNotificationApiData = async () => {
    setDataLoading(true);

    const body = {
      actionType: 'getUsersNotifications',
      panel: 'admin',
      subPanel: 'adminManagement',
    };
    setUserNotitficationData([]);
    const response = await CreatePostAPICall(enqueueSnackbar, V2Management, body);
    if (response) {
      setUserNotitficationData(response.data.body);
    }
    setDataLoading(false);
  };

  useEffect(() => {
    getUserNotificationApiData();
  }, []);

  const exportToCsvData = () => {
    if (userNotitficationData.length !== 0) {
      CreateExcelFile(userNotitficationData, 'User Notification', true);
    }
  };

  return (
    <>
      <div style={{ textAlign: 'end', marginBottom: '15px' }}>
        <Button
          variant="contained"
          onClick={() => {
            exportToCsvData();
          }}
        >
          Export Excel
        </Button>
      </div>

      <DataGridTable columns={userNotificationColumns} data={userNotitficationData} sorting isLoading={dataLoading} />
    </>
  );
};

export default UserNotification;
