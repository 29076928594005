/* eslint-disable */
import {
  Button,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { Tree, TreeNode } from 'react-organizational-chart';
import DataGridTable from '../../../components/Common/DataGridTable';
import NoDataFound from '../../../components/Common/NoDataFound';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import Page from '../../../components/Page';
import useAuth from '../../../hooks/useAuth';
import useSettings from '../../../hooks/useSettings';
import urlConstants from '../../../routes/urlConstants';
import { CreatePostAPICall, MyProduct } from '../../../utils/apis';
import { StyledTableCell, SystemColor, getAccountsData } from '../../../utils/utilities';
import MasterSkuAutocomplete from './MasterSkuAutocomplete';

export default function MasterSkus() {
  // For inner table theme
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const { themeStretch } = useSettings();
  const { user } = useAuth();
  const localUser = window.localStorage.getItem('userEmailId');
  const { enqueueSnackbar } = useSnackbar();
  const [masterSkuData, setMasterSkuData] = useState([]);
  const [isMasterSkuTableLoading, setIsMasterSkuTableLoading] = useState(false);
  const [allAccountTypeData, setAllAccountTypeData] = useState({
    meesho: [],
    flipkart: [],
    glowroad: [],
  });

  const [masterSkuName, setMasterSkuName] = useState('');

  // define colums of table for master ksu data
  const columns = [
    {
      accessorKey: 'sort_key',
      header: 'SKU',
      size: '10',
    },
    {
      accessorKey: 'linked_skus',
      header: 'Linked SKUs',
      size: '10',
    },
    {
      accessorKey: 'inventory',
      header: 'Inventory',
      size: '10',
    },
  ];

  // default api call set master sku data and get all account ser master sku
  useEffect(async () => {
    setIsMasterSkuTableLoading(true);
    const body = {
      username: user?.email ? user.email : localUser,
      subPanel: 'masterSku',
      actionType: 'get',
    };
    const response = await CreatePostAPICall(enqueueSnackbar, MyProduct, body);
    if (response) {
      if (response?.data?.body) {
        setMasterSkuData(response.data.body);
        setIsMasterSkuTableLoading(false);
      } else {
        setIsMasterSkuTableLoading(false);
      }
    }

    const data = await getAccountsData();
    const tempData = {
      flipkart: [],
      meesho: [],
      glowroad: [],
    };
    for (let index = 0; index < data.length; index++) {
      const element = data[index];

      const newObj = {
        TpMailId: element.Account.Email,
        SupplierId: element.Account.SupplierId,
        searchSkuName: '',
        isSearch: false,
        skuNameOptions: [],
      };

      if (element.AcType === 'flipkart') {
        newObj.AcType = 'flipkart';
        tempData.flipkart.push(newObj);
      }

      if (element.AcType === 'meesho') {
        newObj.AcType = 'meesho';
        tempData.meesho.push(newObj);
      }
      if (element.AcType === 'glowroad') {
        newObj.AcType = 'glowroad';
        tempData.glowroad.push(newObj);
      }
    }
    setAllAccountTypeData(tempData);
  }, []);

  // add master sku in table and api call
  const addMasterSkuName = async () => {
    if (masterSkuName.length > 0) {
      const body = {
        username: user?.email ? user.email : localUser,
        subPanel: 'masterSku',
        actionType: 'add',
        masterSku: masterSkuName,
      };
      const response = await CreatePostAPICall(enqueueSnackbar, MyProduct, body);
      if (response) {
        enqueueSnackbar(response.data.body);
        const tempData = [...masterSkuData];

        const newObj = {
          sort_key: masterSkuName,
          linked_skus: 0,
          inventory: 0,
          part_key: 'MasterSkus',
        };
        tempData.push(newObj);
        setMasterSkuData(tempData);
        setMasterSkuName('');
      }
    } else {
      enqueueSnackbar('Please enter master sku name', { variant: 'error' });
    }
  };


  
  // tree node table componant

  const TreeNodeTable=({title,acType,row})=>{
    return <TreeNode label={<div>{title}</div>}>
    <TreeNode
      label={
        <div>
          <TableContainer component={Paper} >
            <Table sx={{ minWidth: 450 }} aria-label="customized table">
              
              <TableBody align="center">
                {allAccountTypeData[acType].map((value, index) => {
                  return (
                    <div>
                    <TableHead>
                <TableRow>
                  <StyledTableCell align="center">
                  <p>{value.TpMailId} | {value.SupplierId}</p>
                  </StyledTableCell>
                </TableRow>
              </TableHead>
                    <StyledTableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={index}>
                      
                      <StyledTableCell align="center" style={{ width: 300 }}>
                        <MasterSkuAutocomplete
                          TpMailId={value.TpMailId}
                          SupplierId={value.SupplierId}
                          AcType={value.AcType}
                          index={index}
                          masterSkuName={row.original.sort_key}
                          masterSkuData={masterSkuData}
                          onSetMasterSku={(data) => {
                            // console.log(masterSkuData);
                            setMasterSkuData(data);
                            // console.log(2);
                            
                          }}
                        />
                      </StyledTableCell>
                    </StyledTableRow>
                    </div>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      }
    />
  </TreeNode>
  }



  // expnad table data show custom data tree
  const expandRowComponent = (row) => (
    <div>
      <Tree label={<div>{row.original?.sort_key}</div>}>
        
        {allAccountTypeData.meesho.length > 0 && (
          <TreeNodeTable title={"Meesho"} row={row} acType={"meesho"} />
        )}

        {allAccountTypeData.flipkart.length > 0 && (
          <TreeNodeTable title={"Flipkart"} row={row} acType={"flipkart"} />
        )}

        {allAccountTypeData.glowroad.length > 0 && (
          <TreeNodeTable title={"Glowroad"} row={row} acType={"glowroad"} />
        )}
      </Tree>
    </div>
  );

  return (
    <Page title="MasterSkus">
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <HeaderBreadcrumbs
          heading="My Products"
          links={[
            { name: 'Dashboard', href: urlConstants.HOME_PAGE },
            { name: 'My Products', href: urlConstants.MASTER_SKUS_PAGE },
            { name: 'Master Skus' },
          ]}
        />
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={6}>
            <FormControl style={{ width: '100%' }}>
              <TextField
                id="outlined-basic-2"
                label="Enter Master SKU Name"
                variant="outlined"
                value={masterSkuName}
                onChange={(event) => {
                  setMasterSkuName(event.target.value);
                }}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6} lg={6} sx={{ alignSelf: 'center' }}>
            <Button
              variant="contained"
              onClick={() => {
                addMasterSkuName();
              }}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={3} sx={{ paddingTop: '20px' }}>
          <Grid item xs={12}>
            <>
              {masterSkuData?.length !== 0 ? (
                <>
                  <DataGridTable
                    columns={columns}
                    data={masterSkuData}
                    isLoading={isMasterSkuTableLoading}
                    renderDetailPanel={expandRowComponent}
                  />
                </>
              ) : (
                <div>
                  <Divider />
                  <br />
                  {isMasterSkuTableLoading === true ? (
                    <div style={{ width: '100%', textAlign: 'center' }}>
                      <CircularProgress sx={{ color: SystemColor.originColor }} size={24} color="inherit" />
                    </div>
                  ) : (
                    <NoDataFound message={'Please Select An Account To See Product Details'} />
                  )}
                </div>
              )}
            </>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
