/* eslint-disable */
import {useSnackbar} from 'notistack';
import React, {useState, useEffect} from 'react';
import {LoadingButton} from '@mui/lab';
import {Box, Grid, Card, Tooltip, IconButton, InputAdornment, TextField, CircularProgress} from '@mui/material';
import xlsx from 'json-as-xlsx';
import DataGridTable from '../../../components/Common/DataGridTable';
import useAuth from '../../../hooks/useAuth';
import Iconify from '../../../components/Iconify';
import {CreatePostAPICall, envType, UserProfile} from '../../../utils/apis';
import {
    SystemColor,
    handleOnClickCopy,
    GetTodayDateTime,
    IOSSwitch,
    isDesktopEnvironment
} from '../../../utils/utilities';
import moment from "moment/moment";
const getAccountActiveData=(userData)=>{

    let isExpired = false;
    let lastUsedDate = null
    let lastActiveDate = null
    let diffActiveDate = null
    let lastReceivedDate = null
    let diffReceivedDate = null
    let lastBalanceUsedDate = null
    let diffBalanceUsedDate = null
    let inactiveFrom = 0
    const {Last_Active_Date, Last_Received_Date, Last_Balance_Used_Date} = userData
    const todayDate = moment()

    if(Last_Active_Date){
        lastActiveDate = moment(Last_Active_Date.split("T")[0].split(" ")[0])
        diffActiveDate = todayDate.diff(lastActiveDate, 'days')
    }
    if(Last_Received_Date){
        lastReceivedDate = moment(Last_Received_Date.split("T")[0].split(" ")[0])
        diffReceivedDate = todayDate.diff(lastReceivedDate, 'days')
        inactiveFrom = diffReceivedDate
        lastUsedDate = Last_Received_Date
    }else {
        isExpired = true
    }
    if(Last_Balance_Used_Date){
        lastBalanceUsedDate = moment(Last_Balance_Used_Date.split("T")[0].split(" ")[0])
        diffBalanceUsedDate = todayDate.diff(lastBalanceUsedDate, 'days')
        if(lastReceivedDate>diffBalanceUsedDate){
            inactiveFrom = diffBalanceUsedDate
            lastUsedDate = Last_Balance_Used_Date
        }
    }else {
        isExpired = true
    }
    // console.log("diffActiveDate ",diffActiveDate,"diffReceivedDate",diffReceivedDate,"diffBalanceUsedDate",diffBalanceUsedDate)
    if(diffBalanceUsedDate > 90){
        isExpired = true
    }
    if(inactiveFrom === 99999999999){
        inactiveFrom = 0
    }

    return {isExpired,inactiveFrom,diffActiveDate,diffReceivedDate,diffBalanceUsedDate,lastActiveDate,lastReceivedDate,lastBalanceUsedDate,lastUsedDate}
}

export default function AccountGeneral() {
    const {user} = useAuth();
    const localUser = window.localStorage.getItem('userEmailId');
    const {enqueueSnackbar} = useSnackbar();
    const [isEditMobileNo, setIsEditMobileNo] = useState(false);
    const [isEditBusinessName, setIsEditBusinessName] = useState(false);
    const [isEditGSTNo, setIsEditGSTNo] = useState(false);
    const [isEditOfficeAddress, setIsEditOfficeAddress] = useState(false);
    const [isEditMainAddress, setIsEditMainAddress] = useState(false);
    const [userData, setUserData] = useState({});
    const [isTransactionTableLoading, setIsTransactionTableLoading] = useState(false);
    const [transactionData, setTransactionData] = useState([]);
    const [isWhatsAppEnable, setIsWhatsAppEnable] = useState(false);

    const {isExpired,inactiveFrom,lastUsedDate,...others} = getAccountActiveData(userData)

    // console.log("others",others)

    // console.log("isExpired",isExpired,"inactiveFrom",inactiveFrom,"lastUsedDate",lastUsedDate)
    const getFormattedTransactionData = (data) => {
        const transactionData = [];
        data.forEach((item) => {
            const transData = {};
            transData.index = transactionData.length + 1;
            transData.token = item[0] ? item[0] : ' - ';
            transData.account = item[1] ? item[1] : ' - ';
            transData.date = item[2] ? item[2] : ' - ';
            transData.type = item[3] ? item[3] : ' - ';
            transData.executed_time = item[4] ? item[4] : ' - ';

            let tempStatus = 'In Progress';
            if (item[4] && item[4].toLowerCase() === 'error') tempStatus = 'Error';
            else if (item[4]) tempStatus = 'Done';

            transData.status = tempStatus;
            transData.total_orders = item[5];
            transData.billed_orders = item[6] ? item[6] : ' - ';
            // transData.before = item[7] ? item[7] : ' - ';
            transData.after = item[8] ? Number(item[8] / 0.6).toFixed(2) : ' - ';
            transactionData.push(transData);
        });
        const newTempData = transactionData.sort((a, b) => b.token - a.token);
        setTransactionData(newTempData);
    };

    const checkIsTimeOut = (data) => {
        // console.log(data)
        const providedDate = new Date(data.date);

        const currentDate = new Date();

        const timeDifference = currentDate - providedDate;

        const minutesDifference = timeDifference / (1000 * 60);
        // console.log("minutesDifference",minutesDifference)

        if(data["status"] === "In Progress")
        {if (minutesDifference > 18) {
            return "Error";
        } else {
            return  "IN PROGRESS";
        }
        }else{
            return "Done"
        }
    }

    const columns =     [
        {
            accessorKey: 'index',
            header: 'Index',
            size: 10,
        },
        {
            accessorKey: 'token',
            header: 'Token',
            size: 10,
        },
        {
            accessorKey: 'account',
            header: 'Account',
            size: 10,
        },
        {
            accessorKey: 'date',
            header: 'Date',
            size: 10,
        },
        {
            accessorKey: 'type',
            header: 'Type',
            size: 10,
        },
        {
            accessorKey: 'status',
            header: 'Status',
            size: 10,
            Cell: ({cell}) => {
                const complete = checkIsTimeOut(cell.row.original);
                // console.log("complete",complete)
                return (
                    <div style={{width: '100%', textAlign: 'left'}}>
                        {cell.getValue().toLowerCase() === 'error' ? (
                            <p style={{color: SystemColor.error}}>Error</p>
                        ) : (
                            <>
                                {complete === 'Done' && <p style={{color: SystemColor.success}}>Done</p>}
                                {complete === 'IN PROGRESS' && <CircularProgress sx={{color: SystemColor.originColor}} size={24}
                                                                                 color="inherit"/>}
                                {complete === 'Error' &&<p style={{color: SystemColor.error}}>Error</p>}

                            </>
                        )}
                    </div>
                )
            },
        },
        {
            accessorKey: 'total_orders',
            header: 'Sync Orders',
            size: 10,
        },
        // {
        //     accessorKey: 'before',
        //     header: 'Before Orders',
        //     size:10,
        // },
        {
            accessorKey: 'billed_orders',
            header: 'Billed Orders',
            size: 10,
        },
        {
            accessorKey: 'after',
            header: 'Available Orders',
            size: 10,
        },
    ];

    const getUserData = async () => {
        setIsTransactionTableLoading(true);
        const response = await CreatePostAPICall(enqueueSnackbar, UserProfile, {
            username: user?.email ? user.email : localUser,
            action: 'getUserInfo',
        });
        setUserData({});
        if (response) {
            // For Mobile Number Dynamic : 10 Digits
            response.data.body.Mobile_No = response.data.body.Mobile_No.slice(-10);
            setUserData(response.data.body);
            if (response.data.body.WAppMessageEnabled === 'True') {
                setIsWhatsAppEnable(true);
            }
        }
        await getUserTransactions();
    };

    const getUserTransactions = async () => {
        setIsTransactionTableLoading(true);
        const response = await CreatePostAPICall(enqueueSnackbar, UserProfile, {
            username: user?.email ? user.email : localUser,
            action: 'getHistory',
        });
        if (response) {
            getFormattedTransactionData(response.data.body);
        }
        setIsTransactionTableLoading(false);
    };

    const updateUserDetail = async () => {
        const userDataToBeSaved = userData;

        console.log('userData', userDataToBeSaved);

        const updateUserDetail = async () => {
            const userDataToBeSaved = userData;

            console.log('userData', userDataToBeSaved);

            if (!userDataToBeSaved.Mobile_No || userDataToBeSaved.Mobile_No.length !== 10) {
                enqueueSnackbar('Invalid Mobile Number', {variant: 'error'});
                return;
            }
            if (!userDataToBeSaved.Username || userDataToBeSaved.Username.length <= 0) {
                enqueueSnackbar('Invalid Username', {variant: 'error'});
                return;
            }
            if (!userDataToBeSaved.Gst_No || userDataToBeSaved.Gst_No.length <= 0) {
                enqueueSnackbar('Invalid Gst No', {variant: 'error'});
                return;
            }
            if (!userDataToBeSaved.Legal_Name || userDataToBeSaved.Legal_Name.length <= 0) {
                enqueueSnackbar('Invalid Legal Name', {variant: 'error'});
                return;
            }
            if (!userDataToBeSaved.Address || userDataToBeSaved.Address.length <= 0) {
                enqueueSnackbar('Invalid Address', {variant: 'error'});
                return;
            }
            if (!userDataToBeSaved.Office_Address || userDataToBeSaved.Office_Address.length <= 0) {
                enqueueSnackbar('Invalid Office Address', {variant: 'error'});
                return;
            }
            if (!userDataToBeSaved.Business_Name || userDataToBeSaved.Business_Name.length <= 0) {
                enqueueSnackbar('Invalid Business Name', {variant: 'error'});
                return;
            }
            // Continue with the rest of your function logic
        };


        const responseUpdateGstInfo = await CreatePostAPICall(
            enqueueSnackbar,
            UserProfile,
            {
                username: userDataToBeSaved?.Username,
                Gst_No: userDataToBeSaved.Gst_No ? userDataToBeSaved.Gst_No : '-',
                Address: userDataToBeSaved.Address || '-',
                Mobile_No: userDataToBeSaved.Mobile_No ? userDataToBeSaved.Mobile_No : '-',
                Office_Address: userDataToBeSaved.Office_Address ? userDataToBeSaved.Office_Address : '-',
                Legal_Name: userDataToBeSaved.Business_Name ? userDataToBeSaved.Business_Name : '-',
                Business_Name: userDataToBeSaved.Business_Name ? userDataToBeSaved.Business_Name : '-',
                action: 'updateGstInfo',
            },
            'Update successfully'
        );
        if (responseUpdateGstInfo) {
            setIsEditMobileNo(false);
            setIsEditBusinessName(false);
            setIsEditGSTNo(false);
            setIsEditOfficeAddress(false);
            setIsEditMainAddress(false);
        }
    };

    const exportToCsvReported = () => {
        if (transactionData.length !== 0) {
            const data = [
                {
                    sheet: 'Transaction Data',
                    columns: [
                        {label: 'Token', value: 'token'},
                        {label: 'Account', value: 'account'},
                        {label: 'Date', value: 'date'},
                        {label: 'Type', value: 'type'},
                        {label: 'Status', value: 'status'},
                        {label: 'Total Orders', value: 'total_orders'},
                        {label: 'Billed Orders', value: 'billed_orders'},
                        {label: 'Before', value: 'before'},
                        {label: 'After', value: 'after'},
                    ],
                    content: transactionData,
                },
            ];

            const settings = {
                fileName: `Transaction Data ${GetTodayDateTime()}`,
                extraLength: 6,
                writeOptions: {},
            };

            xlsx(data, settings);
        }
    };

    const handleMobileNoOnChange = (event) => {
        setUserData({...userData, Mobile_No: event.target.value});
    };

    const handleGSTNoOnChange = (event) => {
        setUserData({...userData, Gst_No: event.target.value});
    };

    const handleOfficeAddressOnChange = (event) => {
        setUserData({...userData, Office_Address: event.target.value});
    };

    useEffect(() => {
        getUserData();
    }, []);

    const updateWPService = async (event) => {
        const body = {
            username: user?.email ? user.email : localUser,
            Mobile_No: userData?.Mobile_No,
            WAppMessageEnabled: event.target.checked ? event.target.checked : isWhatsAppEnable,
            action: 'updateWAppNumber',
        };
        setIsWhatsAppEnable(event.target.checked);
        await CreatePostAPICall(enqueueSnackbar, UserProfile, body, true);
        setIsEditMobileNo(false);
    };


    return (
        <>
            <Grid item xs={12} md={12}>
                <Card sx={{p: 3}}>
                    <Box
                        sx={{
                            display: 'grid',
                            rowGap: 3,
                            columnGap: 2,
                            gridTemplateColumns: {xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)'},
                        }}
                    >
                        <TextField
                            value={userData?.Username ? userData?.Username : ''}
                            variant="outlined"
                            disabled
                            label="Email Address & User Name"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Tooltip title="Copy">
                                            <IconButton
                                                onClick={() => handleOnClickCopy(userData?.Username, enqueueSnackbar)}>
                                                <Iconify icon={'eva:copy-fill'} width={24} height={24}/>
                                            </IconButton>
                                        </Tooltip>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <TextField
                            value={userData?.Business_Name ? userData?.Business_Name : ''}
                            variant="outlined"
                            label="Business Name"
                            disabled={(!isEditBusinessName)}
                            onChange={(event)=>{
                                setUserData({...userData, Business_Name: event.target.value})
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {!isEditBusinessName ? (
                                            <>
                                                <Tooltip title="Edit">
                                                    <IconButton
                                                        onClick={() => {
                                                            setIsEditBusinessName(true);
                                                        }}
                                                    >
                                                        <Iconify icon={'bx:edit'} width={24} height={24}/>
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Copy">
                                                    <IconButton
                                                        onClick={() => handleOnClickCopy(userData?.Business_Name, enqueueSnackbar)}>
                                                        <Iconify icon={'eva:copy-fill'} width={24} height={24}/>
                                                    </IconButton>
                                                </Tooltip>
                                            </>
                                        ) : (
                                            <Tooltip title="Save">
                                                <IconButton onClick={updateUserDetail}>
                                                    <Iconify icon={'fluent:save-20-regular'} width={24} height={24}/>
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <TextField
                            style={{width: '100%'}}
                            value={userData?.Id ? userData?.Id : 0}
                            variant="outlined"
                            disabled
                            label="Client Id"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Tooltip title="Copy">
                                            <IconButton
                                                onClick={() => handleOnClickCopy(userData?.Id, enqueueSnackbar)}>
                                                <Iconify icon={'eva:copy-fill'} width={24} height={24}/>
                                            </IconButton>
                                        </Tooltip>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <TextField
                            value={userData?.Gst_No ? userData?.Gst_No : ''}
                            variant="outlined"
                            label="GST Number"
                            disabled={!isEditGSTNo}
                            onChange={(event)=>{
                                setUserData({...userData, Gst_No: event.target.value})
                            }}
                            onChange={handleGSTNoOnChange}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {!isEditGSTNo ? (
                                            <>
                                                <Tooltip title="Edit">
                                                    <IconButton
                                                        onClick={() => {
                                                            setIsEditGSTNo(true);
                                                        }}
                                                    >
                                                        <Iconify icon={'bx:edit'} width={24} height={24}/>
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Copy">
                                                    <IconButton
                                                        onClick={() => handleOnClickCopy(userData?.Gst_No, enqueueSnackbar)}>
                                                        <Iconify icon={'eva:copy-fill'} width={24} height={24}/>
                                                    </IconButton>
                                                </Tooltip>
                                            </>
                                        ) : (
                                            <Tooltip title="Save">
                                                <IconButton onClick={updateUserDetail}>
                                                    <Iconify icon={'fluent:save-20-regular'} width={24} height={24}/>
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <div>
                            <TextField
                                style={{width: '100%',webKitTextFillColor:"red",webkitTextFillColor:"red"  }}
                                value={isExpired ? ("Expired"): (userData?.Account_Balance ? Math.trunc(userData?.Account_Balance / 0.6) : 0)}
                                variant="outlined"
                                color={"error"}
                                error={isExpired}
                                disabled
                                label="Available Orders"
                                InputProps={{
                                    style: { color: 'red',webKitTextFillColor:"red" },
                                    // startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Tooltip title="Copy">
                                                <IconButton
                                                    onClick={() => handleOnClickCopy(Math.trunc(userData?.Account_Balance / 0.6), enqueueSnackbar)}
                                                >
                                                    <Iconify icon={'eva:copy-fill'} width={24} height={24}/>
                                                </IconButton>
                                            </Tooltip>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <div style={{paddingLeft: '12px', paddingTop: '10px', fontSize: '12px'}}>
                                <span style={{color:"red"}}> <strong>*Please note:</strong> </span> Available orders are not subject to transfer to another account and non-refundable.
                            </div>
                        </div>

                        <TextField
                            value={userData?.Office_Address ? userData?.Office_Address : ''}
                            variant="outlined"
                            multiline
                            disabled={!isEditOfficeAddress}
                            onChange={(event)=>{
                                setUserData({...userData, Office_Address: event.target.value})
                            }}
                            rows={3}
                            label="Office Address"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {!isEditOfficeAddress ? (
                                            <>
                                                <Tooltip title="Edit">
                                                    <IconButton
                                                        onClick={() => {
                                                            setIsEditOfficeAddress(true);
                                                        }}
                                                    >
                                                        <Iconify icon={'bx:edit'} width={24} height={24}/>
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Copy">
                                                    <IconButton
                                                        onClick={() => handleOnClickCopy(userData?.Office_Address, enqueueSnackbar)}>
                                                        <Iconify icon={'eva:copy-fill'} width={24} height={24}/>
                                                    </IconButton>
                                                </Tooltip>
                                            </>
                                        ) : (
                                            <Tooltip title="Save">
                                                <IconButton onClick={updateUserDetail}>
                                                    <Iconify icon={'fluent:save-20-regular'} width={24} height={24}/>
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Grid container>
                            <Grid item xs={12} style={{paddingBottom: '10px'}}>
                                <TextField
                                    value={userData?.Mobile_No ? userData?.Mobile_No : ''}
                                    fullWidth
                                    variant="outlined"
                                    label="WhatsApp Number"
                                    type="number"
                                    disabled={!isEditMobileNo}
                                    onChange={(event)=>{
                                        setUserData({...userData, Mobile_No: event.target.value})
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Iconify icon={'logos:whatsapp-icon'} width={25} height={25}/>
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {!isEditMobileNo ? (
                                                    <>
                                                        <IOSSwitch sx={{m: 1}} checked={isWhatsAppEnable}
                                                                   onChange={updateWPService}/>
                                                        <Tooltip
                                                            title="Edit"
                                                            onClick={() => {
                                                                setIsEditMobileNo(true);
                                                            }}
                                                        >
                                                            <IconButton
                                                                onClick={() => {
                                                                    setIsEditMobileNo(true);
                                                                }}
                                                            >
                                                                <Iconify icon={'bx:edit'} width={24} height={24}/>
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Copy">
                                                            <IconButton
                                                                onClick={() => handleOnClickCopy(userData?.Mobile_No, enqueueSnackbar)}>
                                                                <Iconify icon={'eva:copy-fill'} width={24} height={24}/>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </>
                                                ) : (
                                                    <Tooltip title="Save">
                                                        <IconButton onClick={updateWPService}>
                                                            <Iconify icon={'fluent:save-20-regular'} width={24} height={24}/>
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={6} >
                                        <TextField
                                            value={lastUsedDate||""}
                                            variant="outlined"
                                            label="Last active date"
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            value={`${inactiveFrom} days `}
                                            variant="outlined"
                                            label="Last activity before"
                                            disabled
                                        />
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>

                        <TextField
                            value={userData?.Address ? userData?.Address : ''}
                            variant="outlined"
                            disabled={!isEditMainAddress}
                            multiline
                            rows={3}
                            onChange={(event)=>{
                                setUserData({...userData, Address: event.target.value})
                            }}
                            label="Main Address"
                            InputProps={{
                                endAdornment: (

                                    <InputAdornment position="end">
                                        {!isEditMainAddress ? (
                                            <>
                                                <Tooltip title="Edit">
                                                    <IconButton
                                                        onClick={() => {
                                                            setIsEditMainAddress(true);
                                                        }}
                                                    >
                                                        <Iconify icon={'bx:edit'} width={24} height={24}/>
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Copy">
                                                    <IconButton
                                                        onClick={() => handleOnClickCopy(userData?.Address, enqueueSnackbar)}>
                                                        <Iconify icon={'eva:copy-fill'} width={24} height={24}/>
                                                    </IconButton>
                                                </Tooltip>
                                            </>
                                        ) : (
                                            <Tooltip title="Save">
                                                <IconButton onClick={updateUserDetail}>
                                                    <Iconify icon={'fluent:save-20-regular'} width={24} height={24}/>
                                                </IconButton>
                                            </Tooltip>
                                        )}

                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                </Card>
            </Grid>

            <Grid sx={{pt: 2}} item xs={12} md={12}>
                <div style={{width: '100%', textAlign: 'right', paddingBottom: '30px'}}>
                    <LoadingButton
                        sx={{
                            mt: 2,
                            ml: 2.5,
                        }}
                        variant="contained"
                        onClick={getUserTransactions}
                    >
                        Refresh
                    </LoadingButton>
                    <LoadingButton
                        sx={{
                            mt: 2,
                            ml: 2.5,
                        }}
                        variant="contained"
                        onClick={() => {
                            exportToCsvReported();
                        }}
                    >
                        Export Excel
                    </LoadingButton>
                </div>
                <DataGridTable columns={columns} data={transactionData} isLoading={isTransactionTableLoading}/>
            </Grid>
        </>
    );
}
