export const CLIENT_SALES_REPORT = "clients-sales-reports";
export const REGION = "ap-south-1";
export const IDENTITY_POOL_ID = "ap-south-1:b800dd42-ec62-4fa7-9887-64e5521ac07d";
export const UTILITY_LABEL_PROCESS = "buizzmaster-meesho-label-process";

export const BUISSMASTER_ASSETS = "buizzmaster-meesho-label-process";
export const ACCEPT_LIST_MEESHO = "buissmaster-excels";
export const UPLOAD_MANIFEST = "user-manifest-data";
export const USER_ORDER_LABELS = "user-order-labels";
export const BILL_BUCKET = "buissmaster-client-billes";
export const RECEIVED_REPORTED_DATA = "receivedreportdata";
export const PAYMENT_UPLOAD_BT_ACCOUNT = "payment-upload-by-accounts";


