export const REACT_APP_AWS_COGNITO_USER_POOL_ID = process.env.REACT_APP_ENV.includes('ADMIN')
  ? 'ap-south-1_uniRp5ZUL'
  : 'ap-south-1_9a5rM97CG';
export const REACT_APP_AWS_COGNITO_CLIENT_ID = process.env.REACT_APP_ENV.includes('ADMIN')
  ? '65l568thlg43h41ipo3orijp3v'
  : '12k5uu0qev2a03ppkjnn9f588m';

export const COGNITO_API = {
  userPoolId: REACT_APP_AWS_COGNITO_USER_POOL_ID,
  clientId: REACT_APP_AWS_COGNITO_CLIENT_ID,
};

export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 88,
  DASHBOARD_DESKTOP_HEIGHT: 92,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const NAVBAR = {
  NEED_HELP_WIDTH: 300,
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

export const defaultSettings = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themeColorPresets: 'default',
  themeLayout: 'horizontal',
  themeStretch: false,
};
