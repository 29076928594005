/* eslint-disable */
export const ShopdeckValidateUploadedFile = (header, type) => {
  if (type === 'Order') {
    try {
      if (
        header[0] === 'Order Date' &&
        header[2] === 'AWB NO.' &&
        header[3] === 'Order ID' &&
        header[10] === 'Courier Partner' &&
        header[13] === 'Selling Price' &&
        header[14] === 'Product Name' &&
        header[16] === 'Sku ID'
      ) {
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  }

  if (type === 'RTNIn') {
    try {
      if (
        header[0] === 'Initiated Date' &&
        header[1] === 'Return Type' &&
        header[2] === 'Order ID' &&
        header[4] === 'AWB' &&
        header[5] === 'Shipping Partner' &&
        header[7] === 'Order Value' &&
        header[8] === 'Reason' &&
        header[10] === 'Status'
      ) {
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  }

  if (type === 'RTNDL') {
    try {
      if (
        header[0] === 'Initiated Date' &&
        header[1] === 'Return Type' &&
        header[2] === 'Order ID' &&
        header[4] === 'AWB' &&
        header[5] === 'Shipping Partner' &&
        header[7] === 'Order Value' &&
        header[8] === 'Reason' &&
        header[9] === 'Expected Delivery Date' &&
        header[10] === 'Status'
      ) {
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  }
  if (type === 'Inventory') {
    try {
      if (
        header[2] === 'Name' &&
        header[3] === 'Sku Id' &&
        header[4] === 'Selling Price' &&
        header[6] === 'Cost Price' &&
        header[13] === 'Image 1' &&
        header[26] === 'Size' &&
        header[27] === 'Colour'
      ) {
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  }

  if (type === 'COD Remittance') {
    try {
      if (header[0] === 'Settlement Date' && header[1] === 'Order ID' && header[5] === 'COD Amount') {
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  }

  if (type === 'Shipping Charge') {
    try {
      if (header[0] === 'Settlement Date' && header[1] === 'Order ID' && header[3] === 'Amount') {
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  }
  if (type === 'Shopdeck Service Fees') {
    try {
      if (header[0] === 'Settlement Date' && header[1] === 'Order ID' && header[2] === 'Amount') {
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  }
  if (type === 'Disputes') {
    try {
      if (header[0] === 'Settlement Date' && header[2] === 'Amount' && header[4] === 'Action ID') {
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  }
  if (type === 'Marketing Spends') {
    try {
      if (header[0] === 'Settlement Date' && header[3] === 'Amount') {
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  }
  if (type === 'Return Refunds') {
    try {
      if (header[0] === 'Settlement Date' && header[1] === 'Order ID' && header[3] === 'Amount') {
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  }
};
