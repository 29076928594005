/* eslint-disable */
export const GlowroadValidateUploadedFile = (header, type,subtype) => {
  if (type === 'Order') {
    try {
      if (
        header[0] === 'Order Id' &&
        header[1] === 'Created Date' &&
        header[6] === 'Size' &&
          header[5] === 'Style Code' &&
        header[12] === 'Logistics Partner' &&
        header[13] === 'AWB'
      ) {
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  }

  if (type === 'shippedOrDeliveredOrCancelledOrder') {
    try {
      if (
        header[0] === 'Order Id' &&
        header[1] === 'Created Date' &&
        header[2] === 'Shipped Date' &&
        header[6] === 'Size' &&
        header[5] === 'Style Code' &&
        header[12] === 'Logistics Partner' &&
        header[13] === 'AWB'
      ) {
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  }

  if (type === 'RTN') {
    try {
      if (
        header[0] === 'Order Id' &&
        header[1] === 'Created Date' &&
        header[6] === 'Size' &&
          header[5] === 'Style Code' &&
        header[11] === 'Status' &&
        header[12] === 'Logistics Partner' &&
        header[13] === 'AWB'
      ) {
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  }

  if (type === 'Payment') {
    if(subtype==='managerExcel'){
      try {
        if (
          header[0] === 'SUB_ORDER_NO' &&
          header[5] === 'payment date' &&
          header[6] === 'amount paid'
        ) {
          return true;
        }
        return false;
      } catch (e) {
        return false;
      }
    }else{
      try {
        if (
          header[0] === 'Order Id' &&
          header[2] === 'Product SKU' &&
          header[3] === 'Order Date' &&
          header[4] === 'Delivery Date' &&
          header[7] === 'Fulfillment Status' &&
          header[9] === 'Payout Cycle report Date' &&
          header[18] === 'GR Platform Fee (Inclusive of GST)' &&
          header[21] === 'RTO Shipping Charges(Incl. gst)' &&
          header[23] === 'Return Shipping Charges' &&
          header[24] === 'Wrong Product Penalty' &&
          header[25] === 'Late Shipment Penalty' &&
          header[26] === 'Late Cancellation Penalty' &&
          header[33] === 'Final Payout (Sum R to AG)'
        ) {
          return true;
        }
        return false;
      } catch (e) {
        return false;
      }
    }

  }

  if (type === 'Inventory') {
    try {
      if (
        header[1] === 'Style Code' &&
        header[2] === 'SKU ID' &&
        header[5] === 'L1 Product Category' &&
        header[9] === 'Size' &&
        header[11] === 'Selling Price'
      ) {
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  }
};
