/* eslint-disable */
import { Box, Card, Grid, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

const SentCourier = ({ courierData }) => {
  let totalCount = 0;
  let finalTotalCount = 0;

  let totalOrder = 0;
  let finalTotalOrder = 0;

  {
    Object.keys(courierData).map((itemKey, index) => {
      let tempNumber = 0;
      let tempOrderNum = 0;

      if (index > 0) {
        tempNumber = Number(courierData[itemKey].split('/')[0]);
        finalTotalCount = totalCount += tempNumber;

        tempOrderNum = Number(courierData[itemKey].split('/')[1]);
        finalTotalOrder = totalOrder += tempOrderNum;
      }
    });
  }

  return (
    <>
      <Card sx={{ display: 'flex', p: 3 }}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="subtitle1">{courierData.name}</Typography>

          <Stack direction="row" alignItems="center" spacing={1} sx={{ mt: 2, mb: 1 }}>
            <div
              style={{
                width: '90%',
              }}
            >
              <Typography component="span" variant="h3">
                {`${finalTotalCount}/${finalTotalOrder}`}
              </Typography>
            </div>
          </Stack>
          <Grid
            style={{
              marginTop: '5px',
            }}
            container
            spacing={3}
          >
            {Object.keys(courierData).map((itemKey, index) => {
              return (
                index > 0 && (
                  <Grid item xs={12} md={12} lg={12} xl={6} sm={12}>
                    <div style={{ textAlign: 'center' }}>
                      <h4>{courierData[itemKey]}</h4>
                      <p style={{ wordBreak: 'break-word' }}>{itemKey}</p>
                    </div>
                  </Grid>
                )
              );
            })}
          </Grid>
        </Box>
      </Card>
    </>
  );
};

export default SentCourier;
