import PropTypes from 'prop-types';
import React, {useCallback, useState} from 'react';
// @mui
import {useTheme} from '@mui/material/styles';
import {Checkbox, TableRow, TableCell, Typography, Stack, Link, MenuItem, Avatar, Button} from '@mui/material';
import {useSnackbar} from 'notistack';
import createAvatar from "../../../../../utils/createAvatar";
import TableMoreMenu from "./tableMoreMenu";
import Iconify from "../../../../../components/Iconify";
import {downloadS3File, uploadFileS3} from "../../../../../utils/utilities";
import {BILL_BUCKET} from "../../../../../utils/Constants";
// utils
// ----------------------------------------------------------------------

InvoiceTableRow.propTypes = {
    row: PropTypes.object.isRequired,
    selected: PropTypes.bool,
    onSelectRow: PropTypes.func,
    onViewRow: PropTypes.func,
    onEditRow: PropTypes.func,
    onDeleteRow: PropTypes.func,
};
function numberToWords(num) {
    const units = ['', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
    const teens = ['', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];
    const tens = ['', 'ten', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];

    if (num === 0) return 'zero';

    function convertBelowThousand(num) {
        let result = '';
        if (num >= 100) {
            result += `${units[Math.floor(num / 100)]  } hundred `;
            num %= 100;
        }
        if (num >= 20) {
            result += `${tens[Math.floor(num / 10)]  } `;
            num %= 10;
        }
        if (num >= 10 && num <= 19) {
            result += `${teens[num - 10]  } `;
            num = 0; // Teens already include the unit, so no need to process the unit separately
        }
        if (num > 0) {
            result += `${units[num]  } `;
        }
        return result.trim();
    }

    const billion = Math.floor(num / 1e9);
    const million = Math.floor((num % 1e9) / 1e6);
    const thousand = Math.floor((num % 1e6) / 1e3);
    const remainder = num % 1000;

    let result = '';
    if (billion > 0) {
        result += `${convertBelowThousand(billion)  } billion `;
    }
    if (million > 0) {
        result += `${convertBelowThousand(million)  } million `;
    }
    if (thousand > 0) {
        result += `${convertBelowThousand(thousand)  } thousand `;
    }
    if (remainder > 0) {
        result += convertBelowThousand(remainder);
    }
    return result.trim();
}


// <PDFDownloadLink
//     sx={{textDecoration: 'none'}}
//     document={
//         <Invoice
//             no_of_orders={row.PaymentInfo.no_of_orders}
//             orderId={row.OrderId}
//             invoiceDate={row.InvoiceDateTime}
//             formName={row.FormName}
//             address={""}
//             gstNo={row.GstNo}
//             officeAddress={""}
//             city={""}
//             pincode={""}
//             state={""}
//             country={""}
//             amount={row.Amount}
//             InWords={numberToWords(row.Amount)}
//         />
//     }
//     fileName={row.OrderId}
// >
//     {({blob, url, loading}) =>
//         loading ? (
//             <div style={{paddingLeft: '20px'}}>
//                 <Button variant="contained">Generating Bill...</Button>
//             </div>
//         ) : (
//             <MenuItem
//                 onClick={() => {
//                     uploadBill(blob, url, row.OrderId)
//                 }}
//             ><Iconify icon={'eva:download-outline'} /> Download</MenuItem>
//         )
//     }
// </PDFDownloadLink>
export default function InvoiceTableRow({row, selected, onSelectRow, onViewRow, onEditRow, onDeleteRow}) {
    const theme = useTheme();
    const {enqueueSnackbar} = useSnackbar();
    const [openMenu, setOpenMenuActions] = useState(null);
    const handleOpenMenu = (event) => {
        setOpenMenuActions(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpenMenuActions(null);
    };

    const uploadBill = useCallback((blob, url, fileName) => {
        if (url) {
            const a = document.createElement('a');
            a.download = fileName;
            a.href = url;
            a.addEventListener('click', () => {
                setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
            });
            a.click();
        }
    });

    return (
        <TableRow hover selected={selected}>
            <TableCell padding="checkbox">
                <Checkbox checked={selected} onClick={onSelectRow}/>
            </TableCell>

            <TableCell sx={{display: 'flex', alignItems: 'center'}}>
                <Avatar alt={row.sort_key.split('#')[0].toString().toUpperCase()[0]} style={{
                    backgroundColor:
                    createAvatar(row
                        .sort_key.split('#')[0].toString().toUpperCase()[0]).color
                }} sx={{mr: 2}}>
                    {createAvatar(row.sort_key.split('#')[0].toString().toUpperCase()[0]).name}
                </Avatar>

                <Stack>
                    <Typography variant="subtitle2" noWrap>
                        {row.sort_key.split('#')[0]}
                    </Typography>

                    <Typography noWrap variant="body2" sx={{color: 'text.disabled', cursor: 'pointer'}}>
                        {row.OrderId}
                    </Typography>
                </Stack>
            </TableCell>

            <TableCell align="left">{row.FormName}</TableCell>
            <TableCell align="left">{row.GstNo}</TableCell>
            <TableCell align="left">{row.InvoiceDateTime.split('.')[0]}</TableCell>


            <TableCell align="right">
                <TableMoreMenu
                    open={openMenu}
                    onOpen={handleOpenMenu}
                    onClose={handleCloseMenu}
                    actions={
                        <>
                            <MenuItem
                                onClick={() => {
                                    downloadS3File(row.DownloadKey,true,
                                        BILL_BUCKET,
                                        enqueueSnackbar('Bill Uploaded Successfully'))
                                }}
                            >
                                <Iconify icon={'eva:download-outline'} /> Download</MenuItem>
                            <MenuItem
                                onClick={() => {
                                    onEditRow();
                                    handleCloseMenu();
                                }}
                            >
                                <Iconify icon={'eva:edit-fill'}/>
                                Edit
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    onDeleteRow();
                                    handleCloseMenu();
                                }}
                                sx={{color: 'error.main'}}
                            >
                                <Iconify icon={'eva:trash-2-outline'}/>
                                Delete
                            </MenuItem>
                        </>
                    }
                />
            </TableCell>
        </TableRow>
    );
}
