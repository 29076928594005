/* eslint-disable */
import {Autocomplete, Button, IconButton, TextField, Tooltip} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { useTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import DataGridTable from '../../../../../components/Common/DataGridTable';
import useAuth from '../../../../../hooks/useAuth';
import { CreatePostAPICall, Home, MyLinkedAccount, V2Management } from '../../../../../utils/apis';
import {
    CreateExcelFile,
    downloadS3File,
    handleOnClickCopy,
    isSuperAdminEnvironment,
    JSONToCSVConvertor
} from '../../../../../utils/utilities';
import ReactJson from "react-json-view";
import Iconify from "../../../../../components/Iconify";
import DeleteIcon from "@mui/icons-material/Delete";
import {PAYMENT_UPLOAD_BT_ACCOUNT} from "../../../../../utils/Constants";
import {LoadingButton} from "@mui/lab";

const FailedTicket = ({platform}) => {
    const { enqueueSnackbar } = useSnackbar();
    const [autoPaymentTicketData, setAutoPaymentTicketData] = useState([]);
    const [dataLoading, setDataLoading] = useState(false);
    const [createdPayloadJSON, setCreatedPayloadJSON] = useState([]);
    const CreateTicket = async (setIsLoading,data)=> {
        setIsLoading(true);
        const payloadData = {
            "actionType": "invokeSavePaymentExcelV2",
            "panel": "admin",
            "subPanel": "adminManagement",
            "payloads" : [data],
            "Pending": true,
        }
        // console.log(payloadData);
        const response = await CreatePostAPICall(enqueueSnackbar, V2Management, payloadData);
        if (response) {
            enqueueSnackbar(response.data.body);
        }
        setIsLoading(false);
    }
    const reasons = [
        'CALL NOT RECEIVED',
        'NOT USE WITH NO ISSUE',
        'WILL USE',
        'CALL US WHEN HE/SHE WANTS TO USE',
        'NOT USE BUT DO NOT DEACTIVATE',
        'DEACTIVATE',
        'PACKAGE PRICE IS HIGH',
        'HE/SHE WANTS TICKET MANAGEMENT',
        'MEESHO SELLING CLOSE',
        'NUMBER SWITCH OFF',
        'CUT THE CALL',
    ];

    const AptColumnUser = [
        {
            accessorKey: 'username',
            header: 'User ID',
            size: 20,

        },
        {
            accessorKey: 'acType',
            header: 'Plate from',
            size: 20,

        },
        {
            accessorKey: 'tpEmailIds',
            header: 'Email Id',
            size: 20,


        },
        {
            accessorKey: 'subAccountIds',
            header: 'Supply ID',
            size: 20,


        },
        {
            accessorKey: 'S3_Key',
            header: 'Copy File key',
            size: 20,
            Cell: (cell) => {
                return (
                    <div>
                        {/*{cell?.row.original.File_Key}*/}
                        <Tooltip title="Copy">
                            <IconButton onClick={() => handleOnClickCopy(cell?.row.original.S3_Key, enqueueSnackbar)}>
                                <Iconify icon={'eva:copy-fill'} width={24} height={24} />
                            </IconButton>
                        </Tooltip>
                    </div>
                );
            }

        },
        {
            accessorKey: 'fileName',
            header: 'File Name',
            size: 20,
            Cell: (cell) => {
                return <div>
                    {cell?.row.original.fileName}
                    <Tooltip title="Copy">
                        <IconButton onClick={() => handleOnClickCopy(cell?.row.original.fileName, enqueueSnackbar)}>
                            <Iconify icon={'eva:copy-fill'} width={24} height={24} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Copy">
                        <IconButton onClick={async () => await downloadS3File(cell?.row.original.S3_Key, false, PAYMENT_UPLOAD_BT_ACCOUNT, enqueueSnackbar)}>
                            <Iconify icon={'eva:copy-fill'} width={24} height={24} />
                        </IconButton>
                    </Tooltip>
                </div>
            },
        },
        // {
        //     accessorKey: 'ticketPayload',
        //     header: 'Ticket Payload',
        //     size: 20,
        //     Cell: (cell) => {
        //         const index = cell.row.index;
        //         return <ReactJson
        //             name={"Payload"}
        //             src={createdPayloadJSON[index]}
        //             displayObjectSize={false}
        //             enableClipboard={true}
        //             displayDataTypes={false}
        //             collapsed
        //         />
        //     },
        // },
    ];




    const AptColumnAdmin = [
        {
            accessorKey: 'username',
            header: 'User ID',
            size: 20,

        },
        {
            accessorKey: 'acType',
            header: 'Plate from',
            size: 20,

        },
        {
            accessorKey: 'tpEmailIds',
            header: 'Email Id',
            size: 20,


        },
        {
            accessorKey: 'fileType',
            header: 'File Type',
            size: 20,


        },
        {
            accessorKey: 'subAccountIds',
            header: 'Supply ID',
            size: 20,


        },
        {
            accessorKey: 'Last_Modified',
            header: 'Last Modified',
            size: 20,
            Cell: (cell) => {
                const lastModified = new Date(cell?.row.original.Last_Modified);
                const formattedDate = lastModified.toLocaleDateString('en-US');
                const formattedTime = lastModified.toLocaleTimeString('en-US');
                return (
                    <div>
                        {formattedDate} {formattedTime}

                    </div>
                );
            }

        },

        {
            accessorKey: 'fileName',
            header: 'Download',
            size: 20,
            Cell: (cell) => {
                return <div>
                    {/*{cell?.row.original.fileName}*/}
                    <Tooltip title="Download">
                        <IconButton onClick={() => {
                            downloadS3File(cell?.row.original.S3_Key,false, PAYMENT_UPLOAD_BT_ACCOUNT, enqueueSnackbar)
                        }}>
                            <Iconify icon={'eva:download-fill'} width={24} height={24} />
                        </IconButton>
                    </Tooltip>
                </div>
            },
        },

        // {
        //     accessorKey: 'ticketPayload',
        //     header: 'Ticket Payload',
        //     size: 20,
        //     Cell: (cell) => {
        //         const index = cell.row.index;
        //         return <ReactJson
        //             name={"Payload"}
        //             src={createdPayloadJSON[index]}
        //             displayObjectSize={false}
        //             enableClipboard={true}
        //             displayDataTypes={false}
        //             collapsed
        //         />
        //     },
        // },
        {
            accessorKey: 'Size',
            header: 'File Size',
            size: 20,
            Cell: (cell) => {
                const conversionFactor = 0.000001; // Replace this with your actual conversion factor
                const sizeInDileSoteag = cell?.row.original.Size;
                const sizeInMB = sizeInDileSoteag * conversionFactor;
                return (
                    <div>
                        {sizeInMB.toFixed(2)} MB
                    </div>
                );
            }

        },
        {
            accessorKey: 'S3_Key',
            header: 'Copy File key',
            size: 20,
            Cell: (cell) => {
                return (
                    <div>
                        {/*{cell?.row.original.File_Key}*/}
                        <Tooltip title="Copy">
                            <IconButton onClick={() => handleOnClickCopy(cell?.row.original.S3_Key, enqueueSnackbar)}>
                                <Iconify icon={'eva:copy-fill'} width={24} height={24} />
                            </IconButton>
                        </Tooltip>
                    </div>
                );
            }

        },
        {
            accessorKey: 'Create Ticket',
            header: 'Upload Payment',
            size: 20,
            Cell: (cell) => {
                const index = cell.row.index;
                const [isLoading, setIsLoading] = useState(false);
                return <LoadingButton loading={isLoading} onClick={()=>{CreateTicket(setIsLoading,cell.row.original,cell.row.original)}}  variant={'outlined'}>
                    Re-submit
                </LoadingButton>
            },
        },
        {
            accessorKey: 'S3_Key',
            header: 'Delete',
            size: 10,
            Cell: ({ cell }) => (
                <IconButton
                    aria-label="delete"
                    onClick={() => {
                        deletePaymentTicket(cell.row.original.S3_Key);
                    }}
                >
                    <DeleteIcon style={{ color: 'red' }} />
                </IconButton>
            ),
        },
    ];


    const deletePaymentTicket = async (fileKey) => {
        const body = {
            actionType: "autoPaymentFileDelete",
            panel: "admin",
            subPanel: "adminManagement",
            fileKey : fileKey
        };
        const response = await CreatePostAPICall(enqueueSnackbar, V2Management, body);
        if (response) {
            enqueueSnackbar(response.data.body);
            getAutoPaymentData();
        }
    };

    //   get low balance all user data api call
    const getAutoPaymentData = async () => {
        setDataLoading(true);
        const body = {
            "actionType": "autoPaymentUpload",
            "panel": "admin",
            "subPanel": "adminManagement",
            "prefix" : "Failed",
            "platform" : platform
        };

        setAutoPaymentTicketData([]);
        const response = await CreatePostAPICall(enqueueSnackbar, V2Management, body);
        // if (response) {
        //     var tempData = [];
        //     const listPayload = [];
        //     for (const responseKey in response.data.body) {
        //         const data = response.data.body[responseKey].split('/');
        //         var fileStatus = data[0];
        //         var userName = data[1];
        //         var acType = data[2];
        //         var tpmailId = data[3];
        //         var subAcId = data[4];
        //         var companyName = data[5];
        //         var token = data[6];
        //         var fileType = data[7];
        //         var fileName = data[8];
        //         const ticketPayload = {
        //             'actionType': 'autoSyncExelData',
        //             'fileType': fileType,
        //             'fileName': fileName,
        //             'username': userName,
        //             'tpEmailIds': [tpmailId],
        //             'subAccountIds': [subAcId],
        //             'companyName': companyName,
        //             'acType': acType,
        //             'S3_Key': response.data.body[responseKey],
        //             'token': token,
        //             'fileStatus':fileStatus
        //         }
        //         tempData.push(ticketPayload);
        //     }
        if (response && response.data.body) {
            const responseData = response.data.body;
            const tempData = [];
            // Process each key-value pair in the response data
            for (const i in responseData) {
                if (responseData.hasOwnProperty(i)){
                    // console.log("Key:", i);


                    const item = responseData[i];
                    const key = item.Key;
                    const lastModified = item.LastModified;
                    const size = item.Size;
                    const storageClass = item.StorageClass;



                    // Splitting the key by "/"
                    const keyParts = key.split('/');
                    // cnsole.log("Key:", keyParts);
                    const platformFromKey = keyParts[2];
                    // cnsole.log("Key:", keyParts);
                    if (platform === platformFromKey) {
                        const ticketPayload = {
                            "S3_Key": key,
                            'fileStatus': keyParts[0],
                            "username": keyParts[1],
                            "tpEmailIds": keyParts[3],
                            "acType" :platformFromKey,
                            "subAccountIds": keyParts[4],
                            "companyName":keyParts[5],
                            'token': keyParts[6],
                            'fileType': keyParts[7],
                            'fileName': keyParts[8],
                            "Last_Modified": lastModified,
                            "Size": size,
                            "Storage Class": storageClass,
                            'actionType': 'autoSyncExelData',
                        };
                        tempData.push(ticketPayload);
                    }
                }
            }
            setCreatedPayloadJSON(tempData);

            setAutoPaymentTicketData(tempData);
        }
        setDataLoading(false);
    };
    //   default api call
    useEffect(() => {
        getAutoPaymentData();
    }, [platform]);

    const exportToCsvData = () => {
        if (autoPaymentTicketData.length !== 0) {
            CreateExcelFile(autoPaymentTicketData, 'Low Balance', true);
        }
    };

    const selectedDelete = async (selectedRows) => {
        console.log("selectedRows type:", typeof selectedRows);
        console.log("selectedRows:", selectedRows);

        if (!selectedRows || !selectedRows.S3_Key) {
            enqueueSnackbar("No S3_Key found for the selected row", { variant: 'error' });
            return;
        }

        const s3Key = selectedRows.S3_Key;
        console.log("S3_Key:", s3Key);

        await deletePaymentTicket(s3Key);

        enqueueSnackbar("Row deleted successfully", { variant: 'success' });
        getAutoPaymentData(); // Refresh data after deletion
    };

    return (
        <>
            <DataGridTable columns={isSuperAdminEnvironment ? AptColumnAdmin  : AptColumnUser}
                           data={autoPaymentTicketData}
                           sorting isLoading={dataLoading}
                           checkbox
                           button1={'Delete Selected'}
                           funReported={(value) => selectedDelete(value)}/>
        </>
    );
};

export default FailedTicket;