/* eslint-disable */
import { Box, Divider, Tab, Tabs } from '@mui/material';
import { capitalCase } from 'change-case';
import React from 'react';
import useTabs from '../../../hooks/useTabs';
import AllUserDetails from './adminmanagement/AllUserDetails';
import DeleteMonthlyReports from './adminmanagement/DeleteMonthlyReports';
import GetUserTransaction from './adminmanagement/GetUserTransaction';
import LowBalance from './adminmanagement/LowBalance';
import UserNotification from './adminmanagement/UserNotification';
import UserRequest from './adminmanagement/UserRequest';
import UserStatus from './adminmanagement/UserStatus';
import AutoPaymentTicket from "./adminmanagement/autoPaymentTicket/autoPaymentTicket";
import {isSuperAdminEnvironment} from "../../../utils/utilities";

const AdminManagement = (props) => {
  const { currentUploadTab, onChangeUploadTab } = useTabs('Low Balance');

  const uploadTabs = [
    {
      value: 'Low Balance',
    },
    {
      value: 'Auto Payment Excel',
    },
    // {
    //   value: 'User Status',
    // },
    // {
    //   value: 'User Request',
    // },
    {
      value: 'Delete Monthly Reports',
    },
    {
      value: 'User Notification',
    },
  ];

  if (isSuperAdminEnvironment){
      uploadTabs.splice(3, 0, {
          value: 'All Users Details'
      });

      uploadTabs.splice(4, 0, {
          value: 'Get Users Transaction'
      });
  }

  return (
    <>
      <Divider sx={{ p: 1 }} />

      <Tabs
        allowScrollButtonsMobile
        variant="scrollable"
        scrollButtons="auto"
        value={currentUploadTab}
        onChange={onChangeUploadTab}
      >
        {uploadTabs.map((tab) => (
          <Tab disableRipple key={tab.value} label={capitalCase(tab.value)} icon={tab.icon} value={tab.value} />
        ))}
      </Tabs>

      <Box sx={{ mb: 5 }} />

      {currentUploadTab === 'Low Balance' && <LowBalance />}
      {currentUploadTab === 'User Status' && <UserStatus />}
      {currentUploadTab === 'User Notification' && <UserNotification />}
      {currentUploadTab === 'All Users Details' && <AllUserDetails />}
      {currentUploadTab === 'Get Users Transaction' && <GetUserTransaction />}
      {currentUploadTab === 'User Request' && <UserRequest />}
      {currentUploadTab === 'Delete Monthly Reports' && <DeleteMonthlyReports />}
      {currentUploadTab === 'Auto Payment Excel' && <AutoPaymentTicket />}
    </>
  );
};
export default AdminManagement;
