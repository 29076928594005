/* eslint-disable */
import React,{useState} from 'react';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import urlConstants from './urlConstants';
import useAuth from '../hooks/useAuth';
import {CreatePostAPICall, UserProfile} from "../utils/apis";
import {useSnackbar} from "notistack";
import {isAdmin} from "../utils/utilities";

const {
  MEESHO_CLAIM_ISSUE_PAGE,
  FLIPKART_CLAIM_ISSUE_PAGE,
  GLOWROAD_CLAIM_ISSUE_PAGE,
  SHOPDECK_CLAIM_ISSUE_PAGE,
  MYNTRA_CLAIM_ISSUE_PAGE,
  AMAZON_CLAIM_ISSUE_PAGE,
  SNAPDEAL_CLAIM_ISSUE_PAGE,
  LIMEROAD_CLAIM_ISSUE_PAGE,
  JIOMART_CLAIM_ISSUE_PAGE,
  REPORTED_CLAIM_ISSUE_PAGE,
  SCAN_ORDERS_PAGE,
  SYNC_ORDERS_PAGE,
  MeeshoUtilities,
  FlipkartUtilities,
  GlowroadUtilities,
  CITY_MALL,
  LIME_ROAD,
  SNAP_DEAL,
  ACCEPT_LIST_PAGE,
  CUSTOMER_REVIEW_PAGE,
  FlipkartRewardReco,
  MONTHLY_REPORTS_PAGE,
  AUTO_TICKET
} = urlConstants

export default function PrivateRoutes({ allwoedRoles }) {
  const location = useLocation();
  const userName = window.localStorage.getItem('userEmailId')
  const [userStatus,setUserStatus] = useState(window.localStorage.getItem('userStatus'))
  const [userBalanceStatus,setUserBalanceStatus] = useState(window.localStorage.getItem('Balance_Status'))
  const { enqueueSnackbar } = useSnackbar();
  const getUserDetails = async () => {
    const userIdResponse = await CreatePostAPICall((message,...other)=>{
      console.log(message)}, UserProfile, {
      username: userName,
      action: 'getUserInfo',
    });
    if(userIdResponse){
      localStorage.setItem('userId', JSON.stringify(userIdResponse.data.body.Id));
      localStorage.setItem('Balance_Status', userIdResponse.data.body.Balance_Status);
      if(isAdmin){
        setUserStatus("ENABLE")
      }else {
        setUserStatus(userIdResponse.data.body.userStatus)
      }
      // localStorage.setItem('userStatus', userIdResponse.data.body.userStatus);
      setUserBalanceStatus(userIdResponse.data.body.Balance_Status)
    }

  }
  getUserDetails()
  const restrictedPaths = [
    MEESHO_CLAIM_ISSUE_PAGE,
    FLIPKART_CLAIM_ISSUE_PAGE,
    GLOWROAD_CLAIM_ISSUE_PAGE,
    SHOPDECK_CLAIM_ISSUE_PAGE,
    MYNTRA_CLAIM_ISSUE_PAGE,
    AMAZON_CLAIM_ISSUE_PAGE,
    SNAPDEAL_CLAIM_ISSUE_PAGE,
    LIMEROAD_CLAIM_ISSUE_PAGE,
    JIOMART_CLAIM_ISSUE_PAGE,
    REPORTED_CLAIM_ISSUE_PAGE,
    SCAN_ORDERS_PAGE,
    SYNC_ORDERS_PAGE,
    MeeshoUtilities,
    FlipkartUtilities,
    GlowroadUtilities,
    CITY_MALL,
    LIME_ROAD,
    SNAP_DEAL,
    ACCEPT_LIST_PAGE,
    CUSTOMER_REVIEW_PAGE,
    FlipkartRewardReco,
    MONTHLY_REPORTS_PAGE,
    AUTO_TICKET
  ]
  const isRestrictedPath = restrictedPaths.some((path) => location.pathname.includes(path));
  // console.log("isRestrictedPath",isRestrictedPath)
  // if(isRestrictedPath && userBalanceStatus === "EXPIRED"){
  //   enqueueSnackbar("You are not allowed to access this page, Contact BuissMaster for more details",{variant:'error'})
  //   return <Navigate to={{ pathname: urlConstants.HOME_PAGE }} state={{ from: location }} replace />
  // }

  // return  userName && userStatus === "ENABLE" ? (
  return  userName ? (
    <Outlet />
  ) : (
    <Navigate to={{ pathname: urlConstants.LOGIN_PAGE }} state={{ from: location }} replace />
  );
}
