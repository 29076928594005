/* eslint-disable */
// validation of meesho order excel file
export const ValidateForwardReportsFile = (header) => {
  try {
    if (
      header[0] === 'month' &&
      header[1] === 'order_date' &&
      header[2] === 'identifier' &&
      header[3] === 'order_num' &&
      header[4] === 'sub_order_num' &&
      header[5] === 'quantity' &&
      header[6] === 'order_status' &&
      header[7] === 'manifesttime' &&
      header[8] === 'sup_name' &&
      header[9] === 'state' &&
      header[10] === 'pin' &&
      header[11] === 'reseller_state' &&
      header[12] === 'reseller_pin' &&
      header[13] === 'end_customer_state' &&
      header[14] === 'end_customer_pin' &&
      header[15] === 'gstin' &&
      header[16] === 'hsn_code' &&
      header[17] === 'gst_amount' &&
      header[18] === 'gst_rate' &&
      header[19] === 'meesho_price' &&
      header[20] === 'net_commission' &&
      header[21] === 'commission_gst' &&
      header[22] === 'adj' &&
      header[23] === 'shipping_charges_total' &&
      header[24] === 'gst' &&
      header[25] === 'taxable_shipping' &&
      header[26] === 'shipping_gst_18_percent' &&
      header[27] === 'meesho_price_plus_shipping_charges_total' &&
      header[28] === 'tcs_taxable_amount' &&
      header[29] === 'end_customer_state_new' &&
      header[30] === 'financial_year' &&
      header[31] === 'month_number' &&
      header[32] === 'supplier_id'
    ) {
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
};

// validation of self code excel file
export const ValidateSkuUpDateFile = (header) => {
  try {
    if (header[0] === 'subOrderNo' && header[1] === 'newSelfCost') {
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
};

// validation of remove Received entry excel file
export const ValidateREntryDateFile = (header) => {
  try {
    if (header[0] === 'subOrderNo') {
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
};

// validation of remove pickup entry excel file
export const ValidatePEntryDateFile = (header) => {
  try {
    if (header[0] === 'subOrderNo') {
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
};

// validation of make Received entry excel file
export const ValidateMEntryDateFile = (header) => {
  try {
    if (header[0] === 'subOrderNo') {
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
};

// validation of flipkart frr excel file
export const ValidateFlipkartMpFeeFiles = (header) => {
  try {
    if (header[0] === 'SKU' && header[1] === 'SELLING_PRICE' && header[2] === 'MP_REBATE_%') {
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const ValidateBackupFromBMTradebookFile = (header) => {
  try {
    if (
      header[0] === 'SUB_ORDER_NO' &&
      header[1] === 'AWB' &&
      header[2] === 'ORDER_DATE' &&
      header[3] === 'INVOICE_DATE' &&
      header[4] === 'PICK_UP_DATE' &&
      header[5] === 'DELIVERY_PARTNER' &&
      header[6] === 'SKU' &&
      header[7] === 'QUANTITY' &&
      header[8] === 'SIZE' &&
      header[9] === 'IS_CANCELED' &&
      header[10] === 'COST' &&
      header[11] === 'RETURN_TYPE' &&
      header[12] === 'RET_CONDITION' &&
      header[13] === 'ORDER_STATUS' &&
      header[14] === 'RETURN_DATE' &&
      header[15] === 'RETURN_AWB' &&
      header[16] === 'RET_QT' &&
      header[17] === 'RETURN_PATNER' &&
      header[18] === 'DELIVERED_DATE' &&
      header[19] === 'IS_RECEIVED' &&
      header[20] === 'RECEIVED_DATE' &&
      header[21] === 'IS_PENDING_REPORTED' &&
      header[22] === 'IS_DELIVERED_REPORTED' &&
      header[23] === 'IS_RECEIVED_REPORTED' &&
      header[24] === 'IS_PAYMENT_NOT_RECEIVED_REPORTED' &&
      header[25] === 'PAYMENT_STATUS' &&
      header[26] === 'CAIM/COMPENSATION/RECOVERY' &&
      header[27] === 'MEESHO_FEE' &&
      header[28] === 'PAYMENT_DATE' &&
      header[29] === 'RETURN_CHARGE' &&
      header[30] === 'Final_Settlement' &&
      header[31] === 'PROFIT/LOSS'
    ) {
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
};

// validation of add skus excel file
export const ValidateAddSkusFiles = (header) => {
  try {
    if (header[0] === 'skuName' && header[1] === 'Size' && header[2] === 'selfCost') {
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
};