/* eslint-disable */
import React, {useEffect, useRef, useState} from 'react';
import {
    Button,
    Grid,
    IconButton,
    Container,
    FormControlLabel,
    RadioGroup,
    Radio,
    TextField,
    CircularProgress,
    Box, FormLabel, List, Divider, Autocomplete, Input
} from '@mui/material';
import {useSnackbar} from 'notistack';
import NoDataFound from '../../../components/Common/NoDataFound';
import useAuth from '../../../hooks/useAuth';
import DataGridTable from '../../../components/Common/DataGridTable';
import Iconify from '../../../components/Iconify';
import useSettings from '../../../hooks/useSettings';
import AccountSelector from '../../../components/Common/AccountSelector';
import {
    SystemColor,
    downloadS3File,
    getAccountsDataByType,
    IOSSwitch,
    updateInstruction, checkStatusOfInstruction, isDesktopEnvironment
} from '../../../utils/utilities';
import {CreatePostAPICall, SyncOrders, Utility} from '../../../utils/apis';
import {USER_ORDER_LABELS, ACCEPT_LIST_MEESHO} from '../../../utils/Constants';
import {Card, DatePicker} from 'antd';
import {LoadingButton} from "@mui/lab";
import CardActions from "@mui/material/CardActions";
import {CoachMark} from "react-coach-mark";
import moment from 'moment';
import {log} from "util";
import {useTheme} from "@mui/material/styles";

function AcceptList({utilityType, acType}) {
    const {user} = useAuth();
    const localUser = window.localStorage.getItem('userEmailId');
    const {enqueueSnackbar} = useSnackbar();
    const {themeStretch} = useSettings();
    const [userData, setUserData] = useState([]);
    const [selectedAccountsData, setSelectedAccountsData] = useState([]);
    const [histoyTableData, setHistoyTableData] = useState([]);
    const [ddbDate, setDdbDate] = useState("NEXT_SLOT")
    const [filterValue, setFilterValue] = useState("ALL")
    const [isLoading, setIsLoading] = useState(false);
    const [isGenerateDisable, setIsGenerateDisable] = useState(false);
    const [dimention, setDimention] = useState({"L": 0.00, "B": 0.00, "H": 0.00, "W": 0.00})
    const [updateLbhw, setUpdateLbhw] = useState(true)
    const [userAccount, setUserAccount] = useState([]);
    const [activateNumbers, setActivateNumbers] = useState(0);
    const [dateRange, setDateRange] = useState({});
    const isPassDate = ["reGenerateLabel"].includes(utilityType) && ["limeroad","snapdeal"].includes(acType)

    const {RangePicker} = DatePicker;
    const theme = useTheme();
    const isLight = theme.palette.mode === 'light';

    const slotNext = useRef(null);
    const slotAll = useRef(null);
    const slotToday = useRef(null);
    const slotBreached = useRef(null);
    const filterAll = useRef(null);
    const filterSingle = useRef(null);
    const filterMulti = useRef(null);
    const updateLBWH = useRef(null);

    useEffect(() => {
        getAccountsDataByType(acType).then((filteredData) => {
            const userDa = acType !== 'meesho' ? filteredData.filter(value => value.name !== 'Select All') : filteredData;
            setUserData(userDa);
        });
    }, []);


    const checkIsTimeOut = (data) => {
        const providedDate = new Date(data.Date);

        const currentDate = new Date();

        const timeDifference = currentDate - providedDate;

        const minutesDifference = timeDifference / (1000 * 60);

        if(data["Complete Time"] === "IN PROGRESS")
        {if (minutesDifference > 18) {
            return "Error";
        } else {
            return  "IN PROGRESS";
        }
        }else{
            return "Done"
        }
    }
    const getdate = (value, dateString) => {
        // console.log(value);

        const [startDateString, endDateString] = dateString;

        // console.log(startDateString, endDateString)
        const [day1, month1, year1] = startDateString.split("/");
        const [day2, month2, year2] = endDateString.split("/");
        if (day1 && month1 && year1 && day2 && month2 && year2) {
            setDateRange({start_date:startDateString,end_date: endDateString})
            // console.log(formattedStartDate, formattedEndDate);
        } else {
            setDateRange({})
        }
    }


    const NextButton = <Grid container style={{marginTop: ".5rem"}} justifyContent={'end'}>
        {activateNumbers !== 7 && (<LoadingButton variant={'outlined'} style={{marginRight: ".5rem"}}
                                                  onClick={() => setActivateNumbers(7)}>Skip</LoadingButton>)}
        <LoadingButton
            variant={'contained'}
            onClick={() => {
                setActivateNumbers((prevNumber) => prevNumber + 1)
                if (activateNumbers === 7) {
                    setActivateNumbers(0)
                    updateInstruction('utilitiesFlipkart', false)
                }
            }}
        >
            {activateNumbers === 7 ? 'Done' : 'Next'}
        </LoadingButton>
    </Grid>;

    const coachList = [
        {
            activate: activateNumbers === 0,
            component: <Card><h4 style={{padding: "10px"}}>Next Slot</h4>
                <p style={{paddingLeft: "10px", paddingRight: "5px"}}>Generate Only Next Coming Slot order's label
                    pdf</p><CardActions style={{textAlign: 'center'}}>{NextButton}</CardActions></Card>,
            reference: slotNext,
            disableScrolling: true,
            tooltip: {position: 'bottom', style: {borderRadius: '2rem'}}
        },
        {
            activate: activateNumbers === 1,
            component: <Card><h4 style={{padding: "10px"}}>All Slot</h4>
                <p style={{paddingLeft: "10px", paddingRight: "5px"}}>Generate All Order's label pdf</p><CardActions
                    style={{textAlign: 'center'}}>{NextButton}</CardActions></Card>,
            reference: slotAll,
            disableScrolling: true,
            tooltip: {position: 'bottom', style: {borderRadius: '2rem'}}
        },
        {
            activate: activateNumbers === 2,
            component: <Card><h4 style={{padding: "10px"}}>Today All</h4>
                <p style={{paddingLeft: "10px", paddingRight: "5px"}}>Generate All Order label pdf of today's slots</p>
                <CardActions style={{textAlign: 'center'}}>{NextButton}</CardActions></Card>,
            reference: slotToday,
            disableScrolling: true,
            tooltip: {position: 'bottom', style: {borderRadius: '2rem'}}
        },
        {
            activate: activateNumbers === 3,
            component: <Card><h4 style={{padding: "10px"}}>Only Breached</h4>
                <p style={{paddingLeft: "10px", paddingRight: "5px"}}>Generate Only breached order's label pdf
                </p><CardActions style={{textAlign: 'center'}}>{NextButton}</CardActions></Card>,
            reference: slotBreached,
            disableScrolling: true,
            tooltip: {position: 'bottom', style: {borderRadius: '2rem'}}
        },
        {
            activate: activateNumbers === 4,
            component: <Card><h4 style={{padding: "10px"}}>Filter ALL</h4>
                <p style={{paddingLeft: "10px", paddingRight: "5px"}}>Generate Single QT as well as Multi Qt order's
                    label pdf
                </p><CardActions style={{textAlign: 'center'}}>{NextButton}</CardActions></Card>,
            reference: filterAll,
            disableScrolling: true,
            tooltip: {position: 'bottom', style: {borderRadius: '2rem'}}
        },
        {
            activate: activateNumbers === 5,
            component: <Card><h4 style={{padding: "10px"}}>Filter Single</h4>
                <p style={{paddingLeft: "10px", paddingRight: "5px"}}>Generate Only Single QT order's label pdf
                </p><CardActions style={{textAlign: 'center'}}>{NextButton}</CardActions></Card>,
            reference: filterSingle,
            disableScrolling: true,
            tooltip: {position: 'bottom', style: {borderRadius: '2rem'}}
        },
        {
            activate: activateNumbers === 6,
            component: <Card><h4 style={{padding: "10px"}}>Filter Multiple</h4>
                <p style={{paddingLeft: "10px", paddingRight: "5px"}}>Generate Only MULTI QT order's label pdf
                </p><CardActions style={{textAlign: 'center'}}>{NextButton}</CardActions></Card>,
            reference: filterMulti,
            disableScrolling: true,
            tooltip: {position: 'bottom', style: {borderRadius: '2rem'}}
        },
        {
            activate: activateNumbers === 7,
            component: <Card><h4 style={{padding: "10px"}}>Update LBHW</h4>
                <p style={{paddingLeft: "10px", paddingRight: "5px"}}>If on then update given common LBHW values while
                    generating label.If Off then only single qt order's label pdf will be generated.
                </p><CardActions style={{textAlign: 'center'}}>{NextButton}</CardActions></Card>,
            reference: updateLBWH,
            disableScrolling: true,
            tooltip: {position: 'bottom', style: {borderRadius: '2rem'}}
        },
    ];

    // const onSelectAccount = (e, value) => {
    //     const selectAllArray = value.filter((data) => data.value === 'all');
    //
    //     const selectAllFlipkartArray = value.filter((data) => data.value === 'flipkart');
    //     const selectAllMeeshoArray = value.filter((data) => data.value === 'meesho');
    //     const selectAllGlowroadArray = value.filter((data) => data.value === 'glowroad');
    //
    //     let selectOther = value;
    //
    //     if (selectAllMeeshoArray?.length > 0) {
    //         selectOther = selectOther.filter((data) => data.AcType !== 'meesho');
    //     }
    //     if (selectAllFlipkartArray?.length > 0) {
    //         selectOther = selectOther.filter((data) => data.AcType !== 'flipkart');
    //     }
    //     if (selectAllGlowroadArray?.length > 0) {
    //         selectOther = selectOther.filter((data) => data.AcType !== 'glowroad');
    //     }
    //
    //     if (selectAllArray?.length > 0) {
    //         setUserAccount(selectAllArray);
    //         handleSelectedAccount(e, selectAllArray);
    //     } else {
    //         setUserAccount(selectOther);
    //         handleSelectedAccount(e, selectOther);
    //     }
    // };

    const onSelectAccount = (e, value) => {
        // If 'value' is an array, take the first element, otherwise keep it as is
        const selectedAccount = Array.isArray(value) ? value[0] : value;
        // console.log('onSelectAccount',selectedAccount);
        // Update state with the single selected account
        setUserAccount(selectedAccount ? selectedAccount : []);

        // Pass the selected account to the handler function
        handleSelectedAccount(e, selectedAccount);
    };

    // columns for accept list
    const AcceptListColumns = [
        {
            accessorKey: 'Complete Time',
            header: 'Date',
            Cell: ({cell}) => {
                return <>
                      {moment(cell.row.original.Date).format('DD-MM-YYYY hh:mm:ss')}
                  </>
            }
        },
        {
            accessorKey: 'Account',
            header: 'Account',
        },
        {
            accessorKey: 'key',
            header: 'Download',
            size: 10,
            Cell: ({ cell }) => {
                const complete = checkIsTimeOut(cell.row.original);

                return (
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      {complete === 'IN PROGRESS' ? (
                        <CircularProgress sx={{ color: SystemColor.originColor }} size={24} color="inherit" />
                      ) : complete === 'Error' ? (
                        <Iconify icon="mdi:error-outline" color="red" width={30} height={30} />
                      ) : complete === 'Done' ? (
                        <>
                            <IconButton
                              onClick={() => {
                                  // console.log("cell.row.original.key",cell.row.original.key)
                                  if (typeof cell.row.original.key === 'string') {
                                      downloadS3File(cell.row.original.key[0], false, utilityType.toLowerCase().includes("label") || utilityType.toLowerCase().includes("manifest") ? USER_ORDER_LABELS : ACCEPT_LIST_MEESHO, enqueueSnackbar);
                                      // console.log('Variable is a string');
                                  } else if (Array.isArray(cell.row.original.key)) {
                                      cell.row.original.key.forEach((key)=>{
                                          downloadS3File(key, false, utilityType.toLowerCase().includes("label") || utilityType.toLowerCase().includes("manifest") ? USER_ORDER_LABELS : ACCEPT_LIST_MEESHO, enqueueSnackbar)
                                      })
                                      console.log('Variable is an array');
                                  }
                              }}
                            >
                                {utilityType.toLowerCase().includes("label") || utilityType.toLowerCase().includes("manifest") ? (
                                    <> <Iconify icon="vscode-icons:file-type-pdf2" width={30} height={30} /> {acType==="snapdeal" && <span
                                        style={{
                                            color: "black",
                                            fontSize: "10px",
                                            marginBottom: "1rem"
                                        }}>({cell.row.original.key.length})</span>}</>
                                ) : (
                                  <Iconify icon={'vscode-icons:file-type-excel'} width={30} height={30} />
                                )}
                            </IconButton>
                        </>
                      ) : (
                        <div>null</div>
                      )}
                  </div>
                );

            }

            // Cell: ({cell}) =>
            //     cell.row.original.key ? (

            //     ) : (
            //         <CircularProgress sx={{color: SystemColor.originColor}} size={24} color="inherit"/>
            //     ),
        },
    ];

    // handle select account
    // const handleSelectedAccount = (event, value) => {
    //     const filterData = [];
    //     const selectedAccounts = [];
    //     const selectedOptions = value;
    //
    //     if (selectedOptions.length > 0) {
    //         const isSelectAll = selectedOptions.some((vendor) => vendor.value === 'all');
    //
    //         if (isSelectAll) {
    //             userData.forEach((item) => {
    //                 if (item.value !== 'all') selectedAccounts.push(item);
    //             });
    //         } else {
    //             selectedOptions.forEach((item) => {
    //                 selectedAccounts.push(item);
    //             });
    //         }
    //
    //         selectedOptions.forEach((item) => {
    //             filterData.push(item);
    //         });
    //     }
    //     setSelectedAccountsData([selectedAccounts[0]]);
    //     console.log(selectedAccounts)
    //     if(selectedAccounts.length>0){
    //         setDimention({"L": selectedAccounts.LBHW?.L || 0.00, "B": selectedAccounts.LBHW?.B || 0.00, "H": selectedAccounts.LBHW?.H || 0.00, "W": selectedAccounts.LBHW?.W || 0.00})
    //     }};
    const handleSelectedAccount = (event, value) => {
        if (value) {
            // If a single account is selected, update the selectedAccountsData state with that account
            setSelectedAccountsData([value]);
            // console.log(value);
            // Update dimension state based on the selected account
            setDimention({
                "L": value.LBHW?.L || 0.00,
                "B": value.LBHW?.B || 0.00,
                "H": value.LBHW?.H || 0.00,
                "W": value.LBHW?.W || 0.00
            });
        } else {
            // If no account is selected, reset the selectedAccountsData state and log it
            setSelectedAccountsData([]);
            // console.log("No account selected");
            // Reset dimension state
            setDimention({"L": 0.00, "B": 0.00, "H": 0.00, "W": 0.00});
        }
    };

    // getting table data
    const getHistoyTableData = async () => {
        setIsLoading(true);
        const body = {
            username: user?.email ? user.email : localUser,
            actionType: 'getHistory',
            utilityType: utilityType,
            acType
        };
        const response = await CreatePostAPICall(enqueueSnackbar, Utility, body);
        if (response) {
            setHistoyTableData(response.data.body);
            setIsLoading(false);
        }
    };

    // generating accept list
    const generateAcceptList = async () => {
        setIsGenerateDisable(true);
        const tpEmailIds = [];
        const subAccountIds = [];
        const cNames = [];
        const isMaskingEnabledList = [];

        selectedAccountsData.forEach((data) => {
            tpEmailIds.push(data.Email);
            isMaskingEnabledList.push(data.isMaskingEnabled)
            subAccountIds.push(data.value);
            cNames.push(data.cName);
        });

        if (utilityType === 'generateLabel' && updateLbhw) {
            if (acType == "flipkart") {
                for (const key of Object.keys(dimention)) {
                    if (!dimention[key] || dimention[key] <= 0) {
                        enqueueSnackbar('Please Enter Dimension', {variant: 'error'});
                        setIsGenerateDisable(false);
                        return
                    }

                }
            }
        }
        if (tpEmailIds.length === 0) {
            enqueueSnackbar('Please select account.', {variant: 'error'});
        } else {
            const body = {
                username: user?.email ? user.email : localUser,
                tpEmailIds,
                acType: acType,
                subAccountIds,
                cNames,
                syncDataType: utilityType,
                isMaskingEnabledList: isMaskingEnabledList,
                shipmentType: filterValue.toLowerCase()
            };
            if (acType == "flipkart") {
                body["ddbDate"] = ddbDate;
                body["updateLbhw"] = updateLbhw
                for (const key of Object.keys(dimention)) {
                    body[key] = dimention[key]
                }
            }
            console.log("dateRange",dateRange)
            if (isPassDate){
                for(const key of Object.keys(dateRange)){
                    body[key] = dateRange[key]
                }
            }
            if (isDesktopEnvironment){
                window.document.getElementById("utilitiesData").values = JSON.stringify(body)
            }else{
                const response = await CreatePostAPICall(enqueueSnackbar, SyncOrders, body);
                if (response) {
                    enqueueSnackbar(response.data.body.message);
                }
            }
        }
        setIsGenerateDisable(false);
    };

    const handleInputChange = (key, value) => {
        const tempSize = {...dimention}
        tempSize[key] = parseFloat(value);
        setDimention(tempSize)
    };
    const handleBlur = (key) => {
        if (dimention[key].length === 0) {
            enqueueSnackbar('Please Enter Dimension', {variant: 'error'});
        } else {
            const tempSize = {...dimention}
            tempSize[key] = dimention[key].toFixed(2);
            setDimention(tempSize)
        }
    };

    // refreshHistoyTableData
    const refreshHistoyTableData = () => {
        getHistoyTableData();
    };

    // by default call for all page
    useEffect(() => {
        getHistoyTableData();
    }, []);

    const coach = coachList[activateNumbers]

    return (
        <>
            <Container maxWidth={themeStretch ? false : 'xl'}>
                {checkStatusOfInstruction('utilitiesFlipkart') && (<CoachMark {...coach}/>)}
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12} lg={12} sx={{pb: 2}}>
                                {acType == "flipkart" ? (<>
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        value={ddbDate}
                                        sx={{mb: "1rem"}}
                                        name="radio-buttons-group"
                                    >
                                        <FormLabel style={{
                                            marginLeft: "0.71em",
                                            marginTop: "-0.71em",
                                            paddingLeft: "0.44em",
                                            zIndex: 2,
                                            width: "2rem",
                                            backgroundColor: "#ffffff",
                                            position: "absolute",
                                            fontSize: "0.75em"
                                        }}>Slot</FormLabel>
                                        <List dense sx={{
                                            padding: ".4rem",
                                            borderRadius: 1,
                                            border: 1,
                                            borderColor: 'grey.300',
                                            "&:hover": {borderColor: 'grey.200'}
                                        }}>
                                            {["NEXT_SLOT", "ALL", "TODAY_ALL", "ONLY_BREACHED"].map((value, i) => (

                                                <FormControlLabel
                                                    ref={i === 0 ? slotNext : i === 1 ? slotAll : i === 2 ? slotToday : slotBreached}
                                                    key={value}
                                                    value={value}
                                                    control={<Radio/>}
                                                    label={value}
                                                    onChange={() => {
                                                        setDdbDate(value);
                                                    }}
                                                />
                                            ))}
                                        </List>
                                    </RadioGroup>
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        value={filterValue}
                                        sx={{mb: "1rem"}}
                                        name="radio-buttons-group"
                                    >
                                        <FormLabel style={{
                                            marginLeft: "0.71em",
                                            marginTop: "-0.71em",
                                            paddingLeft: "0.44em",
                                            zIndex: 2,
                                            width: "2rem",
                                            backgroundColor: "#ffffff",
                                            position: "absolute",
                                            fontSize: "0.75em"
                                        }}>Filter</FormLabel>
                                        <List dense sx={{
                                            padding: ".4rem",
                                            borderRadius: 1,
                                            border: 1,
                                            borderColor: 'grey.300',
                                            "&:hover": {borderColor: 'grey.200'}
                                        }}>
                                            {["ALL", "SINGLE", "MULTI"].map((value, i) => (
                                                <FormControlLabel
                                                    ref={i === 0 ? filterAll : i === 1 ? filterSingle : filterMulti}
                                                    key={value}
                                                    value={value}
                                                    control={<Radio/>}
                                                    label={value}
                                                    onChange={() => {
                                                        setFilterValue(value);
                                                    }}
                                                />
                                            ))}
                                            {utilityType === 'generateLabel' && <>
                                                {/*<div style={{ border: 'dashed 1px black',display:"inline", height:"100%",width:"1px"}}> </div>*/}
                                                <Divider orientation="vertical" sx={{
                                                    borderStyle: 'dashed',
                                                    display: "inline",
                                                    margin: "0 1rem 0 1rem"
                                                }} flexItem placeholder={"span"}/>
                                                <box ref={updateLBWH}>
                                                    Update LBHW
                                                    <IOSSwitch sx={{marginLeft: 1}} checked={updateLbhw}
                                                               onChange={(e) => {
                                                                   setUpdateLbhw(e.target.checked)
                                                               }}/>
                                                </box>
                                            </>}
                                        </List>
                                    </RadioGroup>
                                    {/*<Autocomplete*/}
                                    {/*    fullWidth*/}
                                    {/*    multiple*/}
                                    {/*    limitTags={2}*/}
                                    {/*    value={userAccount}*/}
                                    {/*    options={userData}*/}
                                    {/*    onChange={onSelectAccount}*/}
                                    {/*    getOptionLabel={(option) => {*/}
                                    {/*        return <>*/}
                                    {/*            {option.name}*/}
                                    {/*            {!(option.name === 'Select All') && (option.isMaskingEnabled ?*/}
                                    {/*                <div style={{*/}
                                    {/*                    color: '#029a02',*/}
                                    {/*                    display: 'inline-flex',*/}
                                    {/*                    verticalAlign: 'middle',*/}
                                    {/*                    alignItems: 'center'*/}
                                    {/*                }}>&nbsp;- &nbsp;<Iconify icon={'bi:check-circle-fill'} width={20}*/}
                                    {/*                                          height={20}/>*/}
                                    {/*                </div> : <div style={{*/}
                                    {/*                    color: '#fd0000',*/}
                                    {/*                    display: 'inline-flex',*/}
                                    {/*                    verticalAlign: 'middle',*/}
                                    {/*                    alignItems: 'center'*/}
                                    {/*                }}>&nbsp;- &nbsp;<Iconify icon={'material-symbols:cancel'} width={20}*/}
                                    {/*                                          height={20}/>*/}
                                    {/*                </div>)}*/}
                                    {/*        </>*/}
                                    {/*    }}*/}
                                    {/*    renderInput={(params) => <TextField {...params} label="Select Account*"*/}
                                    {/*                                        size={'medium'}/>}*/}
                                    {/*    getOptionDisabled={(option) => {*/}
                                    {/*        if (userAccount.some((value) => value.name === 'Select All')) {*/}
                                    {/*            return true;*/}
                                    {/*        }*/}
                                    {/*        if (userAccount.some((value) => value.value === option.AcType)) {*/}
                                    {/*            return true;*/}
                                    {/*        }*/}
                                    {/*        return false;*/}
                                    {/*    }}*/}
                                    {/*/>*/}
                                    <Autocomplete
                                        fullWidth
                                        multiple={false}
                                        // limitTags={2}
                                        value={userAccount} // Ensure the value is not undefined
                                        options={userData}
                                        onChange={onSelectAccount}
                                        getOptionLabel={(option) => option.name || ''} // Handle the case where option.name might be undefined
                                        renderOption={(props, option) => (
                                            <li {...props}>
                                                {option.name}
                                                {option.name !== 'Select All' && (
                                                    option.isMaskingEnabled ? (
                                                        <div style={{ color: '#029a02', display: 'inline-flex', verticalAlign: 'middle', alignItems: 'center' }}>
                                                            &nbsp;- &nbsp;<Iconify icon={'bi:check-circle-fill'} width={20} height={20}/>
                                                        </div>
                                                    ) : (
                                                        <div style={{ color: '#fd0000', display: 'inline-flex', verticalAlign: 'middle', alignItems: 'center' }}>
                                                            &nbsp;- &nbsp;<Iconify icon={'material-symbols:cancel'} width={20} height={20}/>
                                                        </div>
                                                    )
                                                )}
                                            </li>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Select Account*"
                                                size="medium"
                                            />
                                        )}

                                        // Display the selected value in the text field
                                        getOptionDisabled={(option) => {
                                            if (Array.isArray(userAccount)) {
                                                if (userAccount.some((value) => value.name === 'Select All')) {
                                                    return true;
                                                }
                                                if (userAccount.some((value) => value.value === option.AcType)) {
                                                    return true;
                                                }
                                            }
                                            return false;
                                        }}
                                    />

                                    {utilityType === 'generateLabel' && updateLbhw && (
                                        <Grid container spacing={3} sx={{mb: 2,mt:0.8, maxWidth: "1000px"}}>
                                            {Object.keys(dimention).map((key) =>
                                                <Grid item xs={12} sm={3} md={3}>
                                                    <TextField
                                                        type="number"

                                                        value={dimention[key]}
                                                        onChange={(e) => {
                                                            handleInputChange(key, e.target.value)
                                                        }}
                                                        onBlur={() => {
                                                            handleBlur(key)
                                                        }}
                                                        variant="outlined"
                                                        label={key === 'W' ? 'W (Kg)' : key}
                                                        fullWidth
                                                    />
                                                </Grid>
                                            )}
                                        </Grid>
                                    )}

                                </>):<><Autocomplete
                                    fullWidth
                                    multiple
                                    limitTags={2}
                                    value={userAccount}
                                    options={userData}
                                    onChange={onSelectAccount}
                                    getOptionLabel={(option) => {
                                        return <>
                                            {option.name}
                                            {!(option.name === 'Select All') && (option.isMaskingEnabled ?
                                                <div style={{
                                                    color: '#029a02',
                                                    display: 'inline-flex',
                                                    verticalAlign: 'middle',
                                                    alignItems: 'center'
                                                }}>&nbsp;- &nbsp;<Iconify icon={'bi:check-circle-fill'} width={20}
                                                                          height={20}/>
                                                </div> : <div style={{
                                                    color: '#fd0000',
                                                    display: 'inline-flex',
                                                    verticalAlign: 'middle',
                                                    alignItems: 'center'
                                                }}>&nbsp;- &nbsp;<Iconify icon={'material-symbols:cancel'} width={20}
                                                                          height={20}/>
                                                </div>)}
                                        </>
                                    }}
                                    renderInput={(params) => <TextField {...params} label="Select Account*"
                                                                        size={'medium'}/>}
                                    getOptionDisabled={(option) => {
                                        if (userAccount.some((value) => value.name === 'Select All')) {
                                            return true;
                                        }
                                        if (userAccount.some((value) => value.value === option.AcType)) {
                                            return true;
                                        }
                                        return false;
                                    }}
                                />
                                    {isPassDate && <Grid container marginTop={1}>
                                        <Grid item xs={8} sm={8} md={8}></Grid>
                                        <Grid item xs={4} sm={4} md={4}>
                                            <RangePicker
                                                style={{width: "100%"}}
                                                width={"100%"}
                                                fullWidth
                                                popperPlacement="bottom"
                                                format={'DD/MM/YY'}
                                                onChange={(value, event) => getdate(value, event)}
                                                className={'createDateRangePicker'}
                                                pupClassName={'createDateRangePicker'}
                                                {...(!isLight && {
                                                    className: 'date-picker-dark',
                                                })}
                                            />
                                        </Grid></Grid>}
                                </>
                                }




                                <div style={{paddingTop: '15px', textAlign: 'end', marginBottom: '20px'}}>

                                    <Button
                                        variant="contained"
                                        sx={{marginRight: 2}}
                                        startIcon={<Iconify icon={'material-symbols:refresh'} width={25} height={25}/>}
                                        onClick={refreshHistoyTableData}
                                    >
                                        Refresh
                                    </Button>

                                    <Button variant="outlined" id={`${acType}${utilityType.charAt(0).toUpperCase() + utilityType.slice(1)}`} onClick={generateAcceptList}
                                            disabled={isGenerateDisable}>
                                        Generate
                                    </Button>
                                </div>
                                {isLoading ? <Card>
                                    <div style={{ textAlign: 'center', padding: '100px'}}>
                                        <CircularProgress sx={{color: SystemColor.originColor}} size={24}
                                                          color="inherit"/>
                                    </div>
                                </Card> : histoyTableData.length !== 0 ? (
                                    <DataGridTable
                                        columns={AcceptListColumns}
                                        data={histoyTableData}
                                        isLoading={isLoading}
                                    />
                                ) : (
                                    <NoDataFound message={'No Data Found'}/>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Input id="selectedAccountsData" value={JSON.stringify(selectedAccountsData)} type="hidden" />
                <input id="utilitiesData" type="hidden" />
            </Container>
        </>
    )
        ;
}

export default AcceptList;
