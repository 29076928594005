/* eslint-disable */
import { AppBar, Box, Stack, Toolbar } from '@mui/material';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import Iconify from '../../../components/Iconify';
import Logo from '../../../components/Logo';
import { IconButtonAnimate } from '../../../components/animate';
import { HEADER, NAVBAR } from '../../../config';
import useAuth from '../../../hooks/useAuth';
import useOffSetTop from '../../../hooks/useOffSetTop';
import useResponsive from '../../../hooks/useResponsive';
import useSettings from '../../../hooks/useSettings';
import { CreatePostAPICall, Home } from '../../../utils/apis';
import cssStyles from '../../../utils/cssStyles';
import AccountPopover from './AccountPopover';
import NotificationPopover from './NotificationPopover';
import OrderBalancePopover from './OrderBalancePopover';

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'isCollapse' && prop !== 'isOffset' && prop !== 'verticalLayout',
})(({ isCollapse, isOffset, verticalLayout, theme }) => ({
  ...cssStyles(theme).bgBlur(),
  boxShadow: 'none',
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('lg')]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
    ...(isCollapse && {
      width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
    }),
    ...(isOffset && {
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    }),
    ...(verticalLayout && {
      width: '100%',
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
      backgroundColor: theme.palette.background.default,
    }),
  },
}));

const DarkAndLightModeSwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          '#fff'
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: '#7635DC',
    width: 32,
    height: 32,
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        '#fff'
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
    borderRadius: 20 / 2,
  },
}));

const HeaderMainContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  height: HEADER.MOBILE_HEIGHT,
  [theme.breakpoints.up('lg')]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
  },
}));

DashboardHeader.propTypes = {
  onOpenSidebar: PropTypes.func,
  isCollapse: PropTypes.bool,
  verticalLayout: PropTypes.bool,
};

export default function DashboardHeader({ onOpenSidebar, isCollapse = false, verticalLayout = false }) {
  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;

  const isDesktop = useResponsive('up', 'lg');
  const { themeMode, onChangeMode } = useSettings();
  const localUser = window.localStorage.getItem('userEmailId');
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();

  const [isDarkMode, setIsDarkMode] = useState();
  const [notifications, setNotifications] = useState([]);
  const [userBalanceOrderCount, setUserBalanceOrderCount] = useState({
    ac_balance: 0,
    orders: 0,
  });

  const getUserOrderNitificationData = async () => {
    const getUserBalance = await CreatePostAPICall(enqueueSnackbar, Home, {
      username: user?.email ? user.email : localUser,
      actionType: 'getUserBalance',
    });

    setUserBalanceOrderCount({
      ac_balance: getUserBalance?.data?.body?.ac_balance,
      orders: getUserBalance?.data?.body?.orders,
    });

    const notificationResponse = await CreatePostAPICall(enqueueSnackbar, Home, {
      username: user?.email ? user.email : localUser,
      actionType: 'getNotifications',
    });
    setNotifications([]);
    if (notificationResponse) {
      const commonNotification = notificationResponse.data.body.common_notifications;
      const userNotification = notificationResponse.data.body.userwise_notifications;
      setNotifications([...commonNotification, ...userNotification]);
    }
  };

  useEffect(() => {
    if (themeMode === 'dark') {
      setIsDarkMode(true);
    } else {
      setIsDarkMode(false);
    }
    return () => {};
  }, [themeMode]);

  // get user balance and order count and notification data api call
  // useEffect(async () => {
  //   getUserOrderNitificationData();
  // }, [localStorage.getItem('userEmailId')]);

  return (
    <HeaderMainContainer sx={{ position: 'relative' }}>
      <RootStyle isCollapse={isCollapse} isOffset={isOffset} verticalLayout={verticalLayout}>
        <Toolbar
          sx={{
            minHeight: '100% !important',
            px: { lg: 5 },
          }}
        >
          {isDesktop && verticalLayout && <Logo sx={{ mr: 2.5 }} />}

          {!isDesktop && (
            <IconButtonAnimate onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
              <Iconify icon="eva:menu-2-fill" />
            </IconButtonAnimate>
          )}

          <Box sx={{ flexGrow: 1 }} />

          {/* <OrderBalancePopover userBalanceOrderCount={userBalanceOrderCount} displayName={user?.displayName}/>
        <NotificationPopover notifications={notifications} /> */}

          <DarkAndLightModeSwitch
            checked={isDarkMode}
            onChange={(e) => {
              const event = { target: { value: e.target.checked ? 'dark' : 'light' } };
              onChangeMode(event);
            }}
          />

          <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }} sx={{ ml: 1.5 }}>
            <AccountPopover />
          </Stack>
        </Toolbar>
      </RootStyle>
    </HeaderMainContainer>
  );
}
