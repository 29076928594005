/* eslint-disable */
import { Box, Button, Divider, Grid, IconButton, Tab, Tabs } from '@mui/material';
import { capitalCase } from 'change-case';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import DataGridTable from '../../../components/Common/DataGridTable';
import useTabs from '../../../hooks/useTabs';
import { CreatePostAPICall, MeeshoCreateTicket, V2Management } from '../../../utils/apis';
import moment from 'moment';
import ReactJson from 'react-json-view';

import { LoadingButton } from "@mui/lab";

import {JSONToCSVConvertor, downloadS3File, getFileSize, CreateExcelFile} from '../../../utils/utilities';
import DeleteIcon from "@mui/icons-material/Delete";
import { log } from "util";
import { RECEIVED_REPORTED_DATA, USER_ORDER_LABELS, UTILITY_LABEL_PROCESS } from '../../../utils/Constants';
import { Card, Typography } from 'antd';

const AdminAutoClaims = () => {
    const { currentBMUserTab, setCurrentBMUserTab } = useTabs('Submitted');
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(true);
    const [isFiltered, setIsFiltered] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [ErrorDataList, setErrorDataList] = useState([]);
    const [tableDataDefault, setTableDataDefault] = useState([]);
    const [SubmittedColumns, setSubmittedColumns] = useState([]);
    const [dataList, setDataList] = useState([]);
    const [dataDeleteList, setDataDeleteList] = useState([]);


    const CreateTicket = async (setIsLoading, data, ticketData) => {
        setTableData(oldValues => {
            return oldValues.filter((_, i) => {
                return (_.ticketId !== data.ticketId)
            })
        })
        const payloadData = {
            "actionType":"submitAutoClaim",
            // "username": data.username,
            // "tpEmailId": data.tpEmailId,
            // "subAccountId": data.subAccountId,
            // "ticketData": ticketData,
            "dataToUpdateAutoClaimTable": {
                "part_key": data.part_key,
                "sort_key": data.sort_key,
                // "ticketId": data.ticketId,
                // "noOfTry": data.noOfTry
            },
            // "isAutoCreate": true,
            // "createTicket": true
        }

        // await CreatePostAPICall(() => {
        // }, MeeshoCreateTicket, payloadData);
        await CreatePostAPICall(() => {
        }, V2Management, payloadData);
    }

    const downloadAssets = async (setIsLoading, ticketPayload, sub_order_no) => {

        for (const key of ["productAtch", "barcodeAtch", "videoAtch", "awbAtch","attachments"]) {
            const ImageList = ticketPayload["ticketData"][key]
            for (const image of ImageList) {
                const i = await downloadS3File(`${sub_order_no}/${image}`, true, RECEIVED_REPORTED_DATA, () => { })
            }
        }
        setIsLoading(false)

    }

    const commanSubmittedColumns = [

        {
            accessorKey: 'ticketId',
            header: 'Ticket Id',
            size: 20,
        },
        {
            accessorKey: 'sub_order_no',
            header: 'Sub Order Number',
            size: 20,
        },

        {
            accessorKey: 'ticketType',
            header: 'Ticket Type',
            size: 20,
        },

        {
            accessorKey: 'subAccountId',
            header: 'Sub Account Id',
            size: 20,
        },

        {
            accessorKey: 'tpEmailId',
            header: 'Tp Email Id',
            size: 20,
        },

        {
            accessorKey: 'username',
            header: 'User Name',
            size: 20,
        },
        {
            accessorKey: 'submittedDate',
            header: 'Submitted Date',
            size: 20,
            Cell: (cell) => moment(cell.row.original?.submittedDate).format('DD-MM-YYYY HH:mm'),
        },
        {
            accessorKey: 'ticketPayload',
            header: 'Ticket Payload',
            size: 20,
            Cell: (cell) => {
                return <ReactJson
                    name={"Payload"}
                    src={cell.row.original.ticketPayload}
                    displayObjectSize={false}
                    enableClipboard={true}
                    displayDataTypes={false}
                    collapsed
                />
            },
        },
        {
            accessorKey: 'Product_Image_With_Size',
            header: 'Assets Size',
            size: 20,
            Cell: (cell) => {
                const [assetSizeDetails, setAssetSizeDetails] = useState({});
                const ticketPayload = cell.row.original.ticketPayload
                const [isLoading, setIsLoading] = useState(false);

                const sub_order_no = ticketPayload["ticketData"]["sub_order_no"]
                // console.log(sub_order_no);
                const getAssetSizeDetails = async () => {
                    var tempData = {}
                    setIsLoading(true)
                    for (const key of ["productAtch", "barcodeAtch", "videoAtch", "awbAtch","attachments"]) {
                        const ImageList = ticketPayload["ticketData"][key]
                        for (const image of ImageList) {
                            if (Object.keys(tempData).includes(key)) {
                                tempData[key][image] = await getFileSize(RECEIVED_REPORTED_DATA, `${sub_order_no}/${image}`, enqueueSnackbar)
                            }
                            else {
                                tempData[key] = {}
                                tempData[key][image] = await getFileSize(RECEIVED_REPORTED_DATA, `${sub_order_no}/${image}`, enqueueSnackbar)
                            }
                        }
                    }
                    setIsLoading(false)
                    setAssetSizeDetails(tempData)
                }


                if (Object.keys(assetSizeDetails).length > 0) {
                    return <ReactJson
                        name={"Payload"}
                        src={assetSizeDetails}
                        displayObjectSize={false}
                        enableClipboard={true}
                        displayDataTypes={false}
                        collapsed
                    />
                }
                else {
                    return <LoadingButton loading={isLoading} onClick={() => {
                        getAssetSizeDetails()
                    }} variant={'outlined'}>
                        Asset Details
                    </LoadingButton>
                }


            },
        },
        {
            accessorKey: 'Download Assets',
            header: 'Download Assets',
            size: 20,
            Cell: (cell) => {
                const ticketPayload = cell.row.original.ticketPayload
                const sub_order_no = ticketPayload["ticketData"]["sub_order_no"]
                const [isLoading, setIsLoading] = useState(false);
                return <LoadingButton loading={isLoading} onClick={() => {
                    downloadAssets(setIsLoading, ticketPayload, sub_order_no)
                }} variant={'outlined'}>
                    Download Assets
                </LoadingButton>
            },
        },

        {
            accessorKey: 'ticketStatus',
            header: 'Ticket Status',
            size: 20,
        },

        {
            accessorKey: 'noOfTry',
            header: 'No Of Try',
            size: 20,
        },
        {
            accessorKey: 'CreateTicket',
            header: 'Create Ticket',
            size: 20,
            Cell: (cell) => {
                const index = cell.row.index;
                const [isLoading, setIsLoading] = useState(false);
                return <LoadingButton loading={isLoading} onClick={() => {
                    CreateTicket(setIsLoading, cell.row.original, cell.row.original.ticketPayload.ticketData)
                }} variant={'outlined'}>
                    Submit
                </LoadingButton>
            },
        },

    ];

    const ErrorColumns = [
        {
            header: 'No. Of Error',
            size: 20,
            Cell: (cell) => {
                return <div>{Object.values(cell.row.original)}</div>
            },
        },
        {
            header: 'Error Description',
            size: 20,
            Cell: (cell) => {
                return <div>{Object.keys(cell.row.original)}</div>
            },
        },
    ];
    const filterAndGetErrors = (errorKeys, table) => {
        if (!dataList.includes(errorKeys[0])) {
            dataList.push(errorKeys[0])
        }
        const isLast = errorKeys[0] === Object.keys(table.getSelectedRowModel().flatRows.at(-1).original).at(-1)
        if (isLast) {
            setIsFiltered(true)
            setIsLoading(true)

            setTableData(oldValues => {
                return oldValues.filter((_, i) => {
                    return dataList.includes(_.errorDetails)
                })
            })
            setIsLoading(false)
        }

    };

    const filterAndDeleteData = async (data, table) => {
        dataDeleteList.push(data)
        const isLast = data.ticketId === table.getSelectedRowModel().flatRows.at(-1).original.ticketId
        if (isLast) {
            // console.log(dataDeleteList)
            if (tableData.length === 0) {
                setTableData(tableDataDefault);
            } else {
                setTableData(oldValues => {
                    return oldValues.filter((_, i) => {
                        // console.log(dataDeleteList, _.sort_key)
                        return !(dataDeleteList.includes(_))
                    })
                })
            }

            for (const datum of dataDeleteList) {
                const body = {
                    actionType: "deleteAutoClaim",
                    claimType: "DPWU_Received",
                    ticketData: datum
                };
                await CreatePostAPICall(() => {
                }, V2Management, body);
            }

        }
    };

    const unCommanArray = {
        accessorKey: 'createDate',
        header: 'Created Date',
        size: 20,
        Cell: (cell) => moment(cell.row.original?.createDate).format('DD-MM-YYYY HH:mm'),
    }

    const errorDetailsArray = {
        accessorKey: 'errorDetails',
        header: 'Error Details',
        size: 20,
        Cell: (cell) => (
            <div>
                {cell.row.original?.errorDetails}
            </div>
        ),
    }
    const successDetailsArray = {
        accessorKey: 'errorDetails',
        header: 'Success Message',
        size: 20,
        Cell: (cell) => (
            <div>
                {cell.row.original?.errorDetails}
            </div>
        ),
    }

    const autoClaimsTabs = [
        {
            value: 'Submitted',
        },
        {
            value: 'ReTry',
        },
        {
            value: 'Error',
        },
        {
            value: 'Success',
        },
    ];
    const deleteTicket = async (sort_key, data) => {

        setTableData(oldValues => {
            return oldValues.filter((_, i) => {
                return (_.ticketId !== data.ticketId)
            })
        })
        const body = {
            actionType: "deleteAutoClaim",
            claimType: "DPWU_Received",
            ticketData: data
        };
        await CreatePostAPICall(() => {
            // Handle response if needed
        }, V2Management, body);
    };


    useEffect(() => {
        getTableData()
    }, [currentBMUserTab]);

    const getTableData = async () => {
        setIsLoading(true);
        if (!["Submitted","Success"].includes(currentBMUserTab)) {
            setSubmittedColumns([...commanSubmittedColumns.slice(0, 5), unCommanArray, ...commanSubmittedColumns.slice(5), errorDetailsArray, {
                accessorKey: 'delete',
                header: 'Delete',
                size: 10,
                Cell: ({ cell }) => (
                    <IconButton
                        aria-label="delete"
                        onClick={() => {
                            deleteTicket(cell.row.original.sort_key, cell.row.original);
                        }}
                    >
                        <DeleteIcon style={{ color: 'red' }} />
                    </IconButton>
                ),
            }])
        } else if (currentBMUserTab === "Success") {
            setSubmittedColumns([...commanSubmittedColumns.slice(0, 11), successDetailsArray])
        } else {
            setSubmittedColumns([...commanSubmittedColumns])
        }

        const payload = {
            actionType: 'getAutoClaims',
            claimType: 'DPWU_Received',
            ticketStatus: currentBMUserTab,
        };
        const response = await CreatePostAPICall(enqueueSnackbar, V2Management, payload);
        if (response) {
            var errorCountList = []


            for (var i = 0; i < response.data.body.length; i++) {
                var error = response.data.body[i].errorDetails;
                if (errorCountList.some(item => item[error] !== undefined)) {
                    errorCountList.forEach(item => {
                        if (item[error] !== undefined) {
                            item[error]++;
                        }
                    });
                } else {
                    var errorCount = {};
                    errorCount[error] = 1;
                    errorCountList.push(errorCount);
                }
                // Store sub_order_no in a variable
                var subOrderNo = response.data.body[i].ticketPayload["sub_order_no"];
                response.data.body[i]["sub_order_no"] = subOrderNo;

                response.data.body[i]["ticketPayload"] = {
                    "username": response.data.body[i].username,
                    "tpEmailId": response.data.body[i].tpEmailId,
                    "subAccountId": response.data.body[i].subAccountId,
                    "ticketData": response.data.body[i].ticketPayload,
                    "dataToUpdateAutoClaimTable": {
                        "part_key": response.data.body[i].part_key,
                        "sort_key": response.data.body[i].sort_key,
                        "ticketId": response.data.body[i].ticketId,
                        "noOfTry": response.data.body[i].noOfTry,
                    },
                    "isAutoCreate": true,
                    "createTicket": true,
                }
                response.data.body[i].Product_Image_With_Size = {}


            }


            setErrorDataList(errorCountList)
            setTableData(response.data.body);
            setTableDataDefault(response.data.body);
            // console.log("Set===>", response.data.body);
        }
        setIsLoading(false);
    };

    const exportToCsvData = () => {
        if (tableData.length !== 0) {

            CreateExcelFile(tableData, currentBMUserTab, true);
        }
    };

    const onChangeBMUserTab = (tab) => {
        setTableData([]);
        setCurrentBMUserTab(tab.target.innerText);
    }


    return (
        <>
            <Divider sx={{ p: 1 }} />

            <Grid item xs={12} sm={12} md={12}>
                <Tabs
                    allowScrollButtonsMobile
                    variant="scrollable"
                    scrollButtons="auto"
                    value={currentBMUserTab}
                    onChange={onChangeBMUserTab}
                >
                    {autoClaimsTabs.map((tab) => (
                        <Tab disableRipple key={tab.value} label={tab.value} icon={tab.icon}
                            value={tab.value} />
                    ))}
                </Tabs>
                <Grid container justifyContent={"space-between"} pt={1}>
                    <Grid item>

                            {tableData.length > 0 && (<Button
                                style={{ marginRight: "1rem" }}
                                variant="outlined"
                                onClick={() => {}}
                            >
                                Total Ticket : <strong>{tableData.length}</strong>
                            </Button>)}
                        
                    </Grid>
                    <Grid item>
                        <div style={{ justifyContent: "space-between", marginBottom: '15px' }}>

                            <Button
                                disabled={isLoading}
                                style={{ marginRight: "1rem" }}
                                variant="contained"
                                onClick={() => {
                                    exportToCsvData();
                                }}
                            >
                                Export Excel
                            </Button>
                            <Button
                                disabled={isLoading}
                                variant="contained"
                                onClick={() => {
                                    getTableData()
                                }}
                            >
                                Refresh
                            </Button>

                        </div>
                    </Grid>

                </Grid>

                <Box sx={{ mb: 5 }} />

                {!["Submitted","Success"].includes(currentBMUserTab) && (<><DataGridTable columns={ErrorColumns} data={ErrorDataList}
                    sorting isLoading={isLoading}
                    button1={'Get Error'}
                    funReported={(value, table) => {
                        setDataList([])
                        setTableData(tableDataDefault)
                        filterAndGetErrors(Object.keys(value), table);
                    }} checkbox />
                    <Box sx={{ mb: 5 }} />
                    {isFiltered && (
                        <div style={{ textAlign: 'end', marginBottom: '15px' }}>
                            <Button
                                disabled={isLoading}
                                variant="contained"
                                onClick={() => {
                                    setIsLoading(true)
                                    setIsFiltered(false)
                                    setTableData(tableDataDefault)
                                    setIsLoading(false)
                                }}
                            >
                                Remove Filter
                            </Button>
                        </div>
                    )}</>)}

                <DataGridTable button1={!["Submitted","Success"].includes(currentBMUserTab)}
                    funReported={(value, table) => {
                        setDataDeleteList([])
                        filterAndDeleteData(value, table);
                    }} checkbox={!["Submitted","Success"].includes(currentBMUserTab)} columns={SubmittedColumns}
                    data={tableData} sorting isLoading={isLoading}
                />

            </Grid>
        </>
    );
};

export default AdminAutoClaims;
