/* eslint-disable */
import { Autocomplete, TextField } from '@mui/material';
import React, { useState } from 'react';
import { isSuperAdminEnvironment } from '../../utils/utilities';
import Iconify from '../Iconify';

export default function AccountSelector(props) {
  const { allData, onChangeHandler, disabled, size } = props;

  const [userAccount, setUserAccount] = useState([]);

  const onSelectAccount = (e, value) => {
    const selectAllArray = value.filter((data) => data.value === 'all');

    const selectAllFlipkartArray = value.filter((data) => data.value === 'flipkart');
    const selectAllMeeshoArray = value.filter((data) => data.value === 'meesho');
    const selectAllGlowroadArray = value.filter((data) => data.value === 'glowroad');

    let selectOther = value;

    if (selectAllMeeshoArray?.length > 0) {
      selectOther = selectOther.filter((data) => data.AcType !== 'meesho');
    }
    if (selectAllFlipkartArray?.length > 0) {
      selectOther = selectOther.filter((data) => data.AcType !== 'flipkart');
    }
    if (selectAllGlowroadArray?.length > 0) {
      selectOther = selectOther.filter((data) => data.AcType !== 'glowroad');
    }

    if (selectAllArray?.length > 0) {
      setUserAccount(selectAllArray);
      onChangeHandler(e, selectAllArray);
    } else {
      setUserAccount(selectOther);
      onChangeHandler(e, selectOther);
    }
  };

  return (
    <Autocomplete
      fullWidth
      multiple
      limitTags={2}
      value={userAccount}
      options={allData}
      onChange={onSelectAccount}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => (
        <li {...props}>
          {option.name}
          {isSuperAdminEnvironment &&
            !option.name.includes('Select All') && (
              option.accountStatus === 'ACTIVATE' ? (
                <div
                  style={{
                    color: '#029a02',
                    display: 'inline-flex',
                    verticalAlign: 'middle',
                    alignItems: 'center',
                  }}
                >
                  &nbsp;- &nbsp;<Iconify icon={'bi:check-circle-fill'} width={20} height={20} />
                </div>
              ) : (
                <div
                  style={{
                    color: '#fd0000',
                    display: 'inline-flex',
                    verticalAlign: 'middle',
                    alignItems: 'center',
                  }}
                >
                  &nbsp;- &nbsp;<Iconify icon={'material-symbols:cancel'} width={20} height={20} />
                </div>
              )
            )}
        </li>
      )}
      renderInput={(params) => <TextField {...params} label="Select Account*" size={size} />}
      getOptionDisabled={(option) => {
        if (userAccount.some((value) => value.name === 'Select All')) {
          return true;
        }
        if (userAccount.some((value) => value.value === option.AcType)) {
          return true;
        }
        return false;
      }}
      {...(disabled && {
        disabled,
      })}
    />
  );
}