/* eslint-disable */
import {
    Autocomplete,
    Box,
    Button,
    Card,
    CardContent,
    Checkbox, CircularProgress,
    Container,
    DialogContent,
    Divider,
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import {useSnackbar} from 'notistack';
import React, {useEffect, useState,useRef} from 'react';
import DataGridTable from '../../components/Common/DataGridTable';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Page from '../../components/Page';
import useAuth from '../../hooks/useAuth';
import useSettings from '../../hooks/useSettings';
import urlConstants from '../../routes/urlConstants';
import {CreatePostAPICall, OrderAnalysis, ScanOrder} from '../../utils/apis';
import {
    BootstrapDialog,
    BootstrapDialogTitle,
    getUserAccountDetails,
    IOSSwitch,
    playFailSound,
    playSuccessSound,
    SystemColor,
} from '../../utils/utilities';
import Iconify from "../../components/Iconify";
import {LoadingButton} from "@mui/lab";

export default function ScanOrders() {
    const {enqueueSnackbar} = useSnackbar();
    const {user} = useAuth();
    const localUser = window.localStorage.getItem('userEmailId');
    const [condition, setCondition] = useState('COMPLETE');
    const [receivedAwb, setReceivedAwb] = useState('');
    const [pickupAwb, setPickupAwb] = useState('');
    const [awbData, setAwbData] = useState([]);
    const [subOrderData, setSubOrderData] = useState([]);
    const [scanMode, setScanMode] = useState(false);
    const [detailMode, setDetailMode] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [detailModeEntryModal, setDetailModeEntryModal] = useState(false);
    const [ordersData, setOrdersData] = useState([]);
    const [returnAWBNumber, setReturnAWBNumber] = useState('');
    const [returnSubOrderNumber, setReturnSubOrderNumber] = useState('');
    const [pickupAWBNumber, setPickupAWBNumber] = useState('');
    const [pickupSubOrderNumber, setPickupSubOrderNumber] = useState('');
    const [baglinkAWBNumber, setBaglinkAWBNumber] = useState('');
    const [baglinkPacketId, setBaglinkbagPacketId] = useState('');
    const [focusFiled, setFocusField] = useState('');
    const [tempLoading, setTempLoading] = useState(false);
    const [baglinkSubOrderNumber, setBaglinkSubOrderNumber] = useState(null);
    const [scannedType, setScannedType] = useState({});
    const [confirmDialog, setConfirmDialog] = useState(null);
    const [subOrderList, setSubOrderList] = useState([]);
    // useEffect(() => {
    //     console.log("subOrderList",subOrderList)
    // }, [subOrderList]);


    const bagLinkAWBRef = useRef(null);
    const bagLinkIdRef = useRef(null);

    useEffect(() => {
        // console.log("scanMode",scanMode)
        if (focusFiled !== "") {
            // console.log(focusFiled)
            if(focusFiled === "bagPacketId" && bagLinkIdRef.current) {
                bagLinkIdRef.current.focus();
            }else if(bagLinkAWBRef.current) {
                bagLinkAWBRef.current.focus();
            }
        }
    }, [focusFiled])
    const {themeStretch} = useSettings();

    // select condition options
    const selectConditionData = ['COMPLETE', 'WRONG', 'MISSING_QT', 'DAMAGE', 'USED', 'REJECTED', 'NON_BARCODED_RTO'];

    // handle ios switch
    const toggleChange = (event, cell) => {
        const cloneOriginalData = [...ordersData];
        cloneOriginalData[cell.row.index].checked = !cell.row.original.checked;
        setOrdersData(cloneOriginalData);
    };
    // handle details mode
    const setDetailConditionMode = (event, cell, value) => {
        const cloneOriginalData = [...ordersData];
        cloneOriginalData[cell.row.index].product_condition = value.props.value;
        setOrdersData(cloneOriginalData);
    };
    // Order Details Submit API CALL...
    const submitOrderDetails = () => {
        closeDetailEntryModal();
        const latestScanData = [...ordersData];

        latestScanData.forEach((latestScanData) => {
            if (latestScanData.checked === false) return;
            const body = {
                condition: latestScanData.product_condition === '' ? selectConditionData[0] : latestScanData.product_condition,
                detail_scan: latestScanData.checked,
                part_key: latestScanData.part_key,
                received: latestScanData.Is_Received,
                sort_key: latestScanData.sort_key,
                username: user?.email ? user.email : localUser,
                actionType: 'receivedEntry',
            };
            const response = CreatePostAPICall(enqueueSnackbar, ScanOrder, body, 'Success.');
            if (response) {
                playSuccessSound();
            }
            else {
                playFailSound();
            }
        });
    };
    // columns for details mode entry
    const columns = [{
        accessorKey: '#',
        header: '#',
        size: 10,
        Cell: ({cell}) => (
            <Checkbox checked={cell.row.original?.checked} onChange={(event) => toggleChange(event, cell)}/>),
    }, {
        accessorKey: 'Sub_Order_No', header: 'SubOrder Number', size: 10,
    }, {
        accessorKey: 'Sku', header: 'SKU', size: 10,
    }, {
        accessorKey: 'quantity', header: 'Quantity', size: 20,
    }, {
        accessorKey: 'productCondition', header: 'Product Condition', Cell: ({cell}) => (<>
            <FormControl sx={{m: 1, width: '100%'}}>
                <InputLabel id="returnCondition">Select Condition</InputLabel>
                <Select
                    labelId="returnCondition"
                    value={cell.row.original.product_condition === '' ? selectConditionData[0] : cell.row.original.product_condition}
                    label="Select Condition"
                    onChange={(event, value) => {
                        setDetailConditionMode(event, cell, value);
                    }}
                    MenuProps={MenuProps}
                >
                    {selectConditionData.map((name) => (<MenuItem key={name} value={name}>
                        {name}
                    </MenuItem>))}
                </Select>
            </FormControl>
        </>),
    },];
    // detail modal close
    const closeDetailEntryModal = () => {
        setDetailModeEntryModal(false);
    };
    // detail modal open
    const openDetailEntryModal = () => {
        setDetailModeEntryModal(!detailModeEntryModal);
    };

    // for making return entry for all components
    const makeReturnEntry = async (event, inputValue, isReceived, isAwb) => {
        if (event.key === 'Enter' && inputValue !== '' && inputValue !== null) {
            try {
                const accountDetails = await getUserAccountDetails();
                // console.log(accountDetails)
                const tpEmails = accountDetails.tpEmailids;
                const cNames = accountDetails.cName;
                const suppliers = accountDetails.supplierIds;
                const acTypes = accountDetails.acTypes;

                const body = {
                    username: user?.email ? user.email : localUser,
                    tpEmailIds: tpEmails,
                    subAccountIds: suppliers,
                    acTypes,
                    cNames: cNames
                };

                if (isAwb) {
                    body.awb = inputValue;
                    body.actionType = 'pickup';
                } else {
                    body.suborder_no = inputValue;
                    body.actionType = 'pickup';
                }

                if (isReceived) {
                    body.detail_scan = detailMode;
                    body.received = true;
                    body.condition = condition;
                    body.actionType = 'receivedEntry';
                } else {
                    body.pickup = true;
                }

                setIsLoading(true);

                if (detailMode) {
                    openDetailEntryModal();
                    const response = await CreatePostAPICall(enqueueSnackbar, ScanOrder, body);

                    if (response && response.data.statusCode === 200) {
                        setOrdersData(response.data.body);
                    } else {
                        setDetailModeEntryModal(false);
                    }
                    setIsLoading(false);
                } else {
                    const response = await CreatePostAPICall(enqueueSnackbar, ScanOrder, body);

                    if (response) {
                        enqueueSnackbar(detailMode ? 'Success.' : response.data.body);
                        playSuccessSound();
                        let type = isReceived ? isAwb ? 'Received_AWB' : 'Received_SubOrder_No' : isAwb ? 'PickUp_AWB' : 'PickUp_SubOrder_No';

                        if (!(type in scannedType)) {
                            scannedType[type] = 1;
                        } else {
                            scannedType[type] += 1;
                        }
                    } else {
                        playFailSound();
                    }

                    setAwbData([]);
                    setSubOrderData([]);
                    setIsLoading(false);
                }

                setPickupAwb('');
                setPickupAWBNumber('');
                setReceivedAwb('');
                setReturnAWBNumber('');
                setReturnSubOrderNumber('');
                setPickupSubOrderNumber('');
            } catch (error) {
                console.error(error);
            }
        }
    };

    const getOrderDetails = async (inputValue,foced=false) => {
        setFocusField("")
        setTempLoading(true)
        const accountDetails = await getUserAccountDetails();
        const tpEmails = accountDetails.tpEmailids;
        const cNames = accountDetails.cName;
        const suppliers = accountDetails.supplierIds;
        const acTypes = accountDetails.acTypes;

        const body = {
            username: user?.email ? user.email : localUser,
            tpEmailIds: tpEmails,
            subAccountIds: suppliers,
            acType: acTypes,
            cNames: cNames,
            awb: inputValue,
            actionType: 'awb'
        };
        const response = await CreatePostAPICall(enqueueSnackbar, OrderAnalysis, body);
        const alreadyLinked = []
        const newToLink = []

        if (response && response.data.statusCode === 200) {
            // console.log("orderData", response.data.body);
            for (const [platform, accountData] of Object.entries(response.data.body)) {
                // console.log("platform",platform)
                for (const [email, tpEmailData] of Object.entries(accountData)) {
                    // console.log("email",email)
                    for (const [tpEmailId, subOrderData] of Object.entries(tpEmailData)) {
                        // console.log("tpEmailId",tpEmailId)
                        if (subOrderData.length > 0) {
                            // console.log("subOrderData",subOrderData)
                            for (const subOrder of subOrderData) {
                                if (subOrder.includes(inputValue)) {
                                    setBaglinkSubOrderNumber({
                                        platform: platform, email: email, tpEmailId: tpEmailId, subOrder: subOrder[0],awb:inputValue
                                    })
                                    if(!foced && subOrder[34]){
                                        alreadyLinked.push({
                                            platform: platform, email: email, tpEmailId: tpEmailId, subOrder: subOrder[0],awb:inputValue
                                        })
                                    }else {
                                        newToLink.push({
                                            platform: platform, email: email, tpEmailId: tpEmailId, subOrder: subOrder[0],awb:inputValue
                                        })
                                        setConfirmDialog(null)
                                    }

                                }
                            }
                        }
                    }
                }
            }
            setTempLoading(false)
            if (newToLink.length <= 0 && alreadyLinked.length <= 0) {
                playFailSound()
                setFocusField("bagelinkAWBNumber")
                setBaglinkAWBNumber("")
                enqueueSnackbar('AWB not found. Please check the AWB number and try again.', { variant: 'error' });
            }else {
                if(alreadyLinked.length > 0){
                    setConfirmDialog(inputValue)
                    playFailSound()
                }
                else{
                    playSuccessSound()
                }
                setSubOrderList([...alreadyLinked,...newToLink])
                setFocusField('bagPacketId')
            }
        }
    }

    // handle showing all awb order options
    const onAwbOptionData = async (event, awbValue) => {
        if (awbValue.length > 0) {
            const accountDetails = await getUserAccountDetails();
            const tpEmails = accountDetails.tpEmailids;
            const suppliers = accountDetails.supplierIds;
            const cNames = accountDetails.cName;
            const acTypes = accountDetails.acTypes;
            const body = {
                username: user?.email ? user.email : localUser,
                tpEmailIds: tpEmails,
                subAccountIds: suppliers,
                acType: acTypes,
                cNames: cNames,
                awb: awbValue,
                get_hints: true,
            };
            const response = await CreatePostAPICall(enqueueSnackbar, OrderAnalysis, body);
            if (response) {
                const newArr = [];
                response.data.body.forEach((value) => {
                    if (newArr.indexOf(value) === -1) newArr.push(value);
                });
                setAwbData(newArr);
            }
        } else {
            setAwbData([]);
        }
    };

    // handle showing all subOrder order options
    const onSuborderOptionData = async (event, suborderNo) => {
        if (suborderNo.length > 0) {
            const accountDetails = await getUserAccountDetails();
            const tpEmails = accountDetails.tpEmailids;
            const suppliers = accountDetails.supplierIds;
            const cNames = accountDetails.cName;
            const acTypes = accountDetails.acTypes;

            const body = {
                username: user?.email ? user.email : localUser,
                tpEmailIds: tpEmails,
                subAccountIds: suppliers,
                acType: acTypes,
                suborder_no: suborderNo,
                get_hints: true,
                cNames: cNames
            };
            const response = await CreatePostAPICall(enqueueSnackbar, OrderAnalysis, body);

            if (response) {
                const newArr = [];
                response.data.body.forEach((value) => {
                    if (newArr.indexOf(value) === -1) newArr.push(value);
                });
                setSubOrderData(newArr);
            }
        } else {
            setSubOrderData([]);
        }
    };
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 48 * 4.5 + 68, width: 250,
            },
        },
    };
    const updateScanModel = () => {
        setScanMode((prevState) => {
            setScanMode(!prevState);
        });
    };

    // handle setting detail mode
    const updateDetailModel = () => {
        setDetailMode((prevState) => {
            setDetailMode(!prevState);
        });
    };
    const clearKey = (key) => {
        const updatedScannedType = {...scannedType}; // Create a copy of the object
        delete updatedScannedType[key]; // Remove the key
        setScannedType(updatedScannedType); // Update the state with the modified object
        setConfirmDialog(null)
    };
    const linkBag = (baglinkPacketId) => {
        if(baglinkPacketId === baglinkAWBNumber){
            setBaglinkbagPacketId("")
            enqueueSnackbar('Bag Packet Id can not be same as AWB Number. Please scan again', { variant: 'error' });
            playFailSound()
            return
        }
        var isError = false;
        for(const order of subOrderList){
            // console.log("order",order)
            const {platform, email, tpEmailId, subOrder,awb} = order
            const body ={
                username: user?.email ? user.email : localUser,
                "tpEmailId": email,
                "subAccountId":tpEmailId,
                "acType":platform,
                "bagPacketId":baglinkPacketId,
                "subOrderNo":subOrder,
                "actionType":"bagLink",
                "pickupEntry":true,
            }
            const response = CreatePostAPICall(enqueueSnackbar, ScanOrder, body );
            if (response) {
                enqueueSnackbar(`${baglinkPacketId} linked with ${awb}`, { variant: 'success' });
                playSuccessSound();
            }
            else {
                isError = true
                playFailSound();
            }
        }
        if(isError){
            enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
        }
        else{
            setBaglinkAWBNumber('')
            setBaglinkbagPacketId('')
            setBaglinkSubOrderNumber(null)
            setFocusField('bagelinkAWBNumber')
        }

    }
    const closeDialogBox = (e) =>{
        setBaglinkAWBNumber('')
        setBaglinkbagPacketId('')
        setBaglinkSubOrderNumber(null)
        setConfirmDialog(null)
        setFocusField('bagelinkAWBNumber')
    }

    return (<Page title="ScanOrders">
        <Container maxWidth={themeStretch ? false : 'xl'}>
            <HeaderBreadcrumbs
                heading="Scan Orders"
                links={[{name: 'Dashboard', href: urlConstants.HOME_PAGE}, {name: 'Scan Orders'}]}
            />

            <Card sx={{p: 2, width: '100%'}}>
                <h4>
                    <Box sx={{flexGrow: 1}}>
                        <Grid container spacing={1}>
                            <Grid item xs={6} md={3} lg={2.5}>
                                Scan Mode <IOSSwitch sx={{m: 1}} checked={scanMode} onChange={updateScanModel}/>
                            </Grid>
                            <Grid item xs={6} md={3} lg={2.5}>
                                Detail Mode <IOSSwitch sx={{m: 1}} checked={detailMode}
                                                       onChange={updateDetailModel}/>
                            </Grid>
                        </Grid>
                    </Box>
                </h4>
            </Card>

            <div style={{paddingTop: '30px'}}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={7.5} lg={7.5}>
                        <Card sx={{p: 2, width: '100%'}}>
                            <h2>Received Entry</h2>
                            <Divider sx={{borderStyle: 'dashed', marginTop: '10px', marginBottom: '20px'}}/>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6} lg={6}>
                                    <FormControl sx={{m: 1, width: '100%'}}>
                                        <InputLabel id="returnCondition">Select Condition</InputLabel>
                                        <Select
                                            labelId="returnCondition"
                                            value={condition}
                                            label="Select Condition"
                                            onChange={(event) => {
                                                setCondition(event.target.value);
                                            }}
                                            MenuProps={MenuProps}
                                        >
                                            {selectConditionData.map((name) => (<MenuItem key={name} value={name}>
                                                {name.replaceAll('_', ' ')}
                                            </MenuItem>))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    md={6}
                                    lg={6}
                                    onKeyPress={(event) => makeReturnEntry(event, receivedAwb, true, true)}
                                >
                                    {(scannedType.Received_AWB && scannedType.Received_AWB !== 0) && (<Card style={{
                                        height: "3rem", borderRadius: "10px", padding: ".4rem", marginLeft: ".5rem"
                                    }}>
                                        <Grid container justifyContent={'space-between'}>

                                            <Typography sx={{pt: ".4rem"}} variant={'subtitle1'}>Scanned AWB
                                                : {scannedType.Received_AWB}</Typography>
                                            <LoadingButton onClick={() => {
                                                clearKey('Received_AWB')
                                            }} variant={'outlined'}>Clear</LoadingButton>
                                        </Grid>

                                    </Card>)}
                                    {scanMode ? (<FormControl required sx={{m: 1, width: '100%'}}>
                                        <TextField
                                            variant="outlined"
                                            type="text"
                                            label="Click Here To Scan Received Orders"
                                            value={receivedAwb}
                                            onChange={(event) => {
                                                setReceivedAwb(event.target.value);
                                            }}
                                        />
                                    </FormControl>) : (<FormControl sx={{m: 1, width: '100%'}}>
                                        <Autocomplete
                                            onChange={(event, receivedAwb) => {
                                                event.key = 'Enter';
                                                setReturnAWBNumber(receivedAwb);
                                                makeReturnEntry(event, receivedAwb, true, true)
                                            }}
                                            options={awbData}
                                            onInputChange={onAwbOptionData}
                                            value={returnAWBNumber}
                                            renderInput={(params) => <TextField {...params}
                                                                                label="Return AWB Number"
                                            />}
                                        />
                                    </FormControl>)}
                                </Grid>
                            </Grid>
                            <Grid container spacing={3} style={{justifyContent: 'end'}}>
                                <Grid item xs={12} md={6} lg={6}>
                                    <h4 style={{textAlign: 'center', padding: '10px'}}> OR </h4>
                                    {(scannedType.Received_SubOrder_No && scannedType.Received_SubOrder_No !== 0) && (
                                        <Card style={{
                                            height: "3rem",
                                            borderRadius: "10px",
                                            padding: ".4rem",
                                            marginLeft: ".5rem"
                                        }}>
                                            <Grid container justifyContent={'space-between'}>

                                                <Typography sx={{pt: ".4rem"}} variant={'subtitle1'}>Scanned
                                                    SubOrder
                                                    : {scannedType.Received_SubOrder_No}</Typography>
                                                <LoadingButton onClick={() => {
                                                    clearKey('Received_SubOrder_No')
                                                }} variant={'outlined'}>Clear</LoadingButton>
                                            </Grid>

                                        </Card>)}
                                    <FormControl sx={{m: 1, width: '100%'}}>
                                        <Autocomplete
                                            onChange={(event, receivedSubOrderNo) => {
                                                event.key = 'Enter';
                                                setReturnSubOrderNumber(receivedSubOrderNo);
                                                makeReturnEntry(event, receivedSubOrderNo, true, false)
                                            }}
                                            options={subOrderData}
                                            onInputChange={onSuborderOptionData}
                                            value={returnSubOrderNumber}
                                            renderInput={(params) => <TextField {...params}
                                                                                label="Return Sub Order Number"/>}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>

                    <Grid item xs={12} md={4.5} lg={4.5}>
                        <Card sx={{p: 2, width: '100%'}}>
                            <h2>Pickup Entry</h2>
                            <Divider sx={{borderStyle: 'dashed', marginTop: '10px', marginBottom: '20px'}}/>
                            <Grid container spacing={3}>
                                <Grid
                                    item
                                    xs={12}
                                    md={12}
                                    lg={12}
                                    onKeyPress={(event) => makeReturnEntry(event, pickupAwb, false, true)}
                                >
                                    {(scannedType.PickUp_AWB && scannedType.PickUp_AWB !== 0) && (<Card style={{
                                        height: "3rem", borderRadius: "10px", padding: ".4rem", marginLeft: ".5rem"
                                    }}>
                                        <Grid container justifyContent={'space-between'}>

                                            <Typography sx={{pt: ".4rem"}} variant={'subtitle1'}>Scanned AWB
                                                : {scannedType.PickUp_AWB}</Typography>
                                            <LoadingButton onClick={() => {
                                                clearKey('PickUp_AWB')
                                            }} variant={'outlined'}>Clear</LoadingButton>
                                        </Grid>

                                    </Card>)}
                                    {scanMode ? (<FormControl sx={{m: 1, width: '100%'}}>
                                        <TextField
                                            variant="outlined"
                                            type="text"
                                            label="Click Here To Scan Pickup Orders"
                                            value={pickupAwb}
                                            onChange={(event) => {
                                                setPickupAwb(event.target.value);
                                            }}
                                        />
                                    </FormControl>) : (<FormControl sx={{m: 1, width: '100%'}}>
                                        <Autocomplete
                                            onChange={(event, pickupAwb) => {
                                                event.key = 'Enter';
                                                setPickupAWBNumber(pickupAwb);
                                                makeReturnEntry(event, pickupAwb, false, true)
                                            }}
                                            options={awbData}
                                            onInputChange={onAwbOptionData}
                                            value={pickupAWBNumber}
                                            renderInput={(params) => <TextField {...params}
                                                                                label="PickUp AWB Number"/>}
                                        />
                                    </FormControl>)}
                                </Grid>
                            </Grid>
                            <Grid container spacing={3} style={{justifyContent: 'start'}}>
                                <Grid item xs={12} md={12} lg={12}>
                                    <h4 style={{textAlign: 'center', padding: '10px'}}> OR </h4>
                                    {(scannedType.PickUp_SubOrder_No && scannedType.PickUp_SubOrder_No !== 0) && (
                                        <Card style={{
                                            height: "3rem",
                                            borderRadius: "10px",
                                            padding: ".4rem",
                                            marginLeft: ".5rem"
                                        }}>
                                            <Grid container justifyContent={'space-between'}>

                                                <Typography sx={{pt: ".4rem"}} variant={'subtitle1'}>Scanned
                                                    SubOrder
                                                    : {scannedType.PickUp_SubOrder_No}</Typography>
                                                <LoadingButton onClick={() => {
                                                    clearKey('PickUp_SubOrder_No')
                                                }} variant={'outlined'}>Clear</LoadingButton>
                                            </Grid>

                                        </Card>)}
                                    <FormControl sx={{m: 1, width: '100%'}}>
                                        <Autocomplete
                                            onChange={(event, pickUpSubOrderNo) => {
                                                event.key = 'Enter';
                                                setPickupSubOrderNumber(pickUpSubOrderNo);
                                                makeReturnEntry(event, pickUpSubOrderNo, false, false)
                                            }}
                                            options={subOrderData}
                                            onInputChange={onSuborderOptionData}
                                            value={pickupSubOrderNumber}
                                            renderInput={(params) => <TextField {...params}
                                                                                label="PickUp Sub Order Number"/>}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Card>
                        <BootstrapDialog
                            sx={{'& .MuiDialog-paper': {width: '100%', maxHeight: 435}}}
                            maxWidth="lg"
                            onClose={closeDetailEntryModal}
                            aria-labelledby="customized-dialog-title"
                            open={detailModeEntryModal}
                        >
                            <BootstrapDialogTitle id="customized-dialog-title" onClose={closeDetailEntryModal}>
                                Order Details
                            </BootstrapDialogTitle>
                            <Divider orientation="horizontal" flexItem/>
                            <DialogContent>
                                <Box>
                                    <DataGridTable columns={columns} data={ordersData} isLoading={isLoading}/>
                                    <Box style={{textAlign: 'end', margin: '2px'}}>
                                        <Button onClick={submitOrderDetails} sx={{m: 1, marginLeft: 'auto'}}
                                                variant="contained">
                                            Submit
                                        </Button>
                                    </Box>
                                </Box>
                            </DialogContent>
                        </BootstrapDialog>
                        {confirmDialog && <BootstrapDialog
                            sx={{'& .MuiDialog-paper': {width: '100%'}}}
                            maxWidth="md"
                            onClose={closeDialogBox}
                            aria-labelledby="customized-dialog-title"
                            open={confirmDialog}
                        >
                            <BootstrapDialogTitle id="customized-dialog-title" onClose={closeDialogBox}>
                                Awb number {confirmDialog} already linked with bag
                            </BootstrapDialogTitle>
                            <Divider orientation="horizontal" flexItem/>
                            <DialogContent>
                                <Grid sx={{p: 2}} container spacing={2}>
                                    <Grid item xs={12} md={9}>
                                        <h3 className="">Are you sure to add new baglink id</h3>
                                    </Grid>
                                    <Grid item xs={12} md={3} style={{textAlign: 'end'}}>
                                        <Button variant="contained" onClick={()=>{getOrderDetails(confirmDialog,true)}}
                                                color={'error'}>
                                            Force Add
                                        </Button>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                        </BootstrapDialog>}
                    </Grid>

                    <Grid item xs={12} md={12} lg={12}>
                        <Card sx={{p: 2, width: '100%'}}>
                            <h2>Bag Link</h2>
                            <Divider sx={{borderStyle: 'dashed', marginTop: '10px', marginBottom: '20px'}}/>
                            <Grid container spacing={3}>
                                <Grid
                                    item
                                    xs={tempLoading?11.7:12}
                                    md={tempLoading?5.7:6}
                                    lg={tempLoading?5.7:6}
                                >
                                    {scanMode ? (<FormControl sx={{m: 1, width: '100%'}}>
                                        <TextField
                                            variant="outlined"
                                            inputRef={bagLinkAWBRef}
                                            focused={focusFiled === 'bagelinkAWBNumber'}
                                            type="text"
                                            label="Click Here To Scan baglink Orders"
                                            value={baglinkAWBNumber}
                                            onChange={(event) => {
                                                setBaglinkAWBNumber(event.target.value);
                                            }}
                                            onKeyPress={(e) => {
                                                if (e.key === 'Enter') {
                                                    // console.log("Enter pressed")
                                                    getOrderDetails(baglinkAWBNumber)
                                                }
                                            }}
                                        />
                                    </FormControl>) : (<FormControl sx={{m: 1, width: '100%'}}>
                                        <Autocomplete
                                            onChange={(event, baglinkAWBNumber) => {
                                                event.key = 'Enter';
                                                setBaglinkAWBNumber(baglinkAWBNumber);
                                                getOrderDetails(baglinkAWBNumber)
                                            }}
                                            options={awbData}
                                            onInputChange={onAwbOptionData}
                                            value={baglinkAWBNumber}
                                            renderInput={(params) => <TextField {...params}
                                                                                label="Baglink AWB Number"/>}
                                        />
                                    </FormControl>)}
                                </Grid>
                                {tempLoading&&
                                    <Grid
                                        item
                                        xs={0.3}
                                        md={0.3}
                                        lg={0.3}
                                        sx={{
                                            paddingTop:"25px",
                                            paddingLeft:"25px"
                                        }}
                                    >
                                        <CircularProgress sx={{ color: SystemColor.originColor }} size={25} color="inherit" />
                                    </Grid>
                                    }
                                <Grid
                                    item
                                    xs={12}
                                    md={6}
                                    lg={6}
                                >
                                    <FormControl sx={{m: 1, width: '100%'}}>
                                        <TextField
                                            inputRef={bagLinkIdRef}
                                            disabled={!baglinkSubOrderNumber}
                                            variant="outlined"
                                            focused={focusFiled === 'bagPacketId'}
                                            type="text"
                                            label="Click Here To Scan bagPacketId"
                                            value={baglinkPacketId}
                                            onChange={(event) => {
                                                setBaglinkbagPacketId(event.target.value);
                                            }}
                                            onKeyPress={(e) => {
                                                if (e.key === 'Enter') {
                                                    // console.log("Enter pressed")
                                                    linkBag(baglinkPacketId)
                                                }
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>
            </div>
        </Container>
    </Page>);
}
