import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import { Tooltip } from '@mui/material';
import cssStyles from '../../utils/cssStyles';
import Iconify from '../Iconify';
import { IconButtonAnimate } from '../animate';

const DotStyle = styled('span')(({ theme }) => ({
  top: 8,
  width: 8,
  height: 8,
  right: 10,
  borderRadius: '50%',
  position: 'absolute',
  backgroundColor: theme.palette.error.main,
}));

ToggleButton.propTypes = {
  notDefault: PropTypes.bool,
  onToggle: PropTypes.func,
  open: PropTypes.bool,
};

export default function ToggleButton({ notDefault, open, onToggle }) {
  const RootStyle = styled('span')(({ theme }) => ({
    ...cssStyles(theme).bgBlur({ opacity: 0.64 }),
    right: 0,
    top: '90%',
    position: 'fixed',
    marginTop: theme.spacing(-3),
    padding: theme.spacing(0.5),
    zIndex: theme.zIndex.drawer + 2,
    borderRadius: '24px 0 20px 24px',
    boxShadow: `-12px 12px 32px -4px ${alpha(
      theme.palette.mode === 'light' ? theme.palette.grey[600] : theme.palette.common.black,
      0.36
    )}`,
  }));

  return (
    <RootStyle>
      {notDefault && !open && <DotStyle />}

      <Tooltip title="Need help?" placement="left">
        <IconButtonAnimate
          color="inherit"
          onClick={onToggle}
          sx={{
            p: 1.25,
            transition: (theme) => theme.transitions.create('all'),
            '&:hover': {
              color: 'primary.main',
              bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.hoverOpacity),
            },
          }}
        >
          <Iconify icon="flat-color-icons:online-support" width={20} height={20} />
        </IconButtonAnimate>
      </Tooltip>
    </RootStyle>
  );
}
