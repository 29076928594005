import {Stack, IconButton, Grid, Divider} from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { fDate } from '../../../utils/formatTime';
import { fCurrency } from '../../../utils/formatNumber';
import Iconify from '../../../components/Iconify';
import { UserProfile } from '../../../utils/apis';
import useAuth from '../../../hooks/useAuth';
import axios from '../../../utils/axios';
import { downloadS3File } from '../../../utils/utilities';
import { BILL_BUCKET } from '../../../utils/Constants';

export default function AccountBillingInvoiceHistory() {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();
  const localUser = window.localStorage.getItem('userEmailId');
  const [invoices, setInvoices] = useState([]);
  const getBills = async () => {
    const response = await axios.post(UserProfile, {
      username: user?.email ? user.email : localUser,
      action: 'getBills',
    });
    setInvoices(response.data.body);
  };

  const downloadPdf = async (file, isPDf) => {
    await downloadS3File(file, isPDf, BILL_BUCKET, enqueueSnackbar);
  };

  useEffect(() => {
    getBills().then();
  }, []);
  const [ascending, setAscending] = useState(false);

  // Function to toggle between ascending and descending order
  const toggleSortOrder = () => {
    setAscending(!ascending);
  };

  // Function to sort the data based on InvoiceDateTime
  const sortedData = [...invoices].sort((a, b) => {
    if (ascending) {
      return new Date(a.InvoiceDateTime) - new Date(b.InvoiceDateTime);
    }
      return new Date(b.InvoiceDateTime) - new Date(a.InvoiceDateTime);

  });
  return (
    <Stack spacing={3}>
      <h3>Invoice History</h3>
      <Grid container>
        <Grid item xs={2.4}>
          <h4>Bill Date</h4>
        </Grid>
        <Grid item xs={2.4}>
          <h4>Total Orders</h4>
        </Grid>
        <Grid item xs={2.4}>
          <h4>Bill Amount</h4>
        </Grid>
        <Grid item xs={2.4}>
          <h4>GST Percent</h4>
        </Grid>
        <Grid item xs={2.4}>
          <h4>Download Bill</h4>
        </Grid>
      </Grid>
      {sortedData.map((invoice, index) => (
        <Grid container key={index}>
          <Grid item xs={2.4}>
            {fDate(invoice.InvoiceDateTime)}
          </Grid>
          <Grid item xs={2.4}>
            {invoice.PaymentInfo.no_of_orders}
          </Grid>
          <Grid item xs={2.4}>
            {fCurrency(invoice.PaymentInfo.payment_info?.TXNAMOUNT)} ₹
          </Grid>
          <Grid item xs={2.4}>
            <h4>18%</h4>
          </Grid>
          <Grid item xs={2.4}>
            <IconButton
              onClick={() => {
                downloadPdf(invoice.DownloadKey, true, BILL_BUCKET, enqueueSnackbar);
              }}
            >
              <Iconify icon={'vscode-icons:file-type-pdf2'} width={30} height={30} />
            </IconButton>
          </Grid>

        </Grid>

      ))}
    </Stack>
  );
}
