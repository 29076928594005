/* eslint-disable */
import {
  Autocomplete,
  Box,
  CircularProgress,
  Divider,
  Grid,
  LinearProgress,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { DatePicker } from 'antd';
import xlsx2 from 'json-as-xlsx';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { UploadMultiFile } from '../../../components/upload';
import useAuth from '../../../hooks/useAuth';
import { CreatePostAPICall, MeeshoSaveExcelData, MyLinkedAccount, V2Management } from '../../../utils/apis';
import {
  CapitalizeFirstLetter,
  ReadExcelFile,
  SystemColor,
  getAccountsData,
  getUserAccountDetails,
} from '../../../utils/utilities';
import {
  ValidateAddSkusFiles,
  ValidateBackupFromBMTradebookFile,
  ValidateFlipkartMpFeeFiles,
  ValidateForwardReportsFile,
  ValidateMEntryDateFile,
  ValidatePEntryDateFile,
  ValidateREntryDateFile,
  ValidateSkuUpDateFile,
} from '../validation/AdminUploadValidation';
import {exit} from "process";

const AdminUploadExcels = () => {
  const { user } = useAuth();
  const localUser = window.localStorage.getItem('userEmailId');
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';
  const { RangePicker } = DatePicker;
  const { enqueueSnackbar } = useSnackbar();
  const [flipkartAc, setFlipkartAc] = useState([]);
  const [selectedAcMgt, setSelectedAcMgt] = useState({});
  const [meeshoFile, setMeeshoFile] = useState([]);
  const [glowroadFile, setGlowroadFile] = useState([]);
  const [selfCostFiles, setSelfCostFiles] = useState([]);
  const [removeReceivedEntryFiles, setRemoveReceivedEntryFiles] = useState([]);
  const [removePickupEntryFiles, setRemovePickupEntryFiles] = useState([]);
  const [makeReceivedEntryFiles, setMakeReceivedEntryFiles] = useState([]);
  const [flipkartMpFeeFiles, setFlipkartMpFeeFiles] = useState([]);
  const [selfCostTradeBookFile, setSelfCostTradeBookFile] = useState([]);
  const [deleteOrderDataExelFile, setDeleteOrderDataExelFile] = useState([]);
  const [addSkusFiles, setAddSkusFiles] = useState([]);
  const [addDeleteSkuNotInList, setAddDeleteSkuNotInList] = useState([]);
  const [allAccountsData, setAllAccountsData] = useState([]);
  const [selectAddSkusAccount, setSelectAddSkusAccount] = useState({});

  const [excelLoader, setExcelLoader] = useState(false);
  const [glowroadexcelLoader, setGlowroadExcelLoader] = useState(false);
  const [selfCostLoader, setSelfCostLoader] = useState(false);
  const [removeReceivedEntryLoader, setRemoveReceivedEntryLoader] = useState(false);
  const [removePickupEntryLoader, setRemovePickupEntryLoader] = useState(false);
  const [makeReceivedEntryLoader, setMakeReceivedEntryLoader] = useState(false);
  const [flipkartMpFeeLoader, setFlipkartMpFeeLoader] = useState(false);
  const [addSkusLoader, setAddSkusLoader] = useState(false);
  const [deleteSkuNotInListLoader, setDeleteSkuNotInListLoader] = useState(false);
  const [selfCostTradeBookLoader, setSelfCostTradeBookLoader] = useState(false);
  const [deleteOrderLoader, setDeleteOrderLoader] = useState(false);

  const [flipkartDate, setFlipkartDate] = useState({});

  useEffect(async () => {
    const accountData = await getAccountsData();
    const tempAcFlipkart = [];
    const tempAccountData = [];
    accountData.forEach((item) => {
      if (item.AcType === 'flipkart') {
        tempAcFlipkart.push({
          value: item.Account.SupplierId,
          label: `${CapitalizeFirstLetter(item.AcType)} - ${item.Account.Email} - ${item.Account.Name}`,
          AcType: item.AcType,
          Email: item.Account.Email,
          Name: item.Account.Name,
        });
      }
    });
    setFlipkartAc(tempAcFlipkart);

    accountData.forEach((item) => {
      tempAccountData.push({
        value: item.Account.SupplierId,
        label: `${CapitalizeFirstLetter(item.AcType)} - ${item.Account.Email} - ${item.Account.Name}`,
        AcType: item.AcType,
        Email: item.Account.Email,
        Name: item.Account.Name,
      });
    });
    setAllAccountsData(tempAccountData);
  }, []);

  // handle upload or drag and drop file and save all files in state
  const handleDropMultiFile = useCallback(
    (acceptedFiles, uploadType) => {
      const newFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      if (uploadType === 'meeshoOrderExcel') {
        setMeeshoFile([...newFiles]);
      }
      if (uploadType === 'glowroadOrderExcel') {
        setGlowroadFile([...newFiles]);
      }
      if (uploadType === 'selfCostExcel') {
        setSelfCostFiles([...newFiles]);
      }
      if (uploadType === 'removeReceivedExcel') {
        setRemoveReceivedEntryFiles([...newFiles]);
      }
      if (uploadType === 'removePickupExcel') {
        setRemovePickupEntryFiles([...newFiles]);
      }

      if (uploadType === 'makeReceivedExcel') {
        setMakeReceivedEntryFiles([...newFiles]);
      }

      if (uploadType === 'flipkartFeeExcel') {
        setFlipkartMpFeeFiles([...newFiles]);
      }

      if (uploadType === 'addSkusExcel') {
        setAddSkusFiles([...newFiles]);
      }
      if (uploadType === 'deleteSkuNotInList') {
        setAddDeleteSkuNotInList([...newFiles]);
      }

      if (uploadType === 'selfCostExcelTradeBook') {
        setSelfCostTradeBookFile([...newFiles]);
      }
      if (uploadType === 'deleteOrderDataExel') {
        setDeleteOrderDataExelFile([...newFiles]);
      }
    },
    [meeshoFile,glowroadFile ,selfCostLoader, removeReceivedEntryFiles, removePickupEntryFiles, makeReceivedEntryFiles, addSkusFiles,addDeleteSkuNotInList,setSelfCostTradeBookFile,setDeleteOrderDataExelFile]
  );

  // handle upload button and file upload
  const handleOnUpload = (files, uploadType) => {
    const file = files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      switch (uploadType) {
        case "meeshoOrderExcel":
          uploadFileData(e);
          break;
        case "glowroadOrderExcel":
          uploadGlowroadFileData(e);
          break;
        case "selfCostExcel":
          uploadSelfCostFileData(e);
          break;
        case "removeReceivedExcel":
          uploadRemoveEntryFileData(e, file);
          break;
        case "removePickupExcel":
          uploadRemovePickupEntryFileData(e, file);
          break;
        case "makeReceivedExcel":
          uploadMakeRecievedEntryFileData(e, file);
          break;
        case "flipkartFeeExcel":
          uploadFlipkartMpFeeFiles(e, file);
          break;
        case "addSkusExcel":
          uploadAddSkusFileData(e, file);
          break;
        case "deleteSkuNotInList":
          deleteSkuNotInList(e, file);
          break;
        case "selfCostExcelTradeBook":
          uploadSelfCostTradeBookFileData(e, file);
          break;
        case "deleteOrderDataExel":
          uploadDeleteOrderDataExelFile(e, file);
          break;
        default:
          break
      }
    };
    reader.readAsArrayBuffer(file);
  };

  // handle remove file on file close button
  const handleRemoveFile = (inputFile, removeType) => {
    if (removeType === 'meeshoOrderExcel') {
      const filtered = meeshoFile.filter((file) => file !== inputFile);
      setMeeshoFile(filtered);
    }
    if (removeType === 'glowroadOrderExcel') {
      const filtered = glowroadFile.filter((file) => file !== inputFile);
      setGlowroadFile(filtered);
    }
    if (removeType === 'selfCostExcel') {
      const filtered = selfCostFiles.filter((file) => file !== inputFile);
      setSelfCostFiles(filtered);
    }
    if (removeType === 'removeReceivedExcel') {
      const filtered = removeReceivedEntryFiles.filter((file) => file !== inputFile);
      setRemoveReceivedEntryFiles(filtered);
    }
    if (removeType === 'removePickupExcel') {
      const filtered = removePickupEntryFiles.filter((file) => file !== inputFile);
      setRemovePickupEntryFiles(filtered);
    }
    if (removeType === 'makeReceivedExcel') {
      const filtered = makeReceivedEntryFiles.filter((file) => file !== inputFile);
      setMakeReceivedEntryFiles(filtered);
    }
    if (removeType === 'flipkartFeeExcel') {
      const filtered = flipkartMpFeeFiles.filter((file) => file !== inputFile);
      setFlipkartMpFeeFiles(filtered);
    }
    if (removeType === 'addSkusExcel') {
      const filtered = addSkusFiles.filter((file) => file !== inputFile);
      setAddSkusFiles(filtered);
    }
    if (removeType === 'deleteSkuNotInList') {
      const filtered = addDeleteSkuNotInList.filter((file) => file !== inputFile);
      setAddDeleteSkuNotInList(filtered);
    }
    if (removeType === 'selfCostExcelTradeBook') {
      const filtered = addDeleteSkuNotInList.filter((file) => file !== inputFile);
      setSelfCostTradeBookFile(filtered);
    }
    if (removeType === 'deleteOrderDataExel') {
      const filtered = deleteOrderDataExelFile.filter((file) => file !== inputFile);
      setSelfCostTradeBookFile(filtered);
    }
  };

  // handle remove all selected file
  const handleRemoveAllFiles = (removeType) => {
    if (removeType === 'meeshoOrderExcel') {
      setMeeshoFile([]);
    }
    if (removeType === 'glowroadOrderExcel') {
      setGlowroadFile([]);
    }
    if (removeType === 'selfCostExcel') {
      setSelfCostFiles([]);
    }
    if (removeType === 'removeReceivedExcel') {
      setRemoveReceivedEntryFiles([]);
    }
    if (removeType === 'removePickupExcel') {
      setRemovePickupEntryFiles([]);
    }
    if (removeType === 'makeReceivedExcel') {
      setMakeReceivedEntryFiles([]);
    }
    if (removeType === 'flipkartFeeExcel') {
      setFlipkartMpFeeFiles([]);
    }
    if (removeType === 'addSkusExcel') {
      setAddSkusFiles([]);
    }
    if (removeType === 'deleteSkuNotInList') {
      setAddDeleteSkuNotInList([]);
    }
    if (removeType === 'selfCostExcelTradeBook') {
      setSelfCostTradeBookFile([]);
    }
    if (removeType === 'deleteOrderDataExel') {
      setDeleteOrderDataExelFile([]);
    }
  };

  // read the  Meesho file
  const uploadFileData = async (e) => {
    const excelActualData = ReadExcelFile(e, 0);
    if (excelActualData[1] === undefined) {
      enqueueSnackbar('Selected file is Empty.', { variant: 'error' });
      handleRemoveAllFiles('meeshoOrderExcel');
      return null;
    }
    let type = '';
    let tpEmail = '';
    const dataToPost = [];
    const response = await CreatePostAPICall(enqueueSnackbar, MyLinkedAccount, {
      username: user?.email ? user.email : localUser,
      actionType: 'getAll',
    });
    console.log("response ==",response);
    if (excelActualData[0][32] === 'supplier_id') {
      type = 'dataBackupFromMeeshoGST';
      tpEmail = response.data.body.find((y) => y.SupplierId === excelActualData[1][32]);
      const isValidated = ValidateForwardReportsFile(excelActualData[0]);
      if (isValidated) {
        excelActualData.forEach((item, index) => {
          if (index > 0) {
            dataToPost.push([item[1], item[4], item[5], item[6], item[7], item[11], item[32]]);
          }
        });
      } else {
        enqueueSnackbar('Selected file field(s) not valid.', { variant: 'error' });
        handleRemoveAllFiles('meeshoOrderExcel');
      }
    } else {
      tpEmail = response.data.body.find((y) => y.Name === meeshoFile[0].name.split('_meesho_')[0].replace('-', ' '));
      console.log("tpEmail ==",tpEmail);
      type = 'excelBMTradebook';
      const isValidated = ValidateBackupFromBMTradebookFile(excelActualData[0]);
      if (isValidated) {
        excelActualData.forEach((item, index) => {
          if (index > 0) {
            var cost = "NA";
            var reType;
            var rePartner;
            var reDate;
            while (cost === "NA"){
              if (excelActualData[index][10] !== "-"){
                cost = excelActualData[index][10];
                reType = excelActualData[index][11];
                rePartner = excelActualData[index][17];
                reDate = excelActualData[index][20];
              }else {
                index += 1
              }
            }

            if (item[0]){
              dataToPost.push([
                item[0],
                item[1],
                item[2],
                item[3],
                item[4],
                item[5],
                item[6],
                item[7],
                item[8],
                item[9],
                cost,
                reType,
                item[12],
                item[13],
                item[14],
                item[15],
                item[16],
                rePartner,
                item[18],
                item[19],
                reDate,
                item[21],
                item[22],
                item[23],
                item[24]
              ]);
            }
          }
        });
      } else {
        enqueueSnackbar('Selected file field(s) not valid.', { variant: 'error' });
        handleRemoveAllFiles('meeshoOrderExcel');
      }
    }

    if (tpEmail === undefined) {
      enqueueSnackbar('Please Select Valid Account.', { variant: 'error' });
    } else {
      await formatDataAndPostData(dataToPost, null, type, tpEmail);
    }
  };

  // read the  glowroad file
  const uploadGlowroadFileData = async (e) => {
    const excelActualData = ReadExcelFile(e, 0);
    if (excelActualData[1] === undefined) {
      enqueueSnackbar('Selected file is Empty.', { variant: 'error' });
      handleRemoveAllFiles('glowroadOrderExcel');
      return null;
    }
    let type = '';
    let tpEmail = '';
    const dataToPost = [];
    const response = await CreatePostAPICall(enqueueSnackbar, MyLinkedAccount, {
      username: user?.email ? user.email : localUser,
      actionType: 'getAll',
    });
    if (excelActualData[0][32] === 'supplier_id') {
      type = 'dataBackupFromGlowroadGST';
      tpEmail = response.data.body.find((y) => y.SupplierId === excelActualData[1][32]);
      const isValidated = ValidateForwardReportsFile(excelActualData[0]);
      if (isValidated) {
        excelActualData.forEach((item, index) => {
          if (index > 0) {
            dataToPost.push([item[1], item[4], item[5], item[6], item[7], item[11], item[32]]);
          }
        });
      } else {
        enqueueSnackbar('Selected file field(s) not valid.', { variant: 'error' });
        handleRemoveAllFiles('glowroadOrderExcel');
      }
    } else {
      tpEmail = response.data.body.find((y) => y.Name === glowroadFile[0].name.split('_glowroad_')[0].replace('-', ' '));
      type = 'excelBMTradebook';
      console.log(type);
      const isValidated = ValidateBackupFromBMTradebookFile(excelActualData[0]);
      if (isValidated) {
        excelActualData.forEach((item, index) => {
          if (index > 0) {
            var cost = "NA";
            var reType;
            var rePartner;
            var reDate;
            while (cost === "NA"){
              if (excelActualData[index][10] !== "-"){
                cost = excelActualData[index][10];
                reType = excelActualData[index][11];
                rePartner = excelActualData[index][17];
                reDate = excelActualData[index][20];
                console.log(" reDate" , reDate);
              }else {
                index += 1
              }
            }
            if (item[0]){
              dataToPost.push([
                item[0],
                item[1],
                item[2],
                item[3],
                item[4],
                item[5],
                item[6],
                item[7],
                item[8],
                item[9],
                cost,
                reType,
                item[12],
                item[13],
                item[14],
                item[15],
                item[16],
                rePartner,
                item[18],
                item[19],
                reDate,
                item[21],
                item[22],
                item[23],
                item[24]
              ]);
            }
          }
        });
      } else {
        enqueueSnackbar('Selected file field(s) not valid.', { variant: 'error' });
        handleRemoveAllFiles('glowroadOrderExcel');
      }
    }
    if (tpEmail === undefined) {
      enqueueSnackbar('Please Select Valid Account.', { variant: 'error' });
    } else {
      console.log('Glowroad upload');
      // await formatDataAndPostData(dataToPost, null, type, tpEmail);
    }
  };

  // meesho order excel file api call
  const formatDataAndPostData = async (dataToPost, token, type, tpEmail) => {
    console.log("dataToPost", dataToPost.length)
    setExcelLoader(true);
    const sliceDataPost = dataToPost.slice(0, 200);
    dataToPost = dataToPost.slice(200);
    const payload = {
      username: user?.email ? user.email : localUser,
      tpEmailId: tpEmail.Email,
      subAccountId: tpEmail.SupplierId,
      syncDataType: type,
      body: sliceDataPost,
    };
    if (token) payload.token = token;

    if (sliceDataPost.length > 0) {
      const response = await CreatePostAPICall(enqueueSnackbar, MeeshoSaveExcelData, payload);
      await formatDataAndPostData(dataToPost, response.data.token ,type, tpEmail);
    } else {
      enqueueSnackbar('Saved Successfully.');
      setExcelLoader(false);
      handleRemoveAllFiles('meeshoOrderExcel');
    }
  };

  // read self cost excel file
  const uploadSelfCostFileData = async (e) => {
    const excelActualData = ReadExcelFile(e, 0);
    const isValidated = ValidateSkuUpDateFile(excelActualData[0]);

    if (excelActualData[1] === undefined || !isValidated) {
      enqueueSnackbar('Selected file is Empty or Selected file field(s) not valid.', { variant: 'error' });
      handleRemoveAllFiles('selfCostExcel');
      return null;
    }
    const accountDetails = await getUserAccountDetails();
    const suppliers = accountDetails.supplierIds;
    const tpEmails = accountDetails.tpEmailids;
    const acTypes = accountDetails.acTypes;

    const dataToPost = [];

    excelActualData.forEach((item, index) => {
      if (index > 0) {
        dataToPost.push({
          subOrderNo: item[0],
          newSelfCost: item[1],
        });
      }
    });

    await formatDataAndPostDataSelfCost(dataToPost, tpEmails, suppliers, acTypes);
  };

  // self cost excel file api call
  const formatDataAndPostDataSelfCost = async (dataToPost, tpEmails, suppliers, acTypes) => {
    setSelfCostLoader(true);
    const items = dataToPost.slice(0, 100);
    dataToPost.splice(0, 100);
    const body = {
      username: user?.email ? user.email : localUser,
      tpEmailIds: tpEmails,
      subAccountIds: suppliers,
      acTypes,
      dataToUpdate: items,
      actionType: 'updateSelfCost',
      panel: 'admin',
      subPanel: 'adminUploadExcels',
    };
    if (items.length > 0) {
      const response = await CreatePostAPICall(enqueueSnackbar, V2Management, body);
      if (response) {
        await formatDataAndPostDataSelfCost(dataToPost, tpEmails, suppliers, acTypes);
        setSelfCostLoader(false);
        enqueueSnackbar('Self Cost Updated Successfully');
        handleRemoveAllFiles('selfCostExcel');
      }
    }
  };

  // read remove received entry excel file
  const uploadRemoveEntryFileData = async (e) => {
    const excelActualData = ReadExcelFile(e, 0);
    const isValidated = ValidateREntryDateFile(excelActualData[0]);

    if (excelActualData[1] === undefined || !isValidated) {
      enqueueSnackbar('Selected file is Empty or Selected file field(s) not valid.', { variant: 'error' });
      handleRemoveAllFiles('removeReceivedExcel');
      return null;
    }

    const dataToPost = [];

    excelActualData.forEach((item, index) => {
      if (index > 0) {
        dataToPost.push(item[0]);
      }
    });

    await formatDataAndPostDataRemoveEntry(dataToPost);
  };

  // remove received entry excel file api call
  const formatDataAndPostDataRemoveEntry = async (dataToPost) => {
    setRemoveReceivedEntryLoader(true);
    const notFoundOrdersList = [];

    for (let i = 0; i < dataToPost.length; i++) {
      const subOrderNo = dataToPost[i].toString().trim();

      const body = {
        username: user?.email ? user.email : localUser,
        suborder_no: subOrderNo,
        actionType: 'removeEntry',
        panel: 'admin',
        subPanel: 'innerAdmin',
      };

      const response = await CreatePostAPICall(enqueueSnackbar, V2Management, body);
      if (response) {
        enqueueSnackbar('Removed Received Entry Successfully');
        handleRemoveAllFiles('removeReceivedExcel');
      }
      if (response === null) {
        notFoundOrdersList.push({
          suborder_no: subOrderNo,
        });
      }
    }

    const clmNames = [{ label: 'Suborder No', value: 'suborder_no' }];

    await handleExportExcel(notFoundOrdersList, clmNames, 'Not_Found_Orders_Remove_Received_Entry');
    setRemoveReceivedEntryLoader(false);
  };

  // read remove pickup entry excel file
  const uploadRemovePickupEntryFileData = async (e) => {
    const excelActualData = ReadExcelFile(e, 0);
    const isValidated = ValidatePEntryDateFile(excelActualData[0]);

    if (excelActualData[1] === undefined || !isValidated) {
      enqueueSnackbar('Selected file is Empty or Selected file field(s) not valid.', { variant: 'error' });
      handleRemoveAllFiles('removePickupExcel');
      return null;
    }

    const dataToPost = [];

    delete excelActualData[0];
    excelActualData.forEach((item) => {
      dataToPost.push(item[0]);
    });

    await formatDataAndPostDataRemovePickupEntry(dataToPost);
  };

  // remove pickup entry excel file api call
  const formatDataAndPostDataRemovePickupEntry = async (dataToPost) => {
    setRemovePickupEntryLoader(true);
    const notFoundOrdersList = [];

    for (let i = 0; i < dataToPost.length; i++) {
      const subOrderNo = dataToPost[i].toString().trim();

      const body = {
        username: user?.email ? user.email : localUser,
        suborder_no: subOrderNo,
        actionType: 'removePickupEntry',
        panel: 'admin',
        subPanel: 'innerAdmin',
      };

      const response = await CreatePostAPICall(enqueueSnackbar, V2Management, body);

      if (response) {
        enqueueSnackbar('Removed Pickup Entry Successfully');
        handleRemoveAllFiles('removePickupExcel');
      }

      if (response === null) {
        notFoundOrdersList.push({
          suborder_no: subOrderNo,
        });
      }
    }

    const clmNames = [{ label: 'Sub Order No', value: 'suborder_no' }];

    await handleExportExcel(notFoundOrdersList, clmNames, 'Not_Found_Orders_Remove_Pickup_Entry');
    setRemovePickupEntryLoader(false);
  };

  // read make received entry excel file
  const uploadMakeRecievedEntryFileData = async (e) => {
    const excelActualData = ReadExcelFile(e, 0);
    const isValidated = ValidateMEntryDateFile(excelActualData[0]);

    if (excelActualData[1] === undefined || !isValidated) {
      enqueueSnackbar('Selected file is Empty or Selected file field(s) not valid.', { variant: 'error' });
      handleRemoveAllFiles('makeReceivedExcel');
      return null;
    }

    const dataToPost = [];
    excelActualData.forEach((item, index) => {
      if (index > 0) {
        dataToPost.push(item[0]);
      }
    });

    await formatDataAndPostDataMakeReceivedEntry(dataToPost);
  };

  // make received entry excel file api call
  const formatDataAndPostDataMakeReceivedEntry = async (dataToPost) => {
    setMakeReceivedEntryLoader(true);
    const notFoundOrdersList = [];

    for (let i = 0; i < dataToPost.length; i++) {
      const subOrderNo = dataToPost[i].toString().trim();

      const body = {
        username: user?.email ? user.email : localUser,
        suborder_no: subOrderNo,
        panel: 'admin',
        subPanel: 'innerAdmin',
        actionType: 'makeEntry',
      };

      const response = await CreatePostAPICall(enqueueSnackbar, V2Management, body);

      if (response) {
        enqueueSnackbar('Maked Received Entry Successfully');
        handleRemoveAllFiles('makeReceivedExcel');
      }

      if (response === null) {
        notFoundOrdersList.push({
          suborder_no: subOrderNo,
        });
      }
    }

    const clmNames = [{ label: 'Sub Order No', value: 'suborder_no' }];

    await handleExportExcel(notFoundOrdersList, clmNames, 'Not_Found_Orders_Make_Recieved_Entry');
    setMakeReceivedEntryLoader(false);
  };

  // read flipkart excel file and api call
  const uploadFlipkartMpFeeFiles = async (e) => {
    const excelActualData = ReadExcelFile(e, 0);
    const isValidated = ValidateFlipkartMpFeeFiles(excelActualData[0]);
    if (excelActualData[1] === undefined || !isValidated) {
      enqueueSnackbar('Selected file is Empty or Selected file field(s) not valid.', { variant: 'error' });
      handleRemoveAllFiles('flipkartFeeExcel');
      return null;
    }
    const dataToPost = [];
    excelActualData.forEach((item, index) => {
      if (index > 0) {
        const selectedFile = {
          username: user?.email,
          tpEmailId: selectedAcMgt.Email,
          subAccountId: selectedAcMgt.value,
          acType: selectedAcMgt.AcType,
          pickUpDate: flipkartDate,
          panel: 'admin',
          subPanel: 'adminUploadExcels',
          actionType: 'updateSellingPriceOfFlipkartOrders',
          sku: item[0],
          sellingPrice: item[1],
          mpRebatePercentage: item[2],
        };

        dataToPost.push(selectedFile);
      }
    });
    setFlipkartMpFeeLoader(true);
    for (let i = 0; i < dataToPost.length; i++) {
      await CreatePostAPICall(enqueueSnackbar, V2Management, dataToPost[i]);
    }
    // dataToPost.map((item)=>{
    //     CreatePostAPICall(enqueueSnackbar,V2Management, item);
    // })
    enqueueSnackbar('File Uploaded Successfully');
    handleRemoveAllFiles('flipkartFeeExcel');
    setFlipkartMpFeeLoader(false);
  };

  const handleExportExcel = (dataArray, columnsArray, fileName) => {
    const data = [
      {
        sheet: 'filteredTableData',
        columns: columnsArray,
        content: dataArray,
      },
    ];

    const settings = {
      fileName,
      extraLength: 6,
      writeOptions: {},
    };

    xlsx2(data, settings);
  };

  // select start and end date in flipkart file upload
  const getdate = (value, dateString) => {
    setFlipkartDate({
      start: dateString[0],
      end: dateString[1],
    });
  };

  // read add skus excel file
  const uploadAddSkusFileData = async (e) => {
    const excelActualData = ReadExcelFile(e, 0);
    const isValidated = ValidateAddSkusFiles(excelActualData[0]);

    if (excelActualData[1] === undefined || !isValidated) {
      enqueueSnackbar('Selected file is Empty or Selected file field(s) not valid.', { variant: 'error' });
      handleRemoveAllFiles('addSkusExcel');
      return null;
    }

    if (selectAddSkusAccount?.value && selectAddSkusAccount?.AcType && selectAddSkusAccount?.Email) {
      const dataToPost = [];

      excelActualData.forEach((item, index) => {
        if (index > 0) {
          dataToPost.push({
            Sku: item[0],
            Size: item[1],
            selfCost: item[2],
          });
        }
      });

      await formatDataAndPostDataAddSkus(dataToPost);
    } else {
      enqueueSnackbar('Please Select Account.', { variant: 'error' });
      handleRemoveAllFiles('addSkusExcel');
      return null;
    }
  };
  const postDataDeleteSkus = async (dataToPost) => {
    setDeleteSkuNotInListLoader(true);
    if (!(user?.email ? user.email : localUser)){
      enqueueSnackbar('Please select user first.', { variant: 'error' });
      setDeleteSkuNotInListLoader(false);
      return null;
    }
    const body = {
      username: user?.email ? user.email : localUser,
      skuData: dataToPost,
      actionType: 'deleteSku',
    };

    const response = await CreatePostAPICall(enqueueSnackbar, V2Management, body);
    if (response) {
      setDeleteSkuNotInListLoader(false);
      enqueueSnackbar(response.data.body);
      handleRemoveAllFiles('deleteSkuNotInList');
    }

  };
  const deleteSkuNotInList = async (e) => {
    const excelActualData = ReadExcelFile(e, 0);
    const isValidated = excelActualData[0].includes("SKU Name\n") && excelActualData[0].includes("part_key\n");
    const skuIndex = excelActualData[0].indexOf("SKU Name")
    const indexOfPartKey = excelActualData[0].indexOf("part_key")

    if (isValidated) {
      enqueueSnackbar('Selected file is Empty or Selected file field(s) not valid.', { variant: 'error' });
      handleRemoveAllFiles('addSkusExcel');
      return null;
    }

      const tempExelList = [];

      excelActualData.forEach((item, index) => {
        if (index > 0) {
          tempExelList.push({
            Sku: item[skuIndex],
            part_key: item[indexOfPartKey],
          });
        }
      });
      const dataToPost = tempExelList.reduce((acc,{part_key,Sku})=>{
        if (part_key == "" || !part_key) return acc
        if(!acc[part_key]){
          acc[part_key] = [Sku]
        }else{
          acc[part_key].push(Sku)
        }
        return acc
      },{})
      // console.log("dataToPost",dataToPost)

      await postDataDeleteSkus(dataToPost);
  };

  function divideArrayIntoGroups(array, groupSize) {
    const groups = [];
    for (let i = 0; i < array.length; i += groupSize) {
      groups.push(array.slice(i, i + groupSize));
    }
    return groups;
  }

  const postDataUpdateSelfCostSkus = async (payload,groupsOfSkuWiseOrder) => {
    setSelfCostTradeBookLoader(true);
    payload.actionType = 'updateSelfCostForSubOrders';
    payload.panel = 'admin'
    payload.subPanel = 'adminUploadExcels'
    var allResponseOk = true
    for(const group of groupsOfSkuWiseOrder){
      payload.skuToUpdate = group
      const response = await CreatePostAPICall(enqueueSnackbar, V2Management, payload);
      if (!response) {
        allResponseOk = false
        break
      }
    }
    if (allResponseOk){
      enqueueSnackbar('Self cost updated successfully.')
    }else {
      enqueueSnackbar('Something want wrong while updating self cost. Please try again later.', { variant: 'error' });
    }
    handleRemoveAllFiles('selfCostExcelTradeBook');
    setSelfCostTradeBookLoader(false);
  };
  const postDataDeleteOrder = async (username,orderData) => {
    const payload = {
      "username":username
    }
    setDeleteOrderLoader(true);
    payload.actionType = 'deleteOrders';
    payload.panel = 'admin'
    payload.subPanel = 'adminUploadExcels'
    var isAllOk = false;
    for (const item of divideArrayIntoGroups(orderData, 100)) {
      payload.orderData = item
      const response = await CreatePostAPICall(enqueueSnackbar, V2Management, payload);
      if (response) {
        isAllOk = true
      }else{
        isAllOk = false
      }
    }
    if (isAllOk){
      handleRemoveAllFiles('deleteOrderDataExel');
      setDeleteOrderLoader(false);
    }


  };

  const uploadSelfCostTradeBookFileData = async (e) => {
    const excelSummaryData = ReadExcelFile(e, 0,'Summary');
    const excelSKUSelfCostData = ReadExcelFile(e, 0,'SKU SelfCost');
    if(excelSummaryData.length > 0 && excelSKUSelfCostData.length > 1){
      const userName = excelSummaryData?.[0]?.[1]
      const companyName = excelSummaryData?.[0]?.[7]
      const tpEmailId = excelSummaryData?.[1]?.[1]
      const acType = excelSummaryData?.[1]?.[7]
      const subAccountId = excelSummaryData?.[2]?.[1]
      const month = excelSummaryData?.[2]?.[7]
      const skuToUpdate = {}
      excelSKUSelfCostData.forEach((sku,index)=>{
        if(index >= 1){
          skuToUpdate[sku[0]]=parseInt(sku[1])
        }
      })
      // console.log("skuToUpdate",skuToUpdate)
      if(userName && companyName && tpEmailId && acType){
        if(!['glowroad','limeroad'].includes(acType) && !subAccountId){
          enqueueSnackbar('SubAccount not found.', { variant: 'error' });
          handleRemoveAllFiles('selfCostExcelTradeBook');
          return null;
        }
        const excelOrderData = ReadExcelFile(e, 0,companyName);
        if(excelOrderData.length > 1 && excelOrderData[0].includes('SUB_ORDER_NO') && excelOrderData[0].includes('SKU') && excelOrderData[0].includes('COST') && excelOrderData[0].includes('QUANTITY')){
          const indexOfSubOrderNo = excelOrderData[0].indexOf('SUB_ORDER_NO');
          const indexOfSKU = excelOrderData[0].indexOf('SKU');
          const indexOfCost = excelOrderData[0].indexOf('COST');
          const indexOfQuantity = excelOrderData[0].indexOf('QUANTITY');
          const groupsOfSkuWiseOrder = []
          const formattedSubOrders = []
          var totalOrders = 0
          var ordersToUpdate = 0

          var lastSubOrderIndex = 0

          excelOrderData.forEach((item,i)=>{
            if(i>0){
              const subOrderNo = item[indexOfSubOrderNo]
              const selfCost = parseInt(item[indexOfCost])
              if(subOrderNo && subOrderNo !== ""){
                lastSubOrderIndex = totalOrders
                totalOrders++
                const SKUCode = item[indexOfSKU]
                const quantity = parseInt(item[indexOfQuantity])
                formattedSubOrders.push({
                  subOrderNo,
                  selfCost,
                  SKUCode,
                  quantity
                })
              }
              else if(selfCost && selfCost != "-" && selfCost != ""){
                formattedSubOrders[lastSubOrderIndex].selfCost = parseInt(selfCost)
              }
            }
          })

          console.log("formattedSubOrders",formattedSubOrders)

          formattedSubOrders.forEach(({subOrderNo,quantity,SKUCode,selfCost})=>{

            if(skuToUpdate[SKUCode] !== undefined){
              if(selfCost !== skuToUpdate[SKUCode] *quantity){
                const index = parseInt(ordersToUpdate/300)
                if(!groupsOfSkuWiseOrder[index]){
                  groupsOfSkuWiseOrder[index] = {}
                }
                if(!groupsOfSkuWiseOrder[index][SKUCode]){
                  groupsOfSkuWiseOrder[index][SKUCode] = {
                    orders: [],
                    selfCost: skuToUpdate[SKUCode],
                  }
                }
                if (subOrderNo){
                  groupsOfSkuWiseOrder[index][SKUCode]?.orders.push(subOrderNo)
                }
                ordersToUpdate++
              }
            }
          })
          console.log("totalOrders",totalOrders,"ordersToUpdate",ordersToUpdate)
          const payload = {
            "username": userName,
            "CompanyName":companyName,
            "TpEmailId":tpEmailId,
            "acType":acType,
            "subAccountId":subAccountId,
          }
          console.log(payload,groupsOfSkuWiseOrder)
          await postDataUpdateSelfCostSkus(payload,groupsOfSkuWiseOrder)
        }
        else {
          enqueueSnackbar('Order data not found', { variant: 'error' });
          handleRemoveAllFiles('selfCostExcelTradeBook');
          return null;
        }

      }
      else {
        enqueueSnackbar('File not Include Proper Data', { variant: 'error' });
        handleRemoveAllFiles('selfCostExcelTradeBook');
        return null;
      }
    }
    else {
      enqueueSnackbar('Selected file is Empty or Selected file field(s) not valid.', { variant: 'error' });
      handleRemoveAllFiles('selfCostExcelTradeBook');
      return null;
    }
  }

  const uploadDeleteOrderDataExelFile = async (e) => {
    const orderData = ReadExcelFile(e, 0);
    const userName = localStorage.getItem("userEmailId");
    const dataTosend = []
    if(orderData.length > 0 && orderData.length > 1){
      // console.log("orderData",orderData)
      if(orderData.length > 1 && orderData[0].includes('Platform') && orderData[0].includes('Email') && orderData[0].includes('Supplier Id') && orderData[0].includes('Sub Order No')){
        const indexOfPlatformName = orderData[0].indexOf('Platform');
        const indexOfEmail= orderData[0].indexOf('Email') ;
        const indexOfAccountId= orderData[0].indexOf('Supplier Id')
        const indexOfSubOrderNo = orderData[0].indexOf('Sub Order No');
        // console.log(indexOfPlatformName,indexOfEmail,indexOfAccountId,indexOfSubOrderNo)

        orderData.forEach((item,i)=>{
          if(i>0){
            const subOrderNo = item[indexOfSubOrderNo]
            const accountId = item[indexOfAccountId]
            const tp_email = item[indexOfEmail]
            const platform = item[indexOfPlatformName]
            // console.log([platform,tp_email,accountId,subOrderNo])
            if(subOrderNo && subOrderNo !== "" && platform && platform!=="" && tp_email && tp_email !== "" && accountId && accountId !== "" ){
              dataTosend.push([platform,tp_email,accountId,subOrderNo])
            }
          }
        })
        console.log("dataTosend",dataTosend)
        await postDataDeleteOrder(userName,dataTosend)
      }
      else {
        enqueueSnackbar('File not Include Proper Data', { variant: 'error' });
        handleRemoveAllFiles('selfCostExcelTradeBook');
        return null;
      }
    }
    else {
      enqueueSnackbar('Selected file is Empty or Selected file field(s) not valid.', { variant: 'error' });
      handleRemoveAllFiles('selfCostExcelTradeBook');
      return null;
    }
  }

  // self cost excel file api call
  const formatDataAndPostDataAddSkus = async (dataToPost) => {
    setAddSkusLoader(true);
    const items = dataToPost.slice(0, 100);
    dataToPost.splice(0, 100);
    const body = {
      username: user?.email ? user.email : localUser,
      tpEmailId: selectAddSkusAccount?.Email,
      subAccountId: selectAddSkusAccount?.value,
      acType: selectAddSkusAccount?.AcType,
      skuDetails: items,
      actionType: 'addSku',
      panel: 'admin',
      subPanel: 'adminUploadExcels',
    };

    if (items.length > 0) {
      const response = await CreatePostAPICall(enqueueSnackbar, V2Management, body);
      if (response) {
        await formatDataAndPostDataAddSkus(dataToPost);
        setAddSkusLoader(false);
        enqueueSnackbar('Skus Added Successfully.');
        handleRemoveAllFiles('addSkusExcel');
      }
    }
  };

  return (
    <>
      <Divider sx={{ p: 1 }} />

      <Grid sx={{ p: 2 }} container spacing={3}>

        <Grid item xs={12} md={6} sm={6} lg={6}>
          <Typography sx={{ pb: 2 }} variant="h6">
            Upload Meesho GST / Tradebook Order Excel
          </Typography>
          <Divider sx={{ mb: 2 }} />

          {excelLoader === true ? (
            <Box sx={{ width: '90%', paddingTop: '20%' }}>
              <div style={{ width: '100%' }}>
                <LinearProgress />
              </div>
              <br />
              Please Wait While We Updating....
            </Box>
          ) : (
            <UploadMultiFile
              acceptTypes={'.xlsx, .xls, .csv'}
              files={meeshoFile}
              title={'Drop Or Select Meesho Order Excel'}
              onDrop={(acceptedFiles) => handleDropMultiFile(acceptedFiles, 'meeshoOrderExcel')}
              onRemove={(inputFile) => handleRemoveFile(inputFile, 'meeshoOrderExcel')}
              onRemoveAll={() => handleRemoveAllFiles('meeshoOrderExcel')}
              onUpload={() => handleOnUpload(meeshoFile, 'meeshoOrderExcel')}
            />
          )}
        </Grid>

        <Grid item xs={12} md={6} sm={6} lg={6}>
          <Typography sx={{ pb: 2 }} variant="h6">
            Upload Glowroad GST / Tradebook Order Excel
          </Typography>
          <Divider sx={{ mb: 2 }} />

          {glowroadexcelLoader === true ? (
              <Box sx={{ width: '90%', paddingTop: '20%' }}>
                <div style={{ width: '100%' }}>
                  <LinearProgress />
                </div>
                <br />
                Please Wait While We Updating....
              </Box>
          ) : (
              <UploadMultiFile
                  acceptTypes={'.xlsx, .xls, .csv'}
                  files={glowroadFile}
                  title={'Drop Or Select Glowroad Order Excel'}
                  onDrop={(acceptedFiles) => handleDropMultiFile(acceptedFiles, 'glowroadOrderExcel')}
                  onRemove={(inputFile) => handleRemoveFile(inputFile, 'glowroadOrderExcel')}
                  onRemoveAll={() => handleRemoveAllFiles('glowroadOrderExcel')}
                  onUpload={() => handleOnUpload(glowroadFile, 'glowroadOrderExcel')}
              />
          )}
        </Grid>

        <Grid item xs={12} md={6} sm={6} lg={6}>
          <Typography sx={{ pb: 2 }} variant="h6">
            Upload SelfCost Update Excel
          </Typography>
          <Divider sx={{ mb: 2 }} />

          {selfCostLoader === true ? (
            <Box sx={{ width: '90%', paddingTop: '20%' }}>
              <div style={{ width: '100%' }}>
                <LinearProgress />
              </div>
              <br />
              Please Wait While We Updating....
            </Box>
          ) : (
            <UploadMultiFile
              acceptTypes={'.xlsx, .xls, .csv'}
              files={selfCostFiles}
              title={'Drop Or Select SelfCost Excel'}
              onDrop={(acceptedFiles) => handleDropMultiFile(acceptedFiles, 'selfCostExcel')}
              onRemove={(inputFile) => handleRemoveFile(inputFile, 'selfCostExcel')}
              onRemoveAll={() => handleRemoveAllFiles('selfCostExcel')}
              onUpload={() => handleOnUpload(selfCostFiles, 'selfCostExcel')}
            />
          )}
        </Grid>

        <Grid item xs={12} md={6} sm={6} lg={6}>
          <Typography sx={{ pb: 2 }} variant="h6">
            Upload Remove Received Entry Excel
          </Typography>
          <Divider sx={{ mb: 2 }} />

          {removeReceivedEntryLoader === true ? (
            <Box sx={{ width: '90%', paddingTop: '20%' }}>
              <div style={{ width: '100%' }}>
                <LinearProgress />
              </div>
              <br />
              Please Wait While We Updating....
            </Box>
          ) : (
            <UploadMultiFile
              acceptTypes={'.xlsx, .xls, .csv'}
              files={removeReceivedEntryFiles}
              title={'Drop Or Select Remove Received Entry'}
              onDrop={(acceptedFiles) => handleDropMultiFile(acceptedFiles, 'removeReceivedExcel')}
              onRemove={(inputFile) => handleRemoveFile(inputFile, 'removeReceivedExcel')}
              onRemoveAll={() => handleRemoveAllFiles('removeReceivedExcel')}
              onUpload={() => handleOnUpload(removeReceivedEntryFiles, 'removeReceivedExcel')}
            />
          )}
        </Grid>

        <Grid item xs={12} md={6} sm={6} lg={6}>
          <Typography sx={{ pb: 2 }} variant="h6">
            Upload Remove Pickup Entry Excel
          </Typography>
          <Divider sx={{ mb: 2 }} />

          {removePickupEntryLoader === true ? (
            <Box sx={{ width: '90%', paddingTop: '20%' }}>
              <div style={{ width: '100%' }}>
                <LinearProgress />
              </div>
              <br />
              Please Wait While We Updating....
            </Box>
          ) : (
            <UploadMultiFile
              acceptTypes={'.xlsx, .xls, .csv'}
              files={removePickupEntryFiles}
              title={'Drop Or Select Remove Pickup Entry'}
              onDrop={(acceptedFiles) => handleDropMultiFile(acceptedFiles, 'removePickupExcel')}
              onRemove={(inputFile) => handleRemoveFile(inputFile, 'removePickupExcel')}
              onRemoveAll={() => handleRemoveAllFiles('removePickupExcel')}
              onUpload={() => handleOnUpload(removePickupEntryFiles, 'removePickupExcel')}
            />
          )}
        </Grid>

        <Grid item xs={12} md={6} sm={6} lg={6}>
          <Typography sx={{ pb: 2 }} variant="h6">
            Upload Make Received Entry Excel
          </Typography>
          <Divider sx={{ mb: 2 }} />

          {makeReceivedEntryLoader === true ? (
            <Box sx={{ width: '90%', paddingTop: '20%' }}>
              <div style={{ width: '100%' }}>
                <LinearProgress />
              </div>
              <br />
              Please Wait While We Updating....
            </Box>
          ) : (
            <UploadMultiFile
              acceptTypes={'.xlsx, .xls, .csv'}
              files={makeReceivedEntryFiles}
              title={'Drop Or Select Make Received Entry'}
              onDrop={(acceptedFiles) => handleDropMultiFile(acceptedFiles, 'makeReceivedExcel')}
              onRemove={(inputFile) => handleRemoveFile(inputFile, 'makeReceivedExcel')}
              onRemoveAll={() => handleRemoveAllFiles('makeReceivedExcel')}
              onUpload={() => handleOnUpload(makeReceivedEntryFiles, 'makeReceivedExcel')}
            />
          )}
        </Grid>

        <Grid item xs={12} md={6} sm={6} lg={6}>
          <Typography sx={{ pb: 2 }} variant="h6">
            Upload Delete Sku not in list (Meesho)
          </Typography>
          <Divider sx={{ mb: 2 }} />

          {deleteSkuNotInListLoader === true ? (
            <Box sx={{ width: '90%', paddingTop: '20%' }}>
              <div style={{ width: '100%' }}>
                <LinearProgress />
              </div>
              <br />
              Please Wait While We Updating....
            </Box>
          ) : (
            <UploadMultiFile
              acceptTypes={'.xlsx, .xls, .csv'}
              files={addDeleteSkuNotInList}
              title={'Drop Or Select Delete Sku not in list'}
              onDrop={(acceptedFiles) => handleDropMultiFile(acceptedFiles, 'deleteSkuNotInList')}
              onRemove={(inputFile) => handleRemoveFile(inputFile, 'deleteSkuNotInList')}
              onRemoveAll={() => handleRemoveAllFiles('deleteSkuNotInList')}
              onUpload={() => handleOnUpload(addDeleteSkuNotInList, 'deleteSkuNotInList')}
            />
          )}
        </Grid>

        <Grid item xs={12} md={6} sm={6} lg={6}>
          <Typography sx={{ pb: 2 }} variant="h6">
            Tread Book for update Sku
          </Typography>
          <Divider sx={{ mb: 2 }} />

          {selfCostTradeBookLoader === true ? (
            <Box sx={{ width: '90%', paddingTop: '20%' }}>
              <div style={{ width: '100%' }}>
                <LinearProgress />
              </div>
              <br />
              Please Wait While We Updating....
            </Box>
          ) : (
            <UploadMultiFile
              acceptTypes={'.xlsx, .xls'}
              files={selfCostTradeBookFile}
              title={'Drop Or Select Tread Book for update Sku'}
              onDrop={(acceptedFiles) => handleDropMultiFile(acceptedFiles, 'selfCostExcelTradeBook')}
              onRemove={(inputFile) => handleRemoveFile(inputFile, 'selfCostExcelTradeBook')}
              onRemoveAll={() => handleRemoveAllFiles('selfCostExcelTradeBook')}
              onUpload={() => handleOnUpload(selfCostTradeBookFile, 'selfCostExcelTradeBook')}
            />
          )}
        </Grid>

        <Grid item xs={12} md={6} sm={6} lg={6}>
          <Typography sx={{ pb: 2 }} variant="h6">
            Delete Orders
          </Typography>
          <Divider sx={{ mb: 2 }} />

          {deleteOrderLoader === true ? (
            <Box sx={{ width: '90%', paddingTop: '20%' }}>
              <div style={{ width: '100%' }}>
                <LinearProgress />
              </div>
              <br />
              Please Wait While We Updating....
            </Box>
          ) : (
            <UploadMultiFile
              acceptTypes={'.xlsx, .xls'}
              files={deleteOrderDataExelFile}
              title={'Drop or select Exel for delete orders'}
              onDrop={(acceptedFiles) => handleDropMultiFile(acceptedFiles, 'deleteOrderDataExel')}
              onRemove={(inputFile) => handleRemoveFile(inputFile, 'deleteOrderDataExel')}
              onRemoveAll={() => handleRemoveAllFiles('deleteOrderDataExel')}
              onUpload={() => handleOnUpload(deleteOrderDataExelFile, 'deleteOrderDataExel')}
            />
          )}
        </Grid>

        {/*<Grid item xs={12} md={6} sm={6} lg={6}>*/}
        {/*  <Typography sx={{ pb: 2 }} variant="h6">*/}
        {/*    Flipkart*/}
        {/*  </Typography>*/}
        {/*  <Divider sx={{ mb: 2 }} />*/}
        {/*  <Box sx={{ mb: 2 }}>*/}
        {/*    <Autocomplete*/}
        {/*      disableClearable*/}
        {/*      id="flipkart-adCost-delete"*/}
        {/*      onChange={(event, value) => {*/}
        {/*        setSelectedAcMgt(value);*/}
        {/*      }}*/}
        {/*      options={flipkartAc.map((option) => option)}*/}
        {/*      renderInput={(params) => (*/}
        {/*        <TextField*/}
        {/*          {...params}*/}
        {/*          label="Select Flipkart Account*"*/}
        {/*          InputProps={{*/}
        {/*            ...params.InputProps,*/}
        {/*            type: 'search',*/}
        {/*          }}*/}
        {/*        />*/}
        {/*      )}*/}
        {/*    />*/}
        {/*  </Box>*/}

        {/*  <Box sx={{ mb: 2 }}>*/}
        {/*    <RangePicker*/}
        {/*      format={'DD/MM/YY'}*/}
        {/*      className={'createDateRangePicker'}*/}
        {/*      pupClassName={'createDateRangePicker'}*/}
        {/*      onChange={(value, event) => getdate(value, event)}*/}
        {/*      {...(!isLight && {*/}
        {/*        className: 'date-picker-dark',*/}
        {/*      })}*/}
        {/*    />*/}
        {/*  </Box>*/}

        {/*  {flipkartMpFeeLoader === true ? (*/}
        {/*    <div style={{ width: '100%', textAlign: 'center', padding: '100px' }}>*/}
        {/*      <CircularProgress sx={{ color: SystemColor.originColor }} size={24} color="inherit" />*/}
        {/*      <br />*/}
        {/*      Please Wait While Uploading Data...*/}
        {/*    </div>*/}
        {/*  ) : (*/}
        {/*    <UploadMultiFile*/}
        {/*      acceptTypes={'.xlsx, .xls, .csv'}*/}
        {/*      files={flipkartMpFeeFiles}*/}
        {/*      title={'Drop Or Select Flipkart Files'}*/}
        {/*      onDrop={(acceptedFiles) => handleDropMultiFile(acceptedFiles, 'flipkartFeeExcel')}*/}
        {/*      onRemove={(inputFile) => handleRemoveFile(inputFile, 'flipkartFeeExcel')}*/}
        {/*      onRemoveAll={() => handleRemoveAllFiles('flipkartFeeExcel')}*/}
        {/*      onUpload={() => handleOnUpload(flipkartMpFeeFiles, 'flipkartFeeExcel')}*/}
        {/*    />*/}
        {/*  )}*/}
        {/*</Grid>*/}

        {/*<Grid item xs={12} md={6} sm={6} lg={6}>*/}
        {/*  <Typography sx={{ pb: 2 }} variant="h6">*/}
        {/*    Add Skus*/}
        {/*  </Typography>*/}
        {/*  <Divider sx={{ mb: 2 }} />*/}

        {/*  <Box sx={{ mb: 2 }}>*/}
        {/*    <Autocomplete*/}
        {/*      disableClearable*/}
        {/*      id="add-skus-account"*/}
        {/*      onChange={(event, value) => {*/}
        {/*        setSelectAddSkusAccount(value);*/}
        {/*      }}*/}
        {/*      options={allAccountsData.map((option) => option)}*/}
        {/*      renderInput={(params) => (*/}
        {/*        <TextField*/}
        {/*          {...params}*/}
        {/*          label="Select Account*"*/}
        {/*          InputProps={{*/}
        {/*            ...params.InputProps,*/}
        {/*            type: 'search',*/}
        {/*          }}*/}
        {/*        />*/}
        {/*      )}*/}
        {/*    />*/}
        {/*  </Box>*/}
        {/*  <Divider sx={{ mb: 2 }} />*/}
        {/*  {addSkusLoader === true ? (*/}
        {/*    <Box sx={{ width: '90%', paddingTop: '20%' }}>*/}
        {/*      <div style={{ width: '100%' }}>*/}
        {/*        <LinearProgress />*/}
        {/*      </div>*/}
        {/*      <br />*/}
        {/*      Please Wait While We Updating....*/}
        {/*    </Box>*/}
        {/*  ) : (*/}
        {/*    <UploadMultiFile*/}
        {/*      acceptTypes={'.xlsx, .xls, .csv'}*/}
        {/*      files={addSkusFiles}*/}
        {/*      title={'Drop Or Select Add Skus Excel'}*/}
        {/*      onDrop={(acceptedFiles) => handleDropMultiFile(acceptedFiles, 'addSkusExcel')}*/}
        {/*      onRemove={(inputFile) => handleRemoveFile(inputFile, 'addSkusExcel')}*/}
        {/*      onRemoveAll={() => handleRemoveAllFiles('addSkusExcel')}*/}
        {/*      onUpload={() => handleOnUpload(addSkusFiles, 'addSkusExcel')}*/}
        {/*    />*/}
        {/*  )}*/}
        {/*</Grid>*/}

      </Grid>
    </>
  );
};

export default AdminUploadExcels;
