import SvgIconStyle from '../../../components/SvgIconStyle';
import { isAdmin, isSuperAdminDesktopEnvironment, isSuperAdminEnvironment } from '../../../utils/utilities';
import urlConstants from '../../../routes/urlConstants';

// ----------------------------------------------------------------------
let toShowNavs = [];

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  home: getIcon('ic_home'),
  myAccounts: getIcon('ic_myAccounts'),
  addOrders: getIcon('ic_addOrders'),
  orderAnalysis: getIcon('ic_orderAnalysis'),
  productDetails: getIcon('ic_productDetails'),
  claimIssue: getIcon('ic_claimIssue'),
  scanOrders: getIcon('ic_scanOrders'),
  getOrderReports: getIcon('ic_getOrderReports'),
  utilities: getIcon('ic_utilities'),
  admin: getIcon('ic_admin'),
  bmManagement: getIcon('ic_bmManagement'),
  taskManagement: getIcon('ic_taskManagement'),
  updates: getIcon('ic_updates'),
  Flipkart_Rewards: getIcon('Flipkart_Rewards'),
};

const adminNavs = [
  { title: 'Admin', path: urlConstants.ADMIN_PAGE, icon: ICONS.admin },
  { title: 'Task Management', path: urlConstants.TASK_MANAGEMENT_PAGE, icon: ICONS.taskManagement },
];

const userNavs = [
  { title: 'Dashboard', path: urlConstants.HOME_PAGE, icon: ICONS.home, id: 'home' },
  {
    title: 'My Linked Accounts',
    path: urlConstants.MY_LINKED_ACCOUNTS_PAGE,
    icon: ICONS.myAccounts,
    id: 'myLinkedAccounts',
  },
  { title: 'Sync Orders', path: urlConstants.SYNC_ORDERS_PAGE, icon: ICONS.addOrders, id: 'syncOrders' },
  { title: 'Utilities',  icon: ICONS.utilities, id: 'utilities',
    children: [
      { title: 'Label Process', path: 'https://www.easytools4sellers.com', id: 'labelProcess' },
      // { title: 'Accept List', path: urlConstants.ACCEPT_LIST_PAGE, id: 'acceptList' },
      // { title: 'Meesho', path: urlConstants.MeeshoUtilities, id: 'MeeshoUtilities' },

      { title: 'Flipkart', path: urlConstants.FlipkartUtilities, id: 'FlipkartUtilities' },
      // { title: 'Glowroad', path: urlConstants.GlowroadUtilities, id: 'GlowroadUtilities' },
      // { title: 'City Mall', path: urlConstants.CITY_MALL, id: 'CityMallUtilrties' },
      { title: 'Snap Deal', path: urlConstants.SNAP_DEAL, id: 'SnapDealUtilrties' },
      { title: 'Lime Road', path: urlConstants.LIME_ROAD, id: 'LimeRoadUtilrties' },
      { title: 'Customer Review', path: urlConstants.CUSTOMER_REVIEW_PAGE, id: 'customerReview' },

    ],
  },
  {
    title: 'Order Analysis',
    path: urlConstants.ORDER_ANALYSIS_PAGE,
    icon: ICONS.orderAnalysis,
    id: 'orderAnalysis',
  },
  {
    title: 'Flipkart Reward Reco.',
    path: urlConstants.FlipkartRewardReco,
    icon: ICONS.Flipkart_Rewards,
    id: 'flipkart',
  },
  {
    title: 'My Products',
    icon: ICONS.productDetails,
    children: [
      // { title: 'Master Skus', path: urlConstants.MASTER_SKUS_PAGE, id: 'myProductsMasterSkus' },
      { title: 'Explore Skus', path: urlConstants.EXPLORE_SKUS_PAGE, id: 'myProductsExploreSkus' },
    ],
  },
  {
    title: 'Claim Issues',
    icon: ICONS.claimIssue,
    children: [
      { title: 'Meesho', path: urlConstants.MEESHO_CLAIM_ISSUE_PAGE, id: 'claimIssueMeesho' },
      { title: 'Flipkart', path: urlConstants.FLIPKART_CLAIM_ISSUE_PAGE, id: 'claimIssueFlipkart' },
      { title: 'Glowroad', path: urlConstants.GLOWROAD_CLAIM_ISSUE_PAGE, id: 'claimIssueGlowroad' },
      //  TODO ONLY UNCOMMENT AND EDIT CODE AS PER REQUIREMENT AND START CLAIMISSUE
      // { title: 'Shopdeck', path: urlConstants.SHOPDECK_CLAIM_ISSUE_PAGE, id: 'claimIssueShopdeck' },
      // { title: 'Myntra', path: urlConstants.MYNTRA_CLAIM_ISSUE_PAGE, id: 'claimIssueMyntra' },
      // { title: 'Amazon', path: urlConstants.AMAZON_CLAIM_ISSUE_PAGE, id: 'claimIssueAmazon' },
      { title: 'Snapdeal', path: urlConstants.SNAPDEAL_CLAIM_ISSUE_PAGE, id: 'claimIssueSnapdeal' },
      { title: 'LimeRoad', path: urlConstants.LIMEROAD_CLAIM_ISSUE_PAGE, id: 'claimIssueLimeRoad' },
      // { title: 'Jiomart', path: urlConstants.JIOMART_CLAIM_ISSUE_PAGE, id: 'claimIssueJiomart' },
      { title: 'Reported', path: urlConstants.REPORTED_CLAIM_ISSUE_PAGE, id: 'claimIssueReported' },
      { title: 'Auto Ticket', path: urlConstants.AUTO_TICKET, id: 'auto-ticket' },
    ],
  },
  { title: 'Scan Orders', path: urlConstants.SCAN_ORDERS_PAGE, icon: ICONS.scanOrders, id: 'scanOrders' },
  {
    title: 'Monthly Reports',
    path: urlConstants.MONTHLY_REPORTS_PAGE,
    icon: ICONS.getOrderReports,
    id: 'monthlyReports',
  },
  { title: 'Updates', path: urlConstants.UPDATES_PAGE, icon: ICONS.updates, id: 'updates' },
];

if (isAdmin || isSuperAdminEnvironment || isSuperAdminDesktopEnvironment) {
  toShowNavs = adminNavs.concat(userNavs);
} else {
  toShowNavs = userNavs;
}

const navConfig = [
  {
    items: toShowNavs,
    key: 'appNavbar',
  },
];

export default navConfig;
