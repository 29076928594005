/* eslint-disable */
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import DataGridTable from '../../../components/Common/DataGridTable';
import useAuth from '../../../hooks/useAuth';
import { CreatePostAPICall, OrderAnalysis, V2Management } from '../../../utils/apis';
import axios from '../../../utils/axios';
import {
  changeDataFormat,
  changeDateFormat,
  getUserAccountDetails,
  isDate,
  isSuperAdminEnvironment,
} from '../../../utils/utilities';

const MainAdmin = () => {
  const { user } = useAuth();
  const localUser = window.localStorage.getItem('userEmailId');

  const { enqueueSnackbar } = useSnackbar();
  const [addBalance, setAddBalance] = useState('');
  const [selectBalanceCondition, setSelectBalanceCondition] = useState();
  const [awbData, setAwbData] = useState([]);
  const [subOrderData, setSubOrderData] = useState([]);
  const [receivedSub, setReceivedSub] = useState('');
  const [receivedAWB, setReceivedAWB] = useState('');
  const [pickupSub, setPickupSub] = useState('');
  const [pickupAWB, setPickupAWB] = useState('');
  const [subFetchOrderDetails, setSubFetchOrderDetails] = useState('');
  const [awbFetchOrderDetails, setAwbFetchOrderDetails] = useState(false);
  const [fetchOrderData, setFetchOrderData] = useState([]);

  const selectConditionData = ['Referral', 'Recharge', 'Refund'];

  const fetchOrdersDataColumns = [
    {
      accessorKey: 'Id',
      header: 'Id',
    },
    {
      accessorKey: 'Sub_Order_No',
      header: 'Sub Order No',
    },
    {
      accessorKey: 'Latest_Return_Awb',
      header: 'Return Awb',
    },
    {
      accessorKey: 'Pay_Status',
      header: 'Payment Status',
    },
  ];

  const addRecharge = async () => {
    const userMobileNumber = localStorage.getItem('userMobileNumber');
    const body = {
      username: user?.email ? user.email : localUser,
      message: selectBalanceCondition,
      amount: parseInt(addBalance, 10),
      mobileNo: userMobileNumber,
      actionType: 'addAccountBalance',
      panel: 'admin',
      subPanel: 'innerAdmin',
    };

    const response = await CreatePostAPICall(enqueueSnackbar, V2Management, body);
    if (response) {
      enqueueSnackbar('Balance Updated.');
      setSelectBalanceCondition('');
      setAddBalance('');
    }
  };

  const onAwbOptionData = async (event, awbValue) => {
    if (awbValue.length > 0) {
      const accountDetails = await getUserAccountDetails();
      const suppliers = accountDetails.supplierIds;
      const tpEmails = accountDetails.tpEmailids;
      const cNames = accountDetails.cName;
      const acTypes = accountDetails.acTypes;

      const body = {
        username: user?.email ? user.email : localUser,
        tpEmailIds: tpEmails,
        subAccountIds: suppliers,
        cNames:cNames,
        acType: acTypes,
        awb: awbValue,
        get_hints: true,
      };
      const response = await CreatePostAPICall(enqueueSnackbar, OrderAnalysis, body);
      if (response) {
        const newArr = [];
        response.data.body.forEach((value) => {
          newArr.push(value);
        });
        setAwbData(newArr);
      }
    } else {
      setAwbData([]);
    }
  };

  const onSuborderOptionData = async (event, suborderNo) => {
    if (suborderNo.length > 0) {
      const accountDetails = await getUserAccountDetails();
      const suppliers = accountDetails.supplierIds;
      const tpEmails = accountDetails.tpEmailids;
      const cNames = accountDetails.cName;
      const acTypes = accountDetails.acTypes;

      const body = {
        username: user?.email ? user.email : localUser,
        tpEmailIds: tpEmails,
        subAccountIds: suppliers,
        acType: acTypes,
        cNames:cNames,
        suborder_no: suborderNo,
        get_hints: true,
      };
      const response = await CreatePostAPICall(enqueueSnackbar, OrderAnalysis, body);

      if (response) {
        const newArr = [];
        for (const value of response.data.body) {
          if (newArr.indexOf(value) === -1) newArr.push(value);
        }
        setSubOrderData(newArr);
      }

    } else {
      setSubOrderData([]);
    }
  };

  const removeEntryData = async (isRecieved = false) => {
    const body = {
      username: user?.email ? user.email : localUser,
      panel: 'admin',
      subPanel: 'innerAdmin',
    };
    if (isRecieved) {
      if (receivedSub) {
        body.suborder_no = receivedSub;
      } else {
        body.awb = receivedAWB;
      }
      body.actionType = 'removeEntry';
    } else {
      if (pickupSub) {
        body.suborder_no = pickupSub;
      } else {
        body.awb = pickupAWB;
      }
      body.actionType = 'removePickupEntry';
    }
    const response = await CreatePostAPICall(enqueueSnackbar, V2Management, body);
    if (response) {
      enqueueSnackbar(response.data.body);
    }
  };

  const fetchOrderDetails = async () => {
    const accountDetails = await getUserAccountDetails();
    const suppliers = accountDetails.supplierIds;
    const tpEmails = accountDetails.tpEmailids;
    const acTypes = accountDetails.acTypes;

    const body = {
      username: user?.email ? user.email : localUser,
      tpEmailIds: tpEmails,
      subAccountIds: suppliers,
      acTypes,
      actionType: 'getOrderDetails',
      panel: 'admin',
      subPanel: 'innerAdmin',
    };

    if (awbFetchOrderDetails) {
      body.awb = subFetchOrderDetails;
    } else {
      body.suborder_no = subFetchOrderDetails;
    }

    // TODO Add API Into V2 Then It Will Be Start
    const response = await axios.post(V2Management, body);
    if (response.data.body[0]) {
      setFetchOrderData(response.data.body);
    } else {
      enqueueSnackbar('Data Not Found', { variant: 'error' });
    }
  };

  const expandRowComponent = (row) => (
    <>
      <div>
        <Box
          sx={{
            display: 'grid',
            gridAutoFlow: 'row',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gridTemplateRows: 'auto',
            p: 2,
          }}
        >
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={1}>
            <b style={{ marginRight: '2px' }}>Latest Return Awb :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Latest_Return_Awb === '' ? ' - ' : row.original?.Latest_Return_Awb}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={2}>
            <b style={{ marginRight: '2px' }}>Order Status :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Order_Status === '' ? ' - ' : row.original?.Order_Status}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={3}>
            <b style={{ marginRight: '2px' }}>Final Settlement :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Sub_Order_No === '' ? ' - ' : row.original?.Sub_Order_No}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={4}>
            <b style={{ marginRight: '2px' }}>Expected Delivered Date Lsi :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.ExpectedDeliveredDateLsi === '' ? ' - ' : row.original?.ExpectedDeliveredDateLsi}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={5}>
            <b style={{ marginRight: '2px' }}>Is Received :</b>
            <div style={{ marginLeft: '2px' }}>{changeDataFormat(row.original?.Is_Received)}</div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={6}>
            <b style={{ marginRight: '2px' }}>Pick Up Date :</b>
            <div style={{ marginLeft: '2px' }}>
              {isDate(row.original?.Pick_Up_Date) ? changeDateFormat(row.original?.Pick_Up_Date) : ' - '}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={7}>
            <b style={{ marginRight: '2px' }}>Latest Received Lsi :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.LatestReceivedLsi === '' ? ' - ' : row.original?.LatestReceivedLsi}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={8}>
            <b style={{ marginRight: '2px' }}>Return Courier :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Return_Courier === '' ? ' - ' : row.original?.Return_Courier}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={9}>
            <b style={{ marginRight: '2px' }}>Is Exchange :</b>
            <div style={{ marginLeft: '2px' }}>{changeDataFormat(row.original?.Is_Exchange)}</div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={10}>
            <b style={{ marginRight: '2px' }}>Part Key :</b>
            <div style={{ marginLeft: '2px' }}>{row.original?.part_key === '' ? ' - ' : row.original?.part_key}</div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={11}>
            <b style={{ marginRight: '2px' }}>Latest Received Date :</b>
            <div style={{ marginLeft: '2px' }}>
              {isDate(row.original?.Latest_Received_Date)
                ? changeDateFormat(row.original?.Latest_Received_Date)
                : ' - '}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={12}>
            <b style={{ marginRight: '2px' }}>Return_Sub_Type :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Return_Sub_Type === '' ? ' - ' : row.original?.Return_Sub_Type}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={13}>
            <b style={{ marginRight: '2px' }}>Latest_Sent_Awb :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Latest_Sent_Awb === '' ? ' - ' : row.original?.Latest_Sent_Awb}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={14}>
            <b style={{ marginRight: '2px' }}>Invoice_Date :</b>
            <div style={{ marginLeft: '2px' }}>
              {isDate(row.original?.Invoice_Date) ? changeDateFormat(row.original?.Invoice_Date) : ' - '}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={15}>
            <b style={{ marginRight: '2px' }}>Sent Courier :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Sent_Courier === '' ? ' - ' : row.original?.Sent_Courier}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={16}>
            <b style={{ marginRight: '2px' }}>Re Condition :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Re_Condition === '' ? ' - ' : row.original?.Re_Condition}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={17}>
            <b style={{ marginRight: '2px' }}>Latest Out Payment Date :</b>
            <div style={{ marginLeft: '2px' }}>
              {isDate(row.original?.Latest_Out_Payment_Date)
                ? changeDateFormat(row.original?.Latest_Out_Payment_Date)
                : ' - '}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={18}>
            <b style={{ marginRight: '2px' }}>Return Date :</b>
            <div style={{ marginLeft: '2px' }}>
              {isDate(row.original?.Return_Date) ? changeDateFormat(row.original?.Return_Date) : ' - '}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={19}>
            <b style={{ marginRight: '2px' }}>Sent Quantity :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Sent_Quantity === '' ? ' - ' : row.original?.Sent_Quantity}
            </div>
          </div>
        </Box>
        <Divider sx={{ borderStyle: 'dashed' }} />
        <Typography sx={{ pl: 2, pt: 2 }} variant="h6">
          Payment Info
        </Typography>
        <Box
          sx={{
            display: 'grid',
            gridAutoFlow: 'row',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gridTemplateRows: 'auto',
            p: 2,
          }}
        >
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={20}>
            <b style={{ marginRight: '2px' }}>Claim :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Payment_Info.payout.additionalPayout.claim === ''
                ? ' - '
                : row.original?.Payment_Info.payout.additionalPayout.claim}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={21}>
            <b style={{ marginRight: '2px' }}>Compensation :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Payment_Info.payout.additionalPayout.compensation === ''
                ? ' - '
                : row.original?.Payment_Info.payout.additionalPayout.compensation}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={22}>
            <b style={{ marginRight: '2px' }}>Recovery :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Payment_Info.payout.additionalPayout.recovery === ''
                ? ' - '
                : row.original?.Payment_Info.payout.additionalPayout.recovery}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={23}>
            <b style={{ marginRight: '2px' }}>Address Line 1 :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Payment_Info.address.address_line_1 === ''
                ? ' - '
                : row.original?.Payment_Info.address.address_line_1}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={24}>
            <b style={{ marginRight: '2px' }}>Address Line 2 :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Payment_Info.address.address_line_2 === ''
                ? ' - '
                : row.original?.Payment_Info.address.address_line_2}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={25}>
            <b style={{ marginRight: '2px' }}>City :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Payment_Info.address.city === '' ? ' - ' : row.original?.Payment_Info.address.city}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={26}>
            <b style={{ marginRight: '2px' }}>Landmark :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Payment_Info.address.city === '' ? ' - ' : row.original?.Payment_Info.address.landmark}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={27}>
            <b style={{ marginRight: '2px' }}>Pin :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Payment_Info.address.pin === '' ? ' - ' : row.original?.Payment_Info.address.pin}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={28}>
            <b style={{ marginRight: '2px' }}>State :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Payment_Info.address.state === '' ? ' - ' : row.original?.Payment_Info.address.state}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={29}>
            <b style={{ marginRight: '2px' }}>HSN Code :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Payment_Info.orderDetails.hsnCode === ''
                ? ' - '
                : row.original?.Payment_Info.orderDetails.hsnCode}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={30}>
            <b style={{ marginRight: '2px' }}>Image :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Payment_Info.orderDetails.image === ''
                ? ' - '
                : row.original?.Payment_Info.orderDetails.image}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={31}>
            <b style={{ marginRight: '2px' }}>Input Price Inc Gst :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Payment_Info.orderDetails.inputPriceIncGst === ''
                ? ' - '
                : row.original?.Payment_Info.orderDetails.inputPriceIncGst}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={32}>
            <b style={{ marginRight: '2px' }}>order IGST :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Payment_Info.orderDetails.orderIGST === ''
                ? ' - '
                : row.original?.Payment_Info.orderDetails.orderIGST}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={33}>
            <b style={{ marginRight: '2px' }}>Product Name:</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Payment_Info.orderDetails.productName === ''
                ? ' - '
                : row.original?.Payment_Info.orderDetails.productName}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={34}>
            <b style={{ marginRight: '2px' }}>Quantity :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Payment_Info.orderDetails.quantity === ''
                ? ' - '
                : row.original?.Payment_Info.orderDetails.quantity}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={35}>
            <b style={{ marginRight: '2px' }}>Sub Order Num : </b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Payment_Info.orderDetails.subOrderNum === ''
                ? ' - '
                : row.original?.Payment_Info.orderDetails.subOrderNum}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={36}>
            <b style={{ marginRight: '2px' }}>Supplier SKU :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Payment_Info.orderDetails.supplierSKU === ''
                ? ' - '
                : row.original?.Payment_Info.orderDetails.supplierSKU}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={37}>
            <b style={{ marginRight: '2px' }}>GST Compensation :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Payment_Info.payout.marketplaceFeeDeductions.gstCompensation === ''
                ? ' - '
                : row.original?.Payment_Info.payout.marketplaceFeeDeductions.gstCompensation}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={38}>
            <b style={{ marginRight: '2px' }}>GST On Meesho Commission :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Payment_Info.payout.marketplaceFeeDeductions.gstOnMeeshoCommission === ''
                ? ' - '
                : row.original?.Payment_Info.payout.marketplaceFeeDeductions.gstOnMeeshoCommission}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={39}>
            <b style={{ marginRight: '2px' }}>GST On Meesho MallBrand Fee Commission :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Payment_Info.payout.marketplaceFeeDeductions.gstOnMeeshoMallBrandFeeCommission === ''
                ? ' - '
                : row.original?.Payment_Info.payout.marketplaceFeeDeductions.gstOnMeeshoMallBrandFeeCommission}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={40}>
            <b style={{ marginRight: '2px' }}>GST On Return ShippingCharge :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Payment_Info.payout.marketplaceFeeDeductions.gstOnReturnShippingCharge === ''
                ? ' - '
                : row.original?.Payment_Info.payout.marketplaceFeeDeductions.gstOnReturnShippingCharge}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={41}>
            <b style={{ marginRight: '2px' }}>GST On RTO ShippingCharge :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Payment_Info.payout.marketplaceFeeDeductions.gstOnRtoShippingCharge === ''
                ? ' - '
                : row.original?.Payment_Info.payout.marketplaceFeeDeductions.gstOnRtoShippingCharge}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={42}>
            <b style={{ marginRight: '2px' }}>GST On Shipping Charge :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Payment_Info.payout.marketplaceFeeDeductions.gstOnShippingCharge === ''
                ? ' - '
                : row.original?.Payment_Info.payout.marketplaceFeeDeductions.gstOnShippingCharge}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={43}>
            <b style={{ marginRight: '2px' }}>Meesho Commission Percent :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Payment_Info.payout.marketplaceFeeDeductions.meeshoCommissionPercent === ''
                ? ' - '
                : row.original?.Payment_Info.payout.marketplaceFeeDeductions.meeshoCommissionPercent}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={44}>
            <b style={{ marginRight: '2px' }}>Meesho CommissionValue ExcGst :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Payment_Info.payout.marketplaceFeeDeductions.meeshoCommissionValueExcGst === ''
                ? ' - '
                : row.original?.Payment_Info.payout.marketplaceFeeDeductions.meeshoCommissionValueExcGst}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={45}>
            <b style={{ marginRight: '2px' }}>Meesho CommissionValue IncGst :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Payment_Info.payout.marketplaceFeeDeductions.meeshoCommissionValueIncGst === ''
                ? ' - '
                : row.original?.Payment_Info.payout.marketplaceFeeDeductions.meeshoCommissionValueIncGst}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={46}>
            <b style={{ marginRight: '2px' }}>Meesho MallBrandFeeCommissionValue ExcGst :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Payment_Info.payout.marketplaceFeeDeductions.meeshoMallBrandFeeCommissionValueExcGst === ''
                ? ' - '
                : row.original?.Payment_Info.payout.marketplaceFeeDeductions.meeshoMallBrandFeeCommissionValueExcGst}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={47}>
            <b style={{ marginRight: '2px' }}>Meesho MallBrandFeeCommissionValue IncGst :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Payment_Info.payout.marketplaceFeeDeductions.meeshoMallBrandFeeCommissionValueIncGst === ''
                ? ' - '
                : row.original?.Payment_Info.payout.marketplaceFeeDeductions.meeshoMallBrandFeeCommissionValueIncGst}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={48}>
            <b style={{ marginRight: '2px' }}>RTN ShippingCharge ExcGst :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Payment_Info.payout.marketplaceFeeDeductions.returnShippingChargeExcGst === ''
                ? ' - '
                : row.original?.Payment_Info.payout.marketplaceFeeDeductions.returnShippingChargeExcGst}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={49}>
            <b style={{ marginRight: '2px' }}>RTN ShippingCharge IncGst :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Payment_Info.payout.marketplaceFeeDeductions.returnShippingChargeIncGst === ''
                ? ' - '
                : row.original?.Payment_Info.payout.marketplaceFeeDeductions.returnShippingChargeIncGst}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={50}>
            <b style={{ marginRight: '2px' }}>RTO ShippingCharge ExcGst :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Payment_Info.payout.marketplaceFeeDeductions.rtoShippingChargeExcGst === ''
                ? ' - '
                : row.original?.Payment_Info.payout.marketplaceFeeDeductions.rtoShippingChargeExcGst}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={51}>
            <b style={{ marginRight: '2px' }}>RTO ShippingCharge IncGst :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Payment_Info.payout.marketplaceFeeDeductions.rtoShippingChargeIncGst === ''
                ? ' - '
                : row.original?.Payment_Info.payout.marketplaceFeeDeductions.rtoShippingChargeIncGst}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={52}>
            <b style={{ marginRight: '2px' }}>shippingCharge ExcGst :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Payment_Info.payout.marketplaceFeeDeductions.shippingChargeExcGst === ''
                ? ' - '
                : row.original?.Payment_Info.payout.marketplaceFeeDeductions.shippingChargeExcGst}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={53}>
            <b style={{ marginRight: '2px' }}>shippingCharge IncGst :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Payment_Info.payout.marketplaceFeeDeductions.shippingChargeIncGst === ''
                ? ' - '
                : row.original?.Payment_Info.payout.marketplaceFeeDeductions.shippingChargeIncGst}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={54}>
            <b style={{ marginRight: '2px' }}>Final Settlement Amount :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Payment_Info.payout.paymentDetails.finalSettlementAmount === ''
                ? ' - '
                : row.original?.Payment_Info.payout.paymentDetails.finalSettlementAmount}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={55}>
            <b style={{ marginRight: '2px' }}>Meesho Commission :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Payment_Info.payout.paymentDetails.gstInputCredits.meeshoCommission === ''
                ? ' - '
                : row.original?.Payment_Info.payout.paymentDetails.gstInputCredits.meeshoCommission}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={56}>
            <b style={{ marginRight: '2px' }}>Penalty Waiver :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Payment_Info.payout.paymentDetails.gstInputCredits.penaltyWaiver === ''
                ? ' - '
                : row.original?.Payment_Info.payout.paymentDetails.gstInputCredits.penaltyWaiver}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={57}>
            <b style={{ marginRight: '2px' }}>RTN ShippingCharge :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Payment_Info.payout.paymentDetails.gstInputCredits.returnShippingCharge === ''
                ? ' - '
                : row.original?.Payment_Info.payout.paymentDetails.gstInputCredits.returnShippingCharge}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={1}>
            <b style={{ marginRight: '2px' }}>RTO ShippingCharge :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Payment_Info.payout.paymentDetails.gstInputCredits.rtoShippingCharge === ''
                ? ' - '
                : row.original?.Payment_Info.payout.paymentDetails.gstInputCredits.rtoShippingCharge}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={58}>
            <b style={{ marginRight: '2px' }}>Shipping Charge :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Payment_Info.payout.paymentDetails.gstInputCredits.shippingCharge === ''
                ? ' - '
                : row.original?.Payment_Info.payout.paymentDetails.gstInputCredits.shippingCharge}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={59}>
            <b style={{ marginRight: '2px' }}>TotalGst InputCredits :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Payment_Info.payout.paymentDetails.gstInputCredits.totalGstInputCredits === ''
                ? ' - '
                : row.original?.Payment_Info.payout.paymentDetails.gstInputCredits.totalGstInputCredits}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={60}>
            <b style={{ marginRight: '2px' }}>NetPenalty Waiver :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Payment_Info.payout.paymentDetails.netPenaltyWaiver === ''
                ? ' - '
                : row.original?.Payment_Info.payout.paymentDetails.netPenaltyWaiver}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={61}>
            <b style={{ marginRight: '2px' }}>Tcs CGSTAmount :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Payment_Info.payout.paymentDetails.tcsCGSTAmount === ''
                ? ' - '
                : row.original?.Payment_Info.payout.paymentDetails.tcsCGSTAmount}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={62}>
            <b style={{ marginRight: '2px' }}>Tcs IGSTAmount :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Payment_Info.payout.paymentDetails.tcsIGSTAmount === ''
                ? ' - '
                : row.original?.Payment_Info.payout.paymentDetails.tcsIGSTAmount}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={63}>
            <b style={{ marginRight: '2px' }}>Tcs InputCredits :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Payment_Info.payout.paymentDetails.tcsInputCredits === ''
                ? ' - '
                : row.original?.Payment_Info.payout.paymentDetails.tcsInputCredits}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={64}>
            <b style={{ marginRight: '2px' }}>Total AdditionalPayout :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Payment_Info.payout.paymentDetails.totalAdditionalPayout === ''
                ? ' - '
                : row.original?.Payment_Info.payout.paymentDetails.totalAdditionalPayout}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={65}>
            <b style={{ marginRight: '2px' }}>Total MarketplaceFee :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Payment_Info.payout.paymentDetails.totalMarketplaceFee === ''
                ? ' - '
                : row.original?.Payment_Info.payout.paymentDetails.totalMarketplaceFee}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={66}>
            <b style={{ marginRight: '2px' }}>Total Revenue :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Payment_Info.payout.paymentDetails.totalRevenue === ''
                ? ' - '
                : row.original?.Payment_Info.payout.paymentDetails.totalRevenue}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={67}>
            <b style={{ marginRight: '2px' }}>Price Type :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Payment_Info.payout.payoutMetaData?.unbundlingDetails.returnUnbundling.priceType === ''
                ? ' - '
                : row.original?.Payment_Info.payout.payoutMetaData.unbundlingDetails.returnUnbundling.priceType}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={68}>
            <b style={{ marginRight: '2px' }}>version :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Payment_Info.payout?.payoutMetaData?.unbundlingDetails.returnUnbundling.version === ''
                ? ' - '
                : row.original?.Payment_Info.payout.payoutMetaData.unbundlingDetails.returnUnbundling.version}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={69}>
            <b style={{ marginRight: '2px' }}>GST OnPenalties :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Payment_Info.payout?.penaltyDetails?.gstOnPenalties === ''
                ? ' - '
                : row.original?.Payment_Info.payout.penaltyDetails.gstOnPenalties}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={70}>
            <b style={{ marginRight: '2px' }}>GST OnWaivers :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Payment_Info.payout?.penaltyDetails?.gstOnWaivers === ''
                ? ' - '
                : row.original?.Payment_Info.payout.penaltyDetails.gstOnWaivers}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={71}>
            <b style={{ marginRight: '2px' }}>Penalties ExcGst :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Payment_Info.payout?.penaltyDetails?.penaltiesExcGst === ''
                ? ' - '
                : row.original?.Payment_Info.payout.penaltyDetails.penaltiesExcGst}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={72}>
            <b style={{ marginRight: '2px' }}>Penalties IncGst :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Payment_Info.payout?.penaltyDetails?.penaltiesIncGst === ''
                ? ' - '
                : row.original?.Payment_Info.payout.penaltyDetails.penaltiesIncGst}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={73}>
            <b style={{ marginRight: '2px' }}>Waivers ExcGst :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Payment_Info.payout?.penaltyDetails?.waiversExcGst === ''
                ? ' - '
                : row.original?.Payment_Info.payout.penaltyDetails.waiversExcGst}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={74}>
            <b style={{ marginRight: '2px' }}>Waivers IncGst :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Payment_Info.payout?.penaltyDetails?.waiversIncGst === ''
                ? ' - '
                : row.original?.Payment_Info.payout.penaltyDetails.waiversIncGst}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={75}>
            <b style={{ marginRight: '2px' }}>GST OnReturnAmount :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Payment_Info.payout?.revenueDetails.gstOnReturnAmount === ''
                ? ' - '
                : row.original?.Payment_Info.payout.revenueDetails.gstOnReturnAmount}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={76}>
            <b style={{ marginRight: '2px' }}>GST OnSaleAmount :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Payment_Info.payout?.revenueDetails.gstOnSaleAmount === ''
                ? ' - '
                : row.original?.Payment_Info.payout.revenueDetails.gstOnSaleAmount}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={77}>
            <b style={{ marginRight: '2px' }}>GST OnSaleAmount :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Payment_Info.payout?.revenueDetails.gstOnSaleAmount === ''
                ? ' - '
                : row.original?.Payment_Info.payout.revenueDetails.gstOnSaleAmount}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={78}>
            <b style={{ marginRight: '2px' }}>GST OnShippingReturn :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Payment_Info.payout?.revenueDetails.gstOnShippingReturn === ''
                ? ' - '
                : row.original?.Payment_Info.payout.revenueDetails.gstOnShippingReturn}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={79}>
            <b style={{ marginRight: '2px' }}>GST OnShippingRevenue :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Payment_Info.payout?.revenueDetails.gstOnShippingRevenue === ''
                ? ' - '
                : row.original?.Payment_Info.payout.revenueDetails.gstOnShippingRevenue}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={80}>
            <b style={{ marginRight: '2px' }}>PrepaidDiscountAmount :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Payment_Info.payout?.revenueDetails.prepaidDiscountAmount === ''
                ? ' - '
                : row.original?.Payment_Info.payout.revenueDetails.prepaidDiscountAmount}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={81}>
            <b style={{ marginRight: '2px' }}>PrepaidDiscountReturnAmount :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Payment_Info.payout?.revenueDetails.prepaidDiscountReturnAmount === ''
                ? ' - '
                : row.original?.Payment_Info.payout.revenueDetails.prepaidDiscountReturnAmount}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={82}>
            <b style={{ marginRight: '2px' }}>SaleReturnAmountExcGst :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Payment_Info.payout?.revenueDetails.saleReturnAmountExcGst === ''
                ? ' - '
                : row.original?.Payment_Info.payout.revenueDetails.saleReturnAmountExcGst}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={83}>
            <b style={{ marginRight: '2px' }}>SaleReturnAmountIncGst :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Payment_Info.payout?.revenueDetails.saleReturnAmountIncGst === ''
                ? ' - '
                : row.original?.Payment_Info.payout.revenueDetails.saleReturnAmountIncGst}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={84}>
            <b style={{ marginRight: '2px' }}>ShippingReturnAmountExcGst :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Payment_Info.payout?.revenueDetails.shippingReturnAmountExcGst === ''
                ? ' - '
                : row.original?.Payment_Info.payout.revenueDetails.shippingReturnAmountExcGst}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={85}>
            <b style={{ marginRight: '2px' }}>ShippingReturn AmountIncGst :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Payment_Info.payout?.revenueDetails.shippingReturnAmountIncGst === ''
                ? ' - '
                : row.original?.Payment_Info.payout.revenueDetails.shippingReturnAmountIncGst}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={86}>
            <b style={{ marginRight: '2px' }}>Shipping Revenue ExcGst :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Payment_Info.payout?.revenueDetails.shippingRevenueExcGst === ''
                ? ' - '
                : row.original?.Payment_Info.payout.revenueDetails.shippingRevenueExcGst}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={87}>
            <b style={{ marginRight: '2px' }}>Shipping Revenue IncGst :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Payment_Info.payout?.revenueDetails.shippingRevenueIncGst === ''
                ? ' - '
                : row.original?.Payment_Info.payout.revenueDetails.shippingRevenueIncGst}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={88}>
            <b style={{ marginRight: '2px' }}>Total SaleAmount ExcGst :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Payment_Info.payout?.revenueDetails.totalSaleAmountExcGst === ''
                ? ' - '
                : row.original?.Payment_Info.payout.revenueDetails.totalSaleAmountExcGst}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={89}>
            <b style={{ marginRight: '2px' }}>Total SaleAmount IncGst :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Payment_Info.payout?.revenueDetails.totalSaleAmountIncGst === ''
                ? ' - '
                : row.original?.Payment_Info.payout.revenueDetails.totalSaleAmountIncGst}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={90}>
            <b style={{ marginRight: '2px' }}>Zonal Discount Amount :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Payment_Info.payout?.revenueDetails.zonalDiscountAmount === ''
                ? ' - '
                : row.original?.Payment_Info.payout.revenueDetails.zonalDiscountAmount}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={91}>
            <b style={{ marginRight: '2px' }}>Zonal Discount ReturnAmount :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Payment_Info.payout?.revenueDetails.zonalDiscountReturnAmount === ''
                ? ' - '
                : row.original?.Payment_Info.payout.revenueDetails.zonalDiscountReturnAmount}
            </div>
          </div>
          {/* Shipment Status List */}
          {row.original?.Payment_Info.shipmentStatusList.map((item) => (
            <>
              {Object.entries(item).map(([key, value]) => (
                <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={`${value}A${key}`}>
                  <b style={{ marginRight: '2px' }}>{key} :</b>
                  <div style={{ marginLeft: '2px' }} key={key}>
                    {key === 'eventDate' ? changeDateFormat(value) : value}
                  </div>
                </div>
              ))}
            </>
          ))}
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={92}>
            <b style={{ marginRight: '2px' }}>PickUpDateLsi :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.PickUpDateLsi === '' ? ' - ' : row.original?.PickUpDateLsi}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={93}>
            <b style={{ marginRight: '2px' }}>Return Date Lsi :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.ReturnDateLsi === '' ? ' - ' : row.original?.ReturnDateLsi}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={94}>
            <b style={{ marginRight: '2px' }}>Return Quantity :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Return_Quantity === '' ? ' - ' : row.original?.Return_Quantity}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={95}>
            <b style={{ marginRight: '2px' }}>Return Type :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.Return_Type === '' ? ' - ' : row.original?.Return_Type}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={96}>
            <b style={{ marginRight: '2px' }}>Self Cost :</b>
            <div style={{ marginLeft: '2px' }}>{row.original?.Self_Cost === '' ? ' - ' : row.original?.Self_Cost}</div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={97}>
            <b style={{ marginRight: '2px' }}>Expected Dispatch Date :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.expected_dispatch_date === ''
                ? ' - '
                : changeDateFormat(row.original?.expected_dispatch_date)}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={98}>
            <b style={{ marginRight: '2px' }}>Is Doutefull :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.isDouteFull === '' ? ' - ' : changeDataFormat(row.original?.isDouteFull)}
            </div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={99}>
            <b style={{ marginRight: '2px' }}>sort key :</b>
            <div style={{ marginLeft: '2px' }}>{row.original?.sort_key === '' ? ' - ' : row.original?.sort_key}</div>
          </div>
          <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }} key={100}>
            <b style={{ marginRight: '2px' }}>Ts Expected Dispatch Date :</b>
            <div style={{ marginLeft: '2px' }}>
              {row.original?.ts_expected_dispatch_date === '' ? ' - ' : row.original?.ts_expected_dispatch_date}
            </div>
          </div>
        </Box>
      </div>
    </>
  );

  return (
    <>
      <Divider sx={{ p: 1 }} />

      <Grid sx={{ p: 2 }} container spacing={3}>
        {isSuperAdminEnvironment && (
          <Grid item xs={12} md={4}>
            <Typography sx={{ pb: 2 }} variant="h6">
              Add Balance
            </Typography>
            <Divider />
            <Box
              sx={{
                display: 'grid',
                rowGap: 2,
                p: 1.5,
              }}
            >
              <FormControl>
                <InputLabel id="selectCondition">Select Condition</InputLabel>
                <Select
                  labelId="selectCondition"
                  value={selectBalanceCondition || ''}
                  label="Select Condition"
                  onChange={(event) => {
                    setSelectBalanceCondition(event.target.value);
                  }}
                >
                  {selectConditionData.map((name) => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                id="outlined-basic"
                label="Amount"
                type="number"
                variant="outlined"
                value={addBalance}
                onChange={(event) => {
                  setAddBalance(event.target.value);
                }}
              />

              <div style={{ width: '100%', textAlign: 'end' }}>
                <Button variant="contained" disabled={!addBalance || !selectBalanceCondition} onClick={addRecharge}>
                  Recharge
                </Button>
              </div>
            </Box>
          </Grid>
        )}

        <Grid item xs={12} md={4}>
          <Typography sx={{ pb: 2 }} variant="h6">
            Remove Received Entry
          </Typography>
          <Divider />
          <Box
            sx={{
              display: 'grid',
              rowGap: 2,
              p: 1.5,
            }}
          >
            <FormControl sx={{ width: '100%' }}>
              <Autocomplete
                options={awbData}
                onInputChange={onAwbOptionData}
                onChange={(e, value) => {
                  setReceivedAWB(value);
                }}
                renderInput={(params) => <TextField {...params} label="Return AWB Number" />}
              />
            </FormControl>
            <h4 style={{ textAlign: 'center' }}> OR </h4>
            <FormControl sx={{ width: '100%' }}>
              <Autocomplete
                  options={subOrderData}
                  onInputChange={onSuborderOptionData}
                  onChange={(e, value) => {
                    setReceivedSub(value);
                  }}
                  renderInput={(params) => <TextField {...params} label="Return Sub Order Number" size="small" />}
              />
            </FormControl>

            <div style={{ width: '100%', textAlign: 'end' }}>
              <Button
                variant="contained"
                disabled={!receivedSub && !receivedAWB}
                onClick={() => {
                  removeEntryData(true);
                }}
              >
                Remove
              </Button>
            </div>
          </Box>
        </Grid>

        <Grid item xs={12} md={4}>
          <Typography sx={{ pb: 2 }} variant="h6">
            Remove Pickup Entry
          </Typography>
          <Divider />
          <Box
            sx={{
              display: 'grid',
              rowGap: 2,
              p: 1.5,
            }}
          >
            <FormControl sx={{ width: '100%' }}>
              <Autocomplete
                onChange={(e, value) => {
                  setPickupAWB(value);
                }}
                options={awbData}
                onInputChange={onAwbOptionData}
                renderInput={(params) => <TextField {...params} label="Pickup AWB Number" />}
              />
            </FormControl>
            <h4 style={{ textAlign: 'center' }}> OR </h4>
            <FormControl sx={{ width: '100%' }}>
              <Autocomplete
                onChange={(e, value) => {
                  setPickupSub(value);
                }}
                options={subOrderData}
                onInputChange={onSuborderOptionData}
                renderInput={(params) => <TextField {...params} label="Pickup Sub Order Number" />}
              />
            </FormControl>

            <div style={{ width: '100%', textAlign: 'end' }}>
              <Button
                variant="contained"
                disabled={!pickupSub && !pickupAWB}
                onClick={() => {
                  removeEntryData();
                }}
              >
                Remove
              </Button>
            </div>
          </Box>
        </Grid>

        {/*<Grid item xs={12} md={4}>*/}
        {/*  <Typography sx={{ pb: 2 }} variant="h6">*/}
        {/*    Get Order Details*/}
        {/*  </Typography>*/}
        {/*  <Divider />*/}
        {/*  <Box*/}
        {/*    sx={{*/}
        {/*      display: 'grid',*/}
        {/*      rowGap: 2,*/}
        {/*      p: 1.5,*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    <FormControl sx={{ width: '100%' }}>*/}
        {/*      <Autocomplete*/}
        {/*        onChange={(e, value) => {*/}
        {/*          setAwbFetchOrderDetails(true);*/}
        {/*          setSubFetchOrderDetails(value);*/}
        {/*          setFetchOrderData('');*/}
        {/*        }}*/}
        {/*        options={awbData}*/}
        {/*        onInputChange={onAwbOptionData}*/}
        {/*        renderInput={(params) => <TextField {...params} label="Pickup AWB Number" />}*/}
        {/*      />*/}
        {/*    </FormControl>*/}
        {/*    <h4 style={{ textAlign: 'center' }}> OR </h4>*/}
        {/*    <FormControl sx={{ width: '100%' }}>*/}
        {/*      <Autocomplete*/}
        {/*        getOptionLabel={(option) => (typeof option === 'string' || option instanceof String ? option : '')}*/}
        {/*        onChange={(e, value) => {*/}
        {/*          setAwbFetchOrderDetails(false);*/}
        {/*          setSubFetchOrderDetails(value);*/}
        {/*          setFetchOrderData('');*/}
        {/*        }}*/}
        {/*        options={subOrderData}*/}
        {/*        onInputChange={onSuborderOptionData}*/}
        {/*        renderInput={(params) => <TextField {...params} label="Pickup Sub Order Number" />}*/}
        {/*      />*/}
        {/*    </FormControl>*/}

        {/*    <div style={{ width: '100%', textAlign: 'end' }}>*/}
        {/*      <Button variant="contained" disabled={!subFetchOrderDetails} onClick={fetchOrderDetails}>*/}
        {/*        Fetch Order Details*/}
        {/*      </Button>*/}
        {/*    </div>*/}
        {/*  </Box>*/}
        {/*</Grid>*/}

      </Grid>

      {fetchOrderData.length !== 0 ? (
        <>
          <DataGridTable
            columns={fetchOrdersDataColumns}
            data={fetchOrderData}
            renderDetailPanel={expandRowComponent}
            expand={'true'}
          />
        </>
      ) : null}
    </>
  );
};

export default MainAdmin;
