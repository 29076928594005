import { Stack, Button, Typography } from '@mui/material';
import useAuth from '../../hooks/useAuth';
import { DocIllustration } from '../../assets';

export default function Help() {
  const { user } = useAuth();

  return (
    <>
      <Stack spacing={3} sx={{ px: 5, width: 1, textAlign: 'center', display: 'block' }}>
        <DocIllustration sx={{ width: 1 }} />
        <div>
          <Typography gutterBottom variant="subtitle1">
            Hi, {user?.displayName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary', textAlign: 'center' }}>
            Need help?
            <br /> Please contact us
            {/* <br/> <b>Mon to Sat (10:00 AM to 6:00 PM) / Lunch Break (1:00 PM to 2:00 PM)</b> */}
          </Typography>
        </div>

        <Button href={`tel:${+912613549130}`} rel="noopener" variant="contained">
          0261-3549130 (Helpline)
        </Button>
      </Stack>
    </>
  );
}
