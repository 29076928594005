import { Container, Grid } from '@mui/material';
import React from 'react';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import Page from '../../../components/Page';
import useSettings from '../../../hooks/useSettings';
import urlConstants from '../../../routes/urlConstants';
import FlipkartClaimIssueDetails from './FlipkartClaimIssueDetails';

export default function FlipkartClaimIssue() {
  const { themeStretch } = useSettings();

  return (
    <Page title="FlipkartClaimIssue">
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <HeaderBreadcrumbs
          heading="Claim Issue"
          links={[
            { name: 'Dashboard', href: urlConstants.HOME_PAGE },
            { name: 'Claim Issue', href: urlConstants.MEESHO_CLAIM_ISSUE_PAGE },
            { name: 'Flipkart' },
          ]}
        />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FlipkartClaimIssueDetails />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
