/* eslint-disable */
import React, {useEffect, useState} from 'react';
import {
    Grid,
    TextField,
    IconButton,
    Box,
    Divider,
    Button,
    Autocomplete,
    Typography,
    Chip,
    Avatar,
} from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import InputLabel from '@mui/material/InputLabel';
import {LoadingButton} from '@mui/lab';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {CreatePostAPICall, MyLinkedAccount} from '../../../utils/apis';
import {
    BootstrapDialog,
    BootstrapDialogTitle,
    CapitalizeFirstLetter,
    getAccountsData, IOSSwitch, isAdmin, isSuperAdminEnvironment, SystemColor,
} from '../../../utils/utilities';
import DataGridTable from '../../../components/Common/DataGridTable';
import Iconify from '../../../components/Iconify';
import useAuth from "../../../hooks/useAuth";
import AccountStatusButton from './AccountStatusButton';

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 175,
            width: 250,
        },
    },
};

export default function TimeAccountModal({
                                             closeTimeAccountHistoryModal,
                                             platform,
                                             timeHistoryScheduleData,
                                             openTimeHistoryScheduleModel,
                                             enqueueSnackbar,
                                         }) {

    const {user} = useAuth();
    const localUser = window.localStorage.getItem('userEmailId');

    const [optionTypeArray, setOptionTypeArray] = useState([]);
    const [ddbDate, setDdbDate] = useState("NEXT_SLOT")
    const [filterValue, setFilterValue] = useState("ALL")
    const [updateLbhw, setUpdateLbhw] = useState(true)
    const [optionTypeDayArray, setOptionTypeDayArray] = useState([]);
    const [selectedTime, setSelectedTime] = useState('07:30');
    const [existingTimerSchedules, setExistingTimerSchedules] = useState([]);
    const [timerData, setTimerData] = useState({});
    const [expandedRow, setExpandedRow] = useState(null);
    const [isInserting, setIsInserting] = useState(false);
    const [selectedType, setSelectedType] = useState('rts');
    const [selectedDayType, setSelectedDayType] = useState('Monday');
    const [allAccountsData, setAllAccountsData] = useState([]);
    const [selectTimerAccount, setSelectTimerAccount] = useState('');
    const [otherAccountTimer, setOtherAccountTimer] = useState([]);
    const [columns, setColumns] = useState([
        {
            accessorKey: 'index',
            header: 'No',
            size: 10,
        },
        {
            accessorKey: 'type',
            header: 'Type',
            size: 30,
        },
        {
            accessorKey: 'time',
            header: 'Time',
            size: 30,
            Cell: ({cell}) => {
                const [hours, minutes] = cell.row.original?.time.split(':');

                let ampm = 'AM';
                let twelveHour = parseInt(hours, 10);

                if (twelveHour >= 12) {
                    ampm = 'PM';
                    if (twelveHour > 12) {
                        twelveHour -= 12;
                    }
                }
                return <div>
                    {`${twelveHour.toString().padStart(2, '0')}:${minutes} ${ampm}`}
                </div>
            }
        },
        {
            accessorKey: 'status',
            header: 'Status',
            size: 10,
            Cell: ({cell}) =>
              cell.row.original?.status === 'ACTIVE' ? (
                <Button variant="outlined" style={{color: SystemColor.originColor}}>
                    Active
                </Button>
              ) : (
                <Button variant="outlined" color="error">
                    Deactive
                </Button>
              ),
        },
        {
            accessorKey: 'action',
            header: 'Action',
            size: 10,
            Cell: ({ cell }) => (
              <IconButton
                onClick={async () => {
                    await deleteScheduleHistory(cell.row.original?.timerIndexKey);
                }}
              >
                  <Iconify icon={'mingcute:delete-2-line'} height={24} />
              </IconButton>
            ),
        }
    ]);

    const  slotList = ["NEXT_SLOT", "ALL", "TODAY_ALL", "ONLY_BREACHED"]
    const  filterList = ["ALL", "SINGLE", "MULTI"]

    useEffect(() => {
        if (platform==='flipkart') {
            setColumns(prevState => [
                ...prevState,
                {
                    accessorKey: 'c',
                    header: '',
                    size: 10,
                    Cell: ({ cell }) => {
                        var data = timeHistoryScheduleData ? timeHistoryScheduleData.isMaskingEnabled : false;
                        return (data ? <div style={{
                            color: '#029a02',
                            display: 'inline-flex',
                            verticalAlign: 'middle',
                            alignItems: 'center'
                        }}>
                            <Iconify icon={'bi:check-circle-fill'} width={20} height={20}/>
                        </div> : <div style={{
                            color: '#fd0000',
                            display: 'inline-flex',
                            verticalAlign: 'middle',
                            alignItems: 'center'
                        }}>
                            <Iconify icon={'material-symbols:cancel'} width={20} height={20}/>
                        </div>);
                    },
                },
            ]);
        }
        setTimerData(timeHistoryScheduleData)
    }, []);
    const SystemColor = {
        error: '#FF0000',
        success: '#21AE8C',
        originColor: '#7635dc',
    };
    // copy timer data temp column
    const otherAcoountTimercolumns = [
        {
            accessorKey: 'index',
            header: 'No',
            size: 10,
        },
        {
            accessorKey: 'type',
            header: 'Type',
            size: 30,
        },
        {
            accessorKey: 'time',
            header: 'Time',
            size: 30,
            Cell: ({cell}) => {
                const [hours, minutes] = cell.row.original?.time.split(':');

                let ampm = 'AM';
                let twelveHour = parseInt(hours, 10);

                if (twelveHour >= 12) {
                    ampm = 'PM';
                    if (twelveHour > 12) {
                        twelveHour -= 12;
                    }
                }
                return <div>
                    {`${twelveHour.toString().padStart(2, '0')}:${minutes} ${ampm}`}
                </div>
            }
        },
        {
            accessorKey: 'status',
            header: 'Status',
            size: 10,
            Cell: ({cell}) =>
                cell.row.original?.status === 'ACTIVE' ? (
                    <LoadingButton variant="outlined" style={{color: SystemColor.originColor}}>
                        Active
                    </LoadingButton>
                ) : (
                    <Button variant="outlined" color="error">
                        Deactive
                    </Button>
                ),
        },
        {
            accessorKey: 'action',
            header: 'Action',
            size: 10,
            Cell: ({cell}) => (
                <IconButton
                    onClick={async () => {
                        let tempData = [...otherAccountTimer];
                        tempData = tempData.filter((x) => {
                            return x.timerIndexKey !== cell.row.original?.timerIndexKey;
                        });
                        setOtherAccountTimer(tempData);
                    }}
                >
                    <Iconify icon={'mingcute:delete-2-line'} height={24}/>
                </IconButton>
            ),
        },

    ];


    // delete timer
    const detailPanel = (row) => {
        console.log(row.original.updateLbhw);
        return <div align={'left'}>
            {row.original.L ? <>
<Chip
  sx={{ backgroundColor: '#E3D2F9', margin: '5px' }}
  avatar={<Avatar sx={{ backgroundColor: '#BF9FF0', color: '#ffffff', fontWeight: '1rem' }}>L</Avatar>}
  label={row.original?.L}
/>
            <Chip sx={{ backgroundColor: '#E3D2F9', margin: '5px' }}
                  avatar={<Avatar sx={{ backgroundColor: '#BF9FF0', color: '#ffffff', fontWeight: '1rem' }}>B</Avatar>}
                  label={row.original?.B} />
            <Chip sx={{ backgroundColor: '#E3D2F9', margin: '5px' }}
                  avatar={<Avatar sx={{ backgroundColor: '#BF9FF0', color: '#ffffff', fontWeight: '1rem' }}>H</Avatar>}
                  label={row.original?.H} />
            <Chip sx={{ backgroundColor: '#E3D2F9', margin: '5px' }}
                  avatar={<Avatar sx={{ backgroundColor: '#BF9FF0', color: '#ffffff', fontWeight: '1rem' }}>W</Avatar>}
                  label={row.original?.W} />
                <Typography >Slot : {row.original?.ddbDate}</Typography>
                <Typography >Filter : {row.original?.shipmentType}</Typography>
                <Typography >Update Lbhw : <IOSSwitch
                  checked={row.original.updateLbhw}
                /></Typography>

            </> : <Typography color={'red'} fontWeight={'bold'}>No Data Found</Typography>}</div>
    }
    const deleteScheduleHistory = async (index) => {
        console.log(timeHistoryScheduleData);
        const removeData = {
            username: user?.email ? user.email : localUser,
            action: 'remove',
            acType: platform,
            timerIndexKey: index,
            tpEmailId: timeHistoryScheduleData.Email,
            subAccountId: timeHistoryScheduleData.SupplierId,
        };
        await manageScheduler(removeData);
        await getScheduleHistory();
    };


    // remove or set timer
    const manageScheduler = async (body) => {
        body.actionType = 'manageSchedulers';
        let successMessage = null;
        if (body.action === 'remove') successMessage = 'Record deleted successfully,';
        if (body.action === 'set') successMessage = 'Record inserted successfully.';
        const response = await CreatePostAPICall(enqueueSnackbar, MyLinkedAccount, body, successMessage);
        if (response && body.action === 'get') {
            const newArray = [];
            let noForTimers = 0;
            if(typeof response.data.body === 'string'){
                enqueueSnackbar(response.data.message, { variant: 'error' });
                return;
            }
            response.data.body.forEach((item) => {
                const object = {
                    type: item.fetchType,
                    time: `${item.hour}:${item.minute}`,
                    timerIndexKey: item.timerIndexKey,
                    action: 'delete',
                    status: item.status,
                    index: (noForTimers += 1),
                    L: item.L,
                    B: item.B,
                    H: item.H,
                    W: item.W,
                    ddbDate: item.ddbDate,
                    shipmentType: item.shipmentType,
                    updateLbhw: item.updateLbhw,
                };
                newArray.push(object);
            });
            setExistingTimerSchedules(newArray);
        }
    };

    //  add time schedule api call and refresh data
    const insertTimeschedule = async (time, type) => {
        if (type === 'generateLabel' && platform === 'flipkart') {
            if (timeHistoryScheduleData.L === null) {
                enqueueSnackbar('Please Add LBHW for Account!', { variant: 'error' });
                return;
            }
        }
        setIsInserting(true);
        const newInsert = {
            username: user?.email ? user.email : localUser,
            action: 'set',
            cName: timeHistoryScheduleData.Name,
            tpEmailId: timeHistoryScheduleData.Email,
            subAccountId: timeHistoryScheduleData.SupplierId,
            isMaskingEnabled: timeHistoryScheduleData.isMaskingEnabled ?? false,
            fetchType: type,
            hour: time.split(':')[0],
            minute: time.split(':')[1],
            acType: platform,
        };
        if (newInsert.cName === null) {
            enqueueSnackbar('Please Enter Company Name', {variant: 'error'});
        }
        if (newInsert.fetchType === 'PAYMENT_PAID') {
            newInsert.day = selectedDayType;
        }
        if (type === 'generateLabel'  && platform === 'flipkart') {
            newInsert['shipmentType'] = filterValue;
            newInsert['ddbDate'] = ddbDate;
            newInsert['updateLbhw'] = updateLbhw;
            newInsert['L'] = timeHistoryScheduleData.L;
            newInsert['B'] = timeHistoryScheduleData.B;
            newInsert['H'] = timeHistoryScheduleData.H;
            newInsert['W'] = timeHistoryScheduleData.W;
        }
        await manageScheduler(newInsert);
        await getScheduleHistory();
        setIsInserting(false);
    };

    // validation for time and type are already exist or not it's return true and false
    const validationForAlreadyExist = (timerData, time, timeType) => {
        let isValid = true;
        const index = timerData.findIndex((timer) => timer.type === timeType && timer.time === time);

        if (index > -1) {
            enqueueSnackbar('Timer Is Already Added!', {variant: 'error'});
            isValid = false;
            return isValid;
        }
        return isValid;
    };

    // validation for time max limit set it's return true or false
    const validationForMaxLimit = (timerData, time, timeType) => {
        let isValid = true;
        const tempData = {
            rtsCount: 0,
            cancelCount: 0,
            returnCount: 0,
            generateLabel: 0,
            paymentPaidCount: 0,
        };
        for (let index = 0; index < timerData.length; index++) {
            const element = timerData[index];
            console.log(element);
            if (element.type === 'RTS') {
                tempData.rtsCount += 1;
            }
            if (element.type === 'GENERATELABEL') {
                tempData.generateLabel += 1;
            }
            if (element.type === 'RETURN') {
                tempData.returnCount += 1;
            }
            if (element.type === 'CANCEL') {
                tempData.cancelCount += 1;
            }
            if (element.type === 'PAYMENT_PAID') {
                tempData.paymentPaidCount += 1;
            }
        }
        console.log(tempData);
        if (tempData.rtsCount >= 2 && timeType === 'RTS') {
            enqueueSnackbar('Timer Limit Reached For RTS!', {variant: 'error'});
            isValid = false;
            return isValid;
        }
        if (tempData.generateLabel >= 2 && timeType === 'GENERATELABEL') {
            enqueueSnackbar('Timer Limit Reached For Generate Label!', {variant: 'error'});
            isValid = false;
            return isValid;
        }
        if (tempData.returnCount >= 2 && timeType === 'RETURN') {
            enqueueSnackbar('Timer Limit Reached For Return!', {variant: 'error'});
            isValid = false;
            return isValid;
        }
        if (tempData.cancelCount >= 1 && timeType === 'CANCEL') {
            enqueueSnackbar('Timer Limit Reached For Cancel!', {variant: 'error'});
            isValid = false;
            return isValid;
        }
        if (tempData.paymentPaidCount > 0 && timeType === 'PAYMENT_PAID') {
            enqueueSnackbar('Timer Limit Reached For Payment Paid!', {variant: 'error'});
            isValid = false;
            return isValid;
        }
        return isValid;
    };

    // copy existing timer data
    const insertScheduleHistory = async () => {

        const timeConvert = selectedTime.replace(/^(?:00:)?0?/, '');
        const isTimerExist = validationForAlreadyExist(existingTimerSchedules, timeConvert, selectedType.toUpperCase());

        const isTimerMaxlimit = validationForMaxLimit(existingTimerSchedules, timeConvert, selectedType.toUpperCase());

        if (isTimerExist) {
            if (isTimerMaxlimit) {
                insertTimeschedule(selectedTime, selectedType);
            }
        }
    };

    // handle select fetch type
    const handleChangeType = (event) => {
        if (event && event.length !== 0) {
            setSelectedType(event.target.value);
        } else {
            setSelectedType('');
        }
    };

    // handle select day type
    const handleChangeTypeDay = (event) => {
        if (event && event.length !== 0) {
            setSelectedDayType(event.target.value);
        } else {
            setSelectedDayType('');
        }
    };

    // get timer history
    const getScheduleHistory = async () => {
        await manageScheduler({
            username: user?.email ? user.email : localUser,
            action: 'get',
            acType: platform,
            tpEmailId: timeHistoryScheduleData.Email,
            subAccountId: timeHistoryScheduleData.SupplierId,
        });
    };

    // get platform wise select type option list
    useEffect(() => {
        if (platform && timeHistoryScheduleData) {
            if (platform === 'meesho') {
                if (isAdmin) {
                    setOptionTypeArray([
                        {value: 'rts', name: 'RTS'},
                        {value: 'return', name: 'Return'},
                        {value: 'cancel', name: 'Cancel'},
                        {value: 'PAYMENT_PAID', name: 'Payment Paid'},
                        {value: 'BAG_LINK', name: 'Bag Link'},
                    ]);
                } else {
                    setOptionTypeArray([
                        {value: 'rts', name: 'RTS'},
                        {value: 'return', name: 'Return'},
                        {value: 'cancel', name: 'Cancel'},
                        {value: 'BAG_LINK', name: 'Bag Link'},
                    ]);
                }

                setOptionTypeDayArray([
                    {value: 'Monday', name: 'Monday'},
                    {value: 'Wednesday', name: 'Wednesday'},
                    {value: 'Friday', name: 'Friday'},
                ]);
            } else if (platform === 'flipkart') {
                setOptionTypeArray([
                    {value: 'rts', name: 'RTS'},
                    {value: 'return', name: 'Return'},
                    {value: 'generateLabel', name: 'Generate Label'},
                    {value: 'cancel', name: 'Cancel'},
                ]);
            } else if (platform === 'glowroad') {
                setOptionTypeArray([
                    {value: 'rts', name: 'RTS'},
                    {value: 'return', name: 'Return'},
                    {value: 'cancel', name: 'Cancel'},

                ]);
            }else if (platform === 'limeroad') {
                setOptionTypeArray([
                    {value: 'rts', name: 'RTS'},
                    {value: 'return', name: 'Return'},
                    {value: 'generateLabel', name: 'Generate Label'},
                    // {value: 'cancel', name: 'Cancel'},
                ]);
            }else if (platform === 'snapdeal') {
                setOptionTypeArray([
                    {value: 'rts', name: 'RTS'},
                    {value: 'return', name: 'Return'},
                    {value: 'cancel', name: 'Cancel'},
                    {value: 'generateLabel', name: 'Generate Label'},
                ]);
            }
            manageScheduler({
                username: user?.email ? user.email : localUser,
                action: 'get',
                acType: platform,
                tpEmailId: timeHistoryScheduleData.Email,
                subAccountId: timeHistoryScheduleData.SupplierId,
            });
        }
    }, [timeHistoryScheduleData]);


    // get account data from local storage
    useEffect(async () => {
        if (timeHistoryScheduleData && platform) {
            const account = await getAccountsData();
            const filteredData = [];
            account.forEach((value) => {
                if (value.AcType === platform) {
                    filteredData.push({
                        value: value.Account.SupplierId,
                        name: `${CapitalizeFirstLetter(value.AcType)} - ${value.Account.Email} - ${value.Account.Name}`,
                        AcType: value.AcType,
                        Email: value.Account.Email,
                        cName: value.Account.Name,
                        isMaskingEnabled: value.isMaskingEnabled,
                        Mobile_No: value.Mobile_No,
                        Image_Link: value.Image_Link,
                        identifier: value.identifier,
                        LocationName: value.LocationName,
                    });
                }
            });
            setAllAccountsData(filteredData);
        }
    }, [timeHistoryScheduleData]);

    // on select account get local timer data
    const onSelectAccountForGetTimerData = async (value) => {
        const body = {
            username: user?.email ? user.email : localUser,
            action: 'get',
            acType: platform,
            tpEmailId: value.Email,
            subAccountId: value.value,
            actionType: 'manageSchedulers',
        };
        const response = await CreatePostAPICall(enqueueSnackbar, MyLinkedAccount, body);
        const newArray = [];
        let noForTimers = 0;
        response.data.body.forEach((item) => {
            const object = {
                type: item.fetchType,
                time: `${item.hour}:${item.minute}`,
                timerIndexKey: item.timerIndexKey,
                action: 'delete',
                status: item.status,
                index: (noForTimers += 1),
            };
            newArray.push(object);
        });
        console.log("existingTimerSchedules[0]['L']:", newArray);
        setOtherAccountTimer(newArray);
    };

    // save same timer data to another account
    const saveOtherAccountTimer = async () => {
        const tempArray = existingTimerSchedules.concat(otherAccountTimer);
        for (let index = 0; index < otherAccountTimer.length; index++) {

            const element = otherAccountTimer[index];
            const isTimerExist = validationForAlreadyExist(existingTimerSchedules, element.time, element.type);
            const isTimerMaxlimit = validationForMaxLimit(existingTimerSchedules, element.time, element.type);
            if (isTimerExist) {
                if (isTimerMaxlimit) {
                    await insertTimeschedule(element.time, element.type);
                } else {
                    break;
                }
            } else {
                break;
            }
        }
    };

    // clear value on modal close
    const onCloseModal = () => {
        setOtherAccountTimer([]);
        setSelectTimerAccount('');
        closeTimeAccountHistoryModal();
    };

    return (
        <>
            <BootstrapDialog
                sx={{'& .MuiDialog-paper': {width: '100%', maxHeight: 600}}}
                maxWidth={otherAccountTimer.length > 0 ? 'lg' : 'md'}
                onClose={onCloseModal}
                aria-labelledby="customized-dialog-title"
                open={openTimeHistoryScheduleModel}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={onCloseModal}>
                    {`${CapitalizeFirstLetter(platform)}: ${timeHistoryScheduleData ? timeHistoryScheduleData.Email : ''}`}
                </BootstrapDialogTitle>
                <Divider orientation="horizontal" flexItem/>
                <DialogContent>
                    <Box>
                        <Grid sx={{p: 3}} container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <Autocomplete
                                    fullWidth
                                    limitTags={2}
                                    options={allAccountsData}
                                    onChange={(e, value, reason) => {
                                        if (reason === 'clear') {
                                            setOtherAccountTimer([]);
                                            setSelectTimerAccount('');
                                            return;
                                        }
                                        onSelectAccountForGetTimerData(value);
                                        setSelectTimerAccount(value?.name);
                                    }}
                                    // value={selectTimerAccount}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => <TextField {...params}
                                                                        label="Select Account To Copy Timer Data"/>}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                {otherAccountTimer.length > 0 && (
                                    <>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} md={selectedType === 'PAYMENT_PAID' ? 4 : 6}>
                                                <FormControl required sx={{width: '100%'}}>
                                                    <InputLabel>Select Type</InputLabel>
                                                    <Select
                                                        value={selectedType}
                                                        fullWidth
                                                        placeholder={'Select Type'}
                                                        onChange={handleChangeType}
                                                        MenuProps={MenuProps}
                                                        input={<OutlinedInput label="Select Type"/>}
                                                    >
                                                        {optionTypeArray.map((option) => (
                                                            <MenuItem key={option.value} value={option.value}>
                                                                {option.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>

                                            {selectedType === 'PAYMENT_PAID' ? (
                                                <Grid item xs={12} md={selectedType === 'PAYMENT_PAID' ? 4 : 6}>
                                                    <FormControl required sx={{width: '100%'}}>
                                                        <InputLabel>Select Day</InputLabel>
                                                        <Select
                                                            value={selectedDayType}
                                                            fullWidth
                                                            placeholder={'Select Day'}
                                                            onChange={handleChangeTypeDay}
                                                            MenuProps={MenuProps}
                                                            input={<OutlinedInput label="Select Day"/>}
                                                        >
                                                            {optionTypeDayArray.map((option) => (
                                                                <MenuItem key={option.value} value={option.value}>
                                                                    {option.name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            ) : null}

                                            <Grid item xs={12} md={selectedType === 'PAYMENT_PAID' ? 4 : 6}>
                                                <TextField
                                                    label="Time"
                                                    type="time"
                                                    fullWidth
                                                    defaultValue="07:30"
                                                    onChange={(event) => setSelectedTime(event.target.value)}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <LoadingButton
                                                    fullWidth
                                                    variant="contained"
                                                    loading={isInserting}
                                                    autoFocus
                                                    onClick={async () => {
                                                        await insertScheduleHistory();
                                                    }}
                                                >
                                                    Insert
                                                </LoadingButton>
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <LoadingButton
                                                    fullWidth
                                                    variant="contained"
                                                    loading={isInserting}
                                                    autoFocus
                                                    onClick={async () => {
                                                        await saveOtherAccountTimer();
                                                    }}
                                                >
                                                    Save Other Account Timer
                                                </LoadingButton>
                                            </Grid>
                                        </Grid>
                                    </>
                                )}
                            </Grid>

                            {otherAccountTimer.length > 0 && (
                                <Grid item xs={12} md={6}>
                                    <DataGridTable
                                        columns={otherAcoountTimercolumns}
                                        data={otherAccountTimer}
                                        isLoading={false}
                                        enablePagination={false}
                                        isOtherColor
                                    />
                                </Grid>
                            )}
                            <Grid item xs={12} md={otherAccountTimer.length > 0 ? 6 : 8}>
                                <DataGridTable
                                    columns={columns}
                                    data={existingTimerSchedules}
                                    expand={(rowData) =>    rowData.index === expandedRow}
                                    renderDetailPanel={detailPanel}
                                    isLoading={false}
                                    enablePagination={false}
                                />
                            </Grid>

                            {otherAccountTimer.length === 0 && (
                                <Grid item xs={12} md={otherAccountTimer.length > 0 ? 6 : 4}>
                                    <FormControl required sx={{width: '100%', m: 2}}>
                                        <InputLabel>Select Type</InputLabel>
                                        <Select
                                            value={selectedType}
                                            fullWidth
                                            placeholder={'Select Type'}
                                            onChange={handleChangeType}
                                            MenuProps={MenuProps}
                                            input={<OutlinedInput label="Select Type"/>}
                                        >
                                            {optionTypeArray.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    {selectedType === "generateLabel" && platform === 'flipkart' && (<>
                                        <FormControl required sx={{width: '100%', ml: 2}}>
                                            <InputLabel>Select Slot</InputLabel>
                                            <Select
                                              value={ddbDate}
                                              fullWidth
                                              placeholder={'Select Slot'}
                                              onChange={(e)=>{setDdbDate(e.target.value)}}
                                              MenuProps={MenuProps}
                                              input={<OutlinedInput label="Select Slot"/>}
                                            >
                                                {slotList.map((option) => (
                                                  <MenuItem key={option} value={option}>
                                                      {option}
                                                  </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <Grid container alignItems={'center'} justifyContent={'space-between'}>

                                            <Grid item>
                                                <FormControl required sx={{width: '100%', ml: 2,mt:2}}>
                                                    <InputLabel>Filter</InputLabel>
                                                    <Select
                                                      value={filterValue}
                                                      fullWidth
                                                      placeholder={'Filter'}
                                                      onChange={(e)=>{setFilterValue(e.target.value)}}
                                                      MenuProps={MenuProps}
                                                      input={<OutlinedInput label="Filter"/>}
                                                    >
                                                        {filterList.map((option) => (
                                                          <MenuItem key={option} value={option}>
                                                              {option}
                                                          </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item alignItems="center" justifyContent="center" spacing={2}>
                                                <Grid item>
                                                    <Typography>Update LBHW</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <IOSSwitch
                                                      sx={{marginLeft:5}}
                                                      checked={updateLbhw}
                                                      onChange={(e) => {
                                                          setUpdateLbhw(e.target.checked);
                                                      }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                    </>)
                                    }

                                    {selectedType === 'PAYMENT_PAID' ? (
                                        <FormControl required sx={{width: '100%', m: 2}}>
                                            <InputLabel>Select Day</InputLabel>
                                            <Select
                                                value={selectedDayType}
                                                fullWidth
                                                placeholder={'Select Day'}
                                                onChange={handleChangeTypeDay}
                                                MenuProps={MenuProps}
                                                input={<OutlinedInput label="Select Day"/>}
                                            >
                                                {optionTypeDayArray.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    ) : null}

                                    <TextField
                                        label="Time"
                                        sx={{m: 2}}
                                        type="time"
                                        fullWidth
                                        defaultValue="07:30"
                                        onChange={(event) => setSelectedTime(event.target.value)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />

                                    <LoadingButton
                                        sx={{m: 2}}
                                        fullWidth
                                        variant="contained"
                                        loading={isInserting}
                                        autoFocus
                                        onClick={async () => {
                                            await insertScheduleHistory();
                                        }}
                                    >
                                        Insert
                                    </LoadingButton>
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                </DialogContent>
            </BootstrapDialog>
        </>
    );
}
