/* eslint-disable */
import {Grid, Typography} from '@mui/material';
import {alpha, styled} from '@mui/material/styles';
import React from 'react';
import Card from '@mui/material/Card';
import Iconify from '../Iconify';

const RootStyle = styled(Card)(({theme}) => ({
    boxShadow: 'none',
    textAlign: 'center',
    padding: theme.spacing(3.5, 0),
    cursor: 'pointer',
    height: '130px',
}));

const IconWrapperStyle = styled('div')(({theme}) => ({
    margin: 'auto',
    display: 'flex',
    borderRadius: '50%',
    alignItems: 'center',
    width: theme.spacing(4),
    height: theme.spacing(4),
    justifyContent: 'center',
    marginBottom: theme.spacing(1),
}));

const PlatformSelectionBtn = ({platformName, icon, onClickFunction, isActive = false}) => (
    <Grid item xs={6} sm={3} md={2}>

        <RootStyle
            onClick={() => {
                onClickFunction();
            }}
            sx={{
                color: (theme) => (isActive ? theme.palette.primary.lighter : theme.palette.primary.darker),
                bgcolor: (theme) => (isActive ? '#a560d9' : theme.palette.primary.lighter),
            }}
            id={platformName}
        >
            <IconWrapperStyle
                sx={{
                    color: (theme) => theme.palette.primary.dark,
                    backgroundImage: (theme) =>
                        `linear-gradient(135deg, ${alpha(theme.palette.primary.dark, 0)} 0%, ${alpha(
                            theme.palette.primary.dark,
                            0.24
                        )} 100%)`,
                }}
            >
                {platformName === 'Limeroad' ? <Typography>Lr</Typography> : (
                    <Iconify icon={icon} id={platformName} width={20} height={20}/>)}
            </IconWrapperStyle>
            <Typography variant="h6" id={platformName}>
                {platformName}
            </Typography>
        </RootStyle>


    </Grid>
);

export default PlatformSelectionBtn;
