function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/oms';

export default {
  ROOT_AUTH: '/auth',
  ROOT_OMS: '/oms',

  // auth pages url
  LOGIN_PAGE: path(ROOTS_AUTH, '/login'),
  REGISTER_PAGE: path(ROOTS_AUTH, '/register'),
  VERIFY_PAGE: path(ROOTS_AUTH, '/verify'),
  RESET_PASSWORD_PAGE: path(ROOTS_AUTH, '/reset-password'),

  // page not found url
  COMINGSOON_PAGE: '/coming-soon',
  MAINTENANCE_PAGE: '/maintenance',
  PAGE_404_PAGE: '/404',
  PAGE_500_PAGE: '/500',

  // unautorization url
  UNAUTHORIZED_PAGE: '/unauthorized',

  // OMS Pages
  HOME_PAGE: path(ROOTS_DASHBOARD, '/dashboard'),
  MY_LINKED_ACCOUNTS_PAGE: path(ROOTS_DASHBOARD, '/my-linked-accounts'),
  ECOMMERCE_PAGE: path(ROOTS_DASHBOARD, '/ecommerce'),
  ANALYTICS_PAGE: path(ROOTS_DASHBOARD, '/analytics'),
  BANKING_PAGE: path(ROOTS_DASHBOARD, '/banking'),
  MONTHLY_REPORTS_PAGE: path(ROOTS_DASHBOARD, '/monthly/report'),
  MEESHO_CLAIM_ISSUE_PAGE: path(ROOTS_DASHBOARD, '/meesho/claim-issues'),
  FLIPKART_CLAIM_ISSUE_PAGE: path(ROOTS_DASHBOARD, '/flipkart/claim-issues'),
  GLOWROAD_CLAIM_ISSUE_PAGE: path(ROOTS_DASHBOARD, '/glowroad/claim-issues'),
  SHOPDECK_CLAIM_ISSUE_PAGE: path(ROOTS_DASHBOARD, '/shopdeck/claim-issues'),
  MYNTRA_CLAIM_ISSUE_PAGE: path(ROOTS_DASHBOARD, '/myntra/claim-issues'),
  AMAZON_CLAIM_ISSUE_PAGE: path(ROOTS_DASHBOARD, '/amazon/claim-issues'),
  SNAPDEAL_CLAIM_ISSUE_PAGE: path(ROOTS_DASHBOARD, '/snapdeal/claim-issues'),
  LIMEROAD_CLAIM_ISSUE_PAGE: path(ROOTS_DASHBOARD, '/limeroad/claim-issues'),
  JIOMART_CLAIM_ISSUE_PAGE: path(ROOTS_DASHBOARD, '/jiomart/claim-issues'),
  REPORTED_CLAIM_ISSUE_PAGE: path(ROOTS_DASHBOARD, '/reported/claim-issues'),
  SCAN_ORDERS_PAGE: path(ROOTS_DASHBOARD, '/scan/order'),
  SYNC_ORDERS_PAGE: path(ROOTS_DASHBOARD, '/sync-order'),
  FlipkartRewardReco: path(ROOTS_DASHBOARD, '/offers'),
  ORDER_ANALYSIS_PAGE: path(ROOTS_DASHBOARD, '/order/analysis'),
  MASTER_SKUS_PAGE: path(ROOTS_DASHBOARD, '/masterskus/my-products'),
  AUTO_TICKET: path(ROOTS_DASHBOARD, '/auto-ticket'),
  EXPLORE_SKUS_PAGE: path(ROOTS_DASHBOARD, '/exploreskus/my-products'),
  LABEL_PROCESS_PAGE: path(ROOTS_DASHBOARD, '/utilities/label-process'),
  MeeshoUtilities: path(ROOTS_DASHBOARD, '/utilities/MeeshoUtilities'),
  FlipkartUtilities: path(ROOTS_DASHBOARD, '/utilities/FlipkartUtilities'),
  GlowroadUtilities: path(ROOTS_DASHBOARD, '/utilities/GlowroadUtilities'),
  CITY_MALL: path(ROOTS_DASHBOARD, '/utilities/CityMall'),
  LIME_ROAD: path(ROOTS_DASHBOARD, '/utilities/limeroad'),
  SNAP_DEAL: path(ROOTS_DASHBOARD, '/utilities/snapdeal'),
  ACCEPT_LIST_PAGE: path(ROOTS_DASHBOARD, '/utilities/accept-list'),
  CUSTOMER_REVIEW_PAGE: path(ROOTS_DASHBOARD, '/utilities/customer-review'),
  UPDATES_PAGE: path(ROOTS_DASHBOARD, '/updates'),

  // admin pages
  ADMIN_PAGE: path(ROOTS_DASHBOARD, '/admin'),
  TASK_MANAGEMENT_PAGE: path(ROOTS_DASHBOARD, '/task/management'),
  NOTIFICATION_MANAGEMENT_PAGE: path(ROOTS_DASHBOARD, '/notification/management'),
  BILL_MANAGEMENT_PAGE: path(ROOTS_DASHBOARD, '/bill/management'),
  ADMIN_TOOLS_PAGE: path(ROOTS_DASHBOARD, '/management/BM/admin-tools'),
  ALL_CONGNITO_USERS_PAGE: path(ROOTS_DASHBOARD, '/management/BM/all-cognito-users'),
  ADMIN_ERROR_HANDLING_PAGE: path(ROOTS_DASHBOARD, '/management/BM/admin-error-handling'),
  ALLTIMERS_PAGE: path(ROOTS_DASHBOARD, '/management/BM/all-timers'),
  GET_USER_TRANSACTION_PAGE: path(ROOTS_DASHBOARD, '/management/BM/get-user-transaction'),
  LOW_BALANCE_PAGE: path(ROOTS_DASHBOARD, '/management/BM/low-balance'),
  TODAYS_TRANSACTIONS_PAGE: path(ROOTS_DASHBOARD, '/management/BM/todays-transactions'),
  USER_NOTIFICATION_PAGE: path(ROOTS_DASHBOARD, '/management/BM/user-notification'),
  USER_REQUEST_PAGE: path(ROOTS_DASHBOARD, '/management/BM/user-request'),
  USER_STATUS_PAGE: path(ROOTS_DASHBOARD, '/management/BM/user-status'),

  // user setting page
  ACCOUNT_PAGE: path(ROOTS_DASHBOARD, '/user/setting'),
};
