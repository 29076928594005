import React, {useRef, useState} from 'react';
import {Container, Grid,} from '@mui/material';
import {LoadingButton} from "@mui/lab";
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import Page from '../../../components/Page';
import urlConstants from '../../../routes/urlConstants';
import useSettings from '../../../hooks/useSettings';
import AcceptList from "../acceptList/AcceptList";

export default function SnapDealUtilities() {
    const {themeStretch} = useSettings();
    const [GenerateLabelOn, setGenerateLabelOn] = useState(true);
    const [reGenerateLabelOn, setReGenerateLabelOn] = useState(false);
    const [generateManifestOn, setGenerateManifestOn] = useState(false);
    const [markAsRTDOn, setMarkAsRTDOn] = useState(false);

    const GenerateLabelBTN = useRef(null);

    return (
        <Page title="Utilities">
            <Container maxWidth={themeStretch ? false : 'xl'}>
                <HeaderBreadcrumbs
                    heading="Utilities"
                    links={[{name: 'Dashboard', href: urlConstants.HOME_PAGE}, {name: 'Utilities'}, {name: 'Snap Deal'}]}
                />

                <Grid container spacing={3} style={{marginBottom: "3rem"}}>
                    <Grid item xs={12}>
                        <LoadingButton ref={GenerateLabelBTN} style={{marginRight: "1rem"}} onClick={() => {
                            setReGenerateLabelOn(false)
                            setMarkAsRTDOn(false)
                            setGenerateManifestOn(false)
                            setGenerateLabelOn(true)
                        }} variant={GenerateLabelOn ? 'contained' : 'outlined'}>Generate Label</LoadingButton>
                        <span style={{borderRight: "1px solid #000", height: "100%"}}/>
                        <LoadingButton ref={GenerateLabelBTN} style={{marginLeft: "1rem",marginRight: "1rem"}}  onClick={() => {
                            setReGenerateLabelOn(true)
                            setGenerateManifestOn(false)
                            setMarkAsRTDOn(false)
                            setGenerateLabelOn(false)
                        }} variant={reGenerateLabelOn ? 'contained' : 'outlined'}>Re-Generate Label</LoadingButton>
                        <span style={{borderRight: "1px solid #000", height: "100%"}}/>
                        <LoadingButton ref={GenerateLabelBTN} style={{marginLeft: "1rem"}}  onClick={() => {
                            setReGenerateLabelOn(false)
                            setGenerateManifestOn(true)
                            setMarkAsRTDOn(false)
                            setGenerateLabelOn(false)
                        }} variant={generateManifestOn ? 'contained' : 'outlined'}>Generate Manifest</LoadingButton>
                    </Grid>
                </Grid>
                {GenerateLabelOn && (
                    <AcceptList acType={'snapdeal'} utilityType={"generateLabel"}/>
                )}
                {reGenerateLabelOn && (
                    <AcceptList acType={'snapdeal'} utilityType={"reGenerateLabel"}/>
                )}
                {generateManifestOn && (
                    <AcceptList acType={'snapdeal'} utilityType={"generateManifest"}/>
                )}
            </Container>
        </Page>
    );
}
