/* eslint-disable */
import React from 'react';
import {CircularProgress, Card, Button} from '@mui/material';
import DataGrid from 'material-react-table';
import {SystemColor} from '../../utils/utilities';

const DataGridTable = ({
                           columns,
                           data,
                           isLoading,
                           renderDetailPanel = null,
                           expand = true,
                           densitycol = 'compact',
                           checkbox = false,
                           sorting = false,
                           button1 = false,
                           button2 = false,
                           button3 = false,
                           funReported,
                           funRemove,
                           funSubmitAll,
                           enablePagination = true,
                           isOtherColor = false,
                           enableRowNumbers = false,
                       }) => {


  return (
        <>
            {isLoading ? (
                <Card>
                    <div style={{width: '100%', textAlign: 'center', padding: '100px'}}>
                        <CircularProgress sx={{color: SystemColor.originColor}} size={24} color="inherit"/>
                    </div>
                </Card>
            ) : (
                <>
                    {renderDetailPanel ? (
                        <DataGrid
                            enableRowNumbers={enableRowNumbers}
                            enableColumnActions={false}
                            enableSorting={sorting}
                            columns={columns}
                            enablePagination={enablePagination}
                            enableRowSelection={checkbox}
                            data={data}
                            initialState={{
                                expanded: expand,
                                columnVisibility: {uiUniqueId: false, id: false},
                                density: densitycol,
                            }}
                            muiTableHeadCellProps={{
                                sx: {
                                    backgroundColor: SystemColor.originColor,
                                    color: '#FFF',
                                    boxShadow: `inset 8px 0 0 ${SystemColor.originColor} !important`,
                                },
                            }}
                            renderDetailPanel={({row}) => renderDetailPanel(row)}
                            autoResetPageIndex={false}
                            renderBottomToolbarCustomActions={
                                button1 !== false
                                    ? ({table}) => {
                                        const handleDeactivate = () => {
                                            table.getSelectedRowModel().flatRows.map((row) => {
                                                table.resetRowSelection(true);
                                                return funReported(row.original, table);
                                            });
                                        };

                                        const handleActivate = () => {
                                            table.getSelectedRowModel().flatRows.map((row) => {
                                                table.resetRowSelection(true);
                                                return funRemove(row.original, table);
                                            });
                                        };

                                        const handleButton3 = () => {
                                            table.getSelectedRowModel().flatRows.map((row) => {
                                                table.resetRowSelection(true);
                                                return funSubmitAll(row.original, table);
                                            });
                                        };

                                        return (
                                            <div style={{display: 'flex', gap: '0.5rem'}}>
                                                <Button
                                                    disabled={table.getSelectedRowModel().flatRows.length === 0}
                                                    onClick={handleDeactivate}
                                                    variant="contained"
                                                >
                                                    {button1}
                                                </Button>
                                                {button2 !== false ? (
                                                    <Button
                                                        disabled={table.getSelectedRowModel().flatRows.length === 0}
                                                        onClick={handleActivate}
                                                        variant="contained"
                                                    >
                                                        {button2}
                                                    </Button>
                                                ) : null}
                                                {button3 !== false ? (
                                                    <Button
                                                        disabled={table.getSelectedRowModel().flatRows.length === 0}
                                                        onClick={handleButton3}
                                                        variant="contained"
                                                    >
                                                        {button3}
                                                    </Button>
                                                ) : null}
                                            </div>
                                        );
                                    }
                                    : null
                            }
                        />
                    ) : (
                        <DataGrid
                            enableColumnActions={false}
                            enableRowNumbers={enableRowNumbers}
                            enableSorting={sorting}
                            columns={columns}
                            autoResetPageIndex={false}
                            enablePagination={enablePagination}
                            enableRowSelection={checkbox}
                            data={data}
                            initialState={{columnVisibility: {uiUniqueId: false, id: false}, density: densitycol}}
                            muiTableHeadCellProps={{
                                sx: {
                                    backgroundColor: isOtherColor ? '#b14040' : SystemColor.originColor,
                                    color: '#FFF',
                                    boxShadow: isOtherColor
                                        ? `inset 8px 0 0 #b14040 !important`
                                        : `inset 8px 0 0 ${SystemColor.originColor} !important`,
                                },
                            }}
                            renderBottomToolbarCustomActions={
                                button1 !== false
                                    ? ({table}) => {
                                        const handleDeactivate = () => {
                                            table.getSelectedRowModel().flatRows.map((row) => {
                                                table.resetRowSelection(true);
                                                return funReported(row.original, table);
                                            });
                                        };

                                        const handleButton3 = () => {
                                            table.getSelectedRowModel().flatRows.map((row) => {
                                                table.resetRowSelection(true);
                                                return funSubmitAll(row.original, table);
                                            });
                                        };

                                        const handleActivate = () => {
                                            table.getSelectedRowModel().flatRows.map((row) => {
                                                table.resetRowSelection(true);
                                                return funRemove(row.original, table);
                                            });
                                        };

                                        return (
                                            <div style={{display: 'flex', gap: '0.5rem'}}>
                                                <Button
                                                    disabled={table.getSelectedRowModel().flatRows.length === 0}
                                                    onClick={handleDeactivate}
                                                    variant="contained"
                                                >
                                                    {button1}
                                                </Button>
                                                {button2 !== false ? (
                                                    <Button
                                                        disabled={table.getSelectedRowModel().flatRows.length === 0}
                                                        onClick={handleActivate}
                                                        variant="contained"
                                                    >
                                                        {button2}
                                                    </Button>
                                                ) : null}
                                                {button3 !== false ? (
                                                    <Button
                                                        disabled={table.getSelectedRowModel().flatRows.length === 0}
                                                        onClick={handleButton3}
                                                        variant="contained"
                                                    >
                                                        {button3}
                                                    </Button>
                                                ) : null}
                                            </div>
                                        );
                                    }
                                    : null
                            }
                        />

                    )}
                </>
            )}
        </>
    );
};
export default DataGridTable;
