/* eslint-disable */
import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { sortableContainer, sortableElement, arrayMove } from 'react-sortable-hoc';
import { shortOrderArray } from '../../../utils/utilities';
import AppWidgetSummary from './AppWidgetSummary';

const SortableItem = sortableElement(({key, value, acTypes }) => {
  let type = '';
  if (value.title === 'Orders Today') {
    type = 'eva:trending-up-fill';
  } else if (value.title === 'Today Pickup Cancel') {
    type = 'mdi:box-variant-closed-remove';
  } else if (value.title === 'Today Pickup Exchange') {
    type = 'mdi:box-sync-outline';
  } else if (value.title === 'Combo By Quantity (Orders/Quantity)') {
    type = 'fluent-mdl2:quantity';
  } else if (value.title === 'Combo By Sent AWB (Sent AWB/Orders)') {
    type = 'fluent-mdl2:quantity';
  } else if (value.title === 'New Returns Today') {
    type = 'eva:trending-down-fill';
  } else if (value.title === 'Delivered Today') {
    type = 'mdi:truck-delivery-outline';
  } else if (value.title === 'Today Return Scan Orders') {
    type = 'mdi:account-check-outline';
  } else if (value.title === 'Cancel Received Today') {
    type = 'ri:user-received-line';
  } else if (value.title === 'Pickup Scanned Today') {
    type = 'mdi:cube-scan';
  } else if (value.title === 'Pickup Scanned Remain') {
    type = 'mdi:box-search-outline';
  } else if (value.title === `Today's Invoice`) {
    type = 'tabler:file-invoice';
  } else if (value.title === `Today's Investment`) {
    type = 'material-symbols:currency-rupee-rounded';
  } else {
    type = 'mdi:box-eye-outline';
  }
  return (
    <Box>
      <AppWidgetSummary
        title={value.title}
        key={key}
        Count = {value}
        type={type}
        acTypes={acTypes}
      />
    </Box>
  );
});

const SortableContainer = sortableContainer(({ children }) => {
  return (
    <Box>
      <Box
        sx={{
          display: 'grid',
          gap: { xs: 2, lg: 2 },
          gridTemplateColumns: {
            xs: 'repeat(1, 1fr)',
            sm: 'repeat(2, 1fr)',
            md: 'repeat(3, 1fr)',
          },
          position: 'relative',
          pt: 7,
        }}
      >
        {children}
      </Box>
    </Box>
  );
});

const SortableListComponent = (props) => {
  const { data, orderNumberWiseDataSet, acTypes } = props;

  const [cardData, setCardData] = useState([]);

  // get data from local storage and sort card by order wise
  useEffect(() => {
    const localData = localStorage.getItem('orderWiseCard');

    if (localData) {
      const parseLocalData = JSON.parse(localData);
      const tempData = [];

      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        const ItemIndex = parseLocalData.findIndex((item) => item.title === element.title);

        if (ItemIndex > -1) {
          element.order = parseLocalData[ItemIndex].order;
          element.isShow = parseLocalData[ItemIndex].isShow;
        }
        tempData.push(element);
      }
      // localStorage.setItem('orderWiseCard', JSON.stringify(tempData));
      const filteredData = tempData.filter((value) => value.isShow === true);

      const sortTempData = filteredData.sort((a, b) => a.order - b.order);
      setCardData(sortTempData);
    } else {
      setCardData(data);
    }
  }, [data]);

  // handle drag and drop card by user and store data into local storage
  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newData = arrayMove(cardData, oldIndex, newIndex);

    const newShotData = shortOrderArray(newData);
    const tempData = [];
    for (let index = 0; index < data.length; index++) {
      const element = data[index];

      const ItemIndex = newShotData.findIndex((item) => item.title === element.title);

      if (ItemIndex > -1) {
        const newObj = newShotData[ItemIndex];
        const tempObj = {
          order: newObj.order,
          title: newObj.title,
          id: newObj.id,
          isShow: newObj.isShow,
        };
        tempData.push(tempObj);
      } else {
        const tempObj = {
          order: element.order,
          title: element.title,
          id: element.id,
          isShow: element.isShow,
        };
        tempData.push(tempObj);
      }
    }

    localStorage.setItem('orderWiseCard', JSON.stringify(tempData));
    setCardData(newShotData);
    // orderNumberWiseDataSet(newShotData);
  };

  return (
    <SortableContainer pressDelay={props.datas?80:10000} onSortEnd={onSortEnd} axis="xy">
      {cardData.map((value, index) => (
        <SortableItem key={`item-${value.id}`} index={index} value={value} acTypes={acTypes} />
      ))}
    </SortableContainer>
  );
};

export default SortableListComponent;
