/* eslint-disable */
import {
    Autocomplete,
    Box,
    Button,
    Divider,
    Grid,
    IconButton,
    TextField,
    Typography
} from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import {useTheme} from '@mui/material/styles';
import {PDFDownloadLink} from '@react-pdf/renderer';
import {DatePicker} from 'antd';
import {format} from 'date-fns';
import {useSnackbar} from 'notistack';
import React, {useCallback, useEffect, useState} from 'react';
import NoDataFound from '../../../../components/Common/NoDataFound';
import Iconify from '../../../../components/Iconify';
import Invoice from '../../../../components/invoice/Invoice';
import useAuth from '../../../../hooks/useAuth';
import {BILL_BUCKET} from '../../../../utils/Constants';
import {CreatePostAPICall, UserProfile, V2Management, envType} from '../../../../utils/apis';
import axios from '../../../../utils/axios';
import {
    BootstrapDialog,
    BootstrapDialogTitle,
    downloadS3File,
    getBillDetailsDateRange,
    uploadFileS3
} from '../../../../utils/utilities';
import {orderBy, sumBy} from "lodash";
import {getComparator} from "../../../../hooks/useTable";
import InvoiceTable from "../InvoiceTable";

function numberToWords(num) {
    const units = ['', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
    const teens = ['', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];
    const tens = ['', 'ten', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];

    if (num === 0) return 'zero';

    function convertBelowThousand(num) {
        let result = '';
        if (num >= 100) {
            result += `${units[Math.floor(num / 100)]  } hundred `;
            num %= 100;
        }
        if (num >= 20) {
            result += `${tens[Math.floor(num / 10)]  } `;
            num %= 10;
        }
        if (num >= 10 && num <= 19) {
            result += `${teens[num - 10]  } `;
            num = 0; // Teens already include the unit, so no need to process the unit separately
        }
        if (num > 0) {
            result += `${units[num]  } `;
        }
        return result.trim();
    }

    const billion = Math.floor(num / 1e9);
    const million = Math.floor((num % 1e9) / 1e6);
    const thousand = Math.floor((num % 1e6) / 1e3);
    const remainder = num % 1000;

    let result = '';
    if (billion > 0) {
        result += `${convertBelowThousand(billion)  } billion `;
    }
    if (million > 0) {
        result += `${convertBelowThousand(million)  } million `;
    }
    if (thousand > 0) {
        result += `${convertBelowThousand(thousand)  } thousand `;
    }
    if (remainder > 0) {
        result += convertBelowThousand(remainder);
    }
    return result.trim();
}
export default function AdminBillManagement() {
    const {user} = useAuth();
    const localUser = window.localStorage.getItem('userEmailId');
    const theme = useTheme();
    const isLight = theme.palette.mode === 'light';
    const {enqueueSnackbar} = useSnackbar();
    const {RangePicker} = DatePicker;
    const [formData, setFormData] = useState([]);
    const [gstData, setGSTData] = useState([]);
    const [usernameData, setUsernameData] = useState([]);
    const [orderIdData, setOrderIdData] = useState([]);
    const [gstBillData, setGstBillData] = useState([]);
    const [openGenerateNewReport, setOpenGenerateNewReport] = useState(false);
    const [generateBillDetails, setGenerateBillDetails] = useState({
        no_of_orders: '',
        orderId: '',
        invoiceDate: format(new Date(), 'yyyy-MM-dd hh:mm:ss.s'),
        formName: '',
        address: '',
        gstNo: '',
        officeAddress: '',
        city: '',
        pincode: '',
        state: '',
        country: '',
        amount: '',
        InWords: '',
    });
    const [isErrorVisible, setIsErrorVisible] = useState(false);
    const [isGenerateBill, setIsGenerateBill] = useState(false);
    const [updateBill, setUpdateBill] = useState(false);
    let startDate = '';
    let endDate = '';
    const onFormData = async (formData) => {
        if (formData.target.value.length === 0) {
            setFormData([]);
            return;
        }
        const body = {
            ValueType: 'FormName',
            actionType: 'getBillDetails',
            panel: 'billManagement',
            FormName: formData.target.value,
        };
        const response = await axios.post(V2Management, body);
        if (response.data) {
            const newArr = [];
            response.data.map((value) => {
                newArr.push({
                    value,
                    label: value,
                });
            });
            setFormData(newArr);
        }
    };
    const onGSTData = async (gstData) => {
        if (gstData.target.value.length === 0) {
            setGSTData([]);
            return;
        }
        const body = {
            ValueType: 'GstNo',
            actionType: 'getBillDetails',
            GstNo: gstData.target.value,
            panel: 'billManagement',
        };
        const response = await axios.post(V2Management, body);
        if (response) {
            const newArr = [];
            response.data.map((value) => {
                newArr.push({
                    value,
                    label: value,
                });
            });
            setGSTData(newArr);
        }
    };
    const onUsernameData = async (usernameData) => {
        if (usernameData.target.value.length === 0) {
            setUsernameData([]);
            return;
        }
        const body = {
            ValueType: 'UserName',
            actionType: 'getBillDetails',
            panel: 'billManagement',
            UserName: usernameData.target.value,
        };
        const response = await axios.post(V2Management, body);
        if (response) {
            const newArr = [];
            response.data.map((value) => {
                newArr.push({
                    value,
                    label: value,
                });
            });
            setUsernameData(newArr);
        }
    };
    const onOrderIdData = async (orderIdData) => {
        if (orderIdData.target.value.length === 0) {
            setOrderIdData([]);
            return;
        }
        const body = {
            ValueType: 'OrderId',
            OrderId: orderIdData.target.value,
            actionType: 'getBillDetails',
            panel: 'billManagement',
        };
        const response = await axios.post(V2Management, body);
        if (response.data[0]) {
            const newArr = [];
            response.data.map((value) => {
                newArr.push({
                    value,
                    label: value,
                });
            });
            setOrderIdData(newArr);
        }
    };
    const getdate = async (value, dateString) => {
        startDate = dateString[0];
        endDate = dateString[1];
        await onGetBillDetails('InvoiceDate', 'InvoiceDate');
    };
    const onGetBillDetails = async (getDetailsData, valueType) => {
        let body;
        if (valueType === 'InvoiceDate') {
            body = {
                GetDetails: getDetailsData,
                ValueType: valueType,
                start_date: startDate,
                end_date: endDate,
                actionType: 'getBillDetails',
                panel: 'billManagement',
            };
        } else {
            body = {
                GetDetails: getDetailsData,
                ValueType: valueType,
                actionType: 'getBillDetails',
                panel: 'billManagement',
            };
        }
        const response = await axios.post(V2Management, body);
        if (response) {
            setGstBillData(prevState => response.data);
        }
    };
    const downloadPdf = async (file, isPDf) => {
        await downloadS3File(file, isPDf, BILL_BUCKET, enqueueSnackbar);
    };
    const DownloadKeyFormatter = (value) => (
        <>
            <IconButton onClick={() => downloadPdf(value, true)}>
                <Iconify icon={'vscode-icons:file-type-pdf2'} width={30} height={30}/>
            </IconButton>
        </>
    );
    const expandRowComponent = (row) => (
        <div>
            <Typography sx={{pl: 2}} variant="h6">
                Bill Info
            </Typography>
            <Box
                sx={{
                    display: 'grid',
                    gridAutoFlow: 'row',
                    gridTemplateColumns: 'repeat(3, 1fr)',
                    gridTemplateRows: 'auto',
                    paddingLeft: 2,
                    paddingRight: 2,
                    paddingTop: 2,
                }}
            >
                <div style={{marginTop: '2px', marginBottom: '2px', display: 'flex'}} key={1}>
                    <b style={{marginRight: '2px'}}>Username :</b>
                    <div
                        style={{marginLeft: '2px'}}>{row.original?.UserName === '' ? ' - ' : row.original?.UserName}</div>
                </div>
                <div style={{marginTop: '2px', marginBottom: '2px', display: 'flex'}} key={2}>
                    <b style={{marginRight: '2px'}}>Gst No :</b>
                    <div style={{marginLeft: '2px'}}>{row.original?.GstNo === '' ? ' - ' : row.original?.GstNo}</div>
                </div>
                <div style={{marginTop: '2px', marginBottom: '2px', display: 'flex'}} key={3}>
                    <b style={{marginRight: '2px'}}>Form Name :</b>
                    <div
                        style={{marginLeft: '2px'}}>{row.original?.FormName === '' ? ' - ' : row.original?.FormName}</div>
                </div>
                <div style={{marginTop: '2px', marginBottom: '2px', display: 'flex'}} key={4}>
                    <b style={{marginRight: '2px'}}>Order Id :</b>
                    <div
                        style={{marginLeft: '2px'}}>{row.original?.OrderId === '' ? ' - ' : row.original?.OrderId}</div>
                </div>
                <div style={{marginTop: '2px', marginBottom: '2px', display: 'flex'}} key={5}>
                    <b style={{marginRight: '2px'}}>No Of Orders :</b>
                    <div style={{marginLeft: '2px'}}>
                        {row.original?.PaymentInfo.no_of_orders === '' ? ' - ' : row.original?.PaymentInfo.no_of_orders}
                    </div>
                </div>
                <div style={{marginTop: '2px', marginBottom: '2px', display: 'flex'}} key={6}>
                    <b style={{marginRight: '2px'}}>Amount :</b>
                    <div style={{marginLeft: '2px'}}>{row.original?.Amount === '' ? ' - ' : row.original?.Amount}</div>
                </div>
                <div style={{marginTop: '2px', marginBottom: '2px', display: 'flex'}} key={7}>
                    <b style={{marginRight: '2px'}}>Env Type :</b>
                    <div
                        style={{marginLeft: '2px'}}>{row.original?.EnvType === '' ? ' - ' : row.original?.EnvType}</div>
                </div>
                <div style={{marginTop: '2px', marginBottom: '2px', display: 'flex'}} key={8}>
                    <b style={{marginRight: '2px'}}>Invoice - Date & Time :</b>
                    <div style={{marginLeft: '2px'}}>
                        {row.original?.InvoiceDateTime === '' ? ' - ' : row.original?.InvoiceDateTime}
                    </div>
                </div>
                <div style={{marginTop: '2px', marginBottom: '2px', display: 'flex'}} key={9}>
                    <b style={{marginRight: '2px'}}>Invoice Date :</b>
                    <div style={{marginLeft: '2px'}}>
                        {row.original?.InvoiceDate === '' ? ' - ' : row.original?.InvoiceDate}
                    </div>
                </div>
                <div style={{marginTop: '2px', marginBottom: '2px', display: 'flex'}} key={10}>
                    <b style={{marginRight: '2px'}}>Part Key :</b>
                    <div
                        style={{marginLeft: '2px'}}>{row.original?.part_key === '' ? ' - ' : row.original?.part_key}</div>
                </div>
            </Box>
            <Box
                sx={{
                    display: 'grid',
                    gridAutoFlow: 'row',
                    gridTemplateColumns: 'repeat(1, 1fr)',
                    gridTemplateRows: 'auto',
                    paddingLeft: 2,
                }}
            >
                <div style={{marginTop: '2px', marginBottom: '2px', display: 'flex'}} key={11}>
                    <b style={{marginRight: '2px'}}>Sort Key :</b>
                    <div
                        style={{marginLeft: '2px'}}>{row.original?.sort_key === '' ? ' - ' : row.original?.sort_key}</div>
                </div>
            </Box>
            <Divider sx={{paddingTop: '15px', borderStyle: 'dashed'}}/>
            <Typography sx={{pl: 2, pt: 2}} variant="h6">
                Payment Info
            </Typography>
            <Box
                sx={{
                    display: 'grid',
                    gridAutoFlow: 'row',
                    gridTemplateColumns: 'repeat(3, 1fr)',
                    gridTemplateRows: 'auto',
                    paddingLeft: 2,
                    paddingRight: 2,
                    paddingTop: 2,
                }}
            >
                <div style={{marginTop: '2px', marginBottom: '2px', display: 'flex'}} key={12}>
                    <b style={{marginRight: '2px'}}>Bank Txn Id :</b>
                    <div style={{marginLeft: '2px'}}>
                        {row.original?.PaymentInfo.payment_info?.BANKTXNID === ''
                            ? ' - '
                            : row.original?.PaymentInfo.payment_info?.BANKTXNID}
                    </div>
                </div>
                <div style={{marginTop: '2px', marginBottom: '2px', display: 'flex'}} key={13}>
                    <b style={{marginRight: '2px'}}>Currency :</b>
                    <div style={{marginLeft: '2px'}}>
                        {row.original?.PaymentInfo.payment_info?.CURRENCY === ''
                            ? ' - '
                            : row.original?.PaymentInfo.payment_info?.CURRENCY}
                    </div>
                </div>
                <div style={{marginTop: '2px', marginBottom: '2px', display: 'flex'}} key={14}>
                    <b style={{marginRight: '2px'}}>Payment Gateway Name :</b>
                    <div style={{marginLeft: '2px'}}>
                        {row.original?.PaymentInfo.payment_info?.GATEWAYNAME === ''
                            ? ' - '
                            : row.original?.PaymentInfo.payment_info?.GATEWAYNAME}
                    </div>
                </div>
                <div style={{marginTop: '2px', marginBottom: '2px', display: 'flex'}} key={15}>
                    <b style={{marginRight: '2px'}}>Mid :</b>
                    <div style={{marginLeft: '2px'}}>
                        {row.original?.PaymentInfo.payment_info?.MID === '' ? ' - ' : row.original?.PaymentInfo.payment_info?.MID}
                    </div>
                </div>
                <div style={{marginTop: '2px', marginBottom: '2px', display: 'flex'}} key={16}>
                    <b style={{marginRight: '2px'}}>Payment Mode :</b>
                    <div style={{marginLeft: '2px'}}>
                        {row.original?.PaymentInfo.payment_info?.PAYMENTMODE === ''
                            ? ' - '
                            : row.original?.PaymentInfo.payment_info?.PAYMENTMODE}
                    </div>
                </div>
                <div style={{marginTop: '2px', marginBottom: '2px', display: 'flex'}} key={17}>
                    <b style={{marginRight: '2px'}}>Response Code :</b>
                    <div style={{marginLeft: '2px'}}>
                        {row.original?.PaymentInfo.payment_info?.PAYMENTMODE === ''
                            ? ' - '
                            : row.original?.PaymentInfo.payment_info?.PAYMENTMODE}
                    </div>
                </div>
                <div style={{marginTop: '2px', marginBottom: '2px', display: 'flex'}} key={18}>
                    <b style={{marginRight: '2px'}}>Response Message :</b>
                    <div style={{marginLeft: '2px'}}>
                        {row.original?.PaymentInfo.payment_info?.RESPMSG === ''
                            ? ' - '
                            : row.original?.PaymentInfo.payment_info?.RESPMSG}
                    </div>
                </div>
                <div style={{marginTop: '2px', marginBottom: '2px', display: 'flex'}} key={19}>
                    <b style={{marginRight: '2px'}}>Status :</b>
                    <div style={{marginLeft: '2px'}}>
                        {row.original?.PaymentInfo.payment_info?.STATUS === ''
                            ? ' - '
                            : row.original?.PaymentInfo.payment_info?.STATUS}
                    </div>
                </div>
                <div style={{marginTop: '2px', marginBottom: '2px', display: 'flex'}} key={20}>
                    <b style={{marginRight: '2px'}}>Transaction Amount :</b>
                    <div style={{marginLeft: '2px'}}>
                        {row.original?.PaymentInfo.payment_info?.TXNAMOUNT === ''
                            ? ' - '
                            : row.original?.PaymentInfo.payment_info?.TXNAMOUNT}
                    </div>
                </div>
                <div style={{marginTop: '2px', marginBottom: '2px', display: 'flex'}} key={21}>
                    <b style={{marginRight: '2px'}}>Transaction Date :</b>
                    <div style={{marginLeft: '2px'}}>
                        {row.original?.PaymentInfo.payment_info?.TXNDATE === ''
                            ? ' - '
                            : row.original?.PaymentInfo.payment_info?.TXNDATE}
                    </div>
                </div>
                <div style={{marginTop: '2px', marginBottom: '2px', display: 'flex'}} key={22}>
                    <b style={{marginRight: '2px'}}>Transaction Id :</b>
                    <div style={{marginLeft: '2px'}}>
                        {row.original?.PaymentInfo.payment_info?.TXNID === ''
                            ? ' - '
                            : row.original?.PaymentInfo.payment_info?.TXNID}
                    </div>
                </div>
            </Box>
            <Box
                sx={{
                    display: 'grid',
                    gridAutoFlow: 'row',
                    gridTemplateColumns: 'repeat(1, 1fr)',
                    gridTemplateRows: 'auto',
                    paddingLeft: 2,
                }}
            >
                <div style={{marginTop: '2px', marginBottom: '2px', display: 'flex'}} key={23}>
                    <b style={{marginRight: '2px'}}>Check Sum :</b>
                    <div style={{marginLeft: '2px'}}>
                        {row.original?.PaymentInfo.payment_info?.CHECKSUMHASH === ''
                            ? ' - '
                            : row.original?.PaymentInfo.payment_info?.CHECKSUMHASH}
                    </div>
                </div>
            </Box>
            <Box
                sx={{
                    display: 'grid',
                    gridAutoFlow: 'row',
                    gridTemplateColumns: 'repeat(1, 1fr)',
                    gridTemplateRows: 'auto',
                    paddingLeft: 2,
                }}
            >
                <div style={{marginTop: '2px', marginBottom: '2px', display: 'flex'}} key={24}>
                    <b style={{marginRight: '2px', paddingTop: '0.95%'}}>Download Bill :</b>
                    <div style={{marginLeft: '2px'}}>{DownloadKeyFormatter(row.original.DownloadKey)}</div>
                </div>
            </Box>
        </div>
    );
    const handleClose = () => {
        setOpenGenerateNewReport(false);
        setIsGenerateBill(false);
    };
    const handleGenerateReportModel = () => {
        setIsErrorVisible(false);
        setOpenGenerateNewReport(!openGenerateNewReport);
    };
    const onHandleChangeText = (event) => {
        setGenerateBillDetails({
            ...generateBillDetails,
            [event.target.name]: event.target.value,
        });
    };

    const handleGenerateBill = () => {
        setIsErrorVisible(true);
        if (
            generateBillDetails.InWords !== '' &&
            generateBillDetails.address !== '' &&
            generateBillDetails.amount !== '' &&
            generateBillDetails.formName !== '' &&
            generateBillDetails.gstNo !== '' &&
            generateBillDetails.invoiceDate !== '' &&
            generateBillDetails.no_of_orders !== '' &&
            generateBillDetails.officeAddress !== '' &&
            generateBillDetails.orderId !== ''
        ) {
            setIsGenerateBill(true);
        }
    };
    useEffect(() => {
        const fetchData = async () => {
            const dateRange = getBillDetailsDateRange();
            startDate = dateRange.start_date;
            endDate = dateRange.end_date;

            // Fetch the data
            await onGetBillDetails('InvoiceDate', 'InvoiceDate');

        };

        fetchData(); // Call the fetchData function

    }, []); //
    const saveBillInfo = useCallback(async (downloadKey) => {
        const body2 = {
            payment_info: generateBillDetails.invoiceDate,
            no_of_orders: generateBillDetails.no_of_orders,
            username: user?.email ? user.email : localUser,
        };
        const body = {
            username: user?.email ? user.email : localUser,
            GstNo: generateBillDetails.gstNo,
            InvoiceDate: generateBillDetails.invoiceDate,
            Amount: generateBillDetails.amount,
            PaymentInfo: body2,
            FormName: generateBillDetails.formName,
            OrderId: generateBillDetails.orderId,
            DownloadKey: downloadKey,
            EnvType: envType,
            action: updateBill?'updateBillInfo':'saveBillInfo',
        };
        const response = await CreatePostAPICall(enqueueSnackbar, UserProfile, body, true);
        if(response){
            setUpdateBill(false)
        }
    });

    const uploadBill = useCallback((blob, url, fileName) => {
        if (url) {
            const a = document.createElement('a');
            a.download = fileName;
            a.href = url;
            a.addEventListener('click', () => {
                setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
            });
            a.click();
            const file = new File([blob], `${fileName}.pdf`);
            uploadFileS3(
                file,
                `${fileName}.pdf`,
                `${envType}/${user?.email}/${fileName}/${fileName}.pdf`,
                BILL_BUCKET,
                enqueueSnackbar('Bill Uploaded Successfully')
            );
            saveBillInfo(`${envType}/${user?.email}/${fileName}/${fileName}.pdf`);
        }
    });

    const editBill = useCallback((data) => {
        setUpdateBill(true)
        setGenerateBillDetails({
            no_of_orders: data.PaymentInfo.no_of_orders,
            orderId: data.OrderId,
            invoiceDate: data.InvoiceDateTime,
            formName: data.FormName,
            address: '',
            gstNo: data.GstNo,
            officeAddress: '',
            city: '',
            pincode: '',
            state: '',
            country: '',
            amount: data.Amount,
            InWords: numberToWords(data.Amount),
        })
        setOpenGenerateNewReport(true)
    })

    return (
        <>
            <Divider sx={{p: 1, mb: 2}}/>
            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                    <Grid sx={{paddingBottom: 3}} container spacing={3}>
                        <Grid item xs={12} sm={12} md={6}>
                            <Autocomplete
                                id="Select-Account"
                                disableClearable
                                onInputChange={onFormData}
                                onChange={(event, value) => {
                                    onGetBillDetails(value.value, 'FormName');
                                }}
                                options={formData.map((option) => option)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Search Form Name"
                                        InputProps={{
                                            ...params.InputProps,
                                            type: 'search',
                                        }}
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={6}>
                            <Autocomplete
                                id="Select-Account"
                                disableClearable
                                onInputChange={onGSTData}
                                onChange={(event, value) => {
                                    onGetBillDetails(value.value, 'GstNo');
                                }}
                                options={gstData.map((option) => option)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Search GST Number"
                                        InputProps={{
                                            ...params.InputProps,
                                            type: 'search',
                                        }}
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={6}>
                            <Autocomplete
                                id="Select-Account"
                                disableClearable
                                onInputChange={onUsernameData}
                                onChange={(event, value) => {
                                    onGetBillDetails(value.value, 'UserName');
                                }}
                                options={usernameData.map((option) => option)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Search Username"
                                        InputProps={{
                                            ...params.InputProps,
                                            type: 'search',
                                        }}
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={6}>
                            <Autocomplete
                                id="Select-Account"
                                disableClearable
                                onInputChange={onOrderIdData}
                                onChange={(event, value) => {
                                    onGetBillDetails(value.value, 'OrderId');
                                }}
                                options={orderIdData.map((option) => option)}
                                isOptionEqualToValue={(option, value) => option.label === value.label}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Search Order ID"
                                        InputProps={{
                                            ...params.InputProps,
                                            type: 'search',
                                        }}
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} style={{textAlign: 'end'}}>
                            <Button onClick={handleGenerateReportModel} sx={{m: 1}} variant="contained">
                                Generate New Bill
                            </Button>
                            <BootstrapDialog
                                sx={{'& .MuiDialog-paper': {width: '100%'}}}
                                maxWidth="lg"
                                onClose={handleClose}
                                aria-labelledby="customized-dialog-title"
                                open={openGenerateNewReport}
                            >
                                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                                    Generate New Bill
                                </BootstrapDialogTitle>
                                <Divider orientation="horizontal" flexItem/>
                                <DialogContent>
                                    <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3}}>
                                        {!isGenerateBill ? (
                                            <>
                                                <Grid item xs={12} md={6}>
                                                    <TextField
                                                        style={{width: '100%'}}
                                                        id="outlined-basic-2"
                                                        label="Enter No Of Orders"
                                                        variant="outlined"
                                                        disabled={updateBill}
                                                        name="no_of_orders"
                                                        value={generateBillDetails.no_of_orders}
                                                        onChange={(event) => {
                                                            onHandleChangeText(event);
                                                        }}
                                                    />
                                                    {isErrorVisible && generateBillDetails.no_of_orders === '' ? (
                                                        <p style={{color: 'red', paddingLeft: '5px'}}>This Field is
                                                            Required</p>
                                                    ) : null}
                                                </Grid>

                                                <Grid item xs={12} md={6}>
                                                    <TextField
                                                        style={{width: '100%'}}
                                                        id="outlined-basic-2"
                                                        label="Enter Order Id"

                                                        disabled={updateBill}
                                                        variant="outlined"
                                                        name="orderId"
                                                        value={generateBillDetails.orderId}
                                                        onChange={(event) => {
                                                            onHandleChangeText(event);
                                                        }}
                                                    />
                                                    {isErrorVisible && generateBillDetails.orderId === '' ? (
                                                        <p style={{color: 'red', paddingLeft: '5px'}}>This Field is
                                                            Required</p>
                                                    ) : null}
                                                </Grid>

                                                <Grid item xs={12} md={6}>
                                                    <TextField
                                                        style={{width: '100%'}}
                                                        id="outlined-basic-2"
                                                        label="Enter Invoice Date"
                                                        variant="outlined"
                                                        disabled={updateBill}
                                                        name="invoiceDate"
                                                        value={generateBillDetails.invoiceDate}
                                                        onChange={(event) => {
                                                            onHandleChangeText(event);
                                                        }}
                                                    />

                                                    {isErrorVisible && generateBillDetails.invoiceDate === '' ? (
                                                        <p style={{color: 'red', paddingLeft: '5px'}}>This Field is
                                                            Required</p>
                                                    ) : null}
                                                </Grid>

                                                <Grid item xs={12} md={6}>
                                                    <TextField
                                                        style={{width: '100%'}}
                                                        id="outlined-basic-2"
                                                        label="Enter Form Name"
                                                        variant="outlined"
                                                        name="formName"
                                                        value={generateBillDetails.formName}
                                                        onChange={(event) => {
                                                            onHandleChangeText(event);
                                                        }}
                                                    />
                                                    {isErrorVisible && generateBillDetails.formName === '' ? (
                                                        <p style={{color: 'red', paddingLeft: '5px'}}>This Field is
                                                            Required</p>
                                                    ) : null}
                                                </Grid>

                                                <Grid item xs={12} md={6}>
                                                    <TextField
                                                        style={{width: '100%'}}
                                                        id="outlined-basic-2"
                                                        label="Enter Address"
                                                        variant="outlined"
                                                        name="address"
                                                        value={generateBillDetails.address}
                                                        onChange={(event) => {
                                                            onHandleChangeText(event);
                                                        }}
                                                    />
                                                    {isErrorVisible && generateBillDetails.address === '' ? (
                                                        <p style={{color: 'red', paddingLeft: '5px'}}>This Field is
                                                            Required</p>
                                                    ) : null}
                                                </Grid>

                                                <Grid item xs={12} md={6}>
                                                    <TextField
                                                        style={{width: '100%'}}
                                                        id="outlined-basic-2"
                                                        label="Enter GST Number"
                                                        variant="outlined"
                                                        name="gstNo"
                                                        value={generateBillDetails.gstNo}
                                                        onChange={(event) => {
                                                            onHandleChangeText(event);
                                                        }}
                                                    />
                                                    {isErrorVisible && generateBillDetails.gstNo === '' ? (
                                                        <p style={{color: 'red', paddingLeft: '5px'}}>This Field is
                                                            Required</p>
                                                    ) : null}
                                                </Grid>

                                                <Grid item xs={12} md={6}>
                                                    <TextField
                                                        style={{width: '100%'}}
                                                        id="outlined-basic-2"
                                                        label="Enter Office Address"
                                                        variant="outlined"
                                                        name="officeAddress"
                                                        value={generateBillDetails.officeAddress}
                                                        onChange={(event) => {
                                                            onHandleChangeText(event);
                                                        }}
                                                    />
                                                    {isErrorVisible && generateBillDetails.officeAddress === '' ? (
                                                        <p style={{color: 'red', paddingLeft: '5px'}}>This Field is
                                                            Required</p>
                                                    ) : null}
                                                </Grid>

                                                <Grid item xs={12} md={6}>
                                                    <TextField
                                                        style={{width: '100%'}}
                                                        id="outlined-basic-2"
                                                        label="Enter City"
                                                        variant="outlined"
                                                        name="city"
                                                        value={generateBillDetails.city}
                                                        onChange={(event) => {
                                                            onHandleChangeText(event);
                                                        }}
                                                    />
                                                </Grid>

                                                <Grid item xs={12} md={6}>
                                                    <TextField
                                                        style={{width: '100%'}}
                                                        id="outlined-basic-2"
                                                        label="Enter Pincode"
                                                        variant="outlined"
                                                        name="pincode"
                                                        value={generateBillDetails.pincode}
                                                        onChange={(event) => {
                                                            onHandleChangeText(event);
                                                        }}
                                                    />
                                                </Grid>

                                                <Grid item xs={12} md={6}>
                                                    <TextField
                                                        style={{width: '100%'}}
                                                        id="outlined-basic-2"
                                                        label="Enter State"
                                                        variant="outlined"
                                                        name="state"
                                                        value={generateBillDetails.state}
                                                        onChange={(event) => {
                                                            onHandleChangeText(event);
                                                        }}
                                                    />
                                                </Grid>

                                                <Grid item xs={12} md={6}>
                                                    <TextField
                                                        style={{width: '100%'}}
                                                        id="outlined-basic-2"
                                                        label="Enter Country"
                                                        variant="outlined"
                                                        name="country"
                                                        value={generateBillDetails.country}
                                                        onChange={(event) => {
                                                            onHandleChangeText(event);
                                                        }}
                                                    />
                                                </Grid>

                                                <Grid item xs={12} md={6}>
                                                    <TextField
                                                        style={{width: '100%'}}
                                                        id="outlined-basic-2"
                                                        label="Enter Amount"
                                                        variant="outlined"
                                                        name="amount"
                                                        disabled={updateBill}
                                                        value={generateBillDetails.amount}
                                                        onChange={(event) => {
                                                            onHandleChangeText(event);
                                                        }}
                                                    />
                                                    {isErrorVisible && generateBillDetails.amount === '' ? (
                                                        <p style={{color: 'red', paddingLeft: '5px'}}>This Field is
                                                            Required</p>
                                                    ) : null}
                                                </Grid>

                                                <Grid item xs={12} md={6}>
                                                    <TextField
                                                        style={{width: '100%'}}
                                                        id="outlined-basic-2"
                                                        label="Enter Amount In Words"
                                                        variant="outlined"
                                                        disabled={updateBill}
                                                        name="InWords"
                                                        value={generateBillDetails.InWords}
                                                        onChange={(event) => {
                                                            onHandleChangeText(event);
                                                        }}
                                                    />
                                                    {isErrorVisible && generateBillDetails.InWords === '' ? (
                                                        <p style={{color: 'red', paddingLeft: '5px'}}>This Field is
                                                            Required</p>
                                                    ) : null}
                                                </Grid>

                                                <DialogActions style={{flex: 'auto'}}>
                                                    <Button variant="contained" autoFocus onClick={handleGenerateBill}>
                                                        {updateBill ? "Update Bill" : "Generate Bill"}
                                                    </Button>
                                                </DialogActions>
                                            </>
                                        ) : (
                                            <>
                                                <PDFDownloadLink
                                                    sx={{textDecoration: 'none'}}
                                                    document={
                                                        <Invoice
                                                            no_of_orders={generateBillDetails.no_of_orders}
                                                            orderId={generateBillDetails.orderId}
                                                            invoiceDate={generateBillDetails.invoiceDate}
                                                            formName={generateBillDetails.formName}
                                                            address={generateBillDetails.address}
                                                            gstNo={generateBillDetails.gstNo}
                                                            officeAddress={generateBillDetails.officeAddress}
                                                            city={generateBillDetails.city}
                                                            pincode={generateBillDetails.pincode}
                                                            state={generateBillDetails.state}
                                                            country={generateBillDetails.country}
                                                            amount={generateBillDetails.amount}
                                                            InWords={generateBillDetails.InWords}
                                                        />
                                                    }
                                                    fileName={generateBillDetails.orderId}
                                                >
                                                    {({blob, url, loading}) =>
                                                        loading ? (
                                                            <div style={{paddingLeft: '20px'}}>
                                                                <Button variant="contained">Generating Bill...</Button>
                                                            </div>
                                                        ) : (
                                                            <div style={{paddingLeft: '20px'}}>
                                                                <Button variant="contained">Download Your Gst
                                                                    Bill</Button>
                                                                {uploadBill(blob, url, generateBillDetails.orderId)}
                                                            </div>
                                                        )
                                                    }
                                                </PDFDownloadLink>
                                            </>
                                        )}
                                    </Grid>
                                </DialogContent>
                            </BootstrapDialog>
                        </Grid>
                    </Grid>


                    {gstBillData.length !== 0 ? (
                        <InvoiceTable billData={gstBillData} setBillData={setGstBillData} editBill={editBill}/>
                        // <DataGridTable columns={columns} data={gstBillData} renderDetailPanel={expandRowComponent} />
                    ) : (
                        <NoDataFound message={'Please Select Bill Details To See Data'}/>
                    )}
                </Grid>
            </Grid>
        </>
    );

}
