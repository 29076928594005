/* eslint-disable */
// Validation for RTS Order File
export const JiomartValidateUploadedFile = (header, type) => {
  if (type === 'Order') {
    try {
      if (header[0] === 'Number' && header[4] === 'Sku' && header[5] === 'Quantity' && header[9] === 'Shipping_Agent') {
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  }

  if (type === 'shippedOrder') {
    try {
      if (
        header[0] === 'Shipment Number' &&
        header[5] === 'Accepted At' &&
        header[8] === 'Sku' &&
        header[9] === 'Qty' &&
        header[13] === 'Item Total' &&
        header[15] === 'Tracking Code' &&
        header[16] === 'Shipping Agent Code'
      ) {
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  }
  if (type === 'RTN') {
    try {
      if (header[0] === 'Number' && header[4] === 'Sku' && header[5] === 'Quantity' && header[9] === 'Shipping_Agent') {
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  }

  if (type === 'Payment') {
    try {
      if (
        header[0] === 'Created_At' &&
        header[3] === 'Accountable_Type' &&
        header[5] === 'Shipment_Number' &&
        header[8] === 'Amount'
      ) {
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  }

  if (type === 'Inventory') {
    try {
      if (header[1] === 'SKU Code' && header[3] === 'Product Name' && header[6] === 'Old Selling Price') {
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  }
};
