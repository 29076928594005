import { CognitoUserPool } from 'amazon-cognito-identity-js';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';

// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
import { Card, Grid, IconButton, InputAdornment, Stack } from '@mui/material';
// components
import { useState } from 'react';
import {useNavigate} from "react-router-dom";
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFSwitch, RHFTextField } from '../../../components/hook-form';
import { COGNITO_API } from '../../../config';
import urlConstants from "../../../routes/urlConstants";

// ----------------------------------------------------------------------

export default function AccountChangePassword() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [showPasswordOld, setShowPasswordOld] = useState(false);
  const [showPasswordNew, setShowPasswordNew] = useState(false);
  const [showPasswordConform, setShowPasswordConform] = useState(false);

  const ChangePassWordSchema = Yup.object().shape({
    oldPassword: Yup.string().required('Old Password is required'),
    newPassword: Yup.string()
      .min(8, 'Password must be at least 8 characters')
      .required('New Password is required')
      .matches(
        '^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[0-9]{1,})(?=.*[a-z]).{8,}$',
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
      ),
    confirmNewPassword: Yup.string().oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
  });

  const defaultValues = {
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  };

  const methods = useForm({
    resolver: yupResolver(ChangePassWordSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const UserPool = new CognitoUserPool({
    UserPoolId: COGNITO_API.userPoolId,
    ClientId: COGNITO_API.clientId,
  });

  const getSession = async () => {
    const newSessionResult = await new Promise((resolve, reject) => {
      const changePasswordUser = UserPool.getCurrentUser();

      if (changePasswordUser) {
        changePasswordUser.getSession(async (err, session) => {
          if (err) {
            reject();
          } else {
            const attributes = await new Promise((resolve, reject) => {
              changePasswordUser.getUserAttributes((err, attributes) => {
                if (err) {
                  reject();
                } else {
                  const result = {};
                  attributes.forEach((attribute) => {
                    const { Name, Value } = attribute;
                    result[Name] = Value;
                  });

                  resolve(result);
                }
              });
            });
            resolve({ changePasswordUser, ...session, ...attributes });
          }
        });
      }
    });
    return newSessionResult;
  };

  const onSubmit = async (data) => {
    try {
      getSession().then(({ changePasswordUser }) => {
        changePasswordUser.changePassword(data.oldPassword, data.newPassword, (err, result) => {
          if (err) {
            enqueueSnackbar(err, { variant: 'error' });
          } else {
            enqueueSnackbar('Password changed successfully.');
            changePasswordUser.globalSignOut({
              onSuccess: () => {
                enqueueSnackbar('Password Reset Successfully');
                localStorage.removeItem('username');
                navigate(urlConstants.LOGIN_PAGE, { replace: true });
              },
              onFailure: (err) => {
                enqueueSnackbar(err.message, { variant: 'error' });
              },
            })
            reset();
          }
        });
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Grid item xs={12} md={6} lg={6} sm={6}>
      <Card sx={{ p: 3 }}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3} alignItems="flex-end">
            <RHFTextField
              name="oldPassword"
              label="Old Password"
              type={showPasswordOld ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" onClick={() => setShowPasswordOld(!showPasswordOld)}>
                      <Iconify icon={showPasswordOld ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <RHFTextField
              name="newPassword"
              label="New Password"
              type={showPasswordNew ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" onClick={() => setShowPasswordNew(!showPasswordNew)}>
                      <Iconify icon={showPasswordNew ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <RHFTextField
              name="confirmNewPassword"
              label="Confirm New Password"
              type={showPasswordConform ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" onClick={() => setShowPasswordConform(!showPasswordConform)}>
                      <Iconify icon={showPasswordConform ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
              Save Changes
            </LoadingButton>
          </Stack>
        </FormProvider>
      </Card>
    </Grid>
  );
}
