/* eslint-disable */
import { useTheme } from '@emotion/react';
import { Button, Card, CardHeader, Divider, Grid } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { DatePicker } from 'antd';
import xlsx from 'json-as-xlsx';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { CreatePostAPICall, V2Management, Home, MyLinkedAccount } from '../../../utils/apis';
import { SystemColor } from '../../../utils/utilities';

export default function AdminBuissMasterStatics() {
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';
  const { enqueueSnackbar } = useSnackbar();
  const { RangePicker } = DatePicker;

  const [userDetailsData, setUserDetailsData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [xAxisData, setXAxisData] = useState([]);

  const chartData = {
    Used: [1, 2, 3, 4, 5, 6, 7],
    NotUsed: [8, 9, 10, 11, 12, 13, 14],
  };

  // all user table columns
  const allUsersDataColumns = [
    {
      field: 'Id',
      headerName: 'Id',
      flex: 1,
      minWidth: 50,
    },
    {
      field: 'Email',
      headerName: 'Email',
      flex: 1,
      minWidth: 250,
    },
    {
      field: 'Account_Balance',
      headerName: 'Account Balance',
      flex: 1,
      minWidth: 130,
    },
    {
      field: 'Add_Ons',
      headerName: 'Add Ons',
      flex: 1,
      minWidth: 250,
    },
    {
      field: 'Mobile_No',
      headerName: 'Mobile No',
      flex: 1,
      minWidth: 135,
    },
    {
      field: 'UserStatus',
      headerName: 'UserStatus',
      flex: 1,
      minWidth: 130,
    },
    {
      field: 'userStatus',
      headerName: 'DB UserStatus',
      flex: 1,
      minWidth: 115,
    },
    {
      field: 'Enabled',
      headerName: 'Enabled',
      flex: 1,
      minWidth: 70,
    },
    {
      field: 'WAppMessageEnabled',
      headerName: 'WAppMessageEnabled',
      flex: 1,
      minWidth: 170,
    },
    {
      field: 'dbType',
      headerName: 'dbType',
      flex: 1,
      minWidth: 70,
    },
    {
      field: 'SoftwareVersion',
      headerName: 'SoftwareVersion',
      flex: 1,
      minWidth: 125,
    },
    {
      field: 'UserCreateDate',
      headerName: 'User Created Date',
      flex: 1,
      minWidth: 170,
    },
    {
      field: 'UserLastModifiedDate',
      headerName: 'User Last-Modified Date',
      flex: 1,
      minWidth: 180,
    },
    {
      field: 'Cognito User Actions',
      headerName: 'Cognito User Actions',
      minWidth: 225,
      flex: 1,
      renderCell: (params) => (
        <>
          <Button
            variant="outlined"
            onClick={() => {
              makeAccountEnableDisableAllUserStatus('confirmAc', params.row.Email);
            }}
          >
            Confirm
          </Button>
          <Button
            sx={{ marginLeft: '10px', marginRight: '10px' }}
            variant="outlined"
            onClick={() => {
              makeAccountEnableDisableAllUserStatus('enableAc', params.row.Email);
            }}
          >
            Enable
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              makeAccountEnableDisableAllUserStatus('disableAc', params.row.Email);
            }}
          >
            Disable
          </Button>
        </>
      ),
    },
    {
      field: 'totalCount',
      headerName: 'Total Orders',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'amazonCount',
      headerName: 'Amazon Orders',
      flex: 1,
      minWidth: 120,
    },
    {
      field: 'meeshoCount',
      headerName: 'Meesho Orders',
      flex: 1,
      minWidth: 120,
    },
    {
      field: 'flipkartCount',
      headerName: 'Flipkart Orders',
      flex: 1,
      minWidth: 120,
    },
    {
      field: 'glowroadCount',
      headerName: 'Glowroad Orders',
      flex: 1,
      minWidth: 135,
    },
    {
      field: 'totalAcCount',
      headerName: 'Total Accounts',
      flex: 1,
      minWidth: 135,
    },
    {
      field: 'amazonAcCount',
      headerName: 'Amazon Accounts',
      flex: 1,
      minWidth: 135,
    },

    {
      field: 'meeshoAcCount',
      headerName: 'Meesho Accounts',
      flex: 1,
      minWidth: 135,
    },
    {
      field: 'flipkartAcCount',
      headerName: 'Flipkart Accounts',
      flex: 1,
      minWidth: 135,
    },
    {
      field: 'glowroadAcCount',
      headerName: 'Glowroad Accounts',
      flex: 1,
      minWidth: 135,
    },
  ];

  // 
  const makeAccountEnableDisableAllUserStatus = async (actionType, Username) => {
    setDataLoading(true);
    const body = {
      username: Username,
      actionType,
      panel: 'admin',
      subPanel: 'adminManagement',
    };
    const response = await CreatePostAPICall(enqueueSnackbar, V2Management, body, true);
    if (response) {
      setDataLoading(false);
    }
  };

  // 
  const exportToCsvAllUserDetailsData = () => {
    const data = [
      {
        columns: [
          { label: 'Id', value: 'Id' },
          { label: 'Email', value: 'Email' },
          { label: 'Account Balance', value: 'Account_Balance' },
          { label: 'Add Ons', value: 'Add_Ons' },
          { label: 'Mobile No', value: 'Mobile_No' },
          { label: 'Order Date', value: 'order_date' },
          { label: 'userStatus', value: 'userStatus' },
          { label: 'Enabled', value: 'Enabled' },
          { label: 'WAppMessageEnabled', value: 'WAppMessageEnabled' },
          { label: 'dbType', value: 'dbType' },
          { label: 'SoftwareVersion', value: 'SoftwareVersion' },
          { label: 'User Create Date', value: 'UserCreateDate' },
          { label: 'Sent Quantity', value: 'UserLastModifiedDate' },
          { label: 'Total Orders', value: 'Total Orders' },
          { label: 'Amazon Orders', value: 'Amazon Orders' },
          { label: 'Meesho Orders', value: 'Meesho Orders' },
          { label: 'Flipkart Orders', value: 'Flipkart Orders' },
          { label: 'Glowroad Orders', value: 'Glowroad Orders' },
          { label: 'Total Accounts', value: 'Total Accounts' },
          { label: 'Amazon Accounts', value: 'Amazon Accounts' },
        ],
        content: userDetailsData,
      },
    ];

    const settings = {
      fileName: `All Users Details`,
      extraLength: 6,
      writeOptions: {},
    };

    xlsx(data, settings);
  };

  // 
  const getOrderCount = async (email) => {
    const body = {
      actionType: 'getOrdersCounts',
      action: 'allAccounts',
      username: email,
    };
    const tempObject = {
      amazonCount: 0,
      meeshoCount: 0,
      flipkartCount: 0,
      glowroadCount: 0,
      totalCount: 0,
    };

    const response = await CreatePostAPICall(enqueueSnackbar, Home, body);
    if (response) {
      if (response?.data?.body?.amazon?.orders_today === 0 || response?.data?.body?.amazon?.orders_today) {
        tempObject.amazonCount = response.data.body.amazon.orders_today;
      }
      if (response?.data?.body?.meesho?.orders_today === 0 || response?.data?.body?.meesho?.orders_today) {
        tempObject.meeshoCount = response.data.body.meesho.orders_today;
      }
      if (response?.data?.body?.flipkart?.orders_today === 0 || response?.data?.body?.flipkart?.orders_today) {
        tempObject.flipkartCount = response.data.body.flipkart.orders_today;
      }
      if (response?.data?.body?.glowroad?.orders_today === 0 || response?.data?.body?.glowroad?.orders_today) {
        tempObject.glowroadCount = response.data.body.glowroad.orders_today;
      }

      tempObject.totalCount =
        tempObject.amazonCount + tempObject.meeshoCount + tempObject.flipkartCount + tempObject.glowroadCount;
    }
    return tempObject;
  };

  // 
  const getAccountCount = async (email) => {
    const body = {
      actionType: 'getAll',
      username: email,
    };
    const response = await CreatePostAPICall(enqueueSnackbar, MyLinkedAccount, body);
    if (response) {
      const tempObject = {
        amazonAcCount: 0,
        meeshoAcCount: 0,
        flipkartAcCount: 0,
        glowroadAcCount: 0,
        totalAcCount: 0,
        // totalCount: 0 response.data.body.amazon.orders_today+ response.data.body.meesho.orders_today+ response.data.body.flipkart.orders_today+response.data.body.glowroad.orders_today,
      };
      response.data.body.forEach((data) => {
        if (data.AcType === 'meesho') {
          tempObject.meeshoAcCount += 1;
        }
        if (data.AcType === 'flipkart') {
          tempObject.flipkartAcCount += 1;
        }
        if (data.AcType === 'glowroad') {
          tempObject.glowroadAcCount += 1;
        }
        if (data.AcType === 'amazon') {
          tempObject.amazonAcCount += 1;
        }
        tempObject.totalAcCount =
          tempObject.meeshoAcCount + tempObject.flipkartAcCount + tempObject.glowroadAcCount + tempObject.amazonAcCount;
      });
      return tempObject;
    }
  };

  const allUserDetailsApiData = async () => {
    setDataLoading(true);
    const body = {
      actionType: 'getAllUserStatus',
      panel: 'admin',
      subPanel: 'adminManagement',
    };

    const response = await CreatePostAPICall(enqueueSnackbar, V2Management, body);
    if (response) {
      response.data.body.forEach((data) => {
        getOrderCount(data.Email).then((orderCount) => {
          setDataLoading(true);
          getAccountCount(data.Email).then((acCount) => {
            setDataLoading(true);
            data.amazonCount = orderCount.amazonCount;
            data.meeshoCount = orderCount.meeshoCount;
            data.flipkartCount = orderCount.flipkartCount;
            data.glowroadCount = orderCount.glowroadCount;
            data.totalCount = orderCount.totalCount;

            data.amazonAcCount = acCount.amazonAcCount;
            data.meeshoAcCount = acCount.meeshoAcCount;
            data.flipkartAcCount = acCount.flipkartAcCount;
            data.glowroadAcCount = acCount.glowroadAcCount;
            data.totalAcCount = acCount.totalAcCount;
            setDataLoading(false);
          });
          setDataLoading(false);
        });
      });
      setUserDetailsData(response.data.body);
      setDataLoading(false);
    }
  };

  // useEffect(() => {
  //   allUserDetailsApiData();
  // }, []);

  // chart options and data for all user summery
  const userSummaryChartOptions = {
    series: [700, 300, 200],
    labels: ['Active Users', 'Deactive Users', 'Paused Users'],

    options: {
      stroke: { colors: [theme.palette.background.paper] },
      // legend: { floating: true, horizontalAlign: 'center' },
      dataLabels: { enabled: true, dropShadow: { enabled: false } },
      plotOptions: {
        pie: { donut: { labels: { show: false } } },
      },
      chart: {
        width: 300,
        type: 'pie',
      },
      labels: ['Active Users', 'Deactive Users', 'Paused Users'],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    },
  };

  // chart options and data for new customer summary
  const newCustomerChartOptions = {
    // Colors
    colors: [theme.palette.primary.main, theme.palette.chart.yellow[0]],

    // Chart
    chart: {
      toolbar: { show: false },
      zoom: { enabled: false },
      // animations: { enabled: false },
      foreColor: theme.palette.text.disabled,
      fontFamily: theme.typography.fontFamily,
    },

    // States
    states: {
      hover: {
        filter: {
          type: 'lighten',
          value: 0.04,
        },
      },
      active: {
        filter: {
          type: 'darken',
          value: 0.88,
        },
      },
    },

    // Fill
    fill: {
      type: ['solid', 'solid'],
      opacity: 1,
      gradient: {
        type: 'vertical',
        shadeIntensity: 0,
        opacityFrom: 0.4,
        opacityTo: 0,
        stops: [0, 100],
      },
    },

    // Datalabels
    dataLabels: { enabled: false },

    // Stroke
    stroke: {
      width: [4, 4],
      curve: 'smooth',
      lineCap: 'round',
    },

    // Grid
    grid: {
      strokeDashArray: 3,
      borderColor: theme.palette.divider,
    },

    // Xaxis
    xaxis: {
      axisBorder: { show: false },
      axisTicks: { show: false },
      categories: xAxisData,
      title: {
        text: 'Date',
        style: {
          color: '#FF1654',
        },
      },
    },

    // Markers
    markers: {
      size: 0,
      strokeColors: theme.palette.background.paper,
    },

    // Tooltip
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== 'undefined') {
            return `${y.toFixed(0)} `;
          }
          return y;
        },
      },
    },

    // Legend
    legend: {
      show: true,
      fontSize: String(13),
      position: 'top',
      horizontalAlign: 'right',
      markers: {
        radius: 12,
      },
      fontWeight: 500,
      itemMargin: { horizontal: 12 },
      labels: {
        colors: theme.palette.text.primary,
      },
    },

    // plotOptions
    plotOptions: {
      // Bar
      bar: {
        columnWidth: '28%',
        borderRadius: 4,
      },
    },

    // Responsive
    responsive: [
      {
        // sm
        breakpoint: theme.breakpoints.values.sm,
        options: {
          plotOptions: { bar: { columnWidth: '40%' } },
        },
      },
      {
        // md
        breakpoint: theme.breakpoints.values.md,
        options: {
          plotOptions: { bar: { columnWidth: '32%' } },
        },
      },
    ],

    // plotOptions: { bar: { columnWidth: '14%' } },

    series: [
      {
        name: 'Used',
        type: 'column',
        data: chartData?.Used,
      },
      {
        name: 'Not Used',
        type: 'column',
        data: chartData?.NotUsed,
      },
    ],

    // y axis
    yaxis: [
      {
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
        },
        labels: {
          style: {
            // colors: '#FF1654',
            fontWeight: 'bold',
          },
        },
        title: {
          text: 'Customers Using',
          style: {
            color: '#FF1654',
          },
        },
      },
    ],
  };

  useEffect(() => {
    const today = moment();
    const lastSevenDays = Array(7)
      .fill()
      .map(() => today.subtract(1, 'd').format('DD-MM-YYYY'));
    setXAxisData(lastSevenDays);
  }, []);

  // user select date range from date picker
  const onSelectDateRange = (value, dateString) => {
    const startDate = dateString[0];
    const endDate = dateString[1];
    // console.log('startDate', startDate);
    // console.log('endDate', endDate);
  };

  return (
    <>
      <Divider sx={{ p: 1, mb: 2 }} />
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={4}>
          <Card sx={{ mb: 1, mt: 1, pb: 2 }}>
            <CardHeader title="All Users Summary" />
            <ReactApexChart
              type="pie"
              series={userSummaryChartOptions.series}
              options={userSummaryChartOptions.options}
              height={300}
            />
          </Card>
        </Grid>
        <Grid item xs={12} md={6} lg={8}>
          <Card sx={{ mb: 1, mt: 1 }}>
            <div
              style={{
                marginTop: '10px',
                marginRight: '10px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <CardHeader title="New Customers Summary" sx={{ paddingTop: 'inherit' }} />
              <RangePicker
                format={'DD/MM/YYYY'}
                onChange={(value, event) => onSelectDateRange(value, event)}
                className={'createDateRangePicker'}
                pupClassName={'createDateRangePicker'}
                {...(!isLight && {
                  className: 'date-picker-dark',
                })}
              />
            </div>
            <ReactApexChart
              type="line"
              series={newCustomerChartOptions.series}
              options={newCustomerChartOptions}
              height={400}
            />
          </Card>
        </Grid>
        <Grid item xs={12} align="right">
          <Button
            variant="contained"
            onClick={() => {
              exportToCsvAllUserDetailsData();
            }}
          >
            Export Excel
          </Button>
        </Grid>
        <Grid item xs={12}>
          <div style={{ height: 800, width: '100%' }}>
            <DataGrid
              rows={userDetailsData}
              loading={dataLoading}
              columns={allUsersDataColumns}
              getRowId={(row) => row.No}
              muiTableHeadCellProps={{
                sx: {
                  backgroundColor: SystemColor.originColor,
                  color: '#FFF',
                  boxShadow: `inset 8px 0 0 ${SystemColor.originColor} !important`,
                },
              }}
            />
          </div>
        </Grid>

        <div style={{ height: 800, width: '100%' }}></div>
      </Grid>
    </>
  );
}
