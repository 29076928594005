/* eslint-disable */
import { LoadingButton } from '@mui/lab';
import {
  Box, Button, CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import DataGridTable from '../../../components/Common/DataGridTable';
import Iconify from '../../../components/Iconify';
import TimeAccountModal from './TimeAccountModal';
import useAuth from '../../../hooks/useAuth';
import { AddMeeshoAccount, CreatePostAPICall, MyLinkedAccount } from '../../../utils/apis';
import {
  Android12Switch,
  BootstrapDialog,
  BootstrapDialogTitle,
  CancelChequeBucketName,
  downloadS3File,
  handleOnClickCopy, IOSSwitch, isAdmin, isDesktopEnvironment, isSuperAdminEnvironment,
  uploadFileS3,
} from '../../../utils/utilities';
import AccountStatusButton from './AccountStatusButton';

export default function MeeshoLinkedAccounts({ linkedMeeshoAccounts, getAccountData }) {
  const { user } = useAuth();
  const localUser = window.localStorage.getItem('userEmailId');
  const { enqueueSnackbar } = useSnackbar();
  const [userToBeAdd, setUserToBeAdd] = useState({});
  const [selectedRow, setSelectedRow] = useState(null);
  const [inputFieldType, setInputFieldType] = useState('text');
  const [inputFieldModalType, setInputFieldModalType] = useState('password');
  const [openMobileNoModel, setOpenMobileNoModel] = useState(false);
  const [openPasswordModel, setOpenPasswordModel] = useState(false);
  const [openTimeHistoryScheduleModel, setOpenTimeHistoryScheduleModel] = useState(false);
  const [openDeleteUserModel, setOpenDeleteUserModel] = useState(false);
  const [newMobileNumber, setNewMobileNumber] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [isAccountUpdating, setIsAccountUpdating] = useState(false);
  const [isInsertingAccountMeesho, setIsInsertingAccountMeesho] = useState(false);

  const [meeshoColumns, setMeshoColumns] = useState([
    {
      accessorKey: 'Email',
      header: 'Info',
      Cell: ({ cell }) => (
        <div>
          <span>
            Email : {cell.row.original?.Email}
            <Tooltip title="Copy">
              <IconButton onClick={() => handleOnClickCopy(cell.row.original?.Email, enqueueSnackbar)}>
                <Iconify icon={'eva:copy-fill'} width={15} height={15} />
              </IconButton>
            </Tooltip>
          </span>
          <br />
          <span>
            Name : {cell.row.original?.Name}
            <Tooltip title="Copy">
              <IconButton onClick={() => handleOnClickCopy(cell.row.original?.Name, enqueueSnackbar)}>
                <Iconify icon={'eva:copy-fill'} width={15} height={15} />
              </IconButton>
            </Tooltip>
          </span>
          <br />
          <span>
            Id : {cell.row.original?.SupplierId}
            <Tooltip title="Copy">
              <IconButton onClick={() => handleOnClickCopy(cell.row.original?.SupplierId, enqueueSnackbar)}>
                <Iconify icon={'eva:copy-fill'} width={15} height={15} />
              </IconButton>
            </Tooltip>
          </span>
          <br />
          <span>
            Identifier : {cell.row.original?.identifier}
            <Tooltip title="Copy">
              <IconButton onClick={() => handleOnClickCopy(cell.row.original?.identifier, enqueueSnackbar)}>
                <Iconify icon={'eva:copy-fill'} width={15} height={15} />
              </IconButton>
            </Tooltip>
          </span>
        </div>
      ),
    },
    {
      accessorKey: 'action',
      header: 'Action',
      Cell: ({ cell }) => (
        <div>
          <Tooltip title="Update Mobile Number" placement="bottom">
            <IconButton
              onClick={() => {
                setOpenMobileNoModel(true);
                setNewMobileNumber(cell.row.original?.Mobile_No?.replaceAll(' ', '').slice(-10));
                setSelectedRow(cell.row.original);
              }}
            >
              <Iconify icon={'icon-park:phone-call'} width={24} height={24} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Timers History" placement="bottom">
            <IconButton
              onClick={() => {
                setSelectedRow(cell.row.original);
                setOpenTimeHistoryScheduleModel(true);
              }}
            >
              <Iconify icon={'icon-park:alarm-clock'} width={24} height={24} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Update password" placement="bottom">
            <IconButton
              onClick={() => {
                setOpenPasswordModel(true);
                setSelectedRow(cell.row.original);
              }}
            >
              <Iconify icon={'icon-park:edit'} width={24} height={24} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete" placement="bottom">
            <IconButton
              onClick={() => {
                setOpenDeleteUserModel(true);
                setSelectedRow(cell.row.original);
              }}
            >
              <Iconify icon={'icon-park:delete-one'} width={24} height={24} />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
    {
      accessorKey: 'Image_Link',
      header: 'Cheque',
      Cell: ({ cell }) => (
        <div>
          <Tooltip title="Upload cheque" placement="bottom">
            <IconButton component="label">
              <Iconify icon={'icon-park:folder-upload'} width={24} height={24} />
              <input
                accept=".png, .jpg, .jpeg"
                onChange={async (e) => {
                  await uploadCancelChequeImage(e, cell.row.original);
                }}
                type="file"
                hidden
              />
            </IconButton>
          </Tooltip>
          {cell.row.original?.Image_Link ? (
            <Tooltip title="Download cheque" placement="bottom">
              <IconButton
                onClick={async () => {
                  await downloadS3File(cell.row.original?.Image_Link, false, CancelChequeBucketName, enqueueSnackbar);
                }}
              >
                <Iconify icon={'icon-park:folder-download'} width={24} height={24} />
              </IconButton>
            </Tooltip>
          ) : null}
        </div>
      ),
    },
  ]);

  useEffect(() => {
    if (isSuperAdminEnvironment) {
      setMeshoColumns(prevState => [
        ...prevState,
        {
          accessorKey: 'accountStatus',
          header: 'Account Status',
          size: 10,
          Cell: ({ cell }) => (
            <AccountStatusButton
              user={user}
              localUser={localUser}
              cell={cell}
              enqueueSnackbar={enqueueSnackbar}
              getAccountData={getAccountData}
              platform={'meesho'}
            />
          ),
        },
      ]);
    }
  }, []);



  // call update api for mobile num and password
  const meeshoUpdateMobileNumberAndPassword = async (isMobileNumberUpdate) => {
    setIsAccountUpdating(true);
    const body = {
      username: user?.email ? user.email : localUser,
      tpEmailId: selectedRow.Email,
      subAccountId: selectedRow.SupplierId,
      acType: selectedRow.AcType,
    };

    if (isMobileNumberUpdate) {
      body.mobile_no = newMobileNumber;
      body.actionType = 'updateMobileNo';
    } else {
      body.password = newPassword;
      body.actionType = 'updatePassword';
    }

    const successMessage =
      body.actionType === 'updateMobileNo' ? 'Mobile number updated successfully.' : 'Password updated successfully';
    const response = await CreatePostAPICall(enqueueSnackbar, MyLinkedAccount, body, successMessage);
    if (response) {
      await getAccountData();
      clearAccountUpdateModals();
    }
  };

  // upload cancel cheque image
  const uploadCancelChequeImage = async (e, item) => {
    const file = e.target.files[0];
    const ext = file.name.split('.').pop();
    const supplierId = item.SupplierId;
    const fileName = `${supplierId}_cheque.${ext}`;

    const uploadedTags = await uploadFileS3(
      file,
      fileName,
      `${supplierId}/${fileName}`,
      CancelChequeBucketName,
      enqueueSnackbar
    );
    if (uploadedTags.ETag.length > 0) {
      const body = {
        username: user?.email ? user.email : localUser,
        tpEmailId: item.Email,
        image_link: `${supplierId}/${fileName}`,
        subAccountId: supplierId,
        acType: item.AcType,
        actionType: 'uploadCheque',
      };
      const response = await CreatePostAPICall(enqueueSnackbar, MyLinkedAccount, body, 'Cheque uploaded successfully.');
      if (response) {
        getAccountData();
      }
    } else {
      enqueueSnackbar('Please try again.');
    }
  };

  // handle add account meesho api call to add account and refreshing table data
  const addMeeshoAccountAPI = async () => {
    if (!userToBeAdd?.tp_emailid || userToBeAdd?.tp_emailid === '') {
      enqueueSnackbar('Please enter email id.', { variant: 'error' });
      return;
    }
    if (!userToBeAdd?.password || userToBeAdd?.password === '') {
      enqueueSnackbar('Please enter password.', { variant: 'error' });
      return;
    }
    if (!userToBeAdd?.mobile_number || userToBeAdd?.mobile_number === '') {
      enqueueSnackbar('Please enter mobile number.', { variant: 'error' });
      return;
    }
    if (userToBeAdd?.mobile_number.length < 10) {
      enqueueSnackbar('Please enter valid mobile number.', { variant: 'error' });
      return;
    }
    setIsInsertingAccountMeesho(true);
    const body = {
      username: user?.email ? user.email : localUser,
      tpEmailId: userToBeAdd?.tp_emailid,
      password: userToBeAdd?.password,
      mobileNumber: userToBeAdd?.mobile_number,
      acType: 'meesho',
      syncDataType: 'addMeeshoAccount',
    };
    const response = await CreatePostAPICall(enqueueSnackbar, AddMeeshoAccount, body);
    if (response) {
      enqueueSnackbar(response.data.body);
      getAccountData();
    }
    setIsInsertingAccountMeesho(false);
    setUserToBeAdd({ tp_emailid: '', password: '', mobile_number: '' });
  };

  // for request delete user api call and refreshing table data
  const deleteUser = async () => {
    setIsAccountUpdating(true);
    const body = {
      username: user?.email ? user.email : localUser,
      email: selectedRow.Email,
      supplier_id: selectedRow.SupplierId,
      acType: selectedRow.AcType,
      actionType: 'delete',
    };
    const response = await CreatePostAPICall(enqueueSnackbar, MyLinkedAccount, body);
    if (response) {
      getAccountData();
      clearAccountUpdateModals();
      enqueueSnackbar(response.data.body);
    }
  };

  // handle input change for all text-field
  const onInputChange = (event) => {
    const { name, value } = event.target;
    if (name === 'tp_company') {
      setUserToBeAdd({
        ...userToBeAdd,
        [name]: value.toUpperCase(),
      });
    } else if (name === 'tp_emailid') {
      setUserToBeAdd({
        ...userToBeAdd,
        [name]: value.toLowerCase(),
      });
    } else {
      setUserToBeAdd({
        ...userToBeAdd,
        [name]: value,
      });
    }
  };

  // for clear all value on modal pop up close
  const clearAccountUpdateModals = () => {
    setOpenMobileNoModel(false);
    setOpenPasswordModel(false);
    setNewMobileNumber('');
    setNewPassword('');
    setSelectedRow(null);
    setOpenTimeHistoryScheduleModel(false);
    setOpenDeleteUserModel(false);
    setIsAccountUpdating(false);
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Divider sx={{ p: 1 }} />
          <Grid sx={{ p: 3 }} container spacing={3}>
            {/*<Grid item xs={12} md={4}>*/}
            {/*  <Typography sx={{ pb: 2 }} variant="h6">*/}
            {/*    Link New Meesho Account*/}
            {/*  </Typography>*/}
            {/*  <Box*/}
            {/*    sx={{*/}
            {/*      display: 'grid',*/}
            {/*      rowGap: 2,*/}
            {/*      p: 2,*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    <TextField*/}
            {/*      name="tp_emailid"*/}
            {/*      value={userToBeAdd?.tp_emailid}*/}
            {/*      placeholder="Email"*/}
            {/*      id="meeshoTpEmailid"*/}
            {/*      autoComplete="off"*/}
            {/*      onChange={onInputChange}*/}
            {/*      InputProps={{*/}
            {/*        startAdornment: (*/}
            {/*          <InputAdornment position="start">*/}
            {/*            <IconButton>*/}
            {/*              <Iconify icon={'clarity:email-line'} width={24} height={24} />*/}
            {/*            </IconButton>*/}
            {/*          </InputAdornment>*/}
            {/*        ),*/}
            {/*      }}*/}
            {/*    />*/}
            {/*    <TextField*/}
            {/*      value={userToBeAdd?.password}*/}
            {/*      name="password"*/}
            {/*      placeholder="Password"*/}
            {/*      id="meeshoPassword"*/}
            {/*      type={inputFieldType}*/}
            {/*      onChange={onInputChange}*/}
            {/*      InputProps={{*/}
            {/*        startAdornment: (*/}
            {/*          <InputAdornment position="start">*/}
            {/*            <IconButton>*/}
            {/*              <Iconify icon={'ri:lock-password-line'} width={24} height={24} />*/}
            {/*            </IconButton>*/}
            {/*          </InputAdornment>*/}
            {/*        ),*/}
            {/*        endAdornment: (*/}
            {/*          <InputAdornment position="end">*/}
            {/*            {inputFieldType === 'password' ? (*/}
            {/*              <IconButton*/}
            {/*                onClick={() => {*/}
            {/*                  setInputFieldType('text');*/}
            {/*                }}*/}
            {/*              >*/}
            {/*                <Iconify icon={'ant-design:eye-invisible-filled'} width={24} height={24} />*/}
            {/*              </IconButton>*/}
            {/*            ) : (*/}
            {/*              <IconButton*/}
            {/*                onClick={() => {*/}
            {/*                  setInputFieldType('password');*/}
            {/*                }}*/}
            {/*              >*/}
            {/*                <Iconify icon={'ant-design:eye-filled'} width={24} height={24} />*/}
            {/*              </IconButton>*/}
            {/*            )}*/}
            {/*          </InputAdornment>*/}
            {/*        ),*/}
            {/*      }}*/}
            {/*    />*/}
            {/*    <TextField*/}
            {/*      value={userToBeAdd?.mobile_number}*/}
            {/*      name="mobile_number"*/}
            {/*      autoComplete="none"*/}
            {/*      type="number"*/}
            {/*      id="meeshoMobileNo"*/}
            {/*      placeholder="Mobile Number"*/}
            {/*      onChange={(event) => {*/}
            {/*        if (event.target.value.length <= 10) {*/}
            {/*          onInputChange(event);*/}
            {/*        }*/}
            {/*      }}*/}
            {/*      InputProps={{*/}
            {/*        maxLength: 10,*/}
            {/*        startAdornment: (*/}
            {/*          <InputAdornment position="start">*/}
            {/*            <div style={{ padding: '8px' }}>+91</div>*/}
            {/*          </InputAdornment>*/}
            {/*        ),*/}
            {/*      }}*/}
            {/*    />*/}
            {/*    <div>*/}
            {/*      <p style={{ color: '#FFCC00' }}>Meesho will contact you by this number.</p>*/}
            {/*    </div>*/}

            {/*    /!* TODO Desktop Flipkart Add Account To Live It Unbale It *!/*/}
            {/*    /!**/}
            {/*      <Button*/}
            {/*          className="mr-xs"*/}
            {/*          id="meeshoAddAccount"*/}
            {/*          variant="contained"*/}
            {/*          style={{ width: '50%' }}*/}
            {/*        >*/}
            {/*          Link Account*/}
            {/*      </Button>*/}
            {/*    *!/*/}
            {/*    <Grid item xs={12} md={12} lg={12}>*/}
            {/*      <LoadingButton*/}
            {/*        className="mr-xs"*/}
            {/*        id="meeshoAddAccountAPI"*/}
            {/*        variant="contained"*/}
            {/*        style={{ width: '50%' }}*/}
            {/*        onClick={addMeeshoAccountAPI}*/}
            {/*        loading={isInsertingAccountMeesho}*/}
            {/*      >*/}
            {/*        Link Account*/}
            {/*      </LoadingButton>*/}
            {/*    </Grid>*/}
            {/*  </Box>*/}
            {/*</Grid>*/}<Grid item xs={12} md={4}>
            <Typography sx={{pb: 2}} variant="h6">
              Link New Meesho Account
            </Typography>
            <Box
                sx={{
                  display: 'grid',
                  rowGap: 2,
                  p: 2,
                }}
            >
            {isDesktopEnvironment?
                <>
                <TextField
                    name="tp_emailid"
                    value={userToBeAdd?.tp_emailid}
                    placeholder="Email"
                    id="meeshoTpEmailid"
                    autoComplete="off"
                    onChange={onInputChange}
                    InputProps={{
                      startAdornment: (
                          <InputAdornment position="start">
                            <IconButton>
                              <Iconify icon={'clarity:email-line'} width={24} height={24}/>
                            </IconButton>
                          </InputAdornment>
                      ),
                    }}
                />
                <TextField
                    value={userToBeAdd?.password}
                    name="password"
                    placeholder="Password"
                    id="meeshoPassword"
                    type={inputFieldType}
                    onChange={onInputChange}
                    InputProps={{
                      startAdornment: (
                          <InputAdornment position="start">
                            <IconButton>
                              <Iconify icon={'ri:lock-password-line'} width={24} height={24}/>
                            </IconButton>
                          </InputAdornment>
                      ),
                      endAdornment: (
                          <InputAdornment position="end">
                            {inputFieldType === 'password' ? (
                                <IconButton
                                    onClick={() => {
                                      setInputFieldType('text');
                                    }}
                                >
                                  <Iconify icon={'ant-design:eye-invisible-filled'} width={24} height={24}/>
                                </IconButton>
                            ) : (
                                <IconButton
                                    onClick={() => {
                                      setInputFieldType('password');
                                    }}
                                >
                                  <Iconify icon={'ant-design:eye-filled'} width={24} height={24}/>
                                </IconButton>
                            )}
                          </InputAdornment>
                      ),
                    }}
                />
                <TextField
                    value={userToBeAdd?.mobile_number}
                    name="mobile_number"
                    autoComplete="none"
                    type="number"
                    id="meeshoMobileNo"
                    placeholder="Mobile Number"
                    onChange={(event) => {
                      if (event.target.value.length <= 10) {
                        onInputChange(event);
                      }
                    }}
                    InputProps={{
                      maxLength: 10,
                      startAdornment: (
                          <InputAdornment position="start">
                            <div style={{padding: '8px'}}>+91</div>
                          </InputAdornment>
                      ),
                    }}
                />
                <div>
                  <p style={{color: '#FFCC00'}}>Meesho will contact you by this number.</p>
                </div>

                {/* TODO Desktop Flipkart Add Account To Live It Unbale It */}
                {/*
                  <Button
                      className="mr-xs"
                      id="meeshoAddAccount"
                      variant="contained"
                      style={{ width: '50%' }}
                    >
                      Link Account
                  </Button>
                */}
                <Grid item xs={12} md={12} lg={12}>
                  <LoadingButton
                      className="mr-xs"
                      id="meeshoAddAccount"
                      variant="contained"
                      style={{width: '50%'}}
                      onClick={()=>{}}
                      loading={isInsertingAccountMeesho}
                  >
                    Link Account
                  </LoadingButton>
                </Grid></>:<div style={{maxWidth: '300px'}}>
              <p style={{  color: '#FFCC00',marginBottom:'15px' }}> Please Use BuissMaster OMS Desktop App For Data Sync. </p>
              <Button fullWidth variant="contained" href={"https://buissmaster-desktop-mac.s3.ap-south-1.amazonaws.com/autoUpdate/APP/BuissMaster+OMS+DesktopAPP+V3.exe"}>Download App</Button>
            </div>
            }
          </Box>
          </Grid>

            <Grid item xs={12} md={8}>
              <Typography sx={{ pb: 2 }} variant="h6">
                My Linked Meesho Accounts
              </Typography>
              <DataGridTable
                enableRowNumbers={true}
                columns={meeshoColumns}
                data={linkedMeeshoAccounts}

                enablePagination={false}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <BootstrapDialog
        sx={{ '& .MuiDialog-paper': { width: '100%', maxHeight: 435 } }}
        maxWidth="sm"
        onClose={clearAccountUpdateModals}
        aria-labelledby="customized-dialog-title"
        open={openMobileNoModel}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={clearAccountUpdateModals}>
          Update Mobile Number
        </BootstrapDialogTitle>
        <Divider orientation="horizontal" flexItem />
        <DialogContent>
          <Box>
            <Stack spacing={3}>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <TextField
                  value={newMobileNumber}
                  autoComplete="none"
                  type="number"
                  fullWidth
                  placeholder="Mobile Number"
                  onChange={(e) => {
                    if (e.target.value.length <= 10) {
                      setNewMobileNumber(e.target.value);
                    }
                  }}
                  InputProps={{
                    maxLength: 10,
                    startAdornment: (
                      <InputAdornment position="start">
                        <div style={{ padding: '8px' }}>+91</div>
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack>
            </Stack>
          </Box>
        </DialogContent>
        <Divider orientation="horizontal" flexItem />
        <DialogActions>
          <LoadingButton
            loading={isAccountUpdating}
            variant="contained"
            autoFocus
            onClick={async () => {
              await meeshoUpdateMobileNumberAndPassword(true);
            }}
          >
            Submit
          </LoadingButton>
        </DialogActions>
      </BootstrapDialog>
      <BootstrapDialog
        sx={{ '& .MuiDialog-paper': { width: '100%', maxHeight: 435 } }}
        maxWidth="sm"
        onClose={clearAccountUpdateModals}
        aria-labelledby="customized-dialog-title"
        open={openPasswordModel}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={clearAccountUpdateModals}>
          Change Password
        </BootstrapDialogTitle>
        <Divider orientation="horizontal" flexItem />
        <DialogContent>
          <Box>
            <Stack spacing={3}>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <TextField
                  value={newPassword}
                  placeholder="Password"
                  fullWidth
                  type={inputFieldModalType}
                  onChange={(e) => {
                    setNewPassword(e.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton>
                          <Iconify icon={'ri:lock-password-line'} width={24} height={24} />
                        </IconButton>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        {inputFieldModalType === 'password' ? (
                          <IconButton
                            onClick={() => {
                              setInputFieldModalType('text');
                            }}
                          >
                            <Iconify icon={'ant-design:eye-invisible-filled'} width={24} height={24} />
                          </IconButton>
                        ) : (
                          <IconButton
                            onClick={() => {
                              setInputFieldModalType('password');
                            }}
                          >
                            <Iconify icon={'ant-design:eye-filled'} width={24} height={24} />
                          </IconButton>
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack>
            </Stack>
          </Box>
        </DialogContent>
        <Divider orientation="horizontal" flexItem />
        <DialogActions>
          <LoadingButton
            loading={isAccountUpdating}
            variant="contained"
            autoFocus
            onClick={async () => {
              await meeshoUpdateMobileNumberAndPassword();
            }}
          >
            Submit
          </LoadingButton>
        </DialogActions>
      </BootstrapDialog>
      <BootstrapDialog
        sx={{ '& .MuiDialog-paper': { width: '100%', maxHeight: 435 } }}
        maxWidth="sm"
        onClose={() => {
          setOpenDeleteUserModel(false);
        }}
        aria-labelledby="customized-dialog-title"
        open={openDeleteUserModel}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => {
            setOpenDeleteUserModel(false);
          }}
        >
          Delete User
        </BootstrapDialogTitle>
        <Divider orientation="horizontal" flexItem />
        <DialogContent>
          <Box>
            <Stack spacing={3}>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <Typography>
                  Are you sure, want to sent permanently data delete request to admin for this account?
                </Typography>
              </Stack>
            </Stack>
          </Box>
        </DialogContent>
        <Divider orientation="horizontal" flexItem />
        <DialogActions>
          <LoadingButton
            variant="contained"
            loading={isAccountUpdating}
            autoFocus
            onClick={async () => {
              await deleteUser();
            }}
          >
            Yes
          </LoadingButton>
        </DialogActions>
      </BootstrapDialog>

      {(selectedRow && openTimeHistoryScheduleModel) && <TimeAccountModal
        closeTimeAccountHistoryModal={clearAccountUpdateModals}
        userEmailId={user?.email}
        platform={'meesho'}
        openTimeHistoryScheduleModel={openTimeHistoryScheduleModel}
        timeHistoryScheduleData={selectedRow}
        enqueueSnackbar={enqueueSnackbar}
      />}
    </>
  );
}
