import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import FormControlLabel from '@mui/material/FormControlLabel';
import { LoadingButton } from '@mui/lab';
import { Alert, Checkbox, IconButton, InputAdornment, Link, Stack } from '@mui/material';
// routes
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFCheckbox, RHFTextField } from '../../../components/hook-form';
import urlConstants from '../../../routes/urlConstants';
import { isAdmin, isDesktopEnvironment } from '../../../utils/utilities';
import { CreatePostAPICall, UserProfile } from '../../../utils/apis';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const { login } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const isMountedRef = useIsMountedRef();

  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
  });

  const {
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
  } = methods;
  useEffect(() => {
    if(isDesktopEnvironment) {
      if (methods.watch('rememberedEmail')) {
        setRememberMe(true);
      }
    }
  }, [methods.watch('rememberedEmail')]);

  useEffect(() => {
    if (isDesktopEnvironment) {
      const storedEmail = localStorage.getItem('rememberedEmail');
      const storedPass = localStorage.getItem('rememberedPass');
      if (storedEmail && storedPass) {
        setValue('email', storedEmail);
        setValue('password', storedPass);
        setRememberMe(true);
      }
    }
  }, [setValue]);

  const onSubmit = async (data) => {
    try {
      const response = await login(data.email, data.password);

      if (response) {
        window.localStorage.setItem('userEmailId', response.idToken.payload.email);
        window.localStorage.setItem('idToken', response.idToken.jwtToken);
        window.localStorage.setItem('accessToken', response.accessToken.jwtToken);
        if (isDesktopEnvironment) {
          if (rememberMe) {
            localStorage.setItem('rememberedEmail', data.email);
            localStorage.setItem('rememberedPass', data.password);
          } else {
            localStorage.removeItem('rememberedEmail');
            localStorage.removeItem('rememberedPass');
          }
        }
        const userIdResponse = await CreatePostAPICall(enqueueSnackbar, UserProfile, {
          username: data.email,
          action: 'getUserInfo',
        });

        localStorage.setItem('userId', JSON.stringify(userIdResponse.data.body.Id));
        localStorage.setItem('Balance_Status', userIdResponse.data.body.Id);
        localStorage.setItem('userStatus', userIdResponse.data.body.userStatus);
        navigate(urlConstants.ROOT_OMS);
      }
    } catch (error) {
      if (isMountedRef.current) {
        const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
        await sleep(1000);
        setError('afterSubmit', { ...error, message: error.message });
      }
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

        <RHFTextField name="email" label="Email address" />

        <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={rememberMe}
              onChange={(e) => {
                setRememberMe(!rememberMe);
              }}
            />
          }
          name={'remember'}
          label="Remember Me"
        />
        <Link component={RouterLink} variant="subtitle2" to={urlConstants.RESET_PASSWORD_PAGE}>
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton fullWidth id="login" size="large" type="submit" variant="contained" loading={isSubmitting}>
        Login
      </LoadingButton>
    </FormProvider>
  );
}
