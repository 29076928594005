/* eslint-disable */
import React, {useEffect, useState} from 'react';
import {Grid, TextField, IconButton, Box, Divider, Button, Autocomplete, InputAdornment} from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import InputLabel from '@mui/material/InputLabel';
import {LoadingButton} from '@mui/lab';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {AddGlowroadAccount, AddSnapdealAccount, CreatePostAPICall, MyLinkedAccount} from '../../../utils/apis';
import {
    BootstrapDialog,
    BootstrapDialogTitle,
    CapitalizeFirstLetter,
    getAccountsData,
} from '../../../utils/utilities';
import DataGridTable from '../../../components/Common/DataGridTable';
import Iconify from '../../../components/Iconify';
import useAuth from "../../../hooks/useAuth";
import {Typography} from "antd";

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 175,
            width: 250,
        },
    },
};

export default function VerifyOtpModel({
                                           closeTimeAccountHistoryModal,
                                           platform,
                                           selectedRow,
                                           openOtpVerifyModel,
                                           enqueueSnackbar,
                                           setSelectedRow
                                       }) {

    const {user} = useAuth();
    const localUser = window.localStorage.getItem('userEmailId');

    const [Otp, setOtp] = useState('');
    const [isOtpLoading, setIsOtpLoading] = useState(false);
    const onCloseModal = () => {
        setOtp('');
        closeTimeAccountHistoryModal();
    };

    const verifyByGmail = async () => {
        const body = {
            username: user?.email ? user.email : localUser,
            tpEmailId: selectedRow?.Email,
            acType: platform,
            syncDataType: "otpVerify"
        };
        const response = await CreatePostAPICall(
            enqueueSnackbar,
            platform==='snapdeal' ? AddSnapdealAccount : AddGlowroadAccount,
            body,
            "OTP Verified Successfully"
        );
        if (response) {
            enqueueSnackbar(response.data.body)
            setSelectedRow(null);
            closeTimeAccountHistoryModal();
        }
    }

    // for verify otp
    const OtpVerify = async () => {
        setIsOtpLoading(true)
        const body = {
            username: user?.email ? user.email : localUser,
            tpEmailId: selectedRow?.Email,
            acType: platform,
            Otp: Otp,
            syncDataType: "otpVerify"
        };
        const response = await CreatePostAPICall(
            enqueueSnackbar,
            platform==='snapdeal' ? AddSnapdealAccount : AddGlowroadAccount,
            body,
            "OTP Verified Successfully"
        );
        console.log(response);
        if (response) {
            enqueueSnackbar("OTP Verified Successfully")
            setOtp('');
            setSelectedRow(null);
            closeTimeAccountHistoryModal();
        }
        setIsOtpLoading(false)
    }
    return (
        <>
            <BootstrapDialog
                sx={{'& .MuiDialog-paper': {width: '100%', maxHeight: 600}}}
                maxWidth={'sm'}
                onClose={()=> {
                    if(!isOtpLoading) {
                    onCloseModal()
                    }
                }}
                aria-labelledby="customized-dialog-title"
                open={openOtpVerifyModel}
            >
                <BootstrapDialogTitle id="customized-dialog-title"  onClose={()=> {
                    if(!isOtpLoading) {
                        onCloseModal()
                    }
                }}>
                    {`${CapitalizeFirstLetter(platform)}: ${selectedRow ? selectedRow.Email : ''}`}
                </BootstrapDialogTitle>
                <Divider orientation="horizontal" flexItem/>
                <DialogContent>


                    <h3>Enter OTP</h3>
                    <Grid sx={{mt: '1rem'}}>
                        <TextField
                            fullWidth
                            type="number"
                            placeholder="OTP Number*"
                            value={Otp}
                            onChange={(e) => {
                                setOtp(e.target.value);
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <LoadingButton type={'submit'} variant="outlined" loading={isOtpLoading} onClick={(e) => {
                                            OtpVerify()
                                        }}>
                                            Verify
                                        </LoadingButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <center style={{marginTop: '1rem'}}>
                        <h4>OR</h4>
                        <LoadingButton sx={{mt: '1.5rem'}} disabled={isOtpLoading} onClick={()=>{
                            if(!isOtpLoading){
                                verifyByGmail()
                            }}} variant='contained'>Verify By
                            Gmail</LoadingButton>
                    </center>
                </DialogContent>
            </BootstrapDialog>
        </>
    );
}
