/* eslint-disable */
// Validation for OrderFile
import Cornerimage from "../../../assets/images/top_right_corner.png";
import {Image} from "@react-pdf/renderer";
import React from "react";

export const MeeshoValidateUploadedFile = (header, type, header2) => {
  if (type === 'Order') {
    try {
      if (
        header[0] === 'Reason for Credit Entry' &&
        header[1] === 'Sub Order No' &&
        header[2] === 'Order Date' &&
        header[3] === 'Customer State' &&
        header[5] === 'SKU' &&
        header[6] === 'Size' &&
        header[7] === 'Quantity'
      ) {
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  }

  if (type === 'RTNIn') {
    try {
      if (
        header[2] === 'SKU' &&
        header[3] === 'Variation' &&
        header[6] === 'Qty' &&
        header[8] === 'Suborder Number' &&
        header[10] === 'Return Created Date' &&
        header[11] === 'Type of Return' &&
        header[12] === 'Sub Type' &&
        header[14] === 'Courier Partner' &&
        header[15] === 'AWB Number' &&
        header[20] === 'Return Reason' &&
        header[21] === 'Detailed Return Reason'
      ) {
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  }

  if (type === 'RTNDL') {
    // console.log('==>', header, type);
    try {
      if (
        (header[2] === 'SKU' &&
          header[3] === 'Variation' &&
          header[6] === 'Qty' &&
          header[8] === 'Suborder Number' &&
          header[10] === 'Return Created Date' &&
          header[11] === 'Type of Return' &&
          header[12] === 'Sub Type' &&
          header[13] === 'Delivered Date' &&
          header[14] === 'Courier Partner' &&
          header[15] === 'AWB Number' &&
          header[19] === 'Return Reason' &&
          header[20] === 'Detailed Return Reason')
        //   ||
        // header[14] === 'Expected Delivery Date'
      ) {
        console.log('True');
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  }

  if (type === 'Ticket') {
    try {
      if (
        header[7] === 'Suborder Number' &&
        header[8] === 'Ticket ID' &&
        header[9] === 'Ticket Status' &&
        header[10] === 'Created Date' &&
        header[11] === 'Issue' &&
        header[12] === 'Last Update' &&
        header[13] === 'Reopen Validity'
      ) {
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  }

  if (type === 'SkuIn') {
    console.log('====================================');
    console.log('SkuIn');
    console.log('====================================');
    try {
      if (
        header[0] === 'SERIAL NO' &&
        header[1] === 'CATALOG NAME' &&
        header[2] === 'CATALOG ID' &&
        header[3] === 'PRODUCT NAME' &&
        header[4] === 'PRODUCT ID' &&
        header[5] === 'STYLE ID' &&
        header[6] === 'VARIATION ID' &&
        header[7] === 'VARIATION' &&
        header[8] === 'STOCK' &&
        header[9] === 'SYSTEM STOCK COUNT' &&
        header[10] === 'YOUR STOCK COUNT' &&
        header2[0] === 'Row identifier' &&
        header2[1] === 'Catalog name' &&
        header2[2] === 'Catalog id' &&
        header2[3] === 'Product name' &&
        header2[4] === 'Product id' &&
        header2[5] === 'Product ID/Style ID' &&
        header2[6] === 'Variation id' &&
        header2[7] === 'Variation' &&
        header2[8] === 'Stock type (IN_STOCK / OUT_OF_STOCK / ALL)' &&
        header2[9] === 'Current system stock count' &&
        header2[10] === 'Edit this (keep empty if no change in stock)'
      ) {
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  }
};

function arrayDifference(array1, array2) {
  return array1.filter((value) => !array2.includes(value));
}

export const MeeshoPaymentExcelValidation = (excelRowHeader) => {
  const meeshoPaymentExcelRowTypeOne = [
    'Sub Order No',
    'Order Date',
    'Dispatch Date',
    'Product Name',
    'Supplier SKU',
    'Live Order Status',
    'Product GST %',
    'Listing Price (Incl. GST & Commission)',
    'Quantity',
    'Transaction ID',
    'Payment Date',
    'Final Settlement Amount',
    'Price Type',
    'Total Sale Amount (Incl. Commission & GST)',
    'Sale Return Amount (Incl. GST)',
    'Fixed Fee (Incl. GST)',
    'Waas fee (inc Gst)',
    'Shipping Revenue (Incl. GST)',
    'Shipping Return Amount (Incl. GST)',
    'Return premium (incl GST)',
    'Return premium (incl GST) of Return',
    'Meesho Commission Percentage',
    'Meesho Commission (Excl. GST)',
    'Meesho gold platform fee (excl GST)',
    'Meesho mall platform fee (excl. GST)',
    'Fixed Fee (excl. GST)',
    'Waas fee (excl Gst)',
    'Return Shipping Charge (Excl. GST)',
    'GST Compensation (PRP Shipping)',
    'Shipping Charge (Excl. GST)',
    'Other Support Service Charges (Excl. GST)',
    'Waivers (Excl. GST)',
    'Net Other Support Service Charges (Excl. GST)',
    'GST on Meesho Commission',
    'GST on Waas fee',
    'GST on meesho gold',
    'GST on Meesho Mall platform fee',
    "GST on Shipping Charge', 'CGST + SGST on Shipping Charge",
    'GST on Return Shipping Charge',
    'GST on Net Other Support Service Charges',
    'GST on Fixed Fee',
    'TCS',
    'TDS Rate %',
    'TDS',
    'Compensation',
    'Claims',
    'Recovery',
  ];

  const meeshoPaymentExcelRowTypeTwo = [
    'Sub Order No',
    'Order Date',
    'Dispatch Date',
    'Product Name',
    'Supplier SKU',
    'Live Order Status',
    'Product GST %',
    'Listing Price (Incl. GST & Commission)',
    'Quantity',
    'Transaction ID',
    'Payment Date',
    'Final Settlement Amount',
    'Price Type',
    'Total Sale Amount (Incl. Commission & GST)',
    'Sale Return Amount (Incl. GST)',
    'Fixed Fee (Incl. GST)',
    'Shipping Revenue (Incl. GST)',
    'Shipping Return Amount (Incl. GST)',
    'Return premium (incl GST)',
    'Return premium (incl GST) of Return',
    'Meesho Commission Percentage',
    'Meesho Commission (Excl. GST)',
    'Meesho mall platform fee (excl. GST)',
    'Fixed Fee (excl. GST)',
    'Return Shipping Charge (Excl. GST)',
    'GST Compensation (PRP Shipping)',
    'Shipping Charge (Excl. GST)',
    'Other Support Service Charges (Excl. GST)',
    'Waivers (Excl. GST)',
    'Net Other Support Service Charges (Excl. GST)',
    'GST on Meesho Commission',
    'GST on Meesho Mall platform fee',
    "GST on Shipping Charge', 'CGST + SGST on Shipping Charge",
    'GST on Return Shipping Charge',
    'GST on Net Other Support Service Charges',
    'GST on Fixed Fee',
    'TCS',
    'TDS Rate %',
    'TDS',
    'Compensation',
    'Claims',
    'Recovery',
  ];

  const meeshoPaymentExcelRowTypeThree = [
    'Sub Order No',
    'Order Date',
    'Dispatch Date',
    'Product Name',
    'Supplier SKU',
    'Live Order Status',
    'Product GST %',
    'Listing Price (Incl. GST & Commission)',
    'Quantity',
    'Transaction ID',
    'Payment Date',
    'Final Settlement Amount',
    'Price Type',
    'Total Sale Amount (Incl. Commission & GST)',
    'Sale Return Amount (Incl. GST)',
    'Fixed Fee (Incl. GST)',
    'Shipping Revenue (Incl. GST)',
    'Shipping Return Amount (Incl. GST)',
    'Return premium (incl GST)',
    'Return premium (incl GST) of Return',
    'Meesho Commission Percentage',
    'Meesho Commission (Excl. GST)',
    'Meesho mall platform fee (excl. GST)',
    'Fixed Fee (excl. GST)',
    'Return Shipping Charge (Excl. GST)',
    'GST Compensation (PRP Shipping)',
    'Shipping Charge (Excl. GST)',
    'Other Support Service Charges (Excl. GST)',
    'Waivers (Excl. GST)',
    'Net Other Support Service Charges (Excl. GST)',
    'CGST + SGST on Meesho Commission',
    'GST on Meesho Mall platform fee',
    "GST on Shipping Charge', 'CGST + SGST on Shipping Charge",
    'CGST + SGST on Return Shipping Charge',
    'CGST + SGST on Net Penalty',
    'GST on Fixed Fee',
    'TCS',
    'TDS Rate %',
    'TDS',
    'Compensation',
    'Claims',
    'Recovery',
  ];

  const meeshoPaymentExcelRowTypeFour = [
    'Sub Order No',
    'Order Date',
    'Dispatch Date',
    'Product Name',
    'Supplier SKU',
    'Live Order Status',
    'Product GST %',
    'Listing Price (Incl. GST & Commission)',
    'Quantity',
    'Transaction ID',
    'Payment Date',
    'Final Settlement Amount',
    'Price Type',
    'Total Sale Amount (Incl. Commission & GST)',
    'Sale Return Amount (Incl. GST)',
    'Fixed Fee (Incl. GST)',
    'Waas fee (inc Gst)',
    'Shipping Revenue (Incl. GST)',
    'Shipping Return Amount (Incl. GST)',
    'Return premium (incl GST)',
    'Return premium (incl GST) of Return',
    'Meesho Commission Percentage',
    'Meesho Commission (Excl. GST)',
    'Meesho mall platform fee (excl. GST)',
    'Fixed Fee (excl. GST)',
    'Waas fee (excl Gst)',
    'Return Shipping Charge (Excl. GST)',
    'GST Compensation (PRP Shipping)',
    'Shipping Charge (Excl. GST)',
    'Other Support Service Charges (Excl. GST)',
    'Waivers (Excl. GST)',
    'Net Other Support Service Charges (Excl. GST)',
    'GST on Meesho Commission',
    'GST on Waas fee',
    'GST on Meesho Mall platform fee',
    "GST on Shipping Charge', 'CGST + SGST on Shipping Charge",
    'GST on Return Shipping Charge',
    'GST on Net Other Support Service Charges',
    'GST on Fixed Fee',
    'TCS',
    'TDS Rate %',
    'TDS',
    'Compensation',
    'Claims',
    'Recovery',
  ];

  const meeshoPaymentExcelRowTypeFive = [
    "Sub Order No",
    "Order Date",
    "Dispatch Date",
    "Product Name",
    "Supplier SKU",
    "Live Order Status",
    "Product GST %",
    "Listing Price (Incl. GST & Commission)",
    "Quantity",
    "Transaction ID",
    "Payment Date",
    "Final Settlement Amount",
    "Price Type",
    "Total Sale Amount (Incl. Commission & GST)",
    "Sale Return Amount (Incl. GST)",
    "Fixed Fee (Incl. GST)",
    "Warehousing fee (inc Gst)",
    "Shipping Revenue (Incl. GST)",
    "Shipping Return Amount (Incl. GST)",
    "Return premium (incl GST)",
    "Return premium (incl GST) of Return",
    "Meesho Commission Percentage",
    "Meesho Commission (Excl. GST)",
    "Meesho gold platform fee (excl GST)",
    "Meesho mall platform fee (excl. GST)",
    "Fixed Fee (excl. GST)",
    "Warehousing fee (excl Gst)",
    "Return Shipping Charge (Excl. GST)",
    "GST Compensation (PRP Shipping)",
    "Shipping Charge (Excl. GST)",
    "Other Support Service Charges (Excl. GST)",
    "Waivers (Excl. GST)",
    "Net Other Support Service Charges (Excl. GST)",
    "GST on Meesho Commission",
    "GST on Warehousing fee",
    "GST on meesho gold",
    "GST on Meesho Mall platform fee",
    "GST on Shipping Charge', 'CGST + SGST on Shipping Charge",
    "GST on Return Shipping Charge",
    "GST on Net Other Support Service Charges",
    "GST on Fixed Fee",
    "TCS",
    "TDS Rate %",
    "TDS",
    "Compensation",
    "Claims",
    "Recovery"
  ];

  const meeshoPaymentExcelRowTypeSix = [
    "Sub Order No",
    "Order Date",
    "Dispatch Date",
    "Product Name",
    "Supplier SKU",
    "Live Order Status",
    "Product GST %",
    "Listing Price (Incl. GST & Commission)",
    "Quantity",
    "Transaction ID",
    "Payment Date",
    "Final Settlement Amount",
    "Price Type",
    "Total Sale Amount (Incl. Commission & GST)",
    "Sale Return Amount (Incl. GST)",
    "Fixed Fee (Incl. GST)",
    "Warehousing fee (inc Gst)",
    "Shipping Revenue (Incl. GST)",
    "Shipping Return Amount (Incl. GST)",
    "Return premium (incl GST)",
    "Return premium (incl GST) of Return",
    "Meesho Commission Percentage",
    "Meesho Commission (Excl. GST)",
    "Meesho gold platform fee (excl GST)",
    "Meesho mall platform fee (excl. GST)",
    "Fixed Fee (excl. GST)",
    "Warehousing fee (excl Gst)",
    "Return Shipping Charge (Excl. GST)",
    "GST Compensation (PRP Shipping)",
    "Shipping Charge (Excl. GST)",
    "Other Support Service Charges (Excl. GST)",
    "Waivers (Excl. GST)",
    "Net Other Support Service Charges (Excl. GST)",
    "GST on Meesho Commission",
    "GST on Warehousing fee",
    "GST on meesho gold",
    "GST on Meesho Mall platform fee",
    "GST on Shipping Charge', 'CGST + SGST on Shipping Charge",
    "GST on Return Shipping Charge",
    "GST on Net Other Support Service Charges",
    "GST on Fixed Fee",
    "TCS",
    "TDS Rate %",
    "TDS",
    "Compensation",
    "Claims",
    "Recovery",
    "Compensation Reason",
    "Claims Reason",
    "Recovery Reason"
  ];

  // console.log("--------------------------------------------",excelRowHeader)
  // console.log("type1",arrayDifference(excelRowHeader,meeshoPaymentExcelRowTypeOne))
  // console.log("type2",arrayDifference(excelRowHeader,meeshoPaymentExcelRowTypeTwo))
  // console.log("type3",arrayDifference(excelRowHeader,meeshoPaymentExcelRowTypeThree))
  // console.log("type4",arrayDifference(excelRowHeader,meeshoPaymentExcelRowTypeFour))
  // console.log("type5",arrayDifference(excelRowHeader,meeshoPaymentExcelRowTypeFive))
  if (JSON.stringify([...excelRowHeader]) === JSON.stringify(meeshoPaymentExcelRowTypeOne)) {
    return true;
  }

  if (JSON.stringify([...excelRowHeader]) === JSON.stringify(meeshoPaymentExcelRowTypeTwo)) {
    return true;
  }

  if (JSON.stringify([...excelRowHeader]) === JSON.stringify(meeshoPaymentExcelRowTypeThree)) {
    return true;
  }

  if (JSON.stringify([...excelRowHeader]) === JSON.stringify(meeshoPaymentExcelRowTypeFour)) {
    return true;
  }
  if (JSON.stringify([...excelRowHeader]) === JSON.stringify(meeshoPaymentExcelRowTypeFive)) {
    return true;
  }

  if (JSON.stringify([...excelRowHeader]) === JSON.stringify(meeshoPaymentExcelRowTypeSix)) {
    return true;
  }

  return false;
};
