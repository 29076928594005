import React from 'react';

import {
    Page
} from "@react-pdf/renderer";

import {
    Container,
    Grid
} from '@mui/material';

import HeaderBreadcrumbs from "../../../components/HeaderBreadcrumbs";
import useSettings from "../../../hooks/useSettings";
import AutoTicketDetails from "./autoTicketDetails";
import urlConstants from "../../../routes/urlConstants";

export default function AutoTicket() {
    const {themeStretch} = useSettings();

    return (
        <Page title="MeeshoClaimIssue">
            <Container maxWidth={themeStretch ? false : 'xl'}>
                <HeaderBreadcrumbs
                    heading="Claim Issue"
                    links={[
                        {name: 'Dashboard', href: urlConstants.HOME_PAGE},
                        {name: 'Claim Issue', href: urlConstants.MEESHO_CLAIM_ISSUE_PAGE},
                        {name: 'Auto Ticket'},
                    ]}
                />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <AutoTicketDetails/>
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
}