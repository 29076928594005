/* eslint-disable */
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import axios from 'axios';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import DataGridTable from '../../../components/Common/DataGridTable';
import Iconify from '../../../components/Iconify';
import useAuth from '../../../hooks/useAuth';
import { CreatePostAPICall, V2Management } from '../../../utils/apis';

export default function Coupons() {
  const { user } = useAuth();

  // coupon type options
  const couponTypes = [
    {
      value: 'PERCENTAGE_OFF',
      label: 'PERCENTAGE OFF',
    },

    {
      value: 'FLAT_OFF',
      label: 'FLAT OFF',
    },
  ];

  // offer type options
  const offerTypeOption = [
    {
      value: 'all',
      label: 'All',
    },

    {
      value: '2250',
      label: '2250',
    },

    {
      value: '7500',
      label: '7500',
    },

    {
      value: '12500',
      label: '12500',
    },

    {
      value: '18750',
      label: '18750',
    },

    {
      value: '25000',
      label: '25000',
    },

    {
      value: '62500',
      label: '62500',
    },

    {
      value: '125000',
      label: '125000',
    },

    {
      value: '250000',
      label: '250000',
    },
  ];

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 175,
        width: 250,
      },
    },
  };

  // coupon table columns
  const CouponsColumns = [
    {
      accessorKey: 'couponCode',
      header: 'Coupon Code',
      size: 20,
    },

    {
      accessorKey: 'amountOrPercentage',
      header: 'Amount Or Percentage',
      size: 20,
    },

    {
      accessorKey: 'offerType',
      header: 'Offer Type',
      size: 20,
      Cell: ({ cell }) =>
        cell.row.original?.offerType === 'PERCENTAGE_OFF' ? <div>PERCENTAGE OFF</div> : <div>FLAT OFF</div>,
    },

    {
      accessorKey: 'OfferOn',
      header: 'Offer On',
      size: 20,
      Cell: ({ cell }) =>
        cell.row.original?.OfferOn === 'all' ? <div>All</div> : <div>{cell.row.original?.OfferOn}</div>,
    },

    {
      accessorKey: 'expiryDateTime',
      header: 'Expiry Date',
      size: 20,
      Cell: (cell) => moment(cell.row.original?.expiryDateTime).format('DD-MM-YYYY HH:mm'),
    },
    {
      accessorKey: 'couponStatus',
      header: 'Coupon Status',
      size: 20,
      Cell: ({ cell }) =>
        cell.row.original?.couponStatus === 'Live' ? (
          <Button variant="outlined" color="success">
            {cell.row.original?.couponStatus}
          </Button>
        ) : (
          <Button variant="outlined" color="error">
            {cell.row.original?.couponStatus}
          </Button>
        ),
    },

    {
      accessorKey: 'action',
      header: 'Action',
      size: 10,
      Cell: ({ cell }) => (
        <IconButton
          onClick={async () => {
            await createOrDeleteCouponsCode(cell.row.original?.couponCode, cell.row.original?.sort_key, false);
          }}
        >
          <Iconify icon={'mingcute:delete-2-line'} height={24} />
        </IconButton>
      ),
    },
  ];

  const { enqueueSnackbar } = useSnackbar();
  const [couponsListData, setCouponsListData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [selectCouponType, setSelectCouponType] = useState('PERCENTAGE_OFF');
  const [couponCode, setCouponCode] = useState('');
  const [expiryDate, setExpiryDate] = useState(new Date());
  const [couponValue, setCouponValue] = useState('');
  const [selectOfferType, setSelectOfferType] = useState('all');

  // reset all fields after create coupon api call
  const resetAllFields = () => {
    setSelectCouponType('PERCENTAGE_OFF');
    setCouponCode('');
    setExpiryDate(new Date());
    setCouponValue('');
    setSelectOfferType('all');
  };

  // create or delete api call
  const createOrDeleteCouponsCode = async (couponCode, offerDate, isCouponAdd = true) => {
    const userId = localStorage.getItem('userId');
    const userMobileNumber = localStorage.getItem('userMobileNumber');
    const payload = {
      panel: 'admin',
      subPanel: 'bmUsers',
      username: user.email,
      userId,
      couponCode,
    };

    if (isCouponAdd) {
      payload.actionType = 'createCoupon';
      payload.mobileNo = userMobileNumber;
      payload.offerType = selectCouponType;
      payload.expiryDateTime = offerDate.toISOString();
      payload.rechargeType = 0;
      payload.amountOrPercentage = couponValue;
      payload.OfferOn = selectOfferType;
      payload.noOfCoupon = '1';
      payload.listOfCoupon = `${couponValue}${
        selectCouponType === 'PERCENTAGE_OFF' ? '%' : '₹'
      } ${selectCouponType} On ${selectOfferType} Order Package`;
    } else {
      const newOfferDate = offerDate.split('#');
      payload.actionType = 'deleteCoupon';
      payload.offerDate = newOfferDate[2];
    }

    const response = await CreatePostAPICall(enqueueSnackbar, V2Management, payload, 'Success');
    if (response) {
      resetAllFields();
      getCouponsListData();
    }
  };

  // get all coupon code api call
  const getCouponsListData = async () => {
    setDataLoading(true);
    const body = {
      actionType: 'getCoupons',
      username: user.email,
      panel: 'admin',
      subPanel: 'bmUsers',
    };

    const response = await axios.post(V2Management, body);
    if (response) {
      setCouponsListData(response.data.body);
    }

    setDataLoading(false);
  };

  // default api call
  useEffect(() => {
    getCouponsListData();
  }, []);

  //  select coupon type on change
  const handleChangeType = (event) => {
    if (event && event?.target?.value.length !== 0) {
      setSelectCouponType(event.target.value);
    } else {
      setSelectCouponType('');
    }
  };

  //  select offer type on change
  const handleChangeOfferType = (event) => {
    if (event && event?.target?.value.length !== 0) {
      setSelectOfferType(event.target.value);
    } else {
      setSelectOfferType('');
    }
  };

  // select expiry date on change
  const onChangeExpiryDate = (dateString) => {
    setExpiryDate(dateString);
  };

  // enter percentage or fix amount on change
  const onChangeCouponValue = (value) => {
    if (selectCouponType === 'PERCENTAGE_OFF') {
      if (Number(value) >= 0 && Number(value) <= 100) {
        setCouponValue(value);
      }
    } else {
      setCouponValue(value);
    }
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item lg={3} md={6} sm={12}>
          <TextField
            style={{ width: '100%' }}
            id="outlined-basic"
            label="Coupon Code"
            type="text"
            variant="outlined"
            value={couponCode}
            onChange={(event) => {
              setCouponCode(event.target.value);
            }}
          />
        </Grid>

        <Grid item lg={3} md={6} sm={12}>
          <FormControl required sx={{ width: '100%' }}>
            <InputLabel>Select Coupon Type</InputLabel>
            <Select
              value={selectCouponType}
              fullWidth
              placeholder={'Select Coupon Type'}
              onChange={handleChangeType}
              MenuProps={MenuProps}
              input={<OutlinedInput label="Select Coupon Type" />}
            >
              {couponTypes.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item lg={3} md={6} sm={12}>
          <TextField
            style={{ width: '100%' }}
            id="outlined-basic"
            label={selectCouponType === 'PERCENTAGE_OFF' ? 'Percentage' : 'Amount'}
            type="number"
            variant="outlined"
            value={couponValue}
            onChange={(event) => {
              onChangeCouponValue(event.target.value);
            }}
          />
        </Grid>

        <Grid item lg={3} md={6} sm={12}>
          <FormControl required sx={{ width: '100%' }}>
            <InputLabel>Select Offer Type</InputLabel>
            <Select
              value={selectOfferType}
              fullWidth
              placeholder={'Select Offer Type'}
              onChange={handleChangeOfferType}
              MenuProps={MenuProps}
              input={<OutlinedInput label="Select Offer Type" />}
            >
              {offerTypeOption.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item lg={3} md={6} sm={12}>
          <FormControl fullWidth>
            <DateTimePicker
              label="Expiry Date & Time"
              id="ExpiryDate&Time"
              format="dd/MM/yyyy hh:mm a"
              value={expiryDate}
              onChange={onChangeExpiryDate}
              renderInput={(params) => <TextField {...params} />}
            />
          </FormControl>
        </Grid>
        <Grid item lg={3} md={6} sm={12}>
          <Button
            variant="contained"
            disabled={couponCode.length === 0 || !expiryDate || couponValue.length === 0}
            onClick={() => createOrDeleteCouponsCode(couponCode, expiryDate)}
          >
            Add Coupon
          </Button>
        </Grid>
      </Grid>

      <DataGridTable columns={CouponsColumns} data={couponsListData} sorting isLoading={dataLoading} />
    </>
  );
}
