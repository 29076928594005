/* eslint-disable */
import {
    Autocomplete,
    Box,
    Button,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Radio,
    RadioGroup,
    Select,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {
    CapitalizeFirstLetter,
    getAccountsData,
    getAccountsDataByType,
    isSuperAdminEnvironment, setAccountSelectorData
} from '../../../utils/utilities';
import {useSnackbar} from 'notistack';
import useAuth from '../../../hooks/useAuth';
import {CreatePostAPICall, MyLinkedAccount, V2Management} from '../../../utils/apis';
import {DatePicker} from 'antd';
import ReactJson from 'react-json-view';
import {LoadingButton} from "@mui/lab";
import OpenAdminCommonDialog from "./CommonDialog";
import CommonDialog from "./CommonDialog";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import AccountSelector from "../../../components/Common/AccountSelector";

const AdminTools = ({userDataAll = [], oldAccountToTD = ''}) => {
    const {user} = useAuth();
    const localUser = window.localStorage.getItem('userEmailId');
    console.log("localUser",localUser)
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 300,
                width: 250,
            },
        },
    };

    const selectToReportFoundTypeOptions = ['PENDI_Not_Rec', 'DELI_Not_Rec', 'DPWU_Received', 'PAYMENT_NOT_RECEIVED'];

    const monthList = [
        {value: '01', name: '1. January'},
        {value: '02', name: '2. February'},
        {value: '03', name: '3. March'},
        {value: '04', name: '4. April'},
        {value: '05', name: '5. May'},
        {value: '06', name: '6. June'},
        {value: '07', name: '7. July'},
        {value: '08', name: '8. August'},
        {value: '09', name: '9. September'},
        {value: '10', name: '10. October'},
        {value: '11', name: '11. November'},
        {value: '12', name: '12. December'},
    ];

    const fetchTypeList = [
        {value: 'rts', name: 'RTS'},
        {value: 'return', name: 'Return'},
        {value: 'cancel', name: 'Cancel'},
        {value: 'PAYMENT_PAID', name: 'Payment Paid'},
    ];

    const {enqueueSnackbar} = useSnackbar();

    const [meeshoAc, setMeeshoAc] = useState([]);
    const [flipkartAc, setFlipkartAc] = useState([]);
    const [allAc, setAllAc] = useState([]);
    const [allAcToTD, setAllAcToTD] = useState([]);
    const [selectToReportFoundType, setSelectToReportFoundType] = useState([]);
    const [selectedAcMgt, setSelectedAcMgt] = useState({});

    const [selectedSubAc, setSelectedSubAc] = useState({});
    const [acMgtActionType, setAcMgtActionType] = useState(null);
    const [newAccountToTD, setNewAccountToTD] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState([]);
    const [selectedTimerType, setSelectedTimerType] = useState([]);
    const [existingTimeSchedules, setExistingTimeSchedules] = useState([]);
    const [selectedTime, setSelectedTime] = useState('07:30');
    const [selectedYear, setSelectedYear] = useState([]);
    const [yearList, setYearList] = useState([]);
    const [isCommonOpen, setIsCommonOpen] = useState(false);
    const [isCommonOpen2, setIsCommonOpen2] = useState(false);
    const [closeOnSubmit, setCloseOnSubmit] = useState(true);
    const [commonTitle, setCommonTitle] = useState('');
    const [commonBtnName, setCommonBtnName] = useState('');
    const [actionType, setActionType] = useState('');



    const [commonFunction, setCommonFunction] = useState(
        "default"
    );
    const [commonContent, setCommonContent] = useState(() => () => {
    });
    // useEffect(() => {
    //     console.log("selectedAcMgt",selectedAcMgt)
    // }, [selectedAcMgt]);


    useEffect(async () => {
        const accountData = await getAccountsData();
        const tempAcMeesho = [];
        const tempAcFlipkart = [];
        accountData.forEach((item) => {
            if (item.AcType === 'meesho') {
                tempAcMeesho.push({
                    value: item.Account.SupplierId,
                    label: `${CapitalizeFirstLetter(item.AcType)} - ${item.Account.Email} - ${item.Account.Name}`,
                    AcType: item.AcType,
                    Email: item.Account.Email,
                    Name: item.Account.Name,
                });
            }
            if (item.AcType === 'flipkart') {
                tempAcFlipkart.push({
                    value: item.Account.SupplierId,
                    label: `${CapitalizeFirstLetter(item.AcType)} - ${item.Account.Email} - ${item.Account.Name}`,
                    AcType: item.AcType,
                    Email: item.Account.Email,
                    Name: item.Account.Name,
                });
            }
        });
        const tempAllac = [];
        accountData.forEach((item) => {
            tempAllac.push({
                value: item.Account.SupplierId,
                label: `${CapitalizeFirstLetter(item.AcType)} - ${item.Account.Email} - ${item.Account.Name} - ${
                    item.Account.SupplierId
                }`,
                AcType: item.AcType,
                Email: item.Account.Email,
                Name: item.Account.Name,
                SellerId: item.Account.SellerId,
                LocationName: item.Account.LocationName,
            });
        });
        setAllAc(tempAllac);
        setFlipkartAc(tempAcFlipkart)
        setMeeshoAc(tempAcMeesho);
    }, []);

    useEffect(() => {
        const years = [];
        const earliestYear = 2020;
        let currentYear = new Date().getFullYear();

        while (currentYear >= earliestYear) {
            years.push({value: currentYear, name: currentYear});
            currentYear -= 1;
        }
        setYearList(years);
    }, []);
    const selectStateOptionsArray = [
        'Others',
        'Andhra Pradesh',
        'Gujarat',
        'Andaman & Nicobar Island',
        'Telangana',
        'Arunanchal Pradesh',
        'Assam',
        'Uttar Pradesh',
        'Karnataka',
        'Uttarakhand',
        'Bihar',
        'Chhattisgarh',
        'Chandigarh',
        'Tamil Nadu',
        'Madhya Pradesh',
        'Rajasthan',
        'Maharashtra',
        'Dadara & Nagar Haveli',
        'Daman & Diu',
        'Delhi',
        'Goa',
        'Jharkhand',
        'Odisha',
        'Haryana',
        'Himachal Pradesh',
        'Jammu & Kashmir',
        'Kerala',
        'West Bengal',
        'Manipur',
        'Meghalaya',
        'Mizoram',
        'Punjab',
        'Nagaland',
        'Puducherry',
        'Sikkim',
        'Tripura',
    ];

    selectStateOptionsArray.sort((a, b) => {
        const textA = a.toUpperCase();
        const textB = b.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
    });

    const selectStateOptions = [];

    selectStateOptionsArray.forEach((value) => {
        selectStateOptions.push({
            value,
            label: value,
        });
    });

    const syncMeeshoInventory = async () => {
        const body = {
            username: user?.email ? user.email : localUser,
            tpEmailId: selectedAcMgt.Email,
            subAccountId: selectedAcMgt.value,
            cName: selectedAcMgt.Name,
            acType: 'meesho',
            actionType: 'skuSync',
            panel: 'admin',
            subPanel: 'adminTools',
        };
        const response = await CreatePostAPICall(enqueueSnackbar, V2Management, body);
        if (response) {
            enqueueSnackbar(response.data.body);
        }
    };



    const onDeactivateActivate = async () => {
        const body = {
            username: user?.email ? user.email : localUser,
            tpEmailId: selectedAcMgt.Email,
            subAccountId: selectedAcMgt.value,
            acType: selectedAcMgt.AcType,
            actionType: 'updateAccountStatus',
            updatedStatus: acMgtActionType,
            panel: 'admin',
            subPanel: 'adminTools',
        };
        const response = await CreatePostAPICall(enqueueSnackbar, V2Management, body);
        if (response) {
            enqueueSnackbar(`Account ${acMgtActionType} Successfully`);
        }
    };



    const removePaymentData = async () => {
        const body = {
            username: user?.email ? user.email : localUser,
            tpEmailId: selectedAcMgt.Email,
            subAccountId: selectedAcMgt.value,
            acType: selectedAcMgt.AcType,
            actionType: 'deletePaymentData',
            month: `${selectedYear}-${selectedMonth}`,
            panel: 'admin',
            subPanel: 'adminTools',
        };
        const response = await CreatePostAPICall(enqueueSnackbar, V2Management, body);
        if (response) {
            enqueueSnackbar(response.data.body);
        }
    };

    // setData To TransferData Select Account
    const setLocalStorageDataTransfer = async (accountName) => {
        setNewAccountToTD(accountName);
        // setFetchOrderData([]);
        const response = await CreatePostAPICall(enqueueSnackbar, MyLinkedAccount, {
            username: accountName,
            actionType: 'getAll',
        });
        if (response && response.data && response.data.body && response.data.statusCode === 200) {
            const objData = [];
            response.data.body.forEach((item) => {
                const cell = item;
                const newData = {};
                newData.Account = {
                    Name: cell.Name,
                    Email: cell.Email,
                    SupplierId: cell.SupplierId,
                };
                newData.no = cell.no;
                newData.AcType = cell.AcType;
                objData.push(newData);
            });
            const allac = [];
            objData.forEach((item) => {
                if (item.AcType === selectedSubAc.AcType) {
                    allac.push({
                        value: item.Account.SupplierId,
                        label: `${CapitalizeFirstLetter(item.AcType)} - ${item.Account.Email} - ${item.Account.Name}`,
                        AcType: item.AcType,
                        Email: item.Account.Email,
                    });
                }
            });
            setAllAcToTD(allac);
        }
    };

    const addTimerAPICall = async () => {
        for (let i = 0; i < selectedAcMgt.length; i++) {
            const body = {
                actionType: 'manageSchedulers',
                action: 'set',
                username: user?.email ? user.email : localUser,
                acType: selectedAcMgt[i].AcType,
                tpEmailId: selectedAcMgt[i].Email,
                subAccountId: selectedAcMgt[i].value,
                cName: selectedAcMgt[i].Name,
                fetchType: selectedTimerType,
                hour: selectedTime.split(':')[0],
                minute: selectedTime.split(':')[1],
            };
            manageScheduler(body);
        }
    };

    const manageScheduler = async (body) => {
        const response = await CreatePostAPICall(enqueueSnackbar, MyLinkedAccount, body, 'Record inserted successfully.');
        if (response && body.action === 'get') {
            const newArray = [];
            response.data.body.forEach((item) => {
                const object = {
                    type: item.Type,
                    time: `${item.Hour}:${item.Minute}`,
                    index: item.Index,
                    action: 'delete',
                };
                newArray.push(object);
            });
            setExistingTimeSchedules(newArray);
        }
    };


    const removeToReportFound = async () => {
        const Email = [];
        const SupId = [];
        const AcType = [];
        selectedAcMgt.forEach((data) => {
            Email.push(data.Email);
            SupId.push(data.value);
            AcType.push(data.AcType);
        });
        const body = {
            username: user?.email ? user.email : localUser,
            ToReportFound: selectToReportFoundType,
            panel: 'admin',
            subPanel: 'adminTools',
            actionType: 'removeToReportFound',
            tpEmailIds: Email,
            subAccountIds: SupId,
            acTypes: AcType,
        };

        const response = await CreatePostAPICall(enqueueSnackbar, V2Management, body);
        if (response) {
            enqueueSnackbar(response.data.body);
        }
    };

    const transferSkuV1ToV2 = async () => {
        const body = {
            username: user?.email ? user.email : localUser,
            panel: 'admin',
            subPanel: 'adminTools',
            actionType: 'skuDataV1ToV2',
            action: 'transferSelfCost',
            tpEmailId: selectedAcMgt.Email,
            subAccountId: selectedAcMgt.value,
            acType: selectedAcMgt.AcType,
        };
        const response = await CreatePostAPICall(enqueueSnackbar, V2Management, body);
        if (response) {
            enqueueSnackbar(response.data.body);
        }
    };

    const deleteSkuV1 = async () => {
        const body = {
            username: user?.email ? user.email : localUser,
            panel: 'admin',
            subPanel: 'adminTools',
            actionType: 'skuDataV1ToV2',
            action: 'delete',
            tpEmailId: selectedAcMgt.Email,
            subAccountId: selectedAcMgt.value,
            acType: selectedAcMgt.AcType,
        };
        const response = await CreatePostAPICall(enqueueSnackbar, V2Management, body);
        if (response) {
            enqueueSnackbar(response.data.body);
        }
    };


    const removeAdsCost = async () => {
        // console.log("selectedAcMgt removeAdsCost",selectedAcMgt)
        const body = {
            username: user?.email ? user.email : localUser,
            tpEmailId: selectedAcMgt.Email,
            subAccountId: selectedAcMgt.value,
            acType: selectedAcMgt.AcType,
            actionType: 'deleteAdsCost',
            month: `${selectedYear}-${selectedMonth}`,
            panel: 'admin',
            subPanel: 'adminTools',
        };
        const response = await CreatePostAPICall(enqueueSnackbar, V2Management, body);
        if (response) {
            enqueueSnackbar(response.data.body);
        }
    };

    const commonFunctionMapping = {
        'syncMeeshoInventory': syncMeeshoInventory,
        'removeAdsCost': removeAdsCost,
        'removePaymentData': removePaymentData,
        'addTimerAPICall':addTimerAPICall,
        'removeToReportFound':removeToReportFound,
        'transferSkuV1ToV2':transferSkuV1ToV2,
        'deleteSkuV1':deleteSkuV1,
        "default": () => {}
    }
    return (
        <>
            <CommonDialog title={actionType} actionType={actionType} userDataAll={userDataAll} oldAccountToTD={oldAccountToTD} btnName={commonBtnName} close={() => {
                setIsCommonOpen2(false)
            }} closeOnSubmit={closeOnSubmit} allAc={allAc} open={isCommonOpen2} meeshoAcs={meeshoAc}/>

            <Dialog
                fullWidth
                maxWidth={false}
                open={isCommonOpen}
                onClose={() => {
                    setIsCommonOpen(false)
                }}
                PaperProps={{
                    sx: {maxWidth: 720},
                }}
            >
                <DialogTitle style={{marginBottom: "1rem"}}>{commonTitle}</DialogTitle>

                <DialogContent>
                    {commonContent}
                </DialogContent>

                <DialogActions>
                    <Button variant="outlined" onClick={() => {
                        setIsCommonOpen(false)
                    }}>
                        Cancel
                    </Button>
                    <LoadingButton type="submit" variant="contained" onClick={() => {
                        if (closeOnSubmit) {
                            setIsCommonOpen(false)
                        }
                        const commFunction = commonFunctionMapping[commonFunction]
                        commFunction()
                    }}>
                        {commonBtnName}
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <Divider sx={{p: 1}}/>

            <Box
                rowGap={2}
                columnGap={2}
                display={'grid'}
                style={{margin: "1rem"}}
                paddingTop={0}
                gridTemplateColumns={{
                    xs: 'repeat(3, 1fr)',
                    sm: 'repeat(4, 1fr)',
                }}
            >

                <LoadingButton variant={'outlined'} onClick={() => {
                    setIsCommonOpen(true)
                    setCommonFunction("syncMeeshoInventory")
                    setCommonTitle('Meesho Inventory Sync')
                    setCommonContent(() => (
                        <Box
                            sx={{
                                display: 'grid',
                                rowGap: 2,
                                p: 1.5,
                            }}
                        >
                            <Autocomplete
                                disableClearable
                                id="meesho-inventory-sync"
                                onChange={(event, value) => {
                                    setSelectedAcMgt(value);
                                }}
                                options={meeshoAc.map((option) => option)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Select Meesho Account*"
                                        InputProps={{
                                            ...params.InputProps,
                                            type: 'search',
                                        }}
                                    />
                                )}
                            />
                        </Box>))
                    setCommonBtnName('Submit')
                }}>Meesho Inventory Sync</LoadingButton>

                <LoadingButton variant={'outlined'} onClick={() => {
                    setIsCommonOpen2(true)
                    setCommonTitle('Extract To Report')
                    setActionType('Extract To Report')
                    setCommonBtnName('Submit')
                }}>Extract To Report</LoadingButton>

                {isSuperAdminEnvironment && (
                    <LoadingButton variant={'outlined'} onClick={() => {
                        setIsCommonOpen2(true)
                        setCloseOnSubmit(false)
                        setActionType('Account Management')
                        setCommonTitle('Account Management')
                        setCommonBtnName('Submit')
                    }}>Account Management</LoadingButton>)}

                {/*{isSuperAdminEnvironment && (*/}
                {/*    <LoadingButton variant={'outlined'} onClick={() => {*/}
                {/*        setIsCommonOpen(true)*/}
                {/*        setCommonFunction(() => onDeactivateActivate)*/}
                {/*        setCommonTitle('Account Active / Deactive')*/}
                {/*        setCommonContent(() => <Box*/}
                {/*            sx={{*/}
                {/*                display: 'grid',*/}
                {/*                rowGap: 2,*/}
                {/*                p: 1.5,*/}
                {/*            }}*/}
                {/*        >*/}
                {/*            <Autocomplete*/}
                {/*                id="Select-Account"*/}
                {/*                disableClearable*/}
                {/*                onChange={(event, value) => {*/}
                {/*                    setSelectedAcMgt(value);*/}
                {/*                }}*/}
                {/*                options={allAc.map((option) => option)}*/}
                {/*                renderInput={(params) => (*/}
                {/*                    <TextField*/}
                {/*                        {...params}*/}
                {/*                        label="Select Account*"*/}
                {/*                        InputProps={{*/}
                {/*                            ...params.InputProps,*/}
                {/*                            type: 'search',*/}
                {/*                        }}*/}
                {/*                    />*/}
                {/*                )}*/}
                {/*            />*/}

                {/*            <RadioGroup*/}
                {/*                aria-labelledby="demo-radio-buttons-group-label"*/}
                {/*                defaultValue="female"*/}
                {/*                name="radio-buttons-group"*/}
                {/*            >*/}
                {/*                <div style={{display: 'flex'}}>*/}
                {/*                    <FormControlLabel*/}
                {/*                        value="DEACTIVATE"*/}
                {/*                        control={<Radio/>}*/}
                {/*                        label="Deactive"*/}
                {/*                        onChange={() => {*/}
                {/*                            setAcMgtActionType('DEACTIVATE');*/}
                {/*                        }}*/}
                {/*                    />*/}
                {/*                    <FormControlLabel*/}
                {/*                        value="ACTIVATE"*/}
                {/*                        control={<Radio/>}*/}
                {/*                        label="Active"*/}
                {/*                        onChange={() => {*/}
                {/*                            setAcMgtActionType('ACTIVATE');*/}
                {/*                        }}*/}
                {/*                    />*/}
                {/*                </div>*/}
                {/*            </RadioGroup>*/}

                {/*            /!*<div style={{width: '100%', textAlign: 'end'}}>*!/*/}
                {/*            /!*    <Button*!/*/}
                {/*            /!*        disabled={!selectedAcMgt || !acMgtActionType}*!/*/}
                {/*            /!*        variant="contained"*!/*/}
                {/*            /!*        onClick={() => onDeactivateActivate()}*!/*/}
                {/*            /!*    >*!/*/}
                {/*            /!*        Submit*!/*/}
                {/*            /!*    </Button>*!/*/}
                {/*            /!*</div>*!/*/}
                {/*        </Box>)*/}
                {/*        setCommonBtnName('Submit')*/}
                {/*    }}>Account Active / Deactive</LoadingButton>)}*/}

                {isSuperAdminEnvironment && (
                    <LoadingButton variant={'outlined'} onClick={() => {
                        setIsCommonOpen2(true)
                        setCloseOnSubmit(false)
                        setActionType('Transfer Data')
                        setCommonTitle('Transfer Data')
                        setCommonBtnName('Transfer')
                    }}>Transfer Data</LoadingButton>)}

                <LoadingButton variant={'outlined'} onClick={() => {
                    setIsCommonOpen(true)
                    setCommonFunction("removeAdsCost")
                    setCommonTitle('Meesho AdCost Delete')
                    setCommonContent(() => (
                        <Box
                            sx={{
                                display: 'grid',
                                rowGap: 2,
                                p: 1.5,
                            }}
                        >
                            <Autocomplete
                                disableClearable
                                id="meesho-adCost-delete"
                                onChange={(event, value) => {
                                    setSelectedAcMgt(value);
                                }}
                                options={meeshoAc.map((option) => option)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={`Select Meesho Account*`}
                                        InputProps={{
                                            ...params.InputProps,
                                            type: 'search',
                                        }}
                                    />
                                )}
                            />

                            <FormControl required sx={{width: '100%'}}>
                                <InputLabel>Select Year</InputLabel>
                                <Select
                                    fullWidth
                                    defaultValue=""
                                    placeholder={'Select Year'}
                                    onChange={(event) => {
                                        setSelectedYear(event.target.value);
                                    }}
                                    input={<OutlinedInput label="Select Year"/>}
                                >
                                    {yearList.map((option, index) => (
                                        <MenuItem key={index} value={option.value}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl required sx={{width: '100%'}}>
                                <InputLabel required>Select Month</InputLabel>
                                <Select
                                    fullWidth
                                    defaultValue=""
                                    placeholder={'Select Month'}
                                    onChange={(event) => {
                                        setSelectedMonth(event.target.value);
                                    }}
                                    MenuProps={MenuProps}
                                    input={<OutlinedInput label="Select Month"/>}
                                >
                                    {monthList.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            {/*<div style={{width: '100%', textAlign: 'end'}}>*/}
                            {/*    <Button variant="contained" onClick={removeAdsCost}>*/}
                            {/*        Submit*/}
                            {/*    </Button>*/}
                            {/*</div>*/}
                        </Box>))
                    setCommonBtnName('Submit')
                }}>Meesho AdCost Delete</LoadingButton>
                <LoadingButton variant={'outlined'} onClick={() => {
                    setIsCommonOpen(true)
                    setCommonFunction("removeAdsCost")
                    setCommonTitle('Flipkart AdCost Delete')
                    setCommonContent(() => (
                        <Box
                            sx={{
                                display: 'grid',
                                rowGap: 2,
                                p: 1.5,
                            }}
                        >
                            <Autocomplete
                                disableClearable
                                id="meesho-adCost-delete"
                                onChange={(event, value) => {
                                    setSelectedAcMgt(value);
                                }}
                                options={flipkartAc.map((option) => option)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={`Select Flipkart Account*`}
                                        InputProps={{
                                            ...params.InputProps,
                                            type: 'search',
                                        }}
                                    />
                                )}
                            />

                            <FormControl required sx={{width: '100%'}}>
                                <InputLabel>Select Year</InputLabel>
                                <Select
                                    fullWidth
                                    defaultValue=""
                                    placeholder={'Select Year'}
                                    onChange={(event) => {
                                        setSelectedYear(event.target.value);
                                    }}
                                    input={<OutlinedInput label="Select Year"/>}
                                >
                                    {yearList.map((option, index) => (
                                        <MenuItem key={index} value={option.value}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl required sx={{width: '100%'}}>
                                <InputLabel required>Select Month</InputLabel>
                                <Select
                                    fullWidth
                                    defaultValue=""
                                    placeholder={'Select Month'}
                                    onChange={(event) => {
                                        setSelectedMonth(event.target.value);
                                    }}
                                    MenuProps={MenuProps}
                                    input={<OutlinedInput label="Select Month"/>}
                                >
                                    {monthList.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            {/*<div style={{width: '100%', textAlign: 'end'}}>*/}
                            {/*    <Button variant="contained" onClick={removeAdsCost}>*/}
                            {/*        Submit*/}
                            {/*    </Button>*/}
                            {/*</div>*/}
                        </Box>))
                    setCommonBtnName('Submit')
                }}>Flipkart AdCost Delete</LoadingButton>

                <LoadingButton variant={'outlined'} onClick={() => {
                    setIsCommonOpen(true)
                    setCommonFunction("removePaymentData")
                    setCommonTitle('Delete Payment Data')
                    setCommonContent(() => (
                        <Box
                            sx={{
                                display: 'grid',
                                rowGap: 2,
                                p: 1.5,
                            }}
                        >
                            <Autocomplete
                                disableClearable
                                id="all-payment-delete"
                                onChange={(event, value) => {
                                    setSelectedAcMgt(value);
                                }}
                                options={allAc.map((option) => option)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Select Account*"
                                        InputProps={{
                                            ...params.InputProps,
                                            type: 'search',
                                        }}
                                    />
                                )}
                            />

                            <FormControl required sx={{width: '100%'}}>
                                <InputLabel>Select Year</InputLabel>
                                <Select
                                    fullWidth
                                    defaultValue=""
                                    placeholder={'Select Year'}
                                    onChange={(event) => {
                                        setSelectedYear(event.target.value);
                                    }}
                                    input={<OutlinedInput label="Select Year"/>}
                                >
                                    {yearList.map((option, index) => (
                                        <MenuItem key={index} value={option.value}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl required sx={{width: '100%'}}>
                                <InputLabel required>Select Month</InputLabel>
                                <Select
                                    fullWidth
                                    defaultValue=""
                                    placeholder={'Select Month'}
                                    onChange={(event) => {
                                        setSelectedMonth(event.target.value);
                                    }}
                                    MenuProps={MenuProps}
                                    input={<OutlinedInput label="Select Month"/>}
                                >
                                    {monthList.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            {/*<div style={{width: '100%', textAlign: 'end'}}>*/}
                            {/*    <Button variant="contained" onClick={removePaymentData}>*/}
                            {/*        Submit*/}
                            {/*    </Button>*/}
                            {/*</div>*/}
                        </Box>))
                    setCommonBtnName('Submit')
                }}>Delete Payment Data</LoadingButton>

                <LoadingButton variant={'outlined'} onClick={() => {
                    setIsCommonOpen(true)
                    setCommonFunction("addTimerAPICall")
                    setCommonTitle('Set Timer For Multiple Account')
                    setCommonContent(() => (
                        <Box
                            sx={{
                                display: 'grid',
                                rowGap: 2,
                                p: 1.5,
                            }}
                        >
                            <Autocomplete
                                id="Select-Account-Timer"
                                multiple
                                onChange={(event, value) => {
                                    setSelectedAcMgt(value);
                                }}
                                options={allAc.map((option) => option)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Select Account*"
                                        InputProps={{
                                            ...params.InputProps,
                                            type: 'search',
                                        }}
                                    />
                                )}
                            />

                            <FormControl required sx={{width: '100%'}}>
                                <InputLabel>Select Type</InputLabel>
                                <Select
                                    fullWidth
                                    defaultValue=""
                                    placeholder={'Select Type'}
                                    onChange={(event) => {
                                        setSelectedTimerType(event.target.value);
                                    }}
                                    input={<OutlinedInput label="Select Type"/>}
                                >
                                    {fetchTypeList.map((option, index) => (
                                        <MenuItem key={index} value={option.value}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <TextField
                                label="Time"
                                type="time"
                                defaultValue="07:30"
                                onChange={(event) => setSelectedTime(event.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />

                            {/*<div style={{width: '100%', textAlign: 'end'}}>*/}
                            {/*    <Button variant="contained" onClick={() => addTimerAPICall()}>*/}
                            {/*        Submit*/}
                            {/*    </Button>*/}
                            {/*</div>*/}
                        </Box>))
                    setCommonBtnName('Submit')
                }}>Set Timer For Multiple Account</LoadingButton>

                <LoadingButton variant={'outlined'} onClick={() => {
                    setActionType('Update Meesho Company Name')
                    setIsCommonOpen2(true)
                    setCommonBtnName('Update')
                    setCommonTitle('Update Meesho Company Name')
                }}>Update Meesho Company Name</LoadingButton>

                <LoadingButton variant={'outlined'} onClick={() => {
                    setIsCommonOpen2(true)
                    setCommonBtnName('Submit')
                    setActionType('State Mapping')
                    setCommonTitle('State Mapping')
                }}>State Mapping</LoadingButton>

                {isSuperAdminEnvironment && (
                    <LoadingButton variant={'outlined'} onClick={() => {
                        setIsCommonOpen(true)
                        setCommonFunction("removeToReportFound")
                        setCommonTitle('Remove To Report Found Data')
                        setCommonContent(() => <Box
                            sx={{
                                display: 'grid',
                                rowGap: 2,
                                p: 1.5,
                            }}
                        >
                            <FormControl sx={{width: '100%'}}>
                                <Autocomplete
                                    id="Select-Account"
                                    multiple
                                    onChange={(event, value) => {
                                        setSelectedAcMgt(value);
                                    }}
                                    options={allAc.map((option) => option)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Select Account*"
                                            InputProps={{
                                                ...params.InputProps,
                                                type: 'search',
                                            }}
                                        />
                                    )}
                                />
                            </FormControl>

                            <FormControl sx={{width: '100%'}}>
                                <InputLabel id="selectToReportFoundType">Select To Report Found Type</InputLabel>
                                <Select
                                    labelId="selectToReportFoundType"
                                    value={selectToReportFoundType || ''}
                                    label="Select To Report Found Type"
                                    onChange={(event) => {
                                        setSelectToReportFoundType(event.target.value);
                                    }}
                                >
                                    {selectToReportFoundTypeOptions.map((name) => (
                                        <MenuItem key={name} value={name}>
                                            {name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            {/*<div style={{width: '100%', textAlign: 'end'}}>*/}
                            {/*    <Button variant="contained" onClick={removeToReportFound}>*/}
                            {/*        Remove Data*/}
                            {/*    </Button>*/}
                            {/*</div>*/}
                        </Box>)
                        setCommonBtnName('Remove Data')
                    }}>Remove To Report Found Data</LoadingButton>)}

                {isSuperAdminEnvironment && (
                    <LoadingButton variant={'outlined'} onClick={() => {
                        setIsCommonOpen(true)
                        setCommonFunction("transferSkuV1ToV2")
                        setCommonTitle('Transfer SKU From V1 To V2')
                        setCommonContent(() => <Box
                            sx={{
                                display: 'grid',
                                rowGap: 2,
                                p: 1.5,
                            }}
                        >
                            <FormControl sx={{width: '100%'}}>
                                <Autocomplete
                                    id="transfer-meesho-SKU-v1-v2"
                                    disableClearable
                                    onChange={(event, value) => {
                                        setSelectedAcMgt(value);
                                    }}
                                    options={meeshoAc.map((option) => option)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Select Meesho Account*"
                                            InputProps={{
                                                ...params.InputProps,
                                                type: 'search',
                                            }}
                                        />
                                    )}
                                />
                            </FormControl>

                            {/*<div style={{width: '100%', textAlign: 'end'}}>*/}
                            {/*    <Button variant="contained" onClick={transferSkuV1ToV2}>*/}
                            {/*        Transfer SKU Data*/}
                            {/*    </Button>*/}
                            {/*</div>*/}
                        </Box>)
                        setCommonBtnName('Transfer SKU Data')
                    }}>Transfer SKU From V1 To V2</LoadingButton>)}

                {isSuperAdminEnvironment && (
                    <LoadingButton variant={'outlined'} onClick={() => {
                        setIsCommonOpen(true)
                        setCommonFunction("deleteSkuV1")
                        setCommonTitle('Delete SKU Of V1')
                        setCommonContent(() => <Box
                            sx={{
                                display: 'grid',
                                rowGap: 2,
                                p: 1.5,
                            }}
                        >
                            <FormControl sx={{width: '100%'}}>
                                <Autocomplete
                                    id="delete-meesho-SKU-v1"
                                    disableClearable
                                    onChange={(event, value) => {
                                        setSelectedAcMgt(value);
                                    }}
                                    options={meeshoAc.map((option) => option)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Select Meesho Account*"
                                            InputProps={{
                                                ...params.InputProps,
                                                type: 'search',
                                            }}
                                        />
                                    )}
                                />
                            </FormControl>

                            {/*<div style={{width: '100%', textAlign: 'end'}}>*/}
                            {/*    <Button variant="contained" onClick={deleteSkuV1}>*/}
                            {/*        Delete SKU Data*/}
                            {/*    </Button>*/}
                            {/*</div>*/}
                        </Box>)
                        setCommonBtnName('Delete SKU Data')
                    }}>Delete SKU Of V1</LoadingButton>)}

                <LoadingButton variant={'outlined'} onClick={() => {
                    setIsCommonOpen2(true)
                    setCloseOnSubmit(false)
                    setActionType('Generate Payload')
                    setCommonTitle('Generate Payload')
                    setCommonBtnName('Generate')
                }}>Generate Payload</LoadingButton>

                {isSuperAdminEnvironment && (
                    <LoadingButton variant={'outlined'} onClick={() => {
                        setIsCommonOpen2(true)
                        setCommonTitle('Update SelfCost')
                        setActionType('Update SelfCost')
                        setCommonBtnName('Update SelfCost')

                    }}>Update SelfCost</LoadingButton>)}

            </Box>

        </>
    );
};

export default AdminTools;
