/* eslint-disable */
import {Container, Divider, Grid, Typography} from '@mui/material';
import React, {useCallback, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import PlatformSelectionBtn from '../../components/Common/PlatFormSelectionBtn';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Page from '../../components/Page';
import useSettings from '../../hooks/useSettings';
import urlConstants from '../../routes/urlConstants';
import AmazonSyncOrder from './components/AmazonSyncOrder';
import MeeshoSyncOrder from './components/MeeshoSyncOrder';
import CitymallSyncOrder from "./components/CitymallSyncOrder";
import FlipkartSyncOrder from './components/FlipkartSyncOrder';
import MyntraSyncOrder from './components/MyntraSyncOrder';
import ShopdeckSyncOrder from './components/ShopdeckSyncOrder';
import GlowroadSyncOrder from './components/GlowroadSyncOrder';
import SnapdealSyncOrder from './components/SnapdealSyncOrder';
import JiomartSyncOrder from './components/JiomartSyncOrder';
import LimeRoadSyncOrder from "./components/LimeroadSyncOrder";
import {ToggleButton, ToggleButtonGroup} from "@mui/lab";
import Label from "../../components/Label";
import Iconify from "../../components/Iconify";
import {alpha, useTheme} from "@mui/material/styles";

export default function SyncOrders() {
    const {themeStretch} = useSettings();
    const theme = useTheme();
    const {syncOrderTab} = useParams();
    const navigate = useNavigate();
    const [platformOptions, setPlatformOptions] = useState([]);
    const [Platform, setPlatform] = useState('');
    const existAcTypes = JSON.parse(window.localStorage.getItem('existingAcType'));
    // console.log(existAcTypes);

    useEffect(() => {
        const tempPlatfrom = [];
        if (existAcTypes.some((e) => e === 'meesho')) {
            tempPlatfrom.push({
                platformName: 'Meesho',
                platformIcon: 'healthicons:m-outline',
                value: 'meesho',
            });
        }

        if (existAcTypes.some((e) => e === 'flipkart')) {
            tempPlatfrom.push({
                platformName: 'Flipkart',
                platformIcon: 'arcticons:flipkart',
                value: 'flipkart',
            });
        }
        if (existAcTypes.some((e) => e === 'glowroad')) {
            tempPlatfrom.push({
                platformName: 'Glowroad',
                platformIcon: 'healthicons:g-outline',
                value: 'glowroad',
            });
        }
        if (existAcTypes.some((e) => e === 'citymall')) {
            tempPlatfrom.push({
                platformName: 'City Mall',
                platformIcon: 'tabler:hexagon-letter-m',
                value: 'citymall',
            });
        }
        if (existAcTypes.some((e) => e === 'shopdeck')) {
            tempPlatfrom.push({
                platformName: 'Shopdeck',
                platformIcon: 'tabler:circle-letter-s',
                value: 'shopdeck',
            });
        }
        if (existAcTypes.some((e) => e === 'snapdeal')) {
            tempPlatfrom.push({
                platformName: 'Snapdeal',
                platformIcon: 'tabler:hexagon-letter-s',
                value: 'snapdeal',
            });
        }
        if (existAcTypes.some((e) => e === 'jiomart')) {
            tempPlatfrom.push({
                platformName: 'JioMart',
                platformIcon: 'arcticons:jiomart',
                value: 'jiomart',
            });
        }
        if (existAcTypes.some((e) => e === 'myntra')) {
            tempPlatfrom.push({
                platformName: 'Myntra',
                platformIcon: 'arcticons:myntra',
                value: 'myntra',
            });
        }
        if (existAcTypes.some((e) => e === 'amazon')) {
            tempPlatfrom.push({
                platformName: 'Amazon',
                platformIcon: 'grommet-icons:amazon',
                value: 'amazon',
            });
        }
        if (existAcTypes.some((e) => e === 'limeroad')) {
            tempPlatfrom.push({
                platformName: 'Limeroad',
                platformIcon: 'grommet-icons:amazon',
                value: 'limeroad',
            });
        }
        setPlatformOptions(tempPlatfrom);
        // console.log('tempPlatfrom', tempPlatfrom);
        if(tempPlatfrom[0]){
            setPlatform(tempPlatfrom[0].value)
        }

    }, []);

    // handle Platform Selection
    const onclickPlatformSelectionBtn = useCallback((event, newPlatFormName) => {
        if (newPlatFormName !== null) {
            setPlatform(newPlatFormName);
        }
    }, []);

    return (
        <Page title="SyncOrders">
            <Container maxWidth={themeStretch ? false : 'xl'}>
                <HeaderBreadcrumbs
                    heading="Sync Orders"
                    links={[{name: 'Dashboard', href: urlConstants.HOME_PAGE}, {name: 'Sync Orders'}]}
                />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <>
                            <Grid container spacing={3} style={{paddingTop: '1.75%', width: '100%'}}>
                                <ToggleButtonGroup
                                    exclusive
                                    value={Platform}
                                    size="medium"
                                    onChange={onclickPlatformSelectionBtn}
                                    sx={{mt: 5, ml: 3, flexWrap: 'wrap'}}
                                >
                                    {platformOptions.map((data, i) => (
                                        <ToggleButton value={data.value} aria-label={data.value}
                                                      key={data.value} color={'primary'}>
                                            <Label variant={'soft'} sx={{backgroundColor: alpha(theme.palette.primary.main, 0.15),}} color={'success'}>
                                                {data.platformName === 'Limeroad' ? <Typography>Lr</Typography> : (
                                                    <Iconify sx={{color: "#7635dc"}} icon={data.platformIcon} width={20} height={20}/>)}

                                            </Label>
                                            &nbsp;&nbsp;{data.platformName}
                                        </ToggleButton>
                                    ))}
                                </ToggleButtonGroup>
                            </Grid>

                            <Divider sx={{p: 1}}/>

                            {Platform === 'meesho' && <MeeshoSyncOrder/>}
                            {Platform === 'flipkart' && <FlipkartSyncOrder/>}
                            {Platform === 'citymall' && <CitymallSyncOrder/>}
                            {Platform === 'glowroad' && <GlowroadSyncOrder/>}
                            {Platform === 'shopdeck' && <ShopdeckSyncOrder/>}
                            {Platform === 'snapdeal' && <SnapdealSyncOrder/>}
                            {Platform === 'jiomart' && <JiomartSyncOrder/>}
                            {Platform === 'myntra' && <MyntraSyncOrder/>}
                            {Platform === 'amazon' && <AmazonSyncOrder/>}
                            {Platform === 'limeroad' && <LimeRoadSyncOrder/>}
                        </>
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
}
