/* eslint-disable */
import { Box, Divider, Grid, Tab, Tabs } from '@mui/material';
import { capitalCase } from 'change-case';
import React from 'react';
import useTabs from '../../../hooks/useTabs';
import { V2Management } from '../../../utils/apis';
import BMUsers from './BMUsers';
import Coupons from './Coupons';

const AdminBMUsers = () => {
  const { currentBMUserTab, onChangeBMUserTab } = useTabs('BM Users');

  const BMUsersTabs = [
    {
      value: 'BM Users',
      component: (
        <BMUsers
          api={V2Management}
          body={{ panel: 'admin', subPanel: 'bmUsers', actionType: 'getAllUsers' }}
          col={'BMUsers'}
          name={'BM Users'}
        />
      ),
    },

    {
      value: 'Coupons',
      component: <Coupons />,
    },
  ];

  return (
    <>
      <Divider sx={{ p: 1 }} />

      <Grid item xs={12} sm={12} md={12}>
        <Tabs
          allowScrollButtonsMobile
          variant="scrollable"
          scrollButtons="auto"
          value={currentBMUserTab}
          onChange={onChangeBMUserTab}
        >
          {BMUsersTabs.map((tab) => (
            <Tab disableRipple key={tab.value} label={capitalCase(tab.value)} icon={tab.icon} value={tab.value} />
          ))}
        </Tabs>

        <Box sx={{ mb: 5 }} />

        {BMUsersTabs.map((tab) => {
          const isMatched = tab.value === currentBMUserTab;
          return isMatched && <Box key={tab.value}>{tab.component}</Box>;
        })}
      </Grid>
    </>
  );
};

export default AdminBMUsers;
