/* eslint-disable */
import { Button } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import xlsx from 'json-as-xlsx';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { CreatePostAPICall, Home, MyLinkedAccount, V2Management } from '../../../../utils/apis';
import { SystemColor } from '../../../../utils/utilities';

const AllUserDetails = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [userDetailsData, setUserDetailsData] = useState([]);
  const [activatedUsers, setActivatedUsers] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);

  const allUsersDataColumns = [
    {
      field: 'Id',
      headerName: 'Id',
      flex: 1,
      minWidth: 50,
    },
    {
      field: 'Email',
      headerName: 'Email',
      flex: 1,
      minWidth: 250,
    },
    {
      field: 'Account_Balance',
      headerName: 'Account Balance',
      flex: 1,
      minWidth: 130,
    },
    {
      field: 'Add_Ons',
      headerName: 'Add Ons',
      flex: 1,
      minWidth: 250,
    },
    {
      field: 'Mobile_No',
      headerName: 'Mobile No',
      flex: 1,
      minWidth: 135,
    },
    {
      field: 'UserStatus',
      headerName: 'UserStatus',
      flex: 1,
      minWidth: 130,
    },
    {
      field: 'userStatus',
      headerName: 'DB UserStatus',
      flex: 1,
      minWidth: 115,
    },
    {
      field: 'Enabled',
      headerName: 'Enabled',
      flex: 1,
      minWidth: 70,
    },
    {
      field: 'WAppMessageEnabled',
      headerName: 'WAppMessageEnabled',
      flex: 1,
      minWidth: 170,
    },
    {
      field: 'dbType',
      headerName: 'dbType',
      flex: 1,
      minWidth: 70,
    },
    {
      field: 'SoftwareVersion',
      headerName: 'SoftwareVersion',
      flex: 1,
      minWidth: 125,
    },
    {
      field: 'UserCreateDate',
      headerName: 'User Created Date',
      flex: 1,
      minWidth: 170,
    },
    {
      field: 'UserLastModifiedDate',
      headerName: 'User Last-Modified Date',
      flex: 1,
      minWidth: 180,
    },
    {
      field: 'Cognito User Actions',
      headerName: 'Cognito User Actions',
      minWidth: 225,
      flex: 1,
      // minWidth: 150,
      renderCell: (params) => (
        <>
          <Button
            variant="outlined"
            onClick={() => {
              makeAccountEnableDisableAllUserStatus('confirmAc', params.row.Email);
            }}
          >
            Confirm
          </Button>
          <Button
            sx={{ marginLeft: '10px', marginRight: '10px' }}
            variant="outlined"
            onClick={() => {
              makeAccountEnableDisableAllUserStatus('enableAc', params.row.Email);
            }}
          >
            Enable
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              makeAccountEnableDisableAllUserStatus('disableAc', params.row.Email);
            }}
          >
            Disable
          </Button>
        </>
      ),
    },
    {
      field: 'Balance_Status',
      headerName: 'Balance Status',
      minWidth: 225,
      flex: 1,
      renderCell: (params) => (
          <><center>{params.formattedValue !== "EXPIRED" || activatedUsers.includes(params.row.Email) ? "ACTIVE" : "EXPIRED"}</center>
            {params.formattedValue === "EXPIRED" && !activatedUsers.includes(params.row.Email) && <Button
                sx={{ marginLeft: '15px' }}
                variant="outlined"
                onClick={() => {
                  console.log("active account")
                  makeAccountEnableDisableAllUserStatus('activate', params.row.Email);
                }}
            >
              ACTIVATE
            </Button>}
          </>
      ),
    },
    {
      field: 'totalCount',
      headerName: 'Total Orders',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'amazonCount',
      headerName: 'Amazon Orders',
      flex: 1,
      minWidth: 120,
    },
    {
      field: 'meeshoCount',
      headerName: 'Meesho Orders',
      flex: 1,
      minWidth: 120,
    },
    {
      field: 'flipkartCount',
      headerName: 'Flipkart Orders',
      flex: 1,
      minWidth: 120,
    },
    {
      field: 'glowroadCount',
      headerName: 'Glowroad Orders',
      flex: 1,
      minWidth: 135,
    },
    // TODO ACCOUNTS Count For Sub ACTYPE
    {
      field: 'totalAcCount',
      headerName: 'Total Accounts',
      flex: 1,
      minWidth: 135,
    },
    {
      field: 'amazonAcCount',
      headerName: 'Amazon Accounts',
      flex: 1,
      minWidth: 135,
    },

    {
      field: 'meeshoAcCount',
      headerName: 'Meesho Accounts',
      flex: 1,
      minWidth: 135,
    },
    {
      field: 'flipkartAcCount',
      headerName: 'Flipkart Accounts',
      flex: 1,
      minWidth: 135,
    },
    {
      field: 'glowroadAcCount',
      headerName: 'Glowroad Accounts',
      flex: 1,
      minWidth: 135,
    },
  ];

  const makeAccountEnableDisableAllUserStatus = async (actionType, Username) => {
    setDataLoading(true);
    const body = {
      username: Username,
      actionType,
      panel: 'admin',
      subPanel: 'adminManagement',
    };
    const response = await CreatePostAPICall(enqueueSnackbar, V2Management, body, true);
    if (response) {
      setDataLoading(false);
      if(actionType === "activate") {
        setActivatedUsers([...activatedUsers, Username])
      }
    }
  };

  const exportToCsvAllUserDetailsData = () => {
    const data = [
      {
        columns: [
          { label: 'Id', value: 'Id' },
          { label: 'Email', value: 'Email' },
          { label: 'Account Balance', value: 'Account_Balance' },
          { label: 'Add Ons', value: 'Add_Ons' },
          { label: 'Mobile No', value: 'Mobile_No' },
          { label: 'Order Date', value: 'order_date' },
          { label: 'userStatus', value: 'userStatus' },
          { label: 'Enabled', value: 'Enabled' },
          { label: 'WAppMessageEnabled', value: 'WAppMessageEnabled' },
          { label: 'Balance_Status', value: 'Balance_Status' },
          { label: 'dbType', value: 'dbType' },
          { label: 'SoftwareVersion', value: 'SoftwareVersion' },
          { label: 'User Create Date', value: 'UserCreateDate' },
          { label: 'Sent Quantity', value: 'UserLastModifiedDate' },
          { label: 'Total Orders', value: 'Total Orders' },
          { label: 'Amazon Orders', value: 'Amazon Orders' },
          { label: 'Meesho Orders', value: 'Meesho Orders' },
          { label: 'Flipkart Orders', value: 'Flipkart Orders' },
          { label: 'Glowroad Orders', value: 'Glowroad Orders' },
          { label: 'Total Accounts', value: 'Total Accounts' },
          { label: 'Amazon Accounts', value: 'Amazon Accounts' },
        ],
        content: userDetailsData,
      },
    ];

    const settings = {
      fileName: `All Users Details`,
      extraLength: 6,
      writeOptions: {},
    };

    xlsx(data, settings);
  };

  const getOrderCount = async (email) => {
    const body = {
      actionType: 'getOrdersCounts',
      action: 'allAccounts',
      username: email,
    };
    const tempObject = {
      amazonCount: 0,
      meeshoCount: 0,
      flipkartCount: 0,
      glowroadCount: 0,
      totalCount: 0,
    };

    const response = await CreatePostAPICall((err)=>{
      // console.log("body",body)
      console.log("err:-",err)
    }, Home, body);
    if (response) {
      if (response?.data?.body?.amazon?.orders_today === 0 || response?.data?.body?.amazon?.orders_today) {
        tempObject.amazonCount = response.data.body.amazon.orders_today;
      }
      if (response?.data?.body?.meesho?.orders_today === 0 || response?.data?.body?.meesho?.orders_today) {
        tempObject.meeshoCount = response.data.body.meesho.orders_today;
      }
      if (response?.data?.body?.flipkart?.orders_today === 0 || response?.data?.body?.flipkart?.orders_today) {
        tempObject.flipkartCount = response.data.body.flipkart.orders_today;
      }
      if (response?.data?.body?.glowroad?.orders_today === 0 || response?.data?.body?.glowroad?.orders_today) {
        tempObject.glowroadCount = response.data.body.glowroad.orders_today;
      }
      tempObject.totalCount =
        tempObject.amazonCount + tempObject.meeshoCount + tempObject.flipkartCount + tempObject.glowroadCount;
    }
    return tempObject;
  };

  const getAccountCount = async (email) => {
    const body = {
      actionType: 'getAll',
      username: email,
    };
    const response = await CreatePostAPICall(enqueueSnackbar, MyLinkedAccount, body);
    if (response) {
      const tempObject = {
        amazonAcCount: 0,
        meeshoAcCount: 0,
        flipkartAcCount: 0,
        glowroadAcCount: 0,
        totalAcCount: 0,
        // totalCount: 0 response.data.body.amazon.orders_today+ response.data.body.meesho.orders_today+ response.data.body.flipkart.orders_today+response.data.body.glowroad.orders_today,
      };
      response.data.body.forEach((data) => {
        if (data.AcType === 'meesho') {
          tempObject.meeshoAcCount += 1;
        }
        if (data.AcType === 'flipkart') {
          tempObject.flipkartAcCount += 1;
        }
        if (data.AcType === 'glowroad') {
          tempObject.glowroadAcCount += 1;
        }
        if (data.AcType === 'amazon') {
          tempObject.amazonAcCount += 1;
        }
        tempObject.totalAcCount =
          tempObject.meeshoAcCount + tempObject.flipkartAcCount + tempObject.glowroadAcCount + tempObject.amazonAcCount;
      });
      return tempObject;
    }
    else {
      console.log("body",body)
    }
  };

  const allUserDetailsApiData = async () => {
    setDataLoading(true);
    const body = {
      actionType: 'getAllUserStatus',
      panel: 'admin',
      subPanel: 'adminManagement',
    };

    const response = await CreatePostAPICall(enqueueSnackbar, V2Management, body);
    if (response) {

      response.data.body.forEach((data, index) => {
        data.Email = data.sort_key.split("#")[1]
        data.No = index
        getOrderCount(data.Email).then((orderCount) => {
          getAccountCount(data.Email).then((acCount) => {
            data.amazonCount = orderCount.amazonCount;
            data.meeshoCount = orderCount.meeshoCount;
            data.flipkartCount = orderCount.flipkartCount;
            data.glowroadCount = orderCount.glowroadCount;
            data.totalCount = orderCount.totalCount;

            data.amazonAcCount = acCount.amazonAcCount;
            data.meeshoAcCount = acCount.meeshoAcCount;
            data.flipkartAcCount = acCount.flipkartAcCount;
            data.glowroadAcCount = acCount.glowroadAcCount;
            data.totalAcCount = acCount.totalAcCount;
          });
        });
      });
      setUserDetailsData(response.data.body);
    }
    setDataLoading(false);
  };

  useEffect(() => {
    allUserDetailsApiData();
  }, []);

  return (
    <>
      <div style={{ textAlign: 'end', marginBottom: '15px' }}>
        <Button
          variant="contained"
          onClick={() => {
            exportToCsvAllUserDetailsData();
          }}
        >
          Export Excel
        </Button>
      </div>

      <div style={{ height: 800, width: '100%' }}>
        <DataGrid
          rows={userDetailsData}
          loading={dataLoading}
          columns={allUsersDataColumns}
          getRowId={(row) => row.No}
          muiTableHeadCellProps={{
            sx: {
              backgroundColor: SystemColor.originColor,
              color: '#FFF',
              boxShadow: `inset 8px 0 0 ${SystemColor.originColor} !important`,
            },
          }}
        />
      </div>
    </>
  );
};

export default AllUserDetails;
