/* eslint-disable */
import {Box, Divider, Grid, IconButton, Tab, Tabs} from '@mui/material';
import { capitalCase } from 'change-case';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import {ClaimIssue, CreatePostAPICall, MeeshoCreateTicket, V2Management} from '../../../utils/apis';
import moment from 'moment';
import ReactJson from 'react-json-view';
import {LoadingButton} from "@mui/lab";
import useTabs from "../../../hooks/useTabs";
import DataGridTable from "../../../components/Common/DataGridTable";
import useAuth from "../../../hooks/useAuth";
import {DatePicker} from "antd";
import {useTheme} from "@mui/material/styles";
import {downloadS3File, getFileSize} from "../../../utils/utilities";
import {RECEIVED_REPORTED_DATA} from "../../../utils/Constants";
import DeleteIcon from "@mui/icons-material/Delete";

const AutoTicketDetails = () => {
    const { currentBMUserTab, onChangeBMUserTab } = useTabs('Submitted');
    const { enqueueSnackbar } = useSnackbar();
    const localUser = window.localStorage.getItem('userEmailId');
    const { user } = useAuth();
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);

    const [isLoading, setIsLoading] = useState(true);
    const [tableData, setTableData] = useState([]);
    const [createdPayloadJSON, setCreatedPayloadJSON] = useState([]);
    const [dateRange, setDateRange] = useState({});
    const {RangePicker} = DatePicker;
    const theme = useTheme();
    const isLight = theme.palette.mode === 'light';
    const getdate = (value, dateString) => {
        // console.log(value);

        const [startDateString, endDateString] = dateString;

        console.log(startDateString, endDateString)
        const [day1, month1, year1] = startDateString.split("/");
        const [day2, month2, year2] = endDateString.split("/");
        if (day1 && month1 && year1 && day2 && month2 && year2) {
            const formattedStartDate = `20${year1}-${month1}-${day1}`;
            const formattedEndDate = `20${year2}-${month2}-${day2}`;
            setDateRange({start_date: formattedStartDate, end_date: formattedEndDate})
        } else {
            setDateRange({})
        }
    }

    //   {
    //     "noOfTry": 0,
    //     "ticketStatus": "Submitted",
    //     "subAccountId": "1250062",
    //     "ticketId": "1693256680",
    //     "ticketType": "DPWU_Received",
    //     "part_key": "DPWU_Received",
    //     "sort_key": "bugaliyasangita803@gmail.com#1250062#774630181966_1#DPWU_Received",
    //     "ticketPayload": {
    //         "return_courier": "Shadowfax",
    //         "attachments": [
    //             "774630181966_1_attachments_1.jpeg"
    //         ],
    //         "productAtch": [
    //             "774630181966_1_product_1.jpeg"
    //         ],
    //         "description": "Dear Sir/Madam, \n                                                      Please check the below attachment for the video and order id in which i have received the WRONG product during the return.\n                                                      so please check and process my amount for that product value as soon  as possible. This type of return orders bring us 100% cost loss. \n                                                      so please take Strict action in this type of issue on courier partners or customers. \n        \n                                                      Order ID: 774630181966_1\n                                                      AWB: R591122021443FPL\n                                                      Delivery_partner: Shadowfax AND PLEASE REFUND 100% SHIPPING CHARGES AS WELL.\n                                                    ",
    //         "latest_Return_Awb": "R591122021443FPL",
    //         "barcodeAtch": [
    //             "774630181966_1_barcode_1.jpeg"
    //         ],
    //         "barcode_id": "RVPST16325448",
    //         "re_condition": "WRONG",
    //         "latestEmail": "bugaliyasangita803@gmail.com",
    //         "awbAtch": [
    //             "774630181966_1_awb_1.jpeg"
    //         ],
    //         "sub_order_no": "774630181966_1",
    //         "Mobile_No": "7359824323",
    //         "videoAtch": [
    //             "774630181966_1_video_1.mp4"
    //         ],
    //         "packet_condition": "Tampered",
    //         "to_report_found": "DPWU_Received"
    //     },
    //     "username": "radhikacreation30@gmail.com",
    //     "submittedDate": "2023-08-10 11:13:01.068972+05:30",
    //     "tpEmailId": "bugaliyasangita803@gmail.com"
    // }

    // const CreateTicket = async (setIsLoading,data,ticketData)=> {
    //     setIsLoading(true);
    //     const payloadData = {
    //         "username":data.username,
    //         "tpEmailId":data.tpEmailId,
    //         "subAccountId":data.subAccountId,
    //         "ticketData":ticketData,
    //         "dataToUpdateAutoClaimTable":{
    //             "part_key":"DPWU_Received",
    //             "sort_key":"blackpatti653@gmail.com#132108#529195775452_1#DPWU_Received",
    //             "ticketId":"1695214824",
    //             "noOfTry":0
    //         },
    //         "isAutoCreate":true,
    //         "createTicket":true
    //     }
    //     console.log(payloadData);
    //     const response = await CreatePostAPICall(enqueueSnackbar, MeeshoCreateTicket, payloadData);
    //     if (response) {
    //         enqueueSnackbar(response.data.body);
    //     }
    //     setIsLoading(false);
    // }

    const CreateTicket = async (setIsLoading, data, ticketData) => {
        setTableData(oldValues => {
            return oldValues.filter((_, i) => {
                return (_.ticketId !== data.ticketId)
            })
        })
        const payloadData = {
            "actionType":"submitAutoClaim",
            // "username": data.username,
            // "tpEmailId": data.tpEmailId,
            // "subAccountId": data.subAccountId,
            // "ticketData": ticketData,
            "dataToUpdateAutoClaimTable": {
                "part_key": data.part_key,
                "sort_key": data.sort_key,
                // "ticketId": data.ticketId,
                // "noOfTry": data.noOfTry
            },
            // "isAutoCreate": true,
            // "createTicket": true
        }

        // await CreatePostAPICall(() => {
        // }, MeeshoCreateTicket, payloadData);
        await CreatePostAPICall(() => {
        }, V2Management, payloadData);
    }
    const downloadAssets = async (setIsLoading, ticketPayload, sub_order_no) => {

        for (const key of ["productAtch", "barcodeAtch", "videoAtch", "awbAtch","attachments"]) {
            const ImageList = ticketPayload[key]
            for (const image of ImageList) {
                const i = await downloadS3File(`${sub_order_no}/${image}`, true, RECEIVED_REPORTED_DATA, () => { })
            }
        }
        setIsLoading(false)

    }

    const SubmittedColumns = [
        {
            accessorKey: 'ticketId',
            header: 'Ticket Id',
            size: 20,
        },

        {
            accessorKey: 'ticketType',
            header: 'Ticket Type',
            size: 20,
        },

        {
            accessorKey: 'subAccountId',
            header: 'Sub Account Id',
            size: 20,
        },

        {
            accessorKey: 'tpEmailId',
            header: 'Tp Email Id',
            size: 20,
        },
        {
            accessorKey: 'submittedDate',
            header: 'Submitted Date',
            size: 20,
            Cell: (cell) => moment(cell.row.original?.submittedDate).format('DD-MM-YYYY HH:mm'),
        },

        {
            accessorKey: 'ticketPayload',
            header: 'Ticket Payload',
            size: 20,
            Cell: (cell) => {
                const index = cell.row.index;
                return <ReactJson
                    name={"Payload"}
                    src={createdPayloadJSON[index]}
                    displayObjectSize={false}
                    enableClipboard={true}
                    displayDataTypes={false}
                    collapsed
                />
            },
        },
        {
            accessorKey: 'Product_Image_With_Size',
            header: 'Assets Size',
            size: 20,
            Cell: (cell) => {
                const [assetSizeDetails, setAssetSizeDetails] = useState({});
                const ticketPayload = cell.row.original.ticketPayload
                const [isLoading, setIsLoading] = useState(false);

                const sub_order_no = ticketPayload["sub_order_no"]
                // console.log(sub_order_no);
                const getAssetSizeDetails = async () => {
                    var tempData = {}
                    setIsLoading(true)
                    for (const key of ["productAtch", "barcodeAtch", "videoAtch", "awbAtch","attachments"]) {
                        const ImageList = ticketPayload[key]
                        for (const image of ImageList) {
                            if (Object.keys(tempData).includes(key)) {
                                tempData[key][image] = await getFileSize(RECEIVED_REPORTED_DATA, `${sub_order_no}/${image}`, enqueueSnackbar)
                            }
                            else {
                                tempData[key] = {}
                                tempData[key][image] = await getFileSize(RECEIVED_REPORTED_DATA, `${sub_order_no}/${image}`, enqueueSnackbar)
                            }
                        }
                    }
                    setIsLoading(false)
                    setAssetSizeDetails(tempData)
                }


                if (Object.keys(assetSizeDetails).length > 0) {
                    return <ReactJson
                        name={"Payload"}
                        src={assetSizeDetails}
                        displayObjectSize={false}
                        enableClipboard={true}
                        displayDataTypes={false}
                        collapsed
                    />
                }
                else {
                    return <LoadingButton loading={isLoading} onClick={() => {
                        getAssetSizeDetails()
                    }} variant={'outlined'}>
                        Asset Details
                    </LoadingButton>
                }


            },
        },
        {
            accessorKey: 'Download Assets',
            header: 'Download Assets',
            size: 20,
            Cell: (cell) => {
                const ticketPayload = cell.row.original.ticketPayload
                console.log("ticketpayload",ticketPayload)
                const sub_order_no = ticketPayload["sub_order_no"]
                const [isLoading, setIsLoading] = useState(false);
                return <LoadingButton loading={isLoading} onClick={() => {
                    downloadAssets(setIsLoading, ticketPayload, sub_order_no)
                }} variant={'outlined'}>
                    Download Assets
                </LoadingButton>
            },
        },



        {
            accessorKey: 'ticketStatus',
            header: 'Ticket Status',
            size: 20,
        },

        {
            accessorKey: 'noOfTry',
            header: 'No Of Try',
            size: 20,
        },
        {
            accessorKey: 'CreateTicket',
            header: 'Create Ticket',
            size: 20,
            Cell: (cell) => {
                const index = cell.row.index;
                const [isLoading, setIsLoading] = useState(false);
                return <LoadingButton loading={isLoading} onClick={()=>{CreateTicket(setIsLoading,cell.row.original,cell.row.original.ticketPayload)}}  variant={'outlined'}>
                    Submit
                </LoadingButton>
            },
        },
    ];

    const autoClaimsTabs = [
        {
            value: 'Submitted',
        },
        {
            value: 'ReTry',
        },
        {
            value: 'Error',
        },
        {
            value: 'Success',
        },
    ];

    useEffect(() => {
        getTableData();
    }, [currentBMUserTab,dateRange]);

    const getTableData = async () => {
        setIsLoading(true);
        const payload = {
            "actionType": "getAutoTickets",
            "claimType": "DPWU_Received",
            "ticketStatus": currentBMUserTab,
            "username": user?.email ? user.email : localUser,
            ...dateRange
    };
        console.log('response.data.body...............', payload);

        const response = await CreatePostAPICall(enqueueSnackbar, ClaimIssue, payload);
        if (response) {
            console.log('response.data.body...............', response.data.body);
            setTableData(response.data.body);
            const data = response.data.body;
            const listPayload = [];
            for (var i=0;i< data.length;i++) {
                const ticketPayload = {
                    "username": data[i].username,
                    "tpEmailId": data[i].tpEmailId,
                    "subAccountId": data[i].subAccountId,
                    "ticketData": data[i].ticketPayload,
                    "dataToUpdateAutoClaimTable": {
                        "part_key": data[i].part_key,
                        "sort_key": data[i].sort_key,
                        "ticketId": data[i].ticketId,
                        "noOfTry": data[i].noOfTry ,
                    },
                    "isAutoCreate": true,
                    "createTicket": true,
                }
                listPayload.push(ticketPayload);
            }

            setCreatedPayloadJSON(listPayload);
        }
        setIsLoading(false);
    };

    const deletebutton = {
        accessorKey: 'delete',
        header: 'Delete',
        size: 10,
        Cell: ({ cell }) => (
            <IconButton
                aria-label="delete"
                onClick={() => {
                    deleteTicket(cell.row.original.sort_key, cell.row.original);
                }}
            >
                <DeleteIcon style={{ color: 'red' }} />
            </IconButton>
        ),
    }


    const errorDetailsArray = {
        accessorKey: 'errorDetails',
        header: 'Error Details',
        size: 20,
        Cell: (cell) => (
            <div>
                {cell.row.original?.errorDetails}
            </div>
        ),
    }
    const successDetailsArray = {
        accessorKey: 'errorDetails',
        header: 'Success Message',
        size: 20,
        Cell: (cell) => (
            <div>
                {cell.row.original?.errorDetails}
            </div>
        ),
    }
    const filterColumns = ()=>{
        if(currentBMUserTab === "Success"){
            return [...SubmittedColumns.slice(0,8), successDetailsArray];
        }else if(currentBMUserTab === "Error"){
            return [...SubmittedColumns.slice(0,11), errorDetailsArray];
        }
        else {
            return SubmittedColumns;
        }
    }

    return (
        <>
            <Grid item xs={12} sm={12} md={12}>
                <Tabs
                    allowScrollButtonsMobile
                    variant="scrollable"
                    scrollButtons="auto"
                    value={currentBMUserTab}
                    onChange={onChangeBMUserTab}
                >
                    {autoClaimsTabs.map((tab) => (
                        <Tab disableRipple key={tab.value} label={capitalCase(tab.value)} icon={tab.icon} value={tab.value} />
                    ))}
                </Tabs>

                {currentBMUserTab === "Success" &&<>
                    <Box sx={{ mb: 5 }} />
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        alignItems: "center"
                    }}>
                        <strong>Select Date:</strong>
                        <RangePicker
                            popperPlacement="bottom"
                            style={{margin: '0 10px'}}
                            format={'DD/MM/YY'}
                            onChange={(value, event) => getdate(value, event)}
                            className={'createDateRangePicker'}
                            pupClassName={'createDateRangePicker'}
                            {...(!isLight && {
                                className: 'date-picker-dark',
                            })}
                        />
                    </div>
                </>}


                <Box sx={{ mb: 5 }} />


                <DataGridTable columns={filterColumns()} data={tableData} sorting isLoading={isLoading} />
            </Grid>
        </>
    );
};

export default AutoTicketDetails;
