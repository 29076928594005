/* eslint-disable */
import { Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import DataGridTable from '../../../../components/Common/DataGridTable';
import { CreatePostAPICall, V2Management } from '../../../../utils/apis';
import {JSONToCSVConvertor, changeDataFormat, CreateExcelFile} from '../../../../utils/utilities';

const UserRequest = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [userRequestData, setUserRequestData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);

  const userReq = [
    {
      accessorKey: 'Id',
      header: 'ID',
      size: 10,
    },
    {
      accessorKey: 'Rq Type',
      header: 'RQ Type',
      size: 30,
    },
    {
      accessorKey: 'Username',
      header: 'User Name',
      size: 40,
    },
    {
      accessorKey: 'Message',
      header: 'Message',
      size: 20,
    },
    {
      accessorKey: 'Email ID',
      header: 'Email Id',
      size: 30,
    },
    {
      accessorKey: 'Date',
      header: 'Date',
      size: 40,
    },
    {
      accessorKey: 'Token',
      header: 'Token',
      size: 30,
    },
    {
      accessorKey: 'Is_Solved',
      header: 'Is Solved',
      size: 10,
      Cell: (cell) => changeDataFormat(cell.row.original?.Is_Solved),
    },
    {
      accessorKey: 'Delete User Action',
      header: 'Delete User',
      size: 20,
      Cell: (cell) => (
        <Button
          variant="outlined"
          onClick={() => {
            deleteUserActions(cell.row.original?.['Rq Type'], cell.row.original?.Token);
          }}
        >
          Delete User
        </Button>
      ),
    },
  ];

  const deleteUserActions = async (rqType, token) => {
    const body = {
      'Rq Type': rqType,
      token,
      action: 'deleteUser',
      actionType: 'getDeleteAccountRequests',
      panel: 'admin',
      subPanel: 'adminManagement',
    };

    const response = await CreatePostAPICall(enqueueSnackbar, props.api, body);
    if (response) {
      enqueueSnackbar('User Deleted');
      setUserRequestData(response.data.body);
    }
    setDataLoading(false);
  };

  const getUserRequestApiData = async () => {
    setDataLoading(true);

    const body = {
      actionType: 'getDeleteAccountRequests',
      panel: 'admin',
      subPanel: 'adminManagement',
    };
    setUserRequestData([]);
    const response = await CreatePostAPICall(enqueueSnackbar, V2Management, body);
    if (response) {
      //   setUserRequestData(response.data.body);
    }
    setDataLoading(false);
  };

  useEffect(() => {
    getUserRequestApiData();
  }, []);

  const exportToCsvData = () => {
    if (userRequestData.length !== 0) {
      CreateExcelFile(userRequestData, 'User Request', true);
    }
  };

  return (
    <>
      <div style={{ textAlign: 'end', marginBottom: '15px' }}>
        <Button
          variant="contained"
          onClick={() => {
            exportToCsvData();
          }}
        >
          Export Excel
        </Button>
      </div>

      <DataGridTable columns={userReq} data={userRequestData} sorting isLoading={dataLoading} />
    </>
  );
};

export default UserRequest;
