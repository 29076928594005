/* eslint-disable */
import {LoadingButton} from '@mui/lab';
import {
    Avatar,
    Box,
    Checkbox, Chip,
    Divider,
    Grid,
    IconButton, Input,
    InputAdornment,
    Stack, Tab, Tabs,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import {useSnackbar} from 'notistack';
import React, { useEffect, useState } from 'react';
import DataGridTable from '../../../components/Common/DataGridTable';
import Iconify from '../../../components/Iconify';
import TimeAccountModal from './TimeAccountModal';
import useAuth from '../../../hooks/useAuth';
import {
  AddFlipkartAccount,
  AddFlipkartAccountFBFLITE,
  CreatePostAPICall,
  MyLinkedAccount,
  SyncOrders,
} from '../../../utils/apis';
import {
    BootstrapDialog,
    BootstrapDialogTitle,
    handleOnClickCopy, isDesktopEnvironment,
    isSuperAdminEnvironment,
} from '../../../utils/utilities';
import FormControlLabel from "@mui/material/FormControlLabel";
import AccountStatusButton from './AccountStatusButton';
import {capitalCase} from "change-case";
import useTabs from "../../../hooks/useTabs";

export default function FlipkartLinkedAccounts({linkedFlipkartAccounts, getAccountData}) {
    const {user} = useAuth();
    const localUser = window.localStorage.getItem('userEmailId');
    const accountData = window.localStorage.getItem('accountData');
    const {enqueueSnackbar} = useSnackbar();
    const [userToBeAdd, setUserToBeAdd] = useState({});
    const [selectedRow, setSelectedRow] = useState(null);
    const [inputFieldType, setInputFieldType] = useState('text');
    const [otp, setOTP] = useState('');
    const [inputFieldModalType, setInputFieldModalType] = useState('password');
    const [openMobileNoModel, setOpenMobileNoModel] = useState(false);
    const [openPasswordModel, setOpenPasswordModel] = useState(false);
    const [isLBHWLoading, setIsLBHWLoading] = useState(false);
    const [ismask, setIsmask] = useState(true);
    const [openTimeHistoryScheduleModel, setOpenTimeHistoryScheduleModel] = useState(false);
    const [openDeleteUserModel, setOpenDeleteUserModel] = useState(false);
    const [openLBHWModel, setOpenLBHWModel] = useState(false);
    const [newMobileNumber, setNewMobileNumber] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const { currentUploadTab, onChangeUploadTab } = useTabs('Non-FBF');
    const [isAccountUpdating, setIsAccountUpdating] = useState(false);
  const [dimention, setDimention] = useState({"L": 0.00, "B": 0.00, "H": 0.00, "W": 0.00})
  const [isInsertingAccountFKStep1, setIsInsertingAccountFKStep1] = useState(false);
    const [isInsertingAccountFKStep2, setIsInsertingAccountFKStep2] = useState(false);
    const [flipkartColumns, setFlipkartColumns] = useState([
      {
        accessorKey: 'Email',
        header: 'Info',
        Cell: ({cell}) => {
          var index = cell.row?.index
          var isMaskingEnabled = linkedFlipkartAccounts[index] ? linkedFlipkartAccounts[index].isMaskingEnabled : false;
          return <div>
          <span>
            Email : {cell.row.original?.Email}
            <Tooltip title="Copy">
              <IconButton onClick={() => handleOnClickCopy(cell.row.original?.Email, enqueueSnackbar)}>
                <Iconify icon={'eva:copy-fill'} width={15} height={15}/>
              </IconButton>
            </Tooltip>
          </span>
            <br/>
            <span>
            Company Name : {cell.row.original?.Name}
              <Tooltip title="Copy">
              <IconButton onClick={() => handleOnClickCopy(cell.row.original?.Name, enqueueSnackbar)}>
                <Iconify icon={'eva:copy-fill'} width={15} height={15}/>
              </IconButton>
            </Tooltip>
          </span>
            <br/>
            <span>
            Location Id : {cell.row.original?.SupplierId}
              <Tooltip title="Copy">
              <IconButton onClick={() => handleOnClickCopy(cell.row.original?.SupplierId, enqueueSnackbar)}>
                <Iconify icon={'eva:copy-fill'} width={15} height={15}/>
              </IconButton>
            </Tooltip>
          </span>
            <br/>
            <span>
            Seller Id : {cell.row.original?.SellerId}
              <Tooltip title="Copy">
              <IconButton onClick={() => handleOnClickCopy(cell.row.original?.SellerId, enqueueSnackbar)}>
                <Iconify icon={'eva:copy-fill'} width={15} height={15}/>
              </IconButton>
            </Tooltip>
          </span>
            <br/>
            <span>
            Location Name : {cell.row.original?.LocationName ? cell.row.original?.LocationName : ' - '}
              <Tooltip title="Copy">
              <IconButton onClick={() => handleOnClickCopy(cell.row.original?.LocationName, enqueueSnackbar)}>
                <Iconify icon={'eva:copy-fill'} width={15} height={15}/>
              </IconButton>
            </Tooltip>
          </span>
            <br/>
            <span>
            Is Masking Enabled : {isMaskingEnabled ?
              <div style={{
                color: '#029a02',
                display: 'inline-flex',
                verticalAlign: 'middle',
                alignItems: 'center'
              }}>
                <Iconify icon={'bi:check-circle-fill'} width={20} height={20}/>
              </div> : <div style={{
                color: '#fd0000',
                display: 'inline-flex',
                verticalAlign: 'middle',
                alignItems: 'center'
              }}>
                <Iconify icon={'material-symbols:cancel'} width={20} height={20}/>
              </div>}
          </span>
            <br/>
            {cell.row.original?.L && (<span>
<Chip
  sx={{ backgroundColor: "#E3D2F9", margin: "5px" }}
  avatar={<Avatar sx={{ backgroundColor: "#BF9FF0", color: "#ffffff", fontWeight: "1rem" }}>L</Avatar>}
  label={cell.row.original?.L}
/>
            <Chip sx={{backgroundColor:"#E3D2F9",margin:"5px"}} avatar={<Avatar sx={{backgroundColor:"#BF9FF0",color:"#ffffff",fontWeight:"1rem"}}>B</Avatar>} label={cell.row.original?.B} />
            <Chip sx={{backgroundColor:"#E3D2F9",margin:"5px"}} avatar={<Avatar sx={{backgroundColor:"#BF9FF0",color:"#ffffff",fontWeight:"1rem"}}>H</Avatar>} label={cell.row.original?.H} />
            <Chip sx={{backgroundColor:"#E3D2F9",margin:"5px"}} avatar={<Avatar sx={{backgroundColor:"#BF9FF0",color:"#ffffff",fontWeight:"1rem"}}>W</Avatar>} label={cell.row.original?.W} />
 </span>)}
            <br/>
          </div>
        },
      },
      {
        accessorKey: 'action',
        header: 'Action',
        Cell: ({cell}) => (
          <div>
            <Tooltip title="Timers History" placement="bottom">
              <IconButton
                onClick={() => {
                  var data = cell.row.original
                  setSelectedRow(data);
                  setOpenTimeHistoryScheduleModel(true);
                }}
              >
                <Iconify icon={'icon-park:alarm-clock'} width={24} height={24}/>
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete" placement="bottom">
              <IconButton
                onClick={() => {
                  setOpenDeleteUserModel(true);
                  setSelectedRow(cell.row.original);
                }}
              >
                <Iconify icon={'icon-park:delete-one'} width={24} height={24}/>
              </IconButton>
            </Tooltip>
            <Tooltip title="Update LBHW" placement="bottom">
              <IconButton
                onClick={() => {
                  setOpenLBHWModel(true);
                  if(cell.row.original?.L && cell.row.original?.B && cell.row.original?.H && cell.row.original?.W){
                    setDimention({"L": cell.row.original?.L, "B": cell.row.original?.B, "H": cell.row.original?.H, "W": cell.row.original?.W})
                  }else{
                    setDimention({"L": 0, "B": 0, "H": 0, "W": 0})
                  }
                  setIsLBHWLoading(false)
                  setSelectedRow(cell.row.original);
                }}
              >
                <Iconify icon={'icon-park:box'} width={24} height={24}/>
              </IconButton>
            </Tooltip>
          </div>
        ),
      },
    ]);

    const currentUploadTabs = [
        {
            value: 'Non-FBF',
        },
        {
            value: 'FBF',
        }
    ];

  const handleInputChange = (key, value) => {
    const tempSize = { ...dimention };
    tempSize[key] = parseFloat(value);
    setDimention(tempSize);
  };

  const handleBlur = (key) => {
    if (isNaN(dimention[key])) {
      enqueueSnackbar('Please Enter a Numeric Dimension', { variant: 'error' });
    } else {
      const tempSize = { ...dimention };
      tempSize[key] = parseFloat(dimention[key]).toFixed(2);
      setDimention(tempSize);
    }
  };


  useEffect(() => {
    if (isSuperAdminEnvironment) {
      setFlipkartColumns(prevState => [
        ...prevState,
        {
          accessorKey: 'accountStatus',
          header: 'Account Status',
          size: 10,
          Cell: ({ cell }) => {
            return (
              <AccountStatusButton user={user} localUser={localUser} cell={cell} enqueueSnackbar={enqueueSnackbar} getAccountData={getAccountData} platform={'flipkart'}/>
            );
          },
        },
      ]);
    }
  }, []);

    // call update api for mobile num and password
    const flipkartUpdateMobileNumberAndPassword = async (isMobileNumberUpdate) => {
        setIsAccountUpdating(true);
        const body = {
            username: user?.email ? user.email : localUser,
            tpEmailId: selectedRow.Email,
            subAccountId: selectedRow.SupplierId,
            acType: selectedRow.AcType,
        };

        if (isMobileNumberUpdate) {
            body.mobile_no = newMobileNumber;
            body.actionType = 'updateMobileNo';
        } else {
            body.password = newPassword;
            body.actionType = 'updatePassword';
        }

        const successMessage =
            body.actionType === 'updateMobileNo' ? 'Mobile number updated successfully.' : 'Password updated successfully';
        const response = await CreatePostAPICall(enqueueSnackbar, MyLinkedAccount, body, successMessage);
        if (response) {
            await getAccountData();
            clearAccountUpdateModals();
        }
    };

    // handle add account flipkart api call to add account and refreshing table data step-1
    const addFlipkartAccountAPI = async () => {

        if (!userToBeAdd?.username || userToBeAdd?.username === '') {
            enqueueSnackbar('Please enter display name.', {variant: 'error'});
            return;
        }

        if (!userToBeAdd?.tp_emailid_step_1 || userToBeAdd?.tp_emailid_step_1 === '') {
            enqueueSnackbar('Please enter email id.', {variant: 'error'});
            return;
        }

        setIsInsertingAccountFKStep1(true);
        const body = {
            "username": user?.email ? user.email : localUser,
            "tpEmailId": userToBeAdd?.tp_emailid_step_1,
            "password": (userToBeAdd?.password ? userToBeAdd?.password : "NA"),
            "displayName": userToBeAdd?.username,
            "isMaskingEnabled": ismask ?? true,
            "syncDataType": "addFlipKartAccount"
        };
        const response = await CreatePostAPICall(enqueueSnackbar, AddFlipkartAccountFBFLITE, body);
        if (response) {
            enqueueSnackbar(response.data.body);
            getAccountData();
        }
        setIsInsertingAccountFKStep1(false);
        setUserToBeAdd({username: '', tp_emailid_step_1: '', password: ''});
    };

    // handle add account flipkart api call to add account and refreshing table data step-2
    const addFlipkartAccount = async () => {
        if (currentUploadTab === "Non-FBF"){
            if (!userToBeAdd.tp_company || userToBeAdd.tp_company === '') {
                enqueueSnackbar('Please enter company name.', {variant: 'error'});
                return;
            }

            if (!userToBeAdd.tp_emailid_step_2 || userToBeAdd.tp_emailid_step_2 === '') {
                enqueueSnackbar('Please enter email.', {variant: 'error'});
                return;
            }

            if (!userToBeAdd.locationId || userToBeAdd.locationId === '') {
                enqueueSnackbar('Please enter location id.', {variant: 'error'});
                return;
            }

            if (!userToBeAdd.applicationId || userToBeAdd.applicationId === '') {
                enqueueSnackbar('Please enter application id.', {variant: 'error'});
                return;
            }

            if (!userToBeAdd.applicationSecret || userToBeAdd.applicationSecret === '') {
                enqueueSnackbar('Please enter application secret.', {variant: 'error'});
                return;
            }
            setIsInsertingAccountFKStep2(true);
            const body = {
                acType:currentUploadTab,
                username: user?.email ? user.email : localUser,
                cname: userToBeAdd.tp_company,
                tp_email: userToBeAdd.tp_emailid_step_2,
                location_id: userToBeAdd.locationId,
                application_Id: userToBeAdd.applicationId,
                application_secret: userToBeAdd.applicationSecret,
            };

            const response = await CreatePostAPICall(enqueueSnackbar, AddFlipkartAccount, body);
            if (response) {
                enqueueSnackbar(response.data.body);
                getAccountData();
            }
            setIsInsertingAccountFKStep2(false);
            setUserToBeAdd({
                tp_emailid_step_2: '',
                applicationId: '',
                applicationSecret: '',
                tp_company: '',
                locationId: ''
            });
        }else{
            if (!userToBeAdd.tp_emailid || userToBeAdd.tp_emailid === '') {
                enqueueSnackbar('Please enter email id.', {variant: 'error'});
                return;
            }

            if (!userToBeAdd.tp_company || userToBeAdd.tp_company === '') {
                enqueueSnackbar('Please enter company name.', {variant: 'error'});
                return;
            }

            if (!userToBeAdd.locationId || userToBeAdd.locationId === '') {
                enqueueSnackbar('Please enter location id.', {variant: 'error'});
                return;
            }

            if (!userToBeAdd.locationName || userToBeAdd.locationName === '') {
                enqueueSnackbar('Please enter location name.', {variant: 'error'});
                return;
            }

            if (!userToBeAdd.gstIn || userToBeAdd.gstIn === '') {
                enqueueSnackbar('Please enter Gst No.', {variant: 'error'});
                return;
            }
            setIsInsertingAccountFKStep2(true);
            const body = {
                acType:currentUploadTab,
                username: localUser,
                cname: userToBeAdd.tp_company,
                tp_email: userToBeAdd.tp_emailid,
                location_id: userToBeAdd.locationId,
                locationName: userToBeAdd.locationName,
                gstIn: userToBeAdd.gstIn,
            };

            const response = await CreatePostAPICall(enqueueSnackbar, AddFlipkartAccount, body);
            if (response) {
                enqueueSnackbar(response.data.body);
                getAccountData();
            }
            setIsInsertingAccountFKStep2(false);
            setUserToBeAdd({
                tp_company: '',
                tp_emailid: '',
                applicationSecret: '',
                locationId: '',
                locationName:"",
                gstIn:""

            });
        }

    };

    // for request delete user api call and refreshing table data
    const deleteUser = async () => {
        setIsAccountUpdating(true);
        const body = {
            username: user?.email ? user.email : localUser,
            email: selectedRow.Email,
            supplier_id: selectedRow.SupplierId,
            acType: selectedRow.AcType,
            actionType: 'delete',
        };
        const response = await CreatePostAPICall(enqueueSnackbar, MyLinkedAccount, body, 'Account deleted successfully.');
        if (response) {
            getAccountData();
            clearAccountUpdateModals();
        }
    };

    // handle input change for all text-field
    const submitLBHWData = async () => {
      for (const key of Object.keys(dimention)) {
        if (!dimention[key] || dimention[key] <= 0) {
          enqueueSnackbar('Please Enter Dimension', {variant: 'error'});
          return
        }
      }
      setIsLBHWLoading(true)
      const body = {
        username: user?.email ? user.email : localUser,
        tpEmailId : selectedRow.Email,
        subAccountId : selectedRow.SupplierId,
        acType : selectedRow.AcType,
        actionType : 'updateLabelSetting',
        L: dimention['L'],
        B: dimention['B'],
        H: dimention['H'],
        W: dimention['W'],
      };
      const response = await CreatePostAPICall(enqueueSnackbar, MyLinkedAccount, body);
      if (response) {
        setIsLBHWLoading(false)
        setOpenLBHWModel(false)
        enqueueSnackbar(response.data.body);
        getAccountData()
      }
    }

    const onInputChange = (event) => {
        const {name, value} = event.target;
        if (name === 'tp_company') {
            setUserToBeAdd({
                ...userToBeAdd,
                [name]: value.toUpperCase(),
            });
        } else if (name === 'tp_emailid_step_1') {
            setUserToBeAdd({
                ...userToBeAdd,
                [name]: value.toLowerCase(),
            });
        } else {
            setUserToBeAdd({
                ...userToBeAdd,
                [name]: value,
            });
        }
    };

    // for clear all value on modal pop up close
    const clearAccountUpdateModals = () => {
        setOpenMobileNoModel(false);
        setOpenPasswordModel(false);
        setNewMobileNumber('');
        setNewPassword('');
        setSelectedRow(null);
        setOpenTimeHistoryScheduleModel(false);
        setOpenDeleteUserModel(false);
        setIsAccountUpdating(false);
    };

    return (
        <>
            <Divider sx={{p: 1}}/>
            <Grid sx={{p: 3}} container spacing={3}>
                {!isDesktopEnvironment?


                    <Grid item xs={12} md={4}>
                        <Typography sx={{pb: 2}} variant="h6">
                            Link New Flipkart Account
                        </Typography>
                    <Tabs
                        allowScrollButtonsMobile
                        variant="scrollable"
                        scrollButtons="auto"
                        value={currentUploadTab}
                        onChange={onChangeUploadTab}
                    >
                        {currentUploadTabs.map((tab) => (
                            <Tab disableRipple key={tab.value} label={capitalCase(tab.value)} icon={tab.icon} value={tab.value} />
                        ))}
                    </Tabs>
                        {currentUploadTab === "Non-FBF" && <>

                            <Box
                                sx={{
                                    display: 'grid',
                                    rowGap: 2,
                                    p: 2,
                                }}
                            >
                                <h4 style={{textAlign: 'center'}}>Step - 1</h4>
                                <TextField
                                    name="username"
                                    value={userToBeAdd?.username}
                                    placeholder="Display Name*"
                                    id="flipkartTpEmailid"
                                    // type="email"
                                    autoComplete="off"
                                    onChange={onInputChange}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton>
                                                    <Iconify icon={'basil:user-outline'} width={24} height={24}/>
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <TextField
                                    name="tp_emailid_step_1"
                                    value={userToBeAdd?.tp_emailid_step_1}
                                    placeholder="Email*"
                                    id="flipkartTpEmailid"
                                    // type="email"
                                    autoComplete="off"
                                    onChange={onInputChange}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton>
                                                    <Iconify icon={'clarity:email-line'} width={24} height={24}/>
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                {/*<Grid item xs={12} sm={12} md={12} id="flipkartOtpComponent" style={{display:"none"}}>*/}
                                {/*    <TextField*/}
                                {/*        value={otp}*/}
                                {/*        fullWidth*/}
                                {/*        onChange={(event) => {*/}
                                {/*            if(!event.target.value)*/}
                                {/*                setOTP("")*/}
                                {/*            if (/^\d{1,6}$/.test(event.target.value))*/}
                                {/*                setOTP(event.target.value)*/}
                                {/*        }}*/}
                                {/*        InputProps={{*/}
                                {/*            endAdornment: (*/}
                                {/*                <InputAdornment position="end">*/}
                                {/*                    <LoadingButton type={'submit'} variant="outlined"*/}
                                {/*                                   id={"flipkartAddAccountOTPSubmit"}*/}
                                {/*                                   disabled={!/^\d{6}$/.test(otp)}*/}
                                {/*                    >*/}
                                {/*                        Submit*/}
                                {/*                    </LoadingButton>*/}
                                {/*                </InputAdornment>*/}
                                {/*            ),*/}
                                {/*        }}*/}
                                {/*        placeholder="Enter OTP hear..."*/}
                                {/*    />*/}
                                {/*</Grid>*/}

                                {ismask === false && (<TextField
                                    value={userToBeAdd?.password}
                                    name="password"
                                    placeholder="Password"
                                    id="flipkartPassword"
                                    type={inputFieldType}
                                    onChange={onInputChange}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton>
                                                    <Iconify icon={'ri:lock-password-line'} width={24} height={24}/>
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {inputFieldType === 'password' ? (
                                                    <IconButton
                                                        onClick={() => {
                                                            setInputFieldType('text');
                                                        }}
                                                    >
                                                        <Iconify icon={'ant-design:eye-invisible-filled'} width={24}
                                                                 height={24}/>
                                                    </IconButton>
                                                ) : (
                                                    <IconButton
                                                        onClick={() => {
                                                            setInputFieldType('password');
                                                        }}
                                                    >
                                                        <Iconify icon={'ant-design:eye-filled'} width={24} height={24}/>
                                                    </IconButton>
                                                )}
                                            </InputAdornment>
                                        ),
                                    }}
                                />)}

                                {/* TODO Desktop Flipkart Add Account To Live It Unbale It */}
                                {/*
                  <Button
                      className="mr-xs"
                      id="flipkartAddAccount"
                      variant="contained"
                      style={{ width: '50%' }}
                  >
                    Link Account
                  </Button>
            */}
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={ismask}
                                                    onChange={(e) => {
                                                        setIsmask(!ismask);
                                                    }}
                                                />
                                            }
                                            label="Masking Enabled"
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                        <LoadingButton
                                            id="flipkartAddAccountAPI"
                                            variant="contained"
                                            style={{width: '100%'}}
                                            loading={isInsertingAccountFKStep1}
                                            onClick={addFlipkartAccountAPI}
                                        >
                                            Link Account
                                        </LoadingButton>
                                    </Grid>
                                </Grid>
                                {/*<Grid item xs={12} md={12} lg={12}>*/}
                                {/*    <LoadingButton*/}
                                {/*        className="mr-xs"*/}
                                {/*        id="flipkartAddAccountAPI"*/}
                                {/*        variant="contained"*/}
                                {/*        style={{width: '50%'}}*/}
                                {/*        loading={isInsertingAccountFKStep1}*/}
                                {/*        onClick={addFlipkartAccountAPI}*/}
                                {/*    >*/}
                                {/*        Link Account*/}
                                {/*    </LoadingButton>*/}
                                {/*</Grid>*/}
                                <Divider/>

                                <h4 style={{textAlign: 'center'}}>Step - 2</h4>

                                <TextField
                                    type="name"
                                    placeholder="Company Name"
                                    id="tp_company"
                                    name="tp_company"
                                    value={userToBeAdd?.tp_company}
                                    onChange={onInputChange}
                                    InputProps={{
                                        style: {textTransform: 'uppercase'},
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton>
                                                    <Iconify icon={'carbon:location-company'} width={24} height={24}/>
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <TextField
                                    autoComplete="none"
                                    id="tp_emailid_step_2"
                                    type="email"
                                    placeholder="Email Id"
                                    name="tp_emailid_step_2"
                                    value={userToBeAdd?.tp_emailid_step_2}
                                    onChange={onInputChange}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton>
                                                    <Iconify icon={'clarity:email-line'} width={24} height={24}/>
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <TextField
                                    autoComplete="none"
                                    id="locationId"
                                    name="locationId"
                                    type="text"
                                    placeholder="Location Id"
                                    value={userToBeAdd?.locationId}
                                    onChange={onInputChange}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton>
                                                    <Iconify icon={'ep:location'} width={24} height={24}/>
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <TextField
                                    autoComplete="none"
                                    id="applicationId"
                                    type="text"
                                    name="applicationId"
                                    placeholder="Application Id"
                                    onChange={onInputChange}
                                    value={userToBeAdd?.applicationId}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton>
                                                    <Iconify
                                                        icon={'material-symbols:settings-applications-outline-rounded'}
                                                        width={24} height={24}/>
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <TextField
                                    autoComplete="none"
                                    id="applicationSecret"
                                    name="applicationSecret"
                                    type="text"
                                    placeholder="Application Secret"
                                    value={userToBeAdd?.applicationSecret}
                                    onChange={onInputChange}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton>
                                                    <Iconify icon={'codicon:gist-secret'} width={24} height={24}/>
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />

                                <Grid item xs={12} md={12} lg={12}>
                                    <LoadingButton
                                        className="mr-xs"
                                        onClick={addFlipkartAccount}
                                        variant="contained"
                                        style={{width: '50%'}}
                                        loading={isInsertingAccountFKStep2}
                                    >
                                        Link Account
                                    </LoadingButton>
                                </Grid>
                            </Box>
                        </>}
                        {currentUploadTab === "FBF" &&
                            <Box
                                sx={{
                                    display: 'grid',
                                    rowGap: 2,
                                    p: 2,
                                }}
                            >
                                <TextField
                                    name="tp_emailid"
                                    value={userToBeAdd?.tp_emailid}
                                    placeholder="Email"
                                    id="flipkartTpEmailid"
                                    autoComplete="off"
                                    onChange={onInputChange}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton>
                                                    <Iconify icon={'clarity:email-line'} width={24} height={24}/>
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <TextField
                                    type="name"
                                    placeholder="Company Name"
                                    id="tp_company"
                                    name="tp_company"
                                    value={userToBeAdd?.tp_company}
                                    onChange={onInputChange}
                                    InputProps={{
                                        style: {textTransform: 'uppercase'},
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton>
                                                    <Iconify icon={'carbon:location-company'} width={24} height={24}/>
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <TextField
                                    autoComplete="none"
                                    id="locationId"
                                    name="locationId"
                                    type="text"
                                    placeholder="Location Id"
                                    value={userToBeAdd?.locationId}
                                    onChange={onInputChange}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton>
                                                    <Iconify icon={'ep:location'} width={24} height={24}/>
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <TextField
                                    autoComplete="none"
                                    id="locationName"
                                    name="locationName"
                                    type="text"
                                    placeholder="Location Name"
                                    value={userToBeAdd?.locationName}
                                    onChange={onInputChange}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton>
                                                    <Iconify icon={'material-symbols:warehouse-outline-rounded'} width={24} height={24}/>
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <TextField
                                    autoComplete="none"
                                    id="gstIn"
                                    name="gstIn"
                                    type="text"
                                    placeholder="Gst In"
                                    value={userToBeAdd?.gstIn}
                                    onChange={onInputChange}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton>
                                                    <Iconify icon={'material-symbols:settings-applications-outline-rounded'} width={24} height={24}/>
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <Grid item xs={12} md={12} lg={12}>
                                    <LoadingButton
                                        className="mr-xs"
                                        id="flipkartAddAccountBtn"
                                        variant="contained"
                                        style={{width: '50%'}}
                                        onClick={addFlipkartAccount}
                                        loading={isInsertingAccountFKStep1}
                                    >
                                        Link Account
                                    </LoadingButton>
                                </Grid>
                            </Box>
                        }

                </Grid> :


                    <Grid item xs={12} md={4}>
                        <Typography sx={{ pb: 2 }} variant="h6">
                        Link New Flipkart Account
                    </Typography>
                        <Tabs
                            allowScrollButtonsMobile
                            variant="scrollable"
                            scrollButtons="auto"
                            value={currentUploadTab}
                            onChange={onChangeUploadTab}
                        >
                            {currentUploadTabs.map((tab) => (
                                <Tab disableRipple key={tab.value} label={capitalCase(tab.value)} icon={tab.icon} value={tab.value} />
                            ))}
                        </Tabs>

                            {currentUploadTab === "Non-FBF" && <Box
                                sx={{
                                    display: 'grid',
                                    rowGap: 2,
                                    p: 2,
                                }}
                            >
                                <TextField
                                    name="tp_emailid"
                                    value={userToBeAdd?.tp_emailid}
                                    placeholder="Email"
                                    id="flipkartTpEmailid"
                                    autoComplete="off"
                                    onChange={onInputChange}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton>
                                                    <Iconify icon={'clarity:email-line'} width={24} height={24}/>
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <TextField
                                    value={userToBeAdd?.password}
                                    name="password"
                                    placeholder="Password"
                                    id="flipkartPassword"
                                    type={inputFieldType}
                                    onChange={onInputChange}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton>
                                                    <Iconify icon={'ri:lock-password-line'} width={24} height={24}/>
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {inputFieldType === 'password' ? (
                                                    <IconButton
                                                        onClick={() => {
                                                            setInputFieldType('text');
                                                        }}
                                                    >
                                                        <Iconify icon={'ant-design:eye-invisible-filled'} width={24}
                                                                 height={24}/>
                                                    </IconButton>
                                                ) : (
                                                    <IconButton
                                                        onClick={() => {
                                                            setInputFieldType('password');
                                                        }}
                                                    >
                                                        <Iconify icon={'ant-design:eye-filled'} width={24} height={24}/>
                                                    </IconButton>
                                                )}
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <TextField
                                    value={userToBeAdd?.mobile_number}
                                    name="mobile_number"
                                    autoComplete="none"
                                    type="number"
                                    id="flipkartMobileNo"
                                    placeholder="Mobile Number"
                                    onChange={(event) => {
                                        if (event.target.value.length <= 10) {
                                            onInputChange(event);
                                        }
                                    }}
                                    InputProps={{
                                        maxLength: 10,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <div style={{padding: '8px'}}>+91</div>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <Grid item xs={12} sm={12} md={12} id="flipkartOtpComponent" style={{display: "none"}}>
                                    <TextField
                                        value={otp}
                                        fullWidth
                                        onChange={(event) => {
                                            if (!event.target.value)
                                                setOTP("")
                                            if (/^\d{1,6}$/.test(event.target.value))
                                                setOTP(event.target.value)
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <LoadingButton type={'submit'} variant="outlined"
                                                                   id={"flipkartAddAccountOTPSubmit"}
                                                                   disabled={!/^\d{6}$/.test(otp)}
                                                    >
                                                        Submit
                                                    </LoadingButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        placeholder="Enter OTP hear..."
                                    />
                                </Grid>
                                <div>
                                    <p style={{color: '#FFCC00'}}>flipkart will contact you by this number.</p>
                                </div>

                                {/* TODO Desktop Flipkart Add Account To Live It Unbale It */}
                                {/*
                  <Button
                      className="mr-xs"
                      id="meeshoAddAccount"
                      variant="contained"
                      style={{ width: '50%' }}
                    >
                      Link Account
                  </Button>
                */}
                                <Grid item xs={12} md={12} lg={12}>
                                    <LoadingButton
                                        className="mr-xs"
                                        id="flipkartAddAccountBtn"
                                        variant="contained"
                                        style={{width: '50%'}}
                                        onClick={() => {
                                        }}
                                        loading={isInsertingAccountFKStep1}
                                    >
                                        Link Account
                                    </LoadingButton>
                                </Grid>
                            </Box>}
                            {currentUploadTab === "FBF" &&
                                <Box
                                    sx={{
                                        display: 'grid',
                                        rowGap: 2,
                                        p: 2,
                                    }}
                                >
                                    <TextField
                                        name="tp_emailid"
                                        value={userToBeAdd?.tp_emailid}
                                        placeholder="Email"
                                        id="flipkartTpEmailid"
                                        autoComplete="off"
                                        onChange={onInputChange}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <IconButton>
                                                        <Iconify icon={'clarity:email-line'} width={24} height={24}/>
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <TextField
                                        type="name"
                                        placeholder="Company Name"
                                        id="tp_company"
                                        name="tp_company"
                                        value={userToBeAdd?.tp_company}
                                        onChange={onInputChange}
                                        InputProps={{
                                            style: {textTransform: 'uppercase'},
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <IconButton>
                                                        <Iconify icon={'carbon:location-company'} width={24} height={24}/>
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <TextField
                                        autoComplete="none"
                                        id="locationId"
                                        name="locationId"
                                        type="text"
                                        placeholder="Location Id"
                                        value={userToBeAdd?.locationId}
                                        onChange={onInputChange}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <IconButton>
                                                        <Iconify icon={'ep:location'} width={24} height={24}/>
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <TextField
                                        autoComplete="none"
                                        id="locationName"
                                        name="locationName"
                                        type="text"
                                        placeholder="Location Name"
                                        value={userToBeAdd?.locationId}
                                        onChange={onInputChange}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <IconButton>
                                                        <Iconify icon={'material-symbols:warehouse-outline-rounded'} width={24} height={24}/>
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <TextField
                                        autoComplete="none"
                                        id="gstIn"
                                        name="Gst In"
                                        type="text"
                                        placeholder="Gst In"
                                        value={userToBeAdd?.locationId}
                                        onChange={onInputChange}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <IconButton>
                                                        <Iconify icon={'material-symbols:settings-applications-outline-rounded'} width={24} height={24}/>
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <Grid item xs={12} md={12} lg={12}>
                                        <LoadingButton
                                            className="mr-xs"
                                            id="flipkartAddAccountBtn"
                                            variant="contained"
                                            style={{width: '50%'}}
                                            onClick={addFlipkartAccount}
                                            loading={isInsertingAccountFKStep1}
                                        >
                                            Link Account
                                        </LoadingButton>
                                    </Grid>
                                </Box>
                            }


                </Grid>}
                <Grid item xs={12} md={8}>
                    <Typography sx={{pb: 2}} variant="h6">
                        My Linked Flipkart Accounts
                    </Typography>
                    <DataGridTable
                        enableRowNumbers={true}
                        columns={flipkartColumns}
                        data={linkedFlipkartAccounts}
                        enablePagination={false}
                    />
                </Grid>
            </Grid>

            <BootstrapDialog
                sx={{'& .MuiDialog-paper': {width: '100%', maxHeight: 435}}}
                maxWidth="sm"
                onClose={clearAccountUpdateModals}
                aria-labelledby="customized-dialog-title"
                open={openMobileNoModel}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={clearAccountUpdateModals}>
                    Update Mobile Number
                </BootstrapDialogTitle>
                <Divider orientation="horizontal" flexItem/>
                <DialogContent>
                    <Box>
                        <Stack spacing={3}>
                            <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>
                                <TextField
                                    value={newMobileNumber}
                                    autoComplete="none"
                                    type="number"
                                    fullWidth
                                    placeholder="Mobile Number"
                                    onChange={(e) => {
                                        if (e.target.value.length <= 10) {
                                            setNewMobileNumber(e.target.value);
                                        }
                                    }}
                                    InputProps={{
                                        maxLength: 10,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <div style={{padding: '8px'}}>+91</div>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Stack>
                        </Stack>
                    </Box>
                </DialogContent>
                <Divider orientation="horizontal" flexItem/>
                <DialogActions>
                    <LoadingButton
                        loading={isAccountUpdating}
                        variant="contained"
                        autoFocus
                        onClick={async () => {
                            await flipkartUpdateMobileNumberAndPassword(true);
                        }}
                    >
                        Submit
                    </LoadingButton>
                </DialogActions>
            </BootstrapDialog>
            <BootstrapDialog
                sx={{'& .MuiDialog-paper': {width: '100%', maxHeight: 435}}}
                maxWidth="sm"
                onClose={clearAccountUpdateModals}
                aria-labelledby="customized-dialog-title"
                open={openPasswordModel}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={clearAccountUpdateModals}>
                    Change Password
                </BootstrapDialogTitle>
                <Divider orientation="horizontal" flexItem/>
                <DialogContent>
                    <Box>
                        <Stack spacing={3}>
                            <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>
                                <TextField
                                    value={newPassword}
                                    placeholder="Password"
                                    fullWidth
                                    type={inputFieldModalType}
                                    onChange={(e) => {
                                        setNewPassword(e.target.value);
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton>
                                                    <Iconify icon={'ri:lock-password-line'} width={24} height={24}/>
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {inputFieldModalType === 'password' ? (
                                                    <IconButton
                                                        onClick={() => {
                                                            setInputFieldModalType('text');
                                                        }}
                                                    >
                                                        <Iconify icon={'ant-design:eye-invisible-filled'} width={24}
                                                                 height={24}/>
                                                    </IconButton>
                                                ) : (
                                                    <IconButton
                                                        onClick={() => {
                                                            setInputFieldModalType('password');
                                                        }}
                                                    >
                                                        <Iconify icon={'ant-design:eye-filled'} width={24} height={24}/>
                                                    </IconButton>
                                                )}
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Stack>
                        </Stack>
                    </Box>
                </DialogContent>
                <Divider orientation="horizontal" flexItem/>
                <DialogActions>
                    <LoadingButton
                        loading={isAccountUpdating}
                        variant="contained"
                        autoFocus
                        onClick={async () => {
                            await flipkartUpdateMobileNumberAndPassword();
                        }}
                    >
                        Submit
                    </LoadingButton>
                </DialogActions>
            </BootstrapDialog>
            <BootstrapDialog
                sx={{'& .MuiDialog-paper': {width: '100%', maxHeight: 435}}}
                maxWidth="sm"
                onClose={() => {
                    setOpenDeleteUserModel(false);
                }}
                aria-labelledby="customized-dialog-title"
                open={openDeleteUserModel}
            >
                <BootstrapDialogTitle
                    id="customized-dialog-title"
                    onClose={() => {
                        setOpenDeleteUserModel(false);
                    }}
                >
                    Delete User
                </BootstrapDialogTitle>
                <Divider orientation="horizontal" flexItem/>
                <DialogContent>
                    <Box>
                        <Stack spacing={3}>
                            <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>
                                <Typography>
                                    Are you sure, want to sent permanently data delete request to admin for this
                                    account?
                                </Typography>
                            </Stack>
                        </Stack>
                    </Box>
                </DialogContent>
                <Divider orientation="horizontal" flexItem/>
                <DialogActions>
                    <LoadingButton
                        variant="contained"
                        loading={isAccountUpdating}
                        autoFocus
                        onClick={async () => {
                            await deleteUser();
                        }}
                    >
                        Yes
                    </LoadingButton>
                </DialogActions>
            </BootstrapDialog>

          {(selectedRow && openTimeHistoryScheduleModel) && <TimeAccountModal
            closeTimeAccountHistoryModal={clearAccountUpdateModals}
            userEmailId={user?.email}
            platform={'flipkart'}
            openTimeHistoryScheduleModel={openTimeHistoryScheduleModel}
            timeHistoryScheduleData={selectedRow}
            enqueueSnackbar={enqueueSnackbar}
          />}

          {openLBHWModel && <BootstrapDialog
            sx={{'& .MuiDialog-paper': {width: '100%', maxHeight: 435}}}
            maxWidth="sm"
            onClose={() => {
              setOpenLBHWModel(false);
            }}
            aria-labelledby="customized-dialog-title"
            open={openLBHWModel}
          >
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={() => {
                setOpenLBHWModel(false);
              }}
            >
              Label Setting
            </BootstrapDialogTitle>
            <Divider orientation="horizontal" flexItem/>
            <DialogContent>
              <Grid container spacing={3} sx={{mb: 2, maxWidth: "1000px"}}>
                {Object.keys(dimention).map((key) =>
                  <Grid item xs={12} sm={3} md={3}>
                    <TextField
                      type="number"

                      value={dimention[key]}
                      onChange={(e) => {
                        handleInputChange(key, e.target.value)
                      }}
                      onBlur={() => {
                        handleBlur(key)
                      }}
                      variant="outlined"
                      label={key === 'W' ? 'W (Kg)' : key}
                      fullWidth
                    />
                  </Grid>
                )}
              </Grid>
            </DialogContent>
            <Divider orientation="horizontal" flexItem/>
            <DialogActions>
              <LoadingButton
                variant="contained"
                loading={isLBHWLoading}
                autoFocus
                onClick={async () => {
                  await submitLBHWData();
                }}
              >
                Submit
              </LoadingButton>
            </DialogActions>
          </BootstrapDialog>}
            <Input id="flipkartOtpValue" value={otp} type="hidden"/>
        </>
    );
}
