/* eslint-disable */
import { capitalCase } from 'change-case';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Input,
  LinearProgress,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import useAuth from '../../../hooks/useAuth';
import {CreatePostAPICall, MeeshoSaveExcelData, OrderAnalysis, SyncOrders} from '../../../utils/apis';
import { IDENTITY_POOL_ID, REGION, UPLOAD_MANIFEST } from '../../../utils/Constants';
import {
  BootstrapDialog,
  BootstrapDialogTitle, changeDataFormat, changeDateFormat,
  ExcelDateToJSDate,
  getAccountsDataByType, GetTodayDateTime,
  getUserAccountDetails,
  isAdmin,
  isDate,
  isDesktopEnvironment,
  ReadExcelFile,
  setAccountSelectorData,
  SystemColor, uploadSyncFileS3,
} from '../../../utils/utilities';
import { useTheme } from '@mui/material/styles';
import { DatePicker } from 'antd';
import * as AWS from 'aws-sdk';
import dayjs from 'dayjs';
import xl from 'json-as-xlsx';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import useSettings from '../../../hooks/useSettings';
import useTabs from '../../../hooks/useTabs';
import Iconify from '../../../components/Iconify';
import { UploadMultiFile } from '../../../components/upload';
import AccountSelector from '../../../components/Common/AccountSelector';
import { MeeshoPaymentExcelValidation, MeeshoValidateUploadedFile } from '../excelValidations/MeeshoValidation';
import {LoadingButton} from "@mui/lab";

export default function MeeshoSyncOrder() {
  const platForm = 'meesho';
  const { RangePicker } = DatePicker;
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';
  const { themeStretch } = useSettings();
  const { user } = useAuth();
  const localUser = window.localStorage.getItem('userEmailId');
  const { currentUploadTab, onChangeUploadTab } = useTabs('Order');
  const { meeshoTab, onChangeMeeshoTab } = useTabs('Sync Manifest');
  const [files, setFiles] = useState([]);
  const [fileType, setFileType] = useState('');
  const [dataUploadLoader, setDataUploadLoader] = useState(false);
  const [manifestUpload, SetManifestUpload] = useState(false);
  const [manifestUploadSaveManifest, SetManifestUploadSaveManifest] = useState(false);
  const [allPendingData, setAllPendingData] = useState({});
  const [selectedSkuInAc, setSelectedSkuInAc] = useState(null);
  const [allPendingListData, setAllPendingListData] = useState([]);
  const [uploadingCount, setUploadingCount] = useState({
    shipped_total_orders: 0,
    cancelUpdated_total_orders: 0,
    deliveredUpdated_total_orders: 0,
    shipped_billed_orders: 0,
    cancelUpdated_billed_orders: 0,
    deliveredUpdated_billed_orders: 0,
    rtn_total_orders: 0,
    rtn_billed_orders: 0,
    rtn_intraCount: 0,
    rtn_deliCount: 0,
    rtn_rtoCount: 0,
    rtn_retCount: 0,
    payment_total_orders: 0,
    ticket_ticketUpdated: 0,
  });
  const [pendingListModal, setPendingListModal] = useState(false);
  const [options, setOptions] = useState([]);
  const [rerender, setRerender] = useState(true);
  const [accountError, setAccountError] = useState('');
  const [selectedDate, setSelectedDate] = useState(dayjs().format('YYYY-MM-DD'));
  const [paymentDateToBeFetched, setpaymentDateToBeFetched] = useState({});
  const [ticketDateToBeFetched, setTicketDateToBeFetched] = useState({});
  const [returnDateToBeFetched, setReturnDateToBeFetched] = useState({});
  const [meeshoDateToBeFetched, setMeeshoDateToBeFetched] = useState({});
  const [selectedUploadType, setSelectedUploadType] = useState('');
  const [isMeeshoReadyToShipLoading, SetIsMeeshoReadyToShipLoading] = useState(false);
  const [isMeeshoReturnLoading, SetIsMeeshoReturnLoading] = useState(false);
  const [isMeeshoCancelLoading, SetIsMeeshoCancelLoading] = useState(false);
  const [isMeeshoPayPaidLoading, SetMeeshoPayPaidLoading] = useState(false);
  const [isMeeshoPayOutLoading, SetMeeshoPayOutLoading] = useState(false);
  const [isMeeshoInventoryLoading, SetIsMeeshoInventoryLoading] = useState(false);
  const [isMeeshoShippedLoading, SetIsMeeshoShippedLoading] = useState(false);
  const [isMeeshoBagLinkLoading, SetIsMeeshoBagLinkLoading] = useState(false);
  const [isMeeshoAutoBagLinkLoading, SetIsMeeshoAutoBagLinkLoading] = useState(false);
  const [selectedAccountsWithOutAllOptions, setSelectedAccountsWithOutAllOptions] = useState([]);

  const [allAccountsData, setAllAccountsData] = useState([]);
  const [supplierId, setSupplierId] = useState([]);
  const [tpEmail, setTpEmail] = useState([]);
  const [companyName, setCompanyName] = useState([]);
  let startDate = '';
  let endDate = '';
  let tempFilteredData = []
  let refSubOrders = [];
  const showsacnpackbutton = [
      'devikrupa.2021@gmail.com',
      'vgadhiya62@gmail.com',
      'kavathiyameet7603@gmail.com',
      'savanifestiva@gmail.com',
      'kingpatidar357@gmail.com',
      'shreejifab65@gmail.com',
      'rudracreation0777@gmail.com',
      'perfectfashion0143@gmail.com',
      'jigneshbbambhrolia@gmail.com'
  ]

  // for getting account data from localstorage
  useEffect(() => {
    getAccountsDataByType(platForm).then((filteredData) => {
      setAllAccountsData(filteredData);
      console.log("user"  , localUser)

    });
  }, []);
  function getFormattedDate() {
    // Get current date
    const currentDate = new Date();

    // Extract day, month, and year components
    const day = currentDate.getDate();
    // Month starts from 0, so add 1 to get the correct month
    const month = currentDate.getMonth() + 1;
    const year = currentDate.getFullYear();

    // Format day and month to have leading zeros if necessary
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    // Construct the date string in the desired format
    const pickupDate = {
      start: `${formattedDay}/${formattedMonth}/${year.toString().slice(-2)}`,
      end: `${formattedDay}/${formattedMonth}/${year.toString().slice(-2)}`
    };

    return pickupDate;
  }
  // handle of autocomplete for platform wise showing account list
  const handleSelectedAccount = async (event, value) => {
    const response = await setAccountSelectorData(value, allAccountsData);
    setSelectedAccountsWithOutAllOptions(response[1]);
    if (event.length !== 0) {
      const selectedAccountData = await getUserAccountDetails(response[1]);
      setSupplierId(selectedAccountData.supplierIds);
      setTpEmail(selectedAccountData.tpEmailids);
      setCompanyName(selectedAccountData.cName);
    }
  };

  const credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: IDENTITY_POOL_ID,
  });

  AWS.config.update({ region: REGION, credentials });

  const s3 = new AWS.S3({ region: REGION, credentials });

  // Ticket Formatting
  const GetFormattedDate = (dateString) => {
    const date = new Date(dateString.replace('IST', ''));

    let month = `${date.getMonth() + 1}`;
    let day = `${date.getDate()}`;
    const year = date.getFullYear();

    if (month.length < 2) month = `0${month}`;
    if (day.length < 2) day = `0${day}`;

    return [year, month, day].join('-');
  };

  // #-------------------------------------- Validation for Excel END ------------------------------------#

  // Clear upload Excel data variables
  const clearUploadExcelDetail = () => {
    setUploadingCount({
      shipped_total_orders: 0,
      cancelUpdated_total_orders: 0,
      deliveredUpdated_total_orders: 0,
      shipped_billed_orders: 0,
      cancelUpdated_billed_orders: 0,
      deliveredUpdated_billed_orders: 0,
      rtn_total_orders: 0,
      rtn_billed_orders: 0,
      rtn_intraCount: 0,
      rtn_deliCount: 0,
      rtn_rtoCount: 0,
      rtn_retCount: 0,
      payment_total_orders: 0,
      ticket_ticketUpdated: 0,
    });
    setFiles([]);
  };


  const handleExportExcel = async (paymentData, adsCostData) => {

    var headerPaymentExcel = [
      'Sub Order No',
      'Order Date',
      'Supplier SKU',
      'Quantity',
      'Payment Date',
      'Final Settlement Amount',
      'Return Shipping Charge (Excl. GST)',
      'GST on Shipping Charge',
      'Compensation',
      'Claims',
      'Recovery'
    ]

    var headerAdsCostExcel = [
      'Deduction Duration',
      'Deduction Date',
      'Campaign ID',
      'Ad Cost',
      'Credits / Waivers / Discounts',
      'Ad Cost incl. Credits/Waivers/Discounts',
      'GST'
    ]

    const XLSX = require('xlsx');
    const pySheetData = [headerPaymentExcel, ...paymentData.map(obj => Object.values(obj))];
    const adsCostSheetData = [headerAdsCostExcel, ...adsCostData.map(obj => Object.values(obj))];

    const workbook = XLSX.utils.book_new();
    const worksheetPy = XLSX.utils.json_to_sheet(pySheetData);
    XLSX.utils.book_append_sheet(workbook, worksheetPy, "Payment");

    const worksheetAdsCost = XLSX.utils.json_to_sheet(adsCostSheetData);
    XLSX.utils.book_append_sheet(workbook, worksheetAdsCost, "AdsCost");

    var wbout = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

    /* prepare data for POST */
    var blob = new Blob([new Uint8Array(wbout)], {type:"application/octet-stream"});

    const file = new File([blob], `ReCreatedFile ${GetTodayDateTime()}.xlsx`, { type: 'application/octet-stream' });
    return file;
  };

  // Upload Excel data start
  const uploadFileData = async (e, type, files, fileSupId, selectedSubAccount) => {
    setDataUploadLoader(true);
    const excelActualData = ReadExcelFile(e, 0);
    const dataForUpload = [];
    let apiType;

    if (type === 'Order') {
      apiType = 'excelOrdersData';
      const isValidated = MeeshoValidateUploadedFile(excelActualData[0], type);

      if (isValidated) {
        excelActualData.forEach((item, index) => {
          if (index >= 1) {
            dataForUpload.push([
              item[0],
              item[1],
              item[2] ? ExcelDateToJSDate(item[2]) : 'NA',
              item[3],
              item[5],
              item[6],
              Number(item[7]),
            ]);
          }
        });
      } else {
        setDataUploadLoader(false);
        clearUploadExcelDetail();
        enqueueSnackbar('Selected file field(s) not valid.', { variant: 'error' });
        return;
      }
    }

    if (type === 'RTNIn') {
      apiType = 'excelRtnIntra';
      console.log(excelActualData);
      const isValidated = MeeshoValidateUploadedFile(excelActualData[7], type);


      if (isValidated) {
        excelActualData.forEach((item, index) => {
          if (index >= 8) {
            dataForUpload.push([
              item[2],
              item[3],
              Number(item[6]),
              item[8],
              'In Transist',
              item[10] ? ExcelDateToJSDate(item[10]) : 'NA',
              'NA',
              item[11],
              item[12],
              item[14],
              item[15] ? item[15].toString() : 'NA',
              item[20],
              item[21],
            ]);
          }
        });
      } else {
        setDataUploadLoader(false);
        clearUploadExcelDetail();
        enqueueSnackbar('Selected file field(s) not valid.', { variant: 'error' });
        return;
      }
    }

    if (type === 'RTNDL') {
      apiType = 'excelRtnDeli';
      const isValidated = MeeshoValidateUploadedFile(excelActualData[7], type);
      if (isValidated) {
        excelActualData.forEach((item, index) => {
          if (index >= 8) {
            dataForUpload.push([
              item[2],
              item[3],
              Number(item[6]),
              item[8],
              'Delivering Today',
              item[10] ? ExcelDateToJSDate(item[10]) : 'NA',
              item[13] ? ExcelDateToJSDate(item[13]) : 'NA',
              item[11],
              item[12],
              item[14],
              item[15],
              item[19],
              item[20],
            ]);
          }
        });
      } else {
        setDataUploadLoader(false);
        clearUploadExcelDetail();
        enqueueSnackbar('Selected file field(s) not valid.', { variant: 'error' });
        return;
      }
    }

    if (type === 'Payment') {
      var paymentDataToUpload = [];
      const adsCostDataToUpload = [];
      const orderPaymentsExcelActualData = ReadExcelFile(e, 0, 'Order Payments');
      const adsCostExcelActualData = ReadExcelFile(e, 0, 'Ads Cost');
      const isValidated = MeeshoPaymentExcelValidation(orderPaymentsExcelActualData[1]);

      if (!isValidated) {
        enqueueSnackbar('Selected file field(s) not valid.', { variant: 'error' });
        handleRemoveAllFiles();
        return null;
      }
      const subOrderNoIndex = orderPaymentsExcelActualData[1].findIndex((x) => x === 'Sub Order No');
      const orderDateIndex = orderPaymentsExcelActualData[1].findIndex((x) => x === 'Order Date');
      const supplierSKUIndex = orderPaymentsExcelActualData[1].findIndex((x) => x === 'Supplier SKU');
      const quantityIndex = orderPaymentsExcelActualData[1].findIndex((x) => x === 'Quantity');
      const paymentDateIndex = orderPaymentsExcelActualData[1].findIndex((x) => x === 'Payment Date');

      const finalSettlementAmountIndex = orderPaymentsExcelActualData[1].findIndex(
        (x) => x === 'Final Settlement Amount'
      );
      const returnShippingChargeIndex = orderPaymentsExcelActualData[1].findIndex(
        (x) => x === 'Return Shipping Charge (Excl. GST)'
      );
      const gstReturnShippingChargeIndex = orderPaymentsExcelActualData[1].findIndex(
        (x) => x === 'GST on Return Shipping Charge'
      );
      const compensationIndex = orderPaymentsExcelActualData[1].findIndex((x) => x === 'Compensation');
      const claimsIndex = orderPaymentsExcelActualData[1].findIndex((x) => x === 'Claims');
      const recoveryIndex = orderPaymentsExcelActualData[1].findIndex((x) => x === 'Recovery');
      const compensationIndex_Reason = orderPaymentsExcelActualData[1].findIndex((x) => x === 'Compensation Reason');
      const claimsIndex_Reason = orderPaymentsExcelActualData[1].findIndex((x) => x === 'Claims Reason');
      const recoveryIndex_Reason = orderPaymentsExcelActualData[1].findIndex((x) => x === 'Recovery Reason');

      if (orderPaymentsExcelActualData[3][0] !== 'No data is available for these dates.') {
        // uploadExcelFileData(
        //   allAccountsData.find((y) => y.value === fileSupId).Email,
        //   fileSupId,
        //   'excelPaymentPaid',
        //   paymentDataToUpload,
        //   type,
        //   files
        // );

        var exploredPaymentData = {};

        orderPaymentsExcelActualData.forEach((item, index) => {
          if (index >= 3) {
            const orderDate = isDate(item[orderDateIndex])
              ? item[orderDateIndex]
              : ExcelDateToJSDate(item[orderDateIndex]);
            const paymentDate = isDate(item[paymentDateIndex])
              ? item[paymentDateIndex]
              : ExcelDateToJSDate(item[paymentDateIndex]);

            if (!exploredPaymentData[item[subOrderNoIndex]]){
              exploredPaymentData[item[subOrderNoIndex]] = {
                subOrderNo : item[subOrderNoIndex],
                orderDate : orderDate,
                supplierSKU: item[supplierSKUIndex],
                quantity: item[quantityIndex],
                paymentDate: paymentDate,
                finalSettlementAmount: parseFloat(item[finalSettlementAmountIndex]),
                returnShippingCharge: parseFloat(item[returnShippingChargeIndex]),
                gstReturnShippingCharge: parseFloat(item[gstReturnShippingChargeIndex]),
                compensation: parseFloat(item[compensationIndex]),
                claims: parseFloat(item[claimsIndex]),
                recovery: parseFloat(item[recoveryIndex])
              }
            }else {
              exploredPaymentData[item[subOrderNoIndex]]["finalSettlementAmount"] += parseFloat(item[finalSettlementAmountIndex])
              exploredPaymentData[item[subOrderNoIndex]]["returnShippingCharge"] += parseFloat(item[returnShippingChargeIndex])
              exploredPaymentData[item[subOrderNoIndex]]["gstReturnShippingCharge"] += parseFloat(item[gstReturnShippingChargeIndex])
              exploredPaymentData[item[subOrderNoIndex]]["compensation"] += parseFloat(item[compensationIndex])
              exploredPaymentData[item[subOrderNoIndex]]["claims"] += parseFloat(item[claimsIndex])
              exploredPaymentData[item[subOrderNoIndex]]["recovery"] += parseFloat(item[recoveryIndex])
            }
          }
        });
      }
      paymentDataToUpload = Object.values(exploredPaymentData);

      if (adsCostExcelActualData[3][0] !== 'No data is available for these dates.') {
        // uploadExcelFileData(
        //   allAccountsData.find((y) => y.value === fileSupId).Email,
        //   fileSupId,
        //   'excelAdsCost',
        //   adsCostDataToUpload,
        //   type,
        //   files
        // );
        adsCostExcelActualData.forEach((item, index) => {
          if (index >= 3 && item[1]) {
            adsCostDataToUpload.push(
              {
                deductionDuration: item[0],
                deductionDate: item[1],
                campaignID: item[2],
                adCost: item[3],
                creditsWaiversDiscounts : item[4],
                adCostInclCreditsWaiversDiscounts: item[5],
                gst : item[6]
              }
            );
          }
        });
      }

      let file = await handleExportExcel(paymentDataToUpload, adsCostDataToUpload);
      enqueueSnackbar(`File Created Successfully`, { variant: 'success' });
      if (file){
        return file
      }else {
        return null
      }
    }

    if (type === 'Ticket') {
      apiType = 'excelTicketData';
      const isValidated = MeeshoValidateUploadedFile(excelActualData[0], type);
      if (isValidated) {
        excelActualData.forEach((item, index) => {
          if (index >= 1) {
            dataForUpload.push([
              item[7],
              item[8],
              item[9],
              GetFormattedDate(item[10]),
              item[11],
              item[12],
              GetFormattedDate(item[13]),
            ]);
          }
        });
      } else {
        setDataUploadLoader(false);
        clearUploadExcelDetail();
        enqueueSnackbar('Selected file field(s) not valid.', { variant: 'error' });
        return;
      }
    }

    if (type === 'SkuIn') {
      apiType = 'excelSkuInventory';
      const isValidated = MeeshoValidateUploadedFile(excelActualData[0], type, excelActualData[1]);
      if (isValidated) {
        excelActualData.forEach((item, index) => {
          if (index > 1) {
            dataForUpload.push([item[5].toString().trim(), item[7], item[9], item[2]]);
          }
        });
      } else {
        setDataUploadLoader(false);
        clearUploadExcelDetail();
        enqueueSnackbar('Selected file field(s) not valid.', { variant: 'error' });
        return;
      }
    }

    let selectedSupIdUserData;
    var obj;
    if (type === 'SkuIn') {
      obj = allAccountsData.find((item) => item.name === selectedSkuInAc);
    } else {
      selectedSupIdUserData = allAccountsData.find((x) => x.value === fileSupId);
    }

    if ((selectedSupIdUserData || selectedSkuInAc) && (type !== 'Payment' || type !== 'SkuIn')) {
      if (dataForUpload.length !== 0) {
        uploadExcelFileData(
          type !== 'SkuIn' ? selectedSupIdUserData.Email : obj.Email,
          type !== 'SkuIn' ? fileSupId : obj.value,
          apiType,
          dataForUpload,
          type,
          files
        );
        setSelectedSkuInAc(null);
      } else {
        setDataUploadLoader(false);
        clearUploadExcelDetail();
        enqueueSnackbar('No Data In This File.', { variant: 'error' });
        return null;
      }
    } else if (type !== 'Payment') {
      setDataUploadLoader(false);
      clearUploadExcelDetail();
      enqueueSnackbar('Invalid File Or Data.', { variant: 'error' });
      return null;
    }
  };
  // Excel Upload Container for Meesho,
  const OrderUploadContainer = () =>
    dataUploadLoader ? (
      <Box sx={{ width: '90%' }}>
        <div style={{ width: '100%' }}>
          <LinearProgress />
        </div>
        <br />
        Order Uploading Count
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <h5>{`Total Orders : ${uploadingCount.shipped_total_orders}`}</h5>
          </Grid>
          <Grid item xs={6}>
            <h5>{`Billed Orders : ${uploadingCount.shipped_billed_orders}`}</h5>
          </Grid>
        </Grid>
        <br />
        Cancel Updated
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <h5>{`Total Orders : ${uploadingCount.cancelUpdated_total_orders}`}</h5>
          </Grid>
          <Grid item xs={6}>
            <h5>{`Billed Orders : ${uploadingCount.cancelUpdated_billed_orders}`}</h5>
          </Grid>
        </Grid>
        <br />
        Delivered Updated
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <h5>{`Total Orders : ${uploadingCount.deliveredUpdated_total_orders}`}</h5>
          </Grid>
          <Grid item xs={6}>
            <h5>{`Billed Orders : ${uploadingCount.deliveredUpdated_billed_orders}`}</h5>
          </Grid>
        </Grid>
      </Box>
    ) : (
      <UploadMultiFile
        multiple
        acceptTypes={'.csv,.xls'}
        files={fileType === 'Order' ? files : []}
        title={'Drop Or Select Order Excel'}
        onDrop={(e) => {
          handleDropMultiFile(e, 'Order');
        }}
        onRemove={handleRemoveFile}
        onRemoveAll={handleRemoveAllFiles}
        onUpload={() => {
          handleOnUpload('Order', files);
        }}
      />
    );

  // return upload container
  const ReturnUploadContainer = () =>
    dataUploadLoader ? (
      <>
        <div style={{ width: '100%', textAlign: 'center', padding: '100px' }}>
          <CircularProgress sx={{ color: SystemColor.originColor }} size={24} color="inherit" />
          <br />
          {fileType === 'RTNIn'
            ? 'Please Wait While Uploading Return In-Transit Data...'
            : 'Please Wait While Uploading Return Delivered Data...'}
        </div>
      </>
    ) : (
      <>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <UploadMultiFile
              multiple
              acceptTypes={'.xlsx, .xls, .csv'}
              files={fileType === 'RTNIn' ? files : []}
              title={'Drop Or Select Return In-Transit Excel'}
              onDrop={(e) => {
                handleDropMultiFile(e, 'RTNIn');
              }}
              onRemove={handleRemoveFile}
              onRemoveAll={handleRemoveAllFiles}
              onUpload={() => {
                handleOnUpload('RTNIn', files);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <UploadMultiFile
              multiple
              acceptTypes={'.xlsx, .xls, .csv'}
              files={fileType === 'RTNDL' ? files : []}
              title={'Drop Or Select Return Delivered Excel'}
              onDrop={(e) => {
                handleDropMultiFile(e, 'RTNDL');
              }}
              onRemove={handleRemoveFile}
              onRemoveAll={handleRemoveAllFiles}
              onUpload={() => {
                handleOnUpload('RTNDL', files);
              }}
            />
          </Grid>
        </Grid>
      </>
    );

  // choose which type of payment you want to upload
  const paymentUploadList = [
    { value: 'adCost', name: 'AdsCost' },
    { value: 'payment', name: 'Payment' },
  ];

  // payment upload container
  const PaymentUploadContainer = () =>
    dataUploadLoader ? (
      <div style={{ width: '100%', textAlign: 'center', padding: '100px' }}>
        <CircularProgress sx={{ color: SystemColor.originColor }} size={24} color="inherit" />
        <br />
        Please Wait While Uploading Payment Data...
      </div>
    ) : (
      <>
        {/*{isAdmin ? (*/}
        {/*  <>*/}
        {/*    <FormControl required sx={{ width: '100%' }}>*/}
        {/*      <InputLabel required>Select Upload Type</InputLabel>*/}
        {/*      <Select*/}
        {/*        fullWidth*/}
        {/*        value={selectedUploadType}*/}
        {/*        placeholder={'Select Upload Type'}*/}
        {/*        onChange={(event) => {*/}
        {/*          setSelectedUploadType(event.target.value);*/}
        {/*        }}*/}
        {/*        input={<OutlinedInput label="Select Upload Type" />}*/}
        {/*      >*/}
        {/*        {paymentUploadList.map((option) => (*/}
        {/*          <MenuItem key={option.value} value={option.value}>*/}
        {/*            {option.name}*/}
        {/*          </MenuItem>*/}
        {/*        ))}*/}
        {/*      </Select>*/}
        {/*      <br />*/}
        {/*    </FormControl>*/}
        {/*  </>*/}
        {/*) : null}*/}
        <UploadMultiFile
          multiple
          acceptTypes={'.xlsx, .xls, .csv'}
          files={fileType === 'payment' ? files : []}
          // files={fileType === 'Payment' ? files : []}
          title={'Drop Or Select Payment Excel'}
          onDrop={(e) => {
            handleDropMultiFile(e,'payment');
          }}
          onRemove={handleRemoveFile}
          onRemoveAll={handleRemoveAllFiles}
          onUpload={() => {
            handleOnUpload('payment', files);
          }}
        />
      </>
    );

  // ticket upload container
  const TicketUploadContainer = () =>
    dataUploadLoader ? (
      <div style={{ width: '100%', textAlign: 'center', padding: '100px' }}>
        <CircularProgress sx={{ color: SystemColor.originColor }} size={24} color="inherit" />
        <br />
        Please Wait While Uploading Ticket data...
      </div>
    ) : (
      <UploadMultiFile
        multiple
        acceptTypes={'.xlsx, .xls, .csv'}
        files={fileType === 'TKT' ? files : []}
        title={'Drop Or Select Ticket Excel'}
        onDrop={(e) => {
          handleDropMultiFile(e, 'TKT');
        }}
        onRemove={handleRemoveFile}
        onRemoveAll={handleRemoveAllFiles}
        onUpload={() => {
          // console.log('Uploading files:', files);
          // console.log('Selected Upload Type on Upload:', selectedUploadType);
          handleOnUpload('Ticket', files);
        }}
      />
    );

  // SkuInventory upload container
  const SkuInventoryUploadContainer = () =>
    dataUploadLoader ? (
      <div style={{ width: '100%', textAlign: 'center', padding: '100px' }}>
        <CircularProgress sx={{ color: SystemColor.originColor }} size={24} color="inherit" />
        <br />
        Please Wait While Uploading Sku Inventory data...
      </div>
    ) : (
      <>
        <Autocomplete
          id="flipkart-excel-upload-selector"
          onChange={(event, value) => {
            setSelectedSkuInAc(value);
          }}
          value={selectedSkuInAc}
          options={allAccountsData.map((option) => option.name)}
          renderInput={(params) => <TextField {...params} label="Select Meesho Account*" />}
        />

        <Box sx={{ mb: 2 }} />

        <UploadMultiFile
          multiple
          acceptTypes={'.xlsx, .xls, .csv'}
          files={fileType === 'SkuIn' ? files : []}
          title={'Drop Or Select Sku Inventory Excel'}
          onDrop={(e) => {
            handleDropMultiFile(e, 'SkuIn');
          }}
          onRemove={handleRemoveFile}
          onRemoveAll={handleRemoveAllFiles}
          onUpload={() => {
            handleOnUpload('SkuIn', files);
          }}
        />
      </>
    );

  //  For Sync Manifest
  const SyncManifestUploadContainerMeesho = () =>
    manifestUpload ? (
      <div style={{ width: '100%', textAlign: 'center', padding: '100px' }}>
        <CircularProgress sx={{ color: SystemColor.originColor }} size={24} color="inherit" />
        <br />
        Please Wait While Uploading Manifiest...
      </div>
    ) : (
      <>
        <UploadMultiFile
          multiple
          acceptTypes={'.pdf'}
          files={fileType === 'mnf' ? files : []}
          title={'Drop Or Select Manifest'}
          onDrop={(e) => {
            setFiles([]);
            handleDropMultiFile(e, 'mnf');
          }}
          onRemove={handleRemoveFile}
          onRemoveAll={handleRemoveAllFiles}
          onUpload={handlePdfFile}
        />
      </>
    );

  // For Save Manifest
  const SaveManifestUploadContainerMeesho = () =>
    manifestUploadSaveManifest ? (
      <div style={{ width: '100%', textAlign: 'center', padding: '100px' }}>
        <CircularProgress sx={{ color: SystemColor.originColor }} size={24} color="inherit" />
        <br />
        Please Wait While Uploading Manifiest...
      </div>
    ) : (
      <>
        <div style={{ width: '100%', marginBottom: '10px' }}>
          <DatePicker
            style={{ width: '100%' }}
            className={'createDateRangePicker'}
            pupClassName={'createDateRangePicker'}
            {...(!isLight && {
              className: 'date-picker-dark',
            })}
            value={dayjs(`${selectedDate}`, 'YYYY-MM-DD')}
            onChange={(date, dateToString) => {
              if (date) {
                setSelectedDate(dateToString);
              } else {
                setSelectedDate(dayjs().format('YYYY-MM-DD'));
              }
            }}
          />
        </div>
        <UploadMultiFile
          multiple
          acceptTypes={'.pdf'}
          files={fileType === 'mnfupl' ? files : []}
          title={'Drop Or Select Save Manifest'}
          onDrop={(e) => {
            setFiles([]);
            handleDropMultiFile(e, 'mnfupl');
          }}
          onRemove={handleRemoveFileSaveManifest}
          onRemoveAll={handleRemoveAllFilesSaveManifest}
          onUpload={handlePdfFileSaveManifest}
        />
      </>
    );

  // Meesho Upload Tabs
  const uploadTabs = [
    {
      value: 'Order',
      icon: <Iconify icon={'tabler:truck-delivery'} width={25} height={25} />,
      component: <OrderUploadContainer />,
    },
    {
      value: 'Return',
      icon: <Iconify icon={'tabler:truck-return'} width={25} height={25} />,
      component: <ReturnUploadContainer />,
    },
    {
      value: 'Payment',
      icon: <Iconify icon={'mdi:recurring-payment'} width={25} height={25} />,
      component: <PaymentUploadContainer />,
    },
    {
      value: 'Ticket',
      icon: <Iconify icon={'ion:ticket-sharp'} width={25} height={25} />,
      component: <TicketUploadContainer />,
    },
    {
      value: 'SkuInventory',
      icon: <Iconify icon={'ic:round-inventory-2'} width={25} height={25} />,
      component: <SkuInventoryUploadContainer />,
    },
  ];

  // Manifest Tabs
  const meeshoTabs = [
    {
      value: 'Sync Manifest',
      icon: <Iconify icon={'fluent:document-sync-20-regular'} width={25} height={25} />,
      component: <SyncManifestUploadContainerMeesho />,
    },
    {
      value: 'Save Manifest',
      icon: <Iconify icon={'fluent:document-save-20-regular'} width={25} height={25} />,
      component: <SaveManifestUploadContainerMeesho />,
    },
  ];

  // handle drop file
  const handleDropMultiFile = useCallback(
    (acceptedFiles, fileType) => {
      const newFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      setFiles([...newFiles]);
      setFileType(fileType);
    },
    [files]
  );

  // Remove Files
  const handleRemoveFile = (inputFile) => {
    const filtered = files.filter((file) => file !== inputFile);
    setFiles(filtered);
  };

  // remove files manifest
  const handleRemoveFileSaveManifest = (inputFile) => {
    const filtered = files.filter((file) => file !== inputFile);
    setFiles(filtered);
  };

  // Remove Files
  const handleRemoveAllFiles = () => {
    setFiles([]);
  };

  // Remove Files
  const handleRemoveAllFilesSaveManifest = () => {
    setFiles([]);
  };

  // handle upload
  const handleOnUpload = async (type, files) => {
    const file = files[0];
    const reader = new FileReader();
    let fileSupId = '';
    let isSuppIdMatch = false;
    let selectedSubAccount = ''

    if (type !== 'SkuIn') {
     console.log("allAccountsData",allAccountsData)
      console.log("file.name",file.name)

      allAccountsData.forEach((x) => {
        console.log("file.name.indexOf(x.value)",file.name.indexOf(x.value))
        console.log("file.name.indexOf(x.value) !== -1",file.name.indexOf(x.value) !== -1)
        if (file.name.indexOf(x.value) !== -1 && x.value.toLowerCase() !== 'all') {
          console.log("x.value",x.value)
          fileSupId = x.value;
          selectedSubAccount = x
          isSuppIdMatch = true;
        }
      });
      if (isSuppIdMatch === false) {
        handleRemoveAllFiles();
        enqueueSnackbar('Please Upload File With Valid Sub Account Id.', {variant: 'error'});
        return;
      }
    }
    setDataUploadLoader(true);
    if (file) {
      if (type === "payment"){
        reader.onload = async (e) => {
          let fileToUpload = await uploadFileData(e, "Payment", file, fileSupId);
          console.log("beforecName", selectedSubAccount.cName);

          const removeLastSlashes = (str) => {

            if (str.endsWith('//') ) {

              return str.slice(0, -2);
            }


            return str;
          };
          const updatedCName = removeLastSlashes(selectedSubAccount.cName);

          const removefirstSlashes = (str) => {

            if (str.startsWith('//') ) {

              return str.slice(2);
            }
            return str;
          };
          const finalcName = removefirstSlashes(updatedCName);
          // console.log("updatedCName" , finalcName);

          const data = await uploadSyncFileS3(fileToUpload, user.email, selectedSubAccount.Email, selectedSubAccount.value, finalcName, "PaidPayment", fileToUpload.name,"meesho", enqueueSnackbar)

          if (data){
            enqueueSnackbar('File Uploaded Successfully', { variant: 'success' });
            handleRemoveAllFiles();
          }
          setDataUploadLoader(false);
        };
        reader.readAsArrayBuffer(file);
      }else if(type === "adCost"){
        // console.log("AdsCost");
        const data = await uploadSyncFileS3(file, user.email, selectedSubAccount.Email, selectedSubAccount.value, selectedSubAccount.cName, "AdsCost", file.name,"meesho", enqueueSnackbar)
        if (data){
          enqueueSnackbar('File Uploaded Successfully', { variant: 'success' });
          setDataUploadLoader(false);
          handleRemoveAllFiles();
          return
        }
      }
      else{
        reader.onload = (e) => {
            uploadFileData(e, type, files, fileSupId);
        };
        reader.readAsArrayBuffer(file);
      }
    } else {
      handleRemoveAllFiles();
    }
  };

  // final api call to upload excel
  const uploadExcelFileAdsCostData = async (tpEmailId, supplierId, apiType, dataForUpload, token = null) => {
    const items = dataForUpload.slice(0, 100);
    dataForUpload.splice(0, 100);
    setDataUploadLoader(true);
    const body = {
      username: user?.email ? user.email : localUser,
      tpEmailId,
      subAccountId: supplierId,
      syncDataType: apiType,
      body: items,
    };
    if (token) body.token = token;
    const response = await CreatePostAPICall(enqueueSnackbar, MeeshoSaveExcelData, body);
    if (response) {
      if (dataForUpload.length > 0) {
        return uploadExcelFileAdsCostData(tpEmailId, supplierId, apiType, dataForUpload, response.data.token);
      } else {
        return true;
      }
    } else {
      clearUploadExcelDetail();
      setDataUploadLoader(false);
      setSelectedUploadType('');
      return false;
    }
  };

  // final api call to upload excel
  const uploadExcelFileData = async (
    tpEmailId,
    supplierId,
    apiType,
    dataForUpload,
    type,
    files,
    savedFileName,
    tempData = null,
    token = null
  ) => {
    const items = dataForUpload.slice(0, 100);
    dataForUpload.splice(0, 100);
    setDataUploadLoader(true);
    const body = {
      username: user?.email ? user.email : localUser,
      tpEmailId,
      subAccountId: supplierId,
      syncDataType: apiType,
      body: items,
    };
    console.log('body', body);
    if (token) body.token = token;
    const response = await CreatePostAPICall(enqueueSnackbar, MeeshoSaveExcelData, body);
    if (response) {
      let tempData1 = uploadingCount;

      if (apiType === 'excelOrdersData') {
        if (!tempData) tempData = uploadingCount;
        tempData1 = {
          shipped_total_orders: +tempData.shipped_total_orders + +response.data.shipped.total_orders,
          cancelUpdated_total_orders: +tempData.cancelUpdated_total_orders + +response.data.cancelUpdated.total_orders,
          deliveredUpdated_total_orders:
            +tempData.deliveredUpdated_total_orders + +response.data.deliveredUpdated.total_orders,
          shipped_billed_orders: +tempData.shipped_billed_orders + +response.data.shipped.billed_orders,
          cancelUpdated_billed_orders:
            +tempData.cancelUpdated_billed_orders + +response.data.cancelUpdated.billed_orders,
          deliveredUpdated_billed_orders:
            +tempData.deliveredUpdated_billed_orders + +response.data.deliveredUpdated.billed_orders,
        };
        setUploadingCount(tempData1);
      }

      if (apiType === 'excelRtnIntra' || apiType === 'excelRtnDeli') {
        if (!tempData) tempData = uploadingCount;
        tempData1 = {
          rtn_total_orders: +tempData.rtn_total_orders + +response.data.billDetails.total_orders,
          rtn_billed_orders: +tempData.rtn_billed_orders + +response.data.billDetails.billed_orders,
          rtn_intraCount: +tempData.rtn_intraCount + +response.data.billDetails.intraCount,
          rtn_deliCount: +tempData.rtn_deliCount + +response.data.billDetails.deliCount,
          rtn_rtoCount: +tempData.rtn_rtoCount + +response.data.billDetails.rtoCount,
          rtn_retCount: +tempData.rtn_retCount + +response.data.billDetails.retCount,
        };
        setUploadingCount(tempData1);
      }

      if (apiType === 'excelPaymentData') {
        if (!tempData) tempData = uploadingCount;
        tempData1 = {
          payment_total_orders: +tempData.payment_total_orders + +response.data.paymentUpdated.total_orders,
        };
        setUploadingCount(tempData1);
      }

      if (apiType === 'excelTicketData') {
        if (!tempData) tempData = uploadingCount;
        tempData1 = {
          ticket_ticketUpdated: +tempData.ticket_ticketUpdated + +response.data.ticketUpdated.total_updated,
        };
        setUploadingCount(tempData1);
      }

      if (dataForUpload.length > 0) {
        uploadExcelFileData(
          tpEmailId,
          supplierId,
          apiType,
          dataForUpload,
          type,
          files,
          savedFileName,
          tempData1,
          response.data.token
        );
      } else {
        const tempFiles = files.slice(1);
        if (tempFiles.length === 0) {
          clearUploadExcelDetail();
          setDataUploadLoader(false);
          setSelectedUploadType('');
          if (selectedUploadType === 'adCost') {
            enqueueSnackbar('AdCost File Saved Successfully.');
          } else {
            enqueueSnackbar('File Saved Successfully.');
          }
        } else {
          handleOnUpload(type, tempFiles);
        }
      }
    }
    else {
      clearUploadExcelDetail();
      setDataUploadLoader(false);
      setSelectedUploadType('');
    }
  };

  // upload manifest file
  const uploadManifestFile = async (file, onSuccess = () => {}) => {
    const token = Math.floor(Date.now() / 1000);

    const putParams = {
      Body: file, // encode data to binary string >> data.toString(2)
      Bucket: UPLOAD_MANIFEST, // bucket name
      Key: `${user?.email}/manifestToSaveData/${token}/${file.name}`,
    };
    return new Promise((resolve) => {
      s3.putObject(putParams, (err, data) => {
        if (err) {
          enqueueSnackbar("Can't Upload File.", { variant: 'error' });
        }
        if (data && data.ETag) {
          onSuccess(data && data.ETag ? `${user?.email}/manifestToSaveData/${token}/${file.name}` : []);
          resolve(data && data.ETag ? `${user?.email}/manifestToSaveData/${token}/${file.name}` : []);
        }
      });
    });
  };

  // api call to save manifest file
  const uploadManifestSaveFile = async (file, subAccountIds, tpEmailIds, onSuccess = () => {}) => {
    const putParams = {
      Body: file, // encode data to binary string >> data.toString(2)
      Bucket: UPLOAD_MANIFEST, // bucket name
      Key: `${user?.email}/${tpEmailIds[0]}/${subAccountIds[0]}/${selectedDate}/Manifest.pdf`,
    };
    return new Promise((resolve) => {
      s3.putObject(putParams, (err, data) => {
        if (err) {
          enqueueSnackbar("Can't Upload File.", { variant: 'error' });
        }
        if (data && data.ETag) {
          onSuccess(
            data && data.ETag ? `${user?.email}/${tpEmailIds[0]}/${subAccountIds[0]}/${selectedDate}/Manifest.pdf` : []
          );
          resolve(
            data && data.ETag ? `${user?.email}/${tpEmailIds[0]}/${subAccountIds[0]}/${selectedDate}/Manifest.pdf` : []
          );
        }
      });
    });
  };

  // sync manifest api call
  const handlePdfFile = async () => {
    SetManifestUpload(true);
    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      if (file) {
        const extension = file.name.split('.').pop();
        if (extension === 'pdf') {
          if (file) {
            await uploadManifestFile(file, async (uploadedPath) => {
              await CreatePostAPICall(enqueueSnackbar, SyncOrders, {
                username: user?.email ? user.email : localUser,
                fileKey: uploadedPath,
                excelType: 'MANIFEST',
              });
              enqueueSnackbar('Sync Started Successfully');
              clearUploadExcelDetail();
              SetManifestUpload(false);
            });
          }
        } else {
          enqueueSnackbar('Invalid File.', { variant: 'error' });
          return null;
        }
      }
    }
  };

  // handle save manifest pdf api call
  const handlePdfFileSaveManifest = async () => {
    SetManifestUploadSaveManifest(true);
    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      if (file) {
        const extension = file.name.split('.').pop();
        const accountDetails = await getUserAccountDetails();
        const identifierList = accountDetails.identifiers;
        const supplierIdList = accountDetails.supplierIds;
        const tpEmailIdList = accountDetails.tpEmailids;
        const allDataList = accountDetails.cName;
        const tpEmailIds = [];
        const subAccountIds = [];
        const cNames = [];
        for (let i1 = 0; i1 < identifierList.length; i1++) {
          if (file.name.includes(identifierList[i1])) {
            subAccountIds.push(supplierIdList[i1]);
            tpEmailIds.push(tpEmailIdList[i1]);
            cNames.push(allDataList[i1]);
            break;
          }
        }

        if (extension === 'pdf') {
          if (file) {
            await uploadManifestSaveFile(file, subAccountIds, tpEmailIds, async (uploadedPath) => {
              await CreatePostAPICall(enqueueSnackbar, SyncOrders, {
                username: user?.email ? user.email : localUser,
                manifest_key: uploadedPath,
                subAccountIds,
                pickup_date: selectedDate,
                tpEmailIds,
                cNames,
                acType: 'meesho',
                excelType: 'SAVEMANIFEST',
              });
              enqueueSnackbar('Manifest Saved Successfully');
              clearUploadExcelDetail();
              SetManifestUploadSaveManifest(false);
            });
          }
        } else {
          enqueueSnackbar('Invalid File.', { variant: 'error' });
          return null;
        }
      }
    }
  };

  // get sku wise data
  const getSkuWiseData = (data) => {
    let arr;
    arr = data.reduce((obj, item) => {
      const find = obj.find((i) => i.sku_name === item.sku_name && i.variation === item.variation);
      const _d = {
        ...item,
      };
      !find ? obj.push(_d) : (find.qty += item.qty);
      return obj;
    }, []);
    return arr;
  };

  // download excel for sku wise data
  const handleDownloadExcel = () => {
    setPendingListModal(true);
    const meeshoGeneratePendingList = document.getElementById('meeshoSyncPending');
    const meeshoDownloadGeneratePendingList = document.getElementById('meeshoPendExcelDownload');
    const meeshoGeneratePendingListData = document.getElementById('generate_pending_list_data');
    const meeshoBagLinkData = document.getElementById('bag_link_data');
    meeshoGeneratePendingList.hidden = false;
    meeshoGeneratePendingListData.hidden = true;
    meeshoBagLinkData.hidden = true;
    meeshoDownloadGeneratePendingList.hidden = true;
    const incomingdata = meeshoGeneratePendingListData.value;

    if (!incomingdata) {
      return;
    }
    const data = JSON.parse(incomingdata);
    const option = [];
    Object.entries(data).forEach(([key, value]) => {
      const emailId = [];
      let supId = '';
      Object.entries(value).forEach(([key]) => {
        const supplierData = [];
        supId = key;
        emailId[`${key}`] = supplierData;
      });
      option.push(`${key} - ${supId}`);
    });
    setOptions(option);
    setAllPendingData(data);
  };

  // get selected account data and formatting here
  const getSelectedData = (value) => {
    let allData = [];

    value.forEach((account) => {
      const splitedData = account.split('-');
      const dataToAdd = allPendingData[splitedData[0].replace(/ /g, '')][splitedData[1].replace(/ /g, '')];
      allData = [...allData, ...dataToAdd];
    });

    const listData = [];
    allData.forEach((value) => {
      const data = {};
      if (!value[0]) {
        return;
      }
      data.sku_name = value[2];
      data.qty = value[3];
      data.variation = value[4];
      listData.push(data);
    });

    const skuData = getSkuWiseData(listData);
    setAllPendingListData(skuData);
  };

  // generating pending list data
  const getPendingList = () => {
    const data = [
      {
        sheet: 'GeneratePendingList',
        columns: [
          { label: 'SKU', value: 'sku_name' },
          { label: 'Quantity', value: 'qty' },
          { label: 'Variation', value: 'variation' },
        ],
        content: allPendingListData,
      },
    ];
    const settings = {
      fileName: `GeneratePendingList`,
      extraLength: 6,
      writeOptions: {},
    };
    xl(data, settings);
  };

  // validation for account selector
  const validation = () => {
    const validationAccount = document.getElementById('selectedAccountsData');
    const valid = validationAccount.value;
    let isValidate = true;
    if (valid === '[]') {
      isValidate = false;
      setAccountError('Please Select Account');
    }
    if (isValidate) {
      setRerender(false);
      setAccountError('');
    }
  };

  // modal close and rerender page here without refreshing all page
  const closePendingDownload = () => {
    setPendingListModal(false);
    setRerender(true);
  };

  // rerender page here without refreshing all page
  useEffect(() => {}, [rerender]);

  // get date for save in local storage and api call
  const getdate = async (value, dateString) => {
    startDate = dateString[0];
    endDate = dateString[1];
    const pDate = {
      start: dateString[0],
      end: dateString[1],
    };
    const tDate = {
      start: dateString[0],
      end: dateString[1],
    };
    const rDate = {
      start: dateString[0],
      end: dateString[1],
    };
    const meeshoDate = {
      start: dateString[0],
      end: dateString[1],
    };
    setpaymentDateToBeFetched(pDate);
    setTicketDateToBeFetched(tDate);
    setReturnDateToBeFetched(rDate);
    setMeeshoDateToBeFetched(meeshoDate);
  };

  //  api sync
  const fetchApiMeeshoOrder = async (SyncDataType) => {
    if (tpEmail.length === 0) {
      enqueueSnackbar('Please Select Account', { variant: 'error' });
      return;
    }
    if (SyncDataType === 'RTS') {
      SetIsMeeshoReadyToShipLoading(true);
    }
    if (SyncDataType === 'RTN') {
      SetIsMeeshoReturnLoading(true);
    }
    if (SyncDataType === 'CNL') {
      SetIsMeeshoCancelLoading(true);
    }
    if (SyncDataType === 'PAYMENT_PAID') {
      SetMeeshoPayPaidLoading(true);
    }
    if (SyncDataType === 'PAYMENT_OUT') {
      SetMeeshoPayOutLoading(true);
    }
    if (SyncDataType === 'allInventory') {
      SetIsMeeshoInventoryLoading(true);
    }
    if (SyncDataType === 'SHIPPED') {
      SetIsMeeshoShippedLoading(true);
    }

    if (SyncDataType === 'BAG_LINK') {
      SetIsMeeshoBagLinkLoading(true);
    }
    const body = {
      username: user?.email ? user.email : localUser,
      tpEmailIds: tpEmail,
      subAccountIds: supplierId,
      cNames: companyName,
      syncDataType: SyncDataType,
      acType: 'meesho',
    };
    if (
      (isAdmin && SyncDataType === 'RTN') ||
      SyncDataType === 'SHIPPED' ||
      SyncDataType === 'PAYMENT_PAID' ||
      SyncDataType === 'PAYMENT_OUT'
    ) {
      body.start_date = meeshoDateToBeFetched.start;
      body.end_date = meeshoDateToBeFetched.end;
    }
    const response = await CreatePostAPICall(enqueueSnackbar, SyncOrders, body);
    if (response) {
      enqueueSnackbar(response.data.body.message);
      if (SyncDataType === 'RTS') {
        SetIsMeeshoReadyToShipLoading(false);
      }
      if (SyncDataType === 'RTN') {
        SetIsMeeshoReturnLoading(false);
      }
      if (SyncDataType === 'CNL') {
        SetIsMeeshoCancelLoading(false);
      }
      if (SyncDataType === 'PAYMENT_PAID') {
        SetMeeshoPayPaidLoading(false);
      }
      if (SyncDataType === 'PAYMENT_OUT') {
        SetMeeshoPayOutLoading(false);
      }
      if (SyncDataType === 'allInventory') {
        SetIsMeeshoInventoryLoading(false);
      }
      if (SyncDataType === 'SHIPPED') {
        SetIsMeeshoShippedLoading(false);
      }
      if (SyncDataType === 'BAG_LINK') {
        SetIsMeeshoBagLinkLoading(false);
      }
    }
  };

  const getFormattedData = (data, companyData, skuImageData) => {
    const formattedData = [];
    Object.entries(data).forEach(([key, value]) => {
      const account = [];
      const platformData = {};
      platformData.platformName = key;
      Object.entries(value).forEach(([key, value]) => {
        const accountData = {};
        const supplier = [];
        accountData.accountId = key;
        Object.entries(value).forEach(([key, value]) => {
          const supplierTempData = {};
          const supplierData = [];
          supplierTempData.supplierId = key;
          Object.entries(value).forEach(([key, value]) => {
            const data = {};
            data.sub_order_no = value[0];
            data.order_date = value[1];
            data.invoice_date = value[2];
            data.pick_up_date = value[3];
            data.is_cancelled = value[4];
            data.sku = value[5];
            data.size = value[6];
            data.self_cost = value[7];
            data.sent_quantity = value[8];
            data.latest_sent_awb = value[9];
            data.sent_Courier = value[10];
            data.scanned_Date = value[11];
            data.retry_attempt = value[12];
            data.shipment_type = value[13];
            data.exchange_entry = value[14];
            data.return_date = value[15];
            data.return_type = value[16];
            data.order_Status = value[17];
            data.return_quantity = value[18];
            data.return_courier = value[19];
            data.latest_Return_Awb = value[20];
            data.delivery_date = value[21];
            data.is_received = value[22];
            data.latest_received_date = value[23];
            data.re_condition = value[24];
            data.return_Charge = value[25];
            data.pending_reported = value[26];
            data.received_reported = value[27];
            data.pod_reported = value[28];
            data.payment_reported = value[29];
            data.rejected_date = value[30];
            data.bagPacketId = value[34];
            data.bagPacketScanStatus = value[35];
            data.paymentData = [];
            value[31].forEach((payments) => {
              const paymentDataInner = {};
              paymentDataInner.Payment_Date = payments[0] ? changeDateFormat(payments[0]) : '-';
              paymentDataInner.Pay_Status = payments[1] ? payments[1] : '-';
              paymentDataInner.Final_Settlement = payments[2] ? payments[2] : 0;
              paymentDataInner.Compensation = payments[3] ? payments[3] : '-';
              paymentDataInner.Claims = payments[4] ? payments[4] : '-';
              paymentDataInner.Return_Charge = payments[5] ? payments[5] : '-';
              paymentDataInner.Recovery = payments[6] ? payments[6] : '-';
              data.paymentData.push(paymentDataInner);
            });
            data.ticketData = [];
            value[32].forEach((tickets) => {
              const ticketDataInner = {};
              ticketDataInner.Ticket_Id = tickets[0] ? tickets[0] : '-';
              ticketDataInner.Sub_Order_No = tickets[1] ? tickets[1] : '-';
              ticketDataInner.Reported_Date = tickets[2] ? changeDateFormat(tickets[2]) : '-';
              ticketDataInner.Is_Resolved = tickets[3] ? changeDataFormat(tickets[3]) : '-';
              ticketDataInner.Report_Type = tickets[4] ? tickets[4] : '-';
              ticketDataInner.Last_Update = tickets[5] ? tickets[5] : '-';
              ticketDataInner.Can_Reopen = tickets[6] ? tickets[6] : '-';
              ticketDataInner.Ticket_Status = tickets[6] ? tickets[6] : '-';
              ticketDataInner.Reopen_Date = tickets[6] ? changeDateFormat(tickets[6]) : '-';
              data.ticketData.push(ticketDataInner);
            });
            data.isCustomerCredited = value[33];

            if (!refSubOrders.includes(data.sub_order_no)) {
              supplierData.push(data);
              refSubOrders.push(data.sub_order_no);
            }
          });
          supplierTempData.supplierData = supplierData;
          supplier.push(supplierTempData);
        });
        accountData.accountData = supplier;
        account.push(accountData);
      });
      platformData.platformData = account;
      formattedData.push(platformData);
    });
    if (formattedData.length > 0) {
      formattedData.forEach((item) => {
        item.platformData.forEach((platformData) => {
          platformData.accountData.forEach((accountData) => {
            accountData.supplierData.forEach((supData) => {
              const tempData = {};
              tempData.sub_order_no = supData.sub_order_no ? supData.sub_order_no : ' - ';
              tempData.type = item.platformName ? item.platformName : ' - ';
              if (tempData.type === 'glowroad') {
                tempData.supplier_id = accountData.supplierId ? accountData.supplierId.split('@')[0] : ' - ';
              } else {
                tempData.supplier_id = accountData.supplierId ? accountData.supplierId : ' - ';
              }
              tempData.pending_reported = supData.pending_reported ? supData.pending_reported : ' - ';
              tempData.received_reported = supData.received_reported ? supData.received_reported.toString() : 'false';
              tempData.pod_reported = supData.pod_reported ? supData.pod_reported : ' - ';
              tempData.payment_reported = supData.payment_reported ? supData.payment_reported : ' - ';
              tempData.retry_attempt = supData.retry_attempt ? supData.retry_attempt : '';
              tempData.net_amount = supData.net_amount ? supData.net_amount : ' - ';
              tempData.sent_awb = supData.latest_sent_awb ? supData.latest_sent_awb : ' - ';
              tempData.order_date = supData.order_date ? supData.order_date.toString() : ' - ';
              tempData.invoice_date = supData.invoice_date ? supData.invoice_date.toString() : ' - ';
              tempData.pick_up_date = supData.pick_up_date ? supData.pick_up_date.toString() : ' - ';
              tempData.self_cost = supData.self_cost ? supData.self_cost : ' - ';
              tempData.shipment_type = supData.shipment_type ? supData.shipment_type : ' - ';
              tempData.is_cancelled = supData.is_cancelled ? supData.is_cancelled : 'false';
              tempData.isCustomerCredited = supData.isCustomerCredited ? supData.isCustomerCredited : 'false';
              tempData.sku = supData.sku ? supData.sku : 'NA';
              tempData.sent_quantity = supData.sent_quantity ? supData.sent_quantity.toString() : '0';
              tempData.size = supData.size ? supData.size : 'NA';
              tempData.return_charge = supData.return_Charge ? supData.return_Charge : ' - ';
              tempData.latest_Return_Awb = supData.latest_Return_Awb ? supData.latest_Return_Awb : ' - ';
              const ReturnTypeMapping = {
                customer_return: 'Customer Return',
                'Customer Return': 'Customer Return',
                RET: 'Customer Return',
                CUSTOMER_RETURN: 'Customer Return',
                courier_return: 'Courier Return (RTO)',
                'Courier Return (RTO)': 'Courier Return (RTO)',
                RTO: 'Courier Return (RTO)',
                COURIER_RETURN: 'Courier Return (RTO)',
              };

              tempData.return_type = ReturnTypeMapping[supData.return_type]
                  ? ReturnTypeMapping[supData.return_type]
                  : ' - ';
              tempData.order_Status = supData.order_Status ? supData.order_Status.toString() : ' - ';
              tempData.scanned_Date = supData.scanned_Date ? supData.scanned_Date.toString() : '';
              tempData.id = tempFilteredData.length + 1;
              tempData.company = companyData[item.platformName][accountData.supplierId]
                  ? companyData[item.platformName][accountData.supplierId]
                  : ' - ';
              tempData.skuImage = skuImageData[supData.sku] ? skuImageData[supData.sku] : '';
              tempData.delivery_partner = supData.sent_Courier ? supData.sent_Courier : '';
              tempData.email = platformData.accountId ? platformData.accountId : ' - ';
              tempData.exchange_entry =
                  supData.exchange_entry.length > 0
                      ? supData.exchange_entry.map((x) => ({
                        received: x.Received ? x.Received : 'false',
                        pickupDate: x['Pickup Date'] ? x['Pickup Date'] : ' - ',
                        expectedDispatchDate: x['Expected Dispatch Date'] ? x['Expected Dispatch Date'] : ' - ',
                        receivedDate: x['Received Date'] ? x['Received Date'] : ' - ',
                        deliveryPartner: x['Delivery Partner'] ? x['Delivery Partner'] : ' - ',
                        condition: x.Condition ? x.Condition : ' - ',
                        sentQuantity: x['Sent Quantity'] ? x['Sent Quantity'] : ' - ',
                        isReported: x['Is Reported'] ? x['Is Reported'] : 'false',
                        sentAwb: x['Sent Awb'] ? x['Sent Awb'] : ' - ',
                        scannedDate: x['Scanned Date'] ? x['Scanned Date'] : ' - ',
                        Size: x.Size ? x.Size : ' - ',
                      }))
                      : [];
              tempData.exchange_entry1 = tempData.exchange_entry ? JSON.stringify(tempData.exchange_entry) : ' - ';
              tempData.return_date = supData.return_date ? supData.return_date : ' - ';
              tempData.return_quantity = supData.return_quantity ? supData.return_quantity : '0';
              tempData.return_courier = supData.return_courier ? supData.return_courier : '';
              tempData.delivery_date = supData.delivery_date ? supData.delivery_date : ' - ';
              tempData.is_received = supData.is_received ? supData.is_received : 'false';
              tempData.latest_received_date = supData.latest_received_date
                  ? supData.latest_received_date.toString()
                  : '';
              tempData.re_condition = supData.re_condition ? supData.re_condition : ' - ';
              tempData.rejected_date = supData.rejected_date ? supData.rejected_date.toString() : ' - ';
              tempData.bagPacketId = supData.bagPacketId ? supData.bagPacketId : ' - ';
              tempData.bagPacketScanStatus = supData.bagPacketScanStatus ? supData.bagPacketScanStatus : ' - ';
              let netAmount = 0.0;
              let payStatus = 'NOT LISTED';
              supData.paymentData.forEach((payData) => {
                netAmount += parseFloat(payData.Final_Settlement);
                if (payData.Pay_Status === 'OUTSTANDING') {
                  payStatus = 'OUTSTANDING';
                } else if (payData.Pay_Status === 'PAID' && payStatus !== 'OUTSTANDING') {
                  payStatus = 'PAID';
                }
              });
              tempData.Pay_Status = payStatus;
              supData.ticketData.forEach((ticData) => {
                // For Export Data Into Excel
                tempData.Ticket_Id = ticData.Ticket_Id ? ticData.Ticket_Id : ' - ';
                tempData.Reported_Date = ticData.Reported_Date ? ticData.Reported_Date : ' - ';
                tempData.Is_Resolved = ticData.Is_Resolved ? ticData.Is_Resolved : ' - ';
                tempData.Report_Type = ticData.Report_Type ? ticData.Report_Type : ' - ';
                tempData.Last_Update = ticData.Last_Update ? ticData.Last_Update : ' - ';
                tempData.Can_Reopen = ticData.Can_Reopen ? ticData.Can_Reopen : ' - ';
                tempData.Ticket_Status = ticData.Ticket_Status ? ticData.Ticket_Status : ' - ';
                tempData.Reopen_Date = ticData.Reopen_Date ? ticData.Reopen_Date : ' - ';
              });
              tempData.Net_Amount = netAmount;
              tempData.paymentData = supData.paymentData ? supData.paymentData : [];
              tempData.paymentDataExportExcel = tempData.paymentData ? JSON.stringify(tempData.paymentData) : ' - ';
              tempData.ticketData = supData.ticketData ? supData.ticketData : [];
              tempData.ticketDataExportExcel = tempData.ticketData ? JSON.stringify(tempData.ticketData) : ' - ';
              tempFilteredData.push(tempData);
            });
          });
        });
      });
    }
  };

  const getAccountData = async (bodyObj, emails) => {
    const response = await CreatePostAPICall(enqueueSnackbar, OrderAnalysis, bodyObj);
    if (response) {
      getFormattedData(
          response.data.body,
          response.data.companyName ? response.data.companyName : [],
          response.data.skuImageData ? response.data.skuImageData : [],
      );
      if (response.data.last_key) {
        bodyObj.last_key = response.data.last_key;
        await getAccountData(bodyObj, emails);
      }
    }
  };

  const viewAllData = async (value, sub) => {
    let bodyObj = {};
    const emails = [];
    const values = [];
    const cNames = [];
    const accountType = [];

    selectedAccountsWithOutAllOptions.forEach((item) => {
      emails.push(item.Email);
      values.push(item.value);
      cNames.push(item.cName);
      accountType.push(item.AcType);
    });
    for (let i1 = 0; i1 < emails.length; i1++) {
      bodyObj = {
        username: user?.email ? user.email : localUser,
        tpEmailIds: [emails[i1]],
        subAccountIds: [values[i1]],
        cNames: [cNames[i1]],
        acType: [accountType[i1]],
      };

      bodyObj[`${sub}`] = value;
      await getAccountData(bodyObj, emails);
    }

  };
  //  api sync
  const fetchAutoMeeshoOrder = async (SyncDataType) => {
    tempFilteredData = []
    refSubOrders = [];
    SetIsMeeshoAutoBagLinkLoading(true)
    const dateInterval = getFormattedDate()
    // console.log("dateInterval",dateInterval)
    await viewAllData(dateInterval, "pickup_date");
    const meeshoBagLinkData = document.getElementById('bag_link_data');
    meeshoBagLinkData.hidden = true;
    meeshoBagLinkData.value = JSON.stringify(tempFilteredData);
    // console.log("tempFilteredData",tempFilteredData)
    SetIsMeeshoAutoBagLinkLoading(false)
  };

  return (
    <Container maxWidth={themeStretch ? false : 'xlg'}>
      <Grid sx={{ p: 2 }} container spacing={3}>
        <Grid item xs={12} md={3}>
          {/*<Typography variant="h6">*/}
          {/*  <div style={{ display: 'flex' }}>*/}
          {/*    <Iconify*/}
          {/*      icon={'ant-design:cloud-sync-outlined'}*/}
          {/*      width={25}*/}
          {/*      height={25}*/}
          {/*      style={{ color: '#7635DC', marginRight: '5px' }}*/}
          {/*    />{' '}*/}
          {/*    Api Sync*/}
          {/*  </div>*/}
          {/*</Typography>*/}

          {/*<Divider sx={{ mt: 1, mb: 3, width: '110px', backgroundColor: '#7635DC', height: '2px' }} />*/}

          {/*<div style={{ Width: '100%' }}>*/}
          {/*  <AccountSelector allData={allAccountsData} onChangeHandler={handleSelectedAccount} />*/}
          {/*</div>*/}
          {/*<br />*/}
          {/*<Box*/}
          {/*  sx={{*/}
          {/*    display: 'grid',*/}
          {/*    rowGap: 2,*/}
          {/*  }}*/}
          {/*>*/}
          {/*  {isAdmin && (*/}
          {/*    <>*/}
          {/*      <RangePicker*/}
          {/*        format={'DD/MM/YY'}*/}
          {/*        onChange={(value, event) => getdate(value, event)}*/}
          {/*        className={'createDateRangePicker'}*/}
          {/*        pupClassName={'createDateRangePicker'}*/}
          {/*        {...(!isLight && {*/}
          {/*          className: 'date-picker-dark',*/}
          {/*        })}*/}
          {/*      />*/}
          {/*      <Button*/}
          {/*        variant="outlined"*/}
          {/*        startIcon={<Iconify icon={'ion:return-up-forward-outline'} size="medium" />}*/}
          {/*        onClick={() => {*/}
          {/*          fetchApiMeeshoOrder('SHIPPED');*/}
          {/*        }}*/}
          {/*        disabled={isMeeshoShippedLoading}*/}
          {/*      >*/}
          {/*        Sync Shipped Orders*/}
          {/*      </Button>*/}
          {/*    </>*/}
          {/*  )}*/}
          {/*  <Button*/}
          {/*    variant="outlined"*/}
          {/*    startIcon={<Iconify icon={'ion:return-up-forward-outline'} size="medium" />}*/}
          {/*    onClick={() => {*/}
          {/*      fetchApiMeeshoOrder('RTS');*/}
          {/*    }}*/}
          {/*    disabled={isMeeshoReadyToShipLoading}*/}
          {/*  >*/}
          {/*    Sync Ready To Ship Orders*/}
          {/*  </Button>*/}
          {/*  <Button*/}
          {/*    variant="outlined"*/}
          {/*    startIcon={<Iconify icon={'ion:return-down-back-outline'} size="medium" />}*/}
          {/*    onClick={() => {*/}
          {/*      fetchApiMeeshoOrder('RTN');*/}
          {/*    }}*/}
          {/*    disabled={isMeeshoReturnLoading}*/}
          {/*  >*/}
          {/*    Sync Return Orders*/}
          {/*  </Button>*/}

          {/*  <Button*/}
          {/*    variant="outlined"*/}
          {/*    startIcon={<Iconify icon={'iconoir:cancel'} size="medium" />}*/}
          {/*    onClick={() => {*/}
          {/*      fetchApiMeeshoOrder('CNL');*/}
          {/*    }}*/}
          {/*    disabled={isMeeshoCancelLoading}*/}
          {/*  >*/}
          {/*    Sync Cancel Orders*/}
          {/*  </Button>*/}

          {/*  {isAdmin && (*/}
          {/*    <Button*/}
          {/*      variant="outlined"*/}
          {/*      startIcon={<Iconify icon={'mdi:recurring-payment'} size="medium" />}*/}
          {/*      onClick={() => {*/}
          {/*        fetchApiMeeshoOrder('PAYMENT_PAID');*/}
          {/*      }}*/}
          {/*      disabled={isMeeshoPayPaidLoading}*/}
          {/*    >*/}
          {/*      Sync Paid Payment*/}
          {/*    </Button>*/}
          {/*  )}*/}

          {/*  /!*<Button*!/*/}
          {/*  /!*    variant="outlined"*!/*/}
          {/*  /!*    startIcon={<Iconify icon={'mdi:payment-clock'} size="medium" />}*!/*/}
          {/*  /!*    onClick={() => {*!/*/}
          {/*  /!*      fetchApiMeeshoOrder('PAYMENT_OUT');*!/*/}
          {/*  /!*    }}*!/*/}
          {/*  /!*    disabled={isMeeshoPayOutLoading}*!/*/}
          {/*  /!*>*!/*/}
          {/*  /!*  Sync Outstanding Payment*!/*/}
          {/*  /!*</Button>*!/*/}

          {/*  <Button*/}
          {/*    variant="outlined"*/}
          {/*    startIcon={<Iconify icon={'material-symbols:link'} size="medium" />}*/}
          {/*    onClick={() => {*/}
          {/*      fetchApiMeeshoOrder('BAG_LINK');*/}
          {/*    }}*/}
          {/*    disabled={isMeeshoBagLinkLoading}*/}
          {/*  >*/}
          {/*    Bag Link*/}
          {/*  </Button>*/}

          {/*  /!*{isAdmin && (*!/*/}
          {/*  /!*  <Button*!/*/}
          {/*  /!*    variant="outlined"*!/*/}
          {/*  /!*    startIcon={<Iconify icon={'ant-design:stock-outlined'} size="medium" />}*!/*/}
          {/*  /!*    onClick={() => {*!/*/}
          {/*  /!*      fetchApiMeeshoOrder('allInventory');*!/*/}
          {/*  /!*    }}*!/*/}
          {/*  /!*    disabled={isMeeshoInventoryLoading}*!/*/}
          {/*  /!*  >*!/*/}
          {/*  /!*    Sync Inventory*!/*/}
          {/*  /!*  </Button>*!/*/}
          {/*  /!*)}*!/*/}
          {/*</Box>*/}

          {/*<Divider*/}
          {/*  orientation="horizontal"*/}
          {/*  sx={{ marginBottom: '15px', marginTop: '15px', borderStyle: 'dashed', borderWidth: '0.5px' }}*/}
          {/*/>*/}

          <Typography variant="h6">
            <div style={{ display: 'flex' }}>
              <Iconify
                icon={'ant-design:cloud-sync-outlined'}
                width={25}
                height={25}
                style={{ color: '#7635DC', marginRight: '5px' }}
              />{' '}
              Auto Sync

            </div>
          </Typography>


          <Divider sx={{ mt: 1, mb: 3, width: '120px', backgroundColor: '#7635DC', height: '2px' }} />

          {isDesktopEnvironment ? (
            <>
              <div style={{ Width: '100%' }}>
                <AccountSelector allData={allAccountsData} onChangeHandler={handleSelectedAccount} />
                {accountError ? <span style={{ color: 'red' }}>{accountError}</span> : null}
              </div>
              <br />
              <Box
                sx={{
                  display: 'grid',
                  rowGap: 2,
                }}
              >
                <input id="generate_pending_list_data" type="text" hidden />
                <input id="bag_link_data" type="text" hidden />
                <>
                  <Button
                    id={`${platForm}SyncPending`}
                    style={{ display: rerender ? '' : 'none' }}
                    variant="outlined"
                    startIcon={<Iconify icon={'ic:baseline-pending-actions'} size="medium" />}
                    onClick={() => {
                      validation();
                    }}
                  >
                    Generate Pending List
                  </Button>

                  <Button
                    id={`${platForm}PendExcelDownload`}
                    style={{ display: rerender ? 'none' : '' }}
                    onClick={handleDownloadExcel}
                    variant="outlined"
                    startIcon={<Iconify icon={'fluent:clipboard-checkmark-20-regular'} size="medium" />}
                  >
                    Download Pending List Excel
                  </Button>

                  {isDesktopEnvironment && isAdmin ? (
                    <RangePicker
                      format={'DD-MM-YYYY'}
                      className={'createDateRangePicker'}
                      pupClassName={'createDateRangePicker'}
                      onChange={(value, event) => getdate(value, event)}
                      {...(!isLight && {
                        className: 'date-picker-dark',
                      })}
                    />
                  ) : null}

                  {/*<Button*/}
                  {/*  id={`${platForm}SyncTicketResponse`}*/}
                  {/*  variant="outlined"*/}
                  {/*  startIcon={<Iconify icon={'ion:ticket'} size="medium" />}*/}
                  {/*>*/}
                  {/*  Sync Ticket Response*/}
                  {/*</Button>*/}

                  {/* TODO Uncomment For Payment  Buttons */}

                  {/*{isDesktopEnvironment && isAdmin ? (*/}
                  {/*  <RangePicker*/}
                  {/*    format={'YYYY-MM-DD'}*/}
                  {/*    className={'createDateRangePicker'}*/}
                  {/*    pupClassName={'createDateRangePicker'}*/}
                  {/*    onChange={(value, event) => getdate(value, event)}*/}
                  {/*    {...(!isLight && {*/}
                  {/*      className: 'date-picker-dark',*/}
                  {/*    })}*/}
                  {/*  />*/}
                  {/*) : null}*/}
                  {/*<Button*/}
                  {/*  id={`${platForm}SyncPaidPayment`}*/}
                  {/*  variant="outlined"*/}
                  {/*  startIcon={<Iconify icon={'mdi:recurring-payment'} size="medium" />}*/}
                  {/*>*/}
                  {/*  Sync Paid Payment*/}
                  {/*</Button>*/}

                  {/*<Button*/}
                  {/*  id={`${platForm}SyncOutstandingPayment`}*/}
                  {/*  variant="outlined"*/}
                  {/*  startIcon={<Iconify icon={'mdi:payment-clock'} size="medium" />}*/}
                  {/*>*/}
                  {/*  Sync Outstanding Payment*/}
                  {/*</Button>*/}
                </>

                <>
                  <Button
                    id={`${platForm}SyncRts`}
                    variant="outlined"
                    startIcon={<Iconify icon={'ion:return-up-forward-outline'} size="medium" />}
                  >
                    Sync Ready To Ship Orders
                  </Button>
                  <>
                    {isDesktopEnvironment && isAdmin ? (
                      <RangePicker
                        format={'YYYY-MM-DD'}
                        className={'createDateRangePicker'}
                        pupClassName={'createDateRangePicker'}
                        onChange={(value, event) => getdate(value, event)}
                        {...(!isLight && {
                          className: 'date-picker-dark',
                        })}
                      />
                    ) : null}
                  </>

                  <Button
                    id={`${platForm}SyncRtn`}
                    variant="outlined"
                    startIcon={<Iconify icon={'ion:return-down-back-outline'} size="medium" />}
                  >
                    Sync Return Orders
                  </Button>

                  <Button
                    id={`${platForm}SyncCnl`}
                    variant="outlined"
                    startIcon={<Iconify icon={'iconoir:cancel'} size="medium" />}
                  >
                    Sync Cancel Orders
                  </Button>
                  <LoadingButton
                      variant="outlined"
                      id={`${platForm}BagLink`}
                      startIcon={<Iconify icon={'material-symbols:link'} size="medium" />}
                      // onClick={async () => {
                      //   await fetchAutoMeeshoOrder('BAG_LINK')
                      // }}
                      loading={isMeeshoAutoBagLinkLoading}
                  >
                    Bag Link
                  </LoadingButton>

                  <>
                    {showsacnpackbutton.includes(localUser) ?
                  <Button
                    id={`${platForm}ScanPack`}
                    variant="outlined"
                    startIcon={<Iconify icon={'ant-design:scan-outlined'} size="medium" />}
                  >
                    Make Scan&Pack Request
                  </Button> : null
                  }
                </>

                  {/*<Button*/}
                  {/*  id={`${platForm}SyncInventory`}*/}
                  {/*  variant="outlined"*/}
                  {/*  startIcon={<Iconify icon={'ant-design:stock-outlined'} size="medium" />}*/}
                  {/*>*/}
                  {/*  Sync Inventory*/}
                  {/*</Button>*/}
                </>
              </Box>
            </>
          ) : (
            <div>
              <p style={{ color: '#FFCC00',marginBottom:'15px' }}> Please Use BuissMaster OMS Desktop App For Data Sync. </p>
              <Button fullWidth variant="contained" href={"https://buissmaster-desktop-mac.s3.ap-south-1.amazonaws.com/autoUpdate/APP/BuissMaster+OMS+DesktopAPP+V3.exe"}>Download App</Button>
            </div>
          )}
        </Grid>

        <Divider
          orientation="vertical"
          variant="middle"
          flexItem
          sx={{
            marginLeft: '26px',
            borderStyle: 'dashed',
            borderRightWidth: 'initial',
            marginBottom: '-10px',
            marginTop: '20px',
          }}
        />

        <Grid item xs={12} md={8.5}>
          <Typography variant="h6">
            <div style={{ display: 'flex' }}>
              <Iconify
                icon={'ri:file-excel-2-line'}
                width={25}
                height={25}
                style={{ color: 'green', marginRight: '5px' }}
              />{' '}
              Upload Excel
            </div>
          </Typography>

          <Divider sx={{ mt: 1, mb: 3, width: '143px', backgroundColor: '#7635DC', height: '2px' }} />
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={5.5}>
              <Tabs
                allowScrollButtonsMobile
                variant="scrollable"
                scrollButtons="auto"
                value={meeshoTab}
                onChange={onChangeMeeshoTab}
              >
                {meeshoTabs.map((tab) => (
                  <Tab disableRipple key={tab.value} label={capitalCase(tab.value)} icon={tab.icon} value={tab.value} />
                ))}
              </Tabs>
              <Box sx={{ mb: 2 }} />
              {meeshoTabs.map((tab) => {
                const isMatched = tab.value === meeshoTab;
                return isMatched && <Box key={tab.value}>{tab.component}</Box>;
              })}
            </Grid>

            <Grid item xs={12} sm={6} md={6} sx={{ marginLeft: 'auto' }}>
              <Tabs
                allowScrollButtonsMobile
                variant="scrollable"
                scrollButtons="auto"
                value={currentUploadTab}
                onChange={onChangeUploadTab}
              >
                {uploadTabs.map((tab) => (
                  <Tab disableRipple key={tab.value} label={capitalCase(tab.value)} icon={tab.icon} value={tab.value} />
                ))}
              </Tabs>
              <Box sx={{ mb: 2 }} />
              {uploadTabs.map((tab) => {
                const isMatched = tab.value === currentUploadTab;
                return isMatched && <Box key={tab.value}>{tab.component}</Box>;
              })}
            </Grid>
          </Grid>
        </Grid>

        {/* Pending list DialogBox */}
        <BootstrapDialog
          sx={{ '& .MuiDialog-paper': { width: '100%' } }}
          maxWidth="md"
          onClose={closePendingDownload}
          aria-labelledby="customized-dialog-title"
          open={pendingListModal}
        >
          <BootstrapDialogTitle id="customized-dialog-title" onClose={closePendingDownload}>
            Download Pending List
          </BootstrapDialogTitle>
          <Divider orientation="horizontal" flexItem />
          <DialogContent>
            <Grid sx={{ p: 2 }} container spacing={2}>
              <Grid item xs={12} md={9}>
                <FormControl fullWidth>
                  <Autocomplete
                    multiple
                    limitTags={2}
                    onChange={(e, value) => getSelectedData(value)}
                    id="multiple-limit-tags"
                    options={options}
                    // value={filterODRStatus}
                    renderInput={(params) => <TextField {...params} label={'Select Account'} />}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3} style={{ textAlign: 'end' }}>
                <Button variant="contained" onClick={getPendingList} style={{ padding: '15px 22px 15px 15px' }}>
                  Download Pending List
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </BootstrapDialog>
      </Grid>

      <Input id="selectedAccountsData" value={JSON.stringify(selectedAccountsWithOutAllOptions)} type="hidden" />

      <Input id="selectedPaidDateToSyncData" value={JSON.stringify(paymentDateToBeFetched)} type="hidden" />

      <Input id="selectedTicketDateToSyncResponseData" value={JSON.stringify(ticketDateToBeFetched)} type="hidden" />

      <Input id="selectedReturnDateToSyncResponseData" value={JSON.stringify(returnDateToBeFetched)} type="hidden" />
    </Container>
  );
}
