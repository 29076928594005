import React, {useState} from 'react';
import {LoadingButton} from "@mui/lab";
import {Container, Grid} from '@mui/material';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import Page from '../../../components/Page';
import urlConstants from '../../../routes/urlConstants';
import useSettings from '../../../hooks/useSettings';
import LabelProcess from "../labelProcess/LabelProcess";
import AcceptList from "../acceptList/AcceptList";

export default function MeeshoUtilities() {
    const {themeStretch} = useSettings();
    const [isLabelON, setIsLabelON] = useState(true);
    const [isAcceptListON, setIsAcceptListON] = useState(false);

    return (
        <Page title="Utilities">
            <Container maxWidth={themeStretch ? false : 'xl'}>
                <HeaderBreadcrumbs
                    heading="Utilities"
                    links={[{name: 'Dashboard', href: urlConstants.HOME_PAGE}, {name: 'Utilities'}, {name: 'Meesho'}]}
                />
                <Grid container spacing={3} style={{marginBottom:"3rem"}}>
                    <Grid item xs={12}>

                        {/* <LoadingButton onClick={() => { */}
                        {/*    setIsLabelON(true) */}
                        {/*    setIsAcceptListON(false) */}
                        {/* }} style={{marginRight: "2rem"}} variant={isLabelON ? 'contained' :  'outlined'}> */}
                        {/*    Label Process */}
                        {/* </LoadingButton> */}

                        {/* <span style={{ borderRight: "1px solid #000", height: "100%" }} /> */}
                        <LoadingButton style={{marginRight: "1rem",marginLeft:"1rem"}}
                        //                onClick={() => {
                        //     setIsLabelON(false)
                        //     setIsAcceptListON(true)
                        // }}
                                       variant={isLabelON ? 'contained' : 'outlined'}>Accept List</LoadingButton>
                    </Grid>

                </Grid>
                {/* {isLabelON && ( */}
                {/*    <LabelProcess acType={'meesho'}  utilityType={"labelProcess"} /> */}
                {/* )} */}

                {isLabelON && (
                    <AcceptList acType={'meesho'} utilityType={"generatePendingList"} />
                )}
            </Container>
        </Page>
    );
}
