import { useState } from 'react';

export default function useTabs(defaultValues) {
  const [currentTab, setCurrentTab] = useState(defaultValues || '');
  const [currentUploadTab, setCurrentUploadTab] = useState(defaultValues || '');
  const [meeshoTab, setMeeshoTab] = useState(defaultValues || '');
  const [currentBMUserTab, setCurrentBMUserTab] = useState(defaultValues || '');

  return {
    currentTab,
    onChangeTab: (event, newValue) => {
      setCurrentTab(newValue);
    },
    setCurrentTab,
    currentUploadTab,
    onChangeUploadTab: (event, newValue) => {
      setCurrentUploadTab(newValue);
    },
    meeshoTab,
    onChangeMeeshoTab: (event, newValue) => {
      setMeeshoTab(newValue);
    },
    setMeeshoTab,
    currentBMUserTab,
    onChangeBMUserTab: (event, newValue) => {
      setCurrentBMUserTab(newValue);
    },
    setCurrentBMUserTab,
  };
}
