/* eslint-disable */
import { capitalCase } from 'change-case';
import {
  Autocomplete,
  Box,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import useAuth from '../../../hooks/useAuth';
import { CreatePostAPICall, JiomartSaveExcelData } from '../../../utils/apis';
import {
  CapitalizeFirstLetter,
  ExcelDateToJSDate,
  getAccountsData,
  getAccountsDataByType,
  getUserAccountDetails,
  ReadExcelFile,
  setAccountSelectorData,
  SystemColor,
} from '../../../utils/utilities';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import * as xlsx from 'xlsx';
import useSettings from '../../../hooks/useSettings';
import useTabs from '../../../hooks/useTabs';
import Iconify from '../../../components/Iconify';
import { UploadMultiFile } from '../../../components/upload';
import AccountSelector from '../../../components/Common/AccountSelector';
import { JiomartValidateUploadedFile } from '../excelValidations/JiomartValidation';

export default function JiomartSyncOrder() {
  const platForm = 'jiomart';
  const { enqueueSnackbar } = useSnackbar();
  const { themeStretch } = useSettings();
  const { user } = useAuth();
  const localUser = window.localStorage.getItem('userEmailId');
  const { currentUploadTab, onChangeUploadTab } = useTabs('Order');
  const [files, setFiles] = useState([]);
  const [fileType, setFileType] = useState('');
  const [dataUploadLoader, setDataUploadLoader] = useState(false);
  // Below 5Stats Is For Account Selector
  const [allAccountsData, setAllAccountsData] = useState([]);
  const [selectedAccountsWithOutAllOptions, setSelectedAccountsWithOutAllOptions] = useState([]);
  const [supplierId, setSupplierId] = useState([]);
  const [tpEmail, setTpEmail] = useState([]);
  const [companyName, setCompanyName] = useState([]);
  const [jiomartAccountListOptions, setJiomartAccountListOptions] = useState([]);
  const [selectedAccountForExcelUpload, setSelectedAccountForExcelUpload] = useState(null);

  // for getting account data from localstorage
  useEffect(async () => {
    getAccountsDataByType(platForm).then((filteredData) => {
      setAllAccountsData(filteredData);
    });
    // from here starts account selector data for Excel upload selector
    const accountData = await getAccountsData();
    const objAcGlowroad = [];
    accountData.forEach((item) => {
      if (item.AcType === 'jiomart') {
        objAcGlowroad.push({
          value: item.Account.SupplierId,
          label: `${CapitalizeFirstLetter(item.AcType)} - ${item.Account.Email} - ${item.Account.Name}`,
          AcType: item.AcType,
          Email: item.Account.Email,
          Name: item.Account.Name,
        });
      }
    });
    setJiomartAccountListOptions(objAcGlowroad);
  }, []);

  // handle of autocomplete for platform wise showing account list
  const handleSelectedAccount = async (event, value) => {
    const response = await setAccountSelectorData(value, allAccountsData);
    setSelectedAccountsWithOutAllOptions(response[1]);
    if (event.length !== 0) {
      const selectedAccountData = await getUserAccountDetails(response[1]);
      setSupplierId(selectedAccountData.supplierIds);
      setTpEmail(selectedAccountData.tpEmailids);
      setCompanyName(selectedAccountData.cName);
    }
  };

  // Excel Upload Container for JioMart,
  const OrderUploadContainer = () =>
    dataUploadLoader ? (
      <div style={{ width: '100%', textAlign: 'center', padding: '100px' }}>
        <CircularProgress sx={{ color: SystemColor.originColor }} size={24} color="inherit" />
        <br />
        {fileType === 'Order'
          ? 'Please Wait While Uploading RTS Order Data...'
          : 'Please Wait While Uploading Shipped Order Data...'}
      </div>
    ) : (
      <>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <UploadMultiFile
              multiple
              acceptTypes={'.csv'}
              files={fileType === 'Order' ? files : []}
              title={'Drop Or Select RTS Order Excel'}
              onDrop={(e) => {
                handleDropMultiFile(e, 'Order');
              }}
              onRemove={handleRemoveFile}
              onRemoveAll={handleRemoveAllFiles}
              onUpload={() => {
                handleOnUpload('Order', files);
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <UploadMultiFile
              multiple
              acceptTypes={'.csv'}
              files={fileType === 'shippedOrder' ? files : []}
              title={'Drop Or Select Shipped Order Excel'}
              onDrop={(e) => {
                handleDropMultiFile(e, 'shippedOrder');
              }}
              onRemove={handleRemoveFile}
              onRemoveAll={handleRemoveAllFiles}
              onUpload={() => {
                handleOnUpload('shippedOrder', files);
              }}
            />
          </Grid>
        </Grid>
      </>
    );

  // return upload container
  const ReturnUploadContainer = () =>
    dataUploadLoader ? (
      <>
        <div style={{ width: '100%', textAlign: 'center', padding: '100px' }}>
          <CircularProgress sx={{ color: SystemColor.originColor }} size={24} color="inherit" />
          <br />
          Please Wait While Uploading Return Data...
        </div>
      </>
    ) : (
      <>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <UploadMultiFile
              multiple
              acceptTypes={'.csv'}
              files={fileType === 'RTN' ? files : []}
              title={'Drop Or Select Return Excel'}
              onDrop={(e) => {
                handleDropMultiFile(e, 'RTN');
              }}
              onRemove={handleRemoveFile}
              onRemoveAll={handleRemoveAllFiles}
              onUpload={() => {
                handleOnUpload('RTN', files);
              }}
            />
          </Grid>
        </Grid>
      </>
    );

  // payment upload container
  const PaymentUploadContainer = () =>
    dataUploadLoader ? (
      <div style={{ width: '100%', textAlign: 'center', padding: '100px' }}>
        <CircularProgress sx={{ color: SystemColor.originColor }} size={24} color="inherit" />
        <br />
        Please Wait While Uploading Payment Data...
      </div>
    ) : (
      <>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <UploadMultiFile
              multiple
              acceptTypes={'.csv'}
              files={fileType === 'Payment' ? files : []}
              title={'Drop Or Select Payment Excel'}
              onDrop={(e) => {
                handleDropMultiFile(e, 'Payment');
              }}
              onRemove={handleRemoveFile}
              onRemoveAll={handleRemoveAllFiles}
              onUpload={() => {
                handleOnUpload('Payment', files);
              }}
            />
          </Grid>
        </Grid>
      </>
    );

  // inventory upload container
  const InventoryUploadContainer = () =>
    dataUploadLoader ? (
      <div style={{ width: '100%', textAlign: 'center', padding: '100px' }}>
        <CircularProgress sx={{ color: SystemColor.originColor }} size={24} color="inherit" />
        <br />
        Please Wait While Uploading Inventory Data...
      </div>
    ) : (
      <>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <UploadMultiFile
              multiple
              acceptTypes={'.csv'}
              files={fileType === 'Inventory' ? files : []}
              title={'Drop Or Select Inventory Excel'}
              onDrop={(e) => {
                handleDropMultiFile(e, 'Inventory');
              }}
              onRemove={handleRemoveFile}
              onRemoveAll={handleRemoveAllFiles}
              onUpload={() => {
                handleOnUpload('Inventory', files);
              }}
            />
          </Grid>
        </Grid>
      </>
    );

  // JioMart Upload Tabs
  const uploadTabs = [
    {
      value: 'Order',
      icon: <Iconify icon={'tabler:truck-delivery'} width={25} height={25} />,
      component: <OrderUploadContainer />,
    },
    {
      value: 'Return',
      icon: <Iconify icon={'tabler:truck-return'} width={25} height={25} />,
      component: <ReturnUploadContainer />,
    },
    {
      value: 'Payment',
      icon: <Iconify icon={'mdi:recurring-payment'} width={25} height={25} />,
      component: <PaymentUploadContainer />,
    },
    {
      value: 'Inventory',
      icon: <Iconify icon={'vaadin:stock'} width={20} height={20} />,
      component: <InventoryUploadContainer />,
    },
  ];

  // handle drop file
  const handleDropMultiFile = useCallback(
    (acceptedFiles, fileType) => {
      const newFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      setFiles([...newFiles]);
      setFileType(fileType);
    },
    [files]
  );

  // Remove Files
  const handleRemoveFile = (inputFile) => {
    const filtered = files.filter((file) => file !== inputFile);
    setFiles(filtered);
  };

  // Remove Files
  const handleRemoveAllFiles = () => {
    setFiles([]);
  };

  // handle upload
  const handleOnUpload = (type, files) => {
    setDataUploadLoader(true);
    const file = files[0];
    const reader = new FileReader();
    if (file) {
      reader.onload = (e) => {
        uploadFileData(e, type, files);
      };
      reader.readAsArrayBuffer(file);
    } else {
      handleRemoveAllFiles();
    }
  };

  const handleShowErrorMessage = (message) => {
    setDataUploadLoader(false);
    handleRemoveAllFiles();
    enqueueSnackbar(message, { variant: 'error' });
  };

  // Upload Excel data start
  const uploadFileData = async (e, type, files) => {
    setDataUploadLoader(true);
    let fileSupId;
    const excelActualData = ReadExcelFile(e, 0);
    const dataForUpload = [];
    let apiType;
    const apiCallSize = 100;
    const isValidated = JiomartValidateUploadedFile(excelActualData[0], type);
    if (type === 'Order') {
      apiType = 'excelRTSOrdersData';
      fileSupId = excelActualData[1][8];
      if (fileSupId === undefined) {
        handleShowErrorMessage("Can't Find SubAccount Id");
        return;
      }

      if (isValidated) {
        excelActualData.forEach((item, index) => {
          if (index >= 1) {
            dataForUpload.push([item[0], item[4], item[5], item[9]]);
          }
        });
      } else {
        handleShowErrorMessage('Selected file field(s) not valid.');
        return;
      }
    }

    if (type === 'shippedOrder') {
      apiType = 'excelShippedOrdersData';
      fileSupId = excelActualData[1][4];
      if (fileSupId === undefined) {
        handleShowErrorMessage("Can't Find SubAccount Id");
        return;
      }
      if (isValidated) {
        excelActualData.forEach((item, index) => {
          if (index >= 1) {
            dataForUpload.push([
              item[0],
              item[5] ? ExcelDateToJSDate(item[5]) : 'NA',
              item[8],
              item[9],
              item[13],
              item[15] ? item[15] : 'NA',
              item[16] ? item[16] : 'NA',
            ]);
          }
        });
      } else {
        handleShowErrorMessage('Selected file field(s) not valid.');
        return;
      }
    }

    if (type === 'RTN') {
      apiType = 'excelRtnData';
      fileSupId = excelActualData[1][8];
      if (fileSupId === undefined) {
        handleShowErrorMessage("Can't Find SubAccount Id");
        return;
      }
      if (isValidated) {
        excelActualData.forEach((item, index) => {
          if (index >= 1) {
            dataForUpload.push([item[0], item[4], item[5], item[9]]);
          }
        });
      } else {
        handleShowErrorMessage('Selected file field(s) not valid.');
        return;
      }
    }

    if (type === 'Payment') {
      apiType = 'excelPaymentPaid';
      if (isValidated) {
        excelActualData.forEach((item, index) => {
          if (index >= 3) {
            {
              item[3] === 'Receivable'
                ? dataForUpload.push([item[0] ? ExcelDateToJSDate(item[0]) : 'NA', 'PAID', item[5], item[8]])
                : null;
            }
          }
        });
      } else {
        handleShowErrorMessage('Selected file field(s) not valid.');
        return;
      }

      if (dataForUpload.length !== 0) {
        if (selectedAccountForExcelUpload) {
          uploadExcelFileData(
            selectedAccountForExcelUpload.Email,
            selectedAccountForExcelUpload.value,
            apiType,
            dataForUpload,
            type,
            files
          );
        } else {
          handleShowErrorMessage('Please Select Account');
          return;
        }
      } else {
        handleShowErrorMessage('Invalid File Or Data.');
        return;
      }
    }

    if (type === 'Inventory') {
      apiType = 'excelInventoryData';
      if (isValidated) {
        excelActualData.forEach((item, index) => {
          if (index >= 1) {
            dataForUpload.push([item[1], item[3], item[6]]);
          }
        });
      } else {
        handleShowErrorMessage('Selected file field(s) not valid.');
        return;
      }
    }

    const selectedSupIdUserData = allAccountsData.find((x) => x.value === fileSupId);
    if (selectedSupIdUserData && type !== 'Payment') {
      if (dataForUpload.length !== 0) {
        uploadExcelFileData(selectedSupIdUserData.Email, fileSupId, apiType, dataForUpload, type, files);
      } else {
        handleShowErrorMessage('No Data In This File.');
        return;
      }
    } else if (type !== 'Payment') {
      handleShowErrorMessage('Invalid File Or Data.');
      return;
    }
  };

  // final api call to upload excel
  const uploadExcelFileData = async (
    tpEmailId,
    subAccountId,
    syncDataType,
    dataForUpload,
    fileType,
    files,
    token = null
  ) => {
    const items = dataForUpload.slice(0, 100);
    dataForUpload.splice(0, 100);
    setDataUploadLoader(true);
    const body = {
      username: user?.email ? user.email : localUser,
      tpEmailId,
      subAccountId,
      syncDataType,
      body: items,
    };
    if (token) body.token = token;
    // console.log('body', body);
    const response = await CreatePostAPICall(enqueueSnackbar, JiomartSaveExcelData, body);
    if (response) {
      if (dataForUpload.length > 0) {
        uploadExcelFileData(tpEmailId, subAccountId, syncDataType, dataForUpload, fileType, files, response.data.token);
      } else {
        const tempFiles = files.slice(1);
        if (tempFiles.length === 0) {
          enqueueSnackbar('File Saved Successfully');
          handleRemoveAllFiles();
          setDataUploadLoader(false);
        } else {
          handleOnUpload(fileType, tempFiles);
        }
      }
    } else {
      handleRemoveAllFiles();
      setDataUploadLoader(false);
    }
  };

  return (
    <Container maxWidth={themeStretch ? false : 'xlg'}>
      <Grid sx={{ p: 2 }} container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6">
            <div style={{ display: 'flex' }}>
              <Iconify
                icon={'ri:file-excel-2-line'}
                width={25}
                height={25}
                style={{ color: 'green', marginRight: '5px' }}
              />{' '}
              Upload Excel
            </div>
          </Typography>
          <Divider sx={{ mt: 1, mb: 3, width: '143px', backgroundColor: '#7635DC', height: '2px' }} />
          <Grid item xs={12} md={12} sm={12} lg={12}>
            <Tabs
              allowScrollButtonsMobile
              variant="scrollable"
              scrollButtons="auto"
              value={currentUploadTab}
              onChange={onChangeUploadTab}
            >
              {uploadTabs.map((tab) => (
                <Tab disableRipple key={tab.value} label={capitalCase(tab.value)} icon={tab.icon} value={tab.value} />
              ))}
            </Tabs>
            <Box sx={{ mb: 2 }} />
            {currentUploadTab === 'Payment' ? (
              <>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Autocomplete
                      id="jiomart-excel-upload-selector"
                      onChange={(event, value) => {
                        setSelectedAccountForExcelUpload(value);
                      }}
                      value={selectedAccountForExcelUpload}
                      options={jiomartAccountListOptions.map((option) => option)}
                      renderInput={(params) => <TextField {...params} label="Select JioMart Account*" />}
                    />
                    <Box sx={{ mb: 2 }} />
                  </Grid>
                </Grid>
              </>
            ) : null}
            {uploadTabs.map((tab) => {
              const isMatched = tab.value === currentUploadTab;
              return isMatched && <Box key={tab.value}>{tab.component}</Box>;
            })}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
