import { styled } from '@mui/material/styles';
import {
  Typography,
  Card,
  CardContent,
  Box,
  Divider,
  List,
  ListItemButton,
  ListItemAvatar,
  Avatar,
  Grid,
} from '@mui/material';
import React from 'react';
import { SeoIllustration } from '../../../assets';
import Label from '../../../components/Label';
import Scrollbar from '../../../components/Scrollbar';
import { isSuperAdminEnvironment } from '../../../utils/utilities';
import NotificationIcon from '../../../assets/NotificationIcon';
import CountdownTimer from "../../../components/Common/CustomTimer";

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  backgroundColor: theme.palette.primary.lighter,
}));

export default function AppWelcome({ displayName, staticOrdersData, notifications , greeting}) {
  return (
    <RootStyle>
      <Grid container>
        <Grid item lg={4} md={4} sm={12} xs={12} style={{ alignSelf: 'center' }}>
          <CardContent
            sx={{
              p: { md: 0 },
              pl: { md: 5 },
              color: 'grey.800',
            }}
          >
            <Typography gutterBottom variant="h4">
              Hello, BuissMaster User
              <br /> {greeting},
              <br /> {!displayName ? '...' : displayName}!
            </Typography>

            <Typography variant="body3" sx={{ pb: { xs: 3, xl: 5 }, maxWidth: 480, mx: 'auto' }}>
              {isSuperAdminEnvironment && (
                <>
                  Your Account Balance is <b>{staticOrdersData?.ac_balance?.toFixed(2)} ₹</b>
                  <br />
                </>
              )}
              Your Available Orders is <b> {staticOrdersData?.orders}</b>

            </Typography>
          </CardContent>
          <div style={{paddingLeft: '12px', paddingTop: '10px', fontSize: '12px'}}>
            <span style={{color:"red"}}> <strong>*Please note:</strong> </span> Available orders are not subject to transfer to another account and non-refundable.
          </div>
        </Grid>
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <div style={{ padding: 24 }}>
            <SeoIllustration />
          </div>
        </Grid>

        <Grid item lg={4} md={4} sm={12} xs={12} style={{ alignSelf: 'center' }}>
          <CardContent
            sx={{
              p: 0,
              width: '100%',
              color: 'grey.800',
              margin: { xs: 'auto', md: 'inherit' },
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 1.5 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography gutterBottom variant="subtitle1" sx={{ textAlign: 'center' }}>
                  Notification(s)
                </Typography>
              </Box>
              <Label variant={'ghost'} color={'error'}>
                {notifications.length}
              </Label>
            </Box>

            <Divider sx={{ borderStyle: 'dashed', marginRight: '10px' }} />

            <Scrollbar sx={{ maxHeight: '260px' }}>
              {notifications.length > 0 ? (
                <List disablePadding>
                  {notifications.map((notification) => (
                    <ListItemButton sx={{ py: 2 }} key={notification.id}>
                      <ListItemAvatar>
                        <Avatar sx={{ bgcolor: 'background.neutral' }}>
                          <NotificationIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <div>
                        <p>{notification.content}</p>
                        {notification.validUntil && <CountdownTimer endTime={notification.validUntil}/>}
                      </div>

                    </ListItemButton>
                  ))}
                </List>
              ) : (
                <div style={{ margin: 10, textAlign: 'center' }}>No record found</div>
              )}
            </Scrollbar>
          </CardContent>
        </Grid>
      </Grid>
    </RootStyle>
  );
}
