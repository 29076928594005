/* eslint-disable */
import { Button, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DatePicker } from 'antd';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import useAuth from '../../../../hooks/useAuth';
import {CreateExcelFile, JSONToCSVConvertor} from '../../../../utils/utilities';
import DataGridTable from '../../../../components/Common/DataGridTable';
import { CreatePostAPICall, V2Management } from '../../../../utils/apis';
import moment from 'moment';

const GetUserTransaction = () => {
  const { user } = useAuth();
  const localUser = window.localStorage.getItem('userEmailId');
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';
  const { enqueueSnackbar } = useSnackbar();
  const { RangePicker } = DatePicker;
  const [userTransactionData, setUserTransactionData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);

  const usersTXN = [
    {
      accessorKey: 'Id',
      header: 'ID',
      size: 10,
    },
    {
      accessorKey: 'Date',
      header: 'Date',
      size: 40,
    },
    {
      accessorKey: 'RQ Type',
      header: 'RQ Type',
      size: 30,
    },
    {
      accessorKey: 'Accounts',
      header: 'Accounts',
      size: 30,
    },
    {
      accessorKey: 'Total Orders',
      header: 'Total Orders',
      size: 20,
    },
    {
      accessorKey: 'Billed Orders',
      header: 'Billed Orders',
      size: 20,
    },
    {
      accessorKey: 'Balance Before',
      header: 'Balance Before',
      size: 20,
    },
    {
      accessorKey: 'Balance After',
      header: 'Balance After',
      size: 20,
    },
    {
      accessorKey: 'DateTime',
      header: 'Date Time',
      size: 20,
    },
  ];

  const getUserTXNToday = async () => {
    setDataLoading(true);
    const body = {
      username: user?.email ? user.email : localUser,
      filter: 'Date',
      actionType: 'getUserTransaction',
      start_date: moment().format('DD/MM/YY'),
      end_date: moment().format('DD/MM/YY'),
      panel: 'admin',
      subPanel: 'adminManagement',
    };
    const response = await CreatePostAPICall(enqueueSnackbar, V2Management, body);
    if (response) {
      setUserTransactionData(response.data.body);
    }
    setDataLoading(false);
  };

  const getUserTXNTodayAll = async () => {
    setDataLoading(true);
    const body = {
      username: user?.email ? user.email : localUser,
      filter: 'All',
      actionType: 'getUserTransaction',
      panel: 'admin',
      subPanel: 'adminManagement',
    };
    const response = await CreatePostAPICall(enqueueSnackbar, V2Management, body);
    if (response) {
      setUserTransactionData(response.data.body);
    }
    setDataLoading(false);
  };

  const getUserTXNSelectByDate = async (value, date) => {
    if (date[0] === '' || date[1] === '') {
      setUserTransactionData([]);
      return null;
    }

    const parseDate = dateString => {
      const [day, month, year] = dateString.split('/');
      return new Date(`20${year}`, month - 1, day); // Subtract 1 from month to match JavaScript Date object
    };

    const formatToServerDate = date => {
      return moment(date).format('DD/MM/YY');
    };

    const startDate = parseDate(date[0]);
    const endDate = parseDate(date[1]);
    const intervalDays = 15;

    let currentDate = startDate;
    let promises = [];

    while (currentDate <= endDate) {
      const partEndDate = new Date(currentDate.getTime() + intervalDays * 24 * 60 * 60 * 1000);
      const batchEndDate = currentDate.getTime() + (intervalDays - 1) * 24 * 60 * 60 * 1000;

      if (batchEndDate >= endDate.getTime()) {
        partEndDate.setTime(endDate.getTime());
      }

      const body = {
        username: user?.email ? user.email : localUser,
        filter: 'Date',
        actionType: 'getUserTransaction',
        start_date: formatToServerDate(currentDate),
        end_date: formatToServerDate(partEndDate),
        panel: 'admin',
        subPanel: 'adminManagement',
      };

      promises.push(
        axios.post(V2Management, body).then(response => {
          if (response && response.data) {
            return response.data.body;
          }
          return [];
        }).catch(error => {
          console.error('Error fetching data:', error.message);
          return [];
        })
      );

      currentDate = new Date(partEndDate.getTime() + 24 * 60 * 60 * 1000);
    }

    try {
      setDataLoading(true);
      const allDataArrays = await Promise.all(promises);
      const flattenedData = allDataArrays.flat();
      setUserTransactionData(flattenedData);
    } catch (error) {
      console.error('Error fetching data:', error.message);
    } finally {
      setDataLoading(false);
    }
  };








  const exportToCsvData = () => {
    if (userTransactionData.length !== 0) {
      CreateExcelFile(userTransactionData, 'Get User Transaction', true);
    }
  };

  return (
    <>
      <div style={{ diplay: 'flex' }}>
        <span style={{ marginRight: '20px' }}>Select Transaction Date :</span>
        <RangePicker
          className={'createDateRangePicker'}
          pupClassName={'createDateRangePicker'}
          format={'DD/MM/YY'}
          onChange={(value, event) => getUserTXNSelectByDate(value, event)}
          {...(!isLight && {
            className: 'date-picker-dark',
          })}
        />
        <Button variant="outlined" onClick={getUserTXNToday} style={{ marginLeft: '50px', marginRight: '20px' }}>
          View Today's Transactions
        </Button>
        <Button variant="outlined" onClick={getUserTXNTodayAll}>
          View All Transactions
        </Button>
      </div>

      <div style={{ textAlign: 'end', marginBottom: '15px' }}>
        <Button
          variant="contained"
          onClick={() => {
            exportToCsvData();
          }}
        >
          Export Excel
        </Button>
      </div>

      <DataGridTable columns={usersTXN} data={userTransactionData} sorting isLoading={dataLoading} />
    </>
  );
};

export default GetUserTransaction;
