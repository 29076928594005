/* eslint-disable */
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import { capitalCase } from 'change-case';
import {
    Autocomplete,
    Box,
    Button,
    CircularProgress,
    Container,
    Divider,
    Grid,
    Input,
    InputLabel,
    MenuItem,
    Select,
    Tab,
    Tabs,
    TextField,
    Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DatePicker } from 'antd';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import useSettings from '../../../hooks/useSettings';
import useTabs from '../../../hooks/useTabs';
import useAuth from '../../../hooks/useAuth';
import { CreatePostAPICall, CitymallSaveExcelData, SyncOrders } from '../../../utils/apis';
import {
    isAdmin,
    SystemColor,
    getUserAccountDetails,
    getAccountsDataByType,
    setAccountSelectorData,
    ReadExcelFile,
    getAccountsData,
    CapitalizeFirstLetter, isDesktopEnvironment, uploadSyncFileS3, GetTodayDateTime,
} from '../../../utils/utilities';
import Iconify from '../../../components/Iconify';
import { UploadMultiFile } from '../../../components/upload';
import AccountSelector from '../../../components/Common/AccountSelector';
import { citymallFinancialExcelValidation, citymallExcelValidation } from '../excelValidations/CitymallValidation';
import XLSX from "xlsx";

function removeDuplicates(arr) {
    return arr.filter((item, index) => {
        return arr.findIndex((x) => x[0] === item[0] && x[1] === item[1]) === index;
    });
}

export default function CitymallSyncOrder() {
    const platForm = 'citymall';
    const { RangePicker } = DatePicker;
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();
    const isLight = theme.palette.mode === 'light';
    const { themeStretch } = useSettings();
    const { user } = useAuth();
    const localUser = window.localStorage.getItem('userEmailId');
    const { currentUploadTab, onChangeUploadTab } = useTabs('Order');
    const [files, setFiles] = useState([]);
    const [fileType, setFileType] = useState('');
    const [dataUploadLoader, setDataUploadLoader] = useState(false);
    const [citymallFiles, setCitymallFiles] = useState([]);
    const [allAccountsData, setAllAccountsData] = useState([]);
    const [citymallDateToBeFetched, setCitymallDateToBeFetched] = useState({});
    const [reGenerateLabelLoading, setReGenerateLabelLoading] = useState(false);
    const [isReadyToShipLoading, SetIsReadyToShipLoading] = useState(false);
    const [generateLabelLoading, setGenerateLabelLoading] = useState(false);
    const [markRTDLoading, setmarkRTDLoading] = useState(false);
    const [isReturnLoading, SetIsReturnLoading] = useState(false);
    const [isCancelLoading, SetIsCancelLoading] = useState(false);
    const [isInventoryLoading, SetIsInventoryLoading] = useState(false);
    const [isShippedLoading, SetIsShippedLoading] = useState(false);
    const [selectedAccountsWithOutAllOptions, setSelectedAccountsWithOutAllOptions] = useState([]);
    const [supplierId, setSupplierId] = useState([]);
    const [tpEmail, setTpEmail] = useState([]);
    const [companyName, setCompanyName] = useState([]);
    const [maskingList, setMaskingList] = useState([]);
    const [citymallAccountListOptions, setCitymallAccountListOptions] = useState([]);
    const [selectedAccountForExcelUpload, setSelectedAccountForExcelUpload] = useState(null);
    const [selectedUploadType, setSelectedUploadType] = useState('');

    // for getting account data from localstorage
    useEffect(async () => {
        getAccountsDataByType(platForm).then((filteredData) => {
            setAllAccountsData(filteredData);
        });

        // from here starts account selector data for Excel upload selector
        const accountData = await getAccountsData();
        const objAcCitymall = [];
        accountData.forEach((item) => {
            if (item.AcType === 'citymall') {
                objAcCitymall.push({
                    value: item.Account.SupplierId,
                    label: `${CapitalizeFirstLetter(item.AcType)} - ${item.Account.Email} - ${item.Account.Name}`,
                    AcType: item.AcType,
                    Email: item.Account.Email,
                    // isMaskingEnabled: (item.isMaskingEnabled ? item.isMaskingEnabled : false),
                    Name: item.Account.Name,
                });
            }
        });
        setCitymallAccountListOptions(objAcCitymall);
    }, []);

    // handle of autocomplete for platform wise showing account list
    const handleSelectedAccount = async (event, value) => {
        const response = await setAccountSelectorData(value, allAccountsData);
        setSelectedAccountsWithOutAllOptions(response[1]);
        if (event.length !== 0) {
            const selectedAccountData = await getUserAccountDetails(response[1]);
            setSupplierId(selectedAccountData.supplierIds);
            setTpEmail(selectedAccountData.tpEmailids);
            setCompanyName(selectedAccountData.cName);
            setMaskingList(selectedAccountData.isMaskingEnabled);
        }
    };

    function processDuplicates(inputData) {
        const resultDetails = {};

        for (const data of inputData) {
            const key = `${data[0]}_${data[2]}`; // Combine date and order number as the key
            if (!resultDetails[key]) {
                // If the key doesn't exist, initialize with all values
                resultDetails[key] = data.slice();
            } else {
                // If the key exists, increase the amount at index 1
                resultDetails[key][1] += data[1];
            }
        }

        return Object.values(resultDetails);
    }


    // excel upload api call
    const uploadCitymallExcelFileData = async (
        tpEmailIds,
        supplierIds,
        dataForUpload,
        syncDataType,
        fileType,
        files,
        token = null,
        cName = null
    ) => {
        const result = processDuplicates(dataForUpload);
        const items = result.slice(0, 100);
        result.splice(0, 100);
        setDataUploadLoader(true);
        const body = {
            syncDataType,
            username: user?.email ? user.email : localUser,
            tpEmailId: tpEmailIds,
            subAccountId: supplierIds,
            dataToSave: items,
        };

        if (token) body.token = token;
        if (cName) body.cName = cName;

        const response = await CreatePostAPICall(enqueueSnackbar, CitymallSaveExcelData, body);
        if (response) {
            if (result.length > 0) {
                await uploadCitymallExcelFileData(
                    tpEmailIds,
                    supplierIds,
                    result,
                    syncDataType,
                    fileType,
                    files,
                    response.data.token,
                    response.data.cName
                );
            } else {
                const tempFiles = files.slice(1);
                if (tempFiles.length === 0) {
                    handleRemoveAllFiles();
                    setDataUploadLoader(false);
                    setSelectedUploadType('');
                    enqueueSnackbar('File Saved Successfully.');
                } else {
                    handleOnUploadCitymall(fileType, tempFiles);
                }
            }
        } else {
            handleRemoveAllFiles();
            setSelectedUploadType('');
            setDataUploadLoader(false);
        }

    };

    const handleCitymallExportExcel = async (paymentData) => {
        // console.log("paymentData", paymentData)

        var headerPaymentExcel = [
            'order_id',
            'payment_date',
            'sku_id',
            'quantity',
            'final_settlement_amount',
        ]
        const XLSX = require('xlsx');
        const pySheetData = [headerPaymentExcel, ...paymentData.map(obj => Object.values(obj))];

        const workbook = XLSX.utils.book_new();

        const worksheetPy = XLSX.utils.json_to_sheet(pySheetData);
        XLSX.utils.book_append_sheet(workbook, worksheetPy, "Payment");

        var wbout = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

        /* prepare data for POST */
        var blob = new Blob([new Uint8Array(wbout)], { type: "application/octet-stream" });

        const fileName = `ReCreatedFile ${GetTodayDateTime()}.xlsx`;

        const file = new File([blob], fileName, { type: 'application/octet-stream' });
        // //
        // var a = document.createElement("a");
        // document.body.appendChild(a);
        // a.style = "display: none";
        // var url = window.URL.createObjectURL(blob);
        // // console.log("url ==",url);
        // a.href = url;
        // a.download = fileName;
        // a.click();
        // window.URL.revokeObjectURL(url);
        return file;
    };

    // formatting excel data and calling api function
    const onLoadCitymallFileUploadDetail = async (e, type, files) => {
        const dataForUpload = [];
        const apiType = 'excelOrdersData';
        // console.log("selectedUploadType", selectedUploadType)

        if (type === 'Payment' && selectedUploadType !== 'adCost') {
            const dataForUploadPayment = [];
            const dataForUploadMpFee = [];
            const supplierIds = [];
            const tpEmails = [];
            allAccountsData.forEach((value) => {
                if (value.AcType === 'citymall') {
                    supplierIds.push(value.value);
                    tpEmails.push(value.Email);
                }
            });

            const PaymentExcelActualData = ReadExcelFile(e, 0);
            // console.log("PaymentExcelActualData == ",PaymentExcelActualData[0]);

            if (PaymentExcelActualData[1] === undefined) {
                setDataUploadLoader(false);
                handleRemoveAllFiles();
                enqueueSnackbar('Please Upload Valid File.', { variant: 'error' });
                return;
            }
            const isValidForPayment = citymallExcelValidation(PaymentExcelActualData[0], 'Payment');
            if (!isValidForPayment) {
                setDataUploadLoader(false);
                enqueueSnackbar('Selected file field(s) not valid payment file.', { variant: 'error' });
                handleRemoveAllFiles();
                return null;
            }
            const orderidIndex = PaymentExcelActualData[0].findIndex((x) => x === 'order_id');
            const paymentdateIndex = PaymentExcelActualData[0].findIndex((x) => x === 'payment_date');
            const sellerSKUIndex = PaymentExcelActualData[0].findIndex((x) => x === 'sku_id');
            const quantityIndex = PaymentExcelActualData[0].findIndex((x) => x === 'quantity');
            const finalsettlementamountIndex = PaymentExcelActualData[0].findIndex((x) => x === 'final_settlement_amount');
            PaymentExcelActualData.forEach((item, index) => {
                if (index >= 1) {
                    dataForUploadPayment.push(
                        {
                            order_id: item[orderidIndex],
                            payment_date: item[paymentdateIndex],
                            sku_id: item[sellerSKUIndex],
                            quantity: item[quantityIndex],
                            final_settlement_amount: item[finalsettlementamountIndex],
                        }
                    );
                }
            });
            if (dataForUploadPayment.length === 0) {
                setDataUploadLoader(false);
                enqueueSnackbar('Selected file has no data for Payment.', { variant: 'error' });
                handleRemoveAllFiles();
                return null;
            }
            let fileToUpload = await handleCitymallExportExcel(dataForUploadPayment);

            enqueueSnackbar(`File Created Successfully`, { variant: 'success' });
            if (fileToUpload){
                const data = await uploadSyncFileS3(fileToUpload, user.email, selectedAccountForExcelUpload.Email, selectedAccountForExcelUpload.value, selectedAccountForExcelUpload.cName, "PaidPayment", fileToUpload.name,"citymall", enqueueSnackbar)
                if (data){
                    enqueueSnackbar('File Uploaded Successfully', { variant: 'success' });
                }
            }
            handleRemoveAllFiles();
            setDataUploadLoader(false);
        }
        else if (type === 'Payment' && selectedUploadType === 'adCost') {
            const adCostExcelActualData = ReadExcelFile(e, 0)
            // console.log("adCostExcelActualData",adCostExcelActualData);
            if (adCostExcelActualData.length <= 0) {
                setDataUploadLoader(false);
                enqueueSnackbar('Selected file is empaty', { variant: 'error' });
                handleRemoveAllFiles();
                return null;
            }
            if (adCostExcelActualData.length > 0) {
                const file = files[0];
                if (file) {
                    const data = await uploadSyncFileS3(file, user.email, selectedAccountForExcelUpload.Email
                        , selectedAccountForExcelUpload.value
                        , selectedAccountForExcelUpload.Name,
                        "AdsCost", file.name, "citymall", enqueueSnackbar)
                    if (data) {
                        handleRemoveAllFiles();
                        setSelectedUploadType('');
                        setDataUploadLoader(false);
                    }
                    // console.log("data", data)
                }

                // const dataForUploadAdCost = []
                // adCostExcelActualData.forEach((item, index) => {
                //     if (index >= 1) {
                //         const CampaignID = item[0]
                //         const CampaignStatus = item[5]
                //         const DeductionDate = item[7].split("T")[0]
                //         const EndDate = item[8] ? item[8].split("T")[0] : ''
                //         const DeductionAmount = item[11] ? parseFloat(item[11]) : 0
                //         dataForUploadAdCost.push([CampaignID, DeductionDate, CampaignStatus, DeductionAmount, EndDate]);
                //     }
                // })
                // await uploadCitymallExcelFileData(
                //     selectedAccountForExcelUpload.Email,
                //     selectedAccountForExcelUpload.value,
                //     dataForUploadAdCost,
                //     'excelAdsCostData',
                //     type,
                //     files
                // );
                // console.log("dataForUploadAdCost",dataForUploadAdCost)
            }
        }
    };

    const uploadExcelFileMpFeeData = async (
        tpEmailId,
        supplierId,
        dataForUpload,
        apiType,
        token = null,
        cName = null
    ) => {
        const items = dataForUpload.slice(0, 100);
        dataForUpload.splice(0, 100);
        setDataUploadLoader(true);
        const body = {
            username: user?.email ? user.email : localUser,
            tpEmailId,
            subAccountId: supplierId,
            syncDataType: apiType,
            dataToSave: items,
        };
        if (token) body.token = token;
        if (cName) body.cName = cName;

        const response = await CreatePostAPICall(enqueueSnackbar, CitymallSaveExcelData, body);
        if (response) {
            if (dataForUpload.length > 0) {
                return uploadExcelFileMpFeeData(
                    tpEmailId,
                    supplierId,
                    dataForUpload,
                    apiType,
                    response.data.token,
                    response.data.cName
                );
            } else {
                return true;
            }
        } else {
            handleRemoveAllFiles();
            setDataUploadLoader(false);
            setSelectedUploadType('');
            return false;
        }
    };

    // on load file
    const handleOnUploadCitymall = (type, files) => {

        setDataUploadLoader(true);
        const file = files[0];
        if (file) {
            const reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onload = (e) => {
                onLoadCitymallFileUploadDetail(e, type, files);
            };
        } else {
            handleRemoveAllFilesCitymall();
        }
    };

    // dropping file
    const handleDropMultiFileCitymall = useCallback(
        (acceptedFiles) => {
            const newFiles = acceptedFiles.map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                })
            );
            setCitymallFiles([...newFiles]);
        },
        [citymallFiles]
    );

    // remove all file
    const handleRemoveAllFilesCitymall = () => {
        setcitymallFiles([]);
    };

    // remove file
    const handleRemoveFileCitymall = (inputFile) => {
        const filtered = citymallFiles.filter((file) => file !== inputFile);
        setCitymallFiles(filtered);
    };

    // format data and post data and api call
    const formatDataAndPostDataOfCitymallFinancialReport = async (dataToPost, tpEmailId, subAccountId, files, token) => {
        setDataUploadLoader(true);
        const items = dataToPost.slice(0, 100);
        const body = {
            username: user?.email ? user.email : localUser,
            tpEmailId,
            subAccountId,
            syncDataType: 'excelFinancialReportData',
            dataToSave: items,
        };
        if (token) body.token = token;

        const response = await CreatePostAPICall(enqueueSnackbar, CitymallSaveExcelData, body);

        if (response) {
            if (dataToPost.length > 0) {
                dataToPost.splice(0, 100);
                await formatDataAndPostDataOfCitymallFinancialReport(
                    dataToPost,
                    tpEmailId,
                    subAccountId,
                    files,
                    response.data.token
                );
            } else {
                const tempFiles = files.slice(1);
                if (tempFiles.length === 0) {
                    setDataUploadLoader(false);
                    enqueueSnackbar('Saved Successfully.');
                    handleRemoveAllFilesCitymall();
                } else {
                    handleOnUploadCitymallFinancialReport(tempFiles);
                }
            }
        } else {
            setDataUploadLoader(false);
            handleRemoveAllFilesCitymall();
        }
    };

    // financial report api call
    const uploadFileDataForCitymallFinancialReport = async (e, files) => {
        const dataToPost = [];
        const splittedFileName = files[0].name.split(`_`);
        const sellerId = splittedFileName[0];
        const excelActualData = ReadExcelFile(e, 0, 'Orders');

        const isValid = citymallFinancialExcelValidation(excelActualData[1]);

        if (!isValid) {
            setDataUploadLoader(false);
            enqueueSnackbar('Selected file field(s) not valid.', { variant: 'error' });
            handleRemoveAllFilesCitymall();
            return null;
        }

        if (excelActualData[1] === undefined) {
            setDataUploadLoader(false);
            enqueueSnackbar('Selected file is Empty.', { variant: 'error' });
            handleRemoveAllFilesCitymall();
            return null;
        }

        if (sellerId === undefined) {
            setDataUploadLoader(false);
            handleRemoveAllFilesCitymall();
            enqueueSnackbar('Please Select Valid Seller File.', { variant: 'error' });
            return null;
        }

        //  dateIndex="Settlement Date","Date","Payment Date";
        //  settlementValueIndex="Bank Settlement Value (Rs.)","Settlement Value (Rs.)";
        //  orderItemIDIndex="Order item ID";
        //  marketplaceFeeIndex="Marketplace Fee (Rs.)";
        //  shippingFeeIndex="Shipping Fee (Rs.)";
        //  reverseShippingFeeIndex="Reverse Shipping Fee (Rs.)";
        //  orderDateIndex="Order Date";
        //  sellerSKUIndex="Seller SKU";
        //  quantityIndex="Quantity";
        //  returnTypeIndex="Return Type";

        const dateIndex = excelActualData[1].findIndex((x) => x === ' Settlement Date' || x === 'Payment Date');
        const settlementValueIndex = excelActualData[1].findIndex(
            (x) =>
                x === 'Bank Settlement Value (Rs.)' ||
                x === 'Settlement Value (Rs.)' ||
                x === 'Settlement Value (Rs.) \n= SUM(G:P)' ||
                x === 'Bank Settlement Value (Rs.) \n= SUM(J:R)' ||
                x === 'Bank Settlement Value (Rs.) \n= SUM(J:Q)'
        );
        const orderItemIDIndex = excelActualData[1].findIndex((x) => x === 'Order item ID');
        const marketplaceFeeIndex = excelActualData[1].findIndex(
            (x) =>
                x === 'Marketplace Fee (Rs.)' ||
                x === 'Marketplace Fee (Rs.)\n= SUM (U:AL)' ||
                x === 'Marketplace Fee (Rs.)\n= SUM (V:AI)' ||
                x === 'Marketplace Fee (Rs.)\n= SUM (U:AH)'
        );
        const shippingFeeIndex = excelActualData[1].findIndex((x) => x === 'Shipping Fee (Rs.)');
        const reverseShippingFeeIndex = excelActualData[1].findIndex((x) => x === 'Reverse Shipping Fee (Rs.)');
        const orderDateIndex = excelActualData[1].findIndex((x) => x === 'Order Date');
        const sellerSKUIndex = excelActualData[1].findIndex((x) => x === 'Seller SKU');
        const quantityIndex = excelActualData[1].findIndex((x) => x === 'Quantity');
        const returnTypeIndex = excelActualData[1].findIndex((x) => x === 'Return Type');

        excelActualData.forEach((item, index) => {
            if (index >= 3) {
                dataToPost.push([
                    item[dateIndex] ? item[dateIndex] : 'NA',
                    Number(item[settlementValueIndex]),
                    item[orderItemIDIndex],
                    Number(item[marketplaceFeeIndex]),
                    Number(item[shippingFeeIndex]),
                    Number(item[reverseShippingFeeIndex]),
                    item[orderDateIndex],
                    item[sellerSKUIndex],
                    Number(item[quantityIndex]),
                    item[returnTypeIndex],
                ]);
            }
        });

        // To Find SubAccountId And TpEmailId To Save Excel Payload
        const accountDetails = await getUserAccountDetails();
        const supplierIdList = accountDetails.supplierIds;
        const tpEmailIdList = accountDetails.tpEmailids;
        const sellerIdToList = accountDetails.sellerIds;
        const tpEmailIds = [];
        const subAccountIds = [];
        for (let i1 = 0; i1 < sellerIdToList.length; i1++) {
            if (sellerId.includes(sellerIdToList[i1])) {
                subAccountIds.push(supplierIdList[i1]);
                tpEmailIds.push(tpEmailIdList[i1]);
                break;
            }
        }

        await formatDataAndPostDataOfCitymallFinancialReport(dataToPost, tpEmailIds[0], subAccountIds[0], files);
        return null;
    };

    // handle on load financial report
    const handleOnUploadCitymallFinancialReport = (files) => {
        setDataUploadLoader(true);
        const file = files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                uploadFileDataForCitymallFinancialReport(e, files);
            };
            reader.readAsArrayBuffer(file);
        } else {
            handleRemoveAllFilesCitymall();
        }
    };

    // choose which type of payment you want to upload
    const paymentUploadList = [
        // { value: 'MpFee', name: 'MpFee' },
        { value: 'Payment', name: 'Payment' },
        // { value: 'adCost', name: 'AdsCost' },
    ];

    // choose which type of payment you want to upload
    const orderUploadList = [
        { value: 'MpFee', name: 'MpFee' },
        { value: 'Order', name: 'Order' },
        { value: 'FBF', name: 'FBF' },
    ];

    // Excel Upload Container for Citymall,
    const OrderUploadContainer = () =>
        dataUploadLoader ? (
            <div style={{ width: '100%', textAlign: 'center', padding: '100px' }}>
                <CircularProgress sx={{ color: SystemColor.originColor }} size={24} color="inherit" />
                <br />
                Please Wait While Uploading Orders Data...
            </div>
        ) : (
            <>
                {isAdmin ? (
                    <>
                        <FormControl required sx={{ width: '100%' }}>
                            <InputLabel required>Select Upload Type</InputLabel>
                            <Select
                                fullWidth
                                value={selectedUploadType}
                                placeholder={'Select Upload Type'}
                                onChange={(event) => {
                                    setSelectedUploadType(event.target.value);
                                }}
                                input={<OutlinedInput label="Select Upload Type" />}
                            >
                                {orderUploadList.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </Select>
                            <br />
                        </FormControl>
                    </>
                ) : null}
                <UploadMultiFile
                    multiple
                    acceptTypes={'.csv,.xls,.xlsx'}
                    files={fileType === 'Order' ? files : []}
                    title={'Drop Or Select Order Excel'}
                    onDrop={(e) => {
                        handleDropMultiFile(e, 'Order');
                    }}
                    onRemove={handleRemoveFile}
                    onRemoveAll={handleRemoveAllFiles}
                    onUpload={() => {
                        handleOnUploadCitymall('Order', files);
                    }}
                />

            </>
        );

    // Payment Excel Upload Container for Citymall,
    const PaymentUploadContainerCitymall = () =>
        dataUploadLoader ? (
            <div style={{ width: '100%', textAlign: 'center', padding: '100px' }}>
                <CircularProgress sx={{ color: SystemColor.originColor }} size={24} color="inherit" />
                <br />
                Please Wait While Uploading Payment Data...
            </div>
        ) : (
            <>
                {isAdmin ? (
                    <>
                        <FormControl required sx={{ width: '100%' }}>
                            <InputLabel required>Select Upload Type</InputLabel>
                            <Select
                                fullWidth
                                value={selectedUploadType}
                                placeholder={'Select Upload Type'}
                                onChange={(event) => {
                                    setSelectedUploadType(event.target.value);
                                }}
                                input={<OutlinedInput label="Select Upload Type" />}
                            >
                                {paymentUploadList.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </Select>
                            <br />
                        </FormControl>
                    </>
                ) : null}
                <UploadMultiFile
                    multiple
                    acceptTypes={'.xlsx, .xls, .csv'}
                    files={fileType === 'Payment' ? files : []}
                    title={'Drop Or Select Payment Excel'}
                    onDrop={(e) => {
                        handleDropMultiFile(e, 'Payment');
                    }}
                    onRemove={handleRemoveFile}
                    onRemoveAll={handleRemoveAllFiles}
                    onUpload={() => {
                        handleOnUploadCitymall('Payment', files);
                    }}
                />
            </>
        );

    // Financial Report Excel Upload Container for Citymall,
    const FinancialReportUploadContainerCitymall = () =>
        dataUploadLoader ? (
            <div style={{ width: '100%', textAlign: 'center', padding: '100px' }}>
                <CircularProgress sx={{ color: SystemColor.originColor }} size={24} color="inherit" />
                <br />
                Please Wait While Uploading Financial Report Data...
            </div>
        ) : (
            <UploadMultiFile
                multiple
                acceptTypes={'.xlsx, .xls, .csv'}
                files={citymallFiles}
                title={'Drop Or Select Financial Report Excel'}
                onDrop={handleDropMultiFileCitymall}
                onRemove={handleRemoveFileCitymall}
                onRemoveAll={handleRemoveAllFilesCitymall}
                onUpload={() => handleOnUploadCitymallFinancialReport(citymallFiles)}
            />
        );

    //  Citymall Tabs
    const currentUploadTabs = [
        {
            value: 'Order',
            icon: <Iconify icon={'tabler:truck-delivery'} width={25} height={25} />,
            component: <OrderUploadContainer />,
        },
        {
            value: 'Payment',
            icon: <Iconify icon={'mdi:recurring-payment'} width={25} height={25} />,
            component: <PaymentUploadContainerCitymall />,
        },
        {
            value: 'Financial Report',
            icon: <Iconify icon={'mdi:finance'} width={25} height={25} />,
            component: <FinancialReportUploadContainerCitymall />,
        },
    ];

    // handle drop multi file
    const handleDropMultiFile = useCallback(
        (acceptedFiles, fileType) => {
            const newFiles = acceptedFiles.map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                })
            );
            setFiles([...newFiles]);
            setFileType(fileType);
        },
        [files]
    );

    // handle remove file
    const handleRemoveFile = (inputFile) => {
        const filtered = files.filter((file) => file !== inputFile);
        setFiles(filtered);
    };

    // handle remove all files
    const handleRemoveAllFiles = () => {
        setFiles([]);
    };

    // for get date
    const getdate = async (value, dateString) => {
        const citymallDate = {
            start: dateString[0],
            end: dateString[1],
        };
        setCitymallDateToBeFetched(citymallDate);
    };

    // api sync
    const fetchApiCitymallOrders = async (SyncDataType) => {
        if (tpEmail.length === 0) {
            enqueueSnackbar('Please Select Account', { variant: 'error' });
            return;
        }
        if (SyncDataType === 'RTS') {
            SetIsReadyToShipLoading(true);
        }

        if (SyncDataType === 'RTN') {
            SetIsReturnLoading(true);
        }
        if (SyncDataType === 'CNL') {
            SetIsCancelLoading(true);
        }
        if (SyncDataType === 'allInventory') {
            SetIsInventoryLoading(true);
        }
        if (SyncDataType === 'SHIPPED') {
            SetIsShippedLoading(true);
        }

        const body = {
            username: user?.email ? user.email : localUser,
            tpEmailIds: tpEmail,
            subAccountIds: supplierId,
            cNames: companyName,
            syncDataType: SyncDataType,
            acType: 'citymall',
            isMaskingEnabledList: maskingList,
        };
        if (SyncDataType === 'GL') {
            setGenerateLabelLoading(true);
            body.syncDataType = 'generateLabel';
        }
        if (SyncDataType === 'RGL') {
            setReGenerateLabelLoading(true);
            body.syncDataType = 'reGenerateLabel';
        }
        if (SyncDataType === 'MRTD') {
            setmarkRTDLoading(true);
            body.syncDataType = 'markAsRtd';
        }
        if ((isAdmin && SyncDataType === 'RTN') || SyncDataType === 'SHIPPED') {
            body.start_date = citymallDateToBeFetched.start;
            body.end_date = citymallDateToBeFetched.end;
        }

        const response = await CreatePostAPICall(enqueueSnackbar, SyncOrders, body);
        if (response) {
            enqueueSnackbar(response.data.body.message);
            if (SyncDataType === 'RTS') {
                SetIsReadyToShipLoading(false);
            }
            if (SyncDataType === 'RTN') {
                SetIsReturnLoading(false);
            }
            if (SyncDataType === 'CNL') {
                SetIsCancelLoading(false);
            }
            if (SyncDataType === 'allInventory') {
                SetIsInventoryLoading(false);
            }
            if (SyncDataType === 'SHIPPED') {
                SetIsShippedLoading(false);
            }
            if (SyncDataType === 'GL') {
                setGenerateLabelLoading(false);
            }
            if (SyncDataType === 'RGL') {
                setReGenerateLabelLoading(false);
            }
            if (SyncDataType === 'MRTD') {
                setmarkRTDLoading(false);
            }
        }
    };

    return (
        <Container maxWidth={themeStretch ? false : 'xlg'}>
            <Grid sx={{ p: 2 }} container spacing={3}>
                <Grid item xs={12} md={3}>
                    <Typography variant="h6">
                        <div style={{ display: 'flex' }}>
                            <Iconify
                                icon={'ant-design:cloud-sync-outlined'}
                                width={25}
                                height={25}
                                style={{ color: '#7635DC', marginRight: '5px' }}
                            />{' '}
                            Api Sync
                        </div>
                    </Typography>

                    <Divider sx={{ mt: 1, mb: 3, width: '110px', backgroundColor: '#7635DC', height: '2px' }} />
                    <div style={{ Width: '100%' }}>
                        <AccountSelector allData={allAccountsData} onChangeHandler={handleSelectedAccount} />
                    </div>
                    <br />
                    <Box
                        sx={{
                            display: 'grid',
                            rowGap: 2,
                        }}
                    >
                        {isAdmin && (
                            <>
                                <RangePicker
                                    className={'createDateRangePicker'}
                                    pupClassName={'createDateRangePicker'}
                                    format={'DD/MM/YY'}
                                    onChange={(value, event) => getdate(value, event)}
                                    {...(!isLight && {
                                        className: 'date-picker-dark',
                                    })}
                                />
                                <Button
                                    variant="outlined"
                                    id={`${platForm}SyncShipped`}
                                    startIcon={<Iconify icon={'ion:return-up-forward-outline'} size="medium" />}
                                    onClick={() => {
                                        fetchApiCitymallOrders('SHIPPED');
                                    }}
                                    disabled={isShippedLoading}
                                >
                                    Sync Shipped Orders
                                </Button>
                            </>
                        )}
                        {/*<Button*/}
                        {/*    variant="outlined"*/}
                        {/*    startIcon={<Iconify icon={'ic:round-refresh'} size="medium"/>}*/}
                        {/*    onClick={() => {*/}
                        {/*        fetchApiCitymallOrders('GL');*/}
                        {/*    }}*/}
                        {/*    disabled={generateLabelLoading}*/}
                        {/*>*/}
                        {/*    Generate Label*/}
                        {/*</Button>*/}
                        {/*<Button*/}
                        {/*    variant="outlined"*/}
                        {/*    startIcon={<Iconify icon={'humbleicons:refresh'} size="medium"/>}*/}
                        {/*    onClick={() => {*/}
                        {/*        fetchApiCitymallOrders('RGL ');*/}
                        {/*    }}*/}
                        {/*    disabled={reGenerateLabelLoading}*/}
                        {/*>*/}
                        {/*    Re-Generate Label*/}
                        {/*</Button><Button*/}
                        {/*    variant="outlined"*/}
                        {/*    startIcon={<Iconify icon={'mdi:checkbox-multiple-marked-outline'} size="medium"/>}*/}
                        {/*    onClick={() => {*/}
                        {/*        fetchApiCitymallOrders('MRTD');*/}
                        {/*    }}*/}
                        {/*    disabled={markRTDLoading}*/}
                        {/*>*/}
                        {/*    Mark As RTD*/}
                        {/*</Button>*/}
                        <Button
                            variant="outlined"
                            id={`${platForm}SyncRts`}
                            startIcon={<Iconify icon={'ion:return-up-forward-outline'} size="medium" />}
                            onClick={() => {
                                if (!isDesktopEnvironment) {
                                    fetchApiCitymallOrders('RTS');
                                }
                            }}
                            disabled={isReadyToShipLoading}
                        >
                            Sync Ready To Ship Orders
                        </Button>
                        <Button
                            variant="outlined"
                            id={`${platForm}SyncRtn`}
                            startIcon={<Iconify icon={'ion:return-down-back-outline'} size="medium" />}
                            onClick={() => {
                                if (!isDesktopEnvironment) {
                                    fetchApiCitymallOrders('RTN');
                                }
                            }}
                            disabled={isReturnLoading}
                        >
                            Sync Return Orders
                        </Button>

                        <Button
                            variant="outlined"
                            id={`${platForm}SyncCnl`}
                            startIcon={<Iconify icon={'iconoir:cancel'} size="medium" />}
                            onClick={() => {
                                if (!isDesktopEnvironment) {
                                    fetchApiCitymallOrders('CNL');
                                }
                            }}
                            disabled={isCancelLoading}
                        >
                            Sync Cancel Orders
                        </Button>
                        {isAdmin && (
                            <Button
                                variant="outlined"
                                id={`${platForm}SyncInventory`}
                                startIcon={<Iconify icon={'ant-design:stock-outlined'} size="medium" />}
                                onClick={() => {
                                    if (!isDesktopEnvironment) {
                                        fetchApiCitymallOrders('allInventory');
                                    }
                                }}
                                disabled={isInventoryLoading}
                            >
                                Sync Inventory
                            </Button>
                        )}
                    </Box>

                    {/*
          <Divider
            orientation="horizontal"
            sx={{ marginBottom: '15px', marginTop: '15px', borderStyle: 'dashed', borderWidth: '0.5px' }}
          />

          <Typography variant="h6">
            <div style={{ display: 'flex' }}>
              <Iconify
                icon={'ant-design:cloud-sync-outlined'}
                width={25}
                height={25}
                style={{ color: '#7635DC', marginRight: '5px' }}
              />{' '}
              Auto Sync
            </div>
          </Typography>

          <Divider sx={{ mt: 1, mb: 3, width: '120px', backgroundColor: '#7635DC', height: '2px' }} />
          */}
                </Grid>

                <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    sx={{
                        marginLeft: '26px',
                        borderStyle: 'dashed',
                        borderRightWidth: 'initial',
                        marginBottom: '-10px',
                        marginTop: '20px',
                    }}
                />

                <Grid item xs={12} md={8.5}>
                    <Typography variant="h6">
                        <div style={{ display: 'flex' }}>
                            <Iconify
                                icon={'ri:file-excel-2-line'}
                                width={25}
                                height={25}
                                style={{ color: 'green', marginRight: '5px' }}
                            />
                            Upload Excel
                        </div>
                    </Typography>
                    <Divider sx={{ mt: 1, mb: 3, width: '143px', backgroundColor: '#7635DC', height: '2px' }} />
                    <Grid item xs={12} sm={12} md={12} sx={{ marginTop: '-1.3%' }}>
                        <Tabs
                            allowScrollButtonsMobile
                            variant="scrollable"
                            scrollButtons="auto"
                            value={currentUploadTab}
                            onChange={onChangeUploadTab}
                        >
                            {currentUploadTabs.map((tab) => (
                                <Tab disableRipple key={tab.value} label={capitalCase(tab.value)} icon={tab.icon}
                                     value={tab.value} />
                            ))}
                        </Tabs>
                        <Box sx={{ mb: 2 }} />
                        {currentUploadTab !== 'Financial Report' ? (
                            <>
                                {!(selectedUploadType === "FBF" && currentUploadTab === 'Order') && <Autocomplete
                                    id="citymall-excel-upload-selector"
                                    onChange={(event, value) => {
                                        setSelectedAccountForExcelUpload(value);
                                    }}
                                    value={selectedAccountForExcelUpload}
                                    options={citymallAccountListOptions.map((option) => option)}
                                    renderInput={(params) => <TextField {...params} label="Select Citymall Account*" />}
                                />}
                                <Box sx={{ mb: 2 }} />
                            </>
                        ) : null}
                        {currentUploadTabs.map((tab) => {
                            const isMatched = tab.value === currentUploadTab;
                            return isMatched && <Box key={tab.value}>{tab.component}</Box>;
                        })}
                    </Grid>
                </Grid>
            </Grid>
            <Input id="selectedAccountsData" value={JSON.stringify(selectedAccountsWithOutAllOptions)} type="hidden" />
        </Container>
    );
}
