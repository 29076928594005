import React from 'react';
import {Container, } from '@mui/material';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import Page from '../../../components/Page';
import urlConstants from '../../../routes/urlConstants';
import useSettings from '../../../hooks/useSettings';
import LabelProcess from "../labelProcess/LabelProcess";

export default function GlowroadUtilities() {
    const {themeStretch} = useSettings();
    return (
        <Page title="Utilities">
            <Container maxWidth={themeStretch ? false : 'xl'}>
                <HeaderBreadcrumbs
                    heading="Utilities"
                    links={[{name: 'Dashboard', href: urlConstants.HOME_PAGE}, {name: 'Utilities'}, {name: 'GlowRoad'}]}
                />
                {/* <Grid container spacing={3} style={{marginBottom: "3rem"}}> */}
                {/*    <Grid item xs={12}> */}
                {/*        <LoadingButton onClick={() => { */}
                {/*            setIsAcceptListON(true) */}
                {/*        }} variant={isAcceptListON ? 'contained' : 'outlined'}>Accept List</LoadingButton> */}
                {/*    </Grid> */}
                {/* </Grid> */}

                    <LabelProcess acType={'glowroad'} />


                {/* {isAcceptListON && ( */}
                {/*    <GlowRoadAcceptList/> */}
                {/* )} */}
            </Container>
        </Page>
    );
}
