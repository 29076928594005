/* eslint-disable */
import * as AWS from 'aws-sdk';
import React, {useState} from 'react';
import {useSnackbar} from 'notistack';
import {
    Box,
    Table,
    Button,
    Divider,
    TableRow,
    TableBody,
    TableHead,
    TableContainer,
    Grid,
    CircularProgress,
    Checkbox,
} from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import DatamapsIndia from 'react-datamaps-india';
import {LoadingButton} from '@mui/lab';
import {PDFDownloadLink} from '@react-pdf/renderer';
import DataGridTable from '../../../components/Common/DataGridTable';
import {SystemColor, StyledTableCell, KeyValuePairTableCell, downloadS3File} from '../../../utils/utilities';
import useAuth from '../../../hooks/useAuth';
import {CreatePostAPICall, MonthlyReport} from '../../../utils/apis';
import Iconify from '../../../components/Iconify';
import {CLIENT_SALES_REPORT, IDENTITY_POOL_ID, REGION} from '../../../utils/Constants';
import MonthlyInvoice from '../../../components/invoice/MonthlyInvoice';

export default function OrderReportDetails(props) {
    const {user} = useAuth();
    const localUser = window.localStorage.getItem('userEmailId');
    const {enqueueSnackbar} = useSnackbar();
    const [loading, setLoading] = useState(false);
    const [isConsideredLatestSelfCost, setIsConsideredLatestSelfCost] = useState(false);
    const [isConsideredTDS, setIsConsideredTDS] = useState(false);
    const [oldData, setOldData] = useState(props);

    const reGenerateReport = async (body) => {
        setLoading(true);
        const tmpReport = oldData;
        body.executed_time = 'IN PROGRESS';
        tmpReport.data.forEach((report) => {
            if (report.subAccountId === body.subAccountId) {
                report.executed_time = 'IN PROGRESS';
            }
        });
        setOldData(tmpReport);
        const payload = {
            acTypes: [body.acType],
            month: [body.reRange],
            subAccountIds: [(body.subAccountId ? body.subAccountId : null)],
            cNames: [body.company_name],
            tpEmailIds: [body.userName],
            username: user?.email ? user.email : localUser,
            actionType: 'generateReport',
        };
        if (isConsideredLatestSelfCost === true) {
            payload.considerLatestSelfCost = isConsideredLatestSelfCost;
        }
        if (isConsideredTDS === true) {
            payload.considerTDS = isConsideredTDS;
        }
        const response = await CreatePostAPICall(enqueueSnackbar, MonthlyReport, payload);
        if (response) {
            enqueueSnackbar(response.data.body);
        }
        setLoading(false);
        setIsConsideredLatestSelfCost(false);
        setIsConsideredTDS(false);
    };

    const columns = [
        {
            accessorKey: 'uiUniqueId',
            header: 'Index',
            size: 10,
        },
        {
            accessorKey: 'acType',
            header: 'Platform',
            size: 10,
        },
        {
            accessorKey: 'userName',
            header: 'Account',
            size: 10,
        },
        {
            accessorKey: 'company_name',
            header: 'Company Name',
            size: 10,
        },
        {
            accessorKey: 'subAccountId',
            header: 'Supplier Id',
            size: 10,
            Cell: ({cell}) => {
                return <>
                    <div style={{width: '100%', textAlign: 'left'}}>
                        {cell.row.original.subAccountId ? cell.row.original.subAccountId : '-'}
                    </div>
                </>
            }
        },
        {
            accessorKey: 'reRange',
            header: 'Report Month',
            size: 10,
        },
        {
            accessorKey: 'executed_time',
            header: 'Executed Time',
            size: 10,
            Cell: ({cell}) => (
                <>
                    <div style={{width: '100%', textAlign: 'left'}}>
                        {cell.row.original.executed_time === 'IN PROGRESS' ? (
                            <CircularProgress size={'20px'} style={{marginLeft: '30%'}}/>
                        ) : (
                            <>
                                {cell.getValue().toLowerCase() === 'error' ? (
                                    <p style={{color: SystemColor.error}}>Error</p>
                                ) : (
                                    <p>{cell.getValue()}</p>
                                )}
                            </>
                        )}
                    </div>
                </>
            ),
        },
        {
            accessorKey: 'isConsideredLatestSelfCost',
            header: 'Count New Cost',
            size: 2,
            Cell: (cell) =>(
                    <Checkbox
                        value={isConsideredLatestSelfCost}
                        onChange={(e) => {
                            setIsConsideredLatestSelfCost(e.target.checked);
                        }}
                    />
                ),
        },
        {
            accessorKey: 'isConsideredTDS',
            header: 'Count With TDS',
            size: 0.5,
            Cell: (cell) =>
                cell?.row?.original?.acType === 'flipkart' && (
                    <Checkbox
                        value={isConsideredTDS}
                        onChange={(e) => {
                            setIsConsideredTDS(e.target.checked);
                        }}
                    />
                ),
        },
        {
            accessorKey: 'Re-Generate',
            header: 'Re-Generate',
            size: 10,
            Cell: (cell) => (
                <>
                    {loading || cell.row.original.executed_time === 'IN PROGRESS' ? (
                        <LoadingButton
                            size="small"
                            loading={cell.row.original.executed_time === 'IN PROGRESS'}
                            variant="outlined"
                            disabled
                        >
                            disabled
                        </LoadingButton>
                    ) : (
                        <Button
                            variant="outlined"
                            startIcon={<Iconify icon={'mdi:file-refresh'} width={25} height={25}/>}
                            onClick={() => {
                                reGenerateReport(cell.row.original);
                            }}
                        >
                            Re-Generate
                        </Button>
                    )}
                </>
            ),
        },
    ];

    const MapChart = (data) => (
        <>
            <DatamapsIndia
                regionData={data}
                hoverComponent={({value}) => (
                    <div>
                        <div>
                            <h6 style={{color: '#161c24'}}>
                                <b>{value.name}</b>
                            </h6>
                            <h6 style={{color: '#161c24'}}>
                                <b>Total Sent: </b>
                                {value.value && value.value !== '' ? value.value : 0}
                            </h6>
                            <h6 style={{color: '#161c24'}}>
                                <b>Total Return: </b>
                                {value.rtn && value.rtn !== '' ? value.rtn : 0}
                            </h6>
                        </div>
                    </div>
                )}
                mapLayout={{
                    position: 'relative',
                    legendTitle: 'Numbers of Order Sent',
                    startColor: 'rgba(58, 0, 152, 0.2)',
                    endColor: 'rgba(58, 0, 152, 0.7)',
                    noDataColor: 'rgba(255, 255, 255, 0)',
                    borderColor: '#8D8D8D',
                    hoverBorderColor: '#8D8D8D',
                    hoverColor: SystemColor.originColor,
                    height: 100,
                    weight: 30,
                }}
            />
        </>
    );

    const expandTableRowComponent = (row) => (
        <>
            {row.original.executed_time === 'Error' ? (
                <p style={{color: 'red', textAlign: 'center'}}>{row.original.error}</p>
            ) : (
                <>
                    {row.original.executed_time === 'IN PROGRESS' ? (
                        <p style={{color: 'red', textAlign: 'center'}}>IN PROGRESS</p>
                    ) : (
                        <Box sx={{flexGrow: 1}}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid
                                            sx={{
                                                zIndex: 1,
                                            }}
                                            item
                                            xs={4}
                                        >
                                            <div
                                                style={{position: 'relative'}}>{MapChart(row.original.stateWiseData)}</div>
                                        </Grid>

                                        <Grid item xs={8}>
                                            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                                <div style={{marginRight: 10}}>
                                                    <PDFDownloadLink
                                                        document={<MonthlyInvoice data={row.original}/>}
                                                        fileName={`${row.original.company_name}-${row.original.reRange}.pdf`}
                                                    >
                                                        {({blob, url, loading, error}) =>
                                                            error ? (
                                                                console.log('error..................', error)
                                                            ) : loading ? (
                                                                <CircularProgress sx={{color: SystemColor.originColor}}
                                                                                  size={24} color="inherit"/>
                                                            ) : (
                                                                <Iconify icon="vscode-icons:file-type-pdf2" width={35}
                                                                         height={35}/>
                                                            )
                                                        }
                                                    </PDFDownloadLink>
                                                </div>

                                                <div>
                                                    <Iconify
                                                        icon="vscode-icons:file-type-excel"
                                                        width={35}
                                                        height={35}
                                                        onClick={() => {
                                                            downloadS3File(row.original.excel, true, CLIENT_SALES_REPORT, enqueueSnackbar);
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <Toolbar>
                                                <b>Total Orders</b>
                                            </Toolbar>
                                            <TableContainer component={Paper}>
                                                <Table sx={{minWidth: 650}} size="small" aria-label="a dense table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <StyledTableCell align="left"/>
                                                            <StyledTableCell align="center">Normal</StyledTableCell>
                                                            <StyledTableCell align="center">Combo</StyledTableCell>
                                                            <StyledTableCell align="center">Total</StyledTableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow
                                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                                            <StyledTableCell align="left" component="th" scope="row">
                                                                No of Orders
                                                            </StyledTableCell>
                                                            <StyledTableCell
                                                                align="center">{row.original.totalSent?.nor?.odr}</StyledTableCell>
                                                            <StyledTableCell
                                                                align="center">{row.original.totalSent?.combo?.odr}</StyledTableCell>
                                                            <StyledTableCell
                                                                align="center">{row.original.totalSent?.total?.odr}</StyledTableCell>
                                                        </TableRow>
                                                        <TableRow
                                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                                            <StyledTableCell align="left" component="th" scope="row">
                                                                Quantity
                                                            </StyledTableCell>
                                                            <StyledTableCell
                                                                align="center">{row.original.totalSent?.nor?.qt}</StyledTableCell>
                                                            <StyledTableCell
                                                                align="center">{row.original.totalSent?.combo?.qt}</StyledTableCell>
                                                            <StyledTableCell
                                                                align="center">{row.original.totalSent?.total?.qt}</StyledTableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>

                                            <Toolbar>
                                                <b>Return Orders</b>
                                            </Toolbar>
                                            <TableContainer component={Paper}>
                                                <Table sx={{minWidth: 650}} size="small" aria-label="a dense table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <StyledTableCell align="left"/>
                                                            <StyledTableCell align="center">RTO</StyledTableCell>
                                                            <StyledTableCell align="center">Customer
                                                                Return</StyledTableCell>
                                                            <StyledTableCell align="center">Cancel</StyledTableCell>
                                                            <StyledTableCell align="center">Normal</StyledTableCell>
                                                            <StyledTableCell align="center">Total</StyledTableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow
                                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                                            <StyledTableCell align="left" component="th" scope="row">
                                                                No of Orders
                                                            </StyledTableCell>
                                                            <StyledTableCell
                                                                align="center">{row.original?.noOfReturns?.rto?.odr}</StyledTableCell>
                                                            <StyledTableCell
                                                                align="center">{row.original?.noOfReturns?.rtn?.odr}</StyledTableCell>
                                                            <StyledTableCell
                                                                align="center">{row.original?.noOfReturns?.cnl?.odr}</StyledTableCell>
                                                            <StyledTableCell
                                                                align="center">{row.original?.noOfReturns?.nor?.odr}</StyledTableCell>
                                                            <StyledTableCell
                                                                align="center">{row.original?.noOfReturns?.total?.odr}</StyledTableCell>
                                                        </TableRow>
                                                        <TableRow
                                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                                            <StyledTableCell align="left" component="th" scope="row">
                                                                Investment
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {row.original?.noOfReturns?.rto?.investment}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {row.original?.noOfReturns?.rtn?.investment}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {row.original?.noOfReturns?.cnl?.investment}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {row.original?.noOfReturns?.nor?.investment}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {row.original?.noOfReturns?.total?.investment}
                                                            </StyledTableCell>
                                                        </TableRow>
                                                        <TableRow
                                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                                            <StyledTableCell align="left" component="th" scope="row">
                                                                Received
                                                            </StyledTableCell>
                                                            <StyledTableCell
                                                                align="center">{row.original?.noOfReturns?.rto?.recOdr}</StyledTableCell>
                                                            <StyledTableCell
                                                                align="center">{row.original?.noOfReturns?.rtn?.recOdr}</StyledTableCell>
                                                            <StyledTableCell
                                                                align="center">{row.original?.noOfReturns?.cnl?.recOdr}</StyledTableCell>
                                                            <StyledTableCell
                                                                align="center">{row.original?.noOfReturns?.nor?.recOdr}</StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {row.original?.noOfReturns?.total?.recOdr}
                                                            </StyledTableCell>
                                                        </TableRow>

                                                        <TableRow
                                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                                            <StyledTableCell align="left" component="th" scope="row">
                                                                Received Investment
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {row.original?.noOfReturns?.rto?.receivedInvestment}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {row.original?.noOfReturns?.rtn?.receivedInvestment}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {row.original?.noOfReturns?.cnl?.receivedInvestment}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {row.original?.noOfReturns?.nor?.receivedInvestment}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {row.original?.noOfReturns?.total?.receivedInvestment}
                                                            </StyledTableCell>
                                                        </TableRow>

                                                        <TableRow
                                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                                            <StyledTableCell align="left" component="th" scope="row">
                                                                Not Received
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {row.original?.totalReturn?.notReceived?.rto?.odr}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {row.original?.totalReturn?.notReceived?.rtn?.odr}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {row.original?.totalReturn?.notReceived?.cnl?.odr}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">-</StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {row.original?.totalReturn?.notReceived?.total?.odr}
                                                            </StyledTableCell>
                                                        </TableRow>

                                                        <TableRow
                                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                                            <StyledTableCell align="left" component="th" scope="row">
                                                                Remain Investment
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {row.original?.totalReturn?.notReceived?.rto?.investment?.toFixed(2)}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {row.original?.totalReturn?.notReceived?.rtn?.investment?.toFixed(2)}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {row.original?.totalReturn?.notReceived?.cnl?.investment?.toFixed(2)}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">-</StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {row.original?.totalReturn?.notReceived?.total?.investment?.toFixed(2)}
                                                            </StyledTableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Divider orientation="horizontal" flexItem/>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={8}>
                                            <Toolbar>
                                                <b>Payment Summary</b>
                                            </Toolbar>

                                            <TableContainer component={Paper}>
                                                <Table sx={{minWidth: 650}} size="small" aria-label="a dense table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <StyledTableCell rowSpan={2} align="center"/>
                                                            <StyledTableCell rowSpan={2} align="center">
                                                                Paid
                                                            </StyledTableCell>
                                                            <StyledTableCell rowSpan={2} align="center">
                                                                Outstanding
                                                            </StyledTableCell>
                                                            <StyledTableCell rowSpan={2} align="center">
                                                                Not Listed
                                                            </StyledTableCell>
                                                            <StyledTableCell colSpan={4} align="center">
                                                                Claim
                                                            </StyledTableCell>
                                                        </TableRow>

                                                        <TableRow>
                                                            <StyledTableCell align="center">RTO</StyledTableCell>
                                                            <StyledTableCell align="center">RTN</StyledTableCell>
                                                            <StyledTableCell align="center">Cancel</StyledTableCell>
                                                            <StyledTableCell align="center">Normal</StyledTableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow
                                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                                            <StyledTableCell align="left" component="th" scope="row">
                                                                No of Orders
                                                            </StyledTableCell>
                                                            <StyledTableCell
                                                                align="center"> {row.original?.payment?.paid?.odr}</StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {' '}
                                                                {row.original?.payment?.outstanding?.odr}
                                                            </StyledTableCell>
                                                            <StyledTableCell
                                                                align="center"> {row.original?.payment?.notListed?.odr}</StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {' '}
                                                                {row.original?.payment?.claim?.rto?.odr}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {' '}
                                                                {row.original?.payment?.claim?.rtn?.odr}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {' '}
                                                                {row.original?.payment?.claim?.cnl?.odr}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {' '}
                                                                {row.original?.payment?.claim?.nor?.odr}
                                                            </StyledTableCell>
                                                        </TableRow>

                                                        <TableRow
                                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                                            <StyledTableCell align="left" component="th" scope="row">
                                                                Amount
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {`${row.original?.payment?.paid?.listedPayment?.toFixed(2)} ₹`}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {`${row.original?.payment?.outstanding?.listedPayment?.toFixed(2)} ₹`}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {`${row.original?.payment?.notListed?.listedPayment?.toFixed(2)} ₹`}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {`${row.original?.payment?.claim?.rto?.listedPayment?.toFixed(2)} ₹`}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {`${row.original?.payment?.claim?.rtn?.listedPayment?.toFixed(2)} ₹`}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {`${row.original?.payment?.claim?.cnl?.listedPayment?.toFixed(2)} ₹`}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {`${row.original?.payment?.claim?.nor?.listedPayment?.toFixed(2)} ₹`}
                                                            </StyledTableCell>
                                                        </TableRow>

                                                        <TableRow
                                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                                            <StyledTableCell align="left" component="th" scope="row">
                                                                Investment
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {row.original?.payment?.paid?.investment?.toFixed(2)}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {row.original?.payment?.outstanding?.investment?.toFixed(2)}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {row.original?.payment?.notListed?.investment?.toFixed(2)}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {row.original?.payment?.claim?.rto?.investment?.toFixed(2)}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {row.original?.payment?.claim?.rtn?.investment?.toFixed(2)}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {row.original?.payment?.claim?.cnl?.investment?.toFixed(2)}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {row.original?.payment?.claim?.nor?.investment?.toFixed(2)}
                                                            </StyledTableCell>
                                                        </TableRow>

                                                        <TableRow
                                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                                            <StyledTableCell align="left" component="th" scope="row">
                                                                Profit/Loss
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {row.original?.payment?.paid?.profitLoss?.toFixed(2)}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {row.original?.payment?.outstanding?.profitLoss?.toFixed(2)}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {row.original?.payment?.notListed?.profitLoss?.toFixed(2)}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {row.original?.payment?.claim?.rto?.profitLoss?.toFixed(2)}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {row.original?.payment?.claim?.rtn?.profitLoss?.toFixed(2)}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {row.original?.payment?.claim?.cnl?.profitLoss?.toFixed(2)}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {row.original?.payment?.claim?.nor?.profitLoss?.toFixed(2)}
                                                            </StyledTableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>

                                            <Toolbar>
                                                <b>Return Loss</b>
                                            </Toolbar>

                                            <TableContainer component={Paper}>
                                                <Table sx={{minWidth: 650}} size="small" aria-label="a dense table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <StyledTableCell rowSpan={2} align="center"/>
                                                            <StyledTableCell colSpan={4} align="center">
                                                                Wrong Received
                                                            </StyledTableCell>
                                                            <StyledTableCell colSpan={4} align="center">
                                                                Not Received
                                                            </StyledTableCell>
                                                            <StyledTableCell colSpan={4} align="center">
                                                                Received
                                                            </StyledTableCell>
                                                        </TableRow>

                                                        <TableRow>
                                                            <StyledTableCell align="center">RTO</StyledTableCell>
                                                            <StyledTableCell align="center">RTN</StyledTableCell>
                                                            <StyledTableCell align="center">Cancel</StyledTableCell>
                                                            <StyledTableCell align="center">Normal</StyledTableCell>

                                                            <StyledTableCell align="center">RTO</StyledTableCell>
                                                            <StyledTableCell align="center">RTN</StyledTableCell>
                                                            <StyledTableCell align="center">Cancel</StyledTableCell>
                                                            <StyledTableCell align="center">Normal</StyledTableCell>

                                                            <StyledTableCell align="center">RTO</StyledTableCell>
                                                            <StyledTableCell align="center">RTN</StyledTableCell>
                                                            <StyledTableCell align="center">Cancel</StyledTableCell>
                                                            <StyledTableCell align="center">Normal</StyledTableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow
                                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                                            <StyledTableCell align="left" component="th" scope="row">
                                                                Orders
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {row.original?.totalReturn?.wrongReceived?.rto.odr}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {row.original?.totalReturn?.wrongReceived?.rtn?.odr}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {row.original?.totalReturn?.wrongReceived?.cnl?.odr}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {row.original?.totalReturn?.wrongReceived?.nor?.odr}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {row.original?.totalReturn?.notReceived?.rto?.odr}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {row.original?.totalReturn?.notReceived?.rtn?.odr}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {row.original?.totalReturn?.notReceived?.cnl?.odr}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">-</StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {row.original?.totalReturn?.received?.rto?.odr}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {row.original?.totalReturn?.received?.rtn?.odr}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {row.original?.totalReturn?.received?.cnl?.odr}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {row.original?.totalReturn?.received?.nor?.odr}
                                                            </StyledTableCell>
                                                        </TableRow>

                                                        <TableRow
                                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                                            <StyledTableCell align="left" component="th" scope="row">
                                                                Payment
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {`${row.original?.totalReturn?.wrongReceived?.rto?.listedPayment.toFixed(2)} ₹`}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {`${row.original?.totalReturn?.wrongReceived?.rtn?.listedPayment.toFixed(2)} ₹`}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {`${row.original?.totalReturn?.wrongReceived?.cnl?.listedPayment.toFixed(2)} ₹`}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {`${row.original?.totalReturn?.wrongReceived?.nor?.listedPayment.toFixed(2)} ₹`}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {`${row.original?.totalReturn?.notReceived?.rto?.listedPayment.toFixed(2)} ₹`}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {`${row.original?.totalReturn?.notReceived?.rtn?.listedPayment.toFixed(2)} ₹`}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {`${row.original?.totalReturn?.notReceived?.cnl?.listedPayment.toFixed(2)} ₹`}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">-</StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {`${row.original?.totalReturn?.received?.rto?.listedPayment.toFixed(2)} ₹`}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {`${row.original?.totalReturn?.received?.rtn?.listedPayment.toFixed(2)} ₹`}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {`${row.original?.totalReturn?.received?.cnl?.listedPayment.toFixed(2)} ₹`}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {`${row.original?.totalReturn?.received?.nor?.listedPayment.toFixed(2)} ₹`}
                                                            </StyledTableCell>
                                                        </TableRow>

                                                        <TableRow
                                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                                            <StyledTableCell align="left" component="th" scope="row">
                                                                Investment
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {row.original?.totalReturn?.wrongReceived?.rto.investment}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {row.original?.totalReturn?.wrongReceived?.rtn.investment}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {row.original?.totalReturn?.wrongReceived?.cnl.investment}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {row.original?.totalReturn?.wrongReceived?.nor.investment}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {row.original?.totalReturn?.notReceived?.rto.investment}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {row.original?.totalReturn?.notReceived?.rtn.investment}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {row.original?.totalReturn?.notReceived?.cnl.investment}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">-</StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {row.original?.totalReturn?.received?.rto.investment}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {row.original?.totalReturn?.received?.rtn.investment}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {row.original?.totalReturn?.received?.cnl.investment}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {row.original?.totalReturn?.received?.nor.investment}
                                                            </StyledTableCell>
                                                        </TableRow>

                                                        <TableRow
                                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                                            <StyledTableCell align="left" component="th" scope="row">
                                                                Profit/Loss
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {row.original?.totalReturn?.wrongReceived?.rto.profitLoss.toFixed(2)}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {row.original?.totalReturn?.wrongReceived?.rtn.profitLoss.toFixed(2)}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {row.original?.totalReturn?.wrongReceived?.cnl.profitLoss.toFixed(2)}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {row.original?.totalReturn?.wrongReceived?.nor.profitLoss.toFixed(2)}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {row.original?.totalReturn?.notReceived?.rto.profitLoss.toFixed(2)}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {row.original?.totalReturn?.notReceived?.rtn.profitLoss.toFixed(2)}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {row.original?.totalReturn?.notReceived?.cnl.profitLoss.toFixed(2)}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">-</StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {row.original?.totalReturn?.received?.rto.profitLoss.toFixed(2)}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {row.original?.totalReturn?.received?.rtn.profitLoss.toFixed(2)}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {row.original?.totalReturn?.received?.cnl.profitLoss.toFixed(2)}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {row.original?.totalReturn?.received?.nor.profitLoss.toFixed(2)}
                                                            </StyledTableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Toolbar>
                                                <b>Net Profit/Loss</b>
                                            </Toolbar>

                                            <TableContainer component={Paper}>
                                                <Table size="small" aria-label="a dense table">
                                                    <TableBody>
                                                        <TableRow
                                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                                            <KeyValuePairTableCell align="left" component="th"
                                                                                   scope="row">
                                                                <h4>Orders</h4>
                                                            </KeyValuePairTableCell>
                                                            <KeyValuePairTableCell align="center"/>
                                                        </TableRow>
                                                        <TableRow
                                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                                            <KeyValuePairTableCell align="left" component="th"
                                                                                   scope="row">
                                                                Sent Orders
                                                            </KeyValuePairTableCell>
                                                            <KeyValuePairTableCell align="center">
                                                                {row.original?.netTotal?.odr}
                                                            </KeyValuePairTableCell>
                                                        </TableRow>
                                                        <TableRow
                                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                                            <KeyValuePairTableCell align="left" component="th"
                                                                                   scope="row">
                                                                Return Received Orders
                                                            </KeyValuePairTableCell>
                                                            <KeyValuePairTableCell align="center">
                                                                {row.original?.noOfReturns?.total?.recOdr}
                                                            </KeyValuePairTableCell>
                                                        </TableRow>
                                                        <TableRow
                                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                                            <KeyValuePairTableCell align="left" component="th"
                                                                                   scope="row">
                                                                <h4>Investment Calculation</h4>
                                                            </KeyValuePairTableCell>
                                                            <KeyValuePairTableCell align="center"/>
                                                        </TableRow>
                                                        <TableRow
                                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                                            <KeyValuePairTableCell align="left" component="th"
                                                                                   scope="row">
                                                                Sales Investment
                                                            </KeyValuePairTableCell>
                                                            <KeyValuePairTableCell align="center">
                                                                {`${
                                                                    parseFloat(row.original?.netTotal?.investment?.toFixed(2))
                                                                    // parseFloat(row.original?.payment?.paid?.investment?.toFixed(2))
                                                                    // +
                                                                    // parseFloat(row.original?.payment?.outstanding?.investment?.toFixed(2)) +
                                                                    // parseFloat(row.original?.payment?.notListed?.investment?.toFixed(2)) +
                                                                    // parseFloat(row.original?.payment?.claim?.total?.investment?.toFixed(2)) +
                                                                    // parseFloat(row.original?.totalReturn?.wrongReceived?.total?.investment?.toFixed(2)) +
                                                                    // row.original?.totalReturn?.received?.total.investment +
                                                                    // row.original?.totalReturn?.notReceived?.total.investment
                                                                }`}{' '}
                                                                ₹
                                                            </KeyValuePairTableCell>
                                                        </TableRow>
                                                        <TableRow
                                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                                            <KeyValuePairTableCell align="left" component="th"
                                                                                   scope="row">
                                                                <b>-</b> Return Received Investment
                                                            </KeyValuePairTableCell>
                                                            <KeyValuePairTableCell align="center">
                                                                {row.original?.netTotal?.receivedInvestment ?
                                                                    parseFloat(row.original?.netTotal?.receivedInvestment?.toFixed(2)):
                                                                    parseFloat(row.original?.totalReturn?.received?.total.investment.toFixed(2))} ₹
                                                            </KeyValuePairTableCell>
                                                        </TableRow>
                                                        <TableRow
                                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                                            <KeyValuePairTableCell align="left" component="th"
                                                                                   scope="row">
                                                                <Divider/>
                                                            </KeyValuePairTableCell>
                                                            <KeyValuePairTableCell align="center">
                                                                <Divider/>
                                                            </KeyValuePairTableCell>
                                                        </TableRow>
                                                        <TableRow
                                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                                            <KeyValuePairTableCell align="left" component="th"
                                                                                   scope="row">
                                                                Net Investment
                                                            </KeyValuePairTableCell>
                                                            <KeyValuePairTableCell align="center">
                                                                {`${
                                                                    row.original?.netTotal?.receivedInvestment ?parseFloat(row.original?.netTotal?.investment?.toFixed(2)) - parseFloat(row.original?.netTotal?.receivedInvestment?.toFixed(2)):
                                                                    parseFloat(row.original?.payment?.paid?.investment?.toFixed(2)) +
                                                                    parseFloat(row.original?.payment?.outstanding?.investment?.toFixed(2)) +
                                                                    parseFloat(row.original?.payment?.notListed?.investment?.toFixed(2)) +
                                                                    parseFloat(row.original?.payment?.claim?.total?.investment?.toFixed(2)) +
                                                                    parseFloat(row.original?.totalReturn?.wrongReceived?.total?.investment?.toFixed(2)) +
                                                                    row.original?.totalReturn?.notReceived?.total.investment +
                                                                    row.original?.totalReturn?.received?.total.investment -
                                                                    row.original?.totalReturn?.received?.total.investment
                                                                }`}{' '}
                                                                ₹
                                                            </KeyValuePairTableCell>
                                                        </TableRow>

                                                        <TableRow
                                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                                            <KeyValuePairTableCell align="left" component="th"
                                                                                   scope="row">
                                                                <h4>Profit / Loss</h4>
                                                            </KeyValuePairTableCell>
                                                            <KeyValuePairTableCell align="center"/>
                                                        </TableRow>

                                                        <TableRow
                                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                                            <KeyValuePairTableCell align="left" component="th"
                                                                                   scope="row">
                                                                Total Payment Received
                                                            </KeyValuePairTableCell>
                                                            <KeyValuePairTableCell align="center">
                                                                {`${row.original?.netTotal?.listedPayment?.toFixed(2)} ₹`}
                                                            </KeyValuePairTableCell>
                                                        </TableRow>

                                                        <TableRow
                                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                                            <KeyValuePairTableCell align="left" component="th"
                                                                                   scope="row">
                                                                <b>-</b> Net Investment
                                                            </KeyValuePairTableCell>
                                                            <KeyValuePairTableCell align="center">
                                                                {`${
                                                                    row.original?.netTotal?.receivedInvestment ?parseFloat(row.original?.netTotal?.investment?.toFixed(2)) - parseFloat(row.original?.netTotal?.receivedInvestment?.toFixed(2)):
                                                                    parseFloat(row.original?.payment?.paid?.investment?.toFixed(2)) +
                                                                    parseFloat(row.original?.payment?.outstanding?.investment?.toFixed(2)) +
                                                                    parseFloat(row.original?.payment?.notListed?.investment?.toFixed(2)) +
                                                                    parseFloat(row.original?.payment?.claim?.total?.investment?.toFixed(2)) +
                                                                    parseFloat(row.original?.totalReturn?.wrongReceived?.total?.investment?.toFixed(2)) +
                                                                    row.original?.totalReturn?.notReceived?.total.investment +
                                                                    row.original?.totalReturn?.received?.total.investment -
                                                                    row.original?.totalReturn?.received?.total.investment
                                                                }`}{' '}
                                                                ₹
                                                            </KeyValuePairTableCell>
                                                        </TableRow>

                                                        <TableRow
                                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                                            <KeyValuePairTableCell align="left" component="th"
                                                                                   scope="row">
                                                                <b>-</b> Ads Cost
                                                            </KeyValuePairTableCell>
                                                            <KeyValuePairTableCell align="center">
                                                                {`${row.original?.netTotal?.totalAds?.toFixed(2) * -1} ₹`}
                                                            </KeyValuePairTableCell>
                                                        </TableRow>

                                                        <TableRow
                                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                                            <KeyValuePairTableCell align="left" component="th"
                                                                                   scope="row">
                                                                <Divider/>
                                                            </KeyValuePairTableCell>
                                                            <KeyValuePairTableCell align="center">
                                                                <Divider/>
                                                            </KeyValuePairTableCell>
                                                        </TableRow>

                                                        <TableRow
                                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                                            <KeyValuePairTableCell align="left" component="th"
                                                                                   scope="row">
                                                                Net Profit/Loss
                                                            </KeyValuePairTableCell>
                                                            <KeyValuePairTableCell align="center">
                                                                {
                                                                    row.original?.netTotal?.receivedInvestment ?(parseFloat(row.original?.netTotal?.listedPayment?.toFixed(2))
                                                                    - (parseFloat(row.original?.netTotal?.investment?.toFixed(2)) - parseFloat(row.original?.netTotal?.receivedInvestment?.toFixed(2)))
                                                                    + parseFloat(row.original?.netTotal?.totalAds?.toFixed(2))).toFixed(2):
                                                                    row.original?.netTotal?.profitLossWithAdsCost.toFixed(2)

                                                                } ₹
                                                            </KeyValuePairTableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>

                                            <Toolbar>
                                                <b>Return Loss</b>
                                            </Toolbar>
                                            <TableContainer component={Paper}>
                                                <Table size="small" aria-label="a dense table">
                                                    <TableBody>
                                                        <TableRow
                                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                                            <KeyValuePairTableCell align="left" component="th"
                                                                                   scope="row">
                                                                Total Return Loss
                                                            </KeyValuePairTableCell>
                                                            <KeyValuePairTableCell align="center">
                                                                {(
                                                                    row.original.payment.claim.cnl.profitLoss +
                                                                    row.original.payment.claim.nor.profitLoss +
                                                                    row.original.payment.claim.rtn.profitLoss +
                                                                    row.original.payment.claim.rto.profitLoss +
                                                                    row.original.totalReturn.received.cnl.profitLoss +
                                                                    row.original.totalReturn.received.nor.profitLoss +
                                                                    row.original.totalReturn.received.rtn.profitLoss +
                                                                    row.original.totalReturn.received.rto.profitLoss +
                                                                    row.original.totalReturn.wrongReceived.cnl.profitLoss +
                                                                    row.original.totalReturn.wrongReceived.nor.profitLoss +
                                                                    row.original.totalReturn.wrongReceived.rtn.profitLoss +
                                                                    row.original.totalReturn.wrongReceived.rto.profitLoss
                                                                ).toFixed(2)}{' '}
                                                                ₹
                                                            </KeyValuePairTableCell>
                                                        </TableRow>
                                                        <TableRow
                                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                                            <KeyValuePairTableCell align="left" component="th"
                                                                                   scope="row">
                                                                Total Not Received Loss
                                                            </KeyValuePairTableCell>
                                                            <KeyValuePairTableCell align="center">
                                                                {(
                                                                    row.original.totalReturn.notReceived.cnl.profitLoss +
                                                                    row.original.totalReturn.notReceived.rtn.profitLoss +
                                                                    row.original.totalReturn.notReceived.rto.profitLoss
                                                                ).toFixed(2)}{' '}
                                                                ₹
                                                            </KeyValuePairTableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            <br/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Divider orientation="horizontal" flexItem/>
                        </Box>
                    )}
                </>
            )}
        </>
    );

    return (
        <DataGridTable
            columns={columns}
            expand={false}
            enablePagination={false}
            data={oldData.data}
            sorting={true}
            isLoading={false}
            renderDetailPanel={expandTableRowComponent}
        />
    );
}
