/* eslint-disable */
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Grid,
} from '@mui/material';
import {styled, useTheme} from '@mui/material/styles';
import {DatePicker} from 'antd';
import {isEmpty} from 'lodash';
import {useSnackbar} from 'notistack';
import React, {useEffect, useState} from 'react';
import useAuth from "../../../hooks/useAuth";
import AccountSelector from "../../../components/Common/AccountSelector";
import {
    CreateExcelFile,
    getAccountsDataByType,
    getUserAccountDetails,
    setAccountSelectorData
} from "../../../utils/utilities";
import {CreatePostAPICall, OrderAnalysis} from "../../../utils/apis";
import {LoadingButton} from "@mui/lab";
import DataGridTable from "../../../components/Common/DataGridTable";
import moment from "moment/moment";

export default function FlipkartRewardDetails() {
    const theme = useTheme();
    const localUser = window.localStorage.getItem('userEmailId');
    const {enqueueSnackbar} = useSnackbar();
    const {user} = useAuth();
    const [userData, setUserData] = useState([]);
    const [rewardData, setRewardData] = useState([]);
    const [selectedAccountsData, setSelectedAccountsData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [flag, setFlag] = useState(1);
    const [tempFilteredData, setTempFilteredData] = useState([]);
    let refSubOrders = [];

    const columns = [
        {
            accessorKey: 'rewardName',
            header: 'Reward Name',
            size: 10,
        },
        {
            accessorKey: 'listingId',
            header: 'Listing Id',
            size: 10,
        },
        {
            accessorKey: 'sku',
            header: 'Sku',
            size: 10,
        },
        {
            accessorKey: 'rewardStatus',
            header: 'Reward Status',
            size: 10,
        },
        {
            accessorKey: 'startDate',
            header: 'Start Date',
            size: 10,
        },
        {
            accessorKey: 'endDate',
            header: 'End Date',
            size: 10,
        },
        {
            accessorKey: 'lowerLimit',
            header: 'Lower Limit',
            size: 10,
        },

        {
            accessorKey: 'upperLimit',
            header: 'Upper Limit',
            size: 10,
        },
        {
            accessorKey: 'finalPrice',
            header: 'Final Price',
            size: 10,
        },
        {
            accessorKey: 'lowerMpFee',
            header: 'Lower MpFee',
            size: 10,
        },
        {
            accessorKey: 'upperMpFee',
            header: 'Upper MpFee',
            size: 10,
        },
    ];

    // handle select account
    const handleSelectedAccount = async (event, value) => {
        setRewardData([]);
        if (value[0]) {
            setFlag(0);
        } else {
            setFlag(1);
        }

        var tempIsAllAcFlipkart = true
        for (const selectedAc of value) {
            if (selectedAc.AcType && (selectedAc.AcType !== "flipkart")) {
                tempIsAllAcFlipkart = false
            }
            if (selectedAc.value.length < 9 && (selectedAc.value !== "flipkart")) {
                tempIsAllAcFlipkart = false
            }
        }
        const response = await setAccountSelectorData(value, userData);
        setSelectedAccountsData(response[1]);
        await onSubmitRewards(response[1]);
    };

    // get account selector options by type and account data
    useEffect(() => {
        getAccountsDataByType('flipkart', false, false).then((filteredData) => {
            setUserData(filteredData);
        });
    }, []);

    const exportToExcel = async () => {
        if (rewardData.length !== 0) {
            CreateExcelFile(rewardData, `${selectedAccountsData[0].Email.replaceAll('@gmail.com','')}_Reco`, true);
        }
    }
    const onSubmitRewards = async (value) => {
        setRewardData([]);
        setIsLoading(true);
        var data = [];
        var selectedData = value ?? selectedAccountsData;

        for (const Data of selectedData) {
            let key = {};

            do {
                const body = {
                    "username": user?.email ? user.email : localUser,
                    "tpEmailIds": [Data.Email],
                    "subAccountIds": [Data.value],
                    "acType": [Data.AcType],
                    "cNames": [Data.cName],
                    "flipkartRewards": true,
                };

                if (Object.keys(key).length !== 0) {
                    body['last_key'] = key;
                }

                const response = await CreatePostAPICall(enqueueSnackbar, OrderAnalysis, body);

                if (response) {
                    key = response.data.last_key || {};
                    data.push(...response.data.body);
                }

            } while (Object.keys(key).length !== 0);
        }

        setIsLoading(false);

        const transformedData = data.map((item) => ({
            rewardName: item[0],
            listingId: item[1],
            sku: item[2],
            rewardStatus: item[3],
            startDate: item[4],
            endDate: item[5],
            lowerLimit: item[6],
            upperLimit: item[7],
            finalPrice: item[8],
            lowerMpFee: item[9],
            upperMpFee: item[10]
        }));

        setRewardData(transformedData);
    };

    return (
        <>
            <Grid container direction="row" spacing={1} sx={{paddingBottom: '15px', mt: 2, justifyContent: 'start'}}>
                <Grid item xs={12} sm={12} md={7}>
                    <AccountSelector allData={userData} onChangeHandler={handleSelectedAccount} autoWidth/>
                    <Grid container justifyContent={'end'}>
                        <LoadingButton variant={'contained'} sx={{mt: 1}} onClick={exportToExcel}>
                            Export Excel
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Grid>
            <DataGridTable columns={columns}
                           data={rewardData} sorting isLoading={isLoading}/>
        </>
    );
}
