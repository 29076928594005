import React, {useEffect, useState} from 'react';
import axios from 'axios';
import DataGridTable from '../../../components/Common/DataGridTable';
import {changeDataFormat} from '../../../utils/utilities';

export default function BMUsers(props) {
    const [adminData, setAdminData] = useState([]);
    const [dataLoading, setDataLoading] = useState(false);

    const BMUsersColumn = [
        {
            accessorKey: 'Enabled',
            header: 'Enabled',
            size: 10,
            Cell: (cell) => changeDataFormat(cell.row.original?.Enabled),
        },
        {
            accessorKey: 'UserCreateDate',
            header: 'User Create Date',
            size: 20,
        },
        {
            accessorKey: 'UserLastModifiedDate',
            header: 'User Last Modified Date',
            size: 20,
        },
        {
            accessorKey: 'UserStatus',
            header: 'User Status',
            size: 20,
        },
        {
            accessorKey: 'Username',
            header: 'Username',
            size: 20,
        },
    ];

    const BMUsersAPICall = async () => {
        setDataLoading(true);
        const response = await axios.post(props.api, props.body);
        if (typeof setAdminData === 'function' && response?.data) {
            setAdminData(response.data.body);
        } else {
            console.error('setAdminData is not a function or response.data is undefined');
        }


        setDataLoading(false);
    };

    useEffect(() => {
        BMUsersAPICall();
    }, []);

    return (
        <>
            {adminData && (
                <DataGridTable columns={BMUsersColumn} data={adminData} sorting isLoading={dataLoading}/>
            )}
        </>
    );
}
