/* eslint-disable */
import {Autocomplete, Button, Container, Grid, Input, TextField} from '@mui/material';
import {useSnackbar} from 'notistack';
import React, {useCallback, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import LoadingScreen from '../../components/LoadingScreen';
import Page from '../../components/Page';
import useAuth from '../../hooks/useAuth';
import useSettings from '../../hooks/useSettings';
import urlConstants from '../../routes/urlConstants';
import {CreatePostAPICall, MyLinkedAccount, V2Management} from '../../utils/apis';
import {
    SaveUserExistingAcTypes,
    isAdmin,
    isDesktopEnvironment,
    isSuperAdminDesktopEnvironment,
    isSuperAdminEnvironment,
    saveAccountData,
} from '../../utils/utilities';
import AdminBuissMasterStatics from './components/AdminBuissMasterStatics';
import AdminNotificationManagement from './components/AdminNotificationManagement';
import AdminBMUsers from './components/AdminBMUsers';
import AdminManagement from './components/AdminManagement';
import AdminTools from './components/AdminTools';
import AdminUploadExcels from './components/AdminUploadExcels';
import MainAdmin from './components/MainAdmin';
import AdminBillManagement from './components/billmanagment/AdminBillManagement';
import AdminAutoClaims from './components/AdminAutoClaims';
import {ToggleButton, ToggleButtonGroup} from "@mui/lab";
import Iconify from "../../components/Iconify";
import Label from "../../components/Label";
import {alpha, useTheme} from "@mui/material/styles";
import AdminManageTab from "./components/billmanagment/AdminManageTab";

// show option of admin platform
const AdminPlatFormOptions = [
    {
        platFormName: 'Admin',
        pathName: 'Admin',
        platFormIcon: 'clarity:administrator-solid',
    },
    {
        platFormName: 'Admin Tools',
        pathName: 'AdminTools',
        platFormIcon: 'fa-solid:tools',
    },

    {
        platFormName: 'Management',
        pathName: 'AdminManagement',
        platFormIcon: 'ri:home-gear-fill',
    },

    {
        platFormName: 'Upload Excels',
        pathName: 'AdminUploadExcels',
        platFormIcon: 'material-symbols:cloud-upload',
    },
    {
        platFormName: 'Bill Management',
        pathName: 'AdminBillManagement',
        platFormIcon: 'fa6-solid:file-invoice-dollar',
    },
    {
        platFormName: 'Auto Claims',
        pathName: 'AdminAutoClaims',
        platFormIcon: 'carbon:baggage-claim',
    },
];

const SuperAdminPlatFormOptions = [
    {
        platFormName: 'Admin',
        pathName: 'Admin',
        platFormIcon: 'clarity:administrator-solid',
    },
    {
        platFormName: 'Admin Tools',
        pathName: 'AdminTools',
        platFormIcon: 'fa-solid:tools',
    },

    {
        platFormName: 'Management',
        pathName: 'AdminManagement',
        platFormIcon: 'ri:home-gear-fill',
    },

    {
        platFormName: 'Upload Excels',
        pathName: 'AdminUploadExcels',
        platFormIcon: 'material-symbols:cloud-upload',
    },
    {
        platFormName: 'Bill Management',
        pathName: 'AdminBillManagement',
        platFormIcon: 'fa6-solid:file-invoice-dollar',
    },
    {
        platFormName: 'Auto Claims',
        pathName: 'AdminAutoClaims',
        platFormIcon: 'carbon:baggage-claim',
    },
    {
        platFormName: 'BM Users',
        pathName: 'AdminBMUsers',
        platFormIcon: 'fa6-solid:users',
    },
    {
        platFormName: 'Notification Management',
        pathName: 'AdminNotificationManagement',
        platFormIcon: 'mdi:notification-settings',
    },
    {
        platFormName: 'BuissMaster Statics',
        pathName: 'AdminBuissMasterStatics',
        platFormIcon: 'streamline:money-graph-bar-increase-up-product-performance-increase-arrow-graph-business-chart',
    },
];


export default function Admin() {
    const {themeStretch} = useSettings();
    const theme = useTheme();
    const {user} = useAuth();
    const {adminTabName} = useParams();
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();
    const [Platform, setPlatform] = useState('Admin');
    const [userData, setUserData] = useState([]);
    const [userDataAll, setUserDataAll] = useState([]);
    const [oldAccountToTD, setOldAccountToTD] = useState([]);
    const [isAdminLoading, setIsAdminLoading] = useState(false);

    useEffect(() => {
        setPlatform(adminTabName);
    }, [adminTabName]);

    const accountList = async () => {
        const body = {
            actionType: 'getAllAccounts',
            panel: 'admin',
        };
        const response = await CreatePostAPICall(enqueueSnackbar, V2Management, body);
        if (response) {
            setUserData(response.data.body);
            setUserDataAll(response.data.body);
        }
    };

    //  setLocalStorageData On ADMIN ACCOUNT SELECT
    const setLocalStorageData = async (accountName) => {
        setIsAdminLoading(true);

        const response = await CreatePostAPICall(enqueueSnackbar, MyLinkedAccount, {
            username: accountName,
            actionType: 'getAll',
        });
        setOldAccountToTD(accountName);
        localStorage.setItem('userEmailId', accountName);
        localStorage.setItem('userId', JSON.stringify(response.data.userData[0]));
        localStorage.setItem('userMobileNumber', JSON.stringify(response.data.userData[1]));

        if (response && response.data && response.data.body && response.data.statusCode === 200) {
            const objData = [];
            response.data.body.forEach((item) => {
                const cell = item;
                const newData = {};
                newData.Account = {
                    Name: cell.Name,
                    Email: cell.Email,
                    SupplierId: cell.SupplierId,
                };
                newData.no = cell.no;
                newData.AcType = cell.AcType;
                newData.Mobile_No = cell.Mobile_No;
                newData.Image_Link = cell.Image_Link;
                newData.identifier = cell.identifier;
                newData.SellerId = cell.SellerId;
                newData.LocationName = cell.LocationName;
                newData.accountStatus = cell.accountStatus;
                newData.isMaskingEnabled = cell.isMaskingEnabled;
                newData.LBHW = {
                    "L":cell.L,
                    "B":cell.B,
                    "H":cell.H,
                    "W":cell.W
                }
                objData.push(newData);
            });

            const filteredData = objData.filter(item => {
                if (isSuperAdminEnvironment) {
                    return true;  // Include both "ACTIVATE" and "DEACTIVATE" for superadmin
                } else {
                    return item.accountStatus === "ACTIVATE";  // Include only "ACTIVATE" for non-superadmin
                }
            });

            SaveUserExistingAcTypes(filteredData);
            saveAccountData(filteredData);
        }
        setIsAdminLoading(false);
    };

    useEffect(() => {
        accountList();
    }, []);


    const handleChangePlatFormName = useCallback((event, newPlatFormName) => {
        if (newPlatFormName !== null) {
            setPlatform(newPlatFormName);
            AdminPlatFormOptions.map((e) => {
                if (e.pathName === newPlatFormName) {
                    navigate(`${urlConstants.ADMIN_PAGE}/${e.pathName}`);
                }
            })
        }
    }, []);

    if (isAdminLoading) {
        return <LoadingScreen/>;
    }


    return (
        <Page title="Admin">
            <Container maxWidth={themeStretch ? false : 'xl'}>
                <HeaderBreadcrumbs
                    heading="Admin"
                    links={[{name: 'Dashboard', href: urlConstants.HOME_PAGE}, {name: 'Admin'}]}
                />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Autocomplete
                            id="free-solo-2-demo"
                            disableClearable
                            onChange={(event, accountName) => {
                                setLocalStorageData(accountName);
                            }}
                            options={userData.map((option) => option)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Select Account*"
                                    InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                    }}
                                />
                            )}
                        />

                        {(isDesktopEnvironment && isSuperAdminDesktopEnvironment) || (isDesktopEnvironment && isAdmin) ? (
                            <div style={{marginTop: '25px'}}>
                                <Button variant="contained" id="setupAdminDesktop">
                                    Setup Admin Desktop
                                </Button>
                            </div>
                        ) : null}

                        <Grid container spacing={3} style={{paddingTop: '1.75%', width: '100%'}}>
                            <ToggleButtonGroup
                                exclusive
                                value={Platform}
                                size="medium"
                                onChange={handleChangePlatFormName}
                                sx={{mt: 5, ml: 3, flexWrap: 'wrap'}}
                            >
                                {isSuperAdminEnvironment ? (
                                    SuperAdminPlatFormOptions.map((value, i) => (
                                        <ToggleButton value={value.pathName} aria-label={value.pathName}
                                                      key={value.pathName} color={'primary'}>

                                            <Label variant={'filled'}
                                                   sx={{backgroundColor: alpha(theme.palette.primary.main, 0.15),}}>
                                                <Iconify icon={value.platFormIcon} sx={{color: "#7635dc"}}
                                                         width={20} height={20}/>
                                            </Label>
                                            &nbsp;&nbsp;{value.platFormName}
                                        </ToggleButton>
                                    ))
                                ) : (
                                    AdminPlatFormOptions.map((value, i) => (
                                        <ToggleButton value={value.pathName} aria-label={value.pathName}
                                                      key={value.pathName}>
                                            <Label variant={'filled'}
                                                   sx={{backgroundColor: alpha(theme.palette.primary.main, 0.15),}}>
                                                <Iconify icon={value.platFormIcon} sx={{color: "#7635dc"}} width={20}
                                                         height={20}/>
                                            </Label>
                                            &nbsp;&nbsp;{value.platFormName}
                                        </ToggleButton>
                                    ))
                                )}
                            </ToggleButtonGroup>
                        </Grid>


                        {Platform === 'Admin' && <MainAdmin/>}
                        {Platform === 'AdminTools' &&
                            <AdminTools userDataAll={userDataAll} oldAccountToTD={oldAccountToTD}/>}
                        {Platform === 'AdminManagement' && <AdminManagement/>}
                        {Platform === 'AdminUploadExcels' && <AdminUploadExcels/>}
                        {Platform === 'AdminBMUsers' && <AdminBMUsers/>}
                        {Platform === 'AdminNotificationManagement' && <AdminNotificationManagement/>}
                        {Platform === 'AdminBillManagement' && <AdminManageTab/>}
                        {Platform === 'AdminBuissMasterStatics' && <AdminBuissMasterStatics/>}
                        {Platform === 'AdminAutoClaims' && <AdminAutoClaims/>}
                    </Grid>
                </Grid>
                <Input id="userEmailId" value={user?.email} type="hidden"/>
            </Container>
        </Page>
    );

}
