/* eslint-disable */
import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import useAuth from '../../../hooks/useAuth';
import useSettings from '../../../hooks/useSettings';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import Page from '../../../components/Page';
import urlConstants from '../../../routes/urlConstants';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Autocomplete, Button, Divider, Grid, Radio, RadioGroup, TextField, Container } from '@mui/material';
import { getUserAccountDetails } from '../../../utils/utilities';
import { CreatePostAPICall, OrderAnalysis, Utility } from '../../../utils/apis';

function CustomerReview() {

    const { user } = useAuth();
    const localUser = window.localStorage.getItem('userEmailId');
    const { enqueueSnackbar } = useSnackbar();
    const { themeStretch } = useSettings();
    const [subOrderNumber, setSubOrderNumber] = useState('');
    const [awbNumber, setAwbNumber] = useState('');
    const [subOrderData, setSubOrderData] = useState([]);
    const [awbData, setAwbData] = useState([]);
    const [customerCredit, setCustomerCredit] = useState('true');

    // handle showing all subOrder order options
    const onSuborderOptionData = async (event, suborderNo) => {
        if (suborderNo.length > 0) {
            const accountDetails = await getUserAccountDetails();
            const tpEmails = accountDetails.tpEmailids;
            const suppliers = accountDetails.supplierIds;
            const acTypes = accountDetails.acTypes;
            const cNames = accountDetails.cName;

            const body = {
                username: user?.email ? user.email : localUser,
                tpEmailIds: tpEmails,
                subAccountIds: suppliers,
                cNames: cNames,
                acType: acTypes,
                suborder_no: suborderNo,
                get_hints: true,
            };
            const response = await CreatePostAPICall(enqueueSnackbar, OrderAnalysis, body);

            if (response) {
                const newArr = [];
                response.data.body.forEach((value) => {
                    if (newArr.indexOf(value) === -1) newArr.push(value);
                });
                setSubOrderData(newArr);
            }
        } else {
            setSubOrderData([]);
        }
    };

    // handle showing all AWB order options
    const onAWBOptionData = async (event, awbNo) => {
        if (awbNo.length > 0) {
            const accountDetails = await getUserAccountDetails();
            const tpEmails = accountDetails.tpEmailids;
            const suppliers = accountDetails.supplierIds;
            const acTypes = accountDetails.acTypes;
            const cNames = accountDetails.cName;

            const body = {
                username: user?.email ? user.email : localUser,
                tpEmailIds: tpEmails,
                subAccountIds: suppliers,
                acType: acTypes,
                cNames: cNames,
                awb: awbNo,
                get_hints: true,
            };
            const response = await CreatePostAPICall(enqueueSnackbar, OrderAnalysis, body);

            if (response) {
                const newArr = [];
                response.data.body.forEach((value) => {
                    if (newArr.indexOf(value) === -1) newArr.push(value);
                });
                setAwbData(newArr);
            }
        } else {
            setAwbData([]);
        }
    };


    // for making return entry for all components
    const makeUserCreditEntry = async () => {
        if (subOrderNumber?.length === 0 && awbNumber?.length === 0) {
            enqueueSnackbar('Please Select SubOrder Number Or Awb Number.', { variant: 'error' });
            return;
        }
        const accountDetails = await getUserAccountDetails();
        const tpEmails = accountDetails.tpEmailids;
        const suppliers = accountDetails.supplierIds;
        const acTypes = accountDetails.acTypes;
        const body = {
            username: user?.email ? user.email : localUser,
            tpEmailIds: tpEmails,
            subAccountIds: suppliers,
            acTypes,
            utilityType: 'addCustomerCredit',
            customerCredit,
        };
        if (subOrderNumber) {
            body.suborder_no = subOrderNumber;
        }
        if (awbNumber) {
            body.awb_no = awbNumber;
        }
        const response = await CreatePostAPICall(enqueueSnackbar, Utility, body);
        if (response) {
            enqueueSnackbar(response.data.body);
        }
        setCustomerCredit('true');
        setSubOrderData([]);
        setSubOrderNumber('');
        setAwbData([]);
        setAwbNumber('');
    };

    const selectTrueFalseHandleChange = (e) => {
        setCustomerCredit(e.target.value);
    };


    return (<>
        <Page title="Utilities">
            <Container maxWidth={themeStretch ? false : 'xl'}>
                <HeaderBreadcrumbs
                    heading="Utilities"
                    links={[{ name: 'Dashboard', href: urlConstants.HOME_PAGE }, { name: 'Utilities Customer Review', href: urlConstants.CUSTOMER_REVIEW_PAGE }, { name: 'Customer Review' }]}
                />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Grid item xs={12} md={6} lg={6} sx={{ pb: 2 }}>
                            <h3>Add Customer Review Credit</h3>
                            <Divider sx={{ padding: '3px', marginBottom: '20px' }} />
                            <Autocomplete
                                sx={{ mb: 2 }}
                                onChange={(event, receivedSubOrderNo) => {
                                    event.key = 'Enter';
                                    setSubOrderNumber(receivedSubOrderNo);
                                }}
                                disabled={awbNumber?.length > 0}
                                options={subOrderData}
                                onInputChange={onSuborderOptionData}
                                value={subOrderNumber}
                                renderInput={(params) => <TextField {...params} label="Sub Order Number" />}
                            />
                            <Autocomplete
                                sx={{ mb: 2 }}
                                onChange={(event, receivedAWBNo) => {
                                    event.key = 'Enter';
                                    setAwbNumber(receivedAWBNo);
                                }}
                                disabled={subOrderNumber?.length > 0}
                                options={awbData}
                                onInputChange={onAWBOptionData}
                                value={awbNumber}
                                renderInput={(params) => <TextField {...params} label="AWB Number" />}
                            />
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                value={customerCredit}
                                name="radio-buttons-group"
                            >
                                <Grid container spacing={3} sx={{ mb: 2 }}>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <FormControlLabel
                                            value="true"
                                            control={<Radio />}
                                            label="True"
                                            onChange={selectTrueFalseHandleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <FormControlLabel
                                            value="false"
                                            control={<Radio />}
                                            label="False"
                                            onChange={selectTrueFalseHandleChange}
                                        />
                                    </Grid>
                                </Grid>
                            </RadioGroup>
                            <Button variant="outlined" onClick={makeUserCreditEntry}>
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Page>
    </>)
}
export default CustomerReview;