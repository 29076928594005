import React, { useState } from 'react';
import { TextField, Box, Divider, Stack, Button, Chip, Card, FormControlLabel, Checkbox } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import { useSnackbar } from 'notistack';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { ClaimIssue, CreatePostAPICall } from '../../../utils/apis';
import { BootstrapDialog, BootstrapDialogTitle } from '../../../utils/utilities';

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 175,
      width: 250,
    },
  },
};

export default function MyntraCustomFormatModal({ isModalOpen, handleClose, userEmail }) {
  const [customFormatData, setCustomFormatData] = useState([]);
  const [selectedCustomFormatAction, setSelectedCustomFormatAction] = useState(null);
  const [selectedFormatIndex, setSelectedFormatIndex] = useState(null);
  const [defaultFormatIndex, setDefaultFormatIndex] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const TicketFormatTypeOptions = [
    {
      value: 'Pending_Not_Received',
      name: 'Pending Not Received',
    },
    {
      value: 'Delivered_Not_Received',
      name: 'Delivered Not Received',
    },
    {
      value: 'D_P_W_Not_Received',
      name: 'D P W Not Received',
    },
    {
      value: 'Payment_Not_Received',
      name: 'Payment Not Received',
    },
  ];

  const pendingNotReceivedListVariables = [
    '[SUB_ORDER_ID]',
    '[AWB]',
    '[RET_TYPE]',
    '[LATE_DAYS]',
    '[ORDER_STATUS]',
    '[MYNTRA_LATE_DAYS]',
  ];

  const podListVariables = ['[SUB_ORDER_ID]', '[AWB]', '[RET_TYPE]', '[DELIVERED_DATE]', '[ORDER_STATUS]'];

  const dpwListVariables = ['[SUB_ORDER_ID]', '[AWB]', '[RET_TYPE]', '[RETURN_CONDITION]', '[RETURN_COURIER_PARTNER]'];

  const paymentListVariables = ['[SUB_ORDER_ID]', '[AWB]', '[PICK_UP_DATE]'];

  const handleAddNewFormat = () => {
    const tempObj = {
      Content: '',
      Index: customFormatData.length + 1,
      Is_Default: false,
      ticketFormat: selectedCustomFormatAction,
    };

    const tempArray = customFormatData.map((value) => value);

    tempArray.push(tempObj);
    setCustomFormatData(tempArray);
  };

  const setActionAndCallApi = async (action) => {
    setLoading(true);

    const response = await CreatePostAPICall(enqueueSnackbar, ClaimIssue, {
      username: userEmail,
      acType: 'myntra',
      ticketType: action,
      resourceType: 'claimFormats',
      manageAction: 'getTicketFormats',
      actionType: 'manageNeededResources',
    });
    if (response) {
      response.data.body.map((Format) => {
        if (Format.Is_Default === true) {
          setDefaultFormatIndex(Format.Index);
        }
        return Format;
      });
      setCustomFormatData(response.data.body);
    }
    setLoading(false);
  };

  const saveButtonClickHandle = async (formatData) => {
    setLoading(true);

    const response = await CreatePostAPICall(enqueueSnackbar, ClaimIssue, {
      username: userEmail,
      acType: 'myntra',
      ticketType: formatData.ticketFormat,
      resourceType: 'claimFormats',
      manageAction: 'updateFormat',
      actionType: 'manageNeededResources',
      Index: formatData.Index,
      Is_Default: formatData.Is_Default,
      Content: formatData.Content,
      oldDefaultIndex: defaultFormatIndex,
    });
    if (response) {
      if (formatData.Is_Default) {
        setDefaultFormatIndex(formatData.Index);
      }
      enqueueSnackbar(response.data.body);
    }
    setLoading(false);
  };

  const removeButtonClickHandle = async (formatData, index) => {
    setLoading(true);

    const response = await CreatePostAPICall(enqueueSnackbar, ClaimIssue, {
      username: userEmail,
      acType: 'myntra',
      ticketType: formatData.ticketFormat,
      resourceType: 'claimFormats',
      manageAction: 'removeFormat',
      Index: formatData.Index,
      actionType: 'manageNeededResources',
    });
    if (response) {
      if (formatData.Is_Default) {
        setDefaultFormatIndex(formatData.Index);
      }
      formatData.splice(index, 1);
      setCustomFormatData(formatData);
    }
    setLoading(false);
  };

  const handleChangeCustomFormat = (event) => {
    if (event.target.value) {
      setSelectedCustomFormatAction(event.target.value);
      setActionAndCallApi(event.target.value);
    } else {
      setCustomFormatData([]);
      setSelectedCustomFormatAction(null);
    }
  };

  const handleAddTag = (tagString) => {
    const tempArray = [...customFormatData];
    tempArray.map((value, idx) => {
      if (idx === selectedFormatIndex && !value.Is_Default) {
        value.Content += tagString;
      }
      return value;
    });
    setCustomFormatData(tempArray);
  };

  const onMakeIsDefault = (index) => {
    const tempArray = [...customFormatData];
    tempArray.map((value, idx) => {
      if (index === idx) value.Is_Default = true;
      if (index !== idx) value.Is_Default = false;
      return value;
    });
    setCustomFormatData(tempArray);
  };

  const onMakeEditable = (index) => {
    const tempArray = [...customFormatData];
    tempArray.map((value, idx) => {
      if (index === idx && value.Is_Default) value.Is_Default = false;
      return value;
    });
    setSelectedFormatIndex(index);
    setCustomFormatData(tempArray);
  };

  const handleCustomNoteText = (inputValue, index) => {
    const tempArray = [...customFormatData];
    tempArray[index].Content = inputValue;
    setCustomFormatData(tempArray);
  };

  return (
    <>
      <BootstrapDialog
        sx={{ '& .MuiDialog-paper': { width: '100%', maxHeight: 500 } }}
        maxWidth="lg"
        onClose={() => {
          handleClose(false);
          setCustomFormatData([]);
          setSelectedCustomFormatAction(null);
        }}
        aria-labelledby="customized-dialog-title"
        open={isModalOpen}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => {
            handleClose(false);
            setCustomFormatData([]);
            setSelectedCustomFormatAction(null);
          }}
        >
          Custom Ticket Generate : {selectedCustomFormatAction}
        </BootstrapDialogTitle>
        <Divider orientation="horizontal" flexItem />
        <DialogContent>
          <Box>
            <Stack spacing={3}>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <FormControl required sx={{ width: '100%' }}>
                  <InputLabel>Custom Format Type</InputLabel>
                  <Select
                    defaultValue={[]}
                    placeholder={'Custom Format Type'}
                    onChange={handleChangeCustomFormat}
                    MenuProps={MenuProps}
                    input={<OutlinedInput label="Custom Format Type" />}
                  >
                    {TicketFormatTypeOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Button sx={{ width: '200px' }} variant="contained" autoFocus onClick={handleAddNewFormat}>
                  Add New Format
                </Button>
              </Stack>

              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <div style={{ width: '100%' }}>
                  {customFormatData
                    .map((value, index) => (
                      <Card key={index} sx={{ p: 3, m: 1 }}>
                        {selectedCustomFormatAction === 'Pending_Not_Received' ? (
                          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1} sx={{ mb: 1 }}>
                            {pendingNotReceivedListVariables.map((tag, index) => (
                              <Chip
                                onClick={() => handleAddTag(tag)}
                                color="primary"
                                key={index}
                                label={tag}
                                variant="outlined"
                              />
                            ))}
                          </Stack>
                        ) : (
                          <>
                            {selectedCustomFormatAction === 'Delivered_Not_Received' ? (
                              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
                                {podListVariables.map((tag, index) => (
                                  <Chip
                                    onClick={() => handleAddTag(tag)}
                                    color="primary"
                                    key={index}
                                    label={tag}
                                    variant="outlined"
                                  />
                                ))}
                              </Stack>
                            ) : (
                              <>
                                {selectedCustomFormatAction === 'D_P_W_Not_Received' ? (
                                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
                                    {dpwListVariables.map((tag, index) => (
                                      <Chip
                                        onClick={() => handleAddTag(tag)}
                                        color="primary"
                                        key={index}
                                        label={tag}
                                        variant="outlined"
                                      />
                                    ))}
                                  </Stack>
                                ) : (
                                  <>
                                    {selectedCustomFormatAction === 'Payment_Not_Received' ? (
                                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
                                        {paymentListVariables.map((tag, index) => (
                                          <Chip
                                            onClick={() => handleAddTag(tag)}
                                            color="primary"
                                            key={index}
                                            label={tag}
                                            variant="outlined"
                                          />
                                        ))}
                                      </Stack>
                                    ) : null}
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                        <div>
                          <div
                            onClick={() => {
                              onMakeEditable(index);
                            }}
                            aria-hidden="true"
                          >
                            <div disabled={value.Is_Default}>
                              <TextField
                                value={value.Content}
                                multiline
                                fullWidth
                                rows={4}
                                onChange={(event) => {
                                  event.target.focus = true;
                                  value.Content = event.target.value;
                                  handleCustomNoteText(event.target.value, index);
                                }}
                                placeholder="Enter your custom format note..."
                                sx={{
                                  mt: 2,
                                  '& fieldset': {
                                    borderWidth: `1px !important`,
                                    borderColor: (theme) => `${theme.palette.grey[500_32]} !important`,
                                  },
                                }}
                              />

                              <Box
                                sx={{
                                  mt: 3,
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <Box sx={{ display: 'flex' }}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        id={`checkbox${index}`}
                                        value={value.Index}
                                        checked={value.Is_Default}
                                        onChange={(e) => {
                                          onMakeIsDefault(index);
                                        }}
                                        name="Make Default"
                                      />
                                    }
                                    label="Make Default"
                                  />
                                  <Button
                                    sx={{ mr: 1 }}
                                    variant="contained"
                                    onClick={() => {
                                      saveButtonClickHandle(value);
                                    }}
                                  >
                                    Save
                                  </Button>
                                  <Button
                                    variant="contained"
                                    onClick={() => {
                                      removeButtonClickHandle(value, index);
                                    }}
                                  >
                                    Remove
                                  </Button>
                                </Box>
                              </Box>
                            </div>
                          </div>
                        </div>
                      </Card>
                    ))
                    .reverse()}
                </div>
              </Stack>
            </Stack>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
}
