export const formats = {
    "flipkart": [
        {
            "Content": "Dear Sir/Madam, \n                                      Please check the below attachment the Photos and order item id :- [SUB_ORDER_ID] in which i have received the [RETURN_CONDITION] product during the return.\n                                      so please check and process my amount for that product value as soon  as possible. This type of return orders bring us 100% cost loss. \n                                      so please take Strict action in this type of issue on courier partners or customers. \n\n                                      Order item  ID: [SUB_ORDER_ID]\n                                      AWB: [AWB]\n                                      Delivery Partner:  [RETURN_COURIER_PARTNER]  AND PLEASE REFUND 100% SHIPPING CHARGES AS WELL.",
            "Is_Default": true,
            "ticketFormat": "D_P_W_Not_Received",
            "Index": 1
        }
        ,
        {
            "Content":
                "I have not received return order with AWB No :- [AWB] TYPE :- [RET_TYPE] Status of order as per flipkart system is :- [ORDER_STATUS] and Date of delivery as per flipkart is :- [DELIVERED_DATE].",
            "Is_Default":
                true,
            "ticketFormat":
                "Delivered_Not_Received",
            "Index":
                1
        }
        ,
        {
            "Content":
                "I didn't got payment for Order item ID:- [SUB_ORDER_ID] which was picked up from our side on [PICK_UP_DATE]. Please Proceed payment as per flipkart  policy as soon as possible.",
            "Is_Default":
                true,
            "ticketFormat":
                "Payment_Not_Received",
            "Index":
                1
        }
        ,
        {
            "Content":
                "I have not received return  with Order Item Id No :- [SUB_ORDER_ID] TYPE :- [RET_TYPE] , as per the flipkart policy If I do not receive [RET_TYPE] return within 60 Days , then I can raise the ticket as lost parcel. Though,  AWB No :- [AWB] which is still In Transit and it's been [LATE_DAYS] days and still I didn't receive this parcel. So check this issue as soon as possible.",
            "Is_Default":
                false,
            "ticketFormat":
                "Pending_Not_Received",
            "Index":
                1
        }
        ,
        {
            "Content":
                "Order ID: [SUB_ORDER_ID]\n(Order Item ID: [SUB_ORDER_ID]\n ) - The return for this order has not been delivered. \nIt has been more than 60 days from the Return approval date and less than 120 days. \nThe return promise period has been breached. \nHence kindly approve the claim with the entire settlement amount. \nIf this order has been delivered, please provide us with the Return delivery date, so that we can cross check.",
            "Is_Default":
                true,
            "ticketFormat":
                "Pending_Not_Received",
            "Index":
                2
        }
        ,
        {
            "Content":
                "Incident for Do Not Expect Delivery. Order Item Id: [SUB_ORDER_ID] Order Id: [ORDER_ID]",
            "Is_Default":
                true,
            "ticketFormat":
                "Return Don't Expect",
            "Index":
                1
        }
    ],
    "meesho":[
        {
            "Content": "Dear Sir/Madam, \n                                              Please check the below attachment for the video and order id in which i have received the [RETURN_CONDITION] product during the return.\n                                              so please check and process my amount for that product value as soon  as possible. This type of return orders bring us 100% cost loss. \n                                              so please take Strict action in this type of issue on courier partners or customers. \n        \n                                              Order ID: [SUB_ORDER_ID]\n                                              AWB: [AWB]\n                                              Delivery_partner: [RETURN_COURIER_PARTNER] AND PLEASE REFUND 100% SHIPPING CHARGES AS WELL.\n                                            ",
            "Is_Default": true,
            "ticketFormat": "D_P_W_Not_Received",
            "Index": 1
        },
        {
            "Content": "I have not received return order with AWB No :- [AWB] TYPE :- [RET_TYPE] Status of order as per meesho system is :- [ORDER_STATUS] and Date of delivery as per meesho is :- [DELIVERED_DATE] ",
            "Is_Default": true,
            "ticketFormat": "Delivered_Not_Received",
            "Index": 1
        },
        {
            "Content": "I didn't got payment for suborder no [SUB_ORDER_ID] which was picked up from our side on [PICK_UP_DATE]. Please Proceed payment as per meesho's policy.",
            "Is_Default": true,
            "ticketFormat": "Payment_Not_Received",
            "Index": 1
        },
        {
            "Content": "I have not received return  with  No :- [SUB_ORDER_ID] TYPE :- [RET_TYPE] , as per the meesho policy If I do not receive [RET_TYPE] return within [MEESHO_LATE_DAYS], then I can raise the ticket as lost parcel. Though,  AWB No :- [AWB] which is still In Transit and it's been [LATE_DAYS] days and still I didn't receive this parcel. So check this issue as soon as possible.",
            "Is_Default": true,
            "ticketFormat": "Pending_Not_Received",
            "Index": 1
        },
        {
            "Content": "Hello team,\n                                           We are in doubt that these mentioned order Id have been tampered packaging so we request for open box delivery for these orders and one attempt has been made thus we have rejected such packets,\n                                           so we request you to kindly resolve this issue. \n            \n                                      SUB_ORDER_ID : - [SUB_ORDER_ID]\n                                            ",
            "Is_Default": true,
            "ticketFormat": "Rejected",
            "Index": 1
        }
    ],
    "glowroad":[
        {
            "Content": "Dear Sir/Madam, \n                                      Please check the below attachment for the Photos, Video, and order item id:-[SUB_ORDER_ID] in which I received the [RETURN_CONDITION] product during the return.\n                                      so please check and process my amount for that product value as soon as possible. This type of return order brings us a 100% cost loss. \n                                      so please take Strict action in this type of issue on courier partners or customers. \n\n                                      Order item  ID: [SUB_ORDER_ID]\n                                      AWB: [AWB]\n                                      Delivery Partner:  [RETURN_COURIER_PARTNER] AND PLEASE REFUND 100% SHIPPING CHARGES AS WELL.",
            "Is_Default": true,
            "ticketFormat": "D_P_W_Not_Received",
            "Index": 1
        },
        {
            "Content": "I have not received the return order with AWB No:- [AWB] TYPE:- [RET_TYPE] Status of the order as per Glowroad system is:- [ORDER_STATUS] and the Date of delivery as per Glowroad is:- [DELIVERED_DATE]. So check this issue as soon as possible.",
            "Is_Default": true,
            "ticketFormat": "Delivered_Not_Received",
            "Index": 1
        },
        {
            "Content": "I didn't got payment for Order ID:- [SUB_ORDER_ID]  which was picked up from our side on [PICK_UP_DATE]. Please Proceed with payment as per Glowroad policy as soon as possible.",
            "Is_Default": true,
            "ticketFormat": "Payment_Not_Received",
            "Index": 1
        },
        {
            "Content": "I have not received a return with Order No:- [SUB_ORDER_ID]  TYPE:- [RET_TYPE], as per the Glowroad policy If I do not receive [RET_TYPE] return within 25 Days Of Return, then I can raise the ticket as a lost parcel. Though,  AWB No:- [AWB] which is still In Transit and it's been [LATE_DAYS] days, I still didn't receive this parcel. So check this issue as soon as possible.",
            "Is_Default": true,
            "ticketFormat": "Pending_Not_Received",
            "Index": 1
        }
    ],
    "limeroad":[
        {
            "Content": "Dear Sir/Madam, \n                                      Please check the below attachment for the Photos, Video, and order item id:-[SUB_ORDER_ID] in which I received the [RETURN_CONDITION] product during the return.\n                                      so please check and process my amount for that product value as soon as possible. This type of return order brings us a 100% cost loss. \n                                      so please take Strict action in this type of issue on courier partners or customers. \n\n                                      Order item  ID: [SUB_ORDER_ID]\n                                      AWB: [AWB]\n                                      Delivery Partner:  [RETURN_COURIER_PARTNER] AND PLEASE REFUND 100% SHIPPING CHARGES AS WELL.",
            "Is_Default": true,
            "ticketFormat": "D_P_W_Not_Received",
            "Index": 1
        },
        {
            "Content": "I have not received the return order with AWB No:- [AWB] TYPE:- [RET_TYPE] Status of the order as per Glowroad system is:- [ORDER_STATUS] and the Date of delivery as per Glowroad is:- [DELIVERED_DATE]. So check this issue as soon as possible.",
            "Is_Default": true,
            "ticketFormat": "Delivered_Not_Received",
            "Index": 1
        },
        {
            "Content": "I didn't got payment for Order ID:- [SUB_ORDER_ID]  which was picked up from our side on [PICK_UP_DATE]. Please Proceed with payment as per Glowroad policy as soon as possible.",
            "Is_Default": true,
            "ticketFormat": "Payment_Not_Received",
            "Index": 1
        },
        {
            "Content": "I have not received a return with Order No:- [SUB_ORDER_ID]  TYPE:- [RET_TYPE], as per the Glowroad policy If I do not receive [RET_TYPE] return within 25 Days Of Return, then I can raise the ticket as a lost parcel. Though,  AWB No:- [AWB] which is still In Transit and it's been [LATE_DAYS] days, I still didn't receive this parcel. So check this issue as soon as possible.",
            "Is_Default": true,
            "ticketFormat": "Pending_Not_Received",
            "Index": 1
        }
    ],
    "snapdeal":[
        {
            "Content": "Dear Sir/Madam, \n                                      Please check the below attachment for the Photos, Video, and order item id:-[SUB_ORDER_ID] in which I received the [RETURN_CONDITION] product during the return.\n                                      so please check and process my amount for that product value as soon as possible. This type of return order brings us a 100% cost loss. \n                                      so please take Strict action in this type of issue on courier partners or customers. \n\n                                      Order item  ID: [SUB_ORDER_ID]\n                                      AWB: [AWB]\n                                      Delivery Partner:  [RETURN_COURIER_PARTNER] AND PLEASE REFUND 100% SHIPPING CHARGES AS WELL.",
            "Is_Default": true,
            "ticketFormat": "D_P_W_Not_Received",
            "Index": 1
        },
        {
            "Content": "I have not received the return order with AWB No:- [AWB] TYPE:- [RET_TYPE] Status of the order as per Glowroad system is:- [ORDER_STATUS] and the Date of delivery as per Glowroad is:- [DELIVERED_DATE]. So check this issue as soon as possible.",
            "Is_Default": true,
            "ticketFormat": "Delivered_Not_Received",
            "Index": 1
        },
        {
            "Content": "I didn't got payment for Order ID:- [SUB_ORDER_ID]  which was picked up from our side on [PICK_UP_DATE]. Please Proceed with payment as per Glowroad policy as soon as possible.",
            "Is_Default": true,
            "ticketFormat": "Payment_Not_Received",
            "Index": 1
        },
        {
            "Content": "I have not received a return with Order No:- [SUB_ORDER_ID]  TYPE:- [RET_TYPE], as per the Glowroad policy If I do not receive [RET_TYPE] return within 25 Days Of Return, then I can raise the ticket as a lost parcel. Though,  AWB No:- [AWB] which is still In Transit and it's been [LATE_DAYS] days, I still didn't receive this parcel. So check this issue as soon as possible.",
            "Is_Default": true,
            "ticketFormat": "Pending_Not_Received",
            "Index": 1
        }
    ],
}