/* eslint-disable */
export const citymallExcelValidation = (excelRowHeader, type) => {

    // console.log("type == ",type);
    if (type === 'Payment') {
        // console.log("Payment === ");
        const citymallPaymentExcelRowTypeOne = [
                "order_id",
                "order_date",
                "dispatch_date",
                "return_date_of_delivery_to_seller",
                "month_of_return_date_of_delivery_to_seller",
                "payment_date",
                "product_name",
                "sku_id",
                "live_order_status",
                "product_gst",
                "listing_price",
                "quantity",
                "transaction_id",
                "final_settlement_amount",
                "total_sale_amount",
                "sale_return_amount",
                "shipping_revenue",
                "forward_shipping_fee_without_gst",
                "shipping_return_amount",
                "return_shipping_fee_without_gst",
                "platform_fee",
                "penalty",
                "shipping_charge",
                "return_shipping_charge",
                "tcs",
                "tds"
        ];

        const citymallPaymentExcelRowTypeTwo = [
            'NEFT ID',
            'Neft Type',
            ' Payment Date',
            'Bank Settlement Value (Rs.) \n= SUM(J:Q)',
            'Input GST + TCS Credits (Rs.)\n[GST+TCS]',
            'Income Tax Credits (Rs.)\n[TDS]',
            null,
            'Order ID',
            'Order item ID',
            'Sale Amount (Rs.)',
            'Total Offer Amount (Rs.)',
            'My share (Rs.)',
            'Customer Add-ons Amount (Rs.)',
            'Marketplace Fee (Rs.)\n= SUM (U:AH)',
            'Taxes (Rs.)',
            'Protection Fund (Rs.)',
            'Refund (Rs.)',
            null,
            'Tier',
            'Commission Rate (%)',
            'Commission (Rs.)',
            'Fixed Fee  (Rs.)',
            'Collection Fee (Rs.)',
            'Pick And Pack Fee (Rs.)',
            'Shipping Fee (Rs.)',
            'Reverse Shipping Fee (Rs.)',
            'No Cost Emi Fee Reimbursement(Rs.)',
            'Installation Fee (Rs.)',
            'Tech Visit Fee (Rs.)',
            'Uninstallation & Packaging Fee (Rs.)',
            'Customer Add-ons Amount Recovery (Rs.)',
            'Franchise Fee (Rs.)',
            'Shopsy Marketing Fee (Rs.)',
            'Product Cancellation Fee (Rs.)',
            null,
            'TCS (Rs.)',
            'TDS (Rs.)',
            'GST on MP Fees (Rs.)',
            null,
            'Dead Weight (kgs)',
            'Length*Breadth*Height',
            'Volumetric Weight (kgs)',
            'Chargeable Weight Source',
            'Chargeable Weight Type',
            'Chargeable Wt. Slab (In Kgs)',
            'Shipping Zone',
            null,
            'Order Date',
            'Dispatch Date',
            'Fulfilment Type',
            'Seller SKU',
            'Quantity',
            'Product Sub Category',
            'Additional Information',
            'Return Type',
            'Shopsy Order',
            'Item Return Status',
            null,
            'Invoice ID',
            'Invoice Date',
            null,
            'Sale Amount',
            'Total Offer Amount',
            null,
            null,
            'My Share',
        ];

        const citymallPaymentExcelRowTypeThree = [
            'NEFT ID',
            'Neft Type',
            ' Payment Date',
            'Bank Settlement Value (Rs.) \n= SUM(J:R)',
            'Input GST + TCS Credits (Rs.)\n[GST+TCS]',
            'Income Tax Credits (Rs.)\n[TDS]',
            null,
            'Order ID',
            'Order item ID',
            'Sale Amount (Rs.)',
            'Total Offer Amount (Rs.)',
            'My share (Rs.)',
            'Customer Add-ons Amount (Rs.)',
            'Marketplace Fee (Rs.)\n= SUM (V:AI)',
            'Taxes (Rs.)',
            'Offer Adjustments (Rs.)',
            'Protection Fund (Rs.)',
            'Refund (Rs.)',
            null,
            'Tier',
            'Commission Rate (%)',
            'Commission (Rs.)',
            'Fixed Fee  (Rs.)',
            'Collection Fee (Rs.)',
            'Pick And Pack Fee (Rs.)',
            'Shipping Fee (Rs.)',
            'Reverse Shipping Fee (Rs.)',
            'No Cost Emi Fee Reimbursement(Rs.)',
            'Installation Fee (Rs.)',
            'Tech Visit Fee (Rs.)',
            'Uninstallation & Packaging Fee (Rs.)',
            'Customer Add-ons Amount Recovery (Rs.)',
            'Franchise Fee (Rs.)',
            'Shopsy Marketing Fee (Rs.)',
            'Product Cancellation Fee (Rs.)',
            null,
            'TCS (Rs.)',
            'TDS (Rs.)',
            'GST on MP Fees (Rs.)',
            null,
            'Offer amount settled as Discount in MP Fee (Rs.)',
            'Item GST Rate (%)',
            'Discount in MP fees (Rs.) \n[AO/(1+AP/100)]',
            'GST on Discount (Rs.) \n[18%*AQ]',
            'Total Discount in MP Fee (Rs.) \n[AQ + AR]',
            'Offer Adjustment (Rs.) \n[AS+AO]',
            null,
            'Dead Weight (kgs)',
            'Length*Breadth*Height',
            'Volumetric Weight (kgs)',
            'Chargeable Weight Source',
            'Chargeable Weight Type',
            'Chargeable Wt. Slab (In Kgs)',
            'Shipping Zone',
            null,
            'Order Date',
            'Dispatch Date',
            'Fulfilment Type',
            'Seller SKU',
            'Quantity',
            'Product Sub Category',
            'Additional Information',
            'Return Type',
            'Shopsy Order',
            'Item Return Status',
            null,
            'Invoice ID',
            'Invoice Date',
            null,
            'Sale Amount',
            'Total Offer Amount',
            null,
            null,
            'My Share',
        ];

        const citymallPaymentExcelRowTypeFour = [
            "NEFT ID",
            "Neft Type",
            " Payment Date",
            "Bank Settlement Value (Rs.) \r\n= SUM(J:R)",
            "Input GST + TCS Credits (Rs.)\r\n[GST+TCS]",
            "Income Tax Credits (Rs.)\r\n[TDS]",
            null,
            "Order ID",
            "Order item ID",
            "Sale Amount (Rs.)",
            "Total Offer Amount (Rs.)",
            "My share (Rs.)",
            "Customer Add-ons Amount (Rs.)",
            "Marketplace Fee (Rs.)\r\n= SUM (V:AI)",
            "Taxes (Rs.)",
            "Offer Adjustments (Rs.)",
            "Protection Fund (Rs.)",
            "Refund (Rs.)",
            null,
            "Tier",
            "Commission Rate (%)",
            "Commission (Rs.)",
            "Fixed Fee  (Rs.)",
            "Collection Fee (Rs.)",
            "Pick And Pack Fee (Rs.)",
            "Shipping Fee (Rs.)",
            "Reverse Shipping Fee (Rs.)",
            "No Cost Emi Fee Reimbursement(Rs.)",
            "Installation Fee (Rs.)",
            "Tech Visit Fee (Rs.)",
            "Uninstallation & Packaging Fee (Rs.)",
            "Customer Add-ons Amount Recovery (Rs.)",
            "Franchise Fee (Rs.)",
            "Shopsy Marketing Fee (Rs.)",
            "Product Cancellation Fee (Rs.)",
            null,
            "TCS (Rs.)",
            "TDS (Rs.)",
            "GST on MP Fees (Rs.)",
            null,
            "Offer amount settled as Discount in MP Fee (Rs.)",
            "Item GST Rate (%)",
            "Discount in MP fees (Rs.) \r\n[AO/(1+AP/100)]",
            "GST on Discount (Rs.) \r\n[18%*AQ]",
            "Total Discount in MP Fee (Rs.) \r\n[AQ + AR]",
            "Offer Adjustment (Rs.) \r\n[AS+AO]",
            null,
            "Dead Weight (kgs)",
            "Length*Breadth*Height",
            "Volumetric Weight (kgs)",
            "Chargeable Weight Source",
            "Chargeable Weight Type",
            "Chargeable Wt. Slab (In Kgs)",
            "Shipping Zone",
            null,
            "Order Date",
            "Dispatch Date",
            "Fulfilment Type",
            "Seller SKU",
            "Quantity",
            "Product Sub Category",
            "Additional Information",
            "Return Type",
            "Shopsy Order",
            "Item Return Status",
            null,
            "Invoice ID",
            "Invoice Date",
            null,
            "Sale Amount",
            "Total Offer Amount",
            null,
            null,
            "My Share"
        ];

        // console.log("excelRowHeader",excelRowHeader);
        if (JSON.stringify([...excelRowHeader]) === JSON.stringify(citymallPaymentExcelRowTypeOne)) {
            console.log("miral you can do it");
            return true;
        }

        if (JSON.stringify([...excelRowHeader]) === JSON.stringify(citymallPaymentExcelRowTypeTwo)) {
            return true;
        }

        if (JSON.stringify([...excelRowHeader]) === JSON.stringify(citymallPaymentExcelRowTypeThree)) {
            return true;
        }
        if (JSON.stringify([...excelRowHeader]) === JSON.stringify(citymallPaymentExcelRowTypeFour)) {
            return true;
        }
    }
    if (type === 'Qualified Orders') {
        const citymallPaymentExcelRowTypeOne = [
            "NEFT ID",
            "Neft Type",
            " Payment Date",
            "Bank Settlement Value (Rs.) \n= SUM(J:R)",
            "Input GST + TCS Credits (Rs.)\n[GST+TCS]",
            "Income Tax Credits (Rs.)\n[TDS]",
            null,
            "Order ID",
            "Order item ID",
            "Sale Amount (Rs.)",
            "Total Offer Amount (Rs.)",
            "My share (Rs.)",
            "Customer Add-ons Amount (Rs.)",
            "Marketplace Fee (Rs.)\n= SUM (V:AI)",
            "Taxes (Rs.)",
            "Offer Adjustments (Rs.)",
            "Protection Fund (Rs.)",
            "Refund (Rs.)",
            null,
            "Tier",
            "Commission Rate (%)",
            "Commission (Rs.)",
            "Fixed Fee  (Rs.)",
            "Collection Fee (Rs.)",
            "Pick And Pack Fee (Rs.)",
            "Shipping Fee (Rs.)",
            "Reverse Shipping Fee (Rs.)",
            "No Cost Emi Fee Reimbursement(Rs.)",
            "Installation Fee (Rs.)",
            "Tech Visit Fee (Rs.)",
            "Uninstallation & Packaging Fee (Rs.)",
            "Customer Add-ons Amount Recovery (Rs.)",
            "Franchise Fee (Rs.)",
            "Shopsy Marketing Fee (Rs.)",
            "Product Cancellation Fee (Rs.)",
            null,
            "TCS (Rs.)",
            "TDS (Rs.)",
            "GST on MP Fees (Rs.)",
            null,
            "Offer amount settled as Discount in MP Fee (Rs.)",
            "Item GST Rate (%)",
            "Discount in MP fees (Rs.) \n[AO/(1+AP/100)]",
            "GST on Discount (Rs.) \n[18%*AQ]",
            "Total Discount in MP Fee (Rs.) \n[AQ + AR]",
            "Offer Adjustment (Rs.) \n[AS+AO]",
            null,
            "Dead Weight (kgs)",
            "Length*Breadth*Height",
            "Volumetric Weight (kgs)",
            "Chargeable Weight Source",
            "Chargeable Weight Type",
            "Chargeable Wt. Slab (In Kgs)",
            "Shipping Zone",
            null,
            "Order Date",
            "Dispatch Date",
            "Fulfilment Type",
            "Seller SKU",
            "Quantity",
            "Product Sub Category",
            "Additional Information",
            "Return Type",
            "Shopsy Order",
            "Item Return Status",
            null,
            "Invoice ID",
            "Invoice Date",
            null,
            "Sale Amount",
            "Total Offer Amount",
            null,
            null,
            "My Share"
        ];
        // console.log(excelRowHeader)

        if (JSON.stringify([...excelRowHeader]) === JSON.stringify(citymallPaymentExcelRowTypeOne)) {
            return true;
        }
    }
    return false;
};

export const citymallFinancialExcelValidation = (excelRowHeader) => {
    const citymallExcelRowTypeOne = [
        'NEFT ID',
        'Neft Type',
        ' Settlement Date',
        'Bank Settlement Value (Rs.) \n= SUM(J:Q)',
        'Input GST + TCS Credits (Rs.)\n[GST+TCS]',
        'Income Tax Credits (Rs.)\n[TDS]',
        null,
        'Order ID',
        'Order item ID',
        'Sale Amount (Rs.)',
        'Total Offer Amount (Rs.)',
        'My share (Rs.)',
        'SDD/NDD Amount (Rs.)',
        'Marketplace Fee (Rs.)\n= SUM (U:AL)',
        'Taxes (Rs.)',
        'Protection Fund (Rs.)',
        'Refund (Rs.)',
        null,
        'Tier',
        'Commission Rate (%)',
        'Commission (Rs.)',
        'Fixed Fee  (Rs.)',
        'Collection Fee (Rs.)',
        'Pick And Pack Fee (Rs.)',
        'Shipping Fee (Rs.)',
        'Reverse Shipping Fee (Rs.)',
        'No Cost Emi Fee Reimbursement(Rs.)',
        'Installation Fee (Rs.)',
        'Uninstallation Fee (Rs.)',
        'Tech Visit Fee (Rs.)',
        'Uninstallation & Packaging Fee (Rs.)',
        'SDD/NDD Type',
        'SDD/NDD Recovery (Rs.)',
        'Franchise Fee (Rs.)',
        'Shopsy Marketing Fee (Rs.)',
        'Product Cancellation Fee (Rs.)',
        'Service Cancellation Fee(Rs.)',
        'Fee Discount (Rs.)',
        null,
        'TCS (Rs.)',
        'TDS (Rs.)',
        'GST on MP Fees (Rs.)',
        null,
        'Multipart Shipment?',
        'Profiler Dead Weight',
        ' Seller Dead Weight',
        'Length*Breadth*Height',
        'Volumetric Weight(In Kgs)',
        'Chargeable Weight Type',
        'Chargeable Wt. Slab (In Kgs)',
        'Shipping Zone',
        null,
        'Shipping Neutral? ',
        'Customer Shipping incl. GST (Rs.) \n= MAX (BX, BZ)',
        'GST paid by customer (%)',
        'Pre-GST Customer Shipping (Rs.) \n= BB/(1+BC/100)',
        'Shipping Fee (Rs.)',
        null,
        'Order Date',
        'Dispatch Date',
        'Fulfilment Type',
        'Seller SKU',
        'Quantity',
        'Product Sub Category',
        'Additional Information',
        'Return Type',
        'Shopsy Order',
        'Item Return Status',
        null,
        null,
        'ID',
        'Date',
        null,
        'Sale Amount',
        null,
        null,
        'Total Offer Amount',
        null,
        null,
        'My Share',
    ];

    const citymallExcelRowTypeTwo = [
        'NEFT ID',
        'Neft Type',
        'Payment Date',
        'Bank Settlement Value (Rs.) \n= SUM(J:Q)',
        'Input GST + TCS Credits (Rs.)\n[GST+TCS]',
        'Income Tax Credits (Rs.)\n[TDS]',
        null,
        'Order ID',
        'Order item ID',
        'Sale Amount (Rs.)',
        'Total Offer Amount (Rs.)',
        'My share (Rs.)',
        'Customer Add-ons Amount (Rs.)',
        'Marketplace Fee (Rs.)\n= SUM (U:AH)',
        'Taxes (Rs.)',
        'Protection Fund (Rs.)',
        'Refund (Rs.)',
        null,
        'Tier',
        'Commission Rate (%)',
        'Commission (Rs.)',
        'Fixed Fee  (Rs.)',
        'Collection Fee (Rs.)',
        'Pick And Pack Fee (Rs.)',
        'Shipping Fee (Rs.)',
        'Reverse Shipping Fee (Rs.)',
        'No Cost Emi Fee Reimbursement(Rs.)',
        'Installation Fee (Rs.)',
        'Tech Visit Fee (Rs.)',
        'Uninstallation & Packaging Fee (Rs.)',
        'Customer Add-ons Amount Recovery (Rs.)',
        'Franchise Fee (Rs.)',
        'Shopsy Marketing Fee (Rs.)',
        'Product Cancellation Fee (Rs.)',
        null,
        'TCS (Rs.)',
        'TDS (Rs.)',
        'GST on MP Fees (Rs.)',
        null,
        'Dead Weight (kgs)',
        'Length*Breadth*Height',
        'Volumetric Weight (kgs)',
        'Chargeable Weight Source',
        'Chargeable Weight Type',
        'Chargeable Wt. Slab (In Kgs)',
        'Shipping Zone',
        null,
        'Order Date',
        'Dispatch Date',
        'Fulfilment Type',
        'Seller SKU',
        'Quantity',
        'Product Sub Category',
        'Additional Information',
        'Return Type',
        'Shopsy Order',
        'Item Return Status',
        null,
        'Invoice ID',
        'Invoice Date',
        null,
        'Sale Amount',
        'Total Offer Amount',
        null,
        null,
        'My Share',
    ];

    const citymallExcelRowTypeThree = [
        'NEFT ID',
        'Neft Type',
        'Date',
        'Settlement Value (Rs.) \n= SUM(G:P)',
        null,
        'Order ID',
        'Order item ID',
        'Sale Amount',
        'Total Offer Amount',
        'My Share',
        'Customer Shipping Amount',
        'Marketplace Fee (Rs.)',
        'Tax Collected at source (Rs.)',
        'TDS (Rs.)',
        'Taxes (Rs.)',
        'Protection Fund (Rs.)',
        'Refund (Rs.)',
        null,
        'Order Date',
        'Dispatch Date',
        'Fulfilment Type',
        'Seller SKU',
        'Quantity',
        'Product Sub Category',
        'Additional Information',
        'Return Type',
        'Shopsy Order',
        'Item Return Status',
        null,
        'Sale Amount',
        'Total Offer Amount',
        'My Share',
        null,
        'Tier',
        'Commission Rate',
        'Commission (Rs.)',
        'Collection Fee (Rs.)',
        'Fixed Fee  (Rs.)',
        'No Cost Emi Fee Reimbursement(Rs.)',
        'Installation Fee (Rs.)',
        'Uninstallation Fee (Rs.)',
        'Tech Visit Fee (Rs.)',
        'Uninstallation & Packaging Fee (Rs.)',
        'Pick And Pack Fee',
        'Customer Shipping Fee Type',
        ' Customer Shipping Fee',
        'Shipping Fee (Rs.)',
        'Reverse Shipping Fee (Rs.)',
        'Franchise Fee (Rs.)',
        'Shopsy Marketing Fee (Rs.)',
        'Product Cancellation Fee (Rs.)',
        'Service Cancellation Fee(Rs.)',
        'Fee Discount (Rs.)',
        null,
        'Multipart Shipment?',
        'Profiler Dead Weight',
        ' Seller Dead Weight',
        'Length*Breadth*Height',
        'Volumetric Weight(In Kgs)',
        'Chargeable Weight Type',
        'Chargeable Wt. Slab (In Kgs)',
        'Shipping Zone',
        null,
        'ID',
        'Date',
        'Amount',
    ];

    const citymallExcelRowTypeFour = [
        'NEFT ID',
        'Neft Type',
        'Date',
        'Settlement Value (Rs.) \n= SUM(G:P)',
        null,
        'Order ID',
        'Order item ID',
        'Sale Amount',
        'Total Offer Amount',
        'My Share',
        'Customer Shipping Amount',
        'Marketplace Fee (Rs.)',
        'Tax Collected at source (Rs.)',
        'TDS (Rs.)',
        'Taxes (Rs.)',
        'Protection Fund (Rs.)',
        'Refund (Rs.)',
        null,
        'Order Date',
        'Dispatch Date',
        'Fulfilment Type',
        'Seller SKU',
        'Quantity',
        'Product Sub Category',
        'Additional Information',
        'Return Type',
        'Item Return Status',
        null,
        'Sale Amount',
        'Total Offer Amount',
        'My Share',
        null,
        'Tier',
        'Commission Rate',
        'Commission (Rs.)',
        'Collection Fee (Rs.)',
        'Fixed Fee  (Rs.)',
        'No Cost Emi Fee Reimbursement(Rs.)',
        'Installation Fee (Rs.)',
        'Uninstallation Fee (Rs.)',
        'Tech Visit Fee (Rs.)',
        'Uninstallation & Packaging Fee (Rs.)',
        'Pick And Pack Fee',
        'Customer Shipping Fee Type',
        ' Customer Shipping Fee',
        'Shipping Fee (Rs.)',
        'Reverse Shipping Fee (Rs.)',
        'Franchise Fee (Rs.)',
        'Product Cancellation Fee (Rs.)',
        'Service Cancellation Fee(Rs.)',
        'Fee Discount (Rs.)',
        null,
        'Multipart Shipment?',
        'Profiler Dead Weight',
        ' Seller Dead Weight',
        'Length*Breadth*Height',
        'Volumetric Weight(In Kgs)',
        'Chargeable Weight Type',
        'Chargeable Wt. Slab (In Kgs)',
        'Shipping Zone',
        null,
        'ID',
        'Date',
    ];

    const citymallExcelRowTypeFive = [
        "NEFT ID",
        "Neft Type",
        "Payment Date",
        "Bank Settlement Value (Rs.) \n= SUM(J:R)",
        "Input GST + TCS Credits (Rs.)\n[GST+TCS]",
        "Income Tax Credits (Rs.)\n[TDS]",
        null,
        "Order ID",
        "Order item ID",
        "Sale Amount (Rs.)",
        "Total Offer Amount (Rs.)",
        "My share (Rs.)",
        "Customer Add-ons Amount (Rs.)",
        "Marketplace Fee (Rs.)\n= SUM (V:AI)",
        "Taxes (Rs.)",
        "Offer Adjustments (Rs.)",
        "Protection Fund (Rs.)",
        "Refund (Rs.)",
        null,
        "Tier",
        "Commission Rate (%)",
        "Commission (Rs.)",
        "Fixed Fee  (Rs.)",
        "Collection Fee (Rs.)",
        "Pick And Pack Fee (Rs.)",
        "Shipping Fee (Rs.)",
        "Reverse Shipping Fee (Rs.)",
        "No Cost Emi Fee Reimbursement(Rs.)",
        "Installation Fee (Rs.)",
        "Tech Visit Fee (Rs.)",
        "Uninstallation & Packaging Fee (Rs.)",
        "Customer Add-ons Amount Recovery (Rs.)",
        "Franchise Fee (Rs.)",
        "Shopsy Marketing Fee (Rs.)",
        "Product Cancellation Fee (Rs.)",
        null,
        "TCS (Rs.)",
        "TDS (Rs.)",
        "GST on MP Fees (Rs.)",
        null,
        "Offer amount settled as Discount in MP Fee (Rs.)",
        "Item GST Rate (%)",
        "Discount in MP fees (Rs.) \n[AO/(1+AP/100)]",
        "GST on Discount (Rs.) \n[18%*AQ]",
        "Total Discount in MP Fee (Rs.) \n[AQ + AR]",
        "Offer Adjustment (Rs.) \n[AS+AO]",
        null,
        "Length*Breadth*Height",
        "Volumetric Weight (kgs)",
        "Chargeable Weight Source",
        "Chargeable Weight Type",
        "Chargeable Wt. Slab (In Kgs)",
        "Shipping Zone",
        null,
        "Order Date",
        "Dispatch Date",
        "Fulfilment Type",
        "Seller SKU",
        "Quantity",
        "Product Sub Category",
        "Additional Information",
        "Return Type",
        "Shopsy Order",
        "Item Return Status",
        null,
        "Invoice ID",
        "Invoice Date",
        null,
        "Sale Amount",
        "Total Offer Amount",
        null,
        null,
        "My Share"
    ];

    if (JSON.stringify([...excelRowHeader]) === JSON.stringify(citymallExcelRowTypeOne)) {
        return true;
    }

    if (JSON.stringify([...excelRowHeader]) === JSON.stringify(citymallExcelRowTypeTwo)) {
        return true;
    }

    if (JSON.stringify([...excelRowHeader]) === JSON.stringify(citymallExcelRowTypeThree)) {
        return true;
    }

    if (JSON.stringify([...excelRowHeader]) === JSON.stringify(citymallExcelRowTypeFour)) {
        return true;
    }
    if (JSON.stringify([...excelRowHeader]) === JSON.stringify(citymallExcelRowTypeFive)) {
        return true;
    }

    return false;
};
