/* eslint-disable */
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import { capitalCase } from 'change-case';
import {
    Autocomplete,
    Box,
    Button,
    CircularProgress,
    Container,
    Divider,
    Grid,
    Input,
    InputLabel,
    MenuItem,
    Select,
    Tab,
    Tabs,
    TextField,
    Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DatePicker } from 'antd';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import useSettings from '../../../hooks/useSettings';
import useTabs from '../../../hooks/useTabs';
import useAuth from '../../../hooks/useAuth';
import { CreatePostAPICall, FlipkartSaveExcelData, SyncOrders } from '../../../utils/apis';
import {
    isAdmin,
    SystemColor,
    getUserAccountDetails,
    getAccountsDataByType,
    setAccountSelectorData,
    ReadExcelFile,
    getAccountsData,
    CapitalizeFirstLetter, isDesktopEnvironment, uploadSyncFileS3, GetTodayDateTime, isDate, ExcelDateToJSDate,
} from '../../../utils/utilities';
import Iconify from '../../../components/Iconify';
import { UploadMultiFile } from '../../../components/upload';
import AccountSelector from '../../../components/Common/AccountSelector';
import { flipkartFinancialExcelValidation, flipkartExcelValidation } from '../excelValidations/FilpkartValidation';
const XLSX = require('xlsx');

function removeDuplicates(arr) {
    return arr.filter((item, index) => {
        return arr.findIndex((x) => x[0] === item[0] && x[1] === item[1]) === index;
    });
}

export default function FlipkartSyncOrder() {
    const platForm = 'flipkart';
    const { RangePicker } = DatePicker;
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();
    const isLight = theme.palette.mode === 'light';
    const { themeStretch } = useSettings();
    const { user } = useAuth();
    const localUser = window.localStorage.getItem('userEmailId');
    const { currentUploadTab, onChangeUploadTab } = useTabs('Order');
    const [files, setFiles] = useState([]);
    const [fileType, setFileType] = useState('');
    const [dataUploadLoader, setDataUploadLoader] = useState(false);
    const [flipkartFiles, setFlipkartFiles] = useState([]);
    const [allAccountsData, setAllAccountsData] = useState([]);
    const [flipkartDateToBeFetched, setFlipkartDateToBeFetched] = useState({});
    const [reGenerateLabelLoading, setReGenerateLabelLoading] = useState(false);
    const [isReadyToShipLoading, SetIsReadyToShipLoading] = useState(false);
    const [generateLabelLoading, setGenerateLabelLoading] = useState(false);
    const [markRTDLoading, setmarkRTDLoading] = useState(false);
    const [isReturnLoading, SetIsReturnLoading] = useState(false);
    const [isCancelLoading, SetIsCancelLoading] = useState(false);
    const [isInventoryLoading, SetIsInventoryLoading] = useState(false);
    const [isShippedLoading, SetIsShippedLoading] = useState(false);
    const [selectedAccountsWithOutAllOptions, setSelectedAccountsWithOutAllOptions] = useState([]);
    const [supplierId, setSupplierId] = useState([]);
    const [tpEmail, setTpEmail] = useState([]);
    const [companyName, setCompanyName] = useState([]);
    const [maskingList, setMaskingList] = useState([]);
    const [flipkartAccountListOptions, setFlipkartAccountListOptions] = useState([]);
    const [selectedAccountForExcelUpload, setSelectedAccountForExcelUpload] = useState(null);
    const [selectedUploadType, setSelectedUploadType] = useState('');

    // for getting account data from localstorage
    useEffect(async () => {
        getAccountsDataByType(platForm).then((filteredData) => {
            setAllAccountsData(filteredData);
        });

        // from here starts account selector data for Excel upload selector
        const accountData = await getAccountsData();
        const objAcFlipkart = [];
        accountData.forEach((item) => {
            if (item.AcType === 'flipkart') {
                objAcFlipkart.push({
                    value: item.Account.SupplierId,
                    label: `${CapitalizeFirstLetter(item.AcType)} - ${item.Account.Email} - ${item.Account.Name}`,
                    AcType: item.AcType,
                    Email: item.Account.Email,
                    // isMaskingEnabled: (item.isMaskingEnabled ? item.isMaskingEnabled : false),
                    Name: item.Account.Name,
                });
            }
        });
        setFlipkartAccountListOptions(objAcFlipkart);
    }, []);

    // handle of autocomplete for platform wise showing account list
    const handleSelectedAccount = async (event, value) => {
        const response = await setAccountSelectorData(value, allAccountsData);
        setSelectedAccountsWithOutAllOptions(response[1]);
        if (event.length !== 0) {
            const selectedAccountData = await getUserAccountDetails(response[1]);
            setSupplierId(selectedAccountData.supplierIds);
            setTpEmail(selectedAccountData.tpEmailids);
            setCompanyName(selectedAccountData.cName);
            setMaskingList(selectedAccountData.isMaskingEnabled);
        }
    };

    function processDuplicates(inputData) {
        const resultDetails = {};

        for (const data of inputData) {
            const key = `${data[0]}_${data[2]}`; // Combine date and order number as the key
            if (!resultDetails[key]) {
                // If the key doesn't exist, initialize with all values
                resultDetails[key] = data.slice();
            } else {
                // If the key exists, increase the amount at index 1
                resultDetails[key][1] += data[1];
            }
        }

        return Object.values(resultDetails);
    }

    // excel upload api call
    const uploadFlipkartExcelFileData = async (
        tpEmailIds,
        supplierIds,
        dataForUpload,
        syncDataType,
        fileType,
        files,
        token = null,
        cName = null
    ) => {
        const result = processDuplicates(dataForUpload);
        const items = result.slice(0, 100);
        result.splice(0, 100);
        setDataUploadLoader(true);
        const body = {
            syncDataType,
            username: user?.email ? user.email : localUser,
            tpEmailId: tpEmailIds,
            subAccountId: supplierIds,
            dataToSave: items,
        };

        if (token) body.token = token;
        if (cName) body.cName = cName;

        const response = await CreatePostAPICall(enqueueSnackbar, FlipkartSaveExcelData, body);
        if (response) {
            if (result.length > 0) {
                await uploadFlipkartExcelFileData(
                    tpEmailIds,
                    supplierIds,
                    result,
                    syncDataType,
                    fileType,
                    files,
                    response.data.token,
                    response.data.cName
                );
            } else {
                const tempFiles = files.slice(1);
                if (tempFiles.length === 0) {
                    handleRemoveAllFiles();
                    setDataUploadLoader(false);
                    setSelectedUploadType('');
                    enqueueSnackbar('File Saved Successfully.');
                } else {
                    handleOnUploadFlipkart(fileType, tempFiles);
                }
            }
        } else {
            handleRemoveAllFiles();
            setSelectedUploadType('');
            setDataUploadLoader(false);
        }

    };

    const handleFlipkartExportExcel = async (paymentData, mpFeeData) => {

        var headerPaymentExcel = [
            "SubOrderNo",
            "PaymentDate",
            "OrderDate",
            "Sku",
            "Quantity",
            "ReturnType",
            "FinalSettlementAmount",
            "MarketPlaceCharge",
            "ShippingFee",
            "ReverseShippingFee",
            "Compensation",
            "Tax",
            "Commission",
            "GstOnMpFees",
            "Tds"
        ]

        var headerMpFeePaymentExcel = [
            "SubOrderNo",
            "PaymentDate",
            "FinalSettlementAmount"
        ]
        const pySheetData = [headerPaymentExcel, ...paymentData.map(obj => Object.values(obj))];
        const pyMpFeeSheetData = [headerMpFeePaymentExcel, ...mpFeeData.map(obj => Object.values(obj))];

        const workbook = XLSX.utils.book_new();

        const worksheetPy = XLSX.utils.json_to_sheet(pySheetData);
        const worksheetMpFeePy = XLSX.utils.json_to_sheet(pyMpFeeSheetData);
        XLSX.utils.book_append_sheet(workbook, worksheetPy, "Payment");
        XLSX.utils.book_append_sheet(workbook, worksheetMpFeePy, "MpFee");

        var wbout = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

        /* prepare data for POST */
        var blob = new Blob([new Uint8Array(wbout)], { type: "application/octet-stream" });

        const fileName = `ReCreatedFile ${GetTodayDateTime()}.xlsx`;
        const file = new File([blob], fileName, { type: 'application/octet-stream' });

        // var a = document.createElement("a");
        // document.body.appendChild(a);
        // a.style = "display: none";
        // var url = window.URL.createObjectURL(blob);
        // console.log("url ==",url);
        // a.href = url;
        // a.download = fileName;
        // a.click();
        // window.URL.revokeObjectURL(url);
        return file;
    };

    const handleFlipkartExportAdscostExcel = async (adsCostData) => {

        // console.log('export',adsCostData);
        var headerAdsCostExcel = [
            "campaignID",
            "campaignStatus",
            "deductionDate",
            "endDate",
            "adSpends",
        ]

        const XLSX = require('xlsx');
        const adsCostSheetData = [headerAdsCostExcel, ...adsCostData.map(obj => Object.values(obj))];

        const workbook = XLSX.utils.book_new();

        const worksheetAdsCost = XLSX.utils.json_to_sheet(adsCostSheetData);
        XLSX.utils.book_append_sheet(workbook, worksheetAdsCost, "AdsCost");

        var wbout = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

        /* prepare data for POST */
        var blob = new Blob([new Uint8Array(wbout)], { type: "application/octet-stream" });

        const fileName = `ReCreatedFile Adscost ${GetTodayDateTime()}.xlsx`;

        const file = new File([blob], fileName, { type: 'application/octet-stream' });

        // var a = document.createElement("a");
        // document.body.appendChild(a);
        // a.style = "display: none";
        // var url = window.URL.createObjectURL(blob);
        // a.href = url;
        // a.download = fileName;
        // a.click();
        // window.URL.revokeObjectURL(url);
        return file;
    };

    // formatting excel data and calling api function
    const onLoadFlipkartFileUploadDetail = async (e, type, files) => {
        const dataForUpload = [];
        const apiType = 'excelOrdersData';
        // console.log("selectedUploadType", selectedUploadType)
        if (selectedUploadType === "FBF" && currentUploadTab === 'Order') {
            const file = files[0];
            if (file) {
                const fileData = ReadExcelFile(e, 0,);
                if (fileData.length > 1 && fileData[1].length > 4 && fileData[1][4]) {
                    // console.log("fileData", fileData[1][4])
                    var accountData = null
                    allAccountsData.forEach((value) => {
                        // console.log(value)
                        if (value.AcType === 'flipkart' && value.value === fileData[1][4]) {
                            accountData = value
                        }
                    });
                    // accountData = allAccountsData[1]
                    if (accountData) {
                        const data = await uploadSyncFileS3(file, user.email, accountData.Email
                            , accountData.value
                            , accountData.cName,
                            "FBFOrder", file.name, "flipkart", enqueueSnackbar)
                        if (data) {
                            enqueueSnackbar('File uploaded.',);
                            handleRemoveAllFiles();
                            setSelectedUploadType('');
                            setDataUploadLoader(false);
                        }
                        // console.log("data", data)
                    } else {
                        enqueueSnackbar('Account not found.', { variant: 'error' });
                        handleRemoveAllFiles();
                        setSelectedUploadType('');
                        setDataUploadLoader(false);
                    }
                    // console.log("accountData", accountData)
                }
                else {
                    enqueueSnackbar('Selected file field(s) not valid.', { variant: 'error' });
                    handleRemoveAllFiles();
                    setSelectedUploadType('');
                    setDataUploadLoader(false);
                }

            }
            return null
        }

        if (selectedUploadType === "MpFee") {
            const excelQualifiedOrders = ReadExcelFile(e, 0, 'Orders');
            const isValidQualifiedOrders = flipkartExcelValidation(excelQualifiedOrders[1] || [], 'Qualified Orders');
            if (!isValidQualifiedOrders) {
                enqueueSnackbar('Selected file field(s) not valid.', { variant: 'error' });
                setDataUploadLoader(false);
                handleRemoveAllFiles();
                return null;
            }
            excelQualifiedOrders.forEach((item, index) => {
                if (index > 2) {
                    dataForUpload.push([
                        item[8] || 'NA',
                        item[9] || 'NA',
                        item[25] || 'NA',
                    ]);
                }
            })
            if (dataForUpload.length !== 0) {
                await uploadFlipkartExcelFileData(
                    selectedAccountForExcelUpload.Email,
                    selectedAccountForExcelUpload.value,
                    removeDuplicates(dataForUpload),
                    `${apiType}MPFeeUpdate`,
                    type,
                    files
                );
            } else {
                setDataUploadLoader(false);
                enqueueSnackbar('There is no data in file.', { variant: 'error' });
                handleRemoveAllFiles();
                return null;
            }
        }

        else {
            if (type === 'Order') {
                const excelActualData = ReadExcelFile(e, 0, 'Orders');
                const isValid = flipkartExcelValidation(excelActualData[0] || [], 'Order');
                if (!isValid) {
                    enqueueSnackbar('Selected file field(s) not valid.', { variant: 'error' });
                    setDataUploadLoader(false);
                    handleRemoveAllFiles();
                    return null;
                }
                excelActualData.forEach((item, index) => {
                    if (index >= 1) {
                        dataForUpload.push([
                            item[0] ? item[0].split('OI:')[1] : 'NA',
                            item[4] ? item[4] : 'NA',
                            item[6],
                            item[7] ? item[7].split('SKU:')[1].split('"""')[0] : 'NA',
                            item[10] ? Number(item[10]) : 'NA',
                            item[13] ? item[13].split('-')[0] : item[13],
                            item[14] ? item[14].split('DTr:')[1] : 'NA',
                            item[20] ? item[4] : 'NA',
                            item[21] ? item[21].split('R:')[1] : 'NA',
                            item[27] ? item[27] : 'NA',
                        ]);
                    }
                });
                if (dataForUpload.length !== 0) {
                    await uploadFlipkartExcelFileData(
                        selectedAccountForExcelUpload.Email,
                        selectedAccountForExcelUpload.value,
                        dataForUpload,
                        apiType,
                        type,
                        files
                    );
                } else {
                    setDataUploadLoader(false);
                    enqueueSnackbar('There is no data in file.', { variant: 'error' });
                    handleRemoveAllFiles();
                    return null;
                }
            }
        }

        if (type === 'Payment' && selectedUploadType !== 'adCost') {
            let dataForUploadPayment = [];
            let dataForUploadMpFeePayment = [];
            const dataForUploadMpFee = [];
            const supplierIds = [];
            const tpEmails = [];

            allAccountsData.forEach((value) => {
                if (value.AcType === 'flipkart') {
                    supplierIds.push(value.value);
                    tpEmails.push(value.Email);
                }
            });

            const PaymentExcelActualData = ReadExcelFile(e, 0, 'Orders');
            const MPFeeRebateExcelActualData = ReadExcelFile(e, 0, 'MP Fee Rebate');

            if (PaymentExcelActualData[1] === undefined || MPFeeRebateExcelActualData[1] === undefined) {
                setDataUploadLoader(false);
                handleRemoveAllFiles();
                enqueueSnackbar('Please Upload Valid File.', { variant: 'error' });
                return;
            }
            const isValidForPayment = flipkartExcelValidation(PaymentExcelActualData[1], 'fpPayment');

            if (!isValidForPayment) {
                setDataUploadLoader(false);
                enqueueSnackbar('Selected file field(s) not valid payment file.', { variant: 'error' });
                handleRemoveAllFiles();
                return null;
            }

            const subOrderNoIndex = PaymentExcelActualData[1].findIndex((x) => x === 'Order item ID');
            const finalSettlementAmountIndex = PaymentExcelActualData[1].findIndex((x) => x === "Bank Settlement Value (Rs.) \n= SUM(J:R)");
            const marketPlaceChargeIndex = PaymentExcelActualData[1].findIndex((x) => x === "Marketplace Fee (Rs.)\n= SUM (V:AI)");
            const paymentDateIndex = PaymentExcelActualData[1].findIndex((x) => x === " Payment Date");
            const shippingFeeIndex = PaymentExcelActualData[1].findIndex((x) => x === 'Shipping Fee (Rs.)');
            const reverseShippingFeeIndex = PaymentExcelActualData[1].findIndex((x) => x === 'Reverse Shipping Fee (Rs.)');
            const orderDateIndex = PaymentExcelActualData[1].findIndex((x) => x === 'Order Date');
            const sellerSKUIndex = PaymentExcelActualData[1].findIndex((x) => x === 'Seller SKU');
            const quantityIndex = PaymentExcelActualData[1].findIndex((x) => x === 'Quantity');
            const returnTypeIndex = PaymentExcelActualData[1].findIndex((x) => x === 'Return Type');
            const protectionFundIndex = PaymentExcelActualData[1].findIndex((x) => x === 'Protection Fund (Rs.)');
            const taxesIndex = PaymentExcelActualData[1].findIndex((x) => x === 'Taxes (Rs.)');
            const commissionIndex = PaymentExcelActualData[1].findIndex((x) => x === 'Commission (Rs.)');
            const gstOnMpFeesIndex = PaymentExcelActualData[1].findIndex((x) => x === 'GST on MP Fees (Rs.)');
            const tdsIndex = PaymentExcelActualData[1].findIndex((x) => x === 'TDS (Rs.)');

            const isValidaForMpFee = flipkartExcelValidation(MPFeeRebateExcelActualData[1], 'fpPaymentMPFEE');
            if (!isValidaForMpFee) {
                setDataUploadLoader(false);
                enqueueSnackbar('Selected file field(s) not valid Mp payment file.', { variant: 'error' });
                handleRemoveAllFiles();
                return null;
            }

            const mpSubOrderNoIndex = MPFeeRebateExcelActualData[1].findIndex((x) => x === 'Order item ID');
            const mpFinalSettlementAmountIndex = MPFeeRebateExcelActualData[1].findIndex((x) => x === "Settlement Value (Rs.)");
            const mpPaymentDateIndex = MPFeeRebateExcelActualData[1].findIndex((x) => x === " Payment Date");

            var exploredPaymentData = {};
            var exploredMpFeePaymentData = {};

            PaymentExcelActualData.forEach((item, index) => {
                if (index >= 3) {
                    if (!exploredPaymentData[item[subOrderNoIndex]]){
                        exploredPaymentData[item[subOrderNoIndex]] = {
                            subOrderNo: item[subOrderNoIndex],
                            paymentDate: item[paymentDateIndex],
                            orderDate: item[orderDateIndex],
                            sku: item[sellerSKUIndex],
                            quantity: item[quantityIndex],
                            returnType: item[returnTypeIndex],
                            finalSettlementAmount: parseFloat(item[finalSettlementAmountIndex]),
                            marketPlaceCharge: parseFloat(item[marketPlaceChargeIndex]),
                            shippingFee: parseFloat(item[shippingFeeIndex]),
                            reverseShippingFee: parseFloat(item[reverseShippingFeeIndex]),
                            compensation: parseFloat(item[protectionFundIndex]),
                            tax: parseFloat(item[taxesIndex]),
                            commission: parseFloat(item[commissionIndex]),
                            gstOnMpFees: parseFloat(item[gstOnMpFeesIndex]),
                            tds: parseFloat(item[tdsIndex]),
                        }
                    }else {
                        exploredPaymentData[item[subOrderNoIndex]]["finalSettlementAmount"] += parseFloat(item[finalSettlementAmountIndex])
                        exploredPaymentData[item[subOrderNoIndex]]["marketPlaceCharge"] += parseFloat(item[marketPlaceChargeIndex])
                        exploredPaymentData[item[subOrderNoIndex]]["shippingFee"] += parseFloat(item[shippingFeeIndex])
                        exploredPaymentData[item[subOrderNoIndex]]["reverseShippingFee"] += parseFloat(item[reverseShippingFeeIndex])
                        exploredPaymentData[item[subOrderNoIndex]]["compensation"] += parseFloat(item[protectionFundIndex])
                        exploredPaymentData[item[subOrderNoIndex]]["tax"] += parseFloat(item[taxesIndex])
                        exploredPaymentData[item[subOrderNoIndex]]["commission"] += parseFloat(item[commissionIndex])
                        exploredPaymentData[item[subOrderNoIndex]]["gstOnMpFees"] += parseFloat(item[gstOnMpFeesIndex])
                        exploredPaymentData[item[subOrderNoIndex]]["tds"] += parseFloat(item[tdsIndex])
                    }
                }
            });
            dataForUploadPayment = Object.values(exploredPaymentData);

            MPFeeRebateExcelActualData.forEach((item, index) => {
                if (index >= 3) {
                    if (!exploredMpFeePaymentData[item[mpSubOrderNoIndex]]){
                        exploredMpFeePaymentData[item[mpSubOrderNoIndex]] = {
                            subOrderNo: item[mpSubOrderNoIndex],
                            paymentDate: item[mpPaymentDateIndex],
                            finalSettlementAmount: parseFloat(item[mpFinalSettlementAmountIndex])
                        }
                    }else {
                        exploredMpFeePaymentData[item[mpSubOrderNoIndex]]["finalSettlementAmount"] += parseFloat(item[mpFinalSettlementAmountIndex])
                    }
                }
            });
            dataForUploadMpFeePayment = Object.values(exploredMpFeePaymentData);

            if (!isValidaForMpFee) {
                setDataUploadLoader(false);
                enqueueSnackbar('Selected file field(s) not valid mp-fee-file.', { variant: 'error' });
                handleRemoveAllFiles();
                return null;
            }

            MPFeeRebateExcelActualData.forEach((item, index) => {
                if (index >= 3) {
                    dataForUploadMpFee.push([item[2], Number(item[3]), item[7]]);
                }
            });

            if (dataForUploadPayment.length === 0) {
                setDataUploadLoader(false);
                enqueueSnackbar('Selected file has no data for Payment.', { variant: 'error' });
                handleRemoveAllFiles();
                return null;
            }

            if (selectedAccountForExcelUpload) {
                let file = await handleFlipkartExportExcel(dataForUploadPayment, dataForUploadMpFeePayment);
                enqueueSnackbar(`File Created Successfully`, { variant: 'success' });
                if (file){
                    const data = await uploadSyncFileS3(file, user.email, selectedAccountForExcelUpload.Email
                      , selectedAccountForExcelUpload.value
                      , selectedAccountForExcelUpload.Name,
                      "PaidPayment", file.name, "flipkart", enqueueSnackbar)
                    if (data) {
                        handleRemoveAllFiles();
                        setSelectedUploadType('');
                        setDataUploadLoader(false);
                    }
                }else {
                    return null
                }

            } else {
                setDataUploadLoader(false);
                handleRemoveAllFiles();
                enqueueSnackbar('Please Select Account.', { variant: 'error' });
            }
        }

        else if (type === 'Payment' && selectedUploadType === 'adCost') {
            const adCostExcelActualData = ReadExcelFile(e, 0)
            let adsCostDataToUpload = [];

            const campaignIDIndex = adCostExcelActualData[0].findIndex((x) => x === 'Campaign ID');
            const campaignStatusIndex = adCostExcelActualData[0].findIndex((x) => x === 'campaign_status');
            const deductionDateIndex = adCostExcelActualData[0].findIndex((x) => x === 'Duration');
            const endDateIndex = adCostExcelActualData[0].findIndex((x) => x === 'End Date');
            const adSpendsIndex = adCostExcelActualData[0].findIndex((x) => x === 'Ad Spends');

            if (adCostExcelActualData.length <= 0) {
                setDataUploadLoader(false);
                enqueueSnackbar('Selected file is empaty', { variant: 'error' });
                handleRemoveAllFiles();
                return null;
            }

            var exploredAdsCostData = {};

            adCostExcelActualData.forEach((item, index) => {
                if (index >= 1) {
                    if (!exploredAdsCostData[item[campaignIDIndex]]){
                        exploredAdsCostData[item[campaignIDIndex]] = {
                            campaignID: item[campaignIDIndex],
                            campaignStatus: item[campaignStatusIndex],
                            deductionDate: item[deductionDateIndex],
                            endDate : item[endDateIndex] ? item[endDateIndex] : "NA",
                            adSpends: item[adSpendsIndex]
                        }
                    }else {
                        // exploredAdsCostData[item[campaignIDIndex]]["adSpends"] += parseFloat(item[adSpends])
                    }
                }
            });

            adsCostDataToUpload = Object.values(exploredAdsCostData);

            if (adsCostDataToUpload.length > 0)  {
                let file = await handleFlipkartExportAdscostExcel(adsCostDataToUpload);
                enqueueSnackbar(`File Created Successfully`, { variant: 'success' });
                if (file){
                    const data = await uploadSyncFileS3(
                      file, user.email, selectedAccountForExcelUpload.Email,
                      selectedAccountForExcelUpload.value, selectedAccountForExcelUpload.Name,
                      "AdsCost", file.name, "flipkart", enqueueSnackbar
                    )

                    if (data) {
                        enqueueSnackbar(`File Uploaded Successfully`, { variant: 'success' });
                        handleRemoveAllFilesFlipkart();
                        handleRemoveAllFiles();
                        setSelectedUploadType('');
                        setDataUploadLoader(false);
                    }
                }else {
                    return null
                }
            }
    };
    }

    const uploadExcelFileMpFeeData = async (
        tpEmailId,
        supplierId,
        dataForUpload,
        apiType,
        token = null,
        cName = null
    ) => {
        const items = dataForUpload.slice(0, 100);
        dataForUpload.splice(0, 100);
        setDataUploadLoader(true);
        const body = {
            username: user?.email ? user.email : localUser,
            tpEmailId,
            subAccountId: supplierId,
            syncDataType: apiType,
            dataToSave: items,
        };
        if (token) body.token = token;
        if (cName) body.cName = cName;

        const response = await CreatePostAPICall(enqueueSnackbar, FlipkartSaveExcelData, body);
        if (response) {
            if (dataForUpload.length > 0) {
                return uploadExcelFileMpFeeData(
                    tpEmailId,
                    supplierId,
                    dataForUpload,
                    apiType,
                    response.data.token,
                    response.data.cName
                );
            } else {
                return true;
            }
        } else {
            handleRemoveAllFiles();
            setDataUploadLoader(false);
            setSelectedUploadType('');
            return false;
        }
    };

    // on load file
    const handleOnUploadFlipkart = (type, files) => {

        setDataUploadLoader(true);
        const file = files[0];
        if (file) {
            const reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onload = (e) => {
                onLoadFlipkartFileUploadDetail(e, type, files);
            };
        } else {
            handleRemoveAllFilesFlipkart();
        }
    };

    // dropping file
    const handleDropMultiFileFlipkart = useCallback(
        (acceptedFiles) => {
            const newFiles = acceptedFiles.map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                })
            );
            setFlipkartFiles([...newFiles]);
        },
        [flipkartFiles]
    );

    // remove all file
    const handleRemoveAllFilesFlipkart = () => {
        setFlipkartFiles([]);
    };

    // remove file
    const handleRemoveFileFlipkart = (inputFile) => {
        const filtered = flipkartFiles.filter((file) => file !== inputFile);
        setFlipkartFiles(filtered);
    };

    // format data and post data and api call
    const formatDataAndPostDataOfFlipkartFinancialReport = async (dataToPost, tpEmailId, subAccountId, files, token) => {
        setDataUploadLoader(true);
        const items = dataToPost.slice(0, 100);
        const body = {
            username: user?.email ? user.email : localUser,
            tpEmailId,
            subAccountId,
            syncDataType: 'excelFinancialReportData',
            dataToSave: items,
        };
        if (token) body.token = token;

        const response = await CreatePostAPICall(enqueueSnackbar, FlipkartSaveExcelData, body);

        if (response) {
            if (dataToPost.length > 0) {
                dataToPost.splice(0, 100);
                await formatDataAndPostDataOfFlipkartFinancialReport(
                    dataToPost,
                    tpEmailId,
                    subAccountId,
                    files,
                    response.data.token
                );
            } else {
                const tempFiles = files.slice(1);
                if (tempFiles.length === 0) {
                    setDataUploadLoader(false);
                    enqueueSnackbar('Saved Successfully.');
                    handleRemoveAllFilesFlipkart();
                } else {
                    handleOnUploadFlipkartFinancialReport(tempFiles);
                }
            }
        } else {
            setDataUploadLoader(false);
            handleRemoveAllFilesFlipkart();
        }
    };

    // financial report api call
    const uploadFileDataForFlipkartFinancialReport = async (e, files) => {
        const dataToPost = [];
        const splittedFileName = files[0].name.split(`_`);
        const sellerId = splittedFileName[0];
        const excelActualData = ReadExcelFile(e, 0, 'Orders');

        const isValid = flipkartFinancialExcelValidation(excelActualData[1]);

        if (!isValid) {
            setDataUploadLoader(false);
            enqueueSnackbar('Selected file field(s) not valid.', { variant: 'error' });
            handleRemoveAllFilesFlipkart();
            return null;
        }

        if (excelActualData[1] === undefined) {
            setDataUploadLoader(false);
            enqueueSnackbar('Selected file is Empty.', { variant: 'error' });
            handleRemoveAllFilesFlipkart();
            return null;
        }

        if (sellerId === undefined) {
            setDataUploadLoader(false);
            handleRemoveAllFilesFlipkart();
            enqueueSnackbar('Please Select Valid Seller File.', { variant: 'error' });
            return null;
        }

        //  dateIndex="Settlement Date","Date","Payment Date";
        //  settlementValueIndex="Bank Settlement Value (Rs.)","Settlement Value (Rs.)";
        //  orderItemIDIndex="Order item ID";
        //  marketplaceFeeIndex="Marketplace Fee (Rs.)";
        //  shippingFeeIndex="Shipping Fee (Rs.)";
        //  reverseShippingFeeIndex="Reverse Shipping Fee (Rs.)";
        //  orderDateIndex="Order Date";
        //  sellerSKUIndex="Seller SKU";
        //  quantityIndex="Quantity";
        //  returnTypeIndex="Return Type";

        const dateIndex = excelActualData[1].findIndex((x) => x === ' Settlement Date' || x === 'Payment Date');
        const settlementValueIndex = excelActualData[1].findIndex(
            (x) =>
                x === 'Bank Settlement Value (Rs.)' ||
                x === 'Settlement Value (Rs.)' ||
                x === 'Settlement Value (Rs.) \n= SUM(G:P)' ||
                x === 'Bank Settlement Value (Rs.) \n= SUM(J:R)' ||
                x === 'Bank Settlement Value (Rs.) \n= SUM(J:Q)'
        );
        const orderItemIDIndex = excelActualData[1].findIndex((x) => x === 'Order item ID');
        const marketplaceFeeIndex = excelActualData[1].findIndex(
            (x) =>
                x === 'Marketplace Fee (Rs.)' ||
                x === 'Marketplace Fee (Rs.)\n= SUM (U:AL)' ||
                x === 'Marketplace Fee (Rs.)\n= SUM (V:AI)' ||
                x === 'Marketplace Fee (Rs.)\n= SUM (U:AH)'
        );
        const shippingFeeIndex = excelActualData[1].findIndex((x) => x === 'Shipping Fee (Rs.)');
        const reverseShippingFeeIndex = excelActualData[1].findIndex((x) => x === 'Reverse Shipping Fee (Rs.)');
        const orderDateIndex = excelActualData[1].findIndex((x) => x === 'Order Date');
        const sellerSKUIndex = excelActualData[1].findIndex((x) => x === 'Seller SKU');
        const quantityIndex = excelActualData[1].findIndex((x) => x === 'Quantity');
        const returnTypeIndex = excelActualData[1].findIndex((x) => x === 'Return Type');

        excelActualData.forEach((item, index) => {
            if (index >= 3) {
                dataToPost.push([
                    item[dateIndex] ? item[dateIndex] : 'NA',
                    Number(item[settlementValueIndex]),
                    item[orderItemIDIndex],
                    Number(item[marketplaceFeeIndex]),
                    Number(item[shippingFeeIndex]),
                    Number(item[reverseShippingFeeIndex]),
                    item[orderDateIndex],
                    item[sellerSKUIndex],
                    Number(item[quantityIndex]),
                    item[returnTypeIndex],
                ]);
            }
        });

        // To Find SubAccountId And TpEmailId To Save Excel Payload
        const accountDetails = await getUserAccountDetails();
        const supplierIdList = accountDetails.supplierIds;
        const tpEmailIdList = accountDetails.tpEmailids;
        const sellerIdToList = accountDetails.sellerIds;
        const tpEmailIds = [];
        const subAccountIds = [];
        for (let i1 = 0; i1 < sellerIdToList.length; i1++) {
            if (sellerId.includes(sellerIdToList[i1])) {
                subAccountIds.push(supplierIdList[i1]);
                tpEmailIds.push(tpEmailIdList[i1]);
                break;
            }
        }

        await formatDataAndPostDataOfFlipkartFinancialReport(dataToPost, tpEmailIds[0], subAccountIds[0], files);
        return null;
    };

    // handle on load financial report
    const handleOnUploadFlipkartFinancialReport = (files) => {
        setDataUploadLoader(true);
        const file = files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                uploadFileDataForFlipkartFinancialReport(e, files);
            };
            reader.readAsArrayBuffer(file);
        } else {
            handleRemoveAllFilesFlipkart();
        }
    };

    // choose which type of payment you want to upload
    const paymentUploadList = [
        { value: 'Payment', name: 'Payment' },
        { value: 'adCost', name: 'AdsCost' },
    ];

    // choose which type of payment you want to upload
    const orderUploadList = [
        { value: 'MpFee', name: 'MpFee' },
        { value: 'Order', name: 'Order' },
        { value: 'FBF', name: 'FBF' },
    ];

    // Excel Upload Container for Flipkart,
    const OrderUploadContainer = () =>
        dataUploadLoader ? (
            <div style={{ width: '100%', textAlign: 'center', padding: '100px' }}>
                <CircularProgress sx={{ color: SystemColor.originColor }} size={24} color="inherit" />
                <br />
                Please Wait While Uploading Orders Data...
            </div>
        ) : (
            <>
                {isAdmin ? (
                    <>
                        <FormControl required sx={{ width: '100%' }}>
                            <InputLabel required>Select Upload Type</InputLabel>
                            <Select
                                fullWidth
                                value={selectedUploadType}
                                placeholder={'Select Upload Type'}
                                onChange={(event) => {
                                    setSelectedUploadType(event.target.value);
                                }}
                                input={<OutlinedInput label="Select Upload Type" />}
                            >
                                {orderUploadList.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </Select>
                            <br />
                        </FormControl>
                    </>
                ) : null}
                <UploadMultiFile
                    multiple
                    acceptTypes={'.csv,.xls,.xlsx'}
                    files={fileType === 'Order' ? files : []}
                    title={'Drop Or Select Order Excel'}
                    onDrop={(e) => {
                        handleDropMultiFile(e, 'Order');
                    }}
                    onRemove={handleRemoveFile}
                    onRemoveAll={handleRemoveAllFiles}
                    onUpload={() => {
                        handleOnUploadFlipkart('Order', files);
                    }}
                />

            </>
        );

    // Payment Excel Upload Container for Flipkart,
    const PaymentUploadContainerFlipkart = () =>
        dataUploadLoader ? (
            <div style={{ width: '100%', textAlign: 'center', padding: '100px' }}>
                <CircularProgress sx={{ color: SystemColor.originColor }} size={24} color="inherit" />
                <br />
                Please Wait While Uploading Payment Data...
            </div>
        ) : (
            <>
                {isAdmin ? (
                    <>
                        <FormControl required sx={{ width: '100%' }}>
                            <InputLabel required>Select Upload Type</InputLabel>
                            <Select
                                fullWidth
                                value={selectedUploadType}
                                placeholder={'Select Upload Type'}
                                onChange={(event) => {
                                    setSelectedUploadType(event.target.value);
                                }}
                                input={<OutlinedInput label="Select Upload Type" />}
                            >
                                {paymentUploadList.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </Select>
                            <br />
                        </FormControl>
                    </>
                ) : null}
                <UploadMultiFile
                    multiple
                    acceptTypes={'.xlsx, .xls, .csv'}
                    files={fileType === 'Payment' ? files : []}
                    title={'Drop Or Select Payment Excel'}
                    onDrop={(e) => {
                        handleDropMultiFile(e, 'Payment');
                    }}
                    onRemove={handleRemoveFile}
                    onRemoveAll={handleRemoveAllFiles}
                    onUpload={() => {
                        handleOnUploadFlipkart('Payment', files);
                    }}
                />
            </>
        );

    // Financial Report Excel Upload Container for Flipkart,
    const FinancialReportUploadContainerFlipkart = () =>
        dataUploadLoader ? (
            <div style={{ width: '100%', textAlign: 'center', padding: '100px' }}>
                <CircularProgress sx={{ color: SystemColor.originColor }} size={24} color="inherit" />
                <br />
                Please Wait While Uploading Financial Report Data...
            </div>
        ) : (
            <UploadMultiFile
                multiple
                acceptTypes={'.xlsx, .xls, .csv'}
                files={flipkartFiles}
                title={'Drop Or Select Financial Report Excel'}
                onDrop={handleDropMultiFileFlipkart}
                onRemove={handleRemoveFileFlipkart}
                onRemoveAll={handleRemoveAllFilesFlipkart}
                onUpload={() => handleOnUploadFlipkartFinancialReport(flipkartFiles)}
            />
        );

    //  Flipkart Tabs
    const currentUploadTabs = [
        {
            value: 'Order',
            icon: <Iconify icon={'tabler:truck-delivery'} width={25} height={25} />,
            component: <OrderUploadContainer />,
        },
        {
            value: 'Payment',
            icon: <Iconify icon={'mdi:recurring-payment'} width={25} height={25} />,
            component: <PaymentUploadContainerFlipkart />,
        },
        {
            value: 'Financial Report',
            icon: <Iconify icon={'mdi:finance'} width={25} height={25} />,
            component: <FinancialReportUploadContainerFlipkart />,
        },
    ];

    // handle drop multi file
    const handleDropMultiFile = useCallback(
        (acceptedFiles, fileType) => {
            const newFiles = acceptedFiles.map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                })
            );
            setFiles([...newFiles]);
            setFileType(fileType);
        },
        [files]
    );

    // handle remove file
    const handleRemoveFile = (inputFile) => {
        const filtered = files.filter((file) => file !== inputFile);
        setFiles(filtered);
    };

    // handle remove all files
    const handleRemoveAllFiles = () => {
        setFiles([]);
    };

    // for get date
    const getdate = async (value, dateString) => {
        const flipkartDate = {
            start: dateString[0],
            end: dateString[1],
        };
        setFlipkartDateToBeFetched(flipkartDate);
    };

    // api sync
    const fetchApiFlipkartOrders = async (SyncDataType) => {
        if (tpEmail.length === 0) {
            enqueueSnackbar('Please Select Account', { variant: 'error' });
            return;
        }
        if (SyncDataType === 'RTS') {
            SetIsReadyToShipLoading(true);
        }

        if (SyncDataType === 'RTN') {
            SetIsReturnLoading(true);
        }
        if (SyncDataType === 'CNL') {
            SetIsCancelLoading(true);
        }
        if (SyncDataType === 'allInventory') {
            SetIsInventoryLoading(true);
        }
        if (SyncDataType === 'SHIPPED') {
            SetIsShippedLoading(true);
        }

        const body = {
            username: user?.email ? user.email : localUser,
            tpEmailIds: tpEmail,
            subAccountIds: supplierId,
            cNames: companyName,
            syncDataType: SyncDataType,
            acType: 'flipkart',
            isMaskingEnabledList: maskingList,
        };
        if (SyncDataType === 'GL') {
            setGenerateLabelLoading(true);
            body.syncDataType = 'generateLabel';
        }
        if (SyncDataType === 'RGL') {
            setReGenerateLabelLoading(true);
            body.syncDataType = 'reGenerateLabel';
        }
        if (SyncDataType === 'MRTD') {
            setmarkRTDLoading(true);
            body.syncDataType = 'markAsRtd';
        }
        if ((isAdmin && SyncDataType === 'RTN') || SyncDataType === 'SHIPPED') {
            body.start_date = flipkartDateToBeFetched.start;
            body.end_date = flipkartDateToBeFetched.end;
        }

        const response = await CreatePostAPICall(enqueueSnackbar, SyncOrders, body);
        if (response) {
            enqueueSnackbar(response.data.body.message);
            if (SyncDataType === 'RTS') {
                SetIsReadyToShipLoading(false);
            }
            if (SyncDataType === 'RTN') {
                SetIsReturnLoading(false);
            }
            if (SyncDataType === 'CNL') {
                SetIsCancelLoading(false);
            }
            if (SyncDataType === 'allInventory') {
                SetIsInventoryLoading(false);
            }
            if (SyncDataType === 'SHIPPED') {
                SetIsShippedLoading(false);
            }
            if (SyncDataType === 'GL') {
                setGenerateLabelLoading(false);
            }
            if (SyncDataType === 'RGL') {
                setReGenerateLabelLoading(false);
            }
            if (SyncDataType === 'MRTD') {
                setmarkRTDLoading(false);
            }
        }
    };

    return (
        <Container maxWidth={themeStretch ? false : 'xlg'}>
            <Grid sx={{ p: 2 }} container spacing={3}>
                <Grid item xs={12} md={3}>
                    <Typography variant="h6">
                        <div style={{ display: 'flex' }}>
                            <Iconify
                                icon={'ant-design:cloud-sync-outlined'}
                                width={25}
                                height={25}
                                style={{ color: '#7635DC', marginRight: '5px' }}
                            />{' '}
                            Api Sync
                        </div>
                    </Typography>

                    <Divider sx={{ mt: 1, mb: 3, width: '110px', backgroundColor: '#7635DC', height: '2px' }} />
                    <div style={{ Width: '100%' }}>
                        <AccountSelector allData={allAccountsData} onChangeHandler={handleSelectedAccount} />
                    </div>
                    <br />
                    <Box
                        sx={{
                            display: 'grid',
                            rowGap: 2,
                        }}
                    >
                        {isAdmin && (
                            <>
                                <RangePicker
                                    className={'createDateRangePicker'}
                                    pupClassName={'createDateRangePicker'}
                                    format={'DD/MM/YY'}
                                    onChange={(value, event) => getdate(value, event)}
                                    {...(!isLight && {
                                        className: 'date-picker-dark',
                                    })}
                                />
                                <Button
                                    variant="outlined"
                                    id={`${platForm}SyncShipped`}
                                    startIcon={<Iconify icon={'ion:return-up-forward-outline'} size="medium" />}
                                    onClick={() => {
                                        fetchApiFlipkartOrders('SHIPPED');
                                    }}
                                    disabled={isShippedLoading}
                                >
                                    Sync Shipped Orders
                                </Button>
                            </>
                        )}
                        {/*<Button*/}
                        {/*    variant="outlined"*/}
                        {/*    startIcon={<Iconify icon={'ic:round-refresh'} size="medium"/>}*/}
                        {/*    onClick={() => {*/}
                        {/*        fetchApiFlipkartOrders('GL');*/}
                        {/*    }}*/}
                        {/*    disabled={generateLabelLoading}*/}
                        {/*>*/}
                        {/*    Generate Label*/}
                        {/*</Button>*/}
                        {/*<Button*/}
                        {/*    variant="outlined"*/}
                        {/*    startIcon={<Iconify icon={'humbleicons:refresh'} size="medium"/>}*/}
                        {/*    onClick={() => {*/}
                        {/*        fetchApiFlipkartOrders('RGL ');*/}
                        {/*    }}*/}
                        {/*    disabled={reGenerateLabelLoading}*/}
                        {/*>*/}
                        {/*    Re-Generate Label*/}
                        {/*</Button><Button*/}
                        {/*    variant="outlined"*/}
                        {/*    startIcon={<Iconify icon={'mdi:checkbox-multiple-marked-outline'} size="medium"/>}*/}
                        {/*    onClick={() => {*/}
                        {/*        fetchApiFlipkartOrders('MRTD');*/}
                        {/*    }}*/}
                        {/*    disabled={markRTDLoading}*/}
                        {/*>*/}
                        {/*    Mark As RTD*/}
                        {/*</Button>*/}
                        <Button
                            variant="outlined"
                            id={`${platForm}SyncRts`}
                            startIcon={<Iconify icon={'ion:return-up-forward-outline'} size="medium" />}
                            onClick={() => {
                                if (!isDesktopEnvironment) {
                                    fetchApiFlipkartOrders('RTS');
                                }
                            }}
                            disabled={isReadyToShipLoading}
                        >
                            Sync Ready To Ship Orders
                        </Button>
                        <Button
                            variant="outlined"
                            id={`${platForm}SyncRtn`}
                            startIcon={<Iconify icon={'ion:return-down-back-outline'} size="medium" />}
                            onClick={() => {
                                if (!isDesktopEnvironment) {
                                    fetchApiFlipkartOrders('RTN');
                                }
                            }}
                            disabled={isReturnLoading}
                        >
                            Sync Return Orders
                        </Button>

                        <Button
                            variant="outlined"
                            id={`${platForm}SyncCnl`}
                            startIcon={<Iconify icon={'iconoir:cancel'} size="medium" />}
                            onClick={() => {
                                if (!isDesktopEnvironment) {
                                    fetchApiFlipkartOrders('CNL');
                                }
                            }}
                            disabled={isCancelLoading}
                        >
                            Sync Cancel Orders
                        </Button>
                        {isAdmin && (
                            <Button
                                variant="outlined"
                                id={`${platForm}SyncInventory`}
                                startIcon={<Iconify icon={'ant-design:stock-outlined'} size="medium" />}
                                onClick={() => {
                                    if (!isDesktopEnvironment) {
                                        fetchApiFlipkartOrders('allInventory');
                                    }
                                }}
                                disabled={isInventoryLoading}
                            >
                                Sync Inventory
                            </Button>
                        )}
                    </Box>

                    {/*
          <Divider
            orientation="horizontal"
            sx={{ marginBottom: '15px', marginTop: '15px', borderStyle: 'dashed', borderWidth: '0.5px' }}
          />

          <Typography variant="h6">
            <div style={{ display: 'flex' }}>
              <Iconify
                icon={'ant-design:cloud-sync-outlined'}
                width={25}
                height={25}
                style={{ color: '#7635DC', marginRight: '5px' }}
              />{' '}
              Auto Sync
            </div>
          </Typography>

          <Divider sx={{ mt: 1, mb: 3, width: '120px', backgroundColor: '#7635DC', height: '2px' }} />
          */}
                </Grid>

                <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    sx={{
                        marginLeft: '26px',
                        borderStyle: 'dashed',
                        borderRightWidth: 'initial',
                        marginBottom: '-10px',
                        marginTop: '20px',
                    }}
                />

                <Grid item xs={12} md={8.5}>
                    <Typography variant="h6">
                        <div style={{ display: 'flex' }}>
                            <Iconify
                                icon={'ri:file-excel-2-line'}
                                width={25}
                                height={25}
                                style={{ color: 'green', marginRight: '5px' }}
                            />
                            Upload Excel
                        </div>
                    </Typography>
                    <Divider sx={{ mt: 1, mb: 3, width: '143px', backgroundColor: '#7635DC', height: '2px' }} />
                    <Grid item xs={12} sm={12} md={12} sx={{ marginTop: '-1.3%' }}>
                        <Tabs
                            allowScrollButtonsMobile
                            variant="scrollable"
                            scrollButtons="auto"
                            value={currentUploadTab}
                            onChange={onChangeUploadTab}
                        >
                            {currentUploadTabs.map((tab) => (
                                <Tab disableRipple key={tab.value} label={capitalCase(tab.value)} icon={tab.icon}
                                    value={tab.value} />
                            ))}
                        </Tabs>
                        <Box sx={{ mb: 2 }} />
                        {currentUploadTab !== 'Financial Report' ? (
                            <>
                                {!(selectedUploadType === "FBF" && currentUploadTab === 'Order') && <Autocomplete
                                    id="flipkart-excel-upload-selector"
                                    onChange={(event, value) => {
                                        setSelectedAccountForExcelUpload(value);
                                    }}
                                    value={selectedAccountForExcelUpload}
                                    options={flipkartAccountListOptions.map((option) => option)}
                                    renderInput={(params) => <TextField {...params} label="Select Flipkart Account*" />}
                                />}
                                <Box sx={{ mb: 2 }} />
                            </>
                        ) : null}
                        {currentUploadTabs.map((tab) => {
                            const isMatched = tab.value === currentUploadTab;
                            return isMatched && <Box key={tab.value}>{tab.component}</Box>;
                        })}
                    </Grid>
                </Grid>
            </Grid>
            <Input id="selectedAccountsData" value={JSON.stringify(selectedAccountsWithOutAllOptions)} type="hidden" />
        </Container>
    );
}
