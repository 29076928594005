/* eslint-disable */
import { Box, Divider, Tab, Tabs } from '@mui/material';
import { capitalCase } from 'change-case';
import React from 'react';
import AdminBillManagement from "./AdminBillManagement";
import useTabs from "../../../../hooks/useTabs";
import TodayTransaction from "./TodayTransaction";
import {isSuperAdminEnvironment} from "../../../../utils/utilities";

const AdminManageTab = (props) => {


    const { currentUploadTab, onChangeUploadTab } = useTabs('Manage Bills');

    const uploadTabs = [
        {
            value: 'Manage Bills',
        },
    ];

    if (isSuperAdminEnvironment){
        uploadTabs.splice(1, 0, {
            value: 'Today Transactions'
        });
    }

    return (
        <>
            <Divider sx={{ p: 1 }} />

            <Tabs
                allowScrollButtonsMobile
                variant="scrollable"
                scrollButtons="auto"
                value={currentUploadTab}
                onChange={onChangeUploadTab}
            >
                {uploadTabs.map((tab) => (
                    <Tab disableRipple key={tab.value} label={capitalCase(tab.value)} icon={tab.icon} value={tab.value} />
                ))}
            </Tabs>

            <Box sx={{ mb: 5 }} />

            {currentUploadTab === 'Manage Bills' && <AdminBillManagement />}
            {currentUploadTab === 'Today Transactions' && <TodayTransaction />}
        </>
    );
};
export default AdminManageTab;
