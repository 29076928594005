import React from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import DashboardLayout from '../layouts/dashboard';
import AmazonClaimIssue from '../pages/claimIssue/amazon/AmazonClaimIssue';
import JiomartClaimIssue from '../pages/claimIssue/jiomart/JiomartClaimIssue';
import MyntraClaimIssue from '../pages/claimIssue/myntra/MyntraClaimIssue';
import ShopdeckClaimIssue from '../pages/claimIssue/shopdeck/ShopdeckClaimIssue';
import SnapdealClaimIssue from '../pages/claimIssue/snapdeal/SnapdealClaimIssue';
import PrivateRoutes from './PrivateRoutes';
import ComingSoon from '../pages/ComingSoon';
import Maintenance from '../pages/Maintenance';
import Page404 from '../pages/Page404';
import Page500 from '../pages/Page500';
import Admin from '../pages/admin/Admin';
import Login from '../pages/auth/Login';
import Register from '../pages/auth/Register';
import ResetPassword from '../pages/auth/ResetPassword';
import VerifyCode from '../pages/auth/VerifyCode';
import FlipkartClaimIssue from '../pages/claimIssue/flipkart/FlipkartClaimIssue';
import GlowroadClaimIssue from '../pages/claimIssue/glowroad/GlowroadClaimIssue';
import MeeshoClaimIssue from '../pages/claimIssue/meesho/MeeshoClaimIssue';
import ReportedClaimIssue from '../pages/claimIssue/reported/ReportedClaimIssue';
import HomePage from '../pages/home/HomePage';
import MonthlyReports from '../pages/monthlyReports/MonthlyReports';
import MyLinkedAccounts from '../pages/myLinkedAccounts/MyLinkedAccounts';
import ExploreSkus from '../pages/myProducts/exploreSkus/ExploreSkus';
import MasterSkus from '../pages/myProducts/masterSkus/MasterSkus';
import OrderAnalysis from '../pages/orderAnalysis/OrderAnalysis';
import ScanOrders from '../pages/scanOrders/ScanOrders';
import UserAccount from '../pages/settings/UserAccount';
import SyncOrders from '../pages/syncOrders/SyncOrders';
import TaskManagement from '../pages/taskManagement/TaskManagement';
import Updates from '../pages/updates/Updates';
import urlConstants from './urlConstants';
import Unauthorization from '../pages/Unauthorized';
import CustomerReview from '../pages/utilities/customerReview/CustomerReview';
import AutoTicket from "../pages/claimIssue/autoTicket/AutoTicket";
import MeeshoUtilities from "../pages/utilities/Components/MeeshoUtilities";
import GlowroadUtilities from "../pages/utilities/Components/GlowroadUtilities";
import FlipkartUtilities from "../pages/utilities/Components/FlipkartUtilities";
import CityMallUtilities from "../pages/utilities/Components/CityMallUtilities";
import LimeRoadUtilities from "../pages/utilities/Components/LimeRoadUtilities";
import SnapDealUtilities from "../pages/utilities/Components/SnapDealUtilities";
import FlipkartRewardsReco from "../pages/flipkartRewardReco/FlipkartRewardReco";
import LimeRoadClaimIssue from "../pages/claimIssue/limeroad/LimeRoadClaimIssue";

const RouterUrl = () =>
    (
        <Routes>
            <Route element={<LogoOnlyLayout/>}>
                <Route path={urlConstants.ROOT_AUTH} element={<Navigate to={urlConstants.LOGIN_PAGE}/>}/>
                <Route path={urlConstants.LOGIN_PAGE} element={<Login/>}/>
                <Route path={urlConstants.REGISTER_PAGE} element={<Register/>}/>
                <Route path={urlConstants.RESET_PASSWORD_PAGE} element={<ResetPassword/>}/>
                <Route path={urlConstants.VERIFY_PAGE} element={<VerifyCode/>}/>

                {/* extra page */}
                <Route path={urlConstants.COMINGSOON_PAGE} element={<ComingSoon/>}/>
                <Route path={urlConstants.MAINTENANCE_PAGE} element={<Maintenance/>}/>
                <Route path={urlConstants.PAGE_404_PAGE} element={<Page404/>}/>
                <Route path={urlConstants.PAGE_500_PAGE} element={<Page500/>}/>

                <Route path={urlConstants.UNAUTHORIZED_PAGE} element={<Unauthorization/>}/>
            </Route>

            <Route element={<DashboardLayout/>}>
                <Route element={<PrivateRoutes/>}>
                    <Route path="/" element={<Navigate to={urlConstants.HOME_PAGE}/>}/>
                    <Route path={urlConstants.ROOT_OMS} element={<Navigate to={urlConstants.HOME_PAGE}/>}/>
                    <Route path={urlConstants.HOME_PAGE} element={<HomePage/>}/>
                    <Route
                        path={`${urlConstants.MY_LINKED_ACCOUNTS_PAGE}`}
                        element={<Navigate to={`${urlConstants.MY_LINKED_ACCOUNTS_PAGE}/meesho`}/>}
                    />
                    <Route path={`${urlConstants.MY_LINKED_ACCOUNTS_PAGE}/:myLinkedAccountTab`}
                           element={<MyLinkedAccounts/>}/>
                    <Route path={`${urlConstants.SYNC_ORDERS_PAGE}`} element={<SyncOrders/>}/>
                    <Route path={`${urlConstants.FlipkartRewardReco}/:flipkartTab`} element={<FlipkartRewardsReco/>}/>
                    <Route path={`${urlConstants.FlipkartRewardReco}`} element={<Navigate to={`${urlConstants.FlipkartRewardReco}/reward-offers`}/>}/>
                    <Route path={urlConstants.ORDER_ANALYSIS_PAGE} element={<OrderAnalysis/>}/>
                    <Route path={urlConstants.MASTER_SKUS_PAGE} element={<MasterSkus/>}/>
                    <Route path={urlConstants.AUTO_TICKET} element={<AutoTicket/>}/>
                    <Route path={urlConstants.EXPLORE_SKUS_PAGE} element={<ExploreSkus/>}/>
                    <Route path={urlConstants.MEESHO_CLAIM_ISSUE_PAGE} element={<MeeshoClaimIssue/>}/>
                    <Route path={urlConstants.FLIPKART_CLAIM_ISSUE_PAGE} element={<FlipkartClaimIssue/>}/>
                    <Route path={urlConstants.GLOWROAD_CLAIM_ISSUE_PAGE} element={<GlowroadClaimIssue/>}/>
                    <Route path={urlConstants.SHOPDECK_CLAIM_ISSUE_PAGE} element={<ShopdeckClaimIssue/>}/>
                    <Route path={urlConstants.MYNTRA_CLAIM_ISSUE_PAGE} element={<MyntraClaimIssue/>}/>
                    <Route path={urlConstants.AMAZON_CLAIM_ISSUE_PAGE} element={<AmazonClaimIssue/>}/>
                    <Route path={urlConstants.SNAPDEAL_CLAIM_ISSUE_PAGE} element={<SnapdealClaimIssue/>}/>
                    <Route path={urlConstants.LIMEROAD_CLAIM_ISSUE_PAGE} element={<LimeRoadClaimIssue/>}/>
                    <Route path={urlConstants.JIOMART_CLAIM_ISSUE_PAGE} element={<JiomartClaimIssue/>}/>
                    <Route path={urlConstants.REPORTED_CLAIM_ISSUE_PAGE} element={<ReportedClaimIssue/>}/>
                    <Route path={urlConstants.SCAN_ORDERS_PAGE} element={<ScanOrders/>}/>
                    <Route path={urlConstants.MONTHLY_REPORTS_PAGE} element={<MonthlyReports/>}/>
                    <Route path={urlConstants.MeeshoUtilities} element={<MeeshoUtilities/>}/>
                    <Route path={urlConstants.GlowroadUtilities} element={<GlowroadUtilities/>}/>
                    <Route path={urlConstants.FlipkartUtilities} element={<FlipkartUtilities/>}/>
                    <Route path={urlConstants.CITY_MALL} element={<CityMallUtilities/>}/>
                    <Route path={urlConstants.LIME_ROAD} element={<LimeRoadUtilities/>}/>
                    <Route path={urlConstants.SNAP_DEAL} element={<SnapDealUtilities/>}/>
                    <Route path={urlConstants.CUSTOMER_REVIEW_PAGE} element={<CustomerReview/>}/>
                    <Route path={urlConstants.UPDATES_PAGE} element={<Updates/>}/>
                    <Route path={urlConstants.ACCOUNT_PAGE} element={<UserAccount/>}/>
                    <Route path={'/*'} element={<Navigate to={urlConstants.PAGE_404_PAGE}/>}/>
                </Route>

                {/* admin pages */}
                <Route element={<PrivateRoutes/>}>
                    <Route path={`${urlConstants.ADMIN_PAGE}`}
                           element={<Navigate to={`${urlConstants.ADMIN_PAGE}/Admin`}/>}/>
                    <Route path={`${urlConstants.ADMIN_PAGE}/:adminTabName`} element={<Admin/>}/>
                    <Route path={urlConstants.TASK_MANAGEMENT_PAGE} element={<TaskManagement/>}/>
                </Route>
            </Route>
        </Routes>
    );


export default RouterUrl;
