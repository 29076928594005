/* eslint-disable */
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { Button, IconButton, OutlinedInput, Stack, TextField, Typography } from '@mui/material';
import {AuthenticationDetails, CognitoUser} from 'amazon-cognito-identity-js';
import InputAdornment from '@mui/material/InputAdornment';
import Iconify from '../../../components/Iconify';
import { UserPool } from '../../../contexts/AwsCognitoContext';
import ResetPassword from '../ResetPassword';
import urlConstants from '../../../routes/urlConstants';

// ----------------------------------------------------------------------

export default function VerifyCodeForm(props) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const VerifyCodeSchema = Yup.object().shape({
    code1: Yup.string().required('Code is required'),
    code2: Yup.string().required('Code is required'),
    code3: Yup.string().required('Code is required'),
    code4: Yup.string().required('Code is required'),
    code5: Yup.string().required('Code is required'),
    code6: Yup.string().required('Code is required'),
  });
  const [password, setPassword] = useState();
  const [cnfPassword, setCnfPassword] = useState();
  const [goBack, setGoBack] = useState(false);
  const [inputFieldType, setInputFieldType] = useState('text');
  const [inputFieldType2, setInputFieldType2] = useState('text');
  const [error, setError] = useState(false);

  const defaultValues = {
    code1: '',
    code2: '',
    code3: '',
    code4: '',
    code5: '',
    code6: '',
  };

  const {
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(VerifyCodeSchema),
    defaultValues,
  });

  const values = watch();

  const onSubmit = async (data) => {
    if (password !== cnfPassword) {
      setError(true);
    } else {
      setError(false);
      if (props.email === undefined) {
        const userName = localStorage.getItem('userEmailId');
        const userData = new CognitoUser({
          Username: userName,
          Pool: UserPool,
        });
        userData.confirmRegistration(`${Object.values(data).join('')}`, true, async (err) => {
          if (err) {
            enqueueSnackbar(err.message, { variant: 'error' });
          }
        });
        enqueueSnackbar('Account Verified Successfully');
        navigate(urlConstants.LOGIN_PAGE, { replace: true });
      } else {
        const userData = new CognitoUser({
          Username: props.email,
          Pool: UserPool,
        });
        userData.confirmPassword(`${Object.values(data).join('')}`, cnfPassword, {
          onFailure(err) {
            enqueueSnackbar(err.message, { variant: 'error' });
          },
          onSuccess() {

            const authDetails = new AuthenticationDetails({
              Username: props.email,
              Password: cnfPassword,
            });
            userData.authenticateUser(authDetails, {
              onSuccess: async () => {
                userData.globalSignOut({
                  onSuccess: () => {
                    enqueueSnackbar('Password Reset Successfully');
                    localStorage.removeItem('username');
                    navigate(urlConstants.LOGIN_PAGE, { replace: true });
                  },
                  onFailure: (err) => {
                    enqueueSnackbar(err.message, { variant: 'error' });
                  },
                })
              },
              onFailure: (err) => {
                enqueueSnackbar(err.message, { variant: 'error' });
              },
              newPasswordRequired: () => {
                // Handle this on login page for update password.
                enqueueSnackbar("new password required", { variant: 'error' });
              },
            });

          },
        });
      }
    }
  };

  useEffect(() => {
    document.addEventListener('paste', handlePasteClipboard);
  }, [error]);

  const handlePasteClipboard = (event) => {
    let data = event?.clipboardData?.getData('Text') || '';

    if (!isNaN(data)) {
      data = data.split('');

      [].forEach.call(document.querySelectorAll('#field-code'), (node, index) => {
        node.value = data[index];
        const fieldIndex = `code${index + 1}`;
        setValue(fieldIndex, data[index]);
      });
    }
  };

  const handleChangeWithNextField = (event, handleChange) => {
    const { maxLength, value, name } = event.target;
    const fieldIndex = name.replace('code', '');

    const fieldIntIndex = Number(fieldIndex);

    if (value.length >= maxLength) {
      if (fieldIntIndex < 6) {
        const nextfield = document.querySelector(`input[name=code${fieldIntIndex + 1}]`);

        if (nextfield !== null) {
          nextfield.focus();
        }
      }
    }
    handleChange(event);
  };

  const goPrevious = () => {
    setGoBack(true);
  };

  return (
    <>
      {goBack === true ? (
        <ResetPassword />
      ) : (
        //    Main Content
        <>
          {props.email !== undefined ? (
            <>
              <Button
                size="small"
                onClick={() => {
                  goPrevious();
                }}
                startIcon={<Iconify icon={'eva:arrow-ios-back-fill'} width={20} height={20} />}
                sx={{ mb: 3 }}
              >
                Back
              </Button>
              <Typography variant="h3" paragraph>
                Please check your email!
              </Typography>
              <Typography sx={{ color: 'text.secondary', mb: 5 }}>
                We have emailed a 6-digit confirmation code to <strong>{props.email}</strong>, please enter the code in
                below box to verify your email.
              </Typography>
            </>
          ) : null}
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack direction="row" spacing={2} justifyContent="center">
              {Object.keys(values).map((name, index) => (
                <Controller
                  key={name}
                  name={`code${index + 1}`}
                  control={control}
                  render={({ field }) => (
                    <OutlinedInput
                      {...field}
                      id="field-code"
                      autoFocus={index === 0}
                      placeholder="-"
                      onChange={(event) => handleChangeWithNextField(event, field.onChange)}
                      inputProps={{
                        maxLength: 1,
                        sx: {
                          p: 0,
                          textAlign: 'center',
                          width: { xs: 36, sm: 56 },
                          height: { xs: 36, sm: 56 },
                        },
                      }}
                    />
                  )}
                />
              ))}
            </Stack>
            {props.email !== undefined ? (
              <>
                <div style={{ paddingTop: '30px' }}>
                  <TextField
                    fullWidth
                    id="pass"
                    variant="outlined"
                    defaultValue={''}
                    onChange={(event) => setPassword(event.target.value)}
                    label="Enter New Password"
                    type={inputFieldType}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton>
                            <Iconify icon={'ri:lock-password-line'} width={24} height={24} />
                          </IconButton>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          {inputFieldType === 'password' ? (
                            <IconButton
                              onClick={() => {
                                setInputFieldType('text');
                              }}
                            >
                              <Iconify icon={'ant-design:eye-filled'} width={24} height={24} />
                            </IconButton>
                          ) : (
                            <IconButton
                              onClick={() => {
                                setInputFieldType('password');
                              }}
                            >
                              <Iconify icon={'ant-design:eye-invisible-filled'} width={24} height={24} />
                            </IconButton>
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <br />
                {error === true ? <div style={{ color: 'red' }}> Password Doesn't Match.</div> : null}
                <div style={{ paddingTop: '10px' }}>
                  <TextField
                    fullWidth
                    id="cnf-pass"
                    variant="outlined"
                    defaultValue={''}
                    onChange={(event) => setCnfPassword(event.target.value)}
                    label="Confirm New Password"
                    type={inputFieldType2}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton>
                            <Iconify icon={'ri:lock-password-line'} width={24} height={24} />
                          </IconButton>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          {inputFieldType2 === 'password' ? (
                            <IconButton
                              onClick={() => {
                                setInputFieldType2('text');
                              }}
                            >
                              <Iconify icon={'ant-design:eye-filled'} width={24} height={24} />
                            </IconButton>
                          ) : (
                            <IconButton
                              onClick={() => {
                                setInputFieldType2('password');
                              }}
                            >
                              <Iconify icon={'ant-design:eye-invisible-filled'} width={24} height={24} />
                            </IconButton>
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </>
            ) : null}
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              onChange={onSubmit}
              disabled={!isValid}
              sx={{ mt: 3 }}
            >
              Verify
            </LoadingButton>
          </form>
        </>
      )}
    </>
  );
}
