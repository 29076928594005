import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_ENV === "APP" || process.env.REACT_APP_ENV === "APP_DESKTOP"
      ? "https://api.buissmaster.com/"
      : "https://uat-api.buissmaster.com/",
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;
