/* eslint-disable */
import {capitalCase} from 'change-case';
import {
    Autocomplete,
    Box,
    Button,
    CircularProgress,
    Container,
    Divider,
    Grid,
    Input,
    Tab,
    Tabs,
    TextField,
    Typography,
} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import {DatePicker} from 'antd';
import {useSnackbar} from 'notistack';
import React, {useCallback, useEffect, useState} from 'react';
import useSettings from '../../../hooks/useSettings';
import useTabs from '../../../hooks/useTabs';
import useAuth from '../../../hooks/useAuth';
import {CreatePostAPICall, LimeroadSaveExcelData, SyncOrders} from '../../../utils/apis';
import {
    CapitalizeFirstLetter,
    getAccountsData,
    getAccountsDataByType,
    getUserAccountDetails,
    isAdmin,
    ReadExcelFile,
    setAccountSelectorData,
    SystemColor,
} from '../../../utils/utilities';
import Iconify from '../../../components/Iconify';
import {UploadMultiFile} from '../../../components/upload';
import AccountSelector from '../../../components/Common/AccountSelector';
import {AmazonValidateUploadedFile} from '../excelValidations/AmazonValidation';
import {LimeroadValidateUploadedFile} from "../excelValidations/LimeroadValidation";

export default function LimeRoadSyncOrder() {
    const platForm = 'limeroad';
    const { RangePicker } = DatePicker;
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();
    const isLight = theme.palette.mode === 'light';
    const { themeStretch } = useSettings();
    const { user } = useAuth();
    const localUser = window.localStorage.getItem('userEmailId');
    const { currentUploadTab, onChangeUploadTab } = useTabs('Payment');
    const [files, setFiles] = useState([]);
    const [fileType, setFileType] = useState('');
    const [dataUploadLoader, setDataUploadLoader] = useState(false);
    const [amazonDateToBeFetched, setAmazonDateToBeFetched] = useState({});
    const [isReadyToShipLoading, SetIsReadyToShipLoading] = useState(false);
    const [isReturnLoading, SetIsReturnLoading] = useState(false);
    const [isCancelLoading, SetIsCancelLoading] = useState(false);
    const [isInventoryLoading, SetIsInventoryLoading] = useState(false);
    const [isShippedLoading, SetIsShippedLoading] = useState(false);
    const [allAccountsData, setAllAccountsData] = useState([]);
    const [selectedAccountsWithOutAllOptions, setSelectedAccountsWithOutAllOptions] = useState([]);
    const [supplierId, setSupplierId] = useState([]);
    const [tpEmail, setTpEmail] = useState([]);
    const [companyName, setCompanyName] = useState([]);
    const [limeroadAccountListOptions, setLimeroadAccountListOptions] = useState([]);
    const [selectedAccountForExcelUpload, setSelectedAccountForExcelUpload] = useState(null);

    // for getting account data from localstorage
    useEffect(async () => {
        getAccountsDataByType(platForm).then((filteredData) => {
            setAllAccountsData(filteredData);
        });
        // from here starts account selector data for Excel upload selector
        const accountData = await getAccountsData();
        const objAcAmazon = [];
        accountData.forEach((item) => {
            if (item.AcType === 'limeroad') {
                objAcAmazon.push({
                    value: item.Account.SupplierId,
                    label: `${CapitalizeFirstLetter(item.AcType)} - ${item.Account.Email} - ${item.Account.Name}`,
                    AcType: item.AcType,
                    Email: item.Account.Email,
                    Name: item.Account.Name,
                });
            }
        });
        setLimeroadAccountListOptions(objAcAmazon);
    }, []);
    function sumOrderAmounts(inputData) {
        const orderDetails = {};
        // console.log(inputData)
        for (const orderData of inputData) {
                const orderNumber = orderData[1];
                const amount = parseFloat(orderData[2]);
                const timestamp = orderData[0];
                orderDetails[orderNumber] = {
                    timestamp,
                    orderNumber,
                    amount: (orderDetails[orderNumber]?.amount || 0) + amount,
                }
            // console.log(orderDetails[orderNumber])
        }
        return Object.values(orderDetails).map(({timestamp, orderNumber, amount}) => {
            return [timestamp, orderNumber, amount.toFixed(2)];
        })
    }
    // handle of autocomplete for platform wise showing account list
    const handleSelectedAccount = async (event, value) => {
        const response = await setAccountSelectorData(value, allAccountsData);
        setSelectedAccountsWithOutAllOptions(response[1]);
        if (event.length !== 0) {
            const selectedAccountData = await getUserAccountDetails(response[1]);
            setSupplierId(selectedAccountData.supplierIds);
            setTpEmail(selectedAccountData.tpEmailids);
            setCompanyName(selectedAccountData.cName);
        }
    };

    //  Amazon Excel
    const uploadExcelFileData = async (
        tpEmailId,
        apiType,
        dataForUpload,
        fileType,
        files,
        token = null
    ) => {
        var sumOrderAmount = sumOrderAmounts(dataForUpload)
        const items = sumOrderAmount.slice(0, 100);
        sumOrderAmount.splice(0, 100);
        setDataUploadLoader(true);

        const body = {
            syncDataType: apiType,
            username: user?.email ? user.email : localUser,
            tpEmailId,
            dataListOfLists: items,
        };

        if (token) body.token = token;
        const response = await CreatePostAPICall(enqueueSnackbar, LimeroadSaveExcelData, body);
        if (response) {
            if (sumOrderAmount.length > 0) {
                await uploadExcelFileData(tpEmailId, apiType, sumOrderAmount, fileType, files, response.data.token);
            } else {
                const tempFiles = files.slice(1);
                if (tempFiles.length === 0) {
                    handleRemoveAllFiles();
                    setDataUploadLoader(false);
                    enqueueSnackbar('File Saved Successfully');
                    setSelectedAccountForExcelUpload(null);
                } else {
                    handleOnUpload(fileType, tempFiles);
                }
            }
        } else {
            handleRemoveAllFiles();
            setDataUploadLoader(false);
        }
    };

    // formatting data and calling api call function
    const onLoadLimeroadFileUploadDetail = async (e, file, type, files) => {
        const dataForUpload = [];
        let apiType;
        let mainValidationName;
        const excelActualData = ReadExcelFile(e, 0);

        if (type === 'Order') {
            const isValidated = AmazonValidateUploadedFile(excelActualData[0], type);
            apiType = 'excelRTSOrdersData';
            if (isValidated) {
                excelActualData.forEach((item, index) => {
                    if (index >= 1) {
                        if (item[11] === 'MANIFEST_GENERATED') {
                            dataForUpload.push([
                                item[0],
                                item[1],
                                item[6],
                                item[7],
                                item[12] ? item[12] : 'NA',
                                item[13] ? item[13] : 'NA',
                            ]);
                        } else {
                            setDataUploadLoader(false);
                            handleRemoveAllFiles();
                            enqueueSnackbar('Selected file field(s) not valid.', { variant: 'error' });
                            return null;
                        }
                    }
                });
            } else {
                setDataUploadLoader(false);
                handleRemoveAllFiles();
                enqueueSnackbar('Selected file field(s) not valid.', { variant: 'error' });
                return;
            }
        }

        if (type === 'shippedOrDeliveredOrCancelledOrder') {
            apiType = 'excelShippedOrDeliveredOrCancelledOrdersData';
            const isValidated = AmazonValidateUploadedFile(excelActualData[0], type);
            if (excelActualData[1][11] === 'SHIPPED' || excelActualData[1][11] === 'COMPLETED') {
                if (isValidated) {
                    excelActualData.forEach((item, index) => {
                        if (index >= 1) {
                            dataForUpload.push([
                                item[0],
                                item[1],
                                item[2] ? item[2] : 'NA',
                                item[6],
                                item[7],
                                item[12] ? item[12] : 'NA',
                                item[13] ? item[13] : 'NA',
                            ]);
                        }
                    });
                } else {
                    setDataUploadLoader(false);
                    handleRemoveAllFiles();
                    enqueueSnackbar('Selected file field(s) not valid.', { variant: 'error' });
                    return;
                }
            } else if (excelActualData[1][11].includes('_CANCELLED')) {
                if (isValidated) {
                    excelActualData.forEach((item, index) => {
                        if (index >= 1) {
                            dataForUpload.push([item[0], item[1], item[7], 'True']);
                        }
                    });
                } else {
                    setDataUploadLoader(false);
                    handleRemoveAllFiles();
                    enqueueSnackbar('Selected file field(s) not valid.', { variant: 'error' });
                    return;
                }
            } else {
                setDataUploadLoader(false);
                handleRemoveAllFiles();
                enqueueSnackbar('Selected file field(s) not valid.', { variant: 'error' });
                return;
            }
        }

        if (type === 'RTN') {
            apiType = 'excelRtnData';
            const isValidated = AmazonValidateUploadedFile(excelActualData[0], type);
            if (excelActualData[1][11] === 'RETURNED' || excelActualData[1][11] === 'RTO') {
                if (isValidated) {
                    excelActualData.forEach((item, index) => {
                        if (index >= 1) {
                            dataForUpload.push([
                                item[0],
                                item[1],
                                item[6],
                                item[7],
                                item[11],
                                item[12] ? item[12] : '',
                                item[13] ? item[13] : 'NA',
                            ]);
                        }
                    });
                } else {
                    setDataUploadLoader(false);
                    handleRemoveAllFiles();
                    enqueueSnackbar('Selected file field(s) not valid.', { variant: 'error' });
                    return;
                }
            } else {
                setDataUploadLoader(false);
                handleRemoveAllFiles();
                enqueueSnackbar('Selected file field(s) not valid.', { variant: 'error' });
                return;
            }
        }

        if (type === 'Payment') {
            apiType = 'excellimeroadPayment';
            const excelActualData = ReadExcelFile(e, 0,'Items');
            const isValidated = LimeroadValidateUploadedFile(excelActualData[0], type);

            if (isValidated) {
                excelActualData.forEach((item, index) => {
                    if (index > 0) {
                        dataForUpload.push([
                            item[0],
                            item[4],
                            item[6]
                        ]);
                    }
                });
            } else {
                setDataUploadLoader(false);
                handleRemoveAllFiles();
                enqueueSnackbar('Selected file field(s) not valid.', { variant: 'error' });
                return;
            }
            if (selectedAccountForExcelUpload !== null) {
                if (dataForUpload.length !== 0) {
                    await uploadExcelFileData(selectedAccountForExcelUpload.Email, 'excelPaymentPaid', dataForUpload, type, files);
                } else {
                    setDataUploadLoader(false);
                    enqueueSnackbar('No Data In This File.', { variant: 'error' });
                    handleRemoveAllFiles();
                    return;
                }
            } else {
                setDataUploadLoader(false);
                enqueueSnackbar('Please Select A Account', { variant: 'error' });
                handleRemoveAllFiles();
                return;
            }
        }

        if (type === 'Inventory') {
            apiType = 'excelInventoryData';
            const isValidated = AmazonValidateUploadedFile(excelActualData[0], type);
            if (isValidated) {
                excelActualData.forEach((item, index) => {
                    if (index >= 1) {
                        dataForUpload.push([item[1], item[5], item[9], Number(item[11])]);
                    }
                });
            } else {
                setDataUploadLoader(false);
                handleRemoveAllFiles();
                enqueueSnackbar('Selected file field(s) not valid.', { variant: 'error' });
                return;
            }
        }

        if (type !== 'Payment') {
            if (dataForUpload.length !== 0) {
                if (selectedAccountForExcelUpload) {
                    uploadExcelFileData(
                        selectedAccountForExcelUpload.Email,
                        selectedAccountForExcelUpload.value,
                        apiType,
                        dataForUpload,
                        type,
                        files
                    );
                } else {
                    setDataUploadLoader(false);
                    handleRemoveAllFiles();
                    enqueueSnackbar('Please Select Account', { variant: 'error' });
                }
            } else {
                setDataUploadLoader(false);
                handleRemoveAllFiles();
                enqueueSnackbar('No Data In This File.', { variant: 'error' });
            }
        } else if (type !== 'Payment') {
            setDataUploadLoader(false);
            handleRemoveAllFiles();
            enqueueSnackbar('Invalid File Or Data.', { variant: 'error' });
        }
    };

    // handle on load
    const handleOnUpload = (type, files) => {
        setDataUploadLoader(true);
        const file = files[0];
        if (file) {
            const reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onload = async (e) => {
                await onLoadLimeroadFileUploadDetail(e, file, type, files);
            };
        } else {
            handleRemoveAllFiles();
        }
    };

    // order upload container,
    const OrderUploadContainer = () =>
        dataUploadLoader ? (
            <div style={{ width: '100%', textAlign: 'center', padding: '100px' }}>
                <CircularProgress sx={{ color: SystemColor.originColor }} size={24} color="inherit" />
                <br />
                {fileType === 'Order'
                    ? 'Please Wait While Uploading RTS Order Data...'
                    : 'Please Wait While Uploading Shipped Or Delivered Order Data...'}
            </div>
        ) : (
            <>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        <UploadMultiFile
                            multiple
                            acceptTypes={'.xlsx, .xls,.csv'}
                            files={fileType === 'Order' ? files : []}
                            title={'Drop Or Select RTS Order Excel'}
                            onDrop={(e) => {
                                handleDropMultiFile(e, 'Order');
                            }}
                            onRemove={handleRemoveFile}
                            onRemoveAll={handleRemoveAllFiles}
                            onUpload={() => {
                                handleOnUpload('Order', files);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        <UploadMultiFile
                            multiple
                            acceptTypes={'.xlsx, .xls, .csv'}
                            files={fileType === 'shippedOrDeliveredOrCancelledOrder' ? files : []}
                            title={'Drop Or Select Shipped Or Delivered Or Cancelled Excel'}
                            onDrop={(e) => {
                                handleDropMultiFile(e, 'shippedOrDeliveredOrCancelledOrder');
                            }}
                            onRemove={handleRemoveFile}
                            onRemoveAll={handleRemoveAllFiles}
                            onUpload={() => {
                                handleOnUpload('shippedOrDeliveredOrCancelledOrder', files);
                            }}
                        />
                    </Grid>
                </Grid>
            </>
        );

    // return upload container
    const ReturnUploadContainer = () =>
        dataUploadLoader ? (
            <div style={{ width: '100%', textAlign: 'center', padding: '100px' }}>
                <CircularProgress sx={{ color: SystemColor.originColor }} size={24} color="inherit" />
                <br />
                Please Wait While Uploading Return Data...
            </div>
        ) : (
            <>
                <UploadMultiFile
                    multiple
                    acceptTypes={'.xlsx, .xls,.csv'}
                    files={fileType === 'RTN' ? files : []}
                    title={'Drop Or Select Return Excel'}
                    onDrop={(e) => {
                        handleDropMultiFile(e, 'RTN');
                    }}
                    onRemove={handleRemoveFile}
                    onRemoveAll={handleRemoveAllFiles}
                    onUpload={() => {
                        handleOnUpload('RTN', files);
                    }}
                />
            </>
        );

    // payment container
    const PaymentUploadContainer = () =>
        dataUploadLoader ? (
            <div style={{ width: '100%', textAlign: 'center', padding: '100px' }}>
                <CircularProgress sx={{ color: SystemColor.originColor }} size={24} color="inherit" />
                <br />
                Please Wait While Uploading Limeroad Payment Data...
            </div>
        ) : (
            <UploadMultiFile
                multiple
                acceptTypes={'.xlsx, .xls, .csv'}
                files={fileType === 'Payment' ? files : []}
                title={'Drop Or Select Payment Excel'}
                onDrop={(e) => {
                    handleDropMultiFile(e, 'Payment');
                }}
                onRemove={handleRemoveFile}
                onRemoveAll={handleRemoveAllFiles}
                onUpload={() => {
                    handleOnUpload('Payment', files);
                }}
            />
        );

    // inventory upload container
    const InventoryUploadContainer = () =>
        dataUploadLoader ? (
            <div style={{ width: '100%', textAlign: 'center', padding: '100px' }}>
                <CircularProgress sx={{ color: SystemColor.originColor }} size={24} color="inherit" />
                <br />
                Please Wait While Uploading Inventory Data...
            </div>
        ) : (
            <>
                <UploadMultiFile
                    multiple
                    acceptTypes={'.xlsx, .xls,.csv'}
                    files={fileType === 'Inventory' ? files : []}
                    title={'Drop Or Select Inventory Excel'}
                    onDrop={(e) => {
                        handleDropMultiFile(e, 'Inventory');
                    }}
                    onRemove={handleRemoveFile}
                    onRemoveAll={handleRemoveAllFiles}
                    onUpload={() => {
                        handleOnUpload('Inventory', files);
                    }}
                />
            </>
        );

    // Amazon Tabs
    const currentUploadTabs = [
        // {
        //     value: 'Order',
        //     icon: <Iconify icon={'tabler:truck-delivery'} width={25} height={25} />,
        //     component: <OrderUploadContainer />,
        // },
        // {
        //     value: 'Return',
        //     icon: <Iconify icon={'tabler:truck-return'} width={25} height={25} />,
        //     component: <ReturnUploadContainer />,
        // },
        {
            value: 'Payment',
            icon: <Iconify icon={'mdi:recurring-payment'} width={25} height={25} />,
            component: <PaymentUploadContainer />,
        },
        // {
        //     value: 'Inventory',
        //     icon: <Iconify icon={'vaadin:stock'} width={20} height={20} />,
        //     component: <InventoryUploadContainer />,
        // },
    ];

    // handle drop multiple file
    const handleDropMultiFile = useCallback(
        (acceptedFiles, fileType) => {
            const newFiles = acceptedFiles.map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                })
            );
            setFiles([...newFiles]);
            setFileType(fileType);
        },
        [files]
    );

    // handle remove file
    const handleRemoveFile = (inputFile) => {
        const filtered = files.filter((file) => file !== inputFile);
        setFiles(filtered);
    };

    // handle remove all file
    const handleRemoveAllFiles = () => {
        setFiles([]);
    };

    // for getting date
    const getdate = async (value, dateString) => {
        const amazonDate = {
            start: dateString[0],
            end: dateString[1],
        };
        setAmazonDateToBeFetched(amazonDate);
    };

    // api sync
    const fetchApiAmazonOrder = async (SyncDataType) => {
        if (tpEmail.length === 0) {
            enqueueSnackbar('Please Select Account', { variant: 'error' });
            return;
        }
        if (SyncDataType === 'RTS') {
            SetIsReadyToShipLoading(true);
        }
        if (SyncDataType === 'RTN') {
            SetIsReturnLoading(true);
        }
        if (SyncDataType === 'CNL') {
            SetIsCancelLoading(true);
        }
        if (SyncDataType === 'allInventory') {
            SetIsInventoryLoading(true);
        }
        if (SyncDataType === 'SHIPPED') {
            SetIsShippedLoading(true);
        }
        const body = {
            username: user?.email ? user.email : localUser,
            tpEmailIds: tpEmail,
            subAccountIds: supplierId,
            cNames: companyName,
            syncDataType: SyncDataType,
            acType: 'limeroad',
        };
        if ((isAdmin && SyncDataType === 'RTN') || SyncDataType === 'SHIPPED') {
            body.start_date = amazonDateToBeFetched.start;
            body.end_date = amazonDateToBeFetched.end;
        }
        const response = await CreatePostAPICall(enqueueSnackbar, SyncOrders, body);
        if (response) {
            enqueueSnackbar(response.data.body.message);
            if (SyncDataType === 'RTS') {
                SetIsReadyToShipLoading(false);
            }
            if (SyncDataType === 'RTN') {
                SetIsReturnLoading(false);
            }
            if (SyncDataType === 'CNL') {
                SetIsCancelLoading(false);
            }
            if (SyncDataType === 'allInventory') {
                SetIsInventoryLoading(false);
            }
            if (SyncDataType === 'SHIPPED') {
                SetIsShippedLoading(false);
            }
        }
    };

    return (
        <Container maxWidth={themeStretch ? false : 'xlg'}>
            <Grid sx={{ p: 2 }} container spacing={3}>
                <Grid item xs={12} md={3}>
                    <Typography variant="h6">
                        <div style={{ display: 'flex' }}>
                            <Iconify
                                icon={'ant-design:cloud-sync-outlined'}
                                width={25}
                                height={25}
                                style={{ color: '#7635DC', marginRight: '5px' }}
                            />{' '}
                            Api Sync
                        </div>
                    </Typography>

                    <Divider sx={{ mt: 1, mb: 3, width: '110px', backgroundColor: '#7635DC', height: '2px' }} />

                    <div style={{ width: '100%' }}>
                        <AccountSelector allData={allAccountsData} onChangeHandler={handleSelectedAccount} />
                    </div>
                    <br />
                    <Box
                        sx={{
                            display: 'grid',
                            rowGap: 2,
                        }}
                    >
                        {/*{isAdmin && (*/}
                        {/*    <>*/}
                        {/*        <RangePicker*/}
                        {/*            className={'createDateRangePicker'}*/}
                        {/*            pupClassName={'createDateRangePicker'}*/}
                        {/*            format={'DD/MM/YY'}*/}
                        {/*            onChange={(value, event) => getdate(value, event)}*/}
                        {/*            {...(!isLight && {*/}
                        {/*                className: 'date-picker-dark',*/}
                        {/*            })}*/}
                        {/*        />*/}
                        {/*        <Button*/}
                        {/*            variant="outlined"*/}
                        {/*            startIcon={<Iconify icon={'ion:return-up-forward-outline'} size="medium" />}*/}
                        {/*            onClick={() => {*/}
                        {/*                fetchApiAmazonOrder('SHIPPED');*/}
                        {/*            }}*/}
                        {/*            disabled={isShippedLoading}*/}
                        {/*        >*/}
                        {/*            Sync Shipped Orders*/}
                        {/*        </Button>*/}
                        {/*    </>*/}
                        {/*)}*/}
                        {isAdmin && (
                            <>
                                <RangePicker
                                    className={'createDateRangePicker'}
                                    pupClassName={'createDateRangePicker'}
                                    format={'DD/MM/YY'}
                                    onChange={(value, event) => getdate(value, event)}
                                    {...(!isLight && {
                                        className: 'date-picker-dark',
                                    })}
                                />
                                <Button
                                    variant="outlined"
                                    id={`${platForm}SyncShipped`}
                                    startIcon={<Iconify icon={'ion:return-up-forward-outline'} size="medium" />}
                                    onClick={() => {
                                        fetchApiAmazonOrder('SHIPPED');
                                    }}
                                    disabled={isShippedLoading}
                                >
                                    Sync Shipped Orders
                                </Button>
                            </>
                        )}
                        <Button
                            variant="outlined"
                            id={`${platForm}SyncRts`}
                            startIcon={<Iconify icon={'ion:return-up-forward-outline'} size="medium" />}
                            onClick={() => {
                                fetchApiAmazonOrder('RTS');
                            }}
                            disabled={isReadyToShipLoading}
                        >
                            Sync Ready To Ship Orders
                        </Button>
                        <Button
                            variant="outlined"
                            id={`${platForm}SyncRtn`}
                            startIcon={<Iconify icon={'ion:return-down-back-outline'} size="medium" />}
                            onClick={() => {
                                fetchApiAmazonOrder('RTN');
                            }}
                            disabled={isReturnLoading}
                        >
                            Sync Return Orders
                        </Button>

                        {/*<Button*/}
                        {/*    variant="outlined"*/}
                        {/*    id={'3'}*/}
                        {/*    startIcon={<Iconify icon={'iconoir:cancel'} size="medium" />}*/}
                        {/*    onClick={() => {*/}
                        {/*        fetchApiAmazonOrder('CNL');*/}
                        {/*    }}*/}
                        {/*    disabled={isCancelLoading}*/}
                        {/*>*/}
                        {/*    Sync Cancel Orders*/}
                        {/*</Button>*/}

                        {isAdmin && (
                            <Button
                                variant="outlined"
                                startIcon={<Iconify icon={'ant-design:stock-outlined'} size="medium" />}
                                onClick={() => {
                                    fetchApiAmazonOrder('allInventory');
                                }}
                                id={`${platForm}SyncInventory`}
                                disabled={isInventoryLoading}
                            >
                                Sync Inventory
                            </Button>
                        )}
                    </Box>

                    {/*
          <Divider
            orientation="horizontal"
            sx={{ marginBottom: '15px', marginTop: '15px', borderStyle: 'dashed', borderWidth: '0.5px' }}
          />

          <Typography variant="h6">
            <div style={{ display: 'flex' }}>
              <Iconify
                icon={'ant-design:cloud-sync-outlined'}
                width={25}
                height={25}
                style={{ color: '#7635DC', marginRight: '5px' }}
              />{' '}
              Auto Sync
            </div>
          </Typography>

          <Divider sx={{ mt: 1, mb: 3, width: '120px', backgroundColor: '#7635DC', height: '2px' }} />
          */}
                </Grid>

                <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    sx={{
                        marginLeft: '26px',
                        borderStyle: 'dashed',
                        borderRightWidth: 'initial',
                        marginBottom: '-10px',
                        marginTop: '20px',
                    }}
                />

                <Grid item xs={12} md={8.5}>
                    <Typography variant="h6">
                        <div style={{ display: 'flex' }}>
                            <Iconify
                                icon={'ri:file-excel-2-line'}
                                width={25}
                                height={25}
                                style={{ color: 'green', marginRight: '5px' }}
                            />{' '}
                            Upload Excel
                        </div>
                    </Typography>
                    <Divider sx={{ mt: 1, mb: 3, width: '143px', backgroundColor: '#7635DC', height: '2px' }} />
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={12} sx={{ marginTop: '-1.3%' }}>
                            <Tabs
                                allowScrollButtonsMobile
                                variant="scrollable"
                                scrollButtons="auto"
                                value={currentUploadTab}
                                onChange={onChangeUploadTab}
                            >
                                {currentUploadTabs.map((tab) => (
                                    <Tab disableRipple key={tab.value} label={capitalCase(tab.value)} icon={tab.icon} value={tab.value} />
                                ))}
                            </Tabs>
                            <Box sx={{ mb: 2 }} />

                                <>
                                    <Autocomplete
                                        id="limeroad-excel-upload-selector"
                                        onChange={(event, value) => {
                                            setSelectedAccountForExcelUpload(value);
                                        }}
                                        value={selectedAccountForExcelUpload}
                                        options={limeroadAccountListOptions.map((option) => option)}
                                        renderInput={(params) => <TextField {...params} label="Select Limeroad Account*" />}
                                    />
                                    <Box sx={{ mb: 2 }} />
                                </>
                            {currentUploadTabs.map((tab) => {
                                const isMatched = tab.value === currentUploadTab;
                                return isMatched && <Box key={tab.value}>{tab.component}</Box>;
                            })}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Input id="selectedAccountsData" value={JSON.stringify(selectedAccountsWithOutAllOptions)} type="hidden" />
        </Container>
    );
}
