/* eslint-disable */
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Grid, IconButton, Container, Box, CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import NoDataFound from '../../../components/Common/NoDataFound';
import { UploadMultiFile } from '../../../components/upload';
import { IOSSwitch, downloadS3File, uploadFileS3, SystemColor } from '../../../utils/utilities';
import { UTILITY_LABEL_PROCESS } from '../../../utils/Constants';
import useAuth from '../../../hooks/useAuth';
import { CreatePostAPICall, Utility } from '../../../utils/apis';
import DataGridTable from '../../../components/Common/DataGridTable';
import Iconify from '../../../components/Iconify';
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import * as pdfjsLib from "pdfjs-dist";
import { PDFDocument, StandardFonts } from 'pdf-lib';
import { GetTodayDateTime } from "../../../utils/utilities";


pdfjsLib.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;
const regex = /\[\d{8}\]/;
const HSF_NO = "540754"
const textToFindJson = {
  "meesho": ["Fold Here", "Product Details"],
  "glowroad": ["TAX INVOICE"],
  "City Mall": ["fold "],
  "Snap Deal": ["SHIPPED FROM"],
  "Lime Road": ["PACK SLIP", "Shadowfax Sort Code:"]
}
const cropBoxSizeAdjust = {
  "meesho": [8, -50],
  "glowroad": [8],
  "City Mall": [8],
  "Snap Deal": [-50],
  "Lime Road": [-850, -300]
}

export default function LabelProcess({ acType }) {
  const { user } = useAuth();
  const localUser = window.localStorage.getItem('userEmailId');
  const { enqueueSnackbar } = useSnackbar();
  const { themeStretch } = useSettings();
  const [historyDetails, setHistoryDetails] = useState([]);
  const [historyTableLoading, setHistoryTableLoading] = useState(false);
  const [allFiles, setAllFiles] = useState([]);
  const [withTaxBill, setWithTaxBill] = useState(false);
  const [croppingPdf, setCroppingPdf] = useState(false);

  /* eslint-disable no-await-in-loop */
  async function cropPdf(file) {
    setCroppingPdf(true)
    const arrayBuffer = await file.arrayBuffer();


    // Load the PDF document
    const pdfDoc = await PDFDocument.load(arrayBuffer);
    const pdfDocNew = await PDFDocument.create();

    const textToFind = textToFindJson[acType]
    const fileData = new Uint8Array(arrayBuffer);

    const loadingTask = pdfjsLib.getDocument(fileData);
    const pdfDocument = await loadingTask.promise;
    /* eslint-disable no-await-in-loop */
    for (let pageNumber = 1; pageNumber <= pdfDocument.numPages; pageNumber += 1) {
      let isFound = false;
      let textItem;
      let indexOfText;
      const page = await pdfDocument.getPage(pageNumber);

      const textContent = await page.getTextContent();
      console.log("textContent", textContent)
      const textLayer = textContent.items.reverse();
      for (let i = 0; i < textLayer.length; i += 1) {
        textItem = textLayer[i];
        const text = textItem.str;
        indexOfText = textToFind.indexOf(text)
        if (indexOfText !== -1) {
          isFound = true;
          break;
        }
      }

      if (isFound) {
        const page = pdfDoc.getPage(pageNumber - 1)
        const yAxisAdzust = cropBoxSizeAdjust[acType][indexOfText]
        const cropBox = { x: 0, y: textItem.transform[5] + yAxisAdzust, width: page.getWidth(), height: page.getHeight() - textItem.transform[5] - yAxisAdzust }
        const { width, height } = page.getSize();
        const croppedPage = pdfDocNew.addPage([width, height]);


        // Copy content from the original page to the cropped page
        const copiedContent = await pdfDocNew.embedPdf(pdfDoc, [pageNumber - 1]);
        // console.log("copiedContent", copiedContent);
        croppedPage.drawPage(copiedContent[0], {
          x: 0,
          y: 0,
          width,
          height,
        });

        if (acType === "Lime Road") {
          var modifiedText = "";
          var textItem2 = "";
          const UIpIdArray = []
          const secondPage = await pdfDocument.getPage(pageNumber + 1);

          const secodTextContent = await secondPage.getTextContent();
          const textLayer = secodTextContent.items;
          var upid = ""
          for (let i = 0; i < textLayer.length; i += 1) {
            textItem2 = textLayer[i];
            const text = textItem2.str;
            if (text === HSF_NO) {
              for (let j = i + 1; j <= i + 10; j++) {
                upid += textLayer[j].str
                if (regex.test(textLayer[j].str)) {
                  break;
                }
              }
              upid += "\n"
            }
          }
          modifiedText = `Style Code [Uiproduct Id]\n${upid.replace(/\s/g, '')}`

          // Copy content from the original page to the cropped page
          // console.log("copiedContent", copiedContent);
          const font = await pdfDocNew.embedFont(StandardFonts.Helvetica);
          const drawTextOptions = {
            x: 150, // Adjust the x-coordinate as needed
            y: textItem.transform[5] - 50, // Adjust the y-coordinate as needed
            size: 10,
            font
          };
          croppedPage.drawText(modifiedText, drawTextOptions);
        }
        croppedPage.drawPage(copiedContent[0], {
          x: 0,
          y: 0,
          width,
          height,
        });

        // Crop the page using the specified crop box
        croppedPage.setCropBox(cropBox.x, cropBox.y, cropBox.width, cropBox.height);

      } else {
        console.log("Not Found on", pageNumber)
      }
    }
    const pdfBytes = await pdfDocNew.save();

    // Create a Blob from the Uint8Array
    const blob = new Blob([pdfBytes], { type: 'application/pdf' });

    // Create a URL for the Blob
    const url = URL.createObjectURL(blob);

    // Create an anchor element for downloading
    const a = document.createElement('a');
    a.href = url;
    a.download = `removed_${file.name}_${GetTodayDateTime()}.pdf`;
    a.textContent = 'Download PDF';
    setCroppingPdf(false)
    // Trigger a click event on the anchor element to initiate the download
    a.click();

    // Release the URL and remove the anchor element
    URL.revokeObjectURL(url);
  }

  // columns for label cropping
  const columns = [
    {
      accessorKey: 'Date',
      header: 'Date',
    },
    {
      accessorKey: 'Billed Orders',
      header: 'Billed Orders',
      size: 10,
      Cell: ({ cell }) => {
        return cell.row.original['Billed Orders'] ? cell.row.original['Billed Orders'] : '-';
      },
    },
    {
      accessorKey: 'key',
      header: 'Download',
      size: 10,
      Cell: ({ cell }) =>
        cell.row.original.key ? (
          <>
            <IconButton
              onClick={() => downloadS3File(cell.row.original.key[0], true, UTILITY_LABEL_PROCESS, enqueueSnackbar)}
            >
              <Iconify icon={'vscode-icons:file-type-pdf2'} width={30} height={30} />
            </IconButton>
          </>
        ) : (
          'Error'
        ),
    },
  ];

  const uploadDocument = async (e, type) => {
    allFiles.map(async (file) => {
      await cropPdf(file)
    });
  };

  // trigger from label cropping
  const uploadFiles = async (token, type) => {
    setHistoryTableLoading(true);

    const body = {
      username: user?.email ? user.email : localUser,
      actionType: 'triggerProcessLambda',
      keys: [`${user?.email}/${acType}/${token}/to-process/${allFiles[0].name}`],
      utilityType: type,
      acType,
      withTaxBill,
    };
    const response = await CreatePostAPICall(enqueueSnackbar, Utility, body);
    if (response) {
      enqueueSnackbar('Document Uploaded Successfully');
      setAllFiles([]);
      await getHistoryOfUtility();
      setWithTaxBill(false);
    }
  };

  // getting table data for label cropping
  const getHistoryOfUtility = async () => {
    setHistoryDetails([]);

    const body = {
      username: user?.email ? user.email : localUser,
      actionType: 'getHistory',
      utilityType: 'labelProcess',
      acType
    };

    const response = await CreatePostAPICall(enqueueSnackbar, Utility, body);
    if (response) {
      setHistoryDetails(response.data.body);
      setHistoryTableLoading(false)
    }
  };

  // RefreshHistoryTable
  const refreshHistoryTable = () => {
    getHistoryOfUtility();
  };

  // by default call for all page
  useEffect(() => {
    getHistoryOfUtility()
  }, []);

  // handle drop file
  const handleDropMultiFile = useCallback(
    (acceptedFiles) => {
      const newFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      setAllFiles([...newFiles]);
    },
    [allFiles]
  );

  // handle remove files
  const handleRemoveFile = (inputFile) => {
    const filtered = allFiles.filter((file) => file !== inputFile);
    setAllFiles(filtered);
  };

  // handle remove all files
  const handleRemoveAllFiles = () => {
    setAllFiles([]);
  };

  return (
    <>
      <Page title="Label Process">
        <Container maxWidth={themeStretch ? false : 'xl'}>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={6} sx={{ pb: 2 }}>
                  {/*<Box xs={6} md={3} lg={2.5}>*/}
                  {/*  With Tax Bills*/}
                  {/*  <IOSSwitch sx={{ m: 1 }} checked={withTaxBill} onChange={(e) => setWithTaxBill(e.target.checked)} />*/}
                  {/*</Box>*/}
                  {croppingPdf ? (
                    <div style={{ width: '100%', textAlign: 'center', padding: '100px' }}>
                      <CircularProgress sx={{ color: SystemColor.originColor }} size={24} color="inherit" />
                      <br />
                      Please Wait While Label Cropping...
                    </div>
                  ) : <UploadMultiFile
                    multiple
                    acceptTypes={'.pdf'}
                    files={allFiles}
                    title={`Drop Or Select ${acType} Label Files`}
                    onDrop={(e) => {
                      setAllFiles([]);
                      handleDropMultiFile(e);
                    }}
                    onRemove={handleRemoveFile}
                    onRemoveAll={handleRemoveAllFiles}
                    onUpload={uploadDocument}
                  />}

                  {/*{historyDetails.length !== 0 ? (*/}
                  {/*    <>*/}
                  {/*      <div style={{ textAlign: 'end', marginBottom: '20px', marginTop: '20px' }}>*/}
                  {/*        <Button*/}
                  {/*            variant="contained"*/}
                  {/*            startIcon={<Iconify icon={'material-symbols:refresh'} width={25} height={25} />}*/}
                  {/*            onClick={refreshHistoryTable}*/}
                  {/*        >*/}
                  {/*          Refresh*/}
                  {/*        </Button>*/}
                  {/*      </div>*/}
                  {/*      <DataGridTable columns={columns} data={historyDetails} isLoading={historyTableLoading} />*/}
                  {/*    </>*/}
                  {/*) : (*/}
                  {/*    <NoDataFound message={'No Data Found'} />*/}
                  {/*)}*/}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Page>
    </>
  );
}