/* eslint-disable */
import { Card, CircularProgress, Container, Grid, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PlatformSelectionBtn from '../../components/Common/PlatFormSelectionBtn';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Page from '../../components/Page';
import useAuth from '../../hooks/useAuth';
import useSettings from '../../hooks/useSettings';
import urlConstants from '../../routes/urlConstants';
import { CreatePostAPICall, MyLinkedAccount } from '../../utils/apis';
import { SaveUserExistingAcTypes, saveAccountData, isSuperAdminEnvironment, SystemColor } from '../../utils/utilities';
import AmazonLinkedAccount from './components/AmazonLinkedAccount';
import FlipkartLinkedAccounts from './components/FlipkartLinkedAccount';
import GlowroadLinkedAccounts from './components/GlowroadLinkedAccount';
import JiomartLinkedAccount from './components/JiomartLinkedAccount';
import MeeshoLinkedAccounts from './components/MeeshoLinkedAccount';
import MyntraLinkedAccount from './components/MyntraLinkedAccount';
import ShopdeckLinkedAccounts from './components/ShopdeckLinkedAccount';
import SnapdealLinkedAccounts from './components/SnapdealLinkedAccount';
import LimeroadLinkedAccount from './components/LimeroadLinkedAccount';
import CitymallLinkedAccount from "./components/CitymallLinkedAccount";
import { ToggleButton, ToggleButtonGroup } from '@mui/lab';
import Iconify from '../../components/Iconify';
import Label from '../../components/Label';
import { alpha, useTheme } from '@mui/material/styles';

const PlatformOptions = [
  {
    platformName: 'Meesho',
    platformIcon: 'healthicons:m-outline',
    value: 'meesho',
  },
  {
    platformName: 'Flipkart',
    platformIcon: 'arcticons:flipkart',
    value: 'flipkart',
  },
  {
    platformName: 'Glowroad',
    platformIcon: 'healthicons:g-outline',
    value: 'glowroad',
  },
  {
    platformName: 'Shopdeck',
    platformIcon: 'tabler:circle-letter-s',
    value: 'shopdeck',
  },
  {
    platformName: 'Snapdeal',
    platformIcon: 'tabler:hexagon-letter-s',
    value: 'snapdeal',
  },
  {
    platformName: 'JioMart',
    platformIcon: 'arcticons:jiomart',
    value: 'jiomart',
  },
  {
    platformName: 'Myntra',
    platformIcon: 'arcticons:myntra',
    value: 'myntra',
  },
  {
    platformName: 'Amazon',
    platformIcon: 'grommet-icons:amazon',
    value: 'amazon',
  },
  {
    platformName: 'City Mall',
    platformIcon: 'tabler:hexagon-letter-m',
    value: 'citymall',
  },
  {
    platformName: 'Limeroad',
    platformIcon: 'grommet-icons:amazon',
    value: 'limeroad',
  },
];
export default function MyLinkedAccounts() {
  const { myLinkedAccountTab } = useParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const localUser = window.localStorage.getItem('userEmailId');
  const { themeStretch } = useSettings();
  const [Platform, setPlatform] = useState('');
  const [platformWiseLinkedAccounts, setPlatformWiseLinkedAccounts] = useState({
    linkedMeeshoAccounts: [],
    linkedFlipkartAccounts: [],
    linkedGlowroadAccounts: [],
    linkedShopdeckAccounts: [],
    linkedSnapdealAccounts: [],
    linkedJiomartAccounts: [],
    linkedMyntraAccounts: [],
    linkedLimeroadAccounts: [],
    linkedAmazonAccounts: [],
    linkedCitymallAccounts: [],
  });
  const [isAccountDataLoading, setIsAccountDataLoading] = useState(false);


  useEffect(() => {
    setPlatform(myLinkedAccountTab);
  }, [myLinkedAccountTab]);

  const getAccountData = async () => {
    setIsAccountDataLoading(true);
    const response = await CreatePostAPICall(enqueueSnackbar, MyLinkedAccount, {
      username: user?.email ? user.email : localUser,
      actionType: 'getAll',
    });
    if (response) {
      const responsedata = response.data.body.filter(item => {
        if (isSuperAdminEnvironment) {
          return true;
        } else {
          return item.accountStatus === 'ACTIVATE';
        }
      });
      const meeshoData = [];
      const flipkartData = [];
      const glowroadData = [];
      const shopdeckData = [];
      const jiomartData = [];
      const snapdealData = [];
      const myntraData = [];
      const limeroadData = [];
      const amazonData = [];
      const citymallData = [];
      const data = [];
      responsedata.forEach((item) => {
        if (item.AcType === 'meesho') {
            meeshoData.push(item);
        }
        if (item.AcType === 'flipkart') {
            flipkartData.push(item);
        }
        if (item.AcType === 'glowroad') {
            glowroadData.push(item);
        }
        if (item.AcType === 'shopdeck') {
            shopdeckData.push(item);
        }
        if (item.AcType === 'jiomart') {
            jiomartData.push(item);
        }
        if (item.AcType === 'snapdeal') {
            snapdealData.push(item);
        }
        if (item.AcType === 'myntra') {
            myntraData.push(item);
        }
        if (item.AcType === 'amazon') {
            amazonData.push(item);
        }
        if (item.AcType === 'limeroad') {
            limeroadData.push(item);
        }
        if (item.AcType === 'citymall') {
          citymallData.push(item);
        }
      });
      setPlatformWiseLinkedAccounts({
        linkedMeeshoAccounts: meeshoData,
        linkedFlipkartAccounts: flipkartData,
        linkedGlowroadAccounts: glowroadData,
        linkedShopdeckAccounts: shopdeckData,
        linkedSnapdealAccounts: snapdealData,
        linkedLimeroadAccounts: limeroadData,
        linkedJiomartAccounts: jiomartData,
        linkedMyntraAccounts: myntraData,
        linkedAmazonAccounts: amazonData,
        linkedCitymallAccounts: citymallData,
      });
      // console.log("responsedata",responsedata)
      responsedata.forEach((item) =>  {
        const cell = item;
        const newData = {};
        newData.Account = {
          Name: cell.Name,
          Email: cell.Email,
          SupplierId: cell.SupplierId,
        };
        newData.no = cell.no;
        newData.LBHW = {
          "L":cell.L,
          "B":cell.B,
          "H":cell.H,
          "W":cell.W
        }
        newData.AcType = cell.AcType;
        newData.Mobile_No = cell.Mobile_No;
        newData.Image_Link = cell.Image_Link;
        newData.identifier = cell.identifier;
        newData.SellerId = cell.SellerId;
        newData.LocationName = cell.LocationName;
        newData.accountStatus = cell.accountStatus;
        newData.isMaskingEnabled = cell.isMaskingEnabled;
        data.push(newData);
      });
      SaveUserExistingAcTypes(data);
      saveAccountData(data);
    }

    setIsAccountDataLoading(false);
  };

  useEffect(() => {
    getAccountData();
  }, []);

  // handle Platform Selection
  const onclickPlatformSelectionBtn = useCallback((event, newPlatFormName) => {
    if (newPlatFormName !== null) {
      setPlatform(newPlatFormName);
      PlatformOptions.map((e) => {
        if (e.value === newPlatFormName) {
          navigate(`${urlConstants.MY_LINKED_ACCOUNTS_PAGE}/${e.value}`);
        }
      });
    }
  }, []);

  return (
    <Page title="MyLinkedAccounts">
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <HeaderBreadcrumbs
          heading="My Linked Accounts"
          links={[{ name: 'Dashboard', href: urlConstants.HOME_PAGE }, { name: 'My Linked Accounts' }]}
        />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <>
              <Grid container spacing={3} style={{ paddingTop: '1.75%', width: '100%' }}>
                <ToggleButtonGroup
                  exclusive
                  value={Platform}
                  size="medium"
                  onChange={onclickPlatformSelectionBtn}
                  sx={{ mt: 5, ml: 3, flexWrap: 'wrap' }}
                >
                  {PlatformOptions.map((data, i) => (
                    <ToggleButton value={data.value} aria-label={data.value}
                                  key={data.value} color={'primary'}>
                      <Label variant={'soft'} sx={{ backgroundColor: alpha(theme.palette.primary.main, 0.15) }}>
                        {data.platformName === 'Limeroad' ? <Typography sx={{ color: '#7635dc' }}>Lr</Typography> : (
                          <Iconify sx={{ color: '#7635dc' }} icon={data.platformIcon} width={20} height={20} />)}

                      </Label>
                      &nbsp;&nbsp;{data.platformName}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </Grid>


              {isAccountDataLoading ? <Card>
                <div style={{ width: '100%', textAlign: 'center', padding: '100px' }}>
                  <CircularProgress sx={{ color: SystemColor.originColor }} size={24} color="inherit" />
                </div>
              </Card> : (<>{Platform === 'meesho' && (
                <MeeshoLinkedAccounts
                  linkedMeeshoAccounts={platformWiseLinkedAccounts.linkedMeeshoAccounts}
                  getAccountData={getAccountData}
                />
              )}
                {Platform === 'flipkart' && (
                  <FlipkartLinkedAccounts
                    linkedFlipkartAccounts={platformWiseLinkedAccounts.linkedFlipkartAccounts}
                    getAccountData={getAccountData}
                  />
                )}
                {Platform === 'glowroad' && (
                  <GlowroadLinkedAccounts
                    linkedGlowroadAccounts={platformWiseLinkedAccounts.linkedGlowroadAccounts}
                    getAccountData={getAccountData}
                  />
                )}
                {Platform === 'shopdeck' && (
                  <ShopdeckLinkedAccounts
                    linkedShopdeckAccounts={platformWiseLinkedAccounts.linkedShopdeckAccounts}
                    getAccountData={getAccountData}
                  />
                )}
                {Platform === 'snapdeal' && (
                  <SnapdealLinkedAccounts
                    linkedSnapdealAccounts={platformWiseLinkedAccounts.linkedSnapdealAccounts}
                    getAccountData={getAccountData}
                  />
                )}
                {Platform === 'jiomart' && (
                  <JiomartLinkedAccount
                    linkedJiomartAccounts={platformWiseLinkedAccounts.linkedJiomartAccounts}
                    getAccountData={getAccountData}
                  />
                )}
                {Platform === 'myntra' && (
                  <MyntraLinkedAccount
                    linkedMyntraAccounts={platformWiseLinkedAccounts.linkedMyntraAccounts}
                    getAccountData={getAccountData}
                  />
                )}
                {Platform === 'amazon' && (
                  <AmazonLinkedAccount
                    linkedAmazonAccounts={platformWiseLinkedAccounts.linkedAmazonAccounts}
                    getAccountData={getAccountData}
                  />
                )}
                {Platform === 'citymall' && (
                    <CitymallLinkedAccount
                        linkedCitymallAccounts={platformWiseLinkedAccounts.linkedCitymallAccounts}
                        getAccountData={getAccountData}
                    />
                )}
                {Platform === 'limeroad' && (
                  <LimeroadLinkedAccount
                    linkedAmazonAccounts={platformWiseLinkedAccounts.linkedLimeroadAccounts}
                    getAccountData={getAccountData}
                  />
                )}</>)}
            </>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
