import { Container, Grid } from '@mui/material';
import React from 'react';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Page from '../../components/Page';
import useSettings from '../../hooks/useSettings';
import urlConstants from '../../routes/urlConstants';
import OrderAnalysisDetails from './OrderAnalysisDetails';

export default function OrderAnalysis() {
  const { themeStretch } = useSettings();

  return (
    <Page title="OrderAnalysis">
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <HeaderBreadcrumbs
          heading="Order Analysis"
          links={[{ name: 'Dashboard', href: urlConstants.HOME_PAGE }, { name: 'Order Analysis' }]}
        />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <OrderAnalysisDetails />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
