/* eslint-disable */
import Scheduler from '@aldabil/react-scheduler';
import { Button, Container } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import DataGridTable from '../../components/Common/DataGridTable';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Page from '../../components/Page';
import useSettings from '../../hooks/useSettings';
import urlConstants from '../../routes/urlConstants';
import { CreatePostAPICall, V2Management } from '../../utils/apis';
import { DateFormat } from '../../utils/formatTime';
import { isSuperEnvironment } from '../../utils/utilities';

const CurrentStatusOptions = [
  {
    value: 'to_do',
    label: 'To do',
  },

  {
    value: 'in_progress',
    label: 'In Progress',
  },

  {
    value: 'testing',
    label: 'Testing',
  },

  {
    value: 'done',
    label: 'Done',
  },
];

const SelectPriorityOptions = [
  {
    value: 'HIGH',
    label: 'High',
  },

  {
    value: 'MEDIUM',
    label: 'Medium',
  },
  {
    value: 'LOW',
    label: 'Low',
  },
];

// const CustomEditor = ({ scheduler }) => {
//   const event = scheduler.edited;

//   console.log('event..............', event);

//   console.log('scheduler..............', scheduler);

//   // Make your own form/state
//   const [state, setState] = useState({
//     title: event?.title || '',
//     description: event?.description || '',
//   });
//   const [error, setError] = useState('');

//   const handleChange = (value, name) => {
//     setState((prev) => {
//       return {
//         ...prev,
//         [name]: value,
//       };
//     });
//   };
//   const handleSubmit = async () => {
//     // Your own validation
//     if (state.title.length < 3) {
//       return setError('Min 3 letters');
//     }
//     if (state.title.length < 3) {
//       return setError('Min 3 letters');
//     }

//     try {
//       scheduler.loading(true);

//       const addedUpdatedEvent = await new Promise((res) => {
//         setTimeout(() => {
//           res({
//             event_id: event?.event_id || Math.random(),
//             title: state.title,
//             start: scheduler.state.start.value,
//             end: scheduler.state.end.value,
//             description: state.description,
//           });
//         }, 3000);
//       });

//       scheduler.onConfirm(addedUpdatedEvent, event ? 'edit' : 'create');
//       scheduler.close();
//     } finally {
//       scheduler.loading(false);
//     }
//   };
//   return (
//     <div>
//       <div style={{ padding: '1rem' }}>
//         <p>Load your custom form/fields</p>
//         <TextField
//           label="Title"
//           value={state.title}
//           onChange={(e) => handleChange(e.target.value, 'title')}
//           error={!!error}
//           helperText={error}
//           fullWidth
//         />
//         <TextField
//           label="Description"
//           value={state.description}
//           onChange={(e) => handleChange(e.target.value, 'description')}
//           fullWidth
//         />
//       </div>
//       <DialogActions>
//         <Button onClick={scheduler.close}>Cancel</Button>
//         <Button onClick={handleSubmit}>Confirm</Button>
//       </DialogActions>
//     </div>
//   );
// };

const columns = [
  {
    accessorKey: 'employee_id',
    header: 'Employee id',
  },
  {
    accessorKey: 'employee_name',
    header: 'Employee name',
  },
  {
    accessorKey: 'to_do',
    header: 'To Do',
  },
  {
    accessorKey: 'in_progress',
    header: 'In Progress',
  },
  {
    accessorKey: 'testing',
    header: 'Testing',
  },
  {
    accessorKey: 'done',
    header: 'Done',
  },
  {
    accessorKey: 'total',
    header: 'Total',
  },
];

const TaskManagement = () => {
  const userEmailId = localStorage.getItem('userEmailId');
  const { themeStretch } = useSettings();
  const { enqueueSnackbar } = useSnackbar();
  const [userEvents, setUserEvents] = useState([]);
  const [singleUser, setSingleUser] = useState([]);
  const [tableUserData, setTableUserData] = useState([]);
  const [isTableLoading, setIsTableLoading] = useState(true);
  const [users, setUsers] = useState([
    { employee_id: 9, value: 9, label: 'BuissMaster Tech Support', email: 'tech@buissmaster.com' },
    { employee_id: 1, value: 1, label: 'Rutvi Vaghasiya', email: 'rutvivaghasiya97@gmail.com' },
    // { employee_id: 2, value: 2, label: 'Srushti Galani', email: 'shrustigalani26@gmail.com' },
    { employee_id: 3, value: 3, label: 'Shivu Patel', email: 'shivani.patel2322001@gmail.com' },
    { employee_id: 5, value: 5, label: 'Harpal Jalodra', email: 'harpaljalodara@gmail.com' },
    { employee_id: 10, value: 10, label: 'Viral Vala', email: 'viralvala96@gmail.com' },
    // { employee_id: 4, value: 4, label: 'Dharmi Patel', email: 'dharmip456@gmail.com' },
    { employee_id: 6, value: 6, label: 'Yashpal Vaghela', email: 'vyashpalsinh9@gmail.com' },
    // { employee_id: 8, value: 8, label: 'Kevin Mali', email: 'kevinmali0433@gmail.com' },
    // { employee_id: 11, value: 11, label: 'Ravi Rajodiya', email: 'ravirajodiya@gmail.com' },
    // { employee_id: 7, value: 7, label: 'Mukund Rana', email: 'mukundrana1994@gmail.com' },
  ]);

  let userEventData = [];

  useEffect(() => {
    if (!isSuperEnvironment) {
      const employeeId = getEmployeeIdByEmail();
      const tempUsers = [...users];
      const tempUser = tempUsers.filter((user) => user.employee_id === employeeId);
      setSingleUser(tempUser);
    }
  }, []);

  // show color by current status
  const statusColor = (status) => {
    if (status === 'to_do') {
      return '#ab2d2d';
    }

    if (status === 'in_progress') {
      return '#dec83c';
    }

    if (status === 'testing') {
      return '#3aa8e8';
    }

    if (status === 'done') {
      return '#58ab2d';
    }
  };

  // find employee id by email
  const getEmployeeIdByEmail = () => {
    const ItemIndex = users.findIndex((item) => item.email === userEmailId);

    if (ItemIndex > -1) {
      const tempData = users[ItemIndex];
      return tempData.employee_id;
    }
  };

  // format data by user name, current status and show data in table
  const employeeDataForTable = (data = []) => {
    const tempArray = JSON.parse(JSON.stringify(tableUserData));
    let userIndex = -1;
    for (let index = 0; index < data.length; index++) {
      const element = data[index];

      if (isSuperEnvironment) {
        userIndex = tempArray.findIndex((item) => item.employee_id === element.employee_id);
      } else {
        const employeeId = getEmployeeIdByEmail();
        userIndex = tempArray.findIndex((item) => item.employee_id === employeeId);
      }

      if (userIndex > -1) {
        const tempUserObj = tempArray[userIndex];

        if (element.current_status === 'to_do') {
          tempUserObj.to_do += 1;
        }

        if (element.current_status === 'in_progress') {
          tempUserObj.in_progress += 1;
        }

        if (element.current_status === 'testing') {
          tempUserObj.testing += 1;
        }

        if (element.current_status === 'done') {
          tempUserObj.done += 1;
        }
        tempUserObj.total = tempUserObj.to_do + tempUserObj.in_progress + tempUserObj.testing + tempUserObj.done;
        setTableUserData(tempArray);
      } else {
        const tempUserObj = {
          employee_id: element.employee_id,
          employee_name: element.employee_name,
          to_do: 0,
          in_progress: 0,
          testing: 0,
          done: 0,
          total: 0,
        };
        if (element.current_status === 'to_do') {
          tempUserObj.to_do = 1;
        }

        if (element.current_status === 'in_progress') {
          tempUserObj.in_progress = 1;
        }

        if (element.current_status === 'testing') {
          tempUserObj.testing = 1;
        }

        if (element.current_status === 'done') {
          tempUserObj.done = 1;
        }
        tempUserObj.total = tempUserObj.to_do + tempUserObj.in_progress + tempUserObj.testing + tempUserObj.done;
        tempArray.push(tempUserObj);

        setTableUserData(tempArray);
      }
    }

    setIsTableLoading(false);
  };

  // empolyee id to get employee name
  const getEmployeeName = (empId) => {
    const ItemIndex = users.findIndex((item) => item.employee_id === empId);

    if (ItemIndex > -1) {
      const tempData = users[ItemIndex];
      return tempData.label;
    }
  };

  // add and edit event , api call then set data in calender
  const addEditUserEvents = async (value, action) => {
    const body = {
      ...value,
      actionType: 'innerTaskManagement',
      panel: 'taskManagement',
      action: 'add',
      payload: value.payload ? value.payload : {},
      description: value.description ? value.description : '-',
      color: statusColor(value.current_status),
      employee_name: getEmployeeName(value.employee_id),
      part_key: value.select_priority,
      sort_key: value.event_id,
    };

    if (value.event_id) {
      body.action = 'edit';
    }

    if (action === 'create') {
      body.event_id = new Date().getTime();
      body.sort_key = body.event_id;
    }

    const response = await CreatePostAPICall(enqueueSnackbar, V2Management, body);

    if (response) {
      enqueueSnackbar(response.data.body);
    }

    return new Promise((res, rej) => {
      if (action === 'create') {
        setUserEvents((oldArray) => [...oldArray, body]);
      }

      if (action === 'edit') {
        const ItemIndex = userEvents.findIndex((item) => item.event_id === value.event_id);

        if (ItemIndex > -1) {
          const tempData = userEvents[ItemIndex];
          tempData.color = statusColor(value.current_status);
          userEvents[ItemIndex] = tempData;
          setUserEvents(userEvents);
          userEventData = userEvents;
        }
      }

      res({
        ...body,
        event_id: action === 'create' ? body.event_id : value.event_id,
      });
      employeeDataForTable(userEvents);
      userEventData = userEvents;
    });
  };

  // delete event only super admin and api call
  const deleteUserEvent = async (deleteId) => {
    const ItemIndex = userEventData.findIndex((item) => item.event_id === deleteId);
    if (ItemIndex > -1) {
      const tempData = userEventData[ItemIndex];
      const body = {
        panel: 'taskManagement',
        actionType: 'innerTaskManagement',
        action: 'delete',
        event_id: deleteId,
        select_priority: tempData.select_priority,
      };

      const response = await CreatePostAPICall(enqueueSnackbar, V2Management, body);
      if (response) {
        enqueueSnackbar(response.data.body);
      }
    }

    return new Promise((res, rej) => {
      const tempArray = JSON.parse(JSON.stringify(userEvents));
      const newTempArray = tempArray.filter((event) => event.event_id !== deleteId);
      setUserEvents(newTempArray);
      res(deleteId);
    });
  };

  // user event drag and drop then api call and set response
  const userEventDrops = async (event, update, orignal) => {
    const body = {
      ...update,
      actionType: 'innerTaskManagement',
      panel: 'taskManagement',
      action: 'edit',
      payload: update.payload ? update.payload : {},
      description: update.description ? update.description : '-',
      color: statusColor(update.current_status),
      employee_name: getEmployeeName(update.employee_id),
      part_key: update.select_priority,
      sort_key: update.event_id,
    };
    const response = await CreatePostAPICall(enqueueSnackbar, V2Management, body);
    if (response) {
      enqueueSnackbar(response.data.body);
    }

    return new Promise((res, rej) => {
      const ItemIndex = userEvents.findIndex((item) => item.event_id === update.event_id);

      if (ItemIndex > -1) {
        const tempData = userEvents[ItemIndex];
        tempData.color = statusColor(update.status);
        userEvents[ItemIndex] = tempData;
        setUserEvents(userEvents);
      }
      res({
        ...update,
        event_id: update.event_id,
      });
      employeeDataForTable(userEvents);
    });
  };

  // disable break hour in calendar
  const disabledHours = ({ height, start, onClick, ...props }) => {
    const hour = start.getHours();
    const disabled = hour === 13;
    const restProps = disabled ? {} : props;
    return (
      <Button
        style={{
          height: '100%',
          background: disabled ? '#eee' : 'transparent',
          cursor: disabled ? 'not-allowed' : 'pointer',
        }}
        onClick={() => {
          if (disabled) {
            return alert('Opss');
          }
          onClick();
        }}
        // disableRipple={disabled}
        disabled={disabled}
        {...restProps}
      />
    );
  };

  // default api call, calendar data set
  const fetchRemote = async (query) => {
    const body = {
      actionType: 'innerTaskManagement',
      panel: 'taskManagement',
      action: 'get',
      dateRange: {
        from: DateFormat(query.start),
        to: DateFormat(query.end),
      },
    };
    setIsTableLoading(true);

    let response = await CreatePostAPICall(enqueueSnackbar, V2Management, body);

    return new Promise((res) => {
      if (response.data.body?.length > 0) {
        response = response.data.body.map((s) => {
          s.end = new Date(s.end);
          s.start = new Date(s.start);
          s.color = statusColor(s.current_status);
          s.event_id = s.sort_key;
          s.select_priority = s.part_key;

          // delete s.sort_key;
          // delete s.part_key;
          return s;
        });

        if (!isSuperEnvironment) {
          const employeeId = getEmployeeIdByEmail();
          // const employeeId = 1;
          response = response.filter((item) => item.employee_id === employeeId);
        }

        setUserEvents((oldArray) => [...oldArray, ...response]);
        employeeDataForTable(response);
        userEventData = response;
      } else {
        setIsTableLoading(false);
      }

      setTimeout(() => {
        res(response);
      }, 2000);
    });
  };

  return (
    <Page title="TaskManagement">
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <HeaderBreadcrumbs
          heading="Task Management"
          links={[
            { name: 'Dashboard', href: urlConstants.HOME_PAGE },
            { name: 'Admin', href: urlConstants.ADMIN_PAGE },
            { name: 'Task Management' },
          ]}
        />
        {(singleUser.length > 0 || isSuperEnvironment) && (
          <>
            <DataGridTable
              columns={columns}
              data={tableUserData}
              enablePagination={false}
              isLoading={isTableLoading}
              // renderDetailPanel={expandRowComponent}
            />
            <Scheduler
              // customEditor={(scheduler) => <CustomEditor scheduler={scheduler} />}
              resourceViewMode="tab"
              day={{
                startHour: 10,
                endHour: 19,
                step: 15,
                cellRenderer: disabledHours,
              }}
              week={{
                startHour: 10,
                endHour: 19,
                step: 15,
                weekDays: [0, 1, 2, 3, 4, 5],
                weekStartOn: 1,
                cellRenderer: disabledHours,
              }}
              month={{
                startHour: 10,
                endHour: 19,
                weekDays: [0, 1, 2, 3, 4, 5],
                weekStartOn: 1,
                cellRenderer: disabledHours,
              }}
              events={userEvents}
              resources={singleUser.length > 0 ? singleUser : users}
              resourceFields={{
                idField: 'employee_id',
                textField: 'label',
              }}
              fields={[
                {
                  name: 'employee_id',
                  type: 'select',
                  options: users.map((res) => {
                    return {
                      text: res.label,
                      value: res.employee_id,
                    };
                  }),
                  config: { label: 'User', required: true, md: 4, sm: 4, xs: 4 },
                },

                {
                  name: 'current_status',
                  type: 'select',
                  default: CurrentStatusOptions[0].value,
                  options: CurrentStatusOptions.map((res) => {
                    return {
                      text: res.label,
                      value: res.value,
                    };
                  }),
                  config: { label: 'Current status', required: true, md: 4, sm: 4, xs: 4 },
                },

                {
                  name: 'select_priority',
                  type: 'select',
                  default: SelectPriorityOptions[0].value,
                  options: SelectPriorityOptions.map((res) => {
                    return {
                      text: res.label,
                      value: res.value,
                    };
                  }),
                  config: { label: 'Select priority', required: true, md: 4, sm: 4, xs: 4 },
                },

                {
                  name: 'description',
                  type: 'input',
                  // default: '',
                  config: { label: 'Description', multiline: true, rows: 4 },
                },

                {
                  name: 'payload',
                  type: 'input',
                  // default: '',
                  config: { label: 'Payload', multiline: true, rows: 4 },
                },
              ]}
              onConfirm={addEditUserEvents}
              onDelete={deleteUserEvent}
              onEventDrop={userEventDrops}
              getRemoteEvents={fetchRemote}
              {...(isSuperEnvironment && { deletable: true })}
            />
          </>
        )}
      </Container>
    </Page>
  );
};

export default TaskManagement;
