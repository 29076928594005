import React, {useRef, useState} from 'react';
import {Container, Grid,} from '@mui/material';
import {LoadingButton} from "@mui/lab";
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import Page from '../../../components/Page';
import urlConstants from '../../../routes/urlConstants';
import useSettings from '../../../hooks/useSettings';
import LabelProcess from "../labelProcess/LabelProcess";
import AcceptList from "../acceptList/AcceptList";

export default function LimeRoadUtilities() {
    const {themeStretch} = useSettings();
    const [GenerateLabelOn, setGenerateLabelOn] = useState(true);
    const [generateManifestOn, setGenerateManifestOn] = useState(false);
    const [regenerateLabel, setRegenerateLabel] = useState(false);

    const GenerateLabelBTN = useRef(null);
    return (
        <Page title="Utilities">
            <Container maxWidth={themeStretch ? false : 'xl'}>
                <HeaderBreadcrumbs
                    heading="Utilities"
                    links={[{name: 'Dashboard', href: urlConstants.HOME_PAGE}, {name: 'Utilities'}, {name: 'Lime Road'}]}
                />
                <Grid container spacing={3} style={{marginBottom: "3rem"}}>
                    <Grid item xs={12}>
                        <LoadingButton ref={GenerateLabelBTN} style={{marginRight: "1rem"}} onClick={() => {
                            setGenerateManifestOn(false)
                            setRegenerateLabel(false)
                            setGenerateLabelOn(true)
                        }} variant={GenerateLabelOn ? 'contained' : 'outlined'}>Generate Label</LoadingButton>

                        <span style={{borderRight: "1px solid #000", height: "100%"}}/>
                        <LoadingButton ref={GenerateLabelBTN} style={{marginLeft: "1rem", marginRight:"1rem"}} onClick={() => {
                            setGenerateManifestOn(false)
                            setRegenerateLabel(true)
                            setGenerateLabelOn(false)
                        }} variant={regenerateLabel ? 'contained' : 'outlined'}>ReGenerate Label</LoadingButton>
                        <span style={{borderRight: "1px solid #000", height: "100%"}}/>
                        <LoadingButton ref={GenerateLabelBTN} style={{marginLeft: "1rem", marginRight:"1rem"}} onClick={() => {
                            setGenerateManifestOn(true)
                            setRegenerateLabel(false)
                            setGenerateLabelOn(false)
                        }} variant={generateManifestOn ? 'contained' : 'outlined'}>Generate Manifest</LoadingButton>
                    </Grid>
                </Grid>
                {GenerateLabelOn && (
                    <AcceptList acType={'limeroad'} utilityType={"generateLabel"}/>
                )}
                {regenerateLabel && (
                    <AcceptList acType={'limeroad'} utilityType={"reGenerateLabel"} />
                )}
                {generateManifestOn && (
                    <AcceptList acType={'limeroad'} utilityType={"generateManifest"}/>
                )}
            </Container>
        </Page>
    );
}
