/* eslint-disable */
import { Box, Card, Container, Grid, Link, Stack, Typography } from '@mui/material';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import SyncOrdersUpdates from '../../assets/updatePhotos/SyncOrdersUpdates.jpg';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Page from '../../components/Page';
import useSettings from '../../hooks/useSettings';
import urlConstants from '../../routes/urlConstants';

export default function Updates() {
  const { themeStretch } = useSettings();

  return (
    <Page title="Updates">
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <HeaderBreadcrumbs
          heading="Updates"
          links={[{ name: 'Dashboard', href: urlConstants.HOME_PAGE }, { name: 'Updates' }]}
        />

        <Grid sx={{ p: 2 }} container spacing={2}>
          <Card sx={{ display: 'flex', alignItems: 'center', p: 3 }}>
            <Box sx={{ flexGrow: 1 }}>
              <Stack direction="row" alignItems="center" spacing={1} sx={{ mt: 2, mb: 1 }}>
                <Typography component="span" variant="h3">
                  <Link variant="subtitle3" component={RouterLink} to={urlConstants.SYNC_ORDERS_PAGE}>
                    Sync Orders
                  </Link>
                </Typography>
              </Stack>
              <Typography component="span" variant="h6">
                Platform Wise Api Sync
              </Typography>
              <Grid
                style={{
                  marginTop: '5px',
                }}
                container
                spacing={3}
              >
                <Grid item xs={12} sm={12} md={8} lg={8}>
                  <img src={SyncOrdersUpdates} alt={'updateimage'} />
                </Grid>

                <Grid item xs={12} sm={12} md={4} lg={4} sx={{ display: 'flex', flexFlow: 'column' }}>
                  <Typography variant="h6" style={{ color: 'red' }}>
                    Step 1 :
                  </Typography>
                  <div>Go To Sync Orders Panel</div>
                  <Typography variant="h6" style={{ color: 'red' }}>
                    Step 2 :
                  </Typography>
                  <div>Select Platform And Select Account You Want To Fetch Data</div>
                  <Typography variant="h6" style={{ color: 'red' }}>
                    Step 3 :
                  </Typography>
                  <div>Click On Sync Ready To Ship Orders To Fetch Your Ready To Ship Orders</div>
                  <Typography variant="h6" style={{ color: 'red' }}>
                    Step 4 :
                  </Typography>
                  <div>Click On Sync Return Orders To Fetch Your Return Orders</div>
                  <Typography variant="h6" style={{ color: 'red' }}>
                    Step 5 :
                  </Typography>
                  <div>Click On Sync Cancel Orders To Fetch Your Cancelled Orders</div>
                  <Typography variant="h6" style={{ color: 'red' }}>
                    Step 6 :
                  </Typography>
                  <div>
                    After Clicking On Step-3,4,5 You Will Get Notification On Top Side Of Page Sync Started Successfully
                    That Means Your Data Sync Started Successfully
                  </div>
                </Grid>
              </Grid>
            </Box>
          </Card>
        </Grid>
      </Container>
    </Page>
  );
}
