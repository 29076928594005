/* eslint-disable */
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled, useTheme } from '@mui/material/styles';
import { DatePicker } from 'antd';
import ExcelJS from 'exceljs';
import xlsx from 'json-as-xlsx';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import React, {useCallback, useEffect, useState} from 'react';
import ModalImage from 'react-modal-image';
import AccountSelector from '../../components/Common/AccountSelector';
import DataGridTable from '../../components/Common/DataGridTable';
import NoDataFound from '../../components/Common/NoDataFound';
import Iconify from '../../components/Iconify';
import Image from '../../components/Image';
import useAuth from '../../hooks/useAuth';
import { CreatePostAPICall, OrderAnalysis } from '../../utils/apis';
import { UPLOAD_MANIFEST } from '../../utils/Constants';
import Tesseract from 'tesseract.js';
import {
  changeDataFormat,
  changeDateFormat,
  fullDateShow,
  downloadS3File,
  getAccountsDataByType,
  GetTodayDateTime,
  isDate,
  isDesktopEnvironment,
  setAccountSelectorData,
  StyledTableCell,
} from '../../utils/utilities';
import moment from 'moment';
import Dropzone from "react-dropzone";
import {LoadingButton} from "@mui/lab";

export default function OrderAnalysisDetails()  {
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';
  const { user } = useAuth();
  const localUser = window.localStorage.getItem('userEmailId');
  const { RangePicker } = DatePicker;
  const { enqueueSnackbar } = useSnackbar();
  const [userData, setUserData] = useState([]);
  const [selectedAccountsData, setSelectedAccountsData] = useState([]);
  const [orderAnalysisData, setOrderAnalysisData] = useState([]);
  const [isOrderReportTableLoading, setIsOrderReportTableLoading] = useState(false);
  const [isAllAcFlipkart, setIsAllAcFlipkart] = useState(false);
  const [awbData, setAwbData] = useState([]);
  const [subOrderData, setSubOrderData] = useState([]);
  const [flag, setFlag] = useState(1);
  const [nodata, setNodata] = useState('Please Select An Options To See Orders Analysis Details.');
  const [selectedType, setSelectedType] = useState('');
  const [tableActualData, setTableActualData] = useState([]);
  const [filterODRStatus, setFilterODRStatus] = useState([]);
  const [filterRTNType, setFilterRTNType] = useState([]);
  const [filterSNTCourier, setFilterSNTCourier] = useState([]);
  const [filterRTNCourier, setFilterRTNCourier] = useState([]);
  const [filterRTNCondition, setFilterRTNCondition] = useState([]);
  const [sentCourierList, setSentCourierList] = useState([]);
  const [returnCourierList, setReturnCourierList] = useState([]);
  const [returnConditionList, setReturnConditionList] = useState([]);
  const [tempFilteredData, setTempFilteredData] = useState([]);
  const [skuReportData, setSkuReportData] = useState([]);
  const [imageList, setImageList] = useState([]);
  const [imageLoadingData, setImageLoadingData] = useState(false);

  const [recognizedText, setRecognizedText] = useState([]);
  const [localFilterCriteria, setLocalFilterCriteria] = useState({
    is_Cancelled: false,
    scanned: false,
    not_Scanned: false,
    received: false,
    retry_attempt: false,
    notReceived: false,
    isShowFullDate: false,
    isCustomerCredited: false,
  });
  let startDate = '';
  let endDate = '';
  let title = '';
  let emails = '';
  let values = '';
  let cNames = '';
  let accountType = '';
  let refSubOrders = [];

  // columns of table
  const columns = [
    {
      accessorKey: 'type',
      header: 'Platform',
      size: 10,
    },
    {
      accessorKey: 'company',
      header: 'Company',
      size: 10,
    },
    {
      accessorKey: 'sub_order_no',
      header: 'Suborder No',
      size: 10,
    },
    {
      accessorKey: 'sent_awb',
      header: 'Sent AWB',
      size: 10,
    },
    {
      accessorKey: 'sku',
      header: 'SKU',
      size: 10,
    },
    {
      accessorKey: 'sent_quantity',
      header: 'Quantity',
      size: 10,
    },
    {
      accessorKey: 'return_quantity',
      header: 'Return Quantity',
      size: 10,
    },
    {
      accessorKey: 'latest_Return_Awb',
      header: 'Return AWB',
      size: 10,
    },
    {
      accessorKey: 'Pay_Status',
      header: 'Payment Status',
      size: 10,
    },
  ];

  // getting awb number options
  const onAwbOptionData = async (event, awbValue) => {
    if (awbValue.length > 0) {
      setFlag('rtnAWB');
      const tpEmails = [];
      const suppliers = [];
      const acTypes = [];
      const cNames = [];
      selectedAccountsData.forEach((data) => {
        tpEmails.push(data.Email);
        suppliers.push(data.value);
        acTypes.push(data.AcType);
        cNames.push(data.cName);
      });

      const body = {
        username: user?.email ? user.email : localUser,
        tpEmailIds: tpEmails,
        subAccountIds: suppliers,
        acType: acTypes,
        cNames: cNames,
        awb: awbValue,
        get_hints: true,
      };
      const response = await CreatePostAPICall(enqueueSnackbar, OrderAnalysis, body);
      if (response) {
        const newArr = [];
        response.data.body.forEach((value) => {
          if (newArr.indexOf(value) === -1) newArr.push(value);
        });
        setAwbData(newArr);
      }
    } else {
      setFlag(0);
      setAwbData([]);
    }
  };

  // getting subOrder number options
  const onSubOrderOptionData = async (event, suborderValue) => {
    if (suborderValue.length > 0) {
      setFlag('subOrder');
      const tpEmails = [];
      const suppliers = [];
      const acTypes = [];
      const cNames = [];
      selectedAccountsData.forEach((data) => {
        tpEmails.push(data.Email);
        suppliers.push(data.value);
        acTypes.push(data.AcType);
        cNames.push(data.cName);
      });

      const body = {
        username: user?.email ? user.email : localUser,
        tpEmailIds: tpEmails,
        subAccountIds: suppliers,
        cNames: cNames,
        acType: acTypes,
        suborder_no: suborderValue,
        get_hints: true,
      };
      const response = await CreatePostAPICall(enqueueSnackbar, OrderAnalysis, body);
      if (response) {
        const newArr = [];
        response.data.body.forEach((value) => {
          if (newArr.indexOf(value) === -1) newArr.push(value);
        });
        setSubOrderData(newArr);
      }
    } else {
      setFlag(0);
      setSubOrderData([]);
    }
  };

  // handle select account
  const handleSelectedAccount = async (event, value) => {
    if (value[0]) {
      setFlag(0);
    } else {
      setFlag(1);
    }

    var tempIsAllAcFlipkart = true;
    for (const selectedAc of value) {
      if (selectedAc.AcType && (selectedAc.AcType !== 'flipkart')) {
        tempIsAllAcFlipkart = false;
      }
      else if (selectedAc.value.length < 9 && (selectedAc.value !== 'flipkart')) {
        tempIsAllAcFlipkart = false;
      }
    }

    setIsAllAcFlipkart(tempIsAllAcFlipkart);
    const response = await setAccountSelectorData(value, userData);
    setSelectedAccountsData(response[1]);
    // !isEmpty(DFValue)? viewAllData(DFValue, SubValue, response[1],null) : null
  };

  // formatted data
  const getFormattedData = (data, companyData, skuImageData) => {
    const formattedData = [];
    Object.entries(data).forEach(([key, value]) => {
      const account = [];
      const platformData = {};
      platformData.platformName = key;
      Object.entries(value).forEach(([key, value]) => {
        const accountData = {};
        const supplier = [];
        accountData.accountId = key;
        Object.entries(value).forEach(([key, value]) => {
          const supplierTempData = {};
          const supplierData = [];
          supplierTempData.supplierId = key;
          Object.entries(value).forEach(([key, value]) => {
            const data = {};
            data.sub_order_no = value[0];
            data.order_date = value[1];
            data.invoice_date = value[2];
            data.pick_up_date = value[3];
            data.is_cancelled = value[4];
            data.sku = value[5];
            data.size = value[6];
            data.self_cost = value[7];
            data.sent_quantity = value[8];
            data.latest_sent_awb = value[9];
            data.sent_Courier = value[10];
            data.scanned_Date = value[11];
            data.retry_attempt = value[12];
            data.shipment_type = value[13];
            data.exchange_entry = value[14];
            data.return_date = value[15];
            data.return_type = value[16];
            data.order_Status = value[17];
            data.return_quantity = value[18];
            data.return_courier = value[19];
            data.latest_Return_Awb = value[20];
            data.delivery_date = value[21];
            data.is_received = value[22];
            data.latest_received_date = value[23];
            data.re_condition = value[24];
            data.return_Charge = value[25];
            data.pending_reported = value[26];
            data.received_reported = value[27];
            data.pod_reported = value[28];
            data.payment_reported = value[29];
            data.rejected_date = value[30];
            data.bagPacketId = value[34];
            data.bagPacketScanStatus = value[35];
            data.paymentData = [];
            value[31].forEach((payments) => {
              const paymentDataInner = {};
              paymentDataInner.Payment_Date = payments[0] ? changeDateFormat(payments[0]) : '-';
              paymentDataInner.Pay_Status = payments[1] ? payments[1] : '-';
              paymentDataInner.Final_Settlement = payments[2] ? payments[2] : 0;
              paymentDataInner.Compensation = payments[3] ? payments[3] : '-';
              paymentDataInner.Claims = payments[4] ? payments[4] : '-';
              paymentDataInner.Return_Charge = payments[5] ? payments[5] : '-';
              paymentDataInner.Recovery = payments[6] ? payments[6] : '-';
              data.paymentData.push(paymentDataInner);
            });
            data.ticketData = [];
            value[32].forEach((tickets) => {
              const ticketDataInner = {};
              ticketDataInner.Ticket_Id = tickets[0] ? tickets[0] : '-';
              ticketDataInner.Sub_Order_No = tickets[1] ? tickets[1] : '-';
              ticketDataInner.Reported_Date = tickets[2] ? changeDateFormat(tickets[2]) : '-';
              ticketDataInner.Is_Resolved = tickets[3] ? changeDataFormat(tickets[3]) : '-';
              ticketDataInner.Report_Type = tickets[4] ? tickets[4] : '-';
              ticketDataInner.Last_Update = tickets[5] ? tickets[5] : '-';
              ticketDataInner.Can_Reopen = tickets[6] ? tickets[6] : '-';
              ticketDataInner.Ticket_Status = tickets[6] ? tickets[6] : '-';
              ticketDataInner.Reopen_Date = tickets[6] ? changeDateFormat(tickets[6]) : '-';
              data.ticketData.push(ticketDataInner);
            });
            data.isCustomerCredited = value[33];

            if (!refSubOrders.includes(data.sub_order_no)) {
              supplierData.push(data);
              refSubOrders.push(data.sub_order_no);
            }
          });
          supplierTempData.supplierData = supplierData;
          supplier.push(supplierTempData);
        });
        accountData.accountData = supplier;
        account.push(accountData);
      });
      platformData.platformData = account;
      formattedData.push(platformData);
    });
    if (formattedData.length > 0) {
      formattedData.forEach((item) => {
        item.platformData.forEach((platformData) => {
          platformData.accountData.forEach((accountData) => {
            accountData.supplierData.forEach((supData) => {
              const tempData = {};
              tempData.sub_order_no = supData.sub_order_no ? supData.sub_order_no : ' - ';
              tempData.type = item.platformName ? item.platformName : ' - ';
              if (tempData.type === 'glowroad') {
                tempData.supplier_id = accountData.supplierId ? accountData.supplierId.split('@')[0] : ' - ';
              } else {
                tempData.supplier_id = accountData.supplierId ? accountData.supplierId : ' - ';
              }
              tempData.pending_reported = supData.pending_reported ? supData.pending_reported : ' - ';
              tempData.received_reported = supData.received_reported ? supData.received_reported.toString() : 'false';
              tempData.pod_reported = supData.pod_reported ? supData.pod_reported : ' - ';
              tempData.payment_reported = supData.payment_reported ? supData.payment_reported : ' - ';
              tempData.retry_attempt = supData.retry_attempt ? supData.retry_attempt : '';
              tempData.net_amount = supData.net_amount ? supData.net_amount : ' - ';
              tempData.sent_awb = supData.latest_sent_awb ? supData.latest_sent_awb : ' - ';
              tempData.order_date = supData.order_date ? supData.order_date.toString() : ' - ';
              tempData.invoice_date = supData.invoice_date ? supData.invoice_date.toString() : ' - ';
              tempData.pick_up_date = supData.pick_up_date ? supData.pick_up_date.toString() : ' - ';
              tempData.self_cost = supData.self_cost ? supData.self_cost : ' - ';
              tempData.shipment_type = supData.shipment_type ? supData.shipment_type : ' - ';
              tempData.is_cancelled = supData.is_cancelled ? supData.is_cancelled : 'false';
              tempData.isCustomerCredited = supData.isCustomerCredited ? supData.isCustomerCredited : 'false';
              tempData.sku = supData.sku ? supData.sku : 'NA';
              tempData.sent_quantity = supData.sent_quantity ? supData.sent_quantity.toString() : '0';
              tempData.size = supData.size ? supData.size : 'NA';
              tempData.return_charge = supData.return_Charge ? supData.return_Charge : ' - ';
              tempData.latest_Return_Awb = supData.latest_Return_Awb ? supData.latest_Return_Awb : ' - ';
              const ReturnTypeMapping = {
                customer_return: 'Customer Return',
                'Customer Return': 'Customer Return',
                RET: 'Customer Return',
                CUSTOMER_RETURN: 'Customer Return',
                courier_return: 'Courier Return (RTO)',
                'Courier Return (RTO)': 'Courier Return (RTO)',
                RTO: 'Courier Return (RTO)',
                COURIER_RETURN: 'Courier Return (RTO)',
              };

              tempData.return_type = ReturnTypeMapping[supData.return_type]
                ? ReturnTypeMapping[supData.return_type]
                : ' - ';
              tempData.order_Status = supData.order_Status ? supData.order_Status.toString() : ' - ';
              tempData.scanned_Date = supData.scanned_Date ? supData.scanned_Date.toString() : '';
              tempData.id = tempFilteredData.length + 1;
              tempData.company = companyData[item.platformName][accountData.supplierId]
                ? companyData[item.platformName][accountData.supplierId]
                : ' - ';
              tempData.skuImage = skuImageData[supData.sku] ? skuImageData[supData.sku] : '';
              tempData.delivery_partner = supData.sent_Courier ? supData.sent_Courier : '';
              tempData.email = platformData.accountId ? platformData.accountId : ' - ';
              tempData.exchange_entry =
                supData.exchange_entry.length > 0
                  ? supData.exchange_entry.map((x) => ({
                    received: x.Received ? x.Received : 'false',
                    pickupDate: x['Pickup Date'] ? x['Pickup Date'] : ' - ',
                    expectedDispatchDate: x['Expected Dispatch Date'] ? x['Expected Dispatch Date'] : ' - ',
                    receivedDate: x['Received Date'] ? x['Received Date'] : ' - ',
                    deliveryPartner: x['Delivery Partner'] ? x['Delivery Partner'] : ' - ',
                    condition: x.Condition ? x.Condition : ' - ',
                    sentQuantity: x['Sent Quantity'] ? x['Sent Quantity'] : ' - ',
                    isReported: x['Is Reported'] ? x['Is Reported'] : 'false',
                    sentAwb: x['Sent Awb'] ? x['Sent Awb'] : ' - ',
                    scannedDate: x['Scanned Date'] ? x['Scanned Date'] : ' - ',
                    Size: x.Size ? x.Size : ' - ',
                  }))
                  : [];
              tempData.exchange_entry1 = tempData.exchange_entry ? JSON.stringify(tempData.exchange_entry) : ' - ';
              tempData.return_date = supData.return_date ? supData.return_date : ' - ';
              tempData.return_quantity = supData.return_quantity ? supData.return_quantity : '0';
              tempData.return_courier = supData.return_courier ? supData.return_courier : '';
              tempData.delivery_date = supData.delivery_date ? supData.delivery_date : ' - ';
              tempData.is_received = supData.is_received ? supData.is_received : 'false';
              tempData.latest_received_date = supData.latest_received_date
                ? supData.latest_received_date.toString()
                : '';
              tempData.re_condition = supData.re_condition ? supData.re_condition : ' - ';
              tempData.rejected_date = supData.rejected_date ? supData.rejected_date.toString() : ' - ';
              tempData.bagPacketId = supData.bagPacketId ? supData.bagPacketId : ' - ';
              tempData.bagPacketScanStatus = supData.bagPacketScanStatus ? supData.bagPacketScanStatus : ' - ';
              let netAmount = 0.0;
              let payStatus = 'NOT LISTED';
              supData.paymentData.forEach((payData) => {
                netAmount += parseFloat(payData.Final_Settlement);
                if (payData.Pay_Status === 'OUTSTANDING') {
                  payStatus = 'OUTSTANDING';
                } else if (payData.Pay_Status === 'PAID' && payStatus !== 'OUTSTANDING') {
                  payStatus = 'PAID';
                }
              });
              tempData.Pay_Status = payStatus;
              supData.ticketData.forEach((ticData) => {
                // For Export Data Into Excel
                tempData.Ticket_Id = ticData.Ticket_Id ? ticData.Ticket_Id : ' - ';
                tempData.Reported_Date = ticData.Reported_Date ? ticData.Reported_Date : ' - ';
                tempData.Is_Resolved = ticData.Is_Resolved ? ticData.Is_Resolved : ' - ';
                tempData.Report_Type = ticData.Report_Type ? ticData.Report_Type : ' - ';
                tempData.Last_Update = ticData.Last_Update ? ticData.Last_Update : ' - ';
                tempData.Can_Reopen = ticData.Can_Reopen ? ticData.Can_Reopen : ' - ';
                tempData.Ticket_Status = ticData.Ticket_Status ? ticData.Ticket_Status : ' - ';
                tempData.Reopen_Date = ticData.Reopen_Date ? ticData.Reopen_Date : ' - ';
              });
              tempData.Net_Amount = netAmount;
              tempData.paymentData = supData.paymentData ? supData.paymentData : [];
              tempData.paymentDataExportExcel = tempData.paymentData ? JSON.stringify(tempData.paymentData) : ' - ';
              tempData.ticketData = supData.ticketData ? supData.ticketData : [];
              tempData.ticketDataExportExcel = tempData.ticketData ? JSON.stringify(tempData.ticketData) : ' - ';
              tempFilteredData.push(tempData);
            });
          });
        });
      });
    }
    setTempFilteredData(tempFilteredData);
  };

  //  show data on click
  const showData = () => {
    setOrderAnalysisData([]);
    setTempFilteredData([]);
    const sentCourierList = tempFilteredData.map((x) => x.delivery_partner).filter((y) => y !== '');

    setSentCourierList([...new Set(sentCourierList)].map((x) => ({ label: x, value: x })));
    const returnCourierList = tempFilteredData.map((x) => x.return_courier).filter((y) => y !== '');
    setReturnCourierList([...new Set(returnCourierList)].map((x) => ({ label: x, value: x })));

    const returnConditionList = tempFilteredData.map((x) => x.re_condition).filter((y) => y !== '');
    setReturnConditionList([...new Set(returnConditionList)].map((x) => ({ label: x, value: x })));

    setOrderAnalysisData(tempFilteredData);
    setTableActualData(tempFilteredData);
    setIsOrderReportTableLoading(false);
  };

  // get account selector options by type and account data
  useEffect(() => {
    getAccountsDataByType('all', true).then((filteredData) => {
      setUserData(filteredData);
    });
  }, []);

  // for getting date
  const getdate = (value, dateString, val) => {
    if (value) {
      setOrderAnalysisData('Loading');
      startDate = dateString[0];
      endDate = dateString[1];
      title = {};
      title = {
        start: dateString[0],
        end: dateString[1],
      };
      viewAllData(title, val);
    }
    if (startDate === '') {
      setFlag(0);
      setNodata('Please Select An Options To See Orders Analysis Details.');
      setLocalFilterCriteria({
        is_Cancelled: false,
        scanned: false,
        received: false,
        retry_attempt: false,
        notReceived: false,
        isCustomerCredited: false,
      });
      setOrderAnalysisData([]);
      setTableActualData([]);
    } else {
      setFlag(val);
    }
  };

  // on select subOrder num show data in table
  const selectSuborder = (event, value) => {
    if (value) {
      setOrderAnalysisData('Loading');
      viewAllData(value, 'suborder_no');
    } else {
      setOrderAnalysisData([]);
      setLocalFilterCriteria({
        is_Cancelled: false,
        scanned: false,
        received: false,
        retry_attempt: false,
        notReceived: false,
        isCustomerCredited: false,
      });
      setTableActualData([]);
      setNodata('Data not found.');
    }
  };

  // on select awb num show data in table
  const selectAwborder = (event, value) => {
    if (value) {
      setOrderAnalysisData('Loading');
      viewAllData(value, 'awb');
    } else {
      setOrderAnalysisData([]);
      setLocalFilterCriteria({
        is_Cancelled: false,
        scanned: false,
        received: false,
        retry_attempt: false,
        notReceived: false,
        isCustomerCredited: false,
      });
      setTableActualData([]);
      setNodata('Data not found.');
    }
  };

  const findByImage = (event, value) => {
    if (value) {
      console.log("value",value)
      setOrderAnalysisData('Loading');
      viewAllData(value, 'awb_list');
    } else {
      setOrderAnalysisData([]);
      setLocalFilterCriteria({
        is_Cancelled: false,
        scanned: false,
        received: false,
        retry_attempt: false,
        notReceived: false,
        isCustomerCredited: false,
      });
      setTableActualData([]);
      setNodata('Data not found.');
    }
  };

  // making api call to get data
  const getAccountData = async (bodyObj, emails) => {
    const response = await CreatePostAPICall(enqueueSnackbar, OrderAnalysis, bodyObj);
    if (response) {
      getFormattedData(
        response.data.body,
        response.data.companyName ? response.data.companyName : [],
        response.data.skuImageData ? response.data.skuImageData : [],
      );
      if (response.data.last_key) {
        bodyObj.last_key = response.data.last_key;
        await getAccountData(bodyObj, emails);
      }
    }
  };

  // viewing all data
  const viewAllData = async (value, sub) => {
    setTableActualData([]);
    setIsOrderReportTableLoading(true);
    let bodyObj = {};
    emails = [];
    values = [];
    cNames = [];
    accountType = [];
    refSubOrders = [];

    selectedAccountsData.forEach((item) => {
      emails.push(item.Email);
      values.push(item.value);
      cNames.push(item.cName);
      accountType.push(item.AcType);
    });

    setTempFilteredData([]);

    if (`${sub}` === 'suborder_no' || `${sub}` === 'awb' || `${sub}` === 'awb_list') {
      bodyObj = {
        username: user?.email ? user.email : localUser,
        tpEmailIds: emails,
        subAccountIds: values,
        cNames: cNames,
        acType: accountType,
      };
      bodyObj[`${sub}`] = value;

      const response = await CreatePostAPICall(enqueueSnackbar, OrderAnalysis, bodyObj);
      if (response) {
        getFormattedData(
          response.data.body,
          response.data.companyName ? response.data.companyName : [],
          response.data.skuImageData ? response.data.skuImageData : [],
        );
      }
      showData();
      setIsOrderReportTableLoading(false);
    } else {
      for (let i1 = 0; i1 < emails.length; i1++) {
        bodyObj = {
          username: user?.email ? user.email : localUser,
          tpEmailIds: [emails[i1]],
          subAccountIds: [values[i1]],
          cNames: [cNames[i1]],
          acType: [accountType[i1]],
        };

        bodyObj[`${sub}`] = value;
        await getAccountData(bodyObj, emails);
      }
      showData();
    }
  };

  // in desktop version on double click image download
  const onDoubleClickDownload = async (event, row) => {
    if (event.detail === 2 && isDesktopEnvironment) {
      const element = document.createElement('a');
      element.href = row.original?.skuImage;
      const url = row.original?.skuImage;
      const imageType = url.split('/').at(-1).split('.').at(-1);
      element.download = `${row.original?.skuImage}.${imageType}`;
      element.click();
    }
  };

  // For inner table theme
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  // Tickets Information Tables Column....
  const tktColumns = [
    {
      accessorKey: 'Ticket_Id',
      header: 'Ticket Id',
    },
    {
      accessorKey: 'Sub_Order_No',
      header: 'Sub Order No',
    },
    {
      accessorKey: 'Reported_Date',
      header: 'Reported Date',
    },
    {
      accessorKey: 'Is_Resolved',
      header: 'Is Resolved',
    },
    {
      accessorKey: 'Report_Type',
      header: 'Report Type',
    },
    {
      accessorKey: 'Can_Reopen',
      header: 'Can Re-Open',
    },
    {
      accessorKey: 'Reopen_Date',
      header: 'Reopen Date',
    },
  ];

  // expanded table data for ticket table
  const expandRow = (row) => (
    <div style={{ display: 'flex' }}>
      <Typography sx={{ pl: 2 }} variant="h6">
        Last Update :{' '}
      </Typography>
      <div style={{ padding: '4px 16px 10px 10px' }}>{row.original.Last_Update}</div>
    </div>
  );


  // main table expanded data
  const expandRowComponent = (row) => {
    return <div>
      <Grid sx={{ p: 2 }} container spacing={3}>
        {row.original?.skuImage ? (
          <Grid item xs={2} sm={2} md={2}>
            <div style={{ height: '150px', width: '150px' }}>
              <Image
                src={row.original?.skuImage}
                ratio="1/1"
                onDoubleClick={(event) => {
                  onDoubleClickDownload(event, row);
                }}
              />
            </div>
          </Grid>
        ) : null}
        <Grid
          item
          xs={row.original?.skuImage ? 10 : 12}
          sm={row.original?.skuImage ? 10 : 12}
          md={row.original?.skuImage ? 10 : 12}
        >
          <Box
            sx={{
              display: 'grid',
              gridAutoFlow: 'row',
              gridTemplateColumns: 'repeat(3, 1fr)',
              gridTemplateRows: 'auto',
              p: 2,
              marginTop: '-24px',
            }}
          >
            <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
              <b style={{ marginRight: '2px' }}>Email :</b>
              <div style={{ marginLeft: '2px' }}> {row.original?.email}</div>
            </div>
            <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
              <b style={{ marginRight: '2px' }}>Company :</b>{' '}
              <div style={{ marginLeft: '2px' }}> {row.original?.company}</div>
            </div>
            <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
              <b style={{ marginRight: '2px' }}>Supplier Id :</b>{' '}
              <div style={{ marginLeft: '2px' }}> {row.original?.supplier_id}</div>
            </div>
            <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
              <b style={{ marginRight: '2px' }}>Sub Order Number :</b>
              <div style={{ marginLeft: '2px' }}>
                {row.original?.sub_order_no === '' ? ' - ' : row.original?.sub_order_no}
              </div>
            </div>
            <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
              <b style={{ marginRight: '2px' }}>Sent AWB :</b>
              <div
                style={{ marginLeft: '2px' }}>{row.original?.sent_awb === '' ? ' - ' : row.original?.sent_awb}</div>
            </div>
            <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
              <b style={{ marginRight: '2px' }}>Shipment Type :</b>
              <div style={{ marginLeft: '2px' }}>
                {row.original?.shipment_type === '' ? ' - ' : row.original?.shipment_type}
              </div>
            </div>
            <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
              <b style={{ marginRight: '2px' }}>Type :</b>
              <div
                style={{ marginLeft: '2px' }}>{row.original?.type === '' ? ' - ' : row.original?.type}</div>
            </div>
            <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
              <b style={{ marginRight: '2px' }}>Order Date :</b>
              <div style={{ marginLeft: '2px' }}>
                {' '}
                {fullDateShow(row.original?.order_date, localFilterCriteria.isShowFullDate)}
              </div>
            </div>
            <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
              <b style={{ marginRight: '2px' }}>Invoice Date :</b>
              <div style={{ marginLeft: '2px' }}>
                {fullDateShow(row.original?.invoice_date, localFilterCriteria.isShowFullDate)}
              </div>
            </div>
            <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
              <b style={{ marginRight: '2px' }}>Pickup Date :</b>
              <div style={{ marginLeft: '2px' }}>
                {fullDateShow(row.original?.pick_up_date, localFilterCriteria.isShowFullDate)}
              </div>
            </div>
            <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
              <b style={{ marginRight: '2px' }}>Sent Courier :</b>
              <div style={{ marginLeft: '2px' }}>
                {row.original?.delivery_partner === '' ? ' - ' : row.original?.delivery_partner}
              </div>
            </div>
            <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
              <b style={{ marginRight: '2px' }}>SKU :</b>
              <div
                style={{ marginLeft: '2px' }}>{row.original?.sku === '' ? ' - ' : row.original?.sku}</div>
            </div>
            <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
              <b style={{ marginRight: '2px' }}>Size :</b>
              <div
                style={{ marginLeft: '2px' }}>{row.original?.size === '' ? ' - ' : row.original?.size}</div>
            </div>
            <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
              <b style={{ marginRight: '2px' }}>Sent Quantity :</b>
              <div style={{ marginLeft: '2px' }}>
                {row.original?.sent_quantity === '' ? ' - ' : row.original?.sent_quantity}
              </div>
            </div>
            <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
              <b style={{ marginRight: '2px' }}>Self Cost :</b>
              <div style={{ marginLeft: '2px' }}>
                {row.original?.self_cost === '' ? ' - ' : row.original?.self_cost}
              </div>
            </div>
            <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
              <b style={{ marginRight: '2px' }}>PickUp Scan Status :</b>
              <div style={{ marginLeft: '2px' }}>
                {row.original?.scanned_Date && row.original?.scanned_Date !== ''
                  ? changeDataFormat(true)
                  : changeDataFormat(false)}{' '}
              </div>
            </div>
            <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
              <b style={{ marginRight: '2px' }}>Is Cancelled :</b>
              <div style={{ marginLeft: '2px' }}>{changeDataFormat(row.original?.is_cancelled)}</div>
            </div>
            <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
              <b style={{ marginRight: '2px' }}>Scanned Date :</b>
              <div style={{ marginLeft: '2px' }}>
                {' '}
                {fullDateShow(row.original?.scanned_Date, localFilterCriteria.isShowFullDate)}
              </div>
            </div>
            <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
              <b style={{ marginRight: '2px' }}>Retry Attempt:</b>
              <div style={{ marginLeft: '2px' }}>
                {row.original?.retry_attempt === '' ? ' - ' : row.original?.retry_attempt}
              </div>
            </div>
            <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
              <b style={{ marginRight: '2px' }}>Order Status:</b>
              <div style={{ marginLeft: '2px' }}>
                {row.original?.order_Status === '' ? ' - ' : row.original?.order_Status}
              </div>
            </div>
            <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
              <b style={{ marginRight: '2px' }}>Is Customer Credited:</b>
              <div style={{ marginLeft: '2px' }}>{changeDataFormat(row.original?.isCustomerCredited)}</div>
            </div>

            <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
              <b style={{ marginRight: '2px' }}>Bag Packet Id:</b>
              <div style={{ marginLeft: '2px' }}>{row.original?.bagPacketId}</div>
            </div>

            <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
              <b style={{ marginRight: '2px' }}>Bag Packet Scan Status:</b>
              <div style={{ marginLeft: '2px' }}>{row.original?.bagPacketScanStatus}</div>
            </div>


            <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
              <b style={{ marginRight: '2px' }}>Shipment Id:</b>
              <div style={{ marginLeft: '2px' }}>{row.original?.shipmentId}</div>
            </div>

            {row.original?.type === 'meesho' ? (
              <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
                <b style={{ marginRight: '2px', marginTop: '12px' }}>Download Manifest:</b>
                <div style={{ marginLeft: '2px', marginBottom: '-10px' }}>
                  <IconButton
                    onClick={async () => {
                      const isPDf = true;
                      const date = row.original?.pick_up_date?.split('T')[0];
                      const file = `${user?.email}/${row.original?.email}/${row.original?.supplier_id}/Manifest_${date.replaceAll("-","_")}.pdf`;
                      await downloadS3File(file, isPDf, UPLOAD_MANIFEST, enqueueSnackbar);
                    }}
                  >
                    <Iconify icon={'vscode-icons:file-type-pdf2'} width={30} height={30} />
                  </IconButton>
                </div>
              </div>
            ) : null}
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ borderStyle: 'dashed' }} />
      <Typography sx={{ pl: 2, pt: 2 }} variant="h6">
        Payment Info
      </Typography>
      {row.original?.paymentData?.length > 0 ? (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell rowSpan={2} align="center">
                  Payment Status
                </StyledTableCell>
                <StyledTableCell rowSpan={2} align="center">
                  Payment Date
                </StyledTableCell>
                <StyledTableCell rowSpan={2} align="center">
                  Final Settlement
                </StyledTableCell>
                <StyledTableCell rowSpan={2} align="center">
                  Penalty/Recovery
                </StyledTableCell>
                <StyledTableCell rowSpan={2} align="center">
                  Return Charge
                </StyledTableCell>
                <StyledTableCell rowSpan={2} align="center">
                  Compensation/Claim/Waiver
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {row.original?.paymentData.map((rowPaymentData) => (
                <StyledTableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  key={rowPaymentData.Payment_Date}
                >
                  <StyledTableCell align="center">
                    {rowPaymentData.Pay_Status === undefined ? ' - ' : rowPaymentData.Pay_Status}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {fullDateShow(row.original?.Payment_Date, localFilterCriteria.isShowFullDate)}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {rowPaymentData.Final_Settlement === '' ? ' - ' : rowPaymentData.Final_Settlement}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {rowPaymentData.Recovery === '' ? ' - ' : rowPaymentData.Recovery}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {rowPaymentData.Return_Charge === '' ? ' - ' : rowPaymentData.Return_Charge}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {rowPaymentData.Claims === '' ? ' - ' : rowPaymentData.Claims}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
              <StyledTableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <StyledTableCell align="center">-</StyledTableCell>
                <StyledTableCell align="center">-</StyledTableCell>
                <StyledTableCell align="center">{row.original.Net_Amount}</StyledTableCell>
                <StyledTableCell align="center">-</StyledTableCell>
                <StyledTableCell align="center">-</StyledTableCell>
                <StyledTableCell align="center">-</StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <>
          <Box
            sx={{
              display: 'grid',
              gridAutoFlow: 'row',
              gridTemplateColumns: 'repeat(3, 1fr)',
              gridTemplateRows: 'auto',
            }}
          >
            <h4></h4>
            <h3>-</h3>
            <h4></h4>
          </Box>
        </>
      )}
      <Divider sx={{ borderStyle: 'dashed' }} />
      <Typography sx={{ pl: 2, pt: 2 }} variant="h6">
        Exchange Entry
      </Typography>
      {row.original?.exchange_entry?.length > 0 ? (
        <>
          <Box
            sx={{
              display: 'grid',
              gridAutoFlow: 'row',
              gridTemplateColumns: 'repeat(3, 1fr)',
              gridTemplateRows: 'auto',
              p: 2,
            }}
          >
            <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
              <b style={{ marginRight: '2px' }}>Pickup Date :</b>
              <div style={{ marginLeft: '2px' }}>
                {fullDateShow(row.original?.exchange_entry[0]?.pickupDate, localFilterCriteria.isShowFullDate)}
              </div>
            </div>
            <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
              <b style={{ marginRight: '2px' }}>Expected Dispatch Date :</b>
              <div style={{ marginLeft: '2px' }}>
                {fullDateShow(row.original?.exchange_entry[0]?.expectedDispatchDate, localFilterCriteria.isShowFullDate)}

              </div>
            </div>
            <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
              <b style={{ marginRight: '2px' }}>Sent Awb :</b>
              <div style={{ marginLeft: '2px' }}>
                {fullDateShow(row.original?.exchange_entry[0]?.sentAwb, localFilterCriteria.isShowFullDate)}
              </div>
            </div>
            <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
              <b style={{ marginRight: '2px' }}>Sent Quantity :</b>
              <div style={{ marginLeft: '2px' }}>
                {row.original?.exchange_entry[0]?.sentQuantity === ''
                  ? ' - '
                  : row.original?.exchange_entry[0]?.sentQuantity}
              </div>
            </div>
            <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
              <b style={{ marginRight: '2px' }}>Size :</b>
              <div style={{ marginLeft: '2px' }}>
                {row.original?.exchange_entry[0]?.Size === '' ? ' - ' : row.original?.exchange_entry[0]?.Size}
              </div>
            </div>
            <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
              <b style={{ marginRight: '2px' }}>Scanned Date :</b>
              <div style={{ marginLeft: '2px' }}>
                {fullDateShow(row.original?.exchange_entry[0]?.scannedDate, localFilterCriteria.isShowFullDate)}

              </div>
            </div>
            <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
              <b style={{ marginRight: '2px' }}>Received :</b>
              <div
                style={{ marginLeft: '2px' }}>{changeDataFormat(row.original?.exchange_entry[0]?.received)}</div>
            </div>
            <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
              <b style={{ marginRight: '2px' }}>Received Date :</b>
              <div style={{ marginLeft: '2px' }}>
                {fullDateShow(row.original?.exchange_entry[0]?.receivedDate, localFilterCriteria.isShowFullDate)}
              </div>
            </div>
            <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
              <b style={{ marginRight: '2px' }}>Delivery Partner :</b>
              <div style={{ marginLeft: '2px' }}>
                {row.original?.exchange_entry[0]?.deliveryPartner === ''
                  ? ' - '
                  : row.original?.exchange_entry[0]?.deliveryPartner}
              </div>
            </div>
            <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
              <b style={{ marginRight: '2px' }}>Condition :</b>
              <div style={{ marginLeft: '2px' }}>
                {row.original?.exchange_entry[0]?.condition === '' ? ' - ' : row.original?.exchange_entry[0]?.condition}
              </div>
            </div>
            <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
              <b style={{ marginRight: '2px' }}>Is Reported :</b>
              <div
                style={{ marginLeft: '2px' }}>{changeDataFormat(row.original?.exchange_entry[0]?.isReported)}</div>
            </div>
          </Box>
        </>
      ) : (
        <>
          <Box
            sx={{
              display: 'grid',
              gridAutoFlow: 'row',
              gridTemplateColumns: 'repeat(3, 1fr)',
              gridTemplateRows: 'auto',
            }}
          >
            <h4></h4>
            <h3>-</h3>
            <h4></h4>
          </Box>
        </>
      )}

      <Divider sx={{ borderStyle: 'dashed' }} />
      <Typography sx={{ pl: 2, pt: 2 }} variant="h6">
        Return Info
      </Typography>
      <Box
        sx={{
          display: 'grid',
          gridAutoFlow: 'row',
          gridTemplateColumns: 'repeat(3, 1fr)',
          gridTemplateRows: 'auto',
          p: 2,
        }}
      >
        <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
          <b style={{ marginRight: '2px' }}>Return Type :</b>
          <div style={{ marginLeft: '2px' }}>
            {row.original?.return_type === '' ? ' - ' : row.original?.return_type}
          </div>
        </div>
        <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
          <b style={{ marginRight: '2px' }}>Return AWB :</b>
          <div style={{ marginLeft: '2px' }}>
            {row.original?.latest_Return_Awb === '' ? ' - ' : row.original?.latest_Return_Awb}
          </div>
        </div>
        <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
          <b style={{ marginRight: '2px' }}>Return Date :</b>
          <div style={{ marginLeft: '2px' }}>
            {fullDateShow(row.original?.return_date, localFilterCriteria.isShowFullDate)}

          </div>
        </div>
        <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
          <b style={{ marginRight: '2px' }}>Return Courier Partner :</b>
          <div style={{ marginLeft: '2px' }}>
            {row.original?.return_courier === '' ? ' - ' : row.original?.return_courier}
          </div>
        </div>
        <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
          <b style={{ marginRight: '2px' }}>Return Quantity :</b>
          <div style={{ marginLeft: '2px' }}>
            {row.original?.return_quantity === '' ? ' - ' : row.original?.return_quantity}
          </div>
        </div>
        <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
          <b style={{ marginRight: '2px' }}>Delivered Date :</b>
          <div style={{ marginLeft: '2px' }}>
            {fullDateShow(row.original?.delivery_date, localFilterCriteria.isShowFullDate)}

          </div>
        </div>
        <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
          <b style={{ marginRight: '2px' }}>Is Received :</b>
          <div style={{ marginLeft: '2px' }}>{changeDataFormat(row.original?.is_received)} </div>
        </div>
        <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
          <b style={{ marginRight: '2px' }}>Return Condition :</b>
          <div style={{ marginLeft: '2px' }}>
            {row.original?.re_condition === '' ? ' - ' : row.original?.re_condition}
          </div>
        </div>
        <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
          <b style={{ marginRight: '2px' }}>Return Charge :</b>
          <div style={{ marginLeft: '2px' }}>
            {row.original?.return_charge === '' ? ' - ' : row.original?.return_charge}
          </div>
        </div>
        <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
          <b style={{ marginRight: '2px' }}>Latest Received Date:</b>
          <div style={{ marginLeft: '2px' }}>
            {fullDateShow(row.original?.latest_received_date, localFilterCriteria.isShowFullDate)}

          </div>
        </div>
        <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
          <b style={{ marginRight: '2px' }}>Latest Rejected Date:</b>
          <div style={{ marginLeft: '2px' }}>
            {fullDateShow(row.original?.rejected_date, localFilterCriteria.isShowFullDate)}
          </div>
        </div>
      </Box>
      <Divider sx={{ borderStyle: 'dashed' }} />
      <Typography sx={{ pl: 2, pt: 2 }} variant="h6">
        Claim Issues
      </Typography>
      <Box
        sx={{
          display: 'grid',
          gridAutoFlow: 'row',
          gridTemplateColumns: 'repeat(3, 1fr)',
          gridTemplateRows: 'auto',
          p: 2,
        }}
      >
        <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
          <b style={{ marginRight: '2px' }}>Pending Reported :</b>
          <div style={{ marginLeft: '2px' }}>{changeDataFormat(row.original?.pending_reported)} </div>
        </div>
        <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
          <b style={{ marginRight: '2px' }}>POD Reported :</b>
          <div style={{ marginLeft: '2px' }}>{changeDataFormat(row.original?.pod_reported)} </div>
        </div>
        <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
          <b style={{ marginRight: '2px' }}>Received Reported :</b>
          <div style={{ marginLeft: '2px' }}>{changeDataFormat(row.original?.received_reported)} </div>
        </div>
        <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
          <b style={{ marginRight: '2px' }}>Payment Reported :</b>
          <div style={{ marginLeft: '2px' }}>{changeDataFormat(row.original?.payment_reported)} </div>
        </div>
      </Box>
      <Divider sx={{ borderStyle: 'dashed' }} />
      <Typography sx={{ pl: 2, pt: 2 }} variant="h6">
        Ticket Info
      </Typography>
      <>
        {row.original?.ticketData?.length > 0 ? (
          <>
            <br />
            <DataGridTable
              columns={tktColumns}
              data={row.original?.ticketData}
              renderDetailPanel={expandRow}
              expand={'true'}
              enablePagination={false}
            />
          </>
        ) : (
          <>
            <Box
              sx={{
                display: 'grid',
                gridAutoFlow: 'row',
                gridTemplateColumns: 'repeat(3, 1fr)',
                gridTemplateRows: 'auto',
              }}
            >
              <h4></h4>
              <h3>-</h3>
              <h4></h4>
            </Box>
            <Divider sx={{ borderStyle: 'dashed' }} />
          </>
        )}
      </>
    </div>;
  };

  // filter handler
  if (name == 'received' || 'notReceived') {

  }
  const onLocalFilterChkChange = (event, name) => {
    setLocalFilterCriteria({


      ...localFilterCriteria,


      [name]: event.target.checked,
    });
  };

  // clear all filter handle
  const handleClearAllFilter = () => {
    setLocalFilterCriteria({
      is_Cancelled: false,
      scanned: false,
      received: false,
      retry_attempt: false,
      notReceived: false,
      isCustomerCredited: false,
      isShowFullDate: false,
    });
    setFilterRTNCourier([]);
    setFilterRTNCondition([]);
    setFilterSNTCourier([]);
    setFilterRTNType([]);
    setFilterODRStatus([]);
  };

  function formatDate(inputDate) {
    const parsedDate = moment(inputDate, 'DD HH:mm:ss.SSSSSSZ');
    return parsedDate.format('DD-MM-YYYY HH:mm:ss');
  }

  // export excel
  const handleExportExcel = () => {

    var flipCartList = [
      { label: 'Number', value: 'id' },
      { label: 'Platform', value: 'type' },
      { label: 'Email', value: 'email' },
      { label: 'Company', value: 'company' },
      { label: 'Sub Order No', value: 'sub_order_no' },
      { label: 'Order Date', value: 'order_date' },
      { label: 'Invoice Date', value: 'invoice_date' },
      { label: 'Pickup Date', value: 'pick_up_date' },
      { label: 'Is Cancelled', value: 'is_cancelled' },
      { label: 'SKU', value: 'sku' },
      { label: 'Size', value: 'size' },
      { label: 'Self Cost', value: 'self_cost' },
      { label: 'Sent Quantity', value: 'sent_quantity' },
      { label: 'Sent AWB', value: 'sent_awb' },
      // { label: 'Bag Link', value: 'bagPacketId' },
      // { label: 'Bag Link Status', value: 'bagPacketScanStatus' },
      { label: 'Delivery Partner', value: 'delivery_partner' },
      { label: 'Label Scanned Date', value: 'scanned_Date' },
      { label: 'Retry Attempt', value: 'retry_attempt' },
      { label: 'Shipment Type', value: 'shipment_type' },
      // { label: 'Exchange Entry', value: 'exchange_entry1' },
      { label: 'Create Return Date', value: 'return_date' },
      { label: 'Return Type', value: 'return_type' },
      { label: 'Order Status', value: 'order_Status' },
      { label: 'Return Quantity', value: 'return_quantity' },
      { label: 'Return Courier', value: 'return_courier' },
      { label: 'Latest Return AWB', value: 'latest_Return_Awb' },
      { label: 'Delivery Date', value: 'delivery_date' },
      { label: 'Return Received', value: 'is_received' },
      { label: 'Latest Received Date', value: 'latest_received_date' },
      { label: 'Re Condition', value: 're_condition' },
      { label: 'Pending Reported', value: 'pending_reported' },
      { label: 'Received Reported', value: 'received_reported' },
      { label: 'POD Reported', value: 'pod_reported' },
      { label: 'Payment Reported', value: 'payment_reported' },
      { label: 'Return Charge', value: 'Return_Charge' },
      { label: 'Payment Data', value: 'paymentDataExportExcel' },
      // { label: 'Supplier Id', value: 'supplier_id' },
      { label: 'Is Customer Credited', value: 'isCustomerCredited' },
      { label: 'Shipment Id', value: 'shipmentId' },
      // { label: 'Ticket Data', value: 'ticketDataExportExcel' },

      // TODO FOR THIS DATA'S YOU NEED EXPORT MORE THAN 1 DATA

      // {label: "Payment Status", value: "Pay_Status"},
      // {label: "Payment Date", value: "Payment_Date"},
      // {label: "Final Settlement", value: "Final_Settlement"},
      // {label: "Net Amount", value: "Net_Amount"},
      // {label: "Penalty/Recovery", value: "Recovery"},
      // {label: "Compensation/Claim/Waiver", value: "Claims"},
      // {label: "Return Charge", value: "Return_Charge"},

      // {label: "Ticket Id", value: "Ticket_Id"},
      // {label: "Reported Date", value: "Reported_Date"},
      // {label: "Is Resolved", value: "Is_Resolved"},
      // {label: "Report Type", value: "Report_Type"},
      // {label: "Can Reopen", value: "Can_Reopen"},
      // {label: "Ticket Status", value: "Ticket_Status"},
      // {label: "Reopen Date", value: "Reopen_Date"},
      // {label: "Last Update", value: "Last_Update"},
    ];

    const data = [
      {
        sheet: 'filteredTableData',
        columns: isAllAcFlipkart ? flipCartList : [
          { label: 'Number', value: 'id' },
          { label: 'Platform', value: 'type' },
          { label: 'Email', value: 'email' },
          { label: 'Supplier Id', value: 'supplier_id' },
          { label: 'Company', value: 'company' },
          { label: 'Sub Order No', value: 'sub_order_no' },
          { label: 'Order Date', value: 'order_date' },
          { label: 'Invoice Date', value: 'invoice_date' },
          { label: 'Pickup Date', value: 'pick_up_date' },
          { label: 'Is Cancelled', value: 'is_cancelled' },
          { label: 'SKU', value: 'sku' },
          { label: 'Size', value: 'size' },
          { label: 'Self Cost', value: 'self_cost' },
          { label: 'Sent Quantity', value: 'sent_quantity' },
          { label: 'Sent AWB', value: 'sent_awb' },
          { label: 'Bag Link', value: 'bagPacketId' },
          { label: 'Bag Link Status', value: 'bagPacketScanStatus' },
          { label: 'Delivery Partner', value: 'delivery_partner' },
          { label: 'Label Scanned Date', value: 'scanned_Date' },
          { label: 'Retry Attempt', value: 'retry_attempt' },
          { label: 'Shipment Type', value: 'shipment_type' },
          { label: 'Exchange Entry', value: 'exchange_entry1' },
          { label: 'Create Return Date', value: 'return_date' },
          { label: 'Return Type', value: 'return_type' },
          { label: 'Order Status', value: 'order_Status' },
          { label: 'Return Quantity', value: 'return_quantity' },
          { label: 'Return Courier', value: 'return_courier' },
          { label: 'Latest Return AWB', value: 'latest_Return_Awb' },
          { label: 'Delivery Date', value: 'delivery_date' },
          { label: 'Return Received', value: 'is_received' },
          { label: 'Is Customer Credited', value: 'isCustomerCredited' },
          { label: 'Latest Received Date', value: 'latest_received_date' },
          { label: 'Re Condition', value: 're_condition' },
          { label: 'Return Charge', value: 'return_charge' },
          { label: 'Pending Reported', value: 'pending_reported' },
          { label: 'Received Reported', value: 'received_reported' },
          { label: 'POD Reported', value: 'pod_reported' },
          { label: 'Payment Reported', value: 'payment_reported' },
          { label: 'Return Charge', value: 'Return_Charge' },
          { label: 'Payment Data', value: 'paymentDataExportExcel' },
          { label: 'Ticket Data', value: 'ticketDataExportExcel' },
          { label: 'Shipment Id', value: 'shipmentId' },
        ],
        content: orderAnalysisData.map((value) => {
          for (const key of ['order_date', 'pick_up_date', 'invoice_date', 'scanned_Date', 'return_date', 'delivery_date', 'latest_received_date']) {
              const timeSplit = value[key].split('T');
              if (timeSplit.length > 1) {
                if (timeSplit[1].split('+')[0].includes('.')) {
                  value[key] = timeSplit[0].split('-').reverse().join('-') + ' ' + timeSplit[1].split('+')[0].split('.')[0];
                } else {
                  value[key] = timeSplit[0].split('-').reverse().join('-') + ' ' + timeSplit[1].split('+')[0];
                }
              } else {
                value[key] = timeSplit[0].split('-').reverse().join('-');
              }
              if (value[key].includes('+05:30-')) {
                  const dateParts = value[key].split('+05:30-')[1].split(' ')[0].split('-');
                  const reversedDate = dateParts[1] + '-' + dateParts[0];
                  const formattedDate = reversedDate + '-' + value[key].split(' ')[0];
                  // console.log(formattedDate);
                  const dateFormated = formattedDate.split('-').reverse().join('-')
                  const time = value[key].split('+05:30-')[0].split('.')[0].split(' ')[1];
                  value[key] = dateFormated + ' ' + time;
              }
          }

          if (value['paymentData']) {
            value.Return_Charge = value['paymentData'].reduce((sum, paymentData) => {
              let returnCharge = paymentData['Return_Charge'];
              if (typeof returnCharge === 'number') {
                return sum + returnCharge;
              }
              return sum;
            }, 0);
          } else {
            value.Return_Charge = '-';
          }

          value.is_received = value.is_received.toLowerCase() === 'true' ? 'Yes' : 'No';
          return value;
        }),
      },
    ];

    // console.log('Excel Data ==> ', data[0]['content']);
    const settings = {
      fileName: `Filtered Table Data ${GetTodayDateTime()}`,
      extraLength: 6,
      writeOptions: {},
    };

    xlsx(data, settings);
  };

  // for getting sku wise report
  const getSkuWiseData = (data) => {
    const skuReportData = [];
    const skuList = data.map((x) => x.sku);
    const uniqueSkuList = [...new Set(skuList)];
    uniqueSkuList.forEach((item) => {
      const tempRecordData = {};
      const skuFilteredData = data.filter((x) => x.sku === item);
      tempRecordData.sku = item;

      const temSizeData = [];
      const tempRecordSize = [];
      skuFilteredData.forEach((x) => {
        if (!temSizeData.includes(x.size)) {
          tempRecordData.skuImage = x.skuImage;
          temSizeData.push(x.size);
        }
      });
      temSizeData.forEach((x) => {
        let quantity = 0;
        const recordQuantity = {};
        const sizeData = skuFilteredData.filter((y) => y.size === x);
        recordQuantity.size = x;
        sizeData.forEach((qt) => {
          quantity += +qt.sent_quantity;
        });
        recordQuantity.quantity = quantity;
        tempRecordSize.push(recordQuantity);
      });
      tempRecordData.sizeData = tempRecordSize;
      skuReportData.push(tempRecordData);
    });
    return skuReportData;
  };

  // image url to Base64 to download image in desktop
  const imageUrlToBase64 = async (url) => {
    const response = await fetch(url);
    const blob = await response.blob();
    return new Promise((onSuccess, onError) => {
      try {
        const reader = new FileReader();
        reader.onload = function() {
          const dataUrl = reader.result;
          onSuccess(dataUrl);
        };
        reader.readAsDataURL(blob);
      } catch (e) {
        onError(e);
      }
    });
  };

  // export excel for sku report
  const exportExcelFile = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet('My Sheet');
    {
      isDesktopEnvironment ? (sheet.properties.defaultRowHeight = 100) : null;
    }
    {
      isDesktopEnvironment
        ? (sheet.columns = [
          {
            header: 'SKU',
            key: 'sku',
          },
          {
            header: 'Size',
            key: 'size',
          },
          {
            header: 'Quantity',
            key: 'quantity',
          },
          {
            header: 'SKU Image',
            key: 'skuImage',
          },
        ])
        : (sheet.columns = [
          {
            header: 'SKU',
            key: 'sku',
          },
          {
            header: 'Size',
            key: 'size',
          },
          {
            header: 'Quantity',
            key: 'quantity',
          },
        ]);
    }
    const skuWiseData = [];
    if (skuReportData.length > 0) {
      const skuList = [];
      skuReportData.forEach((x) => {
        x.sizeData.forEach((item) => {
          const tempData = {};
          if (!skuList.includes(x.sku)) {
            tempData.sku = x.sku;
          } else {
            tempData.sku = '';
          }
          tempData.size = item.size;
          tempData.quantity = item.quantity;
          tempData.skuImage = x.skuImage;
          skuList.push(x.sku);
          skuWiseData.push(tempData);
        });
      });
    }

    const promise = Promise.all(
      skuWiseData?.map(async (sku, index) => {
        const rowNumber = index + 1;
        sheet.addRow({
          sku: sku?.sku,
          size: sku?.size,
          quantity: sku?.quantity,
        });

        if (isDesktopEnvironment) {
          if (sku?.skuImage) {
            const result = await imageUrlToBase64(sku.skuImage);

            const splitted = sku?.skuImage.split('.');
            const extName = splitted[splitted.length - 1];

            const imageId2 = workbook.addImage({
              base64: result,
              extension: extName,
            });

            sheet.addImage(imageId2, {
              tl: { col: 3, row: rowNumber },
              ext: { width: 100, height: 100 },
            });
          }
        }
      }),
    );

    promise.then(() => {
      workbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = 'SKU Wise Report.xlsx';
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    });
  };

  // getting sku wise report data
  const getSkuWiseReportData = (data) => {
    const skuReportData = getSkuWiseData(data);
    setSkuReportData(skuReportData);
  };
  const recognizeText = async (imageList) => {
    if (imageList.length>0) {
      setImageLoadingData(true)
      setRecognizedText([])
      for(const image of imageList) {
        const imageUrl = URL.createObjectURL(image)
        const result = await Tesseract.recognize(imageUrl);
        if(result){
          const regex = /[A-Z]{4}\d{10}/g;
          setRecognizedText(oldList =>[...new Set([...oldList,...(result.data.text.toString().match(regex))])])
        }
      }
      setImageLoadingData(false)
    }
  };
  useEffect(() => {
    console.log("recognizedText",recognizedText)
  },[recognizedText])

  const maxNumberOfFiles = 10;

  function truncate(input) {
    if (input.length > 10) {
      return `${input.substring(0, 18)}...`;
    }
    return input;
  }
  const onDropImage = useCallback(
      (acceptedFiles) => {
        if (acceptedFiles.length > maxNumberOfFiles) {
          enqueueSnackbar(`Maximum ${maxNumberOfFiles} Files Only`, { variant: 'error' });
          return;
        }
        setFlag('Image')
        setImageList(acceptedFiles)
        recognizeText(acceptedFiles)
      },
      []

  );

  const removeImg  = useCallback(() => {
    setImageList([])
    setFlag(0)
    setNodata('Please Select An Options To See Orders Analysis Details.');
    setLocalFilterCriteria({
      is_Cancelled: false,
      scanned: false,
      received: false,
      retry_attempt: false,
      notReceived: false,
      isCustomerCredited: false,
    });
    setOrderAnalysisData([]);
    setTableActualData([]);
  })

  // for getting skuwise report data
  useEffect(() => {
    getSkuWiseReportData(tableActualData);
  }, [tableActualData]);

  // local filtering in table
  useEffect(() => {
    let actualData = tableActualData;

    // 1 Is Cancelled : bool
    if (localFilterCriteria.is_Cancelled) {
      actualData = actualData.filter((x) => x.is_cancelled.toLowerCase() === 'true');
    }

    // 2  Is Scanned : bool
    if (localFilterCriteria.scanned) {
      actualData = actualData.filter((x) => x.scanned_Date && x.scanned_Date !== '');
    }

    if (localFilterCriteria.not_Scanned) {
      actualData = actualData.filter((x) => !x.scanned_Date && x.scanned_Date === '');
    }

    // 3 Is received : bool
    if (localFilterCriteria.received) {
      actualData = actualData.filter((x) => x.is_received.toLowerCase() === 'true');
    }

    // 4 Not received : bool
    if (localFilterCriteria.notReceived) {
      actualData = actualData.filter((x) => x.is_received === 'false');
    }

    // 5 isCustomerCredited : bool
    if (localFilterCriteria.isCustomerCredited) {
      actualData = actualData.filter((x) => x.isCustomerCredited === 'true');
    }

    // 6  Retry Attempt : bool
    if (localFilterCriteria.retry_attempt) {
      actualData = actualData.filter((x) => x.retry_attempt && x.retry_attempt !== '');
    }

    // 7 Order Status : DD
    if (localFilterCriteria.order_Status && localFilterCriteria.order_Status !== '') {
      actualData = actualData.filter(
        (x) => x.order_Status.toLowerCase() === localFilterCriteria.order_Status.toLowerCase(),
      );
    }

    // 8 Return Type : DD
    if (localFilterCriteria.return_type && localFilterCriteria.return_type !== '') {
      actualData = actualData.filter((x) => x.return_type === localFilterCriteria.return_type);
    }

    // 9 Sent Courier : DD
    if (localFilterCriteria.sentCourier && localFilterCriteria.sentCourier !== '') {
      actualData = actualData.filter(
        (x) => x.delivery_partner.toLowerCase() === localFilterCriteria.sentCourier.toLowerCase(),
      );
    }

    // 10 Return Courier : DD
    if (localFilterCriteria.return_courier && localFilterCriteria.return_courier !== '') {
      actualData = actualData.filter(
        (x) => x.return_courier.toLowerCase() === localFilterCriteria.return_courier.toLowerCase(),
      );
    }

    // 11 Return Condition : DD
    if (localFilterCriteria.re_condition && localFilterCriteria.re_condition !== '') {
      actualData = actualData.filter((x) => x.re_condition === localFilterCriteria.re_condition);
    }

    if (isEmpty(actualData)) {
      setNodata('Data Not Found.');
    }
    if (isEmpty(actualData) && isEmpty(tableActualData)) {
      setNodata('Please Select An Options To See Orders Analysis Details.');
    }
    setOrderAnalysisData(actualData);
  }, [localFilterCriteria]);

  return (
    <>
      <Grid container direction="row" spacing={1} sx={{ paddingBottom: '15px', justifyContent: 'end' }}>
        <Grid item xs={12} sm={12} md={7}>
          <AccountSelector allData={userData} onChangeHandler={handleSelectedAccount} autoWidth />
        </Grid>
        <Grid item xs={12} sm={12} md={5}>
          <Grid item xs={12} sm={12} md={12} style={{ marginBottom: '5px' }}>
            <FormControl required sx={{ width: '100%' }}>
              <Autocomplete
                disabled={flag !== 'subOrder' && flag !== 0}
                options={subOrderData}
                onInputChange={onSubOrderOptionData}
                onChange={selectSuborder}
                renderInput={(params) => <TextField {...params} label="Sub Order Number" size="small" />}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <FormControl required sx={{ width: '100%' }}>
              <Autocomplete
                disabled={flag !== 'rtnAWB' && flag !== 0}
                options={awbData}
                onInputChange={onAwbOptionData}
                onChange={selectAwborder}
                renderInput={(params) => <TextField {...params} label="AWB Number" size="small" />}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>

      <Grid sx={{ paddingBottom: 2 }} container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <span style={{ marginRight: '9%' }}>Pickup Date:</span>
          <RangePicker
            popperPlacement="bottom"
            disabled={flag === 'pickup_date' ? 0 : flag}
            format={'DD/MM/YY'}
            onChange={(value, event) => getdate(value, event, 'pickup_date')}
            className={'createDateRangePicker'}
            pupClassName={'createDateRangePicker'}
            {...(!isLight && {
              className: 'date-picker-dark',
            })}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <span style={{ marginRight: '5%' }}>Received Date :</span>
          <RangePicker
            disabled={flag === 'received_date' ? 0 : flag}
            format={'DD/MM/YY'}
            className={'createDateRangePicker'}
            pupClassName={'createDateRangePicker'}
            onChange={(value, event) => getdate(value, event, 'received_date')}
            {...(!isLight && {
              className: 'date-picker-dark',
            })}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <span style={{ marginRight: '9%' }}>Return Date:</span>
          <RangePicker
            disabled={flag === 'return_date' ? 0 : flag}
            format={'DD/MM/YY'}
            className={'createDateRangePicker'}
            pupClassName={'createDateRangePicker'}
            onChange={(value, event) => getdate(value, event, 'return_date')}
            {...(!isLight && {
              className: 'date-picker-dark',
            })}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <span style={{ marginRight: '6%' }}>Delivery Date :</span>
          <RangePicker
            disabled={flag === 'delivery_date' ? 0 : flag}
            format={'DD/MM/YY'}
            className={'createDateRangePicker'}
            pupClassName={'createDateRangePicker'}
            onChange={(value, event) => getdate(value, event, 'delivery_date')}
            {...(!isLight && {
              className: 'date-picker-dark',
            })}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <span style={{ marginRight: '6%' }}>Payment Date : </span>
          <RangePicker
            disabled={flag === 'payment_date' ? 0 : flag}
            format={'DD/MM/YY'}
            className={'createDateRangePicker'}
            pupClassName={'createDateRangePicker'}
            onChange={(value, event) => getdate(value, event, 'payment_date')}
            {...(!isLight && {
              className: 'date-picker-dark',
            })}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <span style={{ marginRight: '7%' }}>Invoice Date : </span>
          <RangePicker
            disabled={flag === 'invoice_date' ? 0 : flag}
            format={'DD/MM/YY'}
            className={'createDateRangePicker'}
            pupClassName={'createDateRangePicker'}
            onChange={(value, event) => getdate(value, event, 'invoice_date')}
            {...(!isLight && {
              className: 'date-picker-dark',
            })}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={0}></Grid>
        <Grid item xs={4} sm={4} md={4}>
            <Dropzone onDrop={onDropImage} multiple maxSize={20000000} accept={'image/*'}>
              {({getRootProps, getInputProps}) => (
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <LoadingButton
                        variant="outlined"
                        disabled={imageLoadingData || !(flag ==="Image" || flag === 0)}
                        style={{
                          width: '-webkit-fill-available',
                          justifyContent: 'center',
                        }}
                    >
                      <Iconify icon="material-symbols:upload-rounded" width={30} height={30}/>
                      {imageLoadingData?"Loading":"Upload Image"}
                    </LoadingButton>
                  </div>
              )}
            </Dropzone>
            {!imageLoadingData && imageList.length > 0 && <div
                style={{
                  width: '-webkit-fill-available',
                  display: 'flex',
                  marginTop: '5px',
                }}
            >
              <Iconify icon="material-symbols:image-outline" width={25} height={25}/>
              <div style={{paddingLeft:10}}>{imageList.length} images, {recognizedText.length} awb no.</div>
              <Iconify
                  icon="ic:round-delete-forever"
                  width={25}
                  height={25}
                  style={{color: 'red'}}
                  onClick={() => {
                    removeImg();
                  }}
              />
            </div>}
        </Grid>
        <Grid item xs={2} sm={2} md={2}>
            <LoadingButton variant="outlined" disabled={imageLoadingData || !(flag ==="Image" || flag === 0)} style={{width: '-webkit-fill-available', justifyContent: 'center'}} onClick={(e) =>{findByImage(e,recognizedText)}}>Find</LoadingButton>
        </Grid>
      </Grid>
      <Divider />
      <br />

      {tableActualData.length > 0 ? (
        <>
          <Grid sx={{ paddingBottom: 1 }} container spacing={3}>
            <Grid item xs={6} sm={3} md={3}>
              <FormControl fullWidth>
                <Autocomplete
                  multiple
                  limitTags={2}
                  id="multiple-limit-tags"
                  options={[
                    {
                      value: 'In Transit',
                      label: 'In Transit',
                    },
                    {
                      value: 'Delivered',
                      label: 'Delivered',
                    },
                  ]}
                  value={filterODRStatus}
                  isOptionEqualToValue={(option, value) => option.label === value.label}
                  renderInput={(params) => <TextField {...params} label={'Order Status'} />}
                  onChange={(e, newValue) => {
                    setFilterODRStatus(newValue);
                    newValue.forEach((value) =>
                      setLocalFilterCriteria({ ...localFilterCriteria, order_Status: value.value }),
                    );
                    if (isEmpty(newValue)) {
                      setLocalFilterCriteria({ ...localFilterCriteria, order_Status: false });
                    }
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={3} md={3}>
              <FormControl fullWidth>
                <Autocomplete
                  multiple
                  limitTags={2}
                  id="multiple-limit-tags"
                  options={[
                    {
                      value: 'Courier Return (RTO)',
                      label: 'Courier Return (RTO)',
                    },
                    {
                      value: 'Customer Return',
                      label: 'Customer Return',
                    },
                  ]}
                  value={filterRTNType}
                  isOptionEqualToValue={(option, value) => option.label === value.label}
                  renderInput={(params) => <TextField {...params} label={'Return Type'} />}
                  onChange={(e, newValue) => {
                    setFilterRTNType(newValue);
                    newValue.forEach((value) =>
                      setLocalFilterCriteria({ ...localFilterCriteria, return_type: value.value }),
                    );
                    if (isEmpty(newValue)) {
                      setLocalFilterCriteria({ ...localFilterCriteria, return_type: false });
                    }
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={3} md={3}>
              <FormControl fullWidth>
                <Autocomplete
                  multiple
                  limitTags={2}
                  id="multiple-limit-tags"
                  options={sentCourierList}
                  isOptionEqualToValue={(option, value) => option.label === value.label}
                  value={filterSNTCourier}
                  renderInput={(params) => <TextField {...params} label={'Sent Courier'} />}
                  onChange={(e, newValue) => {
                    setFilterSNTCourier(newValue);
                    newValue.forEach((value) =>
                      setLocalFilterCriteria({ ...localFilterCriteria, sentCourier: value.value }),
                    );
                    if (isEmpty(newValue)) {
                      setLocalFilterCriteria({ ...localFilterCriteria, sentCourier: false });
                    }
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={3} md={3}>
              <FormControl fullWidth>
                <Autocomplete
                  multiple
                  limitTags={2}
                  id="multiple-limit-tags"
                  options={returnCourierList}
                  isOptionEqualToValue={(option, value) => option.label === value.label}
                  value={filterRTNCourier}
                  renderInput={(params) => <TextField {...params} label={'Return Courier'} />}
                  onChange={(e, newValue) => {
                    setFilterRTNCourier(newValue);
                    newValue.forEach((value) =>
                      setLocalFilterCriteria({
                        ...localFilterCriteria,
                        return_courier: value.value,
                      }),
                    );
                    if (isEmpty(newValue)) {
                      setLocalFilterCriteria({ ...localFilterCriteria, return_courier: false });
                    }
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={3} md={3}>
              <FormControl fullWidth>
                <Autocomplete
                  multiple
                  limitTags={2}
                  id="multiple-limit-tags"
                  options={returnConditionList}
                  isOptionEqualToValue={(option, value) => option.label === value.label}
                  value={filterRTNCondition}
                  renderInput={(params) => <TextField {...params} label={'Return Condition'} />}
                  onChange={(e, newValue) => {
                    setFilterRTNCondition(newValue);
                    newValue.forEach((value) =>
                      setLocalFilterCriteria({ ...localFilterCriteria, re_condition: value.value }),
                    );
                    if (isEmpty(newValue)) {
                      setLocalFilterCriteria({ ...localFilterCriteria, re_condition: false });
                    }
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={3}>
            <Grid item xs={6} sm={3} md={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={'is_Cancelled'}
                    checked={localFilterCriteria.is_Cancelled}
                    onChange={(e) => onLocalFilterChkChange(e, 'is_Cancelled')}
                  />
                }
                label="Is Cancelled?"
              />
            </Grid>
            <Grid item xs={6} sm={3} md={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={'scanned'}
                    checked={localFilterCriteria.scanned}
                    onChange={(e) => onLocalFilterChkChange(e, 'scanned')}
                  />
                }
                label="Scanned?"
              />
            </Grid>
            <Grid item xs={6} sm={3} md={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={'not_Scanned'}
                    checked={localFilterCriteria.not_Scanned}
                    onChange={(e) => onLocalFilterChkChange(e, 'not_Scanned')}
                  />
                }
                label="Not Scanned?"
              />
            </Grid>
            <Grid item xs={6} sm={3} md={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={'retry_attempt'}
                    checked={localFilterCriteria.retry_attempt}
                    onChange={(e) => onLocalFilterChkChange(e, 'retry_attempt')}
                  />
                }
                label="Retry Attempt?"
              />
            </Grid>
            {flag !== 'received_date' && (<>
              <Grid item xs={6} sm={3} md={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={'received'}
                      checked={localFilterCriteria.received}
                      onChange={(e) => onLocalFilterChkChange(e, 'received')}
                    />
                  }
                  label="Received?"
                />
              </Grid>
              <Grid item xs={6} sm={3} md={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={'notReceived'}
                      checked={localFilterCriteria.notReceived}
                      onChange={(e) => onLocalFilterChkChange(e, 'notReceived')}
                    />
                  }
                  label="Not Received?"
                />
              </Grid>
            </>)}
            <Grid item xs={6} sm={3} md={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={'isCustomerCredited'}
                    checked={localFilterCriteria.isCustomerCredited}
                    onChange={(e) => onLocalFilterChkChange(e, 'isCustomerCredited')}
                  />
                }
                label="Is Customer Credited?"
              />
            </Grid>
            <Grid item xs={6} sm={3} md={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={'isShowFullDate'}
                    checked={localFilterCriteria.isShowFullDate}
                    onChange={(e) => {
                      onLocalFilterChkChange(e, 'isShowFullDate');
                    }}
                  />
                }
                label="Is Full Date-Time?"
              />
            </Grid>
          </Grid>
          <div style={{ width: '100%', textAlign: 'end', paddingBottom: '20px', paddingTop: '10px' }}>
            <Button onClick={handleClearAllFilter} variant="contained" sx={{ marginRight: '1%' }}>
              Clear All Filter
            </Button>
            <Button onClick={handleExportExcel} variant="contained">
              Export Excel
            </Button>
          </div>
        </>
      ) : null}

      {orderAnalysisData.length !== 0 ? (
        <>
          <Divider />
          <Grid container direction="row" spacing={1} sx={{ paddingBottom: '15px', justifyContent: 'end' }}>
            <Grid item xs={12}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content"
                                  id="panel1a-header">
                  <Typography variant="h6">SKU Wise Report</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ height: '400px', overflowX: 'hidden', overflowY: 'scroll' }}>
                  <div style={{ textAlign: 'end' }}>
                    <Button variant="contained" onClick={exportExcelFile}>
                      Export Excel
                    </Button>
                  </div>
                  <Divider sx={{ borderStyle: 'dashed', marginTop: '10px' }} />
                  {skuReportData.map((item) => (
                    <>
                      <div style={{ display: 'flex' }}>
                        <div>
                          <div style={{
                            fontSize: '20px',
                            fontWeight: 'bold',
                            marginTop: '10px',
                            marginBottom: '5px',
                          }}>
                            <div style={{ display: 'inline-block', paddingRight: '5px' }}>
                              <b>SKU:</b>
                            </div>
                            <div style={{ display: 'inline-block' }}>{item.sku}</div>
                          </div>
                          {item.sizeData.map((data) => (
                            <>
                              <div style={{ width: '100%' }}>
                                <div style={{ display: 'inline-block', width: '200px' }}>
                                  <div
                                    style={{
                                      borderStyle: 'solid',
                                      borderColor: '#777',
                                      margin: '5px',
                                      textAlign: 'center',
                                      flexDirection: 'row',
                                      justifyContent: 'center',
                                    }}
                                  >
                                    <div style={{
                                      paddingTop: '10px !important',
                                      paddingBottom: '10px !important',
                                    }}>
                                      <div
                                        style={{
                                          fontWeight: 'bold',
                                          fontSize: '14px',
                                          paddingBottom: '5px',
                                          paddingRight: '5px',
                                        }}
                                      >
                                        <b>Size : {data.size}</b>
                                      </div>
                                      <div
                                        style={{
                                          fontWeight: 'bold',
                                          fontSize: '14px',
                                          paddingBottom: '5px',
                                          paddingRight: '5px',
                                        }}
                                      >
                                        <b>Quantity : {data.quantity}</b>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ))}
                        </div>
                        <div>
                          {item?.skuImage && (
                            <div style={{ height: '150px', width: '150px', margin: '10px' }}>
                              <ModalImage
                                small={item?.skuImage}
                                medium={item?.skuImage}
                                large={item?.skuImage}
                                hideZoom={'true'}
                                hideDownload
                                className="react-image-modal-order-analysis"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <Divider sx={{ borderStyle: 'dashed', marginTop: '10px' }} />
                    </>
                  ))}
                </AccordionDetails>
              </Accordion>
              <Divider />
            </Grid>
          </Grid>
          <br />
          <DataGridTable
            columns={columns}
            data={orderAnalysisData}
            renderDetailPanel={expandRowComponent}
            isLoading={isOrderReportTableLoading}
          />
        </>
      ) : (
        <div>
          <Divider />
          <NoDataFound message={nodata} />
        </div>
      )}

    </>
  );
}
