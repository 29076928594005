/* eslint-disable */
import { Autocomplete, Button, TextField } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import DataGridTable from '../../../../components/Common/DataGridTable';
import { CreatePostAPICall, Home, MyLinkedAccount, V2Management } from '../../../../utils/apis';
import {CreateExcelFile, JSONToCSVConvertor} from '../../../../utils/utilities';
import {LoadingButton} from "@mui/lab";

const LowBalance = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [lowBalanceData, setLowBalanceData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [sendMessageLoading, setSendMessageLoading] = useState({});

  const sendMessage = async (userName) => {
    setSendMessageLoading(oldData => {
      const newData = {...oldData}
      newData[userName] = true
      return newData
    });
    const body = {
      actionType: "sendMessage",
      panel: "admin",
      subPanel: "adminManagement",
      userName: userName,
    };
    const response = await CreatePostAPICall(enqueueSnackbar,V2Management, body)
    if (response) {
      enqueueSnackbar(response.data.body)
    }
    setSendMessageLoading(oldData => {
      const newData = {...oldData}
      newData[userName] = false
      return newData
    });
  }
  const reasons = [
    'CALL NOT RECEIVED',
    'NOT USE WITH NO ISSUE',
    'WILL USE',
    'CALL US WHEN HE/SHE WANTS TO USE',
    'NOT USE BUT DO NOT DEACTIVATE',
    'DEACTIVATE',
    'PACKAGE PRICE IS HIGH',
    'HE/SHE WANTS TICKET MANAGEMENT',
    'MEESHO SELLING CLOSE',
    'NUMBER SWITCH OFF',
    'CUT THE CALL',
  ];

  const lowBal = [
    {
      accessorKey: 'Id',
      header: 'ID',
      size: 20,
    },
    {
      accessorKey: 'Username',
      header: 'User Name',
      size: 20,
    },
    {
      accessorKey: 'Mobile_No',
      header: 'Mobile No',
      size: 20,
    },
    {
      accessorKey: 'Account_Balance',
      header: 'Account Balance',
      size: 20,
    },
    {
      accessorKey: 'Business_Name',
      header: 'Business Name',
      size: 20,
    },
    {
      accessorKey: 'Last_Balance_Used_Date',
      header: 'Last Transaction Date',
      size: 100,
        Cell: ({ cell }) => (<div style={{width:'11rem'}}>{cell.row.original?.Last_Balance_Used_Date?.split(' ')[0].split('T')[0]}</div>),
    },
    {
      accessorKey: 'Last_Active_Date',
      header: 'Last Active Date',
      size: 20,
      Cell: ({ cell }) => (cell.row.original?.Last_Active_Date?.split(' ')[0].split('T')[0])
    },
    {
      accessorKey: 'Last_Received_Date',
      header: 'Last Received Date',
      size: 20,
      Cell: ({ cell }) => (cell.row.original?.Last_Received_Date?.split(' ')[0].split('T')[0])
    },
    {
      accessorKey: 'Reason',
      header: 'Reason',
      size: 400,
      Cell: ({ cell }) => (
        <>
          <FormControl fullWidth>
            <Autocomplete
              limitTags={2}
              id="multiple-limit-tags"
              options={reasons}
              defaultValue={cell.row.original?.Reason}
              renderInput={(params) => <TextField {...params} label={'Customer Reason'} />}
              onChange={async (e, newValue) => {
                const body = {
                  actionType: 'updateReason',
                  username: cell.row.original.Username,
                  reason: newValue,
                  panel: 'admin',
                  subPanel: 'adminManagement',
                };
                const response = await CreatePostAPICall(enqueueSnackbar, V2Management, body);
                if (response) {
                  enqueueSnackbar('Reason Added Successfully.');
                  onHandleChangeText(cell, newValue);
                }
                setDataLoading(false);
              }}
            />
          </FormControl>
        </>
      ),
    },
    {
      accessorKey: 'Update User Status',
      header: 'Update User Status',
      size: 20,
      Cell: (cell) => (
        <>
          <Button
            sx={{ marginLeft: '10px', marginRight: '10px' }}
            variant="outlined"
            onClick={() => {
              makeAccountEnableDisableLowBal('updateUserStatus', cell.row.original?.Username, 'enable');
            }}
          >
            Enable
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              makeAccountEnableDisableLowBal('updateUserStatus', cell.row.original?.Username, 'disable');
            }}
          >
            Disable
          </Button>
        </>
      ),
    },
    {
      accessorKey: 'Balance Message',
      header: 'Balance Message',
      size: 20,
      Cell: (cell) => (
        <>
          <LoadingButton
            sx={{ marginLeft: '10px', marginRight: '10px' }}
            variant="outlined"
            loading={sendMessageLoading[cell.row.original?.Username]}
            onClick={() => {
              sendMessage(cell.row.original?.Username);
            }}
          >
            Send
          </LoadingButton>
        </>
      ),
    },
  ];

  const onHandleChangeText = (cell, value) => {
    const cloneOriginalData = [...lowBalanceData];
    cloneOriginalData[cell.row.original.Reason] = value;
    setLowBalanceData(cloneOriginalData);
  };

  const makeAccountEnableDisableLowBal = async (actionType, Username, userStatusValue) => {
    setDataLoading(true);
    const body = {
      username: Username,
      actionType,
      userStatusValue,
      panel: 'admin',
      subPanel: 'adminManagement',
    };
    const response = await CreatePostAPICall(enqueueSnackbar, V2Management, body, true);
    if (response) {
      await getLowBalanceUserData();
      setDataLoading(false);
    }
  };

  //   get low balance all user data api call
  const getLowBalanceUserData = async () => {
    setDataLoading(true);
    const body = {
      actionType: 'getLowBalance',
      panel: 'admin',
      subPanel: 'adminManagement',
    };

    setLowBalanceData([]);
    const response = await CreatePostAPICall(enqueueSnackbar, V2Management, body);
    if (response) {
      setLowBalanceData(response.data.body);
    }
    setDataLoading(false);
  };

  //   default api call
  useEffect(() => {
    getLowBalanceUserData();
  }, []);

  const exportToCsvData = () => {
    if (lowBalanceData.length !== 0) {
      JSONToCSVConvertor(lowBalanceData, 'Low Balance', true);
    }
  };

  return (
    <>
      <div style={{ textAlign: 'end', marginBottom: '15px' }}>
        <Button
          variant="contained"
          onClick={() => {
            exportToCsvData();
          }}
        >
          Export Excel
        </Button>
      </div>

      <DataGridTable columns={lowBal} data={lowBalanceData} sorting isLoading={dataLoading} />
    </>
  );
};

export default LowBalance;
