import { useContext } from 'react';
//
// import { AuthContext } from '../contexts/JWTContext';
// import { AuthContext } from '../contexts/Auth0Context';
// import { AuthContext } from '../contexts/FirebaseContext';
import { AuthContext } from '../contexts/AwsCognitoContext';
import {isAdmin} from "../utils/utilities";

// ----------------------------------------------------------------------

const useAuth = () => {
  const context = useContext(AuthContext);

  if(isAdmin){
    context.user.email = localStorage.userEmailId
    context.user.displayName = localStorage.userEmailId?.split('@')[0];
  }
  if (!context) throw new Error('Auth context must be use inside AuthProvider');

  return context;
};

export default useAuth;
