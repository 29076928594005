/* eslint-disable */
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Button,
  Container,
  Divider,
  FormControl, FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import DataGridTable from '../../../components/Common/DataGridTable';
import NoDataFound from '../../../components/Common/NoDataFound';
import useSettings from '../../../hooks/useSettings';
import { CreatePostAPICall, V2Management } from '../../../utils/apis';
import {IOSSwitch, uploadFileS3} from '../../../utils/utilities';
import {DatePicker} from "antd";
import {DateTimePicker} from "@mui/x-date-pickers";
import {UploadMultiFile, UploadSingleFile} from "../../../components/upload";
import Iconify from "../../../components/Iconify";
import Dropzone from "react-dropzone";
import {BUISSMASTER_ASSETS, UTILITY_LABEL_PROCESS} from "../../../utils/Constants";

export default function AdminNotificationManagement() {
  const { themeStretch } = useSettings();
  const { enqueueSnackbar } = useSnackbar();
  const [selectPriorityCondition, setSelectPriorityCondition] = useState();
  const [addNotification, setAddNotification] = useState('');
  const [notificationDate, setNotificationDate] = useState(null);
  const [notificationData, setNotificationData] = useState([]);
  const [posterImage, setPosterImage] = useState([]);
  const [isNotificationTableLoading, setIsNotificationTableLoading] = useState(false);


  const selectPriorityOption = ['High', 'Low','Poster'];

  const updateNotification = (cell, value, actionType) => {
    const newData = [];
    notificationData.map((item) => {
      if (item.Token === cell.row.original.Token) {
        item.Is_show = value;
        newData.push(item);
      } else {
        newData.push(item);
      }
    });
    setNotificationData(newData);

    sendNotification(cell.row.original, value, actionType);
  };

  const columns = [
    {
      accessorKey: 'Token',
      header: 'NO',
      size: '10',
    },
    {
      accessorKey: 'message',
      header: 'Message',
      size: '20',
    },
    {
      accessorKey: 'priority',
      header: 'Priority',
      size: '10',
    },
    {
      accessorKey: 'date',
      header: 'Date',
      size: '10',
    },
    {
      accessorKey: 'Is_show',
      header: 'Is Show',
      size: '10',
      Cell: ({ cell }) => {
        return (
          <IOSSwitch
            sx={{ m: 1 }}
            checked={cell.row.original.Is_show}
            onChange={(e, value) => {
              updateNotification(cell, value, 'update');
            }}
          />
        );
      },
    },
    {
      accessorKey: 'Delete',
      header: 'Delete',
      size: '10',
      Cell: ({ cell }) => {
        return (
          <IconButton
            aria-label="delete"
            onClick={(e) => {
              updateNotification(cell, null, 'delete');
            }}
          >
            <DeleteIcon style={{ color: 'red' }} />
          </IconButton>
        );
      },
    },
  ];

  const conditionChange = (event) => {
    setSelectPriorityCondition(event.target.value);
  };
  const sendNotification = async (row, value, actionType) => {
    setIsNotificationTableLoading(true);
    const body = {
      username: 'common',
      Is_show: value,
      message: row.message,
      priority: row.priority,
      token: row.Token,
      action: actionType,
      panel: 'notificationManagement',
      actionType: 'sendNotificationToUsers',
    };
    if(notificationDate && notificationDate !== "") {
      body.date = notificationDate;
    }
    if(row.priority === 'Poster' && actionType == "add") {
      if(posterImage.length > 0) {
        const imageFile = posterImage[0];
        await uploadFileS3(imageFile, imageFile.name, `posters/${row.message}.jpg`, BUISSMASTER_ASSETS, enqueueSnackbar);
      }else {
        enqueueSnackbar("Please select poster image", {variant: 'error'});
        return
      }
    }
    // console.log("body",body)
    const response = await CreatePostAPICall(enqueueSnackbar, V2Management, body);
    if (response) {
      setSelectPriorityCondition('');
      setAddNotification('');
      setIsNotificationTableLoading(false);
      getNotifications();
    }
  };
  const getNotifications = async () => {
    setIsNotificationTableLoading(true);
    const body = {
      actionType: 'sendNotificationToUsers',
      panel: 'notificationManagement',
    };
    const response = await CreatePostAPICall(enqueueSnackbar, V2Management, body);
    if (response) {
      setNotificationData(response.data.body);
      setIsNotificationTableLoading(false);
    }
  };
  function truncate(input) {
    if (input.length > 10) {
      return `${input.substring(0, 18)}...`;
    }
    return input;
  }

  useEffect(() => {
    getNotifications();
  }, []);

  return (
    <>
      <Divider sx={{ p: 1, mb: 2 }} />
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <Grid sx={{ paddingBottom: 3 }} container spacing={3}>
            <Grid item xs={12} sm={12} md={2}>
              <FormControl sx={{ width: '100%' }}>
                <InputLabel id="selectPriorityCondition">Select Priority</InputLabel>
                <Select
                  labelId="selectPriorityCondition"
                  value={selectPriorityCondition || ''}
                  label="Select Priority"
                  onChange={conditionChange}
                >
                  {selectPriorityOption.map((name) => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {selectPriorityCondition === "Poster" && <Grid item xs={12} sm={12} md={3.5}>
              <Dropzone onDrop={(e)=>{setPosterImage(e)}} multiple maxSize={20000000} accept={'image/*'}>
                {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <Button
                          variant="outlined"
                          style={{
                            width: '-webkit-fill-available',
                            justifyContent: 'left',
                          }}
                      >
                        <Iconify icon="material-symbols:upload-rounded" width={30} height={30} />
                        Poster image
                      </Button>
                    </div>
                )}
              </Dropzone>
              {posterImage && posterImage.length > 0 &&
                  <div
                      key={posterImage[0] + 0}
                      style={{
                        width: '-webkit-fill-available',
                        display: 'flex',
                        marginTop: '5px',
                      }}
                  >
                    <Iconify icon="material-symbols:image-outline" width={25} height={25} />
                    <div style={{ width: 160 }}>{truncate(posterImage[0].name)}</div>
                    <Iconify
                        icon="ic:round-delete-forever"
                        width={25}
                        height={25}
                        style={{ color: 'red' }}
                        onClick={() => {
                          setPosterImage([])
                        }}
                    />
                  </div>}
            </Grid>}
            <Grid item xs={12} sm={12} md={selectPriorityCondition === "Poster"?3.5:7}>
              <FormControl sx={{ width: '100%' }}>
                <TextField
                    id="outlined-basic"
                    label={selectPriorityCondition === "Poster" ? "Enter Poster Name" : "Enter Notification Message"}
                    variant="outlined"
                    value={addNotification}
                    onChange={(event) => {
                      setAddNotification(event.target.value);
                    }}
                />
              </FormControl>
            </Grid>



            <Grid item xs={12} sm={12} md={3}>
              <FormControl sx={{ width: '100%' }}>
                <DateTimePicker
                    id="outlined-basic"
                    variant="outlined"
                    label="End Date"
                    value={notificationDate}
                    onChange={(date) => {
                      setNotificationDate(date);
                    }}
                />
              </FormControl>
            </Grid>
          </Grid>

          <div style={{ width: '100%', textAlign: 'end' }}>
            <Button
              variant="contained"
              disabled={!selectPriorityCondition || !addNotification}
              onClick={() =>
                sendNotification(
                  {
                    message: addNotification,
                    priority: selectPriorityCondition,
                  },
                  'false',
                  'add'
                )
              }
            >
              Send Notification
            </Button>
          </div>

          <br />

          {notificationData.length !== 0 ? (
            <DataGridTable
              columns={columns}
              densitycol={'comfortable'}
              data={notificationData}
              isLoading={isNotificationTableLoading}
              enablePagination={false}
            />
          ) : (
            <NoDataFound message={'No Notifications Found'} />
          )}
        </Grid>
      </Grid>
    </>
  );
}
