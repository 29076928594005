/* eslint-disable */
import React, { useState, useEffect, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import {
  Box,
  Grid,
  Stack,
  Typography,
  Divider,
  Button,
  CircularProgress,
  TextField,
  AlertTitle,
  Alert,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
} from '@mui/material';
import Tab from '@mui/material/Tab';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import DialogContent from '@mui/material/DialogContent';
import Dropzone from 'react-dropzone';
import DialogActions from '@mui/material/DialogActions';
import NoDataFound from '../../../components/Common/NoDataFound';
import ShopdeckCustomFormatModal from '../components/ShopdeckCustomFormatModal';
import DataGridTable from '../../../components/Common/DataGridTable';
import {
  changeDateFormat,
  isDate,
  changeDataFormat,
  getAccountsDataByType,
  JSONToCSVConvertor,
  isDesktopEnvironment,
  BootstrapDialogTitle,
  BootstrapDialog,
  handleOnClickCopy,
  SystemColor,
  uploadFileS3, CreateExcelFile,
} from '../../../utils/utilities';
import useAuth from '../../../hooks/useAuth';
import { CreatePostAPICall, ClaimIssue, ShopdeckCreateTicket } from '../../../utils/apis';
import Image from '../../../components/Image';
import AccountSelector from '../../../components/Common/AccountSelector';
import Iconify from '../../../components/Iconify';
import { RECEIVED_REPORTED_DATA } from '../../../utils/Constants';

export default function ShopdeckClaimIssueDetails() {
  const { user } = useAuth();
  const localUser = window.localStorage.getItem('userEmailId');
  const { enqueueSnackbar } = useSnackbar();
  const [isOrderReportTableLoading, setIsOrderReportTableLoading] = useState(false);
  // Customer Return Count Section State
  const [customerReturnCount, setCustomerReturnCount] = useState(' - ');
  const [rtoReturnCount, setRtoReturnCount] = useState(0);
  const [openCustomFormatModal, setOpenCustomFormatModal] = useState(false);
  const [openTicketModal, setOpenTicketModal] = useState(false);
  const [issueType, setIssueType] = useState('PENDI_Not_Rec');
  const [userData, setUserData] = useState([]);
  const [selectedAccountsData, setSelectedAccountsData] = useState([]);
  const [allDefaultFormat, setAllDefaultFormat] = useState([]);
  const [shopdeckClaimIssueOrders, setShopdeckClaimIssueOrders] = useState([]);
  const [shopdeckClaimIssueAPIOrders, setShopdeckClaimIssueAPIOrders] = useState([]);
  const [showDeliveryModal, setShowDeliveryModal] = useState('');
  const [ticketMessageContent, setTicketMessageContent] = useState('');
  const [createTicketLocalData, setCreateTicketLocalData] = useState(null);
  const [showModalLoader, setShowModalLoader] = useState(false);
  const [ticketAttechment, setTicketAttechment] = useState({
    product: [],
    awb: [],
    video: [],
    attachments: [],
    barcode: [],
  });
  const [binaryTicketAttechment, setBinaryTicketAttechment] = useState({
    product: [],
    awb: [],
    video: [],
    attachments: [],
    barcode: [],
  });
  const [generateTicketLoader, setGenerateTicketLoader] = useState(false);
  const [createTiketHiddenFieldValue, setCreateTiketHiddenFieldValue] = useState(null);
  const [productList, setProductList] = useState([]);
  const [awbList, setAwbList] = useState([]);
  const [videoList, setVideoList] = useState([]);
  const [attachmentsList, setAttachmentsList] = useState([]);
  const [barcodeList, setBarcodeList] = useState([]);
  const [actualClaimIssueShopdeckTableData, setActualClaimIssueShopdeckTableData] = useState([]);
  const [selectPacketCondition, setSelectPacketCondition] = useState('Tampered');
  const [barcodeId, setBarcodeId] = useState('');
  const newRemovedData = [];

  const ticketFormatToReportFoundMap = {
    PENDI_Not_Rec: 'Pending_Not_Received',
    DELI_Not_Rec: 'Delivered_Not_Received',
    DPWU_Received: 'D_P_W_Not_Received',
    PAYMENT_NOT_RECEIVED: 'Payment_Not_Received',
    Rejected: 'Rejected',
  };

  const selectPacketConditionOptions = ['Tampered', 'Intact'];

  const allIssueType = [
    { issueType: 'Return Not Received After 25 Day Of Return', issueKey: 'PENDI_Not_Rec' },
    { issueType: 'Delivered Not Received / Pod', issueKey: 'DELI_Not_Rec' },
    { issueType: 'Wrong/Damage/Missing Qt', issueKey: 'DPWU_Received' },
    { issueType: 'Payment Not Received', issueKey: 'PAYMENT_NOT_RECEIVED' },
    { issueType: 'Rejected', issueKey: 'Rejected' },
  ];

  const columns = [
    {
      accessorKey: 'id',
      header: 'Id',
      size: 5,
    },
    {
      accessorKey: 'email',
      header: 'Email',
      size: 10,
    },
    {
      accessorKey: 'supplier_id',
      header: 'Supplier Id',
      size: 10,
    },
    {
      accessorKey: 'sub_order_no',
      header: 'Suborder Number',
      size: 10,
    },
    {
      accessorKey: 'sku',
      header: 'SKU',
      size: 10,
    },
    {
      accessorKey: 'sent_quantity',
      header: 'Quantity',
      size: 10,
    },
    {
      accessorKey: 'payment_status',
      header: 'Payment Status',
      size: 10,
    },
    {
      accessorKey: 'final_settlement',
      header: 'Final Settlement',
      size: 10,
    },
    {
      accessorKey: 'latest_Return_Awb',
      header: 'Return Awb',
      size: 10,
    },
  ];

  const acType = 'shopdeck';
  const getFormattedData = (data, companyData, skuImageData) => {
    const formattedData = shopdeckClaimIssueAPIOrders;
    Object.entries(data).forEach(([key, value]) => {
      const account = [];
      const platformData = {};
      platformData.platformName = key;
      Object.entries(value).forEach(([emailId, value]) => {
        const accountData = {};
        const supplier = [];
        accountData.accountId = emailId;
        Object.entries(value).forEach(([key, value]) => {
          const supplierTempData = {};
          const supplierData = [];
          supplierTempData.supplierId = key;
          Object.entries(value).forEach(([key, value]) => {
            const data = {};
            data.sub_order_no = value[0];
            data.pick_up_date = value[1];
            data.invoice_date = value[2];
            data.latest_Sent_Awb = value[3];
            if (value[4]) data.sku = typeof value[4] === 'string' ? value[4] : value[4].Sku_Name;
            else data.sku = '';
            data.size = value[5];
            data.sent_quantity = value[6];
            data.payment_status = value[7];
            data.return_date = value[8];
            data.order_status = value[9];
            data.return_type = value[10];
            data.latest_Return_Awb = value[11];
            data.return_courier = value[12];
            data.return_quantity = value[13];
            data.is_received = value[14];
            data.re_condition = value[15];
            data.delivery_date = value[16];
            data.late_days = value[17];
            data.payment_date = value[18];
            data.final_settlement = value[19];
            data.to_report_found = value[20];
            data.pending_reported = value[21];
            data.received_reported = value[22];
            data.pod_reported = value[23];
            data.payment_reported = value[24];
            data.return_price_type = value[25];
            data.return_reason = value[26];
            data.detail_return_reason = value[27];
            data.Mobile_No = selectedAccountsData.filter((x) => x.Email === emailId)[0].Mobile_No;
            data.Image_Link = selectedAccountsData.filter((x) => x.Email === emailId)[0].Image_Link;
            data.cName = selectedAccountsData.filter((x) => x.Email === emailId)[0].cName;
            supplierData.push(data);
          });
          supplierTempData.supplierData = supplierData;
          supplier.push(supplierTempData);
        });
        accountData.accountData = supplier;
        account.push(accountData);
      });
      platformData.platformData = account;
      formattedData.push(platformData);
    });

    setShopdeckClaimIssueAPIOrders(formattedData);
    const storedData = JSON.parse(localStorage.getItem('cancel_image_blob_data'));
    const tempFilteredData = [];

    if (formattedData.length > 0) {
      formattedData.forEach((item) => {
        item.platformData.forEach((platformData) => {
          platformData.accountData.forEach((accountData) => {
            accountData.supplierData.forEach((supData) => {
              const tempData = {};
              tempData.isSelected = false;
              tempData.id = tempFilteredData.length + 1;
              tempData.latest_Sent_Awb = supData.latest_Sent_Awb ? supData.latest_Sent_Awb : ' - ';

              tempData.sub_order_no = supData.sub_order_no ? supData.sub_order_no : ' - ';
              tempData.payment_date = supData.payment_date ? supData.payment_date : ' - ';
              tempData.pick_up_date = supData.pick_up_date ? supData.pick_up_date : ' - ';
              tempData.invoice_date = supData.invoice_date ? supData.invoice_date : ' - ';
              tempData.return_price_type = supData.return_price_type ? supData.return_price_type : ' - ';
              tempData.return_reason = supData.return_reason ? supData.return_reason : ' - ';
              tempData.detail_return_reason = supData.detail_return_reason ? supData.detail_return_reason : ' - ';

              tempData.sku = supData.sku ? supData.sku : 'NA';
              tempData.sent_quantity = supData.sent_quantity ? supData.sent_quantity.toString() : '0';
              tempData.size = supData.size ? supData.size : 'NA';

              tempData.payment_status = supData.payment_status ? supData.payment_status : ' - ';
              tempData.return_date = supData.return_date ? supData.return_date : ' - ';

              tempData.order_status = supData.order_status ? supData.order_status : ' - ';
              tempData.company = supData.cName ? supData.cName : ' - ';

              const ReturnTypeMapping = {
                customer_return: 'Customer Return',
                'Customer Return': 'Customer Return',
                RET: 'Customer Return',
                CUSTOMER_RETURN: 'Customer Return',
                courier_return: 'Courier Return (RTO)',
                'Courier Return (RTO)': 'Courier Return (RTO)',
                RTO: 'Courier Return (RTO)',
                COURIER_RETURN: 'Courier Return (RTO)',
              };

              tempData.return_type = ReturnTypeMapping[supData.return_type]
                ? ReturnTypeMapping[supData.return_type]
                : ' - ';

              tempData.latest_Return_Awb = supData.latest_Return_Awb ? supData.latest_Return_Awb : ' - ';
              tempData.return_quantity = supData.return_quantity ? supData.return_quantity : '0';
              tempData.return_courier = supData.return_courier ? supData.return_courier : ' - ';
              tempData.is_received = supData.is_received ? supData.is_received : 'false';
              tempData.Mobile_No = supData.Mobile_No ? supData.Mobile_No : ' - ';
              tempData.Image_Link = supData.Image_Link ? supData.Image_Link : ' - ';

              tempData.re_condition = supData.re_condition ? supData.re_condition : ' - ';

              tempData.delivery_date = supData.delivery_date ? supData.delivery_date : ' - ';

              tempData.late_days = supData.late_days ? supData.late_days : ' - ';
              tempData.final_settlement = supData.final_settlement ? supData.final_settlement : ' - ';
              tempData.to_report_found = supData.to_report_found ? supData.to_report_found : ' - ';

              tempData.pending_reported = supData.pending_reported ? supData.pending_reported : ' - ';
              tempData.received_reported = supData.received_reported ? supData.received_reported.toString() : 'false';

              tempData.pod_reported = supData.pod_reported ? supData.pod_reported : ' - ';
              tempData.payment_reported = supData.payment_reported ? supData.payment_reported : ' - ';

              tempData.supplier_id = accountData.supplierId ? accountData.supplierId : ' - ';

              // tempData.company = companyData[item.platformName][accountData.supplierId]
              //     ? companyData[item.platformName][accountData.supplierId]
              //     : '';
              tempData.image = skuImageData[supData.sku] ? skuImageData[supData.sku] : null;

              tempData.email = platformData.accountId ? platformData.accountId : ' - ';

              if (storedData)
                tempData.cancel_image_url =
                  storedData.filter((x) => x.supplierId === accountData.supplierId).length > 0
                    ? storedData.filter((x) => x.supplierId === accountData.supplierId)[0].blobUrl
                    : null;
              else tempData.cancel_image_url = null;

              tempFilteredData.push(tempData);
            });
          });
        });
      });
    }
    setShopdeckClaimIssueOrders(tempFilteredData);
    setActualClaimIssueShopdeckTableData(tempFilteredData);
  };

  const getSelectAllShopdeckClaimIssueData = async (index) => {
    if (selectedAccountsData[index]) {
      await getShopdeckClaimIssueOrders(selectedAccountsData[index]);
      await getSelectAllShopdeckClaimIssueData(index + 1);
    }
  };

  const getShopdeckClaimIssueOrders = async (item) => {
    const body = {
      username: user?.email ? user.email : localUser,
      subAccountId: item.value,
      tpEmailId: item.Email,
      reportType: issueType,
      acType: item.AcType,
      actionType: 'getToReportOrders',
    };
    const response = await CreatePostAPICall(enqueueSnackbar, ClaimIssue, body);
    if (response) getFormattedData(response.data.body, response.data.companyName, response.data.skuImageData);
    return true;
  };

  const handleSelectedAccount = (event, value) => {
    setShopdeckClaimIssueAPIOrders([]);
    setShopdeckClaimIssueOrders([]);
    const filterData = [];
    const selectedAccounts = [];
    const selectedOptions = value;

    if (selectedOptions.length > 0) {
      const iselectAll = selectedOptions.some((vendor) => vendor.value === 'all');

      if (iselectAll) {
        userData.forEach((item) => {
          if (item.value !== 'all') selectedAccounts.push(item);
        });
      } else {
        selectedOptions.forEach((item) => {
          selectedAccounts.push(item);
        });
      }

      selectedOptions.forEach((item) => {
        filterData.push(item);
      });
    }
    setSelectedAccountsData(selectedAccounts);
  };

  const handleTabChange = (event, newValue) => {
    if (!isOrderReportTableLoading) {
      setIssueType(newValue);
      setShopdeckClaimIssueAPIOrders([]);
      setShopdeckClaimIssueOrders([]);
    }
  };

  const onDoubleClickDownload = async (event, row) => {
    if (event.detail === 2 && isDesktopEnvironment) {
      const element = document.createElement('a');
      element.href = row.original?.image;
      const url = row.original?.image;
      const imageType = url.split('/').at(-1).split('.').at(-1);
      element.download = `${row.original?.sku}.${imageType}`;
      element.click();
    }
  };

  const getCustomFormats = async () => {
    const body = {
      username: user?.email ? user.email : localUser,
      manageAction: 'getTicketFormats',
      ticketType: 'all',
      resourceType: 'claimFormats',
      actionType: 'manageNeededResources',
      acType: 'shopdeck',
    };

    const response = await CreatePostAPICall(enqueueSnackbar, ClaimIssue, body);
    if (response) {
      setAllDefaultFormat(response.data.body);
    }
  };

  const handleCreateTicketModalOpen = (row) => {
    const defaultFormat = allDefaultFormat.filter(
      (format) =>
        format.Is_Default === true &&
        format.ticketFormat === ticketFormatToReportFoundMap[row.original?.to_report_found]
    );

    if (defaultFormat.length > 0) {
      let textToSend = '';
      textToSend = defaultFormat[0].Content.replaceAll('[AWB]', row.original?.latest_Return_Awb)
        .replaceAll('[RET_TYPE]', row.original?.return_type)
        .replaceAll('[SUB_ORDER_ID]', row.original?.sub_order_no)
        .replaceAll('[LATE_DAYS]', row.original?.late_days)
        .replaceAll('[ORDER_STATUS]', row.original?.order_status)
        .replaceAll('[SHOPDECK_LATE_DAYS]', '[RTO IN 45 DAYS/ RET IN 25 DAYS]')
        .replaceAll('[DELIVERED_DATE]', row.original?.delivery_date)
        .replaceAll('[RETURN_CONDITION]', row.original?.re_condition)
        .replaceAll('[RETURN_COURIER_PARTNER]', row.original?.return_courier)
        .replaceAll('[PICK_UP_DATE]', row.original?.pick_up_date);

      const createTicketObj = {};
      createTicketObj.row = row;
      setCreateTicketLocalData(createTicketObj);
      setTicketMessageContent(textToSend);
      setShowDeliveryModal(row.original);

      setOpenTicketModal(true);
    } else {
      enqueueSnackbar("Can't Setup Ticket Format", { variant: 'error' });
    }
  };

  const handleCreateTicketModalClose = () => {
    setOpenTicketModal(false);
    setShowDeliveryModal('');
    setCreateTiketHiddenFieldValue(null);
    setBinaryTicketAttechment({
      product: [],
      awb: [],
      video: [],
      attachments: [],
      barcode: [],
    });
    setTicketAttechment({
      product: [],
      awb: [],
      video: [],
      attachments: [],
      barcode: [],
    });
    setProductList([]);
    setAwbList([]);
    setVideoList([]);
    setAttachmentsList([]);
    setBarcodeList([]);
    setBarcodeId('');
  };

  useEffect(() => {
    if (shopdeckClaimIssueOrders.length > 0) {
      setRtoReturnCount(shopdeckClaimIssueOrders.filter((x) => x.return_type === 'Courier Return (RTO)').length);
      setCustomerReturnCount(shopdeckClaimIssueOrders.filter((x) => x.return_type === 'Customer Return').length);
    } else {
      setRtoReturnCount(0);
      setCustomerReturnCount(0);
    }
  }, [shopdeckClaimIssueOrders]);

  useEffect(() => {
    getAccountsDataByType('shopdeck').then((filteredData) => {
      setUserData(filteredData);
    });
    getCustomFormats();
  }, []);

  useEffect(() => {
    if (selectedAccountsData.length > 0) {
      setIsOrderReportTableLoading(true);
      getSelectAllShopdeckClaimIssueData(0).then((_) => {
        setIsOrderReportTableLoading(false);
      });
    }
  }, [selectedAccountsData, issueType]);

  const expandRowComponent = (row) => (
    <div>
      <Grid sx={{ p: 2 }} container spacing={3}>
        {row.original?.image ? (
          <Grid item xs={2} sm={2} md={2}>
            <div style={{ height: '150px', width: '150px' }}>
              <Image
                src={row.original?.image}
                ratio="1/1"
                onDoubleClick={(event) => {
                  onDoubleClickDownload(event, row);
                }}
              />
            </div>
          </Grid>
        ) : null}
        <Grid
          item
          xs={row.original?.image ? 10 : 12}
          sm={row.original?.image ? 10 : 12}
          md={row.original?.image ? 10 : 12}
        >
          <Box
            sx={{
              display: 'grid',
              gridAutoFlow: 'row',
              gridTemplateColumns: 'repeat(3, 1fr)',
              gridTemplateRows: 'auto',
              p: 2,
            }}
          >
            <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
              <b style={{ marginRight: '2px' }}>Email :</b>
              <div style={{ marginLeft: '2px' }}> {row.original?.email}</div>
            </div>
            <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
              <b style={{ marginRight: '2px' }}>Supplier Id :</b>{' '}
              <div style={{ marginLeft: '2px' }}> {row.original?.supplier_id}</div>
            </div>
            <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
              <b style={{ marginRight: '2px' }}>Sub Order Number :</b>
              <div style={{ marginLeft: '2px' }}>
                {row.original?.sub_order_no === '' ? ' - ' : row.original?.sub_order_no}
              </div>
            </div>
            <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
              <b style={{ marginRight: '2px' }}>Company :</b>{' '}
              <div style={{ marginLeft: '2px' }}> {row.original?.company}</div>
            </div>

            <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
              <b style={{ marginRight: '2px' }}>SKU :</b>
              <div style={{ marginLeft: '2px' }}>{row.original?.sku === '' ? ' - ' : row.original?.sku}</div>
            </div>

            <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
              <b style={{ marginRight: '2px' }}>Quantity :</b>
              <div style={{ marginLeft: '2px' }}>
                {row.original?.sent_quantity === '' ? ' - ' : row.original?.sent_quantity}
              </div>
            </div>

            <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
              <b style={{ marginRight: '2px' }}>Pickup Date :</b>
              <div style={{ marginLeft: '2px' }}>
                {isDate(row.original?.pick_up_date) ? changeDateFormat(row.original?.pick_up_date) : ' - '}
              </div>
            </div>
            <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
              <b style={{ marginRight: '2px' }}>Invoice Date :</b>
              <div style={{ marginLeft: '2px' }}>
                {isDate(row.original?.invoice_date) ? changeDateFormat(row.original?.invoice_date) : ' - '}
              </div>
            </div>

            <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
              <b style={{ marginRight: '2px' }}>Sent AWB :</b>
              <div style={{ marginLeft: '2px' }}>
                {row.original?.latest_Sent_Awb === '' ? ' - ' : row.original?.latest_Sent_Awb}
              </div>
            </div>
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ borderStyle: 'dashed' }} />
      <Typography sx={{ pl: 2, pt: 2 }} variant="h6">
        Payment Info
      </Typography>
      <Box
        sx={{
          display: 'grid',
          gridAutoFlow: 'row',
          gridTemplateColumns: 'repeat(3, 1fr)',
          gridTemplateRows: 'auto',
          p: 2,
        }}
      >
        <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
          <b style={{ marginRight: '2px' }}>Payment Status :</b>
          <div style={{ marginLeft: '2px' }}>
            {row.original?.payment_status === '' ? ' - ' : row.original?.payment_status}
          </div>
        </div>
        <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
          <b style={{ marginRight: '2px' }}>Payment Date :</b>
          <div style={{ marginLeft: '2px' }}>
            {isDate(row.original?.payment_date) ? changeDateFormat(row.original?.payment_date) : ' - '}
          </div>
        </div>
        <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
          <b style={{ marginRight: '2px' }}>Final Settlement :</b>
          <div style={{ marginLeft: '2px' }}>
            {row.original?.final_settlement === '' ? ' - ' : row.original?.final_settlement}
          </div>
        </div>
      </Box>
      <Divider sx={{ borderStyle: 'dashed' }} />
      <Typography sx={{ pl: 2, pt: 2 }} variant="h6">
        Return Info
      </Typography>
      <Box
        sx={{
          display: 'grid',
          gridAutoFlow: 'row',
          gridTemplateColumns: 'repeat(3, 1fr)',
          gridTemplateRows: 'auto',
          p: 2,
        }}
      >
        <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
          <b style={{ marginRight: '2px' }}>Return Type :</b>
          <div style={{ marginLeft: '2px' }}>
            {row.original?.return_type === '' ? ' - ' : row.original?.return_type}
          </div>
        </div>
        <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
          <b style={{ marginRight: '2px' }}>Return AWB :</b>
          <div style={{ marginLeft: '2px' }}>
            {row.original?.latest_Return_Awb === '' ? ' - ' : row.original?.latest_Return_Awb}
          </div>
        </div>
        <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
          <b style={{ marginRight: '2px' }}>Is Received :</b>
          <div style={{ marginLeft: '2px' }}>{changeDataFormat(row.original?.is_received)} </div>
        </div>
        <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
          <b style={{ marginRight: '2px' }}>Return Quantity :</b>
          <div style={{ marginLeft: '2px' }}>
            {row.original?.return_quantity === '' ? ' - ' : row.original?.return_quantity}
          </div>
        </div>
        <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
          <b style={{ marginRight: '2px' }}>Late Days :</b>
          <div style={{ marginLeft: '2px' }}>{row.original?.late_days === '' ? ' - ' : row.original?.late_days}</div>
        </div>
        <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
          <b style={{ marginRight: '2px' }}>Re Condition :</b>
          <div style={{ marginLeft: '2px' }}>
            {row.original?.re_condition === '' ? ' - ' : row.original?.re_condition}
          </div>
        </div>
        <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
          <b style={{ marginRight: '2px' }}>Delivered Date :</b>
          <div style={{ marginLeft: '2px' }}>
            {isDate(row.original?.delivery_date) ? changeDateFormat(row.original?.delivery_date) : ' - '}
          </div>
        </div>
        <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
          <b style={{ marginRight: '2px' }}>Return Date :</b>
          <div style={{ marginLeft: '2px' }}>
            {isDate(row.original?.return_date) ? changeDateFormat(row.original?.return_date) : ' - '}
          </div>
        </div>
        <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
          <b style={{ marginRight: '2px' }}>Return Courier Partner :</b>
          <div style={{ marginLeft: '2px' }}>
            {row.original?.return_courier === '' ? ' - ' : row.original?.return_courier}
          </div>
        </div>
        <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
          <b style={{ marginRight: '2px' }}>Return Price Type :</b>
          <div style={{ marginLeft: '2px' }}>
            {row.original?.return_price_type === '' ? ' - ' : row.original?.return_price_type}
          </div>
        </div>
        <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
          <b style={{ marginRight: '2px' }}>Return Reason :</b>
          <div style={{ marginLeft: '2px' }}>
            {row.original?.return_reason === '' ? ' - ' : row.original?.return_reason}
          </div>
        </div>
        <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
          <b style={{ marginRight: '2px' }}>Detail Return Reason :</b>
          <div style={{ marginLeft: '2px' }}>
            {row.original?.detail_return_reason === '' ? ' - ' : row.original?.detail_return_reason}
          </div>
        </div>
      </Box>
      <Divider sx={{ borderStyle: 'dashed' }} />
      <Typography sx={{ pl: 2, pt: 2 }} variant="h6">
        Claim Issues
      </Typography>
      <Box
        sx={{
          display: 'grid',
          gridAutoFlow: 'row',
          gridTemplateColumns: 'repeat(3, 1fr)',
          gridTemplateRows: 'auto',
          p: 2,
        }}
      >
        <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
          <b style={{ marginRight: '2px' }}>To Report Found :</b>
          <div style={{ marginLeft: '2px' }}>
            {row.original?.to_report_found === '' ? ' - ' : row.original?.to_report_found}{' '}
          </div>
        </div>
        <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
          <b style={{ marginRight: '2px' }}>POD Reported :</b>
          <div style={{ marginLeft: '2px' }}>{changeDataFormat(row.original?.pod_reported)} </div>
        </div>
        <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
          <b style={{ marginRight: '2px' }}>Payment Reported :</b>
          <div style={{ marginLeft: '2px' }}>{changeDataFormat(row.original?.payment_reported)} </div>
        </div>
        <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
          <b style={{ marginRight: '2px' }}>Pending Reported :</b>
          <div style={{ marginLeft: '2px' }}>{changeDataFormat(row.original?.pending_reported)} </div>
        </div>
        <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
          <b style={{ marginRight: '2px' }}>Received Reported :</b>
          <div style={{ marginLeft: '2px' }}>{changeDataFormat(row.original?.received_reported)} </div>
        </div>
      </Box>
      <Divider sx={{ borderStyle: 'dashed' }} />
      <div
        style={{
          width: '100%',
          textAlign: 'start',
          paddingBottom: '10px',
          paddingRight: '10px',
        }}
      >
        <Button sx={{ m: 1 }} variant="contained" onClick={() => handleCreateTicketModalOpen(row)}>
          Create Ticket
        </Button>
      </div>
    </div>
  );

  const exportToCsvShopdeckData = () => {
    if (shopdeckClaimIssueOrders.length !== 0) {
      CreateExcelFile(shopdeckClaimIssueOrders, 'Shopdeck Ticket Data', true);
    }
  };

  // For Create Ticket Inner Side Mark As Reported
  const handleMarkAsReported = async () => {
    setShowModalLoader(true);
    const body = {
      username: user?.email ? user.email : localUser,
      tpEmailId: showDeliveryModal.email,
      subAccountId: showDeliveryModal.supplier_id,
      suborder_no: showDeliveryModal.sub_order_no,
      ticketType: showDeliveryModal.to_report_found,
      actionType: 'getReportedOrders',
      action: 'markAsReported',
      acType,
    };
    const response = await CreatePostAPICall(enqueueSnackbar, ClaimIssue, body);
    if (response) {
      setShowModalLoader(false);
      let newData = [];
      newData = shopdeckClaimIssueOrders.filter((item) => item.sub_order_no !== showDeliveryModal.sub_order_no);
      setShopdeckClaimIssueOrders(newData);
      handleCreateTicketModalClose();
      enqueueSnackbar('Successfully Reported');
    }
  };

  // For Create Ticket Inner Side Remove Ticket
  const handleRemoveToReport = async () => {
    setShowModalLoader(true);
    const body = {
      username: user?.email ? user.email : localUser,
      tpEmailId: showDeliveryModal.email,
      subAccountId: showDeliveryModal.supplier_id,
      suborder_no: showDeliveryModal.sub_order_no,
      actionType: 'getReportedOrders',
      action: 'removeToReport',
      acType,
    };
    const response = await CreatePostAPICall(enqueueSnackbar, ClaimIssue, body);
    if (response) {
      setShowModalLoader(false);
      let newData = [];
      newData = shopdeckClaimIssueOrders.filter((item) => item.sub_order_no !== showDeliveryModal.sub_order_no);
      setShopdeckClaimIssueOrders(newData);
      handleCreateTicketModalClose();
      enqueueSnackbar('Successfully Removed');
    }
  };

  const createTicketDataStoreInLocalStorage = async (data) => {
    const tempObject = data;
    tempObject.barcodeAtch = binaryTicketAttechment.barcode;
    tempObject.productAtch = binaryTicketAttechment.product;
    tempObject.awbAtch = binaryTicketAttechment.awb;
    tempObject.videoAtch = binaryTicketAttechment.video;
    tempObject.attachments = binaryTicketAttechment.attachments;
    tempObject.cnlAtch = tempObject.row.cancel_image_url;
    setGenerateTicketLoader(true);
    setCreateTiketHiddenFieldValue(JSON.stringify(tempObject));
    setGenerateTicketLoader(false);
  };

  const maxNumberOfFiles = 3;
  const maxNumberOfFilesForBarcode = 2;

  function truncate(input) {
    if (input.length > 10) {
      return `${input.substring(0, 18)}...`;
    }
    return input;
  }

  const removeImg = (topIndex, type) => {
    const oldData = ticketAttechment;
    const oldDataBinary = binaryTicketAttechment;
    const tempArray = ticketAttechment[type].filter((item, index) => index !== topIndex);

    const tempArrayBinary = binaryTicketAttechment[type].filter((item, index) => index !== topIndex);

    oldData[type] = tempArray;

    oldDataBinary[type] = tempArrayBinary;

    setDataToRespectiveList(oldData[type], type);
    setTicketAttechment(oldData);
    setBinaryTicketAttechment(oldDataBinary);
  };

  const setBase64Data = (type, tempArray) => {
    const binaryTempValue = binaryTicketAttechment;
    if (type === 'barcode') {
      binaryTempValue.barcode = [];
      tempArray.forEach((x) => {
        const productUrl = URL.createObjectURL(x);
        const data = { name: x.name, blob: productUrl };
        binaryTempValue.barcode.push(data);
      });
    } else if (type === 'product') {
      binaryTempValue.product = [];
      tempArray.forEach((x) => {
        const productUrl = URL.createObjectURL(x);
        const data = { name: x.name, blob: productUrl };
        binaryTempValue.product.push(data);
      });
    } else if (type === 'awb') {
      binaryTempValue.awb = [];
      tempArray.forEach((x) => {
        const productUrl = URL.createObjectURL(x);
        const data = { name: x.name, blob: productUrl };
        binaryTempValue.awb.push(data);
      });
    } else if (type === 'video') {
      binaryTempValue.video = [];
      tempArray.forEach((x) => {
        const productUrl = URL.createObjectURL(x);
        const data = { name: x.name, blob: productUrl };
        binaryTempValue.video.push(data);
      });
    } else if (type === 'attachments') {
      binaryTempValue.attachments = [];
      tempArray.forEach((x) => {
        const productUrl = URL.createObjectURL(x);
        const data = { name: x.name, blob: productUrl };
        binaryTempValue.attachments.push(data);
      });
    }
    setBinaryTicketAttechment(binaryTempValue);
  };

  const uploadImage = async (fileList, type, maxNumberOfFiles = 3) => {
    const attechment = ticketAttechment;
    const tempArray = [...attechment[type], ...fileList];

    if (tempArray.length > maxNumberOfFiles) {
      enqueueSnackbar(`Maximum ${maxNumberOfFiles} Files Only`, { variant: 'error' });
      return;
    }
    attechment[type] = tempArray;
    setDataToRespectiveList(attechment[type], type);
    setTicketAttechment(attechment);
    setBase64Data(type, tempArray);
  };

  const setDataToRespectiveList = (data, type) => {
    switch (type) {
      case 'barcode':
        setBarcodeList(data);
        break;
      case 'product':
        setProductList(data);
        break;
      case 'awb':
        setAwbList(data);
        break;
      case 'video':
        setVideoList(data);
        break;
      case 'attachments':
        setAttachmentsList(data);
        break;
      default:
        break;
    }
  };

  const uploadVideo = async (fileList, type) => {
    const attechment = ticketAttechment;
    const tempArray = [...attechment[type], ...fileList];

    if (tempArray.length > 1) {
      enqueueSnackbar(`Maximum ${1} Video Only`, { variant: 'error' });
      return;
    }
    attechment[type] = tempArray;
    setDataToRespectiveList(attechment[type], type);
    setTicketAttechment(attechment);
    setBase64Data(type, tempArray);
  };

  const onDropBarcode = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length > maxNumberOfFilesForBarcode) {
        enqueueSnackbar(`Maximum ${maxNumberOfFilesForBarcode} Files Only`, { variant: 'error' });
        return;
      }
      uploadImage(acceptedFiles, 'barcode', maxNumberOfFilesForBarcode);
    },
    [barcodeList, ticketAttechment]
  );

  const onDropProduct = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length > maxNumberOfFiles) {
        enqueueSnackbar(`Maximum ${maxNumberOfFiles} Files Only`, { variant: 'error' });
        return;
      }
      uploadImage(acceptedFiles, 'product');
    },
    [productList, ticketAttechment]
  );

  const onDropAwbImages = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length > maxNumberOfFiles) {
        enqueueSnackbar(`Maximum ${maxNumberOfFiles} Files Only`, { variant: 'error' });
        return;
      }
      uploadImage(acceptedFiles, 'awb');
    },
    [awbList, ticketAttechment]
  );

  const onDropUnpackingVideo = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length > maxNumberOfFiles) {
        enqueueSnackbar(`Maximum ${maxNumberOfFiles} Files Only`, { variant: 'error' });
        return;
      }
      acceptedFiles.forEach((item) => {
        if (item.size > 26000000) {
          enqueueSnackbar(`Video Size Must Be Less Then 25Mb`, { variant: 'error' });
          return;
        }
        uploadVideo(acceptedFiles, 'video');
      });
    },
    [videoList, ticketAttechment]
  );

  // For Creating Ticket
  const generateTicket = async () => {
    const body = {
      username: user?.email ? user.email : localUser,
      tpEmailId: showDeliveryModal.email,
      subAccountId: showDeliveryModal.supplier_id,
      createTicket: 'true',
      ticketData: {
        sub_order_no: showDeliveryModal.sub_order_no,
        to_report_found: showDeliveryModal.to_report_found,
        latestEmail: showDeliveryModal.email,
        Mobile_No: showDeliveryModal.Mobile_No,
        description: ticketMessageContent,
      },
    };
    if (showDeliveryModal.to_report_found === 'DELI_Not_Rec' || showDeliveryModal.to_report_found === 'PENDI_Not_Rec') {
      body.ticketData.latest_Return_Awb = showDeliveryModal.latest_Return_Awb;
      body.ticketData.return_courier = showDeliveryModal.return_courier;
      callApiCreateTicket(body);
    }
    if (showDeliveryModal.to_report_found === 'PAYMENT_NOT_RECEIVED') {
      body.ticketData.cnlAtch = showDeliveryModal.Image_Link;
      body.ticketData.attachments = [];
      callApiCreateTicket(body);
    }
    if (showDeliveryModal.to_report_found === 'DPWU_Received') {
      if (barcodeId.length === 0) {
        enqueueSnackbar('Please Enter Barcode Id', { variant: 'error' });
      } else {
        setShowModalLoader(true);
        if (
          ticketAttechment.product.length === 0 ||
          ticketAttechment.awb.length === 0 ||
          ticketAttechment.video.length === 0
        ) {
          setShowModalLoader(false);
          enqueueSnackbar('Please Upload Required Attachments!', { variant: 'error' });
          return;
        }
        const fileData = { product: [], awb: [], video: [], attachments: [], barcode: [] };
        Object.entries(ticketAttechment).map(async (row, key) => {
          const [type, files] = row;
          if (files && files.length > 0 && type !== 'video') {
            let index = 1;
            files.map(async (file) => {
              const ext = file.name.split('.').pop();
              const fileName = `${showDeliveryModal.sub_order_no}_${type}_${index}.${ext}`;
              index++;
              const res = await uploadFileS3(
                file,
                fileName,
                `${showDeliveryModal.sub_order_no}/${fileName}`,
                RECEIVED_REPORTED_DATA,
                enqueueSnackbar
              );
              if (res) fileData[type].push(fileName);
            });
          }
        });
        let videoCount = ticketAttechment.video.length > 0 ? 1 : 0;
        // video upload s3
        if (videoCount > 0) {
          let index = 1;
          ticketAttechment.video.map(async (file) => {
            const ext = file.name.split('.').pop();
            const fileName = `${showDeliveryModal.sub_order_no}_video_${index}.${ext}`;
            index++;
            const res = await uploadFileS3(
              file,
              fileName,
              `${showDeliveryModal.sub_order_no}/${fileName}`,
              RECEIVED_REPORTED_DATA,
              enqueueSnackbar
            );
            if (res) {
              fileData.video.push(fileName);
              if (videoCount === ticketAttechment.video.length) {
                await DPWTicket(fileData);
              }
              videoCount++;
            }
          });
        }
      }
    }
  };

  const callApiCreateTicket = async (body) => {
    setShowModalLoader(true);
    const response = await CreatePostAPICall(enqueueSnackbar, ShopdeckCreateTicket, body);
    if (response) {
      setShowModalLoader(false);
      const filterTableData = shopdeckClaimIssueOrders.filter(
        (item) => item.sub_order_no !== showDeliveryModal.sub_order_no
      );

      const actualShopdeckTableData = actualClaimIssueShopdeckTableData.filter(
        (item) => item.sub_order_no !== showDeliveryModal.sub_order_no
      );
      setShopdeckClaimIssueOrders(filterTableData);
      setActualClaimIssueShopdeckTableData(actualShopdeckTableData);
      handleCreateTicketModalClose();
      enqueueSnackbar(response.data.body);
    } else {
      setShowModalLoader(false);
      handleCreateTicketModalClose();
    }
  };

  const DPWTicket = async (fileData) => {
    setShowModalLoader(true);
    const body = {
      username: user?.email ? user.email : localUser,
      tpEmailId: showDeliveryModal.email,
      subAccountId: showDeliveryModal.supplier_id,
      createTicket: 'true',
      ticketData: {
        sub_order_no: showDeliveryModal.sub_order_no,
        to_report_found: showDeliveryModal.to_report_found,
        latestEmail: showDeliveryModal.email,
        Mobile_No: showDeliveryModal.Mobile_No,
        description: ticketMessageContent,
        barcode_id: barcodeId,
        re_condition: showDeliveryModal.re_condition,
        productAtch: fileData.product,
        barcodeAtch: fileData.barcode,
        awbAtch: fileData.awb,
        videoAtch: fileData.video,
        attachments: fileData.attachments,
        latest_Return_Awb: showDeliveryModal.latest_Return_Awb,
        return_courier: showDeliveryModal.return_courier,
        packet_condition: selectPacketCondition,
      },
    };

    const response = await CreatePostAPICall(enqueueSnackbar, ShopdeckCreateTicket, body);
    if (response) {
      setShowModalLoader(false);
      const filterTableData = shopdeckClaimIssueOrders.filter(
        (item) => item.sub_order_no !== showDeliveryModal.sub_order_no
      );

      const actualShopdeckTableData = actualClaimIssueShopdeckTableData.filter(
        (item) => item.sub_order_no !== showDeliveryModal.sub_order_no
      );
      setShopdeckClaimIssueOrders(filterTableData);
      setActualClaimIssueShopdeckTableData(actualShopdeckTableData);
      setProductList([]);
      setAwbList([]);
      setVideoList([]);
      setAttachmentsList([]);
      setBarcodeList([]);
      setBarcodeId('');
      handleCreateTicketModalClose();
      enqueueSnackbar(response.data.body);
    } else {
      setShowModalLoader(false);
      handleCreateTicketModalClose();
    }
  };

  const onDropAttachment = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length > maxNumberOfFiles) {
        enqueueSnackbar(`Maximum ${maxNumberOfFiles} Files Only`, { variant: 'error' });
        return;
      }
      uploadImage(acceptedFiles, 'attachments');
    },
    [attachmentsList, ticketAttechment]
  );

  useEffect(() => {
    if (createTicketLocalData) {
      const data = createTicketLocalData;
      data.ticketType = issueType;
      data.barcodeAtch = binaryTicketAttechment.barcode;
      data.productAtch = binaryTicketAttechment.product;
      data.awbAtch = binaryTicketAttechment.awb;
      data.videoAtch = binaryTicketAttechment.video;
      data.attachments = binaryTicketAttechment.attachments;
      data.description = ticketMessageContent || '';
      createTicketDataStoreInLocalStorage(createTicketLocalData);
    }
    return () => {};
  }, [
    createTicketLocalData,
    binaryTicketAttechment.barcode,
    binaryTicketAttechment.product,
    binaryTicketAttechment.awb,
    binaryTicketAttechment.video,
    binaryTicketAttechment.attachments,
    ticketMessageContent,
  ]);

  // Mark As Reported And Remove

  const markAsReportedAndRemoveToReport = async (value, table, action) => {
    const isLast = value === table.getSelectedRowModel().flatRows.at(-1).original;

    const body = {
      username: user?.email ? user.email : localUser,
      tpEmailId: value.email,
      subAccountId: value.supplier_id,
      suborder_no: value.sub_order_no,
      actionType: 'getReportedOrders',
      action,
      acType,
    };
    action === 'markAsReported' ? (body.ticketType = value.to_report_found) : null;

    const response = await CreatePostAPICall(enqueueSnackbar, ClaimIssue, body);

    if (response) {
      newRemovedData.push(value.sub_order_no);
    }

    if (isLast) {
      const newData = shopdeckClaimIssueOrders.filter((item) => !newRemovedData.includes(item.sub_order_no));
      setShopdeckClaimIssueOrders(newData);
      action === 'markAsReported' ? enqueueSnackbar('Successfully Reported') : enqueueSnackbar('Successfully Removed');
    }
  };

  const handleSuccess = (id) => {
    const filterTableData = shopdeckClaimIssueOrders.filter((item) => item.id !== id);

    const actualShopdeckTableData = actualClaimIssueShopdeckTableData.filter((item) => item.id !== id);

    setShopdeckClaimIssueOrders(filterTableData);
    setActualClaimIssueShopdeckTableData(actualShopdeckTableData);
    handleCreateTicketModalClose();
  };

  return (
    <>
      <Grid sx={{ paddingBottom: 5 }} container spacing={3}>
        <Grid item xs={12} sm={12} md={6}>
          <AccountSelector allData={userData} onChangeHandler={handleSelectedAccount} />
        </Grid>

        <Grid item xs={12} sm={12} md={6} sx={{ marginBlock: 'auto' }}>
          <Button
            variant={'outlined'}
            startIcon={
              <Iconify
                icon="material-symbols:edit-square-outline-rounded"
                width={33}
                height={33}
                style={{ marginBottom: '4px' }}
              />
            }
            onClick={() => {
              setOpenCustomFormatModal(true);
            }}
          >
            <span style={{ fontSize: '16px' }}> Custom Format </span>
          </Button>
        </Grid>
      </Grid>

      <TabContext value={issueType}>
        <Box sx={{ bgcolor: 'background.neutral', px: 3 }}>
          <TabList
            allowScrollButtonsMobile
            variant="scrollable"
            scrollButtons="auto"
            onChange={handleTabChange}
            value={issueType}
          >
            {allIssueType.map((item) => (
              <Tab
                disableRipple
                key={item.issueKey}
                value={item.issueKey}
                label={
                  <Stack spacing={1} direction="row" alignItems="center">
                    <div>{item.issueType}</div>
                  </Stack>
                }
                sx={{ '& .MuiTab-wrapper': { whiteSpace: 'nowrap' } }}
              />
            ))}
          </TabList>
        </Box>
        <TabPanel value={issueType}>
          {shopdeckClaimIssueOrders.length !== 0 ? (
            <>
              <Grid sx={{ p: 3 }} container spacing={3}>
                <Grid item xs={12} sm={5} md={5}>
                  <div style={{ display: 'flex' }}>
                    <b style={{ marginRight: '5px' }}>Customer Return :</b>
                    <div>{customerReturnCount}</div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <div style={{ display: 'flex' }}>
                    <b style={{ marginRight: '5px' }}>RTO Return :</b>
                    <div>{rtoReturnCount}</div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <div
                    style={{
                      width: '100%',
                      textAlign: 'end',
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={() => {
                        exportToCsvShopdeckData();
                      }}
                    >
                      Export Excel
                    </Button>
                  </div>
                </Grid>
              </Grid>

              <DataGridTable
                columns={columns}
                data={shopdeckClaimIssueOrders}
                checkbox
                button1={'Mark As All Reported'}
                button2={'Mark As All Remove'}
                funReported={(value, table) => {
                  markAsReportedAndRemoveToReport(value, table, 'markAsReported');
                }}
                funRemove={(value, table) => {
                  markAsReportedAndRemoveToReport(value, table, 'removeToReport');
                }}
                isLoading={isOrderReportTableLoading}
                renderDetailPanel={expandRowComponent}
              />
            </>
          ) : (
            <div>
              <Divider />
              <br />
              {isOrderReportTableLoading === true ? (
                <div style={{ width: '100%', textAlign: 'center', padding: '100px' }}>
                  <CircularProgress sx={{ color: SystemColor.originColor }} size={24} color="inherit" />
                </div>
              ) : (
                <NoDataFound message={'Please Select An Account and Select Issue Type To See Orders Details'} />
              )}
            </div>
          )}
        </TabPanel>
      </TabContext>

      <ShopdeckCustomFormatModal
        isModalOpen={openCustomFormatModal}
        handleClose={(value) => {
          setOpenCustomFormatModal(false);
        }}
        userEmail={user?.email}
      />

      <BootstrapDialog
        sx={{ '& .MuiDialog-paper': { width: '100%', maxHeight: 670 } }}
        maxWidth="md"
        onClose={handleCreateTicketModalClose}
        aria-labelledby="customized-dialog-title"
        open={openTicketModal}
      >
        <div id="generate_ticket_info">
          <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCreateTicketModalClose}>
            {allIssueType.map((item) =>
              item.issueKey === issueType ? `${item.issueType} - ${showDeliveryModal.sub_order_no}` : null
            )}
          </BootstrapDialogTitle>

          <Divider orientation="horizontal" flexItem />

          <DialogContent>
            {showModalLoader ? (
              <div style={{ width: '100%', textAlign: 'center', padding: '100px' }}>
                <CircularProgress sx={{ color: SystemColor.originColor }} size={24} color="inherit" />
              </div>
            ) : (
              <>
                <div>
                  <TextField
                    id="outlined-multiline-static"
                    multiline
                    style={{ width: '100%' }}
                    rows={showDeliveryModal.to_report_found === 'DPWU_Received' ? 11 : 5}
                    defaultValue={ticketMessageContent}
                    onChange={(e) => {
                      setTicketMessageContent(e.target.value);
                    }}
                  />
                </div>
                <br />
                {showDeliveryModal.to_report_found === 'DPWU_Received' ? (
                  <>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={3} lg={3} sx={{ paddingBottom: '10px' }}>
                        <FormControl sx={{ width: '100%' }}>
                          <InputLabel id="selectPacketCondition">Packet Condition</InputLabel>
                          <Select
                            labelId="selectPacketCondition"
                            value={selectPacketCondition || ''}
                            label="Packet Condition"
                            onChange={(event) => {
                              setSelectPacketCondition(event.target.value);
                            }}
                          >
                            {selectPacketConditionOptions.map((name) => (
                              <MenuItem key={name} value={name}>
                                {name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={4} sx={{ paddingBottom: '10px' }}>
                        <FormControl sx={{ width: '100%' }}>
                          <TextField
                            type="text"
                            placeholder="Click Here To Scan Barcode Id"
                            value={barcodeId}
                            onChange={(event) => {
                              setBarcodeId(event.target.value);
                            }}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={3} lg={3}>
                        <Dropzone onDrop={onDropBarcode} multiple maxSize={20000000} accept={'image/*'}>
                          {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()}>
                              <input {...getInputProps()} />
                              <Button
                                variant="outlined"
                                style={{
                                  width: '-webkit-fill-available',
                                  justifyContent: 'space-around',
                                }}
                              >
                                <Iconify icon="material-symbols:upload-rounded" width={30} height={30} />
                                Barcode Image
                              </Button>
                            </div>
                          )}
                        </Dropzone>
                        {barcodeList.map((item, index) => (
                          <div
                            key={item + index}
                            style={{
                              width: '-webkit-fill-available',
                              display: 'flex',
                              marginTop: '5px',
                            }}
                          >
                            <Iconify icon="material-symbols:image-outline" width={25} height={25} />
                            <div style={{ width: 160 }}>{truncate(item.name)}</div>

                            <Iconify
                              icon="ic:round-delete-forever"
                              width={25}
                              height={25}
                              style={{ color: 'red' }}
                              onClick={() => {
                                removeImg(index, 'barcode');
                              }}
                            />
                          </div>
                        ))}
                      </Grid>
                      <Grid item xs={12} sm={6} md={3} lg={3}>
                        <Dropzone onDrop={onDropProduct} multiple maxSize={20000000} accept={'image/*'}>
                          {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()}>
                              <input {...getInputProps()} />
                              <Button
                                variant="outlined"
                                style={{
                                  width: '-webkit-fill-available',
                                  justifyContent: 'space-around',
                                }}
                              >
                                <Iconify icon="material-symbols:upload-rounded" width={30} height={30} />
                                Product Image
                                <span
                                  style={{
                                    color: 'red',
                                    fontSize: '15px',
                                    marginLeft: '-13px',
                                  }}
                                >
                                  *
                                </span>
                              </Button>
                            </div>
                          )}
                        </Dropzone>
                        {productList.map((item, index) => (
                          <div
                            key={item + index}
                            style={{
                              width: '-webkit-fill-available',
                              display: 'flex',
                              marginTop: '5px',
                            }}
                          >
                            <Iconify icon="material-symbols:image-outline" width={25} height={25} />
                            <div style={{ width: 160 }}>{truncate(item.name)}</div>

                            <Iconify
                              icon="ic:round-delete-forever"
                              width={25}
                              height={25}
                              style={{ color: 'red' }}
                              onClick={() => {
                                removeImg(index, 'product');
                              }}
                            />
                          </div>
                        ))}
                      </Grid>
                      <Grid item xs={12} sm={6} md={3} lg={3}>
                        <div>
                          <Dropzone onDrop={onDropAwbImages} multiple maxSize={8000000} accept={'image/*'}>
                            {({ getRootProps, getInputProps }) => (
                              <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <Button
                                  variant="outlined"
                                  style={{
                                    width: '-webkit-fill-available',
                                    justifyContent: 'space-around',
                                  }}
                                >
                                  <Iconify icon="material-symbols:upload-rounded" width={30} height={30} />
                                  AWB Image
                                  <span
                                    style={{
                                      color: 'red',
                                      fontSize: '15px',
                                      marginLeft: '-22px',
                                    }}
                                  >
                                    *
                                  </span>
                                </Button>
                              </div>
                            )}
                          </Dropzone>

                          {awbList.map((item, index) => (
                            <div
                              key={item + index}
                              style={{
                                width: '-webkit-fill-available',
                                display: 'flex',
                                marginTop: '5px',
                              }}
                            >
                              <Iconify icon="material-symbols:image-outline" width={25} height={25} />
                              <div style={{ width: 160 }}>{truncate(item.name)}</div>

                              <Iconify
                                icon="ic:round-delete-forever"
                                width={25}
                                height={25}
                                style={{ color: 'red' }}
                                onClick={() => {
                                  removeImg(index, 'awb');
                                }}
                              />
                            </div>
                          ))}
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3} lg={3}>
                        <div>
                          <Dropzone onDrop={onDropUnpackingVideo} multiple accept={'video/*'}>
                            {({ getRootProps, getInputProps }) => (
                              <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <Button
                                  variant="outlined"
                                  style={{
                                    width: '-webkit-fill-available',
                                    justifyContent: 'space-around',
                                  }}
                                >
                                  <Iconify icon="material-symbols:upload-rounded" width={30} height={30} />
                                  Unpacking Video
                                  <span
                                    style={{
                                      color: 'red',
                                      fontSize: '15px',
                                      marginLeft: '-6px',
                                    }}
                                  >
                                    *
                                  </span>
                                </Button>
                              </div>
                            )}
                          </Dropzone>

                          {videoList.map((item, index) => (
                            <div
                              key={item + index}
                              style={{
                                width: '-webkit-fill-available',
                                display: 'flex',
                                marginTop: '5px',
                              }}
                            >
                              <Iconify icon="material-symbols:video-library-outline-rounded" width={25} height={25} />
                              <div style={{ width: 160 }}>{truncate(item.name)}</div>

                              <Iconify
                                icon="ic:round-delete-forever"
                                width={25}
                                height={25}
                                style={{ color: 'red' }}
                                onClick={() => {
                                  removeImg(index, 'video');
                                }}
                              />
                            </div>
                          ))}
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3} lg={3}>
                        <div>
                          <Dropzone onDrop={onDropAttachment} multiple maxSize={8000000}>
                            {({ getRootProps, getInputProps }) => (
                              <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <Button
                                  variant="outlined"
                                  style={{
                                    width: '-webkit-fill-available',
                                    justifyContent: 'space-around',
                                  }}
                                >
                                  <Iconify icon="material-symbols:upload-rounded" width={30} height={30} />
                                  Extra Attachments
                                </Button>
                              </div>
                            )}
                          </Dropzone>

                          {attachmentsList.map((item, index) => (
                            <div
                              key={item + index}
                              style={{
                                width: '-webkit-fill-available',
                                display: 'flex',
                                marginTop: '5px',
                              }}
                            >
                              <Iconify icon="mdi:attachment-vertical" width={25} height={25} />
                              <div style={{ width: 160 }}>{truncate(item.name)}</div>

                              <Iconify
                                icon="ic:round-delete-forever"
                                width={25}
                                height={25}
                                style={{ color: 'red' }}
                                onClick={() => {
                                  removeImg(index, 'attachments');
                                }}
                              />
                            </div>
                          ))}
                        </div>
                      </Grid>
                    </Grid>
                  </>
                ) : null}
              </>
            )}
          </DialogContent>
          <Divider orientation="horizontal" flexItem />
          <DialogActions>
            <div style={{ textAlign: 'end' }}>
              <Button
                variant="contained"
                style={{ marginRight: '7px', backgroundColor: 'darkorange' }}
                onClick={() => handleOnClickCopy(ticketMessageContent, enqueueSnackbar)}
              >
                Copy Content
              </Button>
              <Button
                variant="contained"
                style={{ marginRight: '7px', backgroundColor: 'green' }}
                onClick={handleMarkAsReported}
              >
                Mark As Reported
              </Button>
              <Button
                variant="contained"
                style={{ marginRight: '7px', backgroundColor: 'brown' }}
                onClick={handleRemoveToReport}
              >
                Remove Ticket
              </Button>
              {/* TODO In Development UnComment And Test Here For Test Ticket Payload */}
              {/*
              <Button variant="contained" disabled={showModalLoader} onClick={generateTicket} id="GenerateTicket">
                Generate Ticket
              </Button>
              */}

              {/* TODO Enabled Desktop Ticket Button Uncomment This And Comment Upper Button */}
              {/*
                {isDesktopEnvironment
                    ?
                    <>
                        {!generateTicketLoader
                            ?
                            (
                                <Button variant="contained" id="generate_ticket">Generate Ticket</Button>
                            )
                            :
                            (
                                <div style={{width: '100%', textAlign: 'center', padding: '100px'}}>
                                    <CircularProgress sx={{color: SystemColor.originColor}} size={24} color="inherit"/>
                                </div>
                            )
                        }
                    </>
                    :
                    <>
                    <Button variant="contained" onClick={generateTicket}>Generate Ticket</Button>
                    </>
                }
              */}
            </div>
          </DialogActions>
        </div>

        <div style={{ display: 'none', backgroundColor: '#eafcd2' }} id="generate_ticket_info_success">
          <Alert
            sx={{ width: '100%' }}
            severity="success"
            onClose={() => {
              handleSuccess(showDeliveryModal.id);
            }}
          >
            <AlertTitle style={{ paddingTop: '2.5px' }}>Success</AlertTitle>
            <span style={{ fontWeight: 'bold', marginLeft: '15px' }}>
              Ticket Id :{' '}
              <span style={{ fontWeight: 'bold', marginLeft: '5px' }} id="shopdeck_generate_ticket_success_ticket_id" />
            </span>
            <br />
            <span style={{ fontWeight: 'bold', marginLeft: '15px' }}>
              Message :{' '}
              <span style={{ fontWeight: 'bold', marginLeft: '5px' }} id="shopdeck_generate_ticket_success_message" />
            </span>
          </Alert>
          <div style={{ textAlign: 'end', marginRight: '15px', marginBottom: '10px' }}>
            <Button
              variant="contained"
              style={{ background: '#a2e46d' }}
              onClick={() => {
                handleSuccess(showDeliveryModal.id);
              }}
            >
              {' '}
              Ok{' '}
            </Button>
          </div>
        </div>

        <div style={{ display: 'none', backgroundColor: '#fde7db' }} id="generate_ticket_info_error">
          <Alert sx={{ width: '100%' }} severity="error" onClose={handleCreateTicketModalClose}>
            <AlertTitle style={{ paddingTop: '2.5px' }}>Error</AlertTitle>
            <span style={{ fontWeight: 'bold', marginLeft: '15px' }}>
              Ticket Id :{' '}
              <span style={{ fontWeight: 'bold', marginLeft: '5px' }} id="shopdeck_generate_ticket_error_ticket_id" />
            </span>
            <br />
            <span style={{ fontWeight: 'bold', marginLeft: '15px' }}>
              Message :{' '}
              <span style={{ fontWeight: 'bold', marginLeft: '5px' }} id="shopdeck_generate_ticket_error_message" />
            </span>
          </Alert>
          <div style={{ textAlign: 'end', marginRight: '15px', marginBottom: '10px' }}>
            <Button variant="contained" style={{ background: '#eb9381' }} onClick={handleCreateTicketModalClose}>
              {' '}
              Ok{' '}
            </Button>
          </div>
        </div>
      </BootstrapDialog>
      <input type="hidden" id={'create_tiket_hidden_field'} defaultValue={createTiketHiddenFieldValue} />
    </>
  );
}
