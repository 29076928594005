/* eslint-disable */
import {
    Avatar,
    Box,
    Button,
    Checkbox,
    Container, DialogContentText,
    Divider,
    FormControlLabel,
    Grid, List, ListItemAvatar, ListItemButton,
    MenuItem,
    Popover,
    Typography,
} from '@mui/material';
import {useSnackbar} from 'notistack';
import React, {useEffect, useRef, useState} from 'react';
import AccountSelector from '../../components/Common/AccountSelector';
import Iconify from '../../components/Iconify';
import LinearProgressWithLabel from '../../components/LinerProgressbar';
import Page from '../../components/Page';
import useAuth from '../../hooks/useAuth';
import useSettings from '../../hooks/useSettings';
import {CreatePostAPICall, Home, MyLinkedAccount} from '../../utils/apis';
import {
    CapitalizeFirstLetter,
    IOSSwitch,
    SaveUserExistingAcTypes,
    saveAccountData,
    isSuperAdminEnvironment, getS3FileURL,
} from '../../utils/utilities';
import SortableListComponent from './components/SortableList';
import AppWelcome from './components/AppWelcome';
import SentCourier from './components/SentCourier';
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import NotificationIcon from "../../assets/NotificationIcon";
import moment from "moment";
import CountdownTimer from "../../components/Common/CustomTimer";
import {BUISSMASTER_ASSETS} from "../../utils/Constants";

const cards = [
    [
        {
            title: 'Orders Today',
            key: 'orders_today',
            order: 1
        },
        {
            title: 'Today Pickup Cancel',
            key: 'cancel_orders_today',
            order: 2
        },
        {
            title: 'Today Pickup Exchange',
            key: 'exchange_orders_today',
            order: 3
        },
        {
            title: 'New Returns Today',
            key: 'new_returns_today',
            order: 4
        },
        {
            title: 'Delivered Today',
            key: 'delivered_today',
            order: 5
        },
        {
            title: 'Today Return Scan Orders',
            key: 'received_today',
            order: 6
        },
        {
            title: 'Cancel Received Today',
            key: 'cancel_received_today',
            order: 7
        },
        {
            title: 'Pickup Scanned Today',
            key: 'pickup_scanned_today',
            order: 8
        },
        {
            title: 'Pickup Scanned Remain',
            key: 'pickup_remain_scanned_today',
            order: 9
        },
        {
            title: `Today's Invoice`,
            key: 'invoice_today',
            order: 10
        },
        {
            title: 'Other Day Scanned',
            key: 'other_day_scanned',
            order: 11
        },
        {
            title: `Today's Investment`,
            key: 'today_investment',
            order: 12
        },
        {
            title: `Filled Investment Order`,
            key: 'filled_investment_order',
            order: 13
        },
        {
            title: `Not Filled Investment Order`,
            key: 'not_filled_investment_order',
            order: 14
        }
    ],
    [
        {
            title: 'Combo By Quantity (Orders/Quantity)',
            key1: 'combo_by_qt',
            key2: 'combo_by_qt_qts',
            order: 15
        },
        {
            title: 'Combo By Sent AWB (Sent AWB/Orders)',
            key1: 'combo_by_awb',
            key2: 'combo_by_awb_orders',
            order: 16
        }
    ],
    [
        {
            title: `Today's Sale`,
            order: 1,
            key1: 'total_sale',
            key2: 'selling_price_found',
        },
        {
            title: `Today's Return Sale`,
            order: 2,
            key1: 'total_return_sale',
            key2: 'total_return_price_found',
        },
        {
            title: `Today's Invoice Sale`,
            order: 3,
            key1: 'total_invoice_sale',
            key2: 'selling_price_found_invoice',
        },
    ]]

const availableAccounts = ["flipkart", "glowroad", "meesho", "shopdeck", "snapdeal", "limeroad"]
export default function HomePage() {
    const {enqueueSnackbar} = useSnackbar();
    const [notifications, setNotifications] = useState([]);
    const [posterImage,setPosterImage] = useState([]);
    // console.log(posterImage)
    const {user} = useAuth();
    const localUser = window.localStorage.getItem('userEmailId');
    const {themeStretch} = useSettings();
    const [dataA, setDataA] = useState(true)
    const [staticOrdersDataNew, setStaticOrdersDataNew] = useState([
        ...cards[0].map((card) => ({
            title: card.title,
            order: card.order,
            id: card.order,
            isShow: true,
            plateformViseCount: {}
        })),
        ...cards[1].map((card) => (
            {
                title: card.title,
                order: card.order,
                id: card.order,
                isMultipleValue: true,
                isShow: true,
                plateformViseComboCount: {},
                plateformViseCount: {}
            }
        ))
    ])
    const [allStaticOrderDataNew, setAllStaticOrderDataNew] = useState([]);
    const [userBalanceOrderCount, setUserBalanceOrderCount] = useState({
        ac_balance: 0,
        orders: 0,
    });
    const [userWiseOrderData, setUserWiseOrderData] = useState([]);
    const [progressBarValue, setProgressBarValue] = useState(0);
    const [userData, setUserData] = useState([]);
    const [isAccountSelect, setIsAccountSelect] = useState(true);
    const [open, setOpen] = useState(false);
    const [allSentCourierData, setAllSentCourierData] = useState({});
    const [sentCourierData, setSentCourierData] = useState({});
    const [acTypes, setAcTypes] = useState([]);
    const [openNotification, setOpenNotification] = useState(false);

    const [totalSaleRetunrData, setTotalSaleRetunrData] = useState([
        ...cards[2].map((card) => (
            {
                title: card.title,
                order: card.order,
                id: card.order,
                isMultipleValue: true,
                isShow: true,
                plateformViseComboCount: {},
                plateformViseCount: {}
            }
        )),
    ]);

    const [staticTotalSaleRetunrData, setStaticTotalSaleRetunrData] = useState([]);

    const [greeting, setGreeting] = useState('');


    // This For Getting Dashboard Data
    const getDashboardDetailsAcWise = async (item) => {
        const body = {
            actionType: 'getOrdersCounts',
            action: 'accountWise',
            username: user?.email ? user.email : localUser,
            acType: item.AcType,
            tpEmailId: item.Account.Email,
            subAccountId: item.Account.SupplierId,
        };
        const response = await CreatePostAPICall(enqueueSnackbar, Home, body);
        if (response) {
            return response.data.body;
        }
    };

    const showNotificationDialog = () => {
        const lastNotificationTime = localStorage.getItem('lastNotificationTime');
        const parsedDate = moment(lastNotificationTime|| new Date(), 'DD HH:mm:ss.SSSSSSZ');
        const currentDate = moment(new Date(), 'DD HH:mm:ss.SSSSSSZ');

        if (currentDate.isAfter(parsedDate.add(3, 'hours')) || !lastNotificationTime) {
            localStorage.setItem('lastNotificationTime', currentDate.format('DD HH:mm:ss.SSSSSSZ'));
            setOpenNotification(true);
        }
    }
    const handleCloseNotification = () => {
        setOpenNotification(false);
    };

    // get user balance and order count api call
    useEffect(async () => {
        const getUserBalance = await CreatePostAPICall(enqueueSnackbar, Home, {
            username: user?.email ? user.email : localUser,
            actionType: 'getUserBalance',
        });

        setUserBalanceOrderCount({
            ac_balance: getUserBalance?.data?.body?.ac_balance,
            orders: getUserBalance?.data?.body?.orders,
        });
    }, []);

    useEffect(() => {
        const currentTime = new Date();
        const currentHour = currentTime.getHours();

        if (currentHour < 12) {
            setGreeting('Good Morning');
        } else if (currentHour < 17) {
            setGreeting('Good Afternoon');
        } else if (currentHour < 21) {
            setGreeting('Good Evening');
        } else {
            setGreeting('Good Night');
        }
    }, []);

    // For Getting Notification
    useEffect(async () => {
        const notificationResponse = await CreatePostAPICall(enqueueSnackbar, Home, {
            username: user?.email ? user.email : localUser,
            actionType: 'getNotifications',
        });
        setNotifications([]);
        setPosterImage([])
        if (notificationResponse.data.body) {
            const commonNotification = notificationResponse.data.body.common_notifications;
            const userNotification = notificationResponse.data.body.userwise_notifications;
            const ntfcs = [...commonNotification, ...userNotification,
                // {
                //     "id": 9,
                //     "icon": "comment",
                //     "color": "Poster",
                //     "content": "bmImage"
                // }
                ]
            const postersData = ntfcs.filter((data)=>data.color === "Poster")
            const posterImageList = []
            for(const data of postersData) {
                const imageBlob = await getS3FileURL(`posters/${data.content}.jpg`, BUISSMASTER_ASSETS)
                posterImageList.push(imageBlob)
            }
            // console.log("values", posterImageList)
            setPosterImage(posterImageList)
            showNotificationDialog()
            setNotifications(ntfcs.filter((data)=>data.color !== "Poster"));

        }
    }, []);

    // store card data in state
    const storeValueInStateNew = (tempData) => {
        if (tempData) {
            setStaticOrdersDataNew([
                ...cards[0].map((card) => ({
                    title: card.title,
                    order: card.order,
                    id: card.order,
                    isShow: true,
                    plateformViseCount: tempData[card.key]
                })),
                ...cards[1].map((card) => (
                    {
                        title: card.title,
                        order: card.order,
                        id: card.order,
                        isMultipleValue: true,
                        isShow: true,
                        plateformViseComboCount: tempData[card.key2],
                        plateformViseCount: tempData[card.key1]
                    }
                ))
            ]);
            setTotalSaleRetunrData([
                ...cards[2].map((card) => (
                    {
                        title: card.title,
                        order: card.order,
                        id: card.order,
                        isMultipleValue: true,
                        isShow: true,
                        plateformViseComboCount: tempData[card.key2],
                        plateformViseCount: tempData[card.key1]
                    }
                ))
            ]);
        }
    };


    // manage order count, manage user wise order count then store
    const handleOrdersData = async (userData) => {
        const tempDataNew = {
            orders_today: {},
            new_returns_today: {},
            delivered_today: {},
            received_today: {},
            cancel_orders_today: {},
            exchange_orders_today: {},
            combo_by_qt: {},
            combo_by_awb: {},
            combo_by_qt_qts: {},
            combo_by_awb_orders: {},
            cancel_received_today: {},
            pickup_scanned_today: {},
            pickup_remain_scanned_today: {},
            invoice_today: {},
            other_day_scanned: {},
            today_investment: {},
            not_filled_investment_order: {},
            filled_investment_order: {},
            total_sale: {},
            selling_price_found: {},
            total_return_sale: {},
            total_return_price_found: {},
        }
        const tempCourierObj = {}
        availableAccounts.forEach((act => {
            tempCourierObj[act] = {name: `${act} Courier Count (Sent AWB / Orders)`}
        }))


        const tempUserOrderData = [];

        for (let index = 0; index < userData.length; index++) {
            const value = await getDashboardDetailsAcWise(userData[index]);

            const tempObj = {
                tpEmailId: userData[index].Account.Email,
                acType: userData[index].AcType,
                orderData: value,
                sentCourier: value?.sentCourier,
            };

            tempUserOrderData.push(tempObj);
            // console.log(temp)

            // new
            availableAccounts.forEach((act) => {
                if (value[act]) { // Check if value[act] is defined
                    // console.log(act);
                    Object.keys(value[act]).forEach((key) => {
                        if (!tempDataNew[key]) {
                            tempDataNew[key] = {};
                        }
                        if (!tempDataNew[key][act]) {
                            tempDataNew[key][act] = 0;
                        }
                        tempDataNew[key][act] += value[act][key];
                    });
                } else {
                    console.warn(`Account ${act} is not defined in value.`);
                }
            });

            // console.log("tempDataNew", tempDataNew)

            availableAccounts.forEach((act) => {
                if (value?.sentCourier[act]) {
                    Object.entries(value?.sentCourier[act]).forEach(([key, value]) => {
                        if (tempCourierObj[act].hasOwnProperty(key)) {
                            tempCourierObj[act][key] = `${Number(tempCourierObj[act][key].split('/')[0]) + Number(value.split('/')[0])
                            }/${Number(tempCourierObj[act][key].split('/')[1]) + Number(value.split('/')[1])}`;
                        } else {
                            tempCourierObj[act][key] = value;
                        }
                    });
                }
            })

            setSentCourierData(tempCourierObj);

            setAllSentCourierData(tempCourierObj);


            storeValueInStateNew(tempDataNew)


            setAllStaticOrderDataNew([
                ...cards[0].map((card) => ({
                    title: card.title,
                    order: card.order,
                    id: card.order,
                    isShow: true,
                    plateformViseCount: tempDataNew[card.key]
                })),
                ...cards[1].map((card) => (
                    {
                        title: card.title,
                        order: card.order,
                        id: card.order,
                        isMultipleValue: true,
                        isShow: true,
                        plateformViseComboCount: tempDataNew[card.key2],
                        plateformViseCount: tempDataNew[card.key1]
                    }
                ))
            ])


            setStaticTotalSaleRetunrData([
                ...cards[1].map((card) => (
                    {
                        title: card.title,
                        order: card.order,
                        id: card.order,
                        isMultipleValue: true,
                        isShow: true,
                        plateformViseComboCount: tempDataNew[card.key2],
                        plateformViseCount: tempDataNew[card.key1]
                    }
                )),
            ]);

            setUserWiseOrderData(tempUserOrderData);

            const tempValue = ((index + 1) / userData.length) * 100;
            if (tempValue === 100) {
                setIsAccountSelect(false);
            }
            setProgressBarValue(tempValue);
        }
    };

    // For Getting Ac Data To Set In Local Storage
    useEffect(async () => {
        const acData = await CreatePostAPICall(enqueueSnackbar, MyLinkedAccount, {
            username: user?.email ? user.email : localUser,
            actionType: 'getAll',
        });
        if (acData) {
            const data = [];
            const filteredData = [];
            const existAcTypes = [];
            for (let index = 0; index < acData.data.body.length; index++) {
                const cell = acData.data.body[index];

                const newData = {};

                newData.Account = {
                    Name: cell.Name,
                    Email: cell.Email,
                    SupplierId: cell.SupplierId,
                };
                newData.no = cell.no;
                newData.AcType = cell.AcType;
                newData.Mobile_No = cell.Mobile_No;
                newData.Image_Link = cell.Image_Link;
                newData.identifier = cell.identifier;
                newData.SellerId = cell.SellerId;
                newData.LocationName = cell.LocationName;
                newData.accountStatus = cell.accountStatus;
                newData.isMaskingEnabled = cell.isMaskingEnabled;
                newData.LBHW = {
                    "L":cell.L,
                    "B":cell.B,
                    "H":cell.H,
                    "W":cell.W
                }

                data.push(newData);

                filteredData.push({
                    value: newData.Account.SupplierId,
                    name: `${CapitalizeFirstLetter(newData.AcType)} - ${newData.Account.Email} - ${newData.Account.Name}`,
                    AcType: newData.AcType,
                    Email: newData.Account.Email,
                    cName: newData.Account.Name,
                    Mobile_No: newData.Mobile_No,
                    Image_Link: newData.Image_Link,
                    identifier: newData.identifier,
                    accountStatus: newData.accountStatus,
                    SellerId: newData.SellerId,
                    LocationName: newData.LocationName,
                });
            }
            // console.log("data=>", filteredData)

            availableAccounts.forEach((account) => {
                    if (filteredData.some((e) => e.AcType === account)) {
                        filteredData.unshift({
                            value: account,
                            name: `Select All ${account}`,
                        });
                        existAcTypes.push(account);
                    }
                }
            )
            const filteredData2 = data.filter(item => {
                if (isSuperAdminEnvironment) {
                    return true;
                } else {
                    return item.accountStatus === "ACTIVATE";
                }
            });
            // console.log("filteredData", filteredData)
            // console.log("existAcTypes", existAcTypes)
            SaveUserExistingAcTypes(filteredData2);
            setAcTypes(existAcTypes);
            setUserData(filteredData);
            handleOrdersData(filteredData2);
            saveAccountData(filteredData2);
        }
    }, []);

    // select user and order data show by selected user
    const handleSelectedAccount = async (event, value) => {

        const tempDataNew = {
            orders_today: {},
            new_returns_today: {},
            delivered_today: {},
            received_today: {},
            cancel_orders_today: {},
            exchange_orders_today: {},
            combo_by_qt: {},
            combo_by_awb: {},
            combo_by_qt_qts: {},
            combo_by_awb_orders: {},
            cancel_received_today: {},
            pickup_scanned_today: {},
            pickup_remain_scanned_today: {},
            invoice_today: {},
            other_day_scanned: {},
            today_investment: {},
            not_filled_investment_order: {},
            filled_investment_order: {},
            total_sale: {},
            selling_price_found: {},
            total_return_sale: {},
            total_return_price_found: {},
        }
        let allTempUser = [];
        let tempUserFlipkart = [];
        let tempUserGlowroad = [];
        let tempUserMeesho = [];
        let tempUserShopdeck = [];
        let tempUserSnapdeal = [];
        const indexUser = [];


        const tempCourierObj = {}
        availableAccounts.forEach((act => {
            tempCourierObj[act] = {name: `${act} Courier Count (Sent AWB / Orders)`}
        }))

        if (value.length > 0) {
            for (let i = 0; i < value.length; i++) {
                const element = value[i];
                const userIndex = userWiseOrderData.findIndex(
                    (item) => item.tpEmailId === element.Email && item.acType === element.AcType
                );

                if (element.value === 'flipkart') {
                    tempUserFlipkart = userWiseOrderData.filter((value) => value.acType === 'flipkart');
                }
                if (element.value === 'glowroad') {
                    tempUserGlowroad = userWiseOrderData.filter((value) => value.acType === 'glowroad');
                }
                if (element.value === 'meesho') {
                    tempUserMeesho = userWiseOrderData.filter((value) => value.acType === 'meesho');
                }
                if (element.value === 'shopdeck') {
                    tempUserShopdeck = userWiseOrderData.filter((value) => value.acType === 'shopdeck');
                }
                if (element.value === 'snapdeal') {
                    tempUserSnapdeal = userWiseOrderData.filter((value) => value.acType === 'snapdeal');
                }
                if (userIndex > -1) {
                    indexUser.push(userWiseOrderData[userIndex]);
                }
            }
            allTempUser = [
                ...tempUserFlipkart,
                ...tempUserGlowroad,
                ...tempUserMeesho,
                ...tempUserShopdeck,
                ...tempUserSnapdeal,
                ...indexUser,
            ];
        }

        if (allTempUser.length > 0) {
            for (let i = 0; i < allTempUser.length; i++) {
                const element = allTempUser[i];
                const orderData = element.orderData

                availableAccounts.forEach((act) => {
                    if (orderData?.sentCourier[act]) {
                        Object.entries(orderData?.sentCourier[act]).forEach(([key, v]) => {
                            if (tempCourierObj[act].hasOwnProperty(key)) {
                                tempCourierObj[act][key] = `${Number(tempCourierObj[act][key].split('/')[0]) + Number(v.split('/')[0])
                                }/${Number(tempCourierObj[act][key].split('/')[1]) + Number(v.split('/')[1])}`;
                            } else {
                                tempCourierObj[act][key] = v;
                            }
                        });
                    }
                })

                availableAccounts.forEach((act) => {
                    Object.keys(orderData[act]).forEach((key) => {
                        if (!tempDataNew[key]) {
                            tempDataNew[key] = {}
                        }
                        if (!tempDataNew[key][act]) {
                            tempDataNew[key][act] = 0
                        }
                        tempDataNew[key][act] += orderData[act][key]

                    })
                })

                storeValueInStateNew(tempDataNew);


                setSentCourierData(tempCourierObj);
            }
        } else {
            setStaticOrdersDataNew(allStaticOrderDataNew);
            setSentCourierData(allSentCourierData);
            setTotalSaleRetunrData(staticTotalSaleRetunrData);
        }
    };

    // card data show by order num wise
    const orderNumberWiseDataSet = (shortData) => {
        setStaticOrdersDataNew(shortData);
    };

    // open popup for selecting which card to show
    const handleOpen = () => {
        setOpen(true);
    };

    // close popup for selecting which card to show
    const handleClose = () => {
        setOpen(false);
    };

    // handle card show or not
    const handleShowCard = (checked, value) => {
        const tempCardData = [...staticOrdersDataNew];

        const cardIndex = tempCardData.findIndex((card) => card.title === value.title);

        if (cardIndex > -1) {
            tempCardData[cardIndex].isShow = checked;
        }

        const tempData = [];
        for (let index = 0; index < tempCardData.length; index++) {
            const element = tempCardData[index];

            const tempObj = {
                order: element.order,
                title: element.title,
                id: element.id,
                isShow: element.isShow,
            };
            tempData.push(tempObj);
        }
        localStorage.setItem('orderWiseCard', JSON.stringify(tempData));
        setStaticOrdersDataNew(tempCardData);
    };

    const NotificationDialog = () => {
        // console.log("notifications",notifications)
        return (
            <Dialog
                open={openNotification}
                onClose={handleCloseNotification}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"

            >
                {posterImage.length <=0 && <DialogTitle id="alert-dialog-title"
                              style={{
                                  borderBottom: "1px dashed rgba(145, 158, 171, 0.24)"
                              }}
                ><>
                    <Box sx={{flexGrow: 1}}>
                        <Typography gutterBottom variant="subtitle1" sx={{textAlign: 'center'}}>
                            Notification(s)
                        </Typography>
                    </Box></>
                </DialogTitle>}
                <DialogContent>
                    {posterImage.length>0 ? <img  src={posterImage[0]} style={{width:"100%",maxHeight:"75vh"}}/> :<List disablePadding>
                        {notifications.map((notification) => (
                            <ListItemButton sx={{py: 2}} key={notification.id}>
                                <ListItemAvatar>
                                    <Avatar sx={{bgcolor: 'background.neutral'}}>
                                        <NotificationIcon/>
                                    </Avatar>
                                </ListItemAvatar>
                                <div>
                                    <p>{notification.content}</p>
                                    {notification.validUntil && <CountdownTimer endTime={notification.validUntil}/>}
                                </div>

                            </ListItemButton>
                        ))}
                    </List>}
                </DialogContent>
                <DialogActions style={{
                    paddingTop:0
                }}  >
                    <Button onClick={handleCloseNotification}>Close</Button>
                </DialogActions>
            </Dialog>
        );
    }


    return (
        <>
            <Page title="Home">
                <Container maxWidth={themeStretch ? false : 'xl'}>
                    {progressBarValue < 100 ? <LinearProgressWithLabel value={progressBarValue}/>:<NotificationDialog/>}


                    {/* TODO Save userEmailId Into Local Storage To Login And On Logout Clear Local Storage For Desktop */}
                    <input
                        type="hidden"
                        id={'userEmailId'}
                        value={user?.email ? user?.email.toLowerCase() : localUser ? localUser?.toLowerCase() : ''}
                    />

                    <Box>
                        <AppWelcome
                            staticOrdersData={userBalanceOrderCount}
                            displayName={user?.displayName}
                            notifications={notifications}
                            greeting={greeting}
                        />
                    </Box>

                    <Box sx={{pt: 2, display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                        <div>
                            <Grid container spacing={1}>
                                <Grid item md={4} sx={4}>
                                    <Button variant="text" onClick={handleOpen}>
                                        <Iconify icon="ic:baseline-remove-red-eye" height="24px" width="24px"/>
                                    </Button>

                                </Grid>
                                <Grid item md={6} sx={6}>
                                    <Box style={{paddingTop: "0.3rem"}}>
                                        <h4>Stick Card </h4>
                                    </Box>
                                </Grid>
                                <Grid item md={2} sx={2}>
                                    <Box style={{paddingTop: "0.2rem"}}>

                                        <IOSSwitch className="button" checked={!dataA} onClick={() => {
                                            setDataA(!dataA)
                                        }}></IOSSwitch>
                                    </Box>
                                </Grid>
                            </Grid>


                            <Popover
                                open={Boolean(open)}
                                anchorEl={open}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                                sx={{p: 0, ml: 0.5, width: 'auto'}}
                            >
                                <Typography variant="body2" sx={{m: 2, fontWeight: 700, fontSize: 16, mb: 0}}>
                                    Choose which card show
                                </Typography>
                                {staticOrdersDataNew.map((value, index) => {
                                    return (
                                        <>
                                            <MenuItem key={index}>
                                                <FormControlLabel
                                                    key={index}
                                                    control={
                                                        <Checkbox
                                                            id={`checkbox${index}`}
                                                            value={value.id}
                                                            checked={value.isShow}
                                                            onChange={(e) => {
                                                                handleShowCard(e.target.checked, value);
                                                            }}
                                                            name={value.title}
                                                        />
                                                    }
                                                    label={value.title}
                                                />
                                            </MenuItem>
                                        </>
                                    );
                                })}
                                {/*{staticOrdersData.map((value, index) => {*/}
                                {/*    return (*/}
                                {/*        <>*/}
                                {/*            <MenuItem key={index}>*/}
                                {/*                <FormControlLabel*/}
                                {/*                    key={index}*/}
                                {/*                    control={*/}

                                {/*                        <Checkbox*/}
                                {/*                            id={`checkbox${index}`}*/}
                                {/*                            value={value.id}*/}
                                {/*                            checked={value.isShow}*/}
                                {/*                            onChange={(e) => {*/}
                                {/*                                handleShowCard(e.target.checked, value);*/}
                                {/*                            }}*/}
                                {/*                            name={value.title}*/}
                                {/*                        />*/}
                                {/*                    }*/}
                                {/*                    label={value.title}*/}
                                {/*                />*/}
                                {/*            </MenuItem>*/}
                                {/*        </>*/}
                                {/*    );*/}
                                {/*})}*/}
                            </Popover>
                        </div>
                        <div style={{width: '50%'}}>
                            <AccountSelector
                                allData={userData}
                                onChangeHandler={handleSelectedAccount}
                                disabled={isAccountSelect}
                                size="small"
                            />
                        </div>
                    </Box>

                    <SortableListComponent
                        datas={dataA}
                        data={staticOrdersDataNew}
                        orderNumberWiseDataSet={orderNumberWiseDataSet}
                        acTypes={acTypes}
                    />

                    <Box>
                        <Box
                            sx={{
                                display: 'grid',
                                gap: {xs: 2, lg: 2},
                                gridTemplateColumns: {
                                    xs: 'repeat(1, 1fr)',
                                    sm: 'repeat(2, 1fr)',
                                    md: 'repeat(3, 1fr)',
                                },
                                position: 'relative',
                                pt: 7,
                            }}
                        >
                            {acTypes.map(acType => {
                                return sentCourierData[acType] ? (
                                    <SentCourier courierData={sentCourierData[acType]}/>
                                ) : (<></>)
                            })}
                        </Box>
                    </Box>
                </Container>

                <br/>
                <br/>
                <Divider/>
                <Container maxWidth={themeStretch ? false : 'xl'}>
                    <SortableListComponent data={totalSaleRetunrData} acTypes={acTypes} datas={dataA}/>
                </Container>
                <br/>
                <br/>
            </Page>
        </>
    );
}
