import { Grid, Card } from '@mui/material';
import AccountBillingInvoiceHistory from './AccountBillingInvoiceHistory';

// ----------------------------------------------------------------------

export default function AccountBilling() {
  return (
    <Grid item xs={12} md={6}>
      <Card sx={{ p: 3 }}>
        <AccountBillingInvoiceHistory />
      </Card>
    </Grid>
  );
}
