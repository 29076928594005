import axios from './axios';

// Uat/App Url
export const url =
  process.env.REACT_APP_ENV === 'APP' || process.env.REACT_APP_ENV === 'APP_DESKTOP'
    ? 'https://api.buissmaster.com/'
    : 'https://uat-api.buissmaster.com/';

// Admin Url
export const adminUrl = 'https://admin-api.buissmaster.com/';

// For PayTm
export const envType = process.env.REACT_APP_ENV.toString().includes('APP') ? 'APP' : 'NON-APP';

// Admin APIs
export const V2Management = `${adminUrl}v2/management`;

// Setting Page API
export const UserProfile = `${url}v2/userProfile`;

// Home Page API
export const Home = `${url}v2/penal/Home`;

// My Linked Account Page API
export const MyLinkedAccount = `${url}v2/penal/MyLinkedAccounts`;
export const AddMeeshoAccount = `${url}v2/addAccountMeesho`;
export const AddGlowroadAccount = `${url}v2/addAccountGlowRoad`;
export const AddCitymallAccount = `${url}v2/addAccountCitymall`;
export const AddSnapdealAccount = `${url}v2/addAccountSnapdeal`;
export const AddJiomartAccount = `${url}v2/addAccountJioMart`;
export const AddMyntraAccount = `${url}v2/addAccountMyntra`;
export const AddFlipkartAccount = `${url}v2/flipkartAddAccount`;
export const AddFlipkartAccountFBFLITE = `${url}v2/addAccountFbfLiteFlipKart`;

// Sync Orders Page API
export const MeeshoSaveExcelData = `${url}v2/saveData/Meesho`;
export const FlipkartSaveExcelData = `${url}v2/saveData/FlipKart`;
export const CitymallSaveExcelData = `${url}v2/saveData/Citymall`;
export const GlowroadSaveExcelData = `${url}v2/saveData/GlowRoad`;
export const SnapdealSaveExcelData = `${url}v2/saveData/SnapDeal`;
export const JiomartSaveExcelData = `${url}v2/saveData/JioMart`;
export const ShopdeckSaveExcelData = `${url}v2/saveData/ShopDeck`;
export const MyntraSaveExcelData = `${url}v2/saveData/Myntra`;
export const AmazonSaveExcelData = `${url}v2/saveData/Amazon`;
export const LimeroadSaveExcelData = `${url}v2/saveData/LimeRoad`;
export const SyncOrders = `${url}v2/penal/SyncOrders`;

// Order Analysis Page API
export const OrderAnalysis = `${url}v2/penal/OrderAnalysis`;

// Product Details Page API
export const MyProduct = `${url}v2/penal/MyProducts`;

// Scan Orders Page API
export const ScanOrder = `${url}v2/penal/ScanOrders`;

// Monthly Report Page API
export const MonthlyReport = `${url}v2/penal/MonthlyReports`;

// Claim Issue Page API
export const ClaimIssue = `${url}v2/penal/ClaimIssues`;
export const MeeshoCreateTicket = `${url}v2/penal/ClaimIssues/MeeshoCreateTicket`;

export const GlowroadCreateTicket = `${url}v2/penal/ClaimIssues/GlowroadCreateTicket`;
export const ShopdeckCreateTicket = `${url}v2/penal/ClaimIssues/ShopdeckCreateTicket`;
export const AmazonCreateTicket = `${url}v2/penal/ClaimIssues/AmazonCreateTicket`;
export const JiomartCreateTicket = `${url}v2/penal/ClaimIssues/JiomartCreateTicket`;
export const MyntraCreateTicket = `${url}v2/penal/ClaimIssues/MyntraCreateTicket`;
export const SnapdealCreateTicket = `${url}v2/penal/ClaimIssues/SnapdealCreateTicket`;

export const LimeRoadCreateTicket = `${url}v2/penal/ClaimIssues/LimeRoadCreateTicket`;

// Utilities Page API
export const Utility = `${url}v2/penal/Utility`;

// Common apis call : POST
export const CreatePostAPICall = async (enqueueSnackbar, path, body, successMessage = null, errorMessage = null ,messageType = null) => {
  try {
    const response = await axios.post(path, body);
    if (response && response.data.body && response.data.statusCode === 200) {
      if (successMessage) enqueueSnackbar(response.data.body);
      return response;
    }
    if (response && response.data.statusCode === 200) {
      if (successMessage) enqueueSnackbar(successMessage);
      return response;
    }
    if (response && response.data && response.data.errorMessage) {
      enqueueSnackbar(errorMessage || response.data.errorMessage, { variant: 'error' });
      return null;
    }
    enqueueSnackbar(errorMessage || response.data.body , { variant: messageType || 'error'});
    return null;
  } catch {
    enqueueSnackbar(errorMessage || 'Internal Server Error!', { variant: 'error' });
    return null;
  }
};
