/* eslint class-methods-use-this: ["error", { "exceptMethods": ["printInvoice"] }] */
import React from "react";
import {Page,Document,View,Image,Text,StyleSheet} from "@react-pdf/renderer";

// import buissLogo from "public/favicon/android-chrome-512x512.png";
// import buissLogo from "../../assets/images/Buiss.png";
import buissLogo from "../../assets/images/BUISSMASTER.png";
import Cornerimage from "../../assets/images/top_right_corner.png";
import bottomCornerimage from "../../assets/images/bottom_left_corner.png";

const Invoice = (props) => {
  const styles = StyleSheet.create({
    body: {
      padding: 45,
    },
    mainContainer: {
      border: "1px solid #495057",
      height: "100%",
    },

    CompanyHeading: {
      marginTop: 50,
      fontSize: 20,
      fontWeight: "bold",
    },
    CompanyText: {
      paddingTop:5,
      fontSize: 12,
    },
   Text: {
      fontSize: 12,
    },
    image: {
      margin: 20,
      height: 130,
      width: 100,
    },
    image1: {
      // margin: 20,
      height: 542,
      width: 450,
      textAlign:"top-right",
      align: "right",
      paddingRight:45,
      paddingTop:45,
    },
    header: {
      fontSize: 20,
      marginBottom: 5,
      textAlign: "center",
      color: "#000000",
      paddingRight: 5,
    },
    border: {
      borderBottom: 1,
      borderBottomColor: "#000000",
      borderTop: 1,
      borderTopColor: "#000000",
      padding: "3px 5px 5px",
    },
    invoiceNo: {
      flexDirection: "row",
    },
    invoiceDate: {
      flexDirection: "row",
    },
    invoiceNoText: {
      fontSize: 10,
      width: 100,
    },
    invoiceNoTextData: {
      fontSize: 10,
      fontWeight: "bold",
    },
    invoiceDateText: {
      fontSize: 10,
      width: 100,
    },
    invoiceDateTextData: {
      fontSize: 10,
      fontWeight: "bold",
    },
    billToContainer: {
      borderBottom: 1,
      borderBottomColor: "#000000",
      backgroundColor: "#ededed",
    },
    billToText: {
      fontSize: 10,
      fontWeight: "bold",
      paddingLeft: 5,
    },
    customerContainer: {
      padding: "5px 5px 15px",
    },
    customerHeading: {
      fontSize: 14,
      fontWeight: "bold",
    },
    customerText: {
      fontSize: 10,
    },
    tableContainerHeading: {
      borderTop: 1,
      borderTopColor: "#000000",
      borderBottom: 1,
      borderBottomColor: "#000000",
      backgroundColor: "#ededed",
      height: 45,
      flexDirection: "row",
    },
    tableNoView: {
      width: 35,
      borderRight: 1,
      borderRightColor: "#000000",
    },
    tableHeadingText: {
      fontSize: 12,
      fontWeight: "bold",
      textAlign: "center",
    },

    tableItemView: {
      width: 140,
      borderRight: 1,
      borderRightColor: "#000000",
    },

    tableHSNView: {
      width: 100,
      borderRight: 1,
      borderRightColor: "#000000",
    },
    tableRateView: {
      width: 100,
      borderRight: 1,
      borderRightColor: "#000000",
    },
    tableHeadingRate: {
      textAlign: "right",
    },

    tableGstView: {
      borderTop: 1,
      borderTopColor: "#000000",
      flexDirection: "row",
      height: "100%",
      marginTop: 10,
    },
    tableGstMainView: {
      borderRight: 1,
      borderRightColor: "#000000",
      width: 130,
    },
    tableGstPreView: {
      borderRight: 1,
      borderRightColor: "#000000",
      width: 65,
    },
    tableAmtView: {
      width: 65,
    },
    tableAmountView: {
      width: 110,
    },
    tableHeadingAmtText: {
      fontSize: 12,
      fontWeight: "bold",
      textAlign: "right",
    },
    tableContainerData: {
      borderBottom: 1,
      borderBottomColor: "#000000",
      flexDirection: "row",
      height: 60,
    },
    tableGstDataView: {
      flexDirection: "row",
      height: "100%",
    },

    tableDataText: {
      fontSize: 10,
      textAlign: "center",
    },

    tableDataAmtText: {
      fontSize: 10,
      textAlign: "right",
    },

    totalContainer: {
      flexDirection: "row",
    },

    totalThanksContainer: {
      paddingTop: 20,
      paddingLeft: 5,
      fontSize: 10,
      width: 400,
    },
    TotalInWordText: {
      fontSize: 12,
      fontStyle: "italic",
      fontWeight: "bold",
    },
    thanksText: {
      fontSize: 10,
      marginTop: 20,
    },
    TotalAmountContainer: {
      borderLeft: 1,
      borderLeftColor: "#000000",
      width: 350,
      borderBottom: 1,
      borderBottomColor: "#000000",
      height: 120,
      textAlign:"center"
    },
    notes_heading: {
      alignContent:'right',
      textAlign: "right",
      color:"#ff0000",
      fontSize: 8,
    },
    notes_content: {
      alignContent:'right',
      textAlign: "right",
      color:"rgb(0,0,0)",
      fontSize: 9,
    },
    notes: {
      borderTop: 1,
      borderLeftColor: "#000000",
      borderBottomColor: "#000000",
      width:"100%",
      height: 120,
      textAlign:"center"
    },
    subTotalView: {
      flexDirection: "row",
      padding: 3,
    },
    subTotalText: {
      fontSize: 10,
      width: "50%",
      textAlign: "right",
      paddingRight: 5,
    },
    paymentText: {
      fontSize: 10,
      width: "50%",
      textAlign: "right",
      paddingRight: 5,
      color: "red",
    },
    imageCornerContainer: {
      position: 'absolute',
      top: 0,
      right: 0,
    },
      imageCornerContainer_bottom: {
      position: 'absolute',
      bottom: 0,
      left: 0,
    },
  });

  return (
      <Document>
        <Page size="A3" style={styles.body}>
          <View style={styles.mainContainer}>


            <View style={{ flexDirection: "row" }}>
               <Image style={styles.image} src={buissLogo} />
              <View>
                <Text style={styles.CompanyHeading}>Buiss Master</Text>

                <Text style={styles.CompanyText}>GSTIN 24AAHFO5230B1ZT</Text>
                <Text style={styles.CompanyText}>314, Varni Plaza, Sudama Chowk,</Text>
                <Text style={styles.Text}>Mota Varachha, Surat, Gujarat - 394101</Text>
              </View>
            </View>

            <Text style={styles.header}>TAX INVOICE</Text>
            <View style={styles.border}>
              <View style={styles.invoiceNo}>
                <Text style={styles.invoiceNoText}># Invoice Number </Text>
                <Text style={styles.invoiceNoTextData}>: {props.orderId}</Text>
              </View>

              <View style={styles.invoiceDate}>
                <Text style={styles.invoiceDateText}>Invoice Date</Text>
                <Text style={styles.invoiceDateTextData}>: {props.invoiceDate}</Text>
              </View>
            </View>
            <View style={styles.billToContainer}>
              <Text style={styles.billToText}>Bill To</Text>
            </View>
            <View style={styles.customerContainer}>
              <Text style={styles.customerHeading}>{props.formName}</Text>
              <Text style={styles.customerText}>
                {props.address}
              </Text>
              <Text style={styles.customerText}>GSTIN {props.gstNo}</Text>
            </View>

            <View style={styles.tableContainerHeading}>
              <View style={styles.tableNoView}>
                <Text style={styles.tableHeadingText}>#</Text>
              </View>

              <View style={styles.tableItemView}>
                <Text style={styles.tableHeadingText}>Item & Description</Text>
              </View>

              <View style={styles.tableHSNView}>
                <Text style={styles.tableHeadingText}>HSN/SAC</Text>
              </View>

              <View style={styles.tableRateView}>
                <Text style={styles.tableHeadingAmtText}>Rate</Text>
              </View>

              <View style={styles.tableGstMainView}>
                <Text style={styles.tableHeadingText}>CGST</Text>
                <View style={styles.tableGstView}>
                  <View style={styles.tableGstPreView}>
                    <Text style={styles.tableHeadingAmtText}>%</Text>
                  </View>
                  <View style={styles.tableAmtView}>
                    <Text style={styles.tableHeadingAmtText}>Amt</Text>
                  </View>
                </View>
              </View>

              <View style={styles.tableGstMainView}>
                <Text style={styles.tableHeadingText}>SGST</Text>
                <View style={styles.tableGstView}>
                  <View style={styles.tableGstPreView}>
                    <Text style={styles.tableHeadingAmtText}>%</Text>
                  </View>
                  <View style={styles.tableAmtView}>
                    <Text style={styles.tableHeadingAmtText}>Amt</Text>
                  </View>
                </View>
              </View>

              <View style={styles.tableAmountView}>
                <Text style={styles.tableHeadingAmtText}>Amount</Text>
              </View>
            </View>

            <View style={styles.tableContainerData}>
              <View style={styles.tableNoView}>
                <Text style={styles.tableDataText}>1</Text>
              </View>

              <View style={styles.tableItemView}>
                <Text style={styles.tableDataText}>
                  Buiss Master {props.no_of_orders} Orders Package
                </Text>
              </View>

              <View style={styles.tableHSNView}>
                <Text style={styles.tableDataText}>997331</Text>
              </View>

              <View style={styles.tableRateView}>
                <Text style={styles.tableDataAmtText}>{props.amount}</Text>
              </View>

              <View style={styles.tableGstMainView}>
                <View style={styles.tableGstDataView}>
                  <View style={styles.tableGstPreView}>
                    <Text style={styles.tableDataAmtText}>9%</Text>
                  </View>
                  <View style={styles.tableAmtView}>
                    <Text style={styles.tableDataAmtText}>{props.amount * (0.09)}</Text>
                  </View>
                </View>
              </View>

              <View style={styles.tableGstMainView}>
                <View style={styles.tableGstDataView}>
                  <View style={styles.tableGstPreView}>
                    <Text style={styles.tableDataAmtText}>9%</Text>
                  </View>
                  <View style={styles.tableAmtView}>
                    <Text style={styles.tableDataAmtText}>{props.amount * (0.09)}  </Text>
                  </View>
                </View>
              </View>

              <View style={styles.tableAmountView}>
                <Text style={styles.tableDataAmtText}>{props.amount * (1 + 0.18)}</Text>
              </View>
            </View>

            <View style={styles.totalContainer}>
              <View style={styles.totalThanksContainer}>
                <Text>Total In Words</Text>
                <Text style={styles.TotalInWordText}>
                  {props.InWords}
                </Text>
                <Text style={styles.thanksText}>Thanks for your business.</Text>
              </View>

              {props.isCouponUsed && (
                  <View style={styles.TotalAmountContainer}>
                    <View style={styles.subTotalView}>
                      <Text style={styles.subTotalText}>Applied Coupon({props.CouponCode})</Text>
                      <Text style={styles.subTotalText}>{props.CouponAmount}{props.CouponType}</Text>
                    </View>
                  </View>
              )}

              <View style={styles.TotalAmountContainer}>
                <View style={styles.subTotalView}>
                  <Text style={styles.subTotalText}>Total Rate</Text>
                  <Text style={styles.subTotalText}>{props.amount}</Text>
                </View>

                <View style={styles.subTotalView}>
                  <Text style={styles.subTotalText}>Total CGST (9%)</Text>
                  <Text style={styles.subTotalText}>{props.amount * (0.09)}</Text>
                </View>

                <View style={styles.subTotalView}>
                  <Text style={styles.subTotalText}>Total SGST (9%)</Text>
                  <Text style={styles.subTotalText}>{props.amount * (0.09)}</Text>
                </View>

                <View style={styles.subTotalView}>
                  <Text style={styles.subTotalText}>Total Amount</Text>
                  <Text style={styles.subTotalText}>{props.amount * (1 + 0.18)}</Text>
                </View>

                <View style={styles.subTotalView}>
                  <Text style={styles.subTotalText}>Payment Made</Text>
                  <Text style={styles.paymentText}>(-) {props.amount * (1 + 0.18)}</Text>
                </View>

                <View style={styles.subTotalView}>
                  <Text style={styles.subTotalText}>Balance Due</Text>
                  <Text style={styles.subTotalText}>0</Text>
                </View>
              </View>
            </View>
            <View style={styles.totalContainer}>
            <View style={styles.notes}>
              <View style={styles.subTotalView}>
                <Text style={styles.notes_heading}>*Please note: </Text>
              </View>
              <View style={styles.subTotalView}>
                <Text style={styles.notes_content}>• All payments are final and non-refundable. Your account will be disabled, and available orders will expire if the account is inactive for 90 days.</Text>
              </View>
              <View style={styles.subTotalView}>
                <Text style={styles.notes_content}>• Available orders are not subject to transfer to another account and non-refundable.</Text>
              </View>
              <View style={styles.subTotalView}>
                <Text style={styles.notes_content}>• Package is valid for one year.</Text>
              </View>
            </View>
            </View>
              <View style={styles.imageCornerContainer_bottom}>
                  <Image style={styles.image1} src={bottomCornerimage} />
              </View>
          </View>
          <View style={styles.imageCornerContainer}>
            <Image style={styles.image1} src={Cornerimage} />
          </View>

        </Page>
      </Document>
  );
};

export default Invoice;
