import {LoadingButton} from "@mui/lab";
import React, {useEffect, useRef, useState} from 'react';
import CardActions from "@mui/material/CardActions";
import {Box, Card, CardContent, CardHeader, Container, Grid} from '@mui/material';
import {CoachMark} from "react-coach-mark";
import Page from '../../../components/Page';
import urlConstants from '../../../routes/urlConstants';
import useSettings from '../../../hooks/useSettings';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import AcceptList from "../acceptList/AcceptList";
import {checkStatusOfInstruction, updateInstruction} from "../../../utils/utilities";

export default function FlipkartUtilities() {
    const {themeStretch} = useSettings();
    const [GenerateLabelOn, setGenerateLabelOn] = useState(true);
    const [reGenerateLabelOn, setReGenerateLabelOn] = useState(false);
    const [markAsRTDOn, setMarkAsRTDOn] = useState(false);

    const GenerateLabelBTN = useRef(null);
    const ReGenerateLabelBTN = useRef(null);
    const MarkAsRtd = useRef(null);
    const [activatedNumber, setActivateNumber] = useState(0);

    const NextButton = (
        <Grid container style={{marginTop: '.5rem'}} justifyContent={'end'}>
            {activatedNumber !== 2 && <LoadingButton
                variant={'outlined'}
                style={{marginRight: '.5rem'}}
                onClick={() =>
                    setActivateNumber(2)
                }
            >
                Skip
            </LoadingButton>}
            <LoadingButton
                variant={'contained'}
                onClick={() => {
                    setActivateNumber((prevNumber) => prevNumber + 1)
                }}
            >
                {activatedNumber === 2 ? 'Done' : 'Next'}
            </LoadingButton>
        </Grid>
    );

    const coachList = [
        {
            activate: activatedNumber === 0,
            component: <Card><h4 style={{padding: "10px"}}>Generate Label</h4>
                <p style={{paddingLeft: "10px", paddingRight: "5px"}}>Generates Pending Orders Label and Sync
                    orders to Oms</p><CardActions style={{textAlign: 'center'}}>{NextButton}</CardActions></Card>,
            reference: GenerateLabelBTN,
            disableScrolling: true,
            tooltip: {position: 'bottom', style: {borderRadius: '2rem'}}
        },
        {
            activate: activatedNumber === 1,
            component: <Card><h4 style={{padding: "10px"}}>Re-Generate Label</h4>
                <p style={{paddingLeft: "10px", paddingRight: "5px"}}>Re Print labels from pending Rtd Orders</p>
                <CardActions style={{textAlign: 'center'}}>{NextButton}</CardActions></Card>,
            reference: ReGenerateLabelBTN,
            disableScrolling: true,
            tooltip: {position: 'bottom', style: {borderRadius: '2rem'}}
        },
        {
            activate: activatedNumber === 2,
            component: <Card><h4 style={{padding: "10px"}}>Mark As Rtd</h4>
                <p style={{paddingLeft: "10px", paddingRight: "5px"}}>Mark as rtd and transfer orders to handover
                    pending rtd orders</p><CardActions style={{textAlign: 'center'}}>{NextButton}</CardActions></Card>,
            reference: MarkAsRtd,
            disableScrolling: true,
            tooltip: {position: 'bottom', style: {borderRadius: '2rem'}}
        },
    ]

    const coach = coachList[activatedNumber]
    return (
        <Page title="Utilities">

            {checkStatusOfInstruction('utilitiesFlipkart') && <CoachMark {...coach} />}

            <Container maxWidth={themeStretch ? false : 'xl'}>
                <HeaderBreadcrumbs
                    heading="Utilities"
                    links={[{name: 'Dashboard', href: urlConstants.HOME_PAGE}, {name: 'Utilities'}, {name: 'FlipKart'}]}
                />
                <Grid container spacing={3} style={{marginBottom: "3rem"}}>
                    <Grid item xs={12}>
                        <LoadingButton ref={GenerateLabelBTN} style={{marginRight: "1rem"}} onClick={() => {
                            setReGenerateLabelOn(false)
                            setMarkAsRTDOn(false)
                            setGenerateLabelOn(true)
                        }} variant={GenerateLabelOn ? 'contained' : 'outlined'}>Generate Label</LoadingButton>
                        <span style={{borderRight: "1px solid #000", height: "100%"}}/>
                        <LoadingButton ref={ReGenerateLabelBTN} style={{marginRight: "1rem", marginLeft: "1rem"}}
                                       onClick={() => {
                                           setGenerateLabelOn(false)
                                           setMarkAsRTDOn(false)
                                           setReGenerateLabelOn(true)
                                       }} variant={reGenerateLabelOn ? 'contained' : 'outlined'}>Re-Generate
                            Label</LoadingButton>
                        <span style={{borderRight: "1px solid #000", height: "100%"}}/>

                        <LoadingButton ref={MarkAsRtd} style={{marginLeft: "1rem"}} onClick={() => {
                            setGenerateLabelOn(false)
                            setReGenerateLabelOn(false)
                            setMarkAsRTDOn(true)
                        }} variant={markAsRTDOn ? 'contained' : 'outlined'}>Mark As RTD</LoadingButton>
                    </Grid>
                </Grid>
                {GenerateLabelOn && (
                    <AcceptList acType={'flipkart'} utilityType={"generateLabel"}/>
                )}

                {reGenerateLabelOn && (
                    <AcceptList acType={'flipkart'} utilityType={"reGenerateLabel"}/>
                )}

                {markAsRTDOn && (
                    <AcceptList acType={'flipkart'} utilityType={"markAsRtd"}/>
                )}
            </Container>
        </Page>
    );
}
