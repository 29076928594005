import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
// @mui
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
//
import React from 'react';
import BlockContent from './BlockContent';
import MultiFilePreview from './MultiFilePreview';

// ----------------------------------------------------------------------

const DropZoneStyle = styled('div')(({ theme }) => ({
  outline: 'none',
  padding: theme.spacing(5, 1),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${theme.palette.grey[500_32]}`,
  '&:hover': { opacity: 0.72, cursor: 'pointer' },
}));

// ----------------------------------------------------------------------

UploadMultiFile.propTypes = {
  error: PropTypes.bool,
  showPreview: PropTypes.bool,
  files: PropTypes.array,
  onRemove: PropTypes.func,
  onRemoveAll: PropTypes.func,
  helperText: PropTypes.node,
  sx: PropTypes.object,
};

export default function UploadMultiFile({
  error,
  showPreview = false,
  files,
  onRemove,
  onRemoveAll,
  onDrop,
  onUpload,
  title,
  acceptTypes,
  helperText,
  sx,
}) {
  return (
    <Dropzone onDrop={onDrop} multiple maxSize={20000000} accept={acceptTypes}>
      {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
        <Box sx={{ width: '100%', ...sx }}>
          <DropZoneStyle
            {...getRootProps()}
            sx={{
              ...(isDragActive && { opacity: 0.72 }),
              ...((isDragReject || error) && {
                color: 'error.main',
                borderColor: 'error.light',
                bgcolor: 'error.lighter',
              }),
            }}
          >
            <input {...getInputProps()} />

            <BlockContent title={title} />
          </DropZoneStyle>

          <MultiFilePreview

            showPreview={showPreview}
            files={files}
            onUpload={onUpload}
            onRemove={onRemove}
            onRemoveAll={onRemoveAll}
          />

          {helperText && helperText}
        </Box>
      )}
    </Dropzone>
  );
}
