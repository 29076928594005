/* eslint-disable */
import {
    Autocomplete,
    Box,
    Button,
    Card,
    CardHeader,
    CircularProgress,
    Container,
    Divider,
    Grid,
    Stack,
    TextField,
} from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import {useTheme} from '@mui/material/styles';
import {DatePicker} from 'antd';
import {isEmpty} from 'lodash';
import moment from 'moment';
import {useSnackbar} from 'notistack';
import React, {useEffect, useState} from 'react';
import AccountSelector from '../../components/Common/AccountSelector';
import MonthlyReportCard from './components/monthlyReportCard';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Page from '../../components/Page';
import useAuth from '../../hooks/useAuth';
import useSettings from '../../hooks/useSettings';
import urlConstants from '../../routes/urlConstants';
import {CreatePostAPICall, MonthlyReport} from '../../utils/apis';
import {
    BootstrapDialog,
    BootstrapDialogTitle,
    SystemColor,
    getAccountsDataByType,
    setAccountSelectorData,
} from '../../utils/utilities';
import SummaryReportChart from './components/SummaryReportChart';

export default function MonthlyReports() {
    const {themeStretch} = useSettings();
    const {user} = useAuth();
    const localUser = window.localStorage.getItem('userEmailId');
    const {enqueueSnackbar} = useSnackbar();
    const theme = useTheme();
    const isLight = theme.palette.mode === 'light';
    const {RangePicker} = DatePicker;
    const [allFormattedTradeBookData, setAllFormattedTradeBookData] = useState({});
    const [filteredFormattedTradeBookData, setFilteredFormattedTradeBookData] = useState({});
    const [userData, setUserData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [openGenerateNewReport, setOpenGenerateNewReport] = useState(false);
    const [accountError, setAccountError] = useState('');
    const [tpEmail, setTpEmail] = useState([]);
    const [yearError, setYearError] = useState('');
    const [monthError, setMonthError] = useState('');
    const [selectedMonth, setSelectedMonth] = useState([]);
    const [selectedYear, setSelectedYear] = useState([]);
    const [yearList, setYearList] = useState([]);
    const [supplierId, setSupplierId] = useState([]);
    const [accountArray, setAccountArray] = useState([]);
    const [companyArray, setCompanyArray] = useState([]);
    const [selectedMonths, setSelectedMonths] = useState([]);
    const [xAxisData, setXAxisData] = useState([]);
    const [isMonthSelected, setIsMonthSelected] = useState(true);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [monthRangeToBeSelect, setMonthRangeToBeSelect] = useState([]);
    const [allRangeMonthReport, setAllRangeMonthReport] = useState({});
    const [chartData, setChartData] = useState({
        sentOrders: [],
        returnOrders: [],
        investment: [],
        paymentReceived: [],
        netProfit: [],
    });

    const onOutBtnClk = (month) => {
        const tempData = {};

        const tempSelectMonth = [...selectedMonths];

        if (tempSelectMonth.find((x) => x === month) === undefined) {
            tempSelectMonth.unshift(month);
            tempSelectMonth.forEach((month) => {
                tempData[month] = allFormattedTradeBookData[month];
            });
            setFilteredFormattedTradeBookData(tempData);
        } else {
            delete tempSelectMonth.splice(tempSelectMonth.indexOf(month), 1);
            tempSelectMonth.forEach((month) => {
                tempData[month] = allFormattedTradeBookData[month];
            });
            !isEmpty(tempData)
                ? setFilteredFormattedTradeBookData(tempData)
                : setFilteredFormattedTradeBookData(allFormattedTradeBookData);
        }
        setSelectedMonths(tempSelectMonth);
    };

    const OutlineButtonFilter = ({month}) => (
        <>
            <Button
                style={{marginRight: '10px', marginBottom: '10px'}}
                variant={selectedMonths.indexOf(month) === -1 ? 'outlined' : 'contained'}
                onClick={() => {
                    onOutBtnClk(month);
                }}
            >
                {month}
            </Button>
        </>
    );

    const monthList = [
        {value: '01', name: '1. January'},
        {value: '02', name: '2. February'},
        {value: '03', name: '3. March'},
        {value: '04', name: '4. April'},
        {value: '05', name: '5. May'},
        {value: '06', name: '6. June'},
        {value: '07', name: '7. July'},
        {value: '08', name: '8. August'},
        {value: '09', name: '9. September'},
        {value: '10', name: '10. October'},
        {value: '11', name: '11. November'},
        {value: '12', name: '12. December'},
    ];

    const allReRange = {};

    const getTotalCount = (data) => {
        const actypeValues = ['meesho', 'flipkart', 'glowroad', 'shopdeck', 'snapdeal', 'limeroad'];

        const platform = {};

        actypeValues.forEach((actype) => {
            platform[actype] = {
                platform: actype,
                TotalAC: 0,
                TotalOrder: 0,
                TotalRTO: 0,
                TotalRTN: 0,
                TotalCNL: 0,
                TotalNOR: 0,
                TotalInvestment: 0,
                TotalProfitLoss: 0,
            };
        });
        const monthCards = {
            meesho: {
                RevenueSummary: {
                    TotalRevenue: 0,
                    TotalInvestment: 0,
                    TotalProfitAndLoss: 0,
                    TotalAds: 0,
                    ProfitAndLossWithAds: 0,
                },
                OrderSummary: {
                    TotalSentOrders: 0,
                    TotalReturnOrders: 0,
                    TotalCancelOrders: 0,
                    TotalNormalOrders: 0,
                    TotalPaidOrders: 0,
                    TotalOutstandingOrders: 0,
                    TotalNotListedOrders: 0,
                },
                ReturnSummary: {
                    TotalCustomerReturn: 0,
                    TotalCourierReturn: 0,
                    TotalCancel: 0,
                    TotalReceivedOrders: 0,
                    TotalNotReceivedOrders: 0,
                    TotalReturnLoss: 0,
                    TotalNotReceivedLoss: 0,
                },
            },
        };

        // Rev Summary
        let TotalRevenue = 0;
        let TotalInvestment = 0;
        let TotalProfitAndLoss = 0;
        let TotalAds = 0;
        let ProfitAndLossWithAds = 0;

        // OrderSummary
        let TotalSentOrders = 0;
        let TotalReturnOrders = 0;
        let TotalCancelOrders = 0;
        let TotalNormalOrders = 0;
        let TotalPaidOrders = 0;
        let TotalOutstandingOrders = 0;
        let TotalNotListedOrders = 0;

        //   Return summary
        let TotalCustomerReturn = 0;
        let TotalCourierReturn = 0;
        let TotalCancel = 0;
        let TotalReceivedOrders = 0;
        let ttClaimOdr = 0;
        let TotalNotReceivedOrders = 0;
        let TotalReturnLoss = 0;
        let TotalNotReceivedLoss = 0;

        // For Platform Wise
        let MeeshoTotalOrders = 0;
        let MeeshoTotalAccounts = 0;
        let MeeshoTotalRTO = 0;
        let MeeshoTotalRTN = 0;
        let MeeshoTotalCNL = 0;
        let MeeshoTotalNormal = 0;
        let MeeshoTotalProfitAndLoss = 0;
        let MeeshoTotalInvestment = 0;

        let FlipkartTotalOrders = 0;
        let FlipkartTotalAccounts = 0;
        let FlipkartTotalRTO = 0;
        let FlipkartTotalRTN = 0;
        let FlipkartTotalCNL = 0;
        let FlipkartTotalNOR = 0;
        let FlipkartTotalProfitAndLoss = 0;
        let FlipkartTotalInvestment = 0;

        let GlowroadTotalOrders = 0;
        let GlowroadTotalAccounts = 0;
        let GlowroadTotalRTO = 0;
        let GlowroadTotalRTN = 0;
        let GlowroadTotalCNL = 0;
        let GlowroadTotalNOR = 0;
        let GlowroadTotalProfitAndLoss = 0;
        let GlowroadTotalInvestment = 0;

        let ShopdeckTotalOrders = 0;
        let ShopdeckTotalAccounts = 0;
        let ShopdeckTotalRTO = 0;
        let ShopdeckTotalRTN = 0;
        let ShopdeckTotalCNL = 0;
        let ShopdeckTotalNOR = 0;
        let ShopdeckTotalProfitAndLoss = 0;
        let ShopdeckTotalInvestment = 0;

        let SnapdealTotalOrder = 0;
        let SnapdealTotalAC = 0;
        let SnapdealTotalRTO = 0;
        let SnapdealTotalRTN = 0;
        let SnapdealTotalCNL = 0;
        let SnapdealTotalNOR = 0;
        let SnapdealTotalProfitLoss = 0;
        let SnapdealTotalInvestment = 0;

        data.forEach((x, i) => {
            if (!x.netTotal) return;
            // Revenue Summary
            TotalRevenue += x.netTotal.listedPayment;
            TotalInvestment +=
                parseFloat(x.payment?.paid?.investment?.toFixed(2)) +
                parseFloat(x.payment?.outstanding?.investment?.toFixed(2)) +
                parseFloat(x.payment?.notListed?.investment?.toFixed(2)) +
                parseFloat(x.payment?.claim?.total?.investment?.toFixed(2)) +
                parseFloat(x.totalReturn?.wrongReceived?.total?.investment?.toFixed(2)) +
                x.totalReturn?.notReceived?.total.investment +
                x.totalReturn?.received?.total.investment -
                x.totalReturn?.received?.total.investment;
            TotalProfitAndLoss = TotalRevenue - TotalInvestment;
            // x.netTotal.profitLoss
            TotalAds += x.netTotal.totalAds;
            ProfitAndLossWithAds = TotalProfitAndLoss + TotalAds;
            // Order Summary

            TotalSentOrders += x.totalSent.total.odr;
            TotalReturnOrders += x.noOfReturns.rtn.odr + x.noOfReturns.rto.odr;
            TotalCancelOrders += x.noOfReturns.cnl.odr;
            TotalNormalOrders += x.noOfReturns.nor.odr;
            TotalPaidOrders += x.payment.paid.odr;
            TotalOutstandingOrders += x.payment.outstanding.odr;
            TotalNotListedOrders += x.payment.notListed.odr;
            // Return summary
            TotalCustomerReturn += x.noOfReturns.rtn.odr;
            TotalCourierReturn += x.noOfReturns.rto.odr;
            TotalCancel += x.noOfReturns.cnl.odr;
            TotalReceivedOrders += x.noOfReturns.total.recOdr;
            ttClaimOdr += x.payment.claim.total.odr;
            TotalNotReceivedOrders += x.totalReturn.notReceived.total.odr;
            TotalReturnLoss +=
                x.payment.claim.rto.profitLoss +
                x.payment.claim.rtn.profitLoss +
                x.payment.claim.cnl.profitLoss +
                x.payment.claim.nor.profitLoss +
                x.totalReturn.wrongReceived.rto.profitLoss +
                x.totalReturn.wrongReceived.rtn.profitLoss +
                x.totalReturn.wrongReceived.cnl.profitLoss +
                x.totalReturn.wrongReceived.nor.profitLoss +
                x.totalReturn.received.rto.profitLoss +
                x.totalReturn.received.rtn.profitLoss +
                x.totalReturn.received.cnl.profitLoss +
                x.totalReturn.received.nor.profitLoss;
            TotalNotReceivedLoss +=
                x.totalReturn.notReceived.rtn.profitLoss +
                x.totalReturn.notReceived.rto.profitLoss +
                x.totalReturn.notReceived.cnl.profitLoss;

            // For Platform Wise
            if (x.acType === 'meesho') {
                MeeshoTotalAccounts += 1;
                MeeshoTotalOrders += x.totalSent.total.qt;
                MeeshoTotalRTO += x.noOfReturns.rto.odr;
                MeeshoTotalRTN += x.noOfReturns.rtn.odr;
                MeeshoTotalCNL += x.noOfReturns.cnl.odr;
                MeeshoTotalNormal += x.noOfReturns.nor.odr;
                MeeshoTotalInvestment += x.netTotal.investment;
                MeeshoTotalProfitAndLoss += x.netTotal.profitLoss;
            }
            if (x.acType === 'flipkart') {
                FlipkartTotalAccounts += 1;
                FlipkartTotalOrders += x.totalSent.total.qt;
                FlipkartTotalRTO += x.noOfReturns.rto.odr;
                FlipkartTotalRTN += x.noOfReturns.rtn.odr;
                FlipkartTotalCNL += x.noOfReturns.cnl.odr;
                FlipkartTotalNOR += x.noOfReturns.nor.odr;
                FlipkartTotalInvestment += x.netTotal.investment;
                FlipkartTotalProfitAndLoss += x.netTotal.profitLoss;
            }
            if (x.acType === 'glowroad') {
                GlowroadTotalAccounts += 1;
                GlowroadTotalOrders += x.totalSent.total.qt;
                GlowroadTotalRTO += x.noOfReturns.rto.odr;
                GlowroadTotalRTN += x.noOfReturns.rtn.odr;
                GlowroadTotalCNL += x.noOfReturns.cnl.odr;
                GlowroadTotalNOR += x.noOfReturns.nor.odr;
                GlowroadTotalInvestment += x.netTotal.investment;
                GlowroadTotalProfitAndLoss += x.netTotal.profitLoss;
            }
            if (x.acType === 'shopdeck') {
                ShopdeckTotalAccounts += 1;
                ShopdeckTotalOrders += x.totalSent.total.qt;
                ShopdeckTotalRTO += x.noOfReturns.rto.odr;
                ShopdeckTotalRTN += x.noOfReturns.rtn.odr;
                ShopdeckTotalCNL += x.noOfReturns.cnl.odr;
                ShopdeckTotalNOR += x.noOfReturns.nor.odr;
                ShopdeckTotalInvestment += x.netTotal.investment;
                ShopdeckTotalProfitAndLoss += x.netTotal.profitLoss;
            }
            if (x.acType === 'snapdeal') {
                SnapdealTotalAC += 1;
                SnapdealTotalOrder += x.totalSent.total.qt;
                SnapdealTotalRTO += x.noOfReturns.rto.odr;
                SnapdealTotalRTN += x.noOfReturns.rtn.odr;
                SnapdealTotalCNL += x.noOfReturns.cnl.odr;
                SnapdealTotalNOR += x.noOfReturns.nor.odr;
                SnapdealTotalInvestment += x.netTotal.investment;
                SnapdealTotalProfitLoss += x.netTotal.profitLoss;
            }


        });
        monthCards.meesho.RevenueSummary.TotalRevenue = TotalRevenue.toFixed(2);
        monthCards.meesho.RevenueSummary.TotalInvestment = TotalInvestment.toFixed(2);
        monthCards.meesho.RevenueSummary.TotalProfitAndLoss = TotalProfitAndLoss.toFixed(2);
        monthCards.meesho.RevenueSummary.TotalAds = TotalAds.toFixed(2);
        monthCards.meesho.RevenueSummary.ProfitAndLossWithAds = ProfitAndLossWithAds.toFixed(2);
        // Order Summary

        monthCards.meesho.OrderSummary.TotalSentOrders = TotalSentOrders;
        monthCards.meesho.OrderSummary.TotalReturnOrders = TotalReturnOrders;
        monthCards.meesho.OrderSummary.TotalCancelOrders = TotalCancelOrders;
        monthCards.meesho.OrderSummary.TotalNormalOrders = TotalNormalOrders;
        monthCards.meesho.OrderSummary.TotalPaidOrders = TotalPaidOrders;
        monthCards.meesho.OrderSummary.TotalOutstandingOrders = TotalOutstandingOrders;
        monthCards.meesho.OrderSummary.TotalNotListedOrders = TotalNotListedOrders;
        // Return summary
        monthCards.meesho.ReturnSummary.TotalCustomerReturn = TotalCustomerReturn;
        monthCards.meesho.ReturnSummary.TotalCourierReturn = TotalCourierReturn;
        monthCards.meesho.ReturnSummary.TotalCancel = TotalCancel;
        monthCards.meesho.ReturnSummary.TotalReceivedOrders = TotalReceivedOrders;
        monthCards.meesho.ReturnSummary.ttClaimOdr = ttClaimOdr;
        monthCards.meesho.ReturnSummary.TotalNotReceivedOrders = TotalNotReceivedOrders;
        monthCards.meesho.ReturnSummary.TotalReturnLoss = TotalReturnLoss;
        monthCards.meesho.ReturnSummary.TotalNotReceivedLoss = TotalNotReceivedLoss;

        // Set Platform state value Meesho
        platform.meesho.TotalAC = MeeshoTotalAccounts;
        platform.meesho.TotalOrder = MeeshoTotalOrders;
        platform.meesho.TotalRTO = MeeshoTotalRTO;
        platform.meesho.TotalRTN = MeeshoTotalRTN;
        platform.meesho.TotalCNL = MeeshoTotalCNL;
        platform.meesho.TotalNOR = MeeshoTotalNormal;
        platform.meesho.TotalInvestment = MeeshoTotalInvestment;
        platform.meesho.TotalProfitLoss = MeeshoTotalProfitAndLoss;

        // Set Platform state value Flipkart
        platform.flipkart.TotalAC = FlipkartTotalAccounts;
        platform.flipkart.TotalOrder = FlipkartTotalOrders;
        platform.flipkart.TotalRTO = FlipkartTotalRTO;
        platform.flipkart.TotalRTN = FlipkartTotalRTN;
        platform.flipkart.TotalCNL = FlipkartTotalCNL;
        platform.flipkart.TotalNOR = FlipkartTotalNOR;
        platform.flipkart.TotalInvestment = FlipkartTotalInvestment;
        platform.flipkart.TotalProfitLoss = FlipkartTotalProfitAndLoss;

        // Set Platform state value Glowroad
        platform.glowroad.TotalAC = GlowroadTotalAccounts;
        platform.glowroad.TotalOrder = GlowroadTotalOrders;
        platform.glowroad.TotalRTO = GlowroadTotalRTO;
        platform.glowroad.TotalRTN = GlowroadTotalRTN;
        platform.glowroad.TotalCNL = GlowroadTotalCNL;
        platform.glowroad.TotalNOR = GlowroadTotalNOR;
        platform.glowroad.TotalInvestment = GlowroadTotalInvestment;
        platform.glowroad.TotalProfitLoss = GlowroadTotalProfitAndLoss;

        // Set Platform state value Shopdeck
        platform.shopdeck.TotalAC = ShopdeckTotalAccounts;
        platform.shopdeck.TotalOrder = ShopdeckTotalOrders;
        platform.shopdeck.TotalRTO = ShopdeckTotalRTO;
        platform.shopdeck.TotalRTN = ShopdeckTotalRTN;
        platform.shopdeck.TotalCNL = ShopdeckTotalCNL;
        platform.shopdeck.TotalNOR = ShopdeckTotalNOR;
        platform.shopdeck.TotalInvestment = ShopdeckTotalInvestment;
        platform.shopdeck.TotalProfitLoss = ShopdeckTotalProfitAndLoss;

        // Set Platform state value Snapdeal
        platform.snapdeal.TotalAC = SnapdealTotalAC;
        platform.snapdeal.TotalOrder = SnapdealTotalOrder;
        platform.snapdeal.TotalRTO = SnapdealTotalRTO;
        platform.snapdeal.TotalRTN = SnapdealTotalRTN;
        platform.snapdeal.TotalCNL = SnapdealTotalCNL;
        platform.snapdeal.TotalNOR = SnapdealTotalNOR;
        platform.snapdeal.TotalInvestment = SnapdealTotalInvestment;
        platform.snapdeal.TotalProfitLoss = SnapdealTotalProfitLoss;

        return monthCards;
    };

    const unique = (myArray) => {
        return myArray.filter((thing, index, self) => index === self.findIndex((t) => t === thing));
    };

    const dataForChart = (monthData) => {
        const tempSentOrders = [];
        const tempReturnOrders = [];
        const tempInvestment = [];
        const tempPaymentReceived = [];
        const tempNetProfit = [];

        const reverseOrder = Object.keys(monthData)
            .sort()
            .reduce((obj, key) => {
                obj[key] = monthData[key];
                return obj;
            }, {});

        // loop the existing state and find the key value
        const objectKeys = Object.keys(reverseOrder);
        const uniqueObjectKeys = unique(objectKeys);

        uniqueObjectKeys.forEach((item) => {
            const data = getTotalCount(reverseOrder[`${item}`]);
            tempSentOrders.push(data.meesho.OrderSummary.TotalSentOrders);
            tempReturnOrders.push(data.meesho.OrderSummary.TotalReturnOrders);
            tempInvestment.push(data.meesho.RevenueSummary.TotalInvestment);
            tempPaymentReceived.push(data.meesho.RevenueSummary.TotalRevenue);
            tempNetProfit.push(data.meesho.RevenueSummary.ProfitAndLossWithAds);
        });
        setXAxisData(uniqueObjectKeys);
        setChartData({
            sentOrders: tempSentOrders,
            returnOrders: tempReturnOrders,
            investment: tempInvestment,
            paymentReceived: tempPaymentReceived,
            netProfit: tempNetProfit,
        });
    };

    // Get data from API && Formatted that data
    const getAllReports = async (
        isAllMonthReport = false,
        isRangeSelected = false,
        monthRange = [],
        lastKey = null,
        isEmpty = false,
        allRangeMonth = {}
    ) => {
        setIsLoading(true);
        let tempObject = {};
        let ordered = {};
        const body = {
            username: user?.email ? user.email : localUser,
            actionType: 'getReports',
        };
        if (isAllMonthReport) {
            body.allReports = true;
        }
        if (isRangeSelected) {
            body.filter = {
                from: monthRange[0],
                to: monthRange[1],
            };
        }

        if (lastKey !== null && isAllMonthReport) {
            body.lastKey = lastKey;
        }
        if (lastKey !== null && isRangeSelected) {
            body.lastKey = lastKey;
        }
        const response = await CreatePostAPICall(enqueueSnackbar, MonthlyReport, body);
        if (response) {
            const summaryData = [];
            response.data.body.forEach((x) => {
                const {stateWiseData} = x;

                const reportData = x.summaryReport;
                reportData.uiUniqueId = summaryData.length + 1;
                reportData.officeExpanse = x.officeExpanse ? x.officeExpanse : 0;
                reportData.stateWiseData = stateWiseData;
                reportData.userName = x.account;
                reportData.token = x.token;
                reportData.acType = x.acType;
                reportData.company_name = x.company_name;
                reportData.subAccountId = x.subAccountId;
                reportData.reRange = x.reRange;
                reportData.reDate = x.reDate;
                reportData.executed_time = x.executed_time;
                reportData.error = x.Error;
                reportData.excel = x.excel;

                if (allReRange[`${x.reRange}`] === undefined) {
                    allReRange[`${x.reRange}`] = [];
                }
                allReRange[`${x.reRange}`].push(reportData);
            });

            if (isAllMonthReport || isRangeSelected) {

                tempObject = {...allRangeMonth, ...allReRange};
            } else {
                tempObject = allReRange;
            }
            ordered = Object.keys(tempObject)
                .sort()
                .reverse()
                .reduce((obj, key) => {
                    obj[key] = tempObject[key];
                    return obj;
                }, {});

            dataForChart(ordered);
            setAllRangeMonthReport(ordered);
            setAllFormattedTradeBookData(ordered);
            setFilteredFormattedTradeBookData(ordered);

            if (response.data.lastKey !== null && response.data.lastKey.length > 0 && isRangeSelected) {
                getAllReports(false, true, monthRange, response.data.lastKey, false, tempObject);
            }

            if (response.data.lastKey !== null && isAllMonthReport) {
                getAllReports(true, false, [], response.data.lastKey, false, tempObject);
            }

            if (response.data.lastKey === null || response.data.lastKey.length === 0) {
                setIsLoading(false);
            }
        }
    };

    useEffect(() => {
        getAccountsDataByType('all', true).then((filteredData) => {
            setUserData(filteredData);
        });
    }, []);

    const handleSelectedYear = (event) => {
        setSelectedYear(event.target.value);
    };

    const handleSelectedAccount = async (event, value) => {
        const response = await setAccountSelectorData(value, userData);
        const supplierArray = [];
        const tpEmailArray = [];
        const accountArray = [];
        const companyArray = [];

        response[1].forEach((item) => {
            supplierArray.push(item.value);
            tpEmailArray.push(item.Email);
            accountArray.push(item.AcType);
            companyArray.push(item.cName);
        });
        setSupplierId(supplierArray);
        setCompanyArray(companyArray);
        setTpEmail(tpEmailArray);
        setAccountArray(accountArray);
    };

    const clearModelValue = () => {
        setAccountError('');
        setYearError('');
        setMonthError('');
        setSelectedYear([]);
        setSelectedMonth([]);
        setTpEmail([]);
    };

    const handleClose = () => {
        setOpenGenerateNewReport(false);
        clearModelValue();
    };

    const handleGenerateReport = async () => {
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth() + 1;

        let isValidate = true;
        if (tpEmail.length === 0) {
            isValidate = false;
            setAccountError('Please select Valid account(s)');
        } else {
            setAccountError('');
        }
        if (selectedMonth.length === 0) {
            isValidate = false;
            setMonthError('Please select month');
        } else if (selectedYear === currentYear && currentMonth < selectedMonth) {
            isValidate = false;
            setMonthError('Selected Month is Invalid');
        } else {
            setMonthError('');
        }
        if (selectedYear.length === 0) {
            isValidate = false;
            setYearError('Please select year');
        } else {
            setYearError('');
        }

        const tempMonthArray = [];
        for (let i = 0; i < selectedMonth.length; i++) {
            const selectedMonths = selectedMonth[i];
            const month = monthList.filter((month) => month.name === selectedMonths);
            tempMonthArray.push(`${selectedYear}-${month[0].value}`);
        }
        const body = {
            username: user?.email ? user.email : localUser,
            tpEmailIds: tpEmail,
            subAccountIds: supplierId,
            acTypes: accountArray,
            cNames: companyArray,
            month: tempMonthArray,
            actionType: 'generateReport',
        };
        if (isValidate) {
            setIsLoading(true);
            const response = await CreatePostAPICall(enqueueSnackbar, MonthlyReport, body, 'Success', true, null);
            if (response) {
                getAllReports();
                handleClose();
            }
        }
    };

    const handleGenerateReportModel = () => {
        setOpenGenerateNewReport(!openGenerateNewReport);
        clearModelValue();
    };

    useEffect(() => {
        const years = [];
        const earliestYear = 2020;
        let currentYear = new Date().getFullYear();

        while (currentYear >= earliestYear) {
            years.push({value: currentYear, name: currentYear});
            currentYear -= 1;
        }
        setYearList(years);
    }, []);

    useEffect(() => {
        getAllReports();
    }, []);

    const refreshMonthCard = async (month) => {
        const body = {
            username: user?.email ? user.email : localUser,
            actionType: 'getReports',
            month,
        };
        const response = await CreatePostAPICall(enqueueSnackbar, MonthlyReport, body);

        if (response) {
            const OneMonth = [];

            response.data.body.forEach((x, index) => {
                const {stateWiseData} = x;
                const reportData = x.summaryReport;
                reportData.uiUniqueId = index + 1;
                reportData.officeExpanse = x.officeExpanse ? x.officeExpanse : 0;
                reportData.stateWiseData = stateWiseData;
                reportData.userName = x.account;
                reportData.token = x.token;
                reportData.acType = x.acType;
                reportData.company_name = x.company_name;
                reportData.subAccountId = x.subAccountId;
                reportData.reRange = x.reRange;
                reportData.reDate = x.reDate;
                reportData.executed_time = x.executed_time;
                reportData.error = x.Error;
                reportData.excel = x.excel;
                OneMonth.push(reportData);
            });

            const allNewFilteredFormattedTradeBookData = filteredFormattedTradeBookData;
            allNewFilteredFormattedTradeBookData[month] = OneMonth;

            const allNewAllFormattedTradeBookData = allFormattedTradeBookData;
            allFormattedTradeBookData[month] = OneMonth;

            setAllFormattedTradeBookData({});
            setFilteredFormattedTradeBookData({});

            setAllFormattedTradeBookData(allNewAllFormattedTradeBookData);
            setFilteredFormattedTradeBookData(allNewFilteredFormattedTradeBookData);
        }
    };

    const getdate = async (value, month) => {
        setIsMonthSelected(false);
        setIsAllSelected(false);

        if (month[0].length > 0 && month[1].length > 0) {
            setAllRangeMonthReport({});
            setMonthRangeToBeSelect([moment(month[0]), moment(month[1])]);
            getAllReports(false, true, month, null, true, {});
        }

        if (month[0].length === 0 && month[1].length === 0) {
            setMonthRangeToBeSelect([]);
        }
    };
    return (
        <>
            <Page title="MonthlyReports">
                <Container maxWidth={themeStretch ? false : 'xl'}>
                    <HeaderBreadcrumbs
                        heading="Monthly Reports"
                        links={[{name: 'Dashboard', href: urlConstants.HOME_PAGE}, {name: 'Monthly Reports'}]}
                    />
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={3} sm={3} lg={2}>
                            <FormControl required sx={{width: '100%'}}>
                                <Button
                                    variant={!isMonthSelected ? 'outlined' : 'contained'}
                                    size="large"
                                    onClick={() => {
                                        setAllRangeMonthReport({});
                                        setMonthRangeToBeSelect([]);
                                        setIsMonthSelected(true);
                                        setIsAllSelected(false);
                                        getAllReports(false, false, [], null, true, {});
                                    }}
                                >
                                    Last 6 Months
                                </Button>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={3} sm={4} lg={3}>
                            <FormControl required sx={{width: '100%'}}>
                                <RangePicker
                                    className={'createDateRangePicker'}
                                    picker="month"
                                    value={monthRangeToBeSelect}
                                    popupClassName={'createDateRangePicker'}
                                    onChange={(value, event) => getdate(value, event)}
                                    {...(!isLight && {
                                        className: 'date-picker-dark',
                                    })}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={3} sm={3} lg={2}>
                            <FormControl required sx={{width: '100%'}}>
                                <Button
                                    variant={!isAllSelected ? 'outlined' : 'contained'}
                                    size="large"
                                    onClick={() => {
                                        setAllRangeMonthReport({});
                                        setMonthRangeToBeSelect([]);
                                        setIsMonthSelected(false);
                                        setIsAllSelected(true);
                                        getAllReports(true, false, [], null, true, {});
                                    }}
                                >
                                    {' '}
                                    All Months{' '}
                                </Button>
                            </FormControl>
                        </Grid>
                        {isLoading ? (
                            <div style={{width: '100%', textAlign: 'center', padding: '100px'}}>
                                <CircularProgress sx={{color: SystemColor.originColor}} size={24} color="inherit"/>
                            </div>
                        ) : (
                            <Grid item xs={12}>
                                {(chartData?.sentOrders?.length > 0 ||
                                    chartData?.returnOrders?.length > 0 ||
                                    chartData?.investment?.length > 0 ||
                                    chartData?.paymentReceived?.length > 0 ||
                                    chartData?.netProfit?.length > 0) && (
                                    <Card sx={{mb: 2, mt: 1}}>
                                        <CardHeader
                                            title="All Monthly Summary"
                                            // subheader="(+43%) than last year"
                                        />
                                        <Box sx={{p: 3, pb: 1}} dir="ltr">
                                            <SummaryReportChart xAxisData={xAxisData} chartData={chartData}/>
                                        </Box>
                                    </Card>
                                )}

                                <Grid item xs={12} align="right" sx={{pb: 2}}>
                                    <Button onClick={handleGenerateReportModel} sx={{m: 1}} variant="contained">
                                        Generate New Report
                                    </Button>
                                </Grid>

                                <Grid item xs={12} sx={{pb: 2}}>
                                    {Object.keys(allFormattedTradeBookData)
                                        .sort()
                                        .reverse()
                                        .map((month) => (
                                            <OutlineButtonFilter key={`${month}_1`} month={month}/>
                                        ))}
                                </Grid>

                                <Grid item xs={12} sx={{pb: 2}}>
                                    {Object.keys(filteredFormattedTradeBookData).map((item) => (
                                        <MonthlyReportCard
                                            key={item}
                                            data={filteredFormattedTradeBookData[`${item}`]}
                                            onRefreshMethod={refreshMonthCard}
                                        />
                                    ))}
                                </Grid>

                                <BootstrapDialog
                                    sx={{'& .MuiDialog-paper': {width: '100%', maxHeight: 435}}}
                                    maxWidth="sm"
                                    onClose={handleClose}
                                    aria-labelledby="customized-dialog-title"
                                    open={openGenerateNewReport}
                                >
                                    <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                                        Generate New Report
                                    </BootstrapDialogTitle>
                                    <Divider orientation="horizontal" flexItem/>
                                    <DialogContent>
                                        <Box>
                                            <Stack spacing={3}>
                                                <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>
                                                    <AccountSelector allData={userData}
                                                                     onChangeHandler={handleSelectedAccount}/>
                                                </Stack>
                                                {accountError ?
                                                    <span style={{color: 'red'}}>{accountError}</span> : null}
                                                <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>
                                                    <FormControl required sx={{width: '100%'}}>
                                                        <InputLabel>Select Year</InputLabel>
                                                        <Select
                                                            fullWidth
                                                            defaultValue=""
                                                            placeholder={'Select Year'}
                                                            onChange={handleSelectedYear}
                                                            input={<OutlinedInput label="Select Year"/>}
                                                        >
                                                            {yearList.map((option, index) => (
                                                                <MenuItem key={index} value={option.value}>
                                                                    {option.name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                        <div style={{color: 'red'}}>{yearError}</div>
                                                    </FormControl>

                                                    <Autocomplete
                                                        fullWidth
                                                        multiple
                                                        disableCloseOnSelect
                                                        onChange={(event, value) => {
                                                            setSelectedMonth(value);
                                                        }}
                                                        options={monthList.map((option) => option.name)}
                                                        renderInput={(params) => <TextField {...params}
                                                                                            label="Select Month*"/>}
                                                    />

                                                    <div style={{color: 'red'}}>{monthError}</div>
                                                </Stack>
                                            </Stack>
                                        </Box>
                                    </DialogContent>
                                    <Divider orientation="horizontal" flexItem/>
                                    <DialogActions>
                                        <Button variant="contained" autoFocus onClick={handleGenerateReport}>
                                            Generate Report
                                        </Button>
                                    </DialogActions>
                                </BootstrapDialog>
                            </Grid>
                        )}
                    </Grid>
                </Container>
            </Page>
        </>
    );
}
