/* eslint-disable */
import { Box, Card, Grid, Stack, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import Iconify from '../../../components/Iconify';

const IconWrapperStyle = styled('div')(({ theme }) => ({
  width: 24,
  height: 24,
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.primary.main,
  backgroundColor: alpha(theme.palette.primary.light, 0.16),
}));

export default function AppWidgetSummary({
  title = '',
  Count={},
  type = '',
  acTypes = [],
}) {
  // console.log("Count",Count)
  return (
    <Card sx={{ display: 'flex', alignItems: 'center', p: 3 }} >
      <Box sx={{ flexGrow: 1 }}>

        <Typography variant="subtitle1"  className={title}>{title}</Typography>

        <Stack direction="row" alignItems="center" spacing={1} sx={{ mt: 2, mb: 1 }}>
          <div
            style={{
              width: '90%',
            }}
          >
            <Typography component="span" variant="h3" >

              {acTypes.reduce((acc, val) => (acc + (Count.plateformViseCount && Count.plateformViseCount[val]  ? Count.plateformViseCount[val]:0)), 0)}
              {Count.plateformViseComboCount&&`/${acTypes.reduce((acc, val) => (acc + (Count.plateformViseComboCount[val] ? Count.plateformViseComboCount[val]:0)), 0)}`}
            </Typography>
          </div>
          <div
            style={{
              textAlign: 'end',
              width: '10%',
            }}
          >
            <IconWrapperStyle>
              <Iconify width={25} height={25} icon={type} />
            </IconWrapperStyle>
          </div>
        </Stack>
        <Grid
          style={{
            marginTop: '5px',
          }}
          container
          spacing={3}
        >
          {acTypes.map(value => (
              <Grid item xs={4} md={4} lg={4} xl={3} sm={4}>
            <div style={{ textAlign: 'center' }}>
              <h4>{(Count.plateformViseCount && Count.plateformViseCount[value])? ((Count.plateformViseComboCount && Count.plateformViseComboCount[value]) ? `${Count.plateformViseCount[value]}/${Count.plateformViseComboCount[value]}` : `${Count.plateformViseCount[value]}`):0}</h4>
              <p>{value.charAt(0).toUpperCase() + value.slice(1) }</p>
            </div>
          </Grid>))}
        </Grid>
      </Box>
    </Card>
  );
}
