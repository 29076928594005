/* eslint-disable */
import React, { useState, useEffect } from 'react';
import {
  Autocomplete,
  Box,
  Button, Card,
  Checkbox,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Select,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import DialogContent from '@mui/material/DialogContent';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import { SketchPicker } from 'react-color';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { isEmpty } from 'lodash';
import FormControlLabel from '@mui/material/FormControlLabel';
import { DatePicker } from 'antd';
import moment from 'moment';
import NoDataFound from '../../../components/Common/NoDataFound';
import useAuth from '../../../hooks/useAuth';
import AccountSelector from '../../../components/Common/AccountSelector';
import {
  BootstrapDialog,
  BootstrapDialogTitle,
  changeDataFormat,
  changeDateFormat, CreateExcelFile,
  getAccountsDataByType, isAdmin,
  isDate,
  JSONToCSVConvertor,
  setAccountSelectorData,
  SystemColor,
} from '../../../utils/utilities';
import DataGridTable from '../../../components/Common/DataGridTable';
import {ClaimIssue, CreatePostAPICall, V2Management} from '../../../utils/apis';
import {LoadingButton} from "@mui/lab";

export default function ReportedClaimIssueDetails() {
  const { user } = useAuth();
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';
  const localUser = window.localStorage.getItem('userEmailId');
  const { enqueueSnackbar } = useSnackbar();
  const [userData, setUserData] = useState([]);
  const [selectedAccountsData, setSelectedAccountsData] = useState([]);
  const [selectedAccountsData2, setSelectedAccountsData2] = useState([]);
  const [actualReportedClaimIssueOrders, setActualReportedClaimIssueOrders] = useState([]);
  const [reportedClaimIssueOrders, setReportedClaimIssueOrders] = useState([]);
  const [isOrderReportTableLoading, setIsOrderReportTableLoading] = useState(false);
  const [reportedClaimIssueAPIOrders, setReportedClaimIssueAPIOrders] = useState([]);
  const [openTagModal, setOpenTagModal] = useState(false);
  const [addTagModal, setAddTagModal] = useState(false);
  const [tagsData, setTagsData] = useState([]);
  const [color, setColor] = useState('#FFFFFF');
  const [tagName, setTagName] = useState('');
  const [selectedRow, setSelectedRow] = useState(null);
  const [tagLoader, setTagLoader] = useState(false);
  const [issyncTicketStatus, setIssyncTicketStatus] = useState(false);
  const newRemovedData = [];
  const [nodata, setNodata] = useState('Please Select An Options To See Orders Analysis Details.');
  const [tableActualData, setTableActualData] = useState([]);
  const [allTicketIdsArrayLocal, setAllTicketIdsArrayLocal] = useState([]);
  const [ticketSubOrderNoMapping, setTicketSubOrderNoMapping] = useState({});
  const [ticketDateSubOrderNoMapping, setTicketDateSubOrderNoMapping] = useState([]);
  const [ticketReOpenDateSubOrderNoMapping, setTicketReOpenDateSubOrderNoMapping] = useState([]);
  const [tagColorSubOrderNoMapping, setTagColorSubOrderNoMapping] = useState([]);
  const [localFilterCriteria, setLocalFilterCriteria] = useState({
    pod_reported: false,
    pending_reported: false,
    payment_reported: false,
    received_reported: '',
    can_reopen_filter: false,
    reported_date: false,
  });

  const columns = [
    {
      accessorKey: 'platformName',
      header: 'Platform',
      size: '20',
    },
    {
      accessorKey: 'email',
      header: 'Account',
      size: '20',
    },
    {
      accessorKey: 'company',
      header: 'Company',
      size: '20',
    },
    {
      accessorKey: 'supplier_id',
      header: 'Supplier Id',
      size: '20',
    },
    {
      accessorKey: 'sub_order_no',
      header: 'Suborder No',
      size: '20',
    },
    {
      accessorKey: 'pay_status',
      header: 'Payment Status',
      size: '20',
    },
    {
      accessorKey: 'Tag',
      header: 'Tag Color',
      size: '20',
      Cell: ({ cell }) => (
        <div style={{ display: 'flex' }}>
          {cell.row.original?.all_tickets_info.map((color, index) => {
            return (
              <div
                style={{
                  backgroundColor: `${color.tag_color}`,
                  width: '15px',
                  height: '15px',
                  marginLeft: 20,
                  marginTop: 3,
                }}
              />
            );
          })}
        </div>
      ),
    },
  ];

  const tagColumn = [
    {
      accessorKey: 'Tag_Name',
      header: 'Tag Name',
    },
    {
      accessorKey: 'Color_Name',
      header: 'Color Name',
      Cell: ({ cell }) => {
        return (
          <>
            <div style={{ display: 'flex' }}>
              {cell.row.original.Color_Name}
              <div
                style={{
                  backgroundColor: `${cell.row.original.Color_Name}`,
                  width: '15px',
                  height: '15px',
                  marginLeft: 20,
                  marginTop: 3,
                }}
              />
            </div>
          </>
        );
      },
    },
    {
      accessorKey: 'Delete',
      header: 'Delete',
      size: 10,
      Cell: ({ cell }) => {
        return (
          <IconButton
            aria-label="delete"
            onClick={(e) => {
              onHandleTag('remove', cell.row.original?.Tag_Name);
            }}
          >
            <DeleteIcon style={{ color: 'red' }} />
          </IconButton>
        );
      },
    },
  ];

  // Tickets Information Tables Column....
  const tktColumns = [
    {
      accessorKey: 'ticket_id',
      header: 'Ticket Id',
      size: '10',
    },
    {
      accessorKey: 'report_type',
      header: 'Ticket Type',
      size: '10',
    },
    {
      accessorKey: 'is_resolved',
      header: 'Is Resolved',
      size: '10',
    },
    {
      accessorKey: 'reported_date',
      header: 'Ticket Date',
      size: '10',
    },
    {
      accessorKey: 're_open_date',
      header: 'Till Re-Open Date',
      size: '10',
    },
    {
      accessorKey: 'can_re_open',
      header: 'Can Re-Open',
      size: '10',
      Cell: ({ cell }) => (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            if (cell.row.original?.can_re_open === 'Yes' && cell.row.original.acTypeForAllTicketInfo === 'meesho') {
              const link = `https://supplier.meesho.com/panel/v3/new/payouts/${cell.row.original.identifier}/support/ticket/${cell.row.original.ticket_id}`;
              window.open(`${link}`, '_blank').focus();
            }
          }}
        >
          {cell.row.original?.can_re_open}
        </div>
      ),
    },
    {
      accessorKey: 'ticket_status',
      header: 'Ticket Status',
      size: '10',
      Cell: ({ cell }) => {
        return (
          <>
            <div style={{ display: 'flex' }}>
              <div
                style={{
                  backgroundColor: `${cell.row.original?.tag_color}`,
                  width: '15px',
                  height: '15px',
                  marginLeft: 20,
                  marginTop: 3,
                }}
              />
            </div>
          </>
        );
      },
    },
  ];

  const getTagsData = async () => {
    const body = {
      username: user?.email ? user.email : localUser,
      resourceType: 'claimTags',
      manageAction: 'get',
      actionType: 'manageNeededResources',
    };
    const response = await axios.post(ClaimIssue, body);
    if (response) {
      setTagsData(response.data.body);
    }
  };

  const getSelectAllMeeshoClaimIssueData = async (index) => {
    if (selectedAccountsData[index]) {
      await getReportedClaimIssueOrders(selectedAccountsData[index]);
      await getSelectAllMeeshoClaimIssueData(index + 1);
    }
  };

  const getFormattedData = (data, companyData) => {
    const formattedData = reportedClaimIssueAPIOrders;
    Object.entries(data).forEach(([key, value]) => {
      const account = [];
      const platformData = {};
      const platName = key;
      platformData.platformName = key;
      Object.entries(value).forEach(([emailId, value]) => {
        const accountData = {};
        const supplier = [];
        accountData.accountId = emailId;
        Object.entries(value).forEach(([key, value]) => {
          const supplierTempData = {};
          const supplierData = [];
          supplierTempData.supplierId = key;
          Object.entries(value).forEach(([key, value]) => {
            const data = {};
            data.sub_order_no = value[0];
            data.pending_reported = value[1];
            data.received_reported = value[2];
            data.pod_reported = value[3];
            data.payment_reported = value[4];
            data.is_received = value[5];
            data.all_tickets_info = [];
            value[6].forEach((tickets) => {
              const ticketData = {};
              if (tickets[0] == null) {
                return;
              }
              ticketData.index = data.all_tickets_info.length + 1;
              ticketData.ticket_id = tickets[0];
              ticketData.report_type = tickets[1];
              ticketData.reported_date = tickets[2] ? changeDateFormat(tickets[2]) : '-';
              ticketData.reported_date_to_filter = tickets[2];
              ticketData.ticket_status = tickets[3];
              ticketData.last_update = tickets[4];
              ticketData.is_resolved = tickets[5] && tickets[5].toLowerCase() === 'true' ? 'Yes' : 'No';
              ticketData.re_open_date = tickets[6] ? changeDateFormat(tickets[6]) : '-';
              ticketData.re_open_date_to_filter = tickets[6];
              ticketData.can_re_open = tickets[7] ? 'Yes' : 'No';
              ticketData.can_reopen_filter = tickets[7];
              ticketData.tag_color = tickets[8];
              ticketData.tag_name = tickets[9];
              ticketData.sKey = tickets[10];
              ticketData.suborder_no = value[0];
              ticketData.identifier = selectedAccountsData.filter((x) => x.Email === emailId)[0].identifier;
              ticketData.acTypeForAllTicketInfo = selectedAccountsData.filter((x) => x.Email === emailId)[0].AcType;
              ticketData.platform = platName;
              ticketData.supplier_id = supplierTempData.supplierId;
              ticketData.email = accountData.accountId;
              data.all_tickets_info.push(ticketData);
            });
            data.pay_status = value[7];
            data.shipment_status_list = value[8];
            data.return_sub_type = value[9];
            data.net_amount = value[10];
            data.penalty = value[11];
            data.compensation = value[12];
            data.waiver = value[13];
            data.recovery = value[14];
            data.order_date = value[15];
            data.pick_up_date = value[16];
            data.invoice_date = value[17];
            data.latest_sent_awb = value[18];
            data.sent_awb = value[19];
            if (value[20]) data.sku = typeof value[20] === 'string' ? value[20] : value[20].Sku_Name;
            else data.sku = '';
            data.size = value[21];
            data.sent_quantity = value[22];
            data.sent_courier = value[23];
            data.self_cost = value[24];
            data.is_cancelled = value[25];
            data.expected_dispatch_date = value[26];
            data.shipment_type = value[27];
            data.is_exchange = value[29];
            data.no_of_exchange = value[30];
            data.order_status = value[31];
            data.delivery_date = value[32];
            data.return_quantity = value[33];
            data.latest_return_awb = value[34];
            data.return_date = value[35];
            data.return_courier = value[36];
            data.return_type = value[37];
            data.expected_delivered_date = value[38];
            data.return_sub_type = value[39];
            supplierData.push(data);
          });
          supplierTempData.supplierData = supplierData;
          supplier.push(supplierTempData);
        });
        accountData.accountData = supplier;
        account.push(accountData);
      });
      platformData.platformData = account;
      formattedData.push(platformData);
    });

    setReportedClaimIssueAPIOrders(formattedData);
    const storedData = JSON.parse(localStorage.getItem('cancel_image_blob_data'));
    const tempFilteredData = [];
    const tempOrderNoDate = [];
    const tempReOpenOrder = [];
    const tempColorTag = [];
    if (formattedData.length > 0) {
      formattedData.forEach((item) => {
        item.platformData.forEach((platformData) => {
          platformData.accountData.forEach((accountData) => {
            accountData.supplierData.forEach((supData) => {
              const tempData = {};
              tempData.platformName = item.platformName;
              tempData.isSelected = false;
              tempData.id = tempFilteredData.length + 1;
              tempData.latest_sent_awb = supData.latest_sent_awb ? supData.latest_sent_awb : ' - ';
              tempData.pay_status = supData.pay_status ? supData.pay_status : ' - ';
              tempData.sub_order_no = supData.sub_order_no ? supData.sub_order_no : ' - ';
              tempData.payment_date = supData.payment_date ? supData.payment_date : ' - ';
              tempData.pick_up_date = supData.pick_up_date ? supData.pick_up_date : ' - ';
              tempData.invoice_date = supData.invoice_date ? supData.invoice_date : ' - ';
              tempData.sku = supData.sku ? supData.sku : 'NA';
              tempData.sent_quantity = supData.sent_quantity ? supData.sent_quantity.toString() : '0';
              tempData.size = supData.size ? supData.size : 'NA';
              tempData.payment_status = supData.payment_status ? supData.payment_status : ' - ';
              tempData.return_date = supData.return_date ? supData.return_date : ' - ';
              tempData.order_status = supData.order_status ? supData.order_status : ' - ';
              tempData.return_type = supData.return_type ? supData.return_type : ' - ';
              tempData.order_date = supData.order_date ? supData.order_date : ' - ';
              tempData.net_amount = supData.net_amount ? supData.net_amount : ' - ';
              tempData.penalty = supData.penalty ? supData.penalty : ' - ';
              tempData.compensation = supData.compensation ? supData.compensation : ' - ';
              tempData.waiver = supData.waiver ? supData.waiver : ' - ';
              tempData.recovery = supData.recovery ? supData.recovery : ' - ';
              tempData.sent_awb = supData.sent_awb ? supData.sent_awb : ' - ';
              tempData.sent_courier = supData.sent_courier ? supData.sent_courier : ' - ';
              tempData.self_cost = supData.self_cost ? supData.self_cost : ' - ';
              tempData.delivery_date = supData.delivery_date ? supData.delivery_date : ' - ';
              tempData.return_quantity = supData.return_quantity ? supData.return_quantity : '0';
              tempData.return_courier = supData.return_courier ? supData.return_courier : ' - ';
              tempData.is_cancelled = supData.is_cancelled ? supData.is_cancelled : ' - ';
              tempData.expected_dispatch_date = supData.expected_dispatch_date ? supData.expected_dispatch_date : ' - ';
              tempData.shipment_type = supData.shipment_type ? supData.shipment_type : ' - ';
              tempData.is_exchange = supData.is_exchange ? supData.is_exchange : ' - ';
              tempData.no_of_exchange = supData.no_of_exchange ? supData.no_of_exchange : ' - ';
              tempData.latest_return_awb = supData.latest_return_awb ? supData.latest_return_awb : ' - ';
              tempData.expected_delivered_date = supData.expected_delivered_date
                ? supData.expected_delivered_date
                : ' - ';
              tempData.return_sub_type = supData.return_sub_type ? supData.return_sub_type : ' - ';
              tempData.is_received = supData.is_received ? supData.is_received : 'false';
              tempData.re_condition = supData.re_condition ? supData.re_condition : ' - ';
              tempData.final_settlement = supData.final_settlement ? supData.final_settlement : ' - ';
              tempData.pending_reported = supData.pending_reported ? supData.pending_reported : '';
              tempData.received_reported = supData.received_reported ? supData.received_reported.toString() : 'false';
              tempData.pod_reported = supData.pod_reported ? supData.pod_reported : '';
              tempData.payment_reported = supData.payment_reported ? supData.payment_reported : '';
              tempData.all_tickets_info = supData.all_tickets_info ? supData.all_tickets_info : ' - ';

              supData.all_tickets_info.forEach((data) => {
                tempData.can_reopen_filter = data.can_reopen_filter ? data.can_reopen_filter : '';
                ticketSubOrderNoMapping[`${data.ticket_id}`] = data.suborder_no;
                if (data.reported_date_to_filter) {
                  const newDate = moment(data.reported_date_to_filter).format('YYYY-MM-DD');
                  tempOrderNoDate.push({ orderDate: newDate, subOrderNo: data.suborder_no });
                }
                if (data.re_open_date_to_filter) {
                  tempReOpenOrder.push({ reOpenDate: data.re_open_date_to_filter, subOrderNo: data.suborder_no });
                }
                if (data.tag_color) {
                  tempColorTag.push({
                    tagColorName: data.tag_name,
                    tagColorCode: data.tag_color,
                  });
                }
                if (data.ticket_id !== '' && data.ticket_id !== 'MANNUAL') {
                  allTicketIdsArrayLocal.push(data.ticket_id);
                }
              });

              tempData.company = companyData[item?.platformName][accountData?.supplierId]
                ? companyData[item?.platformName][accountData?.supplierId]
                : ' - ';

              tempData.email = platformData.accountId ? platformData.accountId : ' - ';
              tempData.supplier_id = accountData.supplierId ? accountData.supplierId : ' - ';

              if (storedData)
                tempData.cancel_image_url =
                  storedData.filter((x) => x.supplierId === accountData.supplierId).length > 0
                    ? storedData.filter((x) => x.supplierId === accountData.supplierId)[0].blobUrl
                    : null;
              else tempData.cancel_image_url = null;
              tempFilteredData.push(tempData);
            });
          });
        });
      });
    }

    const uinqueColorTag = tempColorTag.filter(
      (thing, index, self) => index === self.findIndex((t) => t.tagColorName === thing.tagColorName)
    );

    setReportedClaimIssueOrders(tempFilteredData);
    setTableActualData(tempFilteredData);
    setActualReportedClaimIssueOrders(tempFilteredData);
    setAllTicketIdsArrayLocal(allTicketIdsArrayLocal);
    setTicketSubOrderNoMapping(ticketSubOrderNoMapping);
    setTicketDateSubOrderNoMapping(tempOrderNoDate);
    setTicketReOpenDateSubOrderNoMapping(tempReOpenOrder);
    setTagColorSubOrderNoMapping(uinqueColorTag);
  };

  const getReportedClaimIssueOrders = async (item, lastKey = null) => {
    const body = {
      username: user?.email ? user.email : localUser,
      subAccountId: item.value,
      tpEmailId: item.Email,
      acType: item.AcType,
      actionType: 'getReportedOrders',
    };
    if (lastKey) body.last_key = lastKey;

    const response = await CreatePostAPICall(enqueueSnackbar, ClaimIssue, body);
    if (response) {
      getFormattedData(response.data.body, response.data.companyName, response.data.skuImageData);
      if (response.data.last_key) {
        await getReportedClaimIssueOrders(item, response.data.last_key);
      }
    }
    return true;
  };

  const handleSelectedAccount = async (event, value) => {
    setReportedClaimIssueOrders([]);
    setReportedClaimIssueAPIOrders([]);
    const response = await setAccountSelectorData(value, userData);
    setSelectedAccountsData(response[1]);
  };

  const handleSelectedAccount2 = async (event, value) => {
    const response = await setAccountSelectorData(value, userData);
    setSelectedAccountsData2(response[1]);
  };

  useEffect(() => {
    getAccountsDataByType('meesho', true).then((filteredData) => {
      setUserData(filteredData);
    });
    getTagsData();
  }, []);

  useEffect(() => {
    if (selectedAccountsData.length > 0) {
      setIsOrderReportTableLoading(true);
      getSelectAllMeeshoClaimIssueData(0).then((_) => {
        setIsOrderReportTableLoading(false);
      });
    }
  }, [selectedAccountsData]);

  const checkIsAllResolved = (subOrderNo, ticketId) => {
    const tableData = reportedClaimIssueOrders;
    const tempData = [];
    tableData.forEach((item) => {
      if (item.sub_order_no === subOrderNo) {
        const tempTicketInfo = item.all_tickets_info.filter(
          (x) => x.is_resolved.toLowerCase() === 'no' && x.ticket_id !== ticketId
        );
        if (tempTicketInfo.length > 0) {
          item.all_tickets_info = tempTicketInfo;
          tempData.push(item);
        }
      } else {
        tempData.push(item);
      }
    });
    setReportedClaimIssueOrders(tempData);
    const tableActualData = actualReportedClaimIssueOrders;
    const tempActualData = [];
    tableActualData.forEach((item) => {
      if (item.sub_order_no === subOrderNo) {
        const tempTicketInfo = item.all_tickets_info.filter(
          (x) => x.is_resolved.toLowerCase() === 'no' && x.ticket_id !== ticketId
        );
        if (tempTicketInfo.length > 0) {
          item.all_tickets_info = tempTicketInfo;
          tempActualData.push(item);
        }
      } else {
        tempActualData.push(item);
      }
    });
    setActualReportedClaimIssueOrders(tempActualData);
  };

  const callApiToInnerMarkAsResolved = async (
    userEmailId,
    email,
    supplierId,
    acType,
    ticketId,
    subOrderNo,
    sKey,
    allSolved,
    isMultiMarkAs = false
  ) => {
    const body = {
      username: userEmailId,
      tpEmailId: email,
      subAccountId: supplierId,
      acType,
      ticket_id: ticketId,
      suborder_no: subOrderNo,
      sKeys: sKey,
      allSolved,
      actionType: 'getReportedOrders',
      action: 'ticketMarkAsResolve',
      isMultiMarkAs,
    };
    const response = await CreatePostAPICall(enqueueSnackbar, ClaimIssue, body);
    if (response) {
      checkIsAllResolved(subOrderNo[0], ticketId[0]);
      enqueueSnackbar('Request Submitted Successfully');
    }
  };

  const innerMarkAsResolved = async (row) => {
    const tableData = reportedClaimIssueOrders;
    let allSolved = true;
    tableData.forEach((item) => {
      if (item.sub_order_no === row.original?.suborder_no) {
        const tempTicketInfo = item.all_tickets_info.filter(
          (x) => x.is_resolved.toLowerCase() === 'no' && x.ticket_id !== row.original?.ticket_id
        );
        if (tempTicketInfo.length > 0) {
          allSolved = false;
        }
      }
    });

    callApiToInnerMarkAsResolved(
      user?.email,
      row.original?.email,
      row.original?.supplier_id,
      row.original?.platform,
      [row.original?.ticket_id],
      [row.original?.suborder_no],
      [row.original?.sKey],
      allSolved
    );
  };

  const addTagModalOpen = async (row) => {
    setSelectedRow(row);
    setAddTagModal(!addTagModal);
  };

  const addTagModalClose = () => {
    setAddTagModal(false);
    setSelectedRow(null);
  };

  const removeTag = async (row) => {
    const body = {
      username: user?.email ? user.email : localUser,
      tpEmailId: row.original.email,
      subAccountId: row.original.supplier_id,
      acType: row.original.platform,
      ticket_id: row.original.ticket_id,
      suborder_no: row.original.suborder_no,
      tag_name: row.original.tag_name,
      tag_color: row.original.tag_color,
      actionType: 'getReportedOrders',
      action: 'removeTag',
    };
    const response = await CreatePostAPICall(enqueueSnackbar, ClaimIssue, body);
    if (response) {
      const actualData = reportedClaimIssueOrders;
      const data = actualData.map((item) => {
        if (item.sub_order_no === row.original.suborder_no) {
          item.all_tickets_info.map((x) => {
            if (x.ticket_id === row.original.ticket_id) {
              x.tag_color = null;
              x.tag_name = null;
            }
            return x;
          });
        }
        return item;
      });
      setReportedClaimIssueOrders([]);
      setReportedClaimIssueOrders(data);
      enqueueSnackbar('Tag Removed Successfully.');
    }
  };

  const expandRow = (row) => (
    <div>
      <Typography sx={{ pl: 2, pt: 2 }} variant="h6">
        Last Update :
      </Typography>
      <div style={{ padding: '0px 16px 10px 14px' }}>{row.original.last_update}</div>
      <Box sx={{ display: 'block', textAlign: 'end' }}>
        <Button sx={{ ml: '5px' }} onClick={() => addTagModalOpen(row)} variant="contained">
          Add Tag
        </Button>
        <Button sx={{ ml: '5px' }} onClick={() => removeTag(row)} variant="contained">
          Remove Tag
        </Button>
        <Button sx={{ ml: '5px' }} onClick={() => innerMarkAsResolved(row)} variant="contained">
          Mark as resolved
        </Button>
      </Box>
    </div>
  );

  const expandRowComponent = (row) => (
    <>
      <Typography sx={{ pl: 2 }} variant="h6">
        Order Details
      </Typography>
      <Divider sx={{ borderStyle: 'dashed' }} />
      <Box
        sx={{
          display: 'grid',
          gridAutoFlow: 'row',
          gridTemplateColumns: 'repeat(3, 1fr)',
          gridTemplateRows: 'auto',
          p: 2,
        }}
      >
        <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
          <b style={{ marginRight: '2px' }}>Platform :</b>{' '}
          <div style={{ marginLeft: '2px' }}> {row.original?.platformName}</div>
        </div>
        <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
          <b style={{ marginRight: '2px' }}>Sub Order Number :</b>
          <div style={{ marginLeft: '2px' }}>{row.original?.sub_order_no}</div>
        </div>
        <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
          <b style={{ marginRight: '2px' }}>Received Reported :</b>
          <div style={{ marginLeft: '2px' }}>{changeDataFormat(row.original?.received_reported)} </div>
        </div>
        <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
          <b style={{ marginRight: '2px' }}>Email :</b> <div style={{ marginLeft: '2px' }}> {row.original?.email}</div>
        </div>
        <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
          <b style={{ marginRight: '2px' }}>Is Received :</b>
          <div style={{ marginLeft: '2px' }}>{changeDataFormat(row.original?.is_received)} </div>
        </div>
        <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
          <b style={{ marginRight: '2px' }}>POD Reported :</b>
          <div style={{ marginLeft: '2px' }}>{changeDataFormat(row.original?.pod_reported)} </div>
        </div>
        <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
          <b style={{ marginRight: '2px' }}>Company :</b>{' '}
          <div style={{ marginLeft: '2px' }}> {row.original?.company}</div>
        </div>
        <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
          <b style={{ marginRight: '2px' }}>Pending Reported :</b>
          <div style={{ marginLeft: '2px' }}>{changeDataFormat(row.original?.pending_reported)} </div>
        </div>
        <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
          <b style={{ marginRight: '2px' }}>Payment Reported :</b>
          <div style={{ marginLeft: '2px' }}>{changeDataFormat(row.original?.payment_reported)} </div>
        </div>
        <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
          <b style={{ marginRight: '2px' }}>Supplier Id :</b>{' '}
          <div style={{ marginLeft: '2px' }}> {row.original?.supplier_id}</div>
        </div>
        <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
          <b style={{ marginRight: '2px' }}>Pay Status :</b>{' '}
          <div style={{ marginLeft: '2px' }}> {row.original?.pay_status}</div>
        </div>
        <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
          <b style={{ marginRight: '2px' }}>Final Settlement :</b>{' '}
          <div style={{ marginLeft: '2px' }}> {row.original?.final_settlement}</div>
        </div>

        <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
          <b style={{ marginRight: '2px' }}>Return Courier :</b>{' '}
          <div style={{ marginLeft: '2px' }}> {row.original?.return_courier}</div>
        </div>
        <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
          <b style={{ marginRight: '2px' }}>Return Type :</b>{' '}
          <div style={{ marginLeft: '2px' }}> {row.original?.return_type}</div>
        </div>
        <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
          <b style={{ marginRight: '2px' }}>Return Sub Type :</b>{' '}
          <div style={{ marginLeft: '2px' }}> {row.original?.return_sub_type}</div>
        </div>
        <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
          <b style={{ marginRight: '2px' }}>Compensation :</b>{' '}
          <div style={{ marginLeft: '2px' }}> {row.original?.compensation}</div>
        </div>
        <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
          <b style={{ marginRight: '2px' }}>Waiver :</b>{' '}
          <div style={{ marginLeft: '2px' }}> {row.original?.waiver}</div>
        </div>
        <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
          <b style={{ marginRight: '2px' }}>Recovery :</b>{' '}
          <div style={{ marginLeft: '2px' }}> {row.original?.recovery}</div>
        </div>
        <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
          <b style={{ marginRight: '2px' }}>SKU :</b> <div style={{ marginLeft: '2px' }}> {row.original?.sku}</div>
        </div>
        <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
          <b style={{ marginRight: '2px' }}>Delivery Date:</b>{' '}
          <div style={{ marginLeft: '2px' }}>
            {isDate(row.original?.delivery_date) ? changeDateFormat(row.original?.delivery_date) : ' - '}
          </div>
        </div>
      </Box>
      <Typography sx={{ pl: 2, pt: 2 }} variant="h6">
        Tickets Information
      </Typography>
      <Divider sx={{ borderStyle: 'dashed' }} />
      <>
        <DataGridTable
          columns={tktColumns}
          data={row.original.all_tickets_info}
          renderDetailPanel={expandRow}
          expand={'true'}
        />
      </>
    </>
  );

  const exportToCsvMeeshoData = () => {
    if (reportedClaimIssueOrders.length !== 0) {
      const updatedData = reportedClaimIssueOrders.map(item => {
        const { all_tickets_info, ...rest } = item;
        const updatedTicketsInfo = all_tickets_info.map(ticketInfo => {
          const { ticket_id, reported_date, ...ticketInfoRest } = ticketInfo;
          return { ticket_id, reported_date, ...ticketInfoRest };
        });

        return { all_tickets_info: updatedTicketsInfo, ...rest, ticket_id: all_tickets_info[0].ticket_id, reported_date: all_tickets_info[0].reported_date };
      });
      CreateExcelFile(updatedData, 'Report Data', true);
    }
  };

  const markAsResolved = async (value, table) => {
    const isLast = value === table.getSelectedRowModel().flatRows.at(-1).original;
    const body = {
      username: user?.email ? user.email : localUser,
      tpEmailId: value.email,
      acType: value.all_tickets_info[0].platform,
      ticket_id: [value.all_tickets_info[0].ticket_id],
      subAccountId: value.supplier_id,
      suborder_no: [value.sub_order_no],
      sKeys: [value.all_tickets_info[0].sKey],
      allSolved: true,
      actionType: 'getReportedOrders',
      action: 'ticketMarkAsResolve',
      isMultiMarkAs: false,
    };
    const response = await CreatePostAPICall(enqueueSnackbar, ClaimIssue, body);
    if (response) {
      newRemovedData.push(value.sub_order_no);
    }
    if (isLast) {
      const newData = reportedClaimIssueOrders.filter((item) => !newRemovedData.includes(item.sub_order_no));
      setReportedClaimIssueOrders(newData);
      enqueueSnackbar('Successfully Resolved');
    }
  };

  // Tag Color Started Here...
  const handleTagModelOpen = () => {
    setOpenTagModal(!openTagModal);
  };

  const handleTagModelClose = () => {
    setOpenTagModal(false);
  };

  const handleChangeComplete = (color) => {
    setColor(color.hex);
  };

  const onHandleTag = async (action, name) => {
    if (tagName === '' && action === 'set') {
      enqueueSnackbar('Please Enter Tag Name', { variant: 'error' });
    } else {
      const body = {
        username: user?.email ? user.email : localUser,
        detailsType: 'reportedPenal',
        manageAction: action,
      };
      if (action === 'set') {
        body.name = tagName;
        body.color = color;
      } else {
        body.name = name;
      }
      const response = await axios.post(ClaimIssue, body);
      if (response) {
        setTagName('');
        action === 'set' ? enqueueSnackbar('Tag Added Successfully.') : enqueueSnackbar('Tag Deleted Successfully.');
        if (action === 'set') {
          const tempData = {
            Color_Name: color,
            Tag_Name: tagName,
          };
          const existingTagArray = tagsData;
          setTagsData([]);
          existingTagArray.push(tempData);
          setTagsData(existingTagArray);
        } else {
          const newTagArray = tagsData.filter((item) => {
            return item.Tag_Name !== body.name;
          });
          setTagsData(newTagArray);
        }
      }
    }
  };

  const onLocalFilterChkChange = (event, name) => {
    setLocalFilterCriteria({
      ...localFilterCriteria,
      [name]: event.target.checked,
    });
  };

  const onLocalFilterTextChange = (event, name, value) => {
    console.log('value...............', value);
    setLocalFilterCriteria({
      ...localFilterCriteria,
      [name]: event ? event.target.value : value,
    });
  };

  const onSelectedTag = async (e) => {
    if (e.target.value) {
      setTagLoader(true);
      const body = {
        username: user?.email ? user.email : localUser,
        acType: selectedRow.original.platform,
        tpEmailId: selectedRow.original.email,
        subAccountId: selectedRow.original.supplier_id,
        ticket_id: selectedRow.original.ticket_id,
        report_date: selectedRow.original.reported_date,
        suborder_no: selectedRow.original.suborder_no,
        tag_name: e.target.value.split('-')[1],
        tag_color: e.target.value.split('-')[0],
        actionType: 'getReportedOrders',
        action: 'addTag',
      };
      const response = await CreatePostAPICall(enqueueSnackbar, ClaimIssue, body);
      if (response) {
        const actualData = actualReportedClaimIssueOrders;
        actualData.map((item) => {
          if (item.sub_order_no === selectedRow.original.suborder_no) {
            item.all_tickets_info.map((x) => {
              if (x.ticket_id === selectedRow.original.ticket_id) {
                x.tag_color = e.target.value.split('-')[0];
                x.tag_name = e.target.value.split('-')[1];
              }
              return x;
            });
          }
          return item;
        });
        setActualReportedClaimIssueOrders(actualData);
        enqueueSnackbar('Tag Added Successfully.');
        addTagModalClose();
      }
      setTagLoader(false);
    }
  };

  useEffect(() => {
    let actualData = tableActualData;

    if (localFilterCriteria.pod_reported) {
      const tempData = actualData.filter((x) => x.pod_reported && x.pod_reported !== '');
      actualData = tempData;
    }

    if (localFilterCriteria.pending_reported) {
      const tempData = actualData.filter((x) => x.pending_reported && x.pending_reported !== '');
      actualData = tempData;
    }

    if (localFilterCriteria.received_reported) {
      const tempData = actualData.filter((x) => x.received_reported && x.received_reported !== 'false');
      actualData = tempData;
    }

    if (localFilterCriteria.payment_reported) {
      const tempData = actualData.filter((x) => x.payment_reported && x.payment_reported !== '');
      actualData = tempData;
    }

    if (localFilterCriteria.can_reopen_filter) {
      const tempData = actualData.filter((x) => x.can_reopen_filter && x.can_reopen_filter !== '');
      actualData = tempData;
    }

    if (localFilterCriteria.can_reopen_filter) {
      const tempData = actualData.filter((x) => x.can_reopen_filter && x.can_reopen_filter !== '');
      actualData = tempData;
    }

    if (localFilterCriteria.ticket_id && localFilterCriteria.ticket_id !== '') {
      const subOrderNo = ticketSubOrderNoMapping[`${localFilterCriteria.ticket_id}`];
      const tempData = actualData.filter((x) => x.sub_order_no.includes(subOrderNo));
      actualData = tempData;
    }

    if (localFilterCriteria.reported_date && localFilterCriteria.reported_date !== '') {
      const tempData = [];

      actualData.forEach((data, index) => {
        let subOrderIndex = -1;
        data.all_tickets_info?.forEach((subData, idx) => {
          console.log(subData?.reported_date,localFilterCriteria.reported_date)
          if (moment(subData?.reported_date).format('YYYY-MM-DD') === localFilterCriteria.reported_date) {
            subOrderIndex = index;
          }
        });
        if (subOrderIndex > -1) {
          tempData.push(actualData[subOrderIndex]);
        }
      });
      actualData = tempData;
    }

    if (localFilterCriteria.colorTag && localFilterCriteria.colorTag !== '') {
      const tempData = [];

      actualData.forEach((data, index) => {
        let subOrderIndex = -1;
        data.all_tickets_info?.forEach((subData, idx) => {
          if (subData?.tag_name === localFilterCriteria.colorTag) {
            subOrderIndex = index;
          }
        });
        if (subOrderIndex > -1) {
          tempData.push(actualData[subOrderIndex]);
        }
      });
      actualData = tempData;
    }

    if (localFilterCriteria.reOpenDate && localFilterCriteria.reOpenDate !== '') {
      const tempData = [];
      for (let i = 0; i < ticketReOpenDateSubOrderNoMapping.length; i++) {
        const element = ticketReOpenDateSubOrderNoMapping[i];
        if (element.reOpenDate === localFilterCriteria.reOpenDate) {
          const subOrderIndex = actualData.findIndex((x) => x.sub_order_no === element.subOrderNo);
          if (subOrderIndex > -1) {
            tempData.push(actualData[subOrderIndex]);
          }
        }
      }
      actualData = tempData;
    }
    if (localFilterCriteria.ticketDate && localFilterCriteria.ticketDate !== '') {
      const tempData = [];
      for (let i = 0; i < ticketDateSubOrderNoMapping.length; i++) {
        const element = ticketDateSubOrderNoMapping[i];
        if (element.orderDate === localFilterCriteria.ticketDate) {
          const subOrderIndex = actualData.findIndex((x) => x.sub_order_no === element.subOrderNo);
          if (subOrderIndex > -1) {
            tempData.push(actualData[subOrderIndex]);
          }
        }
      }
      actualData = tempData;
    }

    if (isEmpty(actualData)) {
      setNodata('Data not found.');
    }
    if (isEmpty(actualData) && isEmpty(tableActualData)) {
      setNodata('Please Select An Options To See Orders Analysis Details.');
    }
    setReportedClaimIssueOrders(actualData);
  }, [localFilterCriteria]);

  const onChangeDateManifest = (e, name, dateString) => {
    setLocalFilterCriteria({
      ...localFilterCriteria,
      [name]: dateString,
    });
  };

  const dateFormat = 'YYYY-MM-DD';

 const SyncTicketStatus = async (chunk) => {

   for (const resData in chunk) {
     const subAccountIds = chunk[resData].value;
     const tpEmailIds = chunk[resData].Email;
     const body =  {
       "username": localUser,
       "subAccountId": subAccountIds,
       "tpEmailId": tpEmailIds,
       "acType": "meesho",
       "actionType": "getOrdersForTicketStatusUpdate"
     };

     const response = await CreatePostAPICall(enqueueSnackbar, V2Management, body,);
     if (response) {
       enqueueSnackbar(response.data.body);
     }
   }


  }
  function chunkArray(array, chunkSize) {
    const chunks = [];

    for (let j = 0; j < array.length; j += chunkSize) {
      chunks.push(array.slice(j, j + chunkSize));
    }
    return chunks;
  }

  return (
    <>

      {isAdmin &&(<Card sx={{mb: "2rem"}}>
        <Grid sx={{paddingBottom: 2}} container spacing={2} padding={"1rem"}>

          <Grid item xs={12} sm={12} md={6}>
            <Typography variant={"h5"}>
              Sync Ticket Status
            </Typography>
            <div style={{margin: "1rem"}}></div>
            <AccountSelector allData={userData} onChangeHandler={handleSelectedAccount2}/>

          </Grid>
          <LoadingButton
              variant="contained" style={{height: "3rem", marginTop: "4rem", marginLeft: "2rem"}} onClick={(e) => {

            const chunkedArray = chunkArray(selectedAccountsData2, 1);
            chunkedArray.forEach(chunk => {
              console.log(chunk)
              SyncTicketStatus(chunk);
            });
          }}
          >
            Sync Status
          </LoadingButton>
        </Grid>
      </Card>)}
      <Grid sx={{ paddingBottom: 5 }} container spacing={3}>
        <Grid item xs={12} sm={12} md={6}>
          <AccountSelector allData={userData} onChangeHandler={handleSelectedAccount} />
        </Grid>
      </Grid>

      {actualReportedClaimIssueOrders.length !== 0 ? (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={3} md={3}>
              <FormControl fullWidth>
                <Autocomplete
                  limitTags={2}
                  onChange={(e, value) => onLocalFilterTextChange(false, 'ticket_id', value)}
                  id="multiple-limit-tags"
                  options={allTicketIdsArrayLocal}
                  value={localFilterCriteria.ticket_id ? localFilterCriteria.ticket_id : ''}
                  renderInput={(params) => <TextField {...params} label={'Select Ticket Id'} />}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={3} md={3}>
              <FormControl fullWidth>
                <Autocomplete
                  limitTags={2}
                  onChange={(e, value) => onLocalFilterTextChange(false, 'colorTag', value?.tagColorName)}
                  id="multiple-limit-tags"
                  options={tagColorSubOrderNoMapping.map((option) => option)}
                  getOptionLabel={(option) => option.tagColorName}
                  defaultValue={localFilterCriteria?.colorTag ? localFilterCriteria?.colorTag : null}
                  renderInput={(params) => <TextField {...params} label={'Select Color Tag'} />}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={3} md={3}>
              <div style={{ width: '100%', marginBottom: '10px' }}>
                <DatePicker
                  placeholder="Select Ticket Created Date"
                  style={{ width: '100%', paddingTop: '9.5px', paddingBottom: '9.5px' }}
                  className={'createDateRangePicker'}
                  pupClassName={'createDateRangePicker'}
                  {...(!isLight && {
                    className: 'date-picker-dark',
                  })}
                  format={dateFormat}
                  onChange={(e, value) => onChangeDateManifest(e, 'reported_date', value)}
                />
              </div>
            </Grid>

            <Grid item xs={12} sm={3} md={3}>
              <div style={{ width: '100%', marginBottom: '10px' }}>
                <DatePicker
                  placeholder="Select Ticket Re-Open Date"
                  style={{ width: '100%', paddingTop: '9.5px', paddingBottom: '9.5px' }}
                  className={'createDateRangePicker'}
                  pupClassName={'createDateRangePicker'}
                  {...(!isLight && {
                    className: 'date-picker-dark',
                  })}
                  format={dateFormat}
                  onChange={(e, value) => onChangeDateManifest(e, 'reOpenDate', value)}
                />
              </div>
            </Grid>
          </Grid>

          <br />

          <Grid container spacing={3}>
            <Grid item xs={12} sm={3} md={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={'pod_reported'}
                    checked={localFilterCriteria.pod_reported}
                    onChange={(e) => onLocalFilterChkChange(e, 'pod_reported')}
                  />
                }
                label="POD Reported"
              />
            </Grid>
            <Grid item xs={12} sm={3} md={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={'pending_reported'}
                    checked={localFilterCriteria.pending_reported}
                    onChange={(e) => onLocalFilterChkChange(e, 'pending_reported')}
                  />
                }
                label="Pending Reported"
              />
            </Grid>
            <Grid item xs={12} sm={3} md={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={'received_reported'}
                    checked={localFilterCriteria.received_reported}
                    onChange={(e) => onLocalFilterChkChange(e, 'received_reported')}
                  />
                }
                label="Received Reported"
              />
            </Grid>
            <Grid item xs={12} sm={3} md={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={'payment_reported'}
                    checked={localFilterCriteria.payment_reported}
                    onChange={(e) => onLocalFilterChkChange(e, 'payment_reported')}
                  />
                }
                label="Payment Reported"
              />
            </Grid>
            <Grid item xs={12} sm={3} md={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={'can_reopen_filter'}
                    checked={localFilterCriteria.can_reopen_filter}
                    onChange={(e) => onLocalFilterChkChange(e, 'can_reopen_filter')}
                  />
                }
                label="Can Re-Open?"
              />
            </Grid>
          </Grid>
        </>
      ) : null}

      {reportedClaimIssueOrders.length !== 0 ? (
        <>
          <Grid sx={{ p: 1, paddingBottom: '20px', paddingTop: '50px' }} container spacing={4}>
            <div
              style={{
                width: '100%',
                textAlign: 'end',
              }}
            >
              <Button variant="contained" sx={{ marginRight: 1.5 }} onClick={handleTagModelOpen}>
                Create Tags
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  exportToCsvMeeshoData();
                }}
              >
                Export Excel
              </Button>
            </div>
          </Grid>
          <DataGridTable
            columns={columns}
            data={reportedClaimIssueOrders}
            checkbox
            button1={'Mark AS All Resolved'}
            funReported={(value, table) => markAsResolved(value, table)}
            isLoading={isOrderReportTableLoading}
            renderDetailPanel={expandRowComponent}
          />
        </>
      ) : (
        <div>
          <Divider sx={{ pt: 2 }} />
          <br />
          {isOrderReportTableLoading === true ? (
            <div style={{ width: '100%', textAlign: 'center', padding: '100px' }}>
              <CircularProgress sx={{ color: SystemColor.originColor }} size={24} color="inherit" />
            </div>
          ) : (
            <NoDataFound message={nodata} />
          )}
        </div>
      )}
      {/*  Tag Color Selection Modal Popup */}
      <BootstrapDialog
        onClose={handleTagModelClose}
        aria-labelledby="customized-dialog-title"
        open={openTagModal}
        maxWidth="lg"
        sx={{ '& .MuiDialog-paper': { width: '100%', maxHeight: 1000 } }}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleTagModelClose}>
          Add New Tags
        </BootstrapDialogTitle>
        <Divider orientation="horizontal" flexItem />
        <DialogContent style={{ display: 'flex' }}>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <div>
                <h3>Created Tags List</h3>
                <br />
                <DataGridTable columns={tagColumn} data={tagsData} />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <h3>Add New Tag</h3>
                <br />
                <TextField
                  id="outlined-basic"
                  label="Add Tag Name"
                  variant="outlined"
                  value={tagName}
                  onChange={(event) => {
                    setTagName(event.target.value);
                  }}
                />
                <br />
                <br />
                <SketchPicker width="200" color={color} onChangeComplete={handleChangeComplete} />
                <br />
                <Button
                  variant="contained"
                  onClick={() => {
                    onHandleTag('set');
                  }}
                >
                  Add
                </Button>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
      </BootstrapDialog>

      {/* Add Tag Model */}
      {tagLoader ? (
        <div style={{ width: '100%', textAlign: 'center', padding: '100px' }}>
          <CircularProgress sx={{ color: SystemColor.originColor }} size={24} color="inherit" />
        </div>
      ) : (
        <BootstrapDialog
          onClose={addTagModalClose}
          aria-labelledby="customized-dialog-title"
          open={addTagModal}
          maxWidth="sm"
          sx={{ '& .MuiDialog-paper': { width: '100%', maxHeight: 620 } }}
        >
          <BootstrapDialogTitle id="customized-dialog-title" onClose={addTagModalClose}>
            Add Tag
          </BootstrapDialogTitle>
          <Divider orientation="horizontal" flexItem />
          <DialogContent style={{ width: '100%' }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Select Tag</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                defaultValue={''}
                label="Select Tag"
                onChange={onSelectedTag}
              >
                {tagsData.map((tag) => (
                  <MenuItem key={`${tag.Color_Name}-${tag.Tag_Name}`} value={`${tag.Color_Name}-${tag.Tag_Name}`}>
                    {tag.Tag_Name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
        </BootstrapDialog>
      )}
    </>
  );
}
