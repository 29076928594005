// routes
import {Link as RouterLink} from 'react-router-dom';
// @mui
import {Box, Card, Container, Link, Stack, Typography} from '@mui/material';
import {styled} from '@mui/material/styles';

import useAuth from '../../hooks/useAuth';
import useResponsive from '../../hooks/useResponsive';
// components
import Image from '../../components/Image';
import Logo from '../../components/Logo';
import Page from '../../components/Page';
// sections
import loginside from '../../assets/images/buiss_login.png';
import urlConstants from '../../routes/urlConstants';
import LoginForm from './components/LoginForm';
import {isAdmin} from "../../utils/utilities";

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({theme}) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));

const HeaderStyle = styled('header')(({theme}) => ({
    top: 0,
    zIndex: 9,
    lineHeight: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    padding: theme.spacing(3),
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
        alignItems: 'flex-start',
        padding: theme.spacing(7, 5, 0, 7),
    },
}));

const SectionStyle = styled(Card)(({theme}) => ({
    width: '100%',
    maxWidth: 464,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({theme}) => ({
    maxWidth: 480,
    margin: 'auto',
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------
// console.log(process.env.REACT_APP_ENV==='DESKTOP')
export default function Login() {
    const {method} = useAuth();

    const smUp = useResponsive('up', 'sm');

    const mdUp = useResponsive('up', 'md');

    return (
        <Page title="Login">
            <RootStyle>
                <HeaderStyle>
                    <Logo/>

                    {isAdmin  && <>
                        {!smUp && (
                            <Typography variant="body2" align="center" sx={{mt: 3}}>
                                Don’t have an account?{' '}
                                <Link variant="subtitle2" component={RouterLink} to={urlConstants.REGISTER_PAGE}>
                                    Get started
                                </Link>
                            </Typography>
                        )}
                    </>}

                </HeaderStyle>

                {mdUp && (
                    <SectionStyle>
                        <Typography variant="h3" sx={{px: 5, mt: 10, mb: 5}}>
                            Hi, Welcome Back
                        </Typography>
                        <Image visibleByDefault disabledEffect alt="login" src={loginside}/>
                    </SectionStyle>
                )}

                <Container maxWidth="sm">
                    <ContentStyle>
                        <Stack direction="row" alignItems="center" sx={{mb: 5}}>
                            <Box sx={{flexGrow: 1}}>
                                <Typography variant="h4" gutterBottom>
                                    Sign in to BuissMaster OMS
                                </Typography>
                                <Typography sx={{color: 'text.secondary'}}>Enter your details below.</Typography>
                            </Box>
                        </Stack>

                        <LoginForm/>

                        {isAdmin  && <>
                            {!smUp && (
                                <Typography variant="body2" align="center" sx={{mt: 3}}>
                                    Don’t have an account?{' '}
                                    <Link variant="subtitle2" component={RouterLink} to={urlConstants.REGISTER_PAGE}>
                                        Get started
                                    </Link>
                                </Typography>
                            )}
                        </>}
                    </ContentStyle>
                </Container>
            </RootStyle>
        </Page>
    );
}
