import { format } from 'date-fns';

// ----------------------------------------------------------------------

export function fDate(date) {
  return format(new Date(date), 'dd MMMM yyyy');
}

export function DateFormat(date) {
  return format(new Date(date), 'dd/MM/yy');
}
