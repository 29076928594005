import React, { useState } from 'react';
import { CircularProgress } from '@mui/material'; // Replace with the correct import path for your Android12Switch component
import { CreatePostAPICall, MyLinkedAccount } from '../../../utils/apis';
import { Android12Switch } from '../../../utils/utilities';

// eslint-disable-next-line react/prop-types
const AccountStatusButton = ({ user, localUser, cell, getAccountData, enqueueSnackbar,platform }) => {
  const [accountStatus, setAccountStatus] = useState(cell.row.original?.accountStatus ?? 'ACTIVATE');
  const [loading, setLoading] = useState(false);

  const updateAccountStatus = async (body) => {
    try {
      setLoading(true);
      const response = await CreatePostAPICall(enqueueSnackbar, MyLinkedAccount, body, 'Account Status Changed');
      if (response) {
        await getAccountData();
        setLoading(false);
        setAccountStatus((prevStatus) => (prevStatus === 'ACTIVATE' ? 'DEACTIVATE' : 'ACTIVATE'));
      }
    } catch (error) {
      console.error('Error updating account status:', error);
      setLoading(false);
    }
  };

  const handleSwitchChange = async (isChecked) => {
    const body = {
      username: user?.email ? user.email : localUser,
      tpEmailId: cell.row.original?.Email,
      subAccountId: cell.row.original?.SupplierId,
      acType: platform,
      actionType: 'updateAccountStatus',
      accountStatus: isChecked.target.checked ? 'ACTIVATE' : 'DEACTIVATE',
    };
    await updateAccountStatus(body);
  };

  return (
    // eslint-disable-next-line react/no-unknown-property
    <div align={'center'}>
      {loading ? (
        <CircularProgress size={24} />
      ) : (
        <Android12Switch
          onChange={(isChecked) => handleSwitchChange(isChecked)}
          isTransferred={accountStatus}
          defaultChecked={accountStatus === 'ACTIVATE'}
        />
      )}
    </div>
  );
};

export default AccountStatusButton
