import PropTypes from 'prop-types';
import { AnimatePresence, m } from 'framer-motion';
import React, { useState, useEffect } from 'react';
import { alpha, styled } from '@mui/material/styles';
import { Backdrop, Typography, Stack, FormControlLabel, Radio } from '@mui/material';
import useSettings from '../../hooks/useSettings';
import cssStyles from '../../utils/cssStyles';
import { NAVBAR, defaultSettings } from '../../config';
import Iconify from '../Iconify';
import Scrollbar from '../Scrollbar';
import { IconButtonAnimate, varFade } from '../animate';
import ToggleButton from './ToggleButton';
import Help from './help';

const RootStyle = styled(m.div)(({ theme }) => ({
  ...cssStyles(theme).bgBlur({ color: theme.palette.background.paper, opacity: 0.92 }),
  top: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  position: 'fixed',
  overflow: 'hidden',
  width: NAVBAR.NEED_HELP_WIDTH,
  flexDirection: 'column',
  margin: theme.spacing(2),
  paddingBottom: theme.spacing(3),
  zIndex: theme.zIndex.drawer + 3,
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  boxShadow: `-24px 12px 32px -4px ${alpha(
    theme.palette.mode === 'light' ? theme.palette.grey[500] : theme.palette.common.black,
    0.16
  )}`,
}));

export default function Settings() {
  // const { enqueueSnackbar } = useSnackbar();
  // const { user } = useAuth();
  // const localUser = window.localStorage.getItem('userEmailId');
  const { themeMode, themeDirection, themeColorPresets, themeStretch, themeLayout } = useSettings();
  const [open, setOpen] = useState(false);
  const [desktopDownloadLink, setDesktopDownloadLink] = useState();
  const [mobileDownloadLink, setMobileDownloadLink] = useState();

  // const getDashboardDetails = async () => {
  //     const notificationResponse = await CreatePostAPICall(enqueueSnackbar, Home, { username: user?.email ? user.email : localUser, actionType:"getNotifications" });
  //     if (notificationResponse) {
  //         setDesktopDownloadLink(notificationResponse.data.body.latestDesktopDownloadLink)
  //         setMobileDownloadLink(notificationResponse.data.body.latestMobileDownloadLink)
  //     }
  // };

  // useEffect(() => {
  //     getDashboardDetails();
  // }, []);

  const notDefault =
    themeMode !== defaultSettings.themeMode ||
    themeDirection !== defaultSettings.themeDirection ||
    themeColorPresets !== defaultSettings.themeColorPresets ||
    themeLayout !== defaultSettings.themeLayout ||
    themeStretch !== defaultSettings.themeStretch;

  const varSidebar =
    themeDirection !== 'rtl'
      ? varFade({
          distance: NAVBAR.BASE_WIDTH,
          durationIn: 0.32,
          durationOut: 0.32,
        }).inRight
      : varFade({
          distance: NAVBAR.BASE_WIDTH,
          durationIn: 0.32,
          durationOut: 0.32,
        }).inLeft;

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [open]);

  const handleToggle = () => {
    setOpen((prev) => !prev);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Backdrop
        open={open}
        onClick={handleClose}
        sx={{ background: 'transparent', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      />

      {!open && <ToggleButton open={open} notDefault={notDefault} onToggle={handleToggle} />}

      <AnimatePresence>
        {open && (
          <>
            <RootStyle {...varSidebar}>
              <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ py: 2, pr: 1, pl: 2.5 }}>
                <Typography variant="subtitle1">Need help?</Typography>
                <div>
                  <IconButtonAnimate onClick={handleClose}>
                    <Iconify icon={'eva:close-fill'} width={20} height={20} />
                  </IconButtonAnimate>
                </div>
              </Stack>

              {/* <Divider sx={{ borderStyle: 'dashed' }} />
                <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ py: 2, pr: 1, pl: 2.5 }}>
                    <Typography variant="subtitle1">Application Download</Typography>
                </Stack>

                <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
                    <Box sx={{ flexGrow: 1 }}>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <Iconify icon="material-symbols:apk-install-outline" width={25} height={25} style={{color: '#7635DC',marginRight:"5px"}} />
                            <Typography variant="subtitle1" >
                                <a
                                    href={mobileDownloadLink}
                                    rel="noopener noreferrer" style={{textDecoration:"none",color: "#7635DC"}}>
                                    Click here! To Download Latest Mobile Application
                                </a>
                            </Typography>
                        </div>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
                    <Box sx={{ flexGrow: 1 }}>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <Iconify icon="ic:round-install-desktop" width={25} height={25} style={{color: '#7635DC',marginRight:"5px"}} />
                            <Typography variant="subtitle1" >
                                <a
                                    href={desktopDownloadLink}
                                    rel="noopener noreferrer" style={{textDecoration:"none",color: "#7635DC"}}>
                                    Click here! To Download Latest Desktop Application
                                </a>
                            </Typography>
                        </div>
                    </Box>
                </Box>

              <Divider sx={{ borderStyle: 'dashed' }} /> */}

              <Scrollbar sx={{ flexGrow: 1 }}>
                <Stack spacing={3} sx={{ p: 3 }}>
                  <Stack spacing={1.5}>
                    <Help />
                  </Stack>
                </Stack>
              </Scrollbar>
            </RootStyle>
          </>
        )}
      </AnimatePresence>
    </>
  );
}

BoxMask.propTypes = {
  value: PropTypes.string,
};

export function BoxMask({ value }) {
  return (
    <FormControlLabel
      label=""
      value={value}
      control={<Radio sx={{ display: 'none' }} />}
      sx={{
        m: 0,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        position: 'absolute',
      }}
    />
  );
}
