/* eslint-disable */
import { capitalCase } from 'change-case';
import {
  Autocomplete,
  Box, Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import moment from 'moment/moment';
import useAuth from '../../../hooks/useAuth';
import {CreatePostAPICall, SnapdealSaveExcelData, SyncOrders} from '../../../utils/apis';
import {
  CapitalizeFirstLetter,
  ExcelDateToJSDate,
  getAccountsData,
  getAccountsDataByType, GetTodayDateTime,
  getUserAccountDetails, isAdmin,
  ReadExcelFile,
  setAccountSelectorData,
  SystemColor, uploadSyncFileS3,
} from '../../../utils/utilities';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import useSettings from '../../../hooks/useSettings';
import useTabs from '../../../hooks/useTabs';
import Iconify from '../../../components/Iconify';
import { UploadMultiFile } from '../../../components/upload';
import AccountSelector from '../../../components/Common/AccountSelector';
import { SnapdealValidateUploadedFile } from '../excelValidations/SnapdealValidation';
import {DatePicker} from "antd";
import {useTheme} from "@mui/material/styles";
const XLSX = require('xlsx');

export default function SnapdealSyncOrder() {
  const platForm = 'snapdeal';
  const { themeStretch } = useSettings();
  const { user } = useAuth();
  const { RangePicker } = DatePicker;
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';
  const { enqueueSnackbar } = useSnackbar();

  const localUser = window.localStorage.getItem('userEmailId');
  const { currentUploadTab, onChangeUploadTab } = useTabs('Order');
  const [files, setFiles] = useState([]);
  const [fileType, setFileType] = useState('');
  const [dataUploadLoader, setDataUploadLoader] = useState(false);
  // Below 5 Stats Is For Account Selector
  const [allAccountsData, setAllAccountsData] = useState([]);
  const [selectedAccountsWithOutAllOptions, setSelectedAccountsWithOutAllOptions] = useState([]);
  const [supplierId, setSupplierId] = useState([]);
  const [tpEmail, setTpEmail] = useState([]);
  const [companyName, setCompanyName] = useState([]);
  const [snapdealAccountListOptions, setSnapdealAccountListOptions] = useState([]);
  const [selectedAccountForExcelUpload, setSelectedAccountForExcelUpload] = useState(null);

  const [snapDealDateToBeFetched, setSnapDealDateToBeFetched] = useState({});
  const [isSnapDealReadyToShipLoading, SetIsSnapDealReadyToShipLoading] = useState(false);
  const [isSnapDealReturnLoading, SetIsSnapDealReturnLoading] = useState(false);
  const [isSnapDealCancelLoading, SetIsSnapDealCancelLoading] = useState(false);
  const [isSnapDealPayPaidLoading, SetSnapDealPayPaidLoading] = useState(false);
  const [isSnapDealPayOutLoading, SetSnapDealPayOutLoading] = useState(false);
  const [isSnapDealInventoryLoading, SetIsSnapDealInventoryLoading] = useState(false);
  const [isSnapDealShippedLoading, SetIsSnapDealShippedLoading] = useState(false);
  const [isSnapDealBagLinkLoading, SetIsSnapDealBagLinkLoading] = useState(false);

  // for getting account data from localstorage
  useEffect(async () => {
    getAccountsDataByType(platForm).then((filteredData) => {
      setAllAccountsData(filteredData);
    });
    // from here starts account selector data for Excel upload selector
    const accountData = await getAccountsData();
    const objAcSnapdeal = [];
    accountData.forEach((item) => {
      if (item.AcType === 'snapdeal') {
        objAcSnapdeal.push({
          value: item.Account.SupplierId,
          label: `${CapitalizeFirstLetter(item.AcType)} - ${item.Account.Email} - ${item.Account.Name}`,
          AcType: item.AcType,
          Email: item.Account.Email,
          Name: item.Account.Name,
        });
      }
    });
    setSnapdealAccountListOptions(objAcSnapdeal);
  }, []);

  // handle of autocomplete for platform wise showing account list
  const handleSelectedAccount = async (event, value) => {
    const response = await setAccountSelectorData(value, allAccountsData);
    setSelectedAccountsWithOutAllOptions(response[1]);
    if (event.length !== 0) {
      const selectedAccountData = await getUserAccountDetails(response[1]);
      setSupplierId(selectedAccountData.supplierIds);
      setTpEmail(selectedAccountData.tpEmailids);
      setCompanyName(selectedAccountData.cName);
    }
  };

  const ExcelDateToJSDateOnlyForSnapdeal = (date) => {
    if (date?.includes(' ')) {
      const newDate = date.split(' ');
      if (newDate[1].includes('-')) {
        return moment(newDate[1], 'DD-MM-YYYY').format('YYYY-MM-DD');
      } else if (newDate[1].includes('/')) {
        return moment(newDate[1], 'DD/MM/YYYY').format('YYYY-MM-DD');
      }
    } else {
      const newDate = date.split('/');
      if (newDate[2].length === 2) {
        return moment(date, 'DD/MM/YY').format('YYYY-MM-DD');
      }
    }
    return moment(date).format('YYYY-MM-DD');
  };

  // Excel Upload Container for Snapdeal,
  const OrderUploadContainer = () =>
    dataUploadLoader ? (
      <div style={{ width: '100%', textAlign: 'center', padding: '100px' }}>
        <CircularProgress sx={{ color: SystemColor.originColor }} size={24} color="inherit" />
        <br />
        {fileType === 'Order'
          ? 'Please Wait While Uploading RTS Order Data...'
          : 'Please Wait While Uploading Shipped Order Data...'}
      </div>
    ) : (
      <>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <UploadMultiFile
              multiple
              acceptTypes={'.csv,.xls,.xlsx'}
              files={fileType === 'Order' ? files : []}
              title={'Drop Or Select RTS Order Excel'}
              onDrop={(e) => {
                handleDropMultiFile(e, 'Order');
              }}
              onRemove={handleRemoveFile}
              onRemoveAll={handleRemoveAllFiles}
              onUpload={() => {
                handleOnUpload('Order', files);
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <UploadMultiFile
              multiple
              acceptTypes={'.xls, .csv'}
              files={fileType === 'shippedOrder' ? files : []}
              title={'Drop Or Select Shipped Order Excel'}
              onDrop={(e) => {
                handleDropMultiFile(e, 'shippedOrder');
              }}
              onRemove={handleRemoveFile}
              onRemoveAll={handleRemoveAllFiles}
              onUpload={() => {
                handleOnUpload('shippedOrder', files);
              }}
            />
          </Grid>
        </Grid>
      </>
    );

  // return upload container
  const ReturnUploadContainer = () =>
    dataUploadLoader ? (
      <>
        <div style={{ width: '100%', textAlign: 'center', padding: '100px' }}>
          <CircularProgress sx={{ color: SystemColor.originColor }} size={24} color="inherit" />
          <br />
          {fileType === 'RTNIn'
            ? 'Please Wait While Uploading Return In-Transit Data...'
            : 'Please Wait While Uploading Return Delivered Data...'}
        </div>
      </>
    ) : (
      <>
        <Typography variant="h6">Courier Return (RTO)</Typography>
        <Divider sx={{ mb: 2 }} />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <UploadMultiFile
              multiple
              acceptTypes={'.xlsx, .xls, .csv'}
              files={fileType === 'RTNIn' ? files : []}
              title={'Drop Or Select Return In-Transit Excel'}
              onDrop={(e) => {
                handleDropMultiFile(e, 'RTNIn');
              }}
              onRemove={handleRemoveFile}
              onRemoveAll={handleRemoveAllFiles}
              onUpload={() => {
                handleOnUpload('RTNIn', files);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <UploadMultiFile
              multiple
              acceptTypes={'.xlsx, .xls, .csv'}
              files={fileType === 'RTNDL' ? files : []}
              title={'Drop Or Select Return Delivered Excel'}
              onDrop={(e) => {
                handleDropMultiFile(e, 'RTNDL');
              }}
              onRemove={handleRemoveFile}
              onRemoveAll={handleRemoveAllFiles}
              onUpload={() => {
                handleOnUpload('RTNDL', files);
              }}
            />
          </Grid>
        </Grid>
        <Typography variant="h6" sx={{ pt: 5 }}>
          Customer Return (RET)
        </Typography>
        <Divider sx={{ mb: 2 }} />
        <Grid container spacing={3} sx={{ pb: 2 }}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Autocomplete
              id="snapdeal-excel-upload-selector"
              onChange={(event, value) => {
                setSelectedAccountForExcelUpload(value);
              }}
              value={selectedAccountForExcelUpload}
              options={snapdealAccountListOptions.map((option) => option)}
              renderInput={(params) => <TextField {...params} label="Select Snapdeal Account*" />}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <UploadMultiFile
              multiple
              acceptTypes={'.xlsx, .xls, .csv'}
              files={fileType === 'RTNINRET' ? files : []}
              title={'Drop Or Select Return In-Transit Excel'}
              onDrop={(e) => {
                handleDropMultiFile(e, 'RTNINRET');
              }}
              onRemove={handleRemoveFile}
              onRemoveAll={handleRemoveAllFiles}
              onUpload={() => {
                handleOnUpload('RTNINRET', files);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <UploadMultiFile
              multiple
              acceptTypes={'.xlsx, .xls, .csv'}
              files={fileType === 'RTNDLRET' ? files : []}
              title={'Drop Or Select Return Delivered Excel'}
              onDrop={(e) => {
                handleDropMultiFile(e, 'RTNDLRET');
              }}
              onRemove={handleRemoveFile}
              onRemoveAll={handleRemoveAllFiles}
              onUpload={() => {
                handleOnUpload('RTNDLRET', files);
              }}
            />
          </Grid>
        </Grid>
      </>
    );

  // payment upload container
  const PaymentUploadContainer = () =>
    dataUploadLoader ? (
      <div style={{ width: '100%', textAlign: 'center', padding: '100px' }}>
        <CircularProgress sx={{ color: SystemColor.originColor }} size={24} color="inherit" />
        <br />
        Please Wait While Uploading Payment Data...
      </div>
    ) : (
      <>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <UploadMultiFile
              multiple
              acceptTypes={'.xlsx, .xls, .csv'}
              files={fileType === 'Payment' ? files : []}
              title={'Drop Or Select Payment Excel'}
              onDrop={(e) => {
                handleDropMultiFile(e, 'Payment');
              }}
              onRemove={handleRemoveFile}
              onRemoveAll={handleRemoveAllFiles}
              onUpload={() => {
                handleOnUpload('Payment', files);
              }}
            />
          </Grid>
        </Grid>
      </>
    );

  // inventory upload container
  const InventoryUploadContainer = () =>
    dataUploadLoader ? (
      <div style={{ width: '100%', textAlign: 'center', padding: '100px' }}>
        <CircularProgress sx={{ color: SystemColor.originColor }} size={24} color="inherit" />
        <br />
        Please Wait While Uploading Inventory Data...
      </div>
    ) : (
      <>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <UploadMultiFile
              multiple
              acceptTypes={'.xlsx, .xls, .csv'}
              files={fileType === 'Inventory' ? files : []}
              title={'Drop Or Select Inventory Excel'}
              onDrop={(e) => {
                handleDropMultiFile(e, 'Inventory');
              }}
              onRemove={handleRemoveFile}
              onRemoveAll={handleRemoveAllFiles}
              onUpload={() => {
                handleOnUpload('Inventory', files);
              }}
            />
          </Grid>
        </Grid>
      </>
    );

  // Snapdeal Upload Tabs
  const uploadTabs = [
    {
      value: 'Order',
      icon: <Iconify icon={'tabler:truck-delivery'} width={25} height={25} />,
      component: <OrderUploadContainer />,
    },
    {
      value: 'Return',
      icon: <Iconify icon={'tabler:truck-return'} width={25} height={25} />,
      component: <ReturnUploadContainer />,
    },
    {
      value: 'Payment',
      icon: <Iconify icon={'mdi:recurring-payment'} width={25} height={25} />,
      component: <PaymentUploadContainer />,
    },
    {
      value: 'Inventory',
      icon: <Iconify icon={'vaadin:stock'} width={20} height={20} />,
      component: <InventoryUploadContainer />,
    },
  ];

  // handle drop file
  const handleDropMultiFile = useCallback(
    (acceptedFiles, fileType) => {
      const newFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      setFiles([...newFiles]);
      setFileType(fileType);
    },
    [files]
  );

  // Remove Files
  const handleRemoveFile = (inputFile) => {
    const filtered = files.filter((file) => file !== inputFile);
    setFiles(filtered);
  };

  // Remove Files
  const handleRemoveAllFiles = () => {
    setFiles([]);
  };

  // handle upload
  const handleOnUpload = (type, files) => {
    setDataUploadLoader(true);
    const file = files[0];
    const reader = new FileReader();
    if (file) {
      reader.onload = (e) => {
        uploadFileData(e, type, file, files);
      };
      reader.readAsArrayBuffer(file);
    } else {
      handleRemoveAllFiles();
    }
  };
  const handleSnapDealExportExcel = async (paymentData) => {
    var headerPaymentExcel = [
      "SubOrderNo",
      "Sku",
      "InvoiceNo",
      "FinalSettlementAmount",
      "PaymentDate",
      "Awb",
      "State"
    ]

    const pySheetData = [headerPaymentExcel, ...paymentData.map(obj => Object.values(obj))];
    const workbook = XLSX.utils.book_new();

    const worksheetPy = XLSX.utils.json_to_sheet(pySheetData);
    XLSX.utils.book_append_sheet(workbook, worksheetPy, "Payment");

    var wbout = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

    /* prepare data for POST */
    var blob = new Blob([new Uint8Array(wbout)], { type: "application/octet-stream" });

    const fileName = `ReCreatedFile_SnapDeal_Payment ${GetTodayDateTime()}.xlsx`;
    const file = new File([blob], fileName, { type: 'application/octet-stream' });
    return file;
  };

  // Upload Excel data start
  const uploadFileData = async (e, type, file, files) => {
    setDataUploadLoader(true);
    let fileSupId = null;
    allAccountsData.forEach((x) => {
      if (file.name.split('_')[0] === x.value) {
        fileSupId = x.value;
      }
    });
    const excelActualData = ReadExcelFile(e, 0);
    const dataForUpload = [];
    let dataForUploadPayment = [];
    let apiType;

    if (type === 'RTNINRET' || type === 'RTNDLRET') {

      const isValidated = SnapdealValidateUploadedFile(excelActualData[0], type);
      // console.log(isValidated);
      // console.log(excelActualData[0]);

      // console.log(type);

      if (!isValidated) {
        handleRemoveAllFiles();
        setDataUploadLoader(false);
        enqueueSnackbar('Selected file field(s) not valid.', { variant: 'error' });
        return null;
      }
      if (excelActualData[1][18] === 'IN TRANSIT' && type === 'RTNINRET') {
        apiType = 'excelRtnIntraRet';
      } else if (
        (excelActualData[1][18] === 'DELIVERED' && type === 'RTNDLRET') ||
        (excelActualData[1][18] === 'COMPLETED' && type === 'RTNDLRET')
      ) {
        apiType = 'excelRtnDeliRet';
      } else {
        handleRemoveAllFiles();
        setDataUploadLoader(false);
        enqueueSnackbar('Selected file field(s) not valid.', { variant: 'error' });
        return null;
      }
      if (selectedAccountForExcelUpload) {
        excelActualData.forEach((item, index) => {
          if (index >= 1) {
            dataForUpload.push([
              item[0],
              item[1],
              item[2],
              item[3],
              item[4] ? ExcelDateToJSDateOnlyForSnapdeal(item[4]) : 'NA',
              item[5] ? ExcelDateToJSDateOnlyForSnapdeal(item[5]) : 'NA',
              item[7],
              Number(item[8]),
              item[10] ? item[10] : 'NA',
            ]);
          }
        });
        if (dataForUpload.length !== 0) {
          uploadExcelFileData(
            selectedAccountForExcelUpload.Email,
            selectedAccountForExcelUpload.value,
            apiType,
            dataForUpload,
            file,
            type,
            files
          );
        } else {
          handleRemoveAllFiles();
          setDataUploadLoader(false);
          enqueueSnackbar('No Data In This File.', { variant: 'error' });
          return null;
        }
      } else {
        handleRemoveAllFiles();
        setDataUploadLoader(false);
        enqueueSnackbar('Please Select Account', { variant: 'error' });
        return null;
      }
      return;
    }

    if (type === 'Payment') {
      if (excelActualData[3][1] === undefined) {
        handleRemoveAllFiles();
        setDataUploadLoader(false);
        enqueueSnackbar('Invalid File Or Data In This File.', { variant: 'error' });
        return;
      } else {
        const PaymentSubAccountId = excelActualData[3][1];
        const isValidated = SnapdealValidateUploadedFile(excelActualData[2], type);

        const subOrderNoIndex = excelActualData[2].findIndex((x) => x === 'Transaction ID');
        const sellerSKUIndex = excelActualData[2].findIndex((x) => x === 'SKU');
        const invoiceNoIndex = excelActualData[2].findIndex((x) => x === 'Invoice Number');
        const finalSettlementAmountIndex = excelActualData[2].findIndex((x) => x === 'NetPayable');
        const paymentDateIndex = excelActualData[2].findIndex((x) => x === "Payment Date");
        const awbNoIndex = excelActualData[2].findIndex((x) => x === "AWB Number");
        const custCityIndex = excelActualData[2].findIndex((x) => x === "Customer City");

        var exploredPaymentData = {};

        if (isValidated) {
          excelActualData.forEach((item, index) => {
            if (index >= 3 && item[subOrderNoIndex]) {
              if (!exploredPaymentData[item[subOrderNoIndex]]){
                exploredPaymentData[item[subOrderNoIndex]] = {
                  SubOrderNo:item[subOrderNoIndex],
                  Sku:item[sellerSKUIndex] ? item[sellerSKUIndex] : "NA",
                  InvoiceNo:item[invoiceNoIndex] ? item[invoiceNoIndex] === "?" ? "NA" : item[invoiceNoIndex] : "NA",
                  finalSettlementAmount:parseFloat(item[finalSettlementAmountIndex]),
                  PaymentDate: ExcelDateToJSDate(item[paymentDateIndex]),
                  Awb:item[awbNoIndex] ? item[awbNoIndex] === "?" ? "NA" : item[awbNoIndex] : "NA",
                  State:item[custCityIndex] ? item[custCityIndex] : "OTHER"
                }
              }else {
                exploredPaymentData[item[subOrderNoIndex]]["finalSettlementAmount"] += parseFloat(item[finalSettlementAmountIndex])
              }
            }

          });

          dataForUploadPayment = Object.values(exploredPaymentData);

        } else {
          handleRemoveAllFiles();
          setDataUploadLoader(false);
          enqueueSnackbar('Selected file field(s) not valid.', { variant: 'error' });
          return;
        }

        let selectedAccountDetails = allAccountsData.find((x) => x.value === PaymentSubAccountId);

        if (dataForUpload.length !== 0 || PaymentSubAccountId !== 'undefined') {

          let file = await handleSnapDealExportExcel(dataForUploadPayment);
          enqueueSnackbar(`File Created Successfully`, { variant: 'success' });
          if (file){
            const data = await uploadSyncFileS3(file, user.email, selectedAccountDetails.Email
              , selectedAccountDetails.value
              , selectedAccountDetails.Name,
              "PaidPayment", file.name, "snapdeal", enqueueSnackbar)
            if (data) {
              handleRemoveAllFiles();
              setDataUploadLoader(false);
            }
          }else {
            return null
          }
        } else {
          handleRemoveAllFiles();
          setDataUploadLoader(false);
          enqueueSnackbar('Invalid File Or No Data.', { variant: 'error' });
        }
        return;
      }
    }

    if (type === 'Order') {
      apiType = 'excelRTSOrdersData';
      const isValidated = SnapdealValidateUploadedFile(excelActualData[0], type);
      if (isValidated) {
        excelActualData.forEach((item, index) => {
          if (index >= 1) {
            dataForUpload.push([
              item[0],
              item[1],
              item[2],
              item[3],
              item[4],
              item[5],
              item[6],
              Number(item[10]),
              item[16],
              item[17],
            ]);
          }
        });
      } else {
        handleRemoveAllFiles();
        setDataUploadLoader(false);
        enqueueSnackbar('Selected file field(s) not valid.', { variant: 'error' });
        return;
      }
    }

    if (type === 'shippedOrder') {
      apiType = 'excelShippedOrdersData';
      const isValidated = SnapdealValidateUploadedFile(excelActualData[0], type);

      if (isValidated) {
        excelActualData.forEach((item, index) => {
          if (index >= 1) {
            dataForUpload.push([
              item[0],
              item[2],
              item[3],
              item[4] ? ExcelDateToJSDateOnlyForSnapdeal(item[4]) : 'NA',
              item[6],
              item[7],
              item[8],
              item[11] ? ExcelDateToJSDateOnlyForSnapdeal(item[11]) : 'NA',
              item[15] ? ExcelDateToJSDateOnlyForSnapdeal(item[15]) : 'NA',
              item[19],
              item[22],
              item[23],
            ]);
          }
        });
      } else {
        handleRemoveAllFiles();
        setDataUploadLoader(false);
        enqueueSnackbar('Selected file field(s) not valid.', { variant: 'error' });
        return;
      }
    }

    if (type === 'RTNIn') {
      apiType = 'excelRtnIntraRto';
      const isValidated = SnapdealValidateUploadedFile(excelActualData[0], type);
      if (isValidated) {
        if (excelActualData[1][6] === '') {
          excelActualData.forEach((item, index) => {
            if (index >= 1) {
              dataForUpload.push([
                item[1],
                item[2],
                item[3],
                item[4],
                item[5] ? ExcelDateToJSDateOnlyForSnapdeal(item[5]) : 'NA',
                item[7],
                item[8],
                item[11] ? ExcelDateToJSDateOnlyForSnapdeal(item[11]) : 'NA',
                item[14],
              ]);
            }
          });
        } else {
          handleRemoveAllFiles();
          setDataUploadLoader(false);
          enqueueSnackbar('Selected file field(s) not valid.', { variant: 'error' });
          return;
        }
      } else {
        handleRemoveAllFiles();
        setDataUploadLoader(false);
        enqueueSnackbar('Selected file field(s) not valid.', { variant: 'error' });
        return;
      }
    }

    if (type === 'RTNDL') {
      apiType = 'excelRtnDeliRto';
      let isValidated = false;
      if (
        JSON.stringify(excelActualData[0]) ===
        JSON.stringify([
          'Name',
          'SUPC',
          'SKU',
          'Suborder ID',
          'Attribute',
          'Return Initiated On',
          'Return Delivered On',
          'Fulfilment Mode',
          'AWBNumber',
          'Selling Price',
          'Payment Mode',
          'Order Date',
          'Return Updated on',
          'Shipping Date',
          'SD Reference Number',
        ])
      ) {
        isValidated = SnapdealValidateUploadedFile(excelActualData[0], type);
        if (isValidated) {
          if (excelActualData[1][6] !== '') {
            excelActualData.forEach((item, index) => {
              if (index >= 1) {
                dataForUpload.push([
                  item[1],
                  item[2],
                  item[3],
                  item[4],
                  item[5] ? ExcelDateToJSDateOnlyForSnapdeal(item[5]) : 'NA',
                  item[6] ? ExcelDateToJSDateOnlyForSnapdeal(item[6]) : 'NA',
                  item[7],
                  item[8],
                  item[11] ? ExcelDateToJSDateOnlyForSnapdeal(item[11]) : 'NA',
                  item[14],
                ]);
              }
            });
          } else {
            handleRemoveAllFiles();
            setDataUploadLoader(false);
            enqueueSnackbar('Selected file field(s) not valid.', { variant: 'error' });
            return null;
          }
        } else {
          handleRemoveAllFiles();
          setDataUploadLoader(false);
          enqueueSnackbar('Selected file field(s) not valid.', { variant: 'error' });
          return null;
        }
      } else if (
        JSON.stringify(excelActualData[0]) ===
        JSON.stringify([
          'Name',
          'Attribute',
          'SUPC',
          'SKU',
          'Suborder ID',
          'Return Initiated On',
          'Return Delivered On',
          'Fulfilment Mode',
          'AWBNumber',
          'Selling Price',
          'Payment Mode',
          'Order Date',
          'Deducted Amount',
          'Due Payment Date',
          'Deducted Amount(Wallet)',
          'Due Payment Date(Wallet)',
          'Return Accepted on',
        ])
      ) {
        isValidated = SnapdealValidateUploadedFile(excelActualData[0], 'RTNDL2');
        if (isValidated) {
          excelActualData.forEach((item, index) => {
            if (index >= 1) {
              // In Below Data NA Send To BackEnd For Detect Length Only This Is Not Extrcated From Excel This NA Is Dummy Data
              dataForUpload.push([
                item[0],
                item[1],
                item[2],
                item[3],
                item[4],
                item[5] ? ExcelDateToJSDateOnlyForSnapdeal(item[5]) : 'NA',
                item[6] ? ExcelDateToJSDateOnlyForSnapdeal(item[6]) : 'NA',
                item[7],
                item[8],
                Number(item[9]),
                item[11] ? ExcelDateToJSDateOnlyForSnapdeal(item[11]) : 'NA',
                'NA',
              ]);
            }
          });
        } else {
          handleRemoveAllFiles();
          setDataUploadLoader(false);
          enqueueSnackbar('Selected file field(s) not valid.', { variant: 'error' });
          return null;
        }
      } else {
        isValidated = SnapdealValidateUploadedFile(excelActualData[0], 'RTNDL3');
        if (isValidated) {
          excelActualData.forEach((item, index) => {
            if (index >= 1) {
              dataForUpload.push([
                item[0],
                item[2],
                item[3],
                item[4] ? ExcelDateToJSDateOnlyForSnapdeal(item[4]) : 'NA',
                item[6],
                item[7],
                item[17] ? ExcelDateToJSDateOnlyForSnapdeal(item[17]) : 'NA',
                item[18] ? ExcelDateToJSDateOnlyForSnapdeal(item[18]) : 'NA',
                item[19],
                item[22],
                item[23],
              ]);
            }
          });
        } else {
          handleRemoveAllFiles();
          setDataUploadLoader(false);
          enqueueSnackbar('Selected file field(s) not valid.', { variant: 'error' });
          return null;
        }
      }
    }

    if (type === 'Inventory') {
      apiType = 'excelInventoryData';
      const isValidated = SnapdealValidateUploadedFile(excelActualData[0], type);
      if (isValidated) {
        excelActualData.forEach((item, index) => {
          if (index >= 1) {
            dataForUpload.push([item[0], item[1], item[3], Number(item[6])]);
          }
        });
      } else {
        handleRemoveAllFiles();
        setDataUploadLoader(false);
        enqueueSnackbar('Selected file field(s) not valid.', { variant: 'error' });
        return;
      }
    }

    const selectedSupIdUserData = allAccountsData.find((x) => x.value === fileSupId);
    if (selectedSupIdUserData) {
      if (dataForUpload.length !== 0) {
        uploadExcelFileData(selectedSupIdUserData.Email, fileSupId, apiType, dataForUpload, file, type, files);
      } else {
        handleRemoveAllFiles();
        setDataUploadLoader(false);
        enqueueSnackbar('No Data In This File.', { variant: 'error' });
        return null;
      }
    } else if (type !== 'Payment') {
      handleRemoveAllFiles();
      setDataUploadLoader(false);
      enqueueSnackbar('Invalid File Or Data.', { variant: 'error' });
      return null;
    }
  };

  const getdate = async (value, dateString) => {
    const snapdealDate = {
      start: dateString[0],
      end: dateString[1],
    };
    setSnapDealDateToBeFetched(snapdealDate);
  };

  // final api call to upload excel
  const uploadExcelFileData = async (
    tpEmailId,
    subAccountId,
    syncDataType,
    dataForUpload,
    file,
    fileType,
    files,
    token = null
  ) => {
    let items;
    if (fileType === 'Inventory') {
      items = dataForUpload.slice(0, 50);
      dataForUpload.splice(0, 50);
    } else {
      items = dataForUpload.slice(0, 100);
      dataForUpload.splice(0, 100);
    }
    setDataUploadLoader(true);
    const body = {
      username: user?.email ? user.email : localUser,
      tpEmailId,
      subAccountId,
      syncDataType,
      body: items,
    };
    if (token) body.token = token;
    const response = await CreatePostAPICall(enqueueSnackbar, SnapdealSaveExcelData, body);
    if (response) {
      if (dataForUpload.length > 0) {
        uploadExcelFileData(
          tpEmailId,
          subAccountId,
          syncDataType,
          dataForUpload,
          file,
          fileType,
          files,
          response.data.token
        );
      } else {
        const tempFiles = files.slice(1);
        if (tempFiles.length === 0) {
          enqueueSnackbar('File Saved Successfully');
          handleRemoveAllFiles();
          setDataUploadLoader(false);
        } else {
          handleOnUpload(fileType, tempFiles);
        }
      }
    }
  };

  //  api sync
  const fetchApiSnapDealOrder = async (SyncDataType) => {
    if (tpEmail.length === 0) {
      enqueueSnackbar('Please Select Account', { variant: 'error' });
      return;
    }
    if (SyncDataType === 'RTS') {
      SetIsSnapDealReadyToShipLoading(true);
    }
    if (SyncDataType === 'RTN') {
      SetIsSnapDealReturnLoading(true);
    }
    if (SyncDataType === 'CNL') {
      SetIsSnapDealCancelLoading(true);
    }
    if (SyncDataType === 'PAYMENTS') {
      SetSnapDealPayPaidLoading(true);
    }
    if (SyncDataType === 'allInventory') {
      SetIsSnapDealInventoryLoading(true);
    }
    if (SyncDataType === 'SHIPPED') {
      SetIsSnapDealShippedLoading(true);
    }

    if (SyncDataType === 'BAG_LINK') {
      SetIsSnapDealBagLinkLoading(true);
    }
    const body = {
      username: user?.email ? user.email : localUser,
      tpEmailIds: tpEmail,
      subAccountIds: supplierId,
      cNames: companyName,
      syncDataType: SyncDataType,
      acType: 'snapdeal',
    };
    if ((isAdmin && SyncDataType === 'RTN') || SyncDataType === 'SHIPPED' || SyncDataType === 'PAYMENTS') {
      body.start_date = snapDealDateToBeFetched.start;
      body.end_date = snapDealDateToBeFetched.end;
    }
    const response = await CreatePostAPICall(enqueueSnackbar, SyncOrders, body);
    if (response) {
      enqueueSnackbar(response.data.body.message);
      if (SyncDataType === 'RTS') {
        SetIsSnapDealReadyToShipLoading(false);
      }
      if (SyncDataType === 'RTN') {
        SetIsSnapDealReturnLoading(false);
      }
      if (SyncDataType === 'CNL') {
        SetIsSnapDealCancelLoading(false);
      }
      if (SyncDataType === 'PAYMENTS') {
        SetSnapDealPayPaidLoading(false);
      }
      if (SyncDataType === 'allInventory') {
        SetIsSnapDealInventoryLoading(false);
      }
      if (SyncDataType === 'SHIPPED') {
        SetIsSnapDealShippedLoading(false);
      }
      if (SyncDataType === 'BAG_LINK') {
        SetIsSnapDealBagLinkLoading(false);
      }
    }
  };
  
  return (
    <Container maxWidth={themeStretch ? false : 'xlg'}>
      <Grid sx={{ p: 2 }} container spacing={3}>
        <Grid item xs={12} md={3}>
          <Typography variant="h6">
            <div style={{ display: 'flex' }}>
              <Iconify
                  icon={'ant-design:cloud-sync-outlined'}
                  width={25}
                  height={25}
                  style={{ color: '#7635DC', marginRight: '5px' }}
              />{' '}
              Api Sync
            </div>
          </Typography>

          <Divider sx={{ mt: 1, mb: 3, width: '110px', backgroundColor: '#7635DC', height: '2px' }} />

          <div style={{ Width: '100%' }}>
            <AccountSelector allData={allAccountsData} onChangeHandler={handleSelectedAccount} />
          </div>
          <br />
          <Box
              sx={{
                display: 'grid',
                rowGap: 2,
              }}
          >
            {isAdmin && (
                <>
                  <RangePicker
                      format={'DD/MM/YY'}
                      onChange={(value, event) => getdate(value, event)}
                      className={'createDateRangePicker'}
                      pupClassName={'createDateRangePicker'}
                      {...(!isLight && {
                        className: 'date-picker-dark',
                      })}
                  />
                  <Button
                      variant="outlined"
                      startIcon={<Iconify icon={'ion:return-up-forward-outline'} size="medium" />}
                      onClick={() => {
                        fetchApiSnapDealOrder('SHIPPED');
                      }}
                      disabled={isSnapDealShippedLoading}
                  >
                    Sync Shipped Orders
                  </Button>
                </>
            )}
            <Button
                variant="outlined"
                startIcon={<Iconify icon={'ion:return-up-forward-outline'} size="medium" />}
                onClick={() => {
                  fetchApiSnapDealOrder('RTS');
                }}
                disabled={isSnapDealReadyToShipLoading}
            >
              Sync Ready To Ship Orders
            </Button>
            <Button
                variant="outlined"
                startIcon={<Iconify icon={'ion:return-down-back-outline'} size="medium" />}
                onClick={() => {
                  fetchApiSnapDealOrder('RTN');
                }}
                disabled={isSnapDealReturnLoading}
            >
              Sync Return Orders
            </Button>

            <Button
                variant="outlined"
                startIcon={<Iconify icon={'iconoir:cancel'} size="medium" />}
                onClick={() => {
                  fetchApiSnapDealOrder('CNL');
                }}
                disabled={isSnapDealCancelLoading}
            >
              Sync Cancel Orders
            </Button>

            <Button
                variant="outlined"
                startIcon={<Iconify icon={'mdi:payment-clock'} size="medium" />}
                onClick={() => {
                  fetchApiSnapDealOrder('PAYMENTS');
                }}
                disabled={isSnapDealPayOutLoading}
            >
              Sync Payment
            </Button>

          {/*  /!*<Button*!/*/}
          {/*  /!*    variant="outlined"*!/*/}
          {/*  /!*    startIcon={<Iconify icon={'material-symbols:link'} size="medium" />}*!/*/}
          {/*  /!*    onClick={() => {*!/*/}
          {/*  /!*      fetchApiSnapDealOrder('BAG_LINK');*!/*/}
          {/*  /!*    }}*!/*/}
          {/*  /!*    disabled={isSnapDealBagLinkLoading}*!/*/}
          {/*  /!*>*!/*/}
          {/*  /!*  Bag Link*!/*/}
          {/*  /!*</Button>*!/*/}

            {isAdmin && (
                <Button
                    variant="outlined"
                    startIcon={<Iconify icon={'ant-design:stock-outlined'} size="medium" />}
                    onClick={() => {
                      fetchApiSnapDealOrder('allInventory');
                    }}
                    disabled={isSnapDealInventoryLoading}
                >
                  Sync Inventory
                </Button>
            )}
          </Box>

          {/*<Divider*/}
          {/*    orientation="horizontal"*/}
          {/*    sx={{ marginBottom: '15px', marginTop: '15px', borderStyle: 'dashed', borderWidth: '0.5px' }}*/}
          {/*/>*/}

          {/*<Typography variant="h6">*/}
          {/*  <div style={{ display: 'flex' }}>*/}
          {/*    <Iconify*/}
          {/*        icon={'ant-design:cloud-sync-outlined'}*/}
          {/*        width={25}*/}
          {/*        height={25}*/}
          {/*        style={{ color: '#7635DC', marginRight: '5px' }}*/}
          {/*    />{' '}*/}
          {/*    Auto Sync*/}
          {/*  </div>*/}
          {/*</Typography>*/}

          {/*<Divider sx={{ mt: 1, mb: 3, width: '120px', backgroundColor: '#7635DC', height: '2px' }} />*/}

          {/*{isDesktopEnvironment ? (*/}
          {/*    <>*/}
          {/*      <div style={{ Width: '100%' }}>*/}
          {/*        <AccountSelector allData={allAccountsData} onChangeHandler={handleSelectedAccount} />*/}
          {/*        {accountError ? <span style={{ color: 'red' }}>{accountError}</span> : null}*/}
          {/*      </div>*/}
          {/*      <br />*/}
          {/*      <Box*/}
          {/*          sx={{*/}
          {/*            display: 'grid',*/}
          {/*            rowGap: 2,*/}
          {/*          }}*/}
          {/*      >*/}
          {/*        <input id="generate_pending_list_data" type="text" hidden />*/}
          {/*        <>*/}
          {/*          <Button*/}
          {/*              id={`${platForm}SyncPending`}*/}
          {/*              style={{ display: rerender ? '' : 'none' }}*/}
          {/*              variant="outlined"*/}
          {/*              startIcon={<Iconify icon={'ic:baseline-pending-actions'} size="medium" />}*/}
          {/*              onClick={() => {*/}
          {/*                validation();*/}
          {/*              }}*/}
          {/*          >*/}
          {/*            Generate Pending List*/}
          {/*          </Button>*/}

          {/*          /!*<Button*!/*/}
          {/*          /!*    id={`${platForm}PendExcelDownload`}*!/*/}
          {/*          /!*    style={{ display: rerender ? 'none' : '' }}*!/*/}
          {/*          /!*    onClick={handleDownloadExcel}*!/*/}
          {/*          /!*    variant="outlined"*!/*/}
          {/*          /!*    startIcon={<Iconify icon={'fluent:clipboard-checkmark-20-regular'} size="medium" />}*!/*/}
          {/*          /!*>*!/*/}
          {/*          /!*  Download Pending List Excel*!/*/}
          {/*          /!*</Button>*!/*/}

          {/*          {isDesktopEnvironment && isAdmin ? (*/}
          {/*              <RangePicker*/}
          {/*                  format={'DD-MM-YYYY'}*/}
          {/*                  className={'createDateRangePicker'}*/}
          {/*                  pupClassName={'createDateRangePicker'}*/}
          {/*                  onChange={(value, event) => getdate(value, event)}*/}
          {/*                  {...(!isLight && {*/}
          {/*                    className: 'date-picker-dark',*/}
          {/*                  })}*/}
          {/*              />*/}
          {/*          ) : null}*/}

          {/*          <Button*/}
          {/*              id={`${platForm}SyncTicketResponse`}*/}
          {/*              variant="outlined"*/}
          {/*              startIcon={<Iconify icon={'ion:ticket'} size="medium" />}*/}
          {/*          >*/}
          {/*            Sync Ticket Response*/}
          {/*          </Button>*/}

          {/*          <Button*/}
          {/*              id={`${platForm}BagLink`}*/}
          {/*              variant="outlined"*/}
          {/*              startIcon={<Iconify icon={'material-symbols:link'} size="medium" />}*/}
          {/*          >*/}
          {/*            Bag Link*/}
          {/*          </Button>*/}

          {/*          /!* TODO Uncomment For Payment  Buttons *!/*/}

          {/*          /!*{isDesktopEnvironment && isAdmin ? (*!/*/}
          {/*          /!*  <RangePicker*!/*/}
          {/*          /!*    format={'YYYY-MM-DD'}*!/*/}
          {/*          /!*    className={'createDateRangePicker'}*!/*/}
          {/*          /!*    pupClassName={'createDateRangePicker'}*!/*/}
          {/*          /!*    onChange={(value, event) => getdate(value, event)}*!/*/}
          {/*          /!*    {...(!isLight && {*!/*/}
          {/*          /!*      className: 'date-picker-dark',*!/*/}
          {/*          /!*    })}*!/*/}
          {/*          /!*  />*!/*/}
          {/*          /!*) : null}*!/*/}
          {/*          /!*<Button*!/*/}
          {/*          /!*  id={`${platForm}SyncPaidPayment`}*!/*/}
          {/*          /!*  variant="outlined"*!/*/}
          {/*          /!*  startIcon={<Iconify icon={'mdi:recurring-payment'} size="medium" />}*!/*/}
          {/*          /!*>*!/*/}
          {/*          /!*  Sync Paid Payment*!/*/}
          {/*          /!*</Button>*!/*/}

          {/*          /!*<Button*!/*/}
          {/*          /!*  id={`${platForm}SyncOutstandingPayment`}*!/*/}
          {/*          /!*  variant="outlined"*!/*/}
          {/*          /!*  startIcon={<Iconify icon={'mdi:payment-clock'} size="medium" />}*!/*/}
          {/*          /!*>*!/*/}
          {/*          /!*  Sync Outstanding Payment*!/*/}
          {/*          /!*</Button>*!/*/}
          {/*        </>*/}

          {/*        <>*/}
          {/*          <Button*/}
          {/*              id={`${platForm}SyncRts`}*/}
          {/*              variant="outlined"*/}
          {/*              startIcon={<Iconify icon={'ion:return-up-forward-outline'} size="medium" />}*/}
          {/*          >*/}
          {/*            Sync Ready To Ship Orders*/}
          {/*          </Button>*/}
          {/*          <>*/}
          {/*            {isDesktopEnvironment && isAdmin ? (*/}
          {/*                <RangePicker*/}
          {/*                    format={'YYYY-MM-DD'}*/}
          {/*                    className={'createDateRangePicker'}*/}
          {/*                    pupClassName={'createDateRangePicker'}*/}
          {/*                    onChange={(value, event) => getdate(value, event)}*/}
          {/*                    {...(!isLight && {*/}
          {/*                      className: 'date-picker-dark',*/}
          {/*                    })}*/}
          {/*                />*/}
          {/*            ) : null}*/}
          {/*          </>*/}

          {/*          <Button*/}
          {/*              id={`${platForm}SyncRtn`}*/}
          {/*              variant="outlined"*/}
          {/*              startIcon={<Iconify icon={'ion:return-down-back-outline'} size="medium" />}*/}
          {/*          >*/}
          {/*            Sync Return Orders*/}
          {/*          </Button>*/}

          {/*          <Button*/}
          {/*              id={`${platForm}SyncCnl`}*/}
          {/*              variant="outlined"*/}
          {/*              startIcon={<Iconify icon={'iconoir:cancel'} size="medium" />}*/}
          {/*          >*/}
          {/*            Sync Cancel Orders*/}
          {/*          </Button>*/}

          {/*          <Button*/}
          {/*              id={`${platForm}SyncInventory`}*/}
          {/*              variant="outlined"*/}
          {/*              startIcon={<Iconify icon={'ant-design:stock-outlined'} size="medium" />}*/}
          {/*          >*/}
          {/*            Sync Inventory*/}
          {/*          </Button>*/}
          {/*        </>*/}
          {/*      </Box>*/}
          {/*    </>*/}
          {/*) : (*/}
          {/*    <div>*/}
          {/*      <p style={{ color: '#FFCC00',marginBottom:'15px' }}> Please Use BuissMaster OMS Desktop App For Data Sync. </p>*/}
          {/*      <Button fullWidth variant="contained" href={"https://buissmaster-desktop-mac.s3.ap-south-1.amazonaws.com/autoUpdate/APP/BuissMaster+OMS+DesktopAPP+V3.exe"}>Download App</Button>*/}
          {/*    </div>*/}
          {/*)}*/}
        </Grid>

        <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            sx={{
              marginLeft: '26px',
              borderStyle: 'dashed',
              borderRightWidth: 'initial',
              marginBottom: '-10px',
              marginTop: '20px',
            }}
        />

        {/*<Grid item xs={12} md={8.5}>*/}
        {/*  <Typography variant="h6">*/}
        {/*    <div style={{ display: 'flex' }}>*/}
        {/*      <Iconify*/}
        {/*          icon={'ri:file-excel-2-line'}*/}
        {/*          width={25}*/}
        {/*          height={25}*/}
        {/*          style={{ color: 'green', marginRight: '5px' }}*/}
        {/*      />{' '}*/}
        {/*      Upload Excel*/}
        {/*    </div>*/}
        {/*  </Typography>*/}

        {/*  <Divider sx={{ mt: 1, mb: 3, width: '143px', backgroundColor: '#7635DC', height: '2px' }} />*/}
        {/*  <Grid container spacing={3}>*/}
        {/*    <Grid item xs={12} sm={6} md={5.5}>*/}
        {/*      <Tabs*/}
        {/*          allowScrollButtonsMobile*/}
        {/*          variant="scrollable"*/}
        {/*          scrollButtons="auto"*/}
        {/*          value={meeshoTab}*/}
        {/*          onChange={onChangeMeeshoTab}*/}
        {/*      >*/}
        {/*        {meeshoTabs.map((tab) => (*/}
        {/*            <Tab disableRipple key={tab.value} label={capitalCase(tab.value)} icon={tab.icon} value={tab.value} />*/}
        {/*        ))}*/}
        {/*      </Tabs>*/}
        {/*      <Box sx={{ mb: 2 }} />*/}
        {/*      {meeshoTabs.map((tab) => {*/}
        {/*        const isMatched = tab.value === meeshoTab;*/}
        {/*        return isMatched && <Box key={tab.value}>{tab.component}</Box>;*/}
        {/*      })}*/}
        {/*    </Grid>*/}

        {/*    <Grid item xs={12} sm={6} md={6} sx={{ marginLeft: 'auto' }}>*/}
        {/*      <Tabs*/}
        {/*          allowScrollButtonsMobile*/}
        {/*          variant="scrollable"*/}
        {/*          scrollButtons="auto"*/}
        {/*          value={currentUploadTab}*/}
        {/*          onChange={onChangeUploadTab}*/}
        {/*      >*/}
        {/*        {uploadTabs.map((tab) => (*/}
        {/*            <Tab disableRipple key={tab.value} label={capitalCase(tab.value)} icon={tab.icon} value={tab.value} />*/}
        {/*        ))}*/}
        {/*      </Tabs>*/}
        {/*      <Box sx={{ mb: 2 }} />*/}
        {/*      {uploadTabs.map((tab) => {*/}
        {/*        const isMatched = tab.value === currentUploadTab;*/}
        {/*        return isMatched && <Box key={tab.value}>{tab.component}</Box>;*/}
        {/*      })}*/}
        {/*    </Grid>*/}
        {/*  </Grid>*/}
        {/*</Grid>*/}

        {/* Pending list DialogBox */}
        {/*<BootstrapDialog*/}
        {/*    sx={{ '& .MuiDialog-paper': { width: '100%' } }}*/}
        {/*    maxWidth="md"*/}
        {/*    onClose={closePendingDownload}*/}
        {/*    aria-labelledby="customized-dialog-title"*/}
        {/*    open={pendingListModal}*/}
        {/*>*/}
        {/*  <BootstrapDialogTitle id="customized-dialog-title" onClose={closePendingDownload}>*/}
        {/*    Download Pending List*/}
        {/*  </BootstrapDialogTitle>*/}
        {/*  <Divider orientation="horizontal" flexItem />*/}
        {/*  <DialogContent>*/}
        {/*    <Grid sx={{ p: 2 }} container spacing={2}>*/}
        {/*      <Grid item xs={12} md={9}>*/}
        {/*        <FormControl fullWidth>*/}
        {/*          <Autocomplete*/}
        {/*              multiple*/}
        {/*              limitTags={2}*/}
        {/*              onChange={(e, value) => getSelectedData(value)}*/}
        {/*              id="multiple-limit-tags"*/}
        {/*              options={options}*/}
        {/*              // value={filterODRStatus}*/}
        {/*              renderInput={(params) => <TextField {...params} label={'Select Account'} />}*/}
        {/*          />*/}
        {/*        </FormControl>*/}
        {/*      </Grid>*/}
        {/*      <Grid item xs={12} md={3} style={{ textAlign: 'end' }}>*/}
        {/*        <Button variant="contained" onClick={getPendingList} style={{ padding: '15px 22px 15px 15px' }}>*/}
        {/*          Download Pending List*/}
        {/*        </Button>*/}
        {/*      </Grid>*/}
        {/*    </Grid>*/}
        {/*  </DialogContent>*/}
        {/*</BootstrapDialog>*/}

        <Grid item xs={12} md={8.5}>
          <Typography variant="h6">
            <div style={{ display: 'flex' }}>
              <Iconify
                  icon={'ri:file-excel-2-line'}
                  width={25}
                  height={25}
                  style={{ color: 'green', marginRight: '5px' }}
              />{' '}
              Upload Excel
            </div>
          </Typography>
          <Divider sx={{ mt: 1, mb: 3, width: '143px', backgroundColor: '#7635DC', height: '2px' }} />
          <Grid item xs={12} md={12} sm={12} lg={12}>
            <Tabs
                allowScrollButtonsMobile
                variant="scrollable"
                scrollButtons="auto"
                value={currentUploadTab}
                onChange={onChangeUploadTab}
            >
              {uploadTabs.map((tab) => (
                  <Tab disableRipple key={tab.value} label={capitalCase(tab.value)} icon={tab.icon} value={tab.value} />
              ))}
            </Tabs>
            <Box sx={{ mb: 2 }} />
            {uploadTabs.map((tab) => {
              const isMatched = tab.value === currentUploadTab;
              return isMatched && <Box key={tab.value}>{tab.component}</Box>;
            })}
          </Grid>
        </Grid>
      </Grid>

    </Container>
  );
}
