/* eslint-disable */
import {
  Autocomplete,
  Button,
  CircularProgress,
  Container,
  DialogActions,
  Divider,
  Grid,
  Link,
  TextField,
} from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import xlsx from 'json-as-xlsx';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import ModalImage from 'react-modal-image';
import AccountSelector from '../../../components/Common/AccountSelector';
import DataGridTable from '../../../components/Common/DataGridTable';
import NoDataFound from '../../../components/Common/NoDataFound';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import Iconify from '../../../components/Iconify';
import Page from '../../../components/Page';
import { UploadMultiFile } from '../../../components/upload';
import useAuth from '../../../hooks/useAuth';
import useSettings from '../../../hooks/useSettings';
import urlConstants from '../../../routes/urlConstants';
import { CreatePostAPICall, MyProduct } from '../../../utils/apis';
import {
  BootstrapDialog,
  BootstrapDialogTitle,
  SystemColor,
  getAccountsDataByType,
  isDesktopEnvironment,
  isValidUrl,
  setAccountSelectorData,
  ReadExcelFile,
} from '../../../utils/utilities';

export default function ExploreSkus() {
  const { themeStretch } = useSettings();
  const { user } = useAuth();
  const localUser = window.localStorage.getItem('userEmailId');
  const { enqueueSnackbar } = useSnackbar();
  const [userData, setUserData] = useState([]);
  const [selectedAccountsData, setSelectedAccountsData] = useState([]);
  const [productListData, setProductListData] = useState([]);
  const [data4Upload, setData4Upload] = useState([]);
  const [isOrderReportTableLoading, setIsOrderReportTableLoading] = useState(false);
  const [actualProductListData, setActualProductListData] = useState([]);
  const [localFilterData, setLocalFilterData] = useState();
  const [filterODRStatus, setFilterODRStatus] = useState([]);
  const [exportExcelData, setExportExcelData] = useState([]);
  const [openImportExcelModal, setOpenImportExcelModal] = useState(false);
  const [openSKUAlertModal, setOpenSKUAlertModal] = useState(false);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [catalogPopup, setCatalogPopup] = useState(false);
  const [catalogRow, setCatalogRow] = useState([]);
  const [allCost, setAllCost] = useState(0);
  const [catalogData, setCatalogData] = useState([]);
  const [selfCostMsg, setSelfCostMsg] = useState('');
  const [excelFileName, setExcelFileName] = useState('');
  const AllPRDData = [];
  const columns = [
    {
      accessorKey: 'category',
      header: 'Category',
    },
    {
      accessorKey: 'catelog_id',
      header: 'Catalog Id',
    },
    {
      accessorKey: 'style_id',
      header: 'Style Id',
    },
    {
      accessorKey: 'sku_name',
      header: 'Sku Name',
    },
    {
      accessorKey: 'sku_live_status',
      header: 'Sku Live Status',
    },
    {
      accessorKey: 'Variation',
      header: 'Variation',
    },
  ];

  const handleSelectedAccount = async (event, value) => {
    setActualProductListData([]);
    setProductListData([]);
    setLocalFilterData([]);
    setSelectedAccountsData([]);
    setExportExcelData([]);
    setExcelFileName('');
    const response = await setAccountSelectorData(value, userData);

    if (value?.length > 0) {
      let tempName = '';
      value.forEach((item, index) => {
        if (tempName.length < 200) {
          if (item.value === 'all') {
            tempName = tempName.concat(`${item.value}`);
          } else if (
            item.value === 'flipkart' ||
            item.value === 'glowroad' ||
            item.value === 'meesho' ||
            item.value === 'shopdeck' ||
            item.value === 'snapdeal' ||
            item.value === 'jiomart'
          ) {
            tempName = tempName.concat(`all-${item.value}`);
          } else {
            tempName = tempName.concat(`${item.AcType}-${item.cName}`);
          }

          if (value.length - 1 !== index) {
            tempName = tempName.concat(`,`);
          }
        }
      });
      setExcelFileName(tempName);
    }

    setSelectedAccountsData(response[1]);
  };

  const getFormattedData = (data) => {
    const productDetails = actualProductListData;
    const formattedData = [];
    const exportData = exportExcelData;
    data.forEach((datas) => {
      Object.entries(datas).forEach(([key, value]) => {
        const account = [];
        const platformData = {};
        platformData.platformName = key;
        Object.entries(value).forEach(([key, value]) => {
          const accountData = {};
          const supplier = [];
          accountData.accountId = key;
          Object.entries(value).forEach(([key, value]) => {
            const supplierTempData = {};
            const supplierData = [];
            supplierTempData.supplierId = key;
            Object.entries(value).forEach(([key, value]) => {
              const data = {};
              if (!value[0]) {
                return;
              }
              data.sku_name = value[0];
              data.self_cost = value[1];
              data.catelog_id = value[2];
              data.image_link = value[3];
              data.selling_price = value[4];
              data.category = value[5];
              data.sku_live_status = value[6];
              data.Variation = value[7];
              data.Sub_Quantity = value[8];
              data.part_key = value[9];
              data.sort_key = value[10];
              data.style_id = value[11];

              productDetails.push(data);
              supplierData.push(data);
            });
            supplierTempData.supplierData = supplierData;
            supplier.push(supplierTempData);
          });
          accountData.accountData = supplier;
          account.push(accountData);
        });
        platformData.accounData = account;
        exportData.push(platformData);

        platformData.platformData = account;
        formattedData.push(platformData);
      });
    });

    const storedData = JSON.parse(localStorage.getItem('cancel_image_blob_data'));
    const tempFilteredData = [];
    if (formattedData.length > 0) {
      formattedData.forEach((item) => {
        item.platformData.forEach((platformData) => {
          platformData.accountData.forEach((accountData) => {
            accountData.supplierData.forEach((supData) => {
              const tempData = {};
              tempData.platformName = item.platformName;
              tempData.isSelected = false;
              tempData.sku_name = supData.sku_name ? supData.sku_name : ' - ';
              tempData.self_cost = supData.self_cost ? supData.self_cost : '';
              tempData.catelog_id = supData.catelog_id ? supData.catelog_id : ' - ';
              tempData.image_link = supData.image_link ? supData.image_link : '';
              tempData.selling_price = supData.selling_price ? supData.selling_price : '';
              tempData.category = supData.category ? supData.category : ' - ';
              tempData.sku_live_status = supData.sku_live_status ? supData.sku_live_status : ' - ';
              tempData.Variation = supData.Variation ? supData.Variation : ' - ';
              tempData.Sub_Quantity = supData.Sub_Quantity ? supData.Sub_Quantity : 0;
              tempData.part_key = supData.part_key ? supData.part_key : ' - ';
              tempData.sort_key = supData.sort_key ? supData.sort_key : ' - ';
              tempData.style_id = supData.style_id ? supData.style_id : ' - ';
              tempData.supplierId = accountData.supplierId;
              tempData.accountId = platformData.accountId;
              if (storedData)
                tempData.cancel_image_url =
                  storedData.filter((x) => x.supplierId === accountData.supplierId).length > 0
                    ? storedData.filter((x) => x.supplierId === accountData.supplierId)[0].blobUrl
                    : null;
              else tempData.cancel_image_url = null;

              tempFilteredData.push(tempData);
            });
          });
        });
      });
    }
    setProductListData(tempFilteredData);
    setLocalFilterData(tempFilteredData);
    setIsOrderReportTableLoading(false);
    setExportExcelData(exportData);
    setActualProductListData(productDetails);
  };

  const getProductListData = async (item, lastKey) => {
    const body = {
      username: user?.email ? user.email : localUser,
      subAccountId: item.value,
      tpEmailId: item.Email,
      acType: item.AcType,
    };
    if (lastKey !== undefined) {
      body.last_key = lastKey;
    }
    const response = await CreatePostAPICall(enqueueSnackbar, MyProduct, body);
    if (response) {
      AllPRDData.push(response.data.body);
      if (response.data.last_key) {
        await getProductListData(item, response.data.last_key);
      }
    }
  };

  const getSelectAllMeeshoClaimIssueData = async (index) => {
    if (selectedAccountsData[index]) {
      setIsOrderReportTableLoading(true);
      await getProductListData(selectedAccountsData[index]);
      getSelectAllMeeshoClaimIssueData(index + 1);
    } else {
      const AllPRD = [];
      AllPRDData.forEach((item) => {
        AllPRD.push(item);
      });
      getFormattedData(AllPRD);
    }
  };

  // custom filter in product data
  const filterProductListData = async () => {
    setIsOrderReportTableLoading(true);
    await getSelectAllMeeshoClaimIssueData(0);
  };

  // get default user account and set data in selection
  useEffect(async () => {
    getAccountsDataByType('all', true).then((filteredData) => {
      setUserData(filteredData);
    });
  }, []);

  // product details api call after select account
  const getSelectAccountProductDetails = async () => {
    if (selectedAccountsData.length > 0) {
      filterProductListData();
    } else {
      enqueueSnackbar('Please select account or accounts', { variant: 'error' });
    }
  };

  // refresh data
  const refreshData = async () => {
    setProductListData([]);
    setProductListData(productListData);
  };

  // update self cost local manage data and show update data
  const updatedSelfCostList = async (updatedList) => {
    const tempArray = JSON.parse(JSON.stringify(productListData));
    const tempArrayActual = JSON.parse(JSON.stringify(actualProductListData));
    // setActualProductListData([]);
    // setProductListData([]);

    setTimeout(() => {
      updatedList.forEach((element) => {
        const productIndex = tempArray.findIndex((item) => item.sort_key === element.sort_key);
        if (productIndex > -1) {
          tempArray[productIndex].self_cost = element.selfCost;
          setProductListData(tempArray);
        }

        const actualProductIndex = tempArrayActual.findIndex((item) => item.sort_key === element.sort_key);

        if (actualProductIndex > -1) {
          tempArrayActual[actualProductIndex].self_cost = element.selfCost;
          setActualProductListData(tempArrayActual);
        }
      });
      setIsOrderReportTableLoading(false);
    }, 100);
  };

  // update self cost api call
  const saveUpdatedCost = async (updateObj) => {
    const body = {
      username: user?.email ? user.email : localUser,
      update: true,
      selfCostData: updateObj,
    };

    const response = await CreatePostAPICall(enqueueSnackbar, MyProduct, body);
    if (response) {
      const tempArray = [...productListData];
      const productIndex = tempArray.findIndex((item) => item.sort_key === updateObj[0].sort_key);
      if (productIndex > -1) {
        tempArray[productIndex].self_cost = updateObj[0].selfCost;
        setProductListData(tempArray);
      }

      enqueueSnackbar(response.data.body);
      updatedSelfCostList(updateObj);
    }
  };

  //  Expand Row Input Self Cost Edit Function
  const updateCost = (event, name, itemToUpdate) => {
    const value = event?.target?.value;
    if (event.key === 'Enter') {
      const updateObj = [
        {
          selfCost: value,
          part_key: itemToUpdate.original.part_key,
          sort_key: itemToUpdate.original.sort_key,
        },
      ];
      saveUpdatedCost(updateObj);
    }
  };

  //  Expand Row Input Sub Quantity Edit Function
  const updateSubQuantity = async (event, rowData) => {
    const value = event?.target?.value;
    const { sort_key, part_key } = rowData.original;
    if (event.key === 'Enter') {
      const body = {
        username: user?.email ? user.email : localUser,
        part_key,
        sort_key,
        subQt: Number(value),
      };

      const response = await CreatePostAPICall(enqueueSnackbar, MyProduct, body);
      if (response) {
        const tempArray = [...productListData];
        const productIndex = tempArray.findIndex((item) => item.sort_key === sort_key);
        if (productIndex > -1) {
          tempArray[productIndex].Sub_Quantity = Number(value);
          setProductListData(tempArray);
        }
        enqueueSnackbar(response.data.body);
      }
    }
  };

  // catalog pop up input field self cost data change
  const updateCostCatalog = (event, prdData = null) => {
    const tempData = [...catalogData];

    if (prdData !== null) {
      tempData.forEach((product) => {
        if (product.sku_name === prdData) {
          product.self_cost = parseInt(event.target.value, 10);
        }
      });
    } else {
      tempData.forEach((product) => {
        product.self_cost = parseInt(allCost, 10);
      });
    }

    setCatalogData(tempData);
  };

  // catalog pop up  POPUP Input Self Cost Edit Function
  const updateCatalogPopUp = async () => {
    setIsOrderReportTableLoading(true);
    const tempData = [];

    catalogData.forEach((item) => {
      tempData.push({
        selfCost: item.self_cost,
        part_key: item.part_key,
        sort_key: item.sort_key,
      });
    });
    await saveUpdatedCost(tempData);
    closeCatalogPopUp();
  };

  // catalog pop up table columns
  const catalogColumn = [
    {
      accessorKey: 'sku_name',
      header: 'Sku Name',
    },
    {
      accessorKey: 'image_link',
      header: 'Image',
      Cell: (cell) => (
        <div style={{ width: '100px' }}>

          {isValidUrl(cell.row.original.image_link) ? (
            <>
              {isDesktopEnvironment ? (
                <ModalImage
                  small={cell.row.original.image_link}
                  medium={cell.row.original.image_link}
                  large={cell.row.original.image_link}
                  hideZoom={'true'}
                />
              ) : (
                <ModalImage
                  small={cell.row.original.image_link}
                  medium={cell.row.original.image_link}
                  large={cell.row.original.image_link}
                  hideZoom={'true'}
                  hideDownload
                />
              )}
            </>
          ) : null}
        </div>
      ),
    },
    {
      accessorKey: 'category',
      header: 'Category',
    },
    {
      accessorKey: 'self_cost',
      header: 'Self Cost',
      Cell: (cell) => (
        <TextField
          id="inner-selfcost"
          size="small"
          variant="outlined"
          // value={cell.row.original?.selfCost === '' ? 0 : cell.row.original?.selfCost}
          value={parseFloat(cell.row.original?.self_cost || 0)}
          onChange={(event) => {
            updateCostCatalog(event, cell.row.original.sku_name);
          }}
        />
      ),
    },
  ];

  // open catalog pop up
  const openCatalogPopUp = (value) => {
    const tempData = [];
    productListData.forEach((item) => {
      if (item.catelog_id === value.catelog_id) {
        tempData.push(item);
      }
    });
    setCatalogData(tempData);
    setCatalogRow(value);
    setCatalogPopup(true);
  };

  // close catalog pop up
  const closeCatalogPopUp = () => {
    setCatalogData([]);
    setCatalogRow([]);
    setCatalogPopup(false);
  };

  // expand row show product details in table
  const expandRowComponent = (row) => (
    <Grid container spacing={3}>
      {row.original?.image_link ? (
        <Grid item xs={2} sm={2} md={2}>
          <div style={{ width: '100px' }}>
            {isValidUrl(row.original.image_link) ? (
              <>
                {isDesktopEnvironment ? (
                  <ModalImage
                    small={row.original.image_link}
                    medium={row.original.image_link}
                    large={row.original.image_link}
                    hideZoom={'true'}
                  />
                ) : (
                  <ModalImage
                    small={row.original.image_link}
                    medium={row.original.image_link}
                    large={row.original.image_link}
                    hideZoom={'true'}
                    hideDownload
                  />
                )}
              </>
            ) : null}
          </div>
        </Grid>
      ) : null}
      <Grid item sx={3} sm={3} md={3} lg={3}>
        <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
          <b style={{ marginRight: '2px' }}>Email :</b>
          <p style={{ marginLeft: '2px' }}>
            {row.original?.part_key.split('#')[1] === '' ? ' - ' : row.original?.part_key.split('#')[1]}
          </p>
        </div>
        <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
          <b style={{ marginRight: '2px' }}>Catalog Id :</b>{' '}
          <Link
            underline="none"
            onClick={() => (row.original?.catelog_id !== ' - ' ? openCatalogPopUp(row.original) : null)}
          >
            <p style={{ marginLeft: '2px' }}> {row.original?.catelog_id === '' ? ' - ' : row.original?.catelog_id}</p>
          </Link>
        </div>
        <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
          <b style={{ marginRight: '2px' }}>SKU Live Status :</b>{' '}
          <p style={{ marginLeft: '2px' }}>
            {' '}
            {row.original?.sku_live_status === '' ? ' - ' : row.original?.sku_live_status}
          </p>
        </div>
        <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
          <b style={{ marginRight: '2px' }}>Category :</b>
          <p style={{ marginLeft: '2px' }}>{row.original?.category === '' ? ' - ' : row.original?.category} </p>
        </div>
        <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
          <b style={{ marginRight: '2px' }}>Style Id :</b>
          <p style={{ marginLeft: '2px' }}>{row.original?.style_id === '' ? ' - ' : row.original?.style_id} </p>
        </div>
        <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
          <b style={{ marginRight: '2px' }}>Supplier Id:</b>
          <p style={{ marginLeft: '2px' }}>
            {row.original?.part_key.split('#')[2] === '' ? ' - ' : row.original?.part_key.split('#')[2]}
          </p>
        </div>
      </Grid>
      <Grid item sx={3} sm={3} md={3} lg={3}>
        <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
          <b style={{ marginRight: '2px' }}>Company :</b>
          <p style={{ marginLeft: '2px' }}>
            {row.original?.part_key.split('#')[0] === '' ? ' - ' : row.original?.part_key.split('#')[0]}{' '}
          </p>
        </div>
        <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
          <b style={{ marginRight: '2px' }}>SKU Name :</b>
          <p style={{ marginLeft: '2px' }}>{row.original?.sku_name === '' ? ' - ' : row.original?.sku_name}</p>
        </div>
        <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
          <b style={{ marginRight: '2px' }}>Selling Price :</b>
          <p style={{ marginLeft: '2px' }}>
            {row.original?.selling_price === '' ? ' - ' : row.original?.selling_price}{' '}
          </p>
        </div>
        <div style={{ marginTop: '2px', marginBottom: '2px', display: 'flex' }}>
          <b style={{ marginRight: '2px' }}>Variation :</b>
          <p style={{ marginLeft: '2px' }}>{row.original?.Variation === '' ? ' - ' : row.original?.Variation} </p>
        </div>
      </Grid>
      <Grid item sx={3} sm={3} md={3} lg={3}>
        <Grid container spacing={1}>
          <Grid item xs={5} sm={5} md={5} lg={5} alignSelf={'center'}>
            <b>Self Cost :</b>
          </Grid>
          <Grid item xs={7} sm={7} md={7} lg={7}>
            <TextField
              style={{ width: '100%' }}
              id="outlined-basic"
              size="small"
              variant="outlined"
              type="number"
              defaultValue={row.original?.self_cost === '' ? 0 : row.original?.self_cost}
              onKeyPress={(event) => updateCost(event, row.original.sku_name, row)}
              onChange={(event) => updateCost(event, row.original.sku_name, row)}
            />
          </Grid>
        </Grid>
        <br />
        <Grid container spacing={1}>
          <Grid item xs={5} sm={5} md={5} lg={5} alignSelf={'center'}>
            <b>Sub Qty : {'  '}</b>
          </Grid>
          <Grid item xs={7} sm={7} md={7} lg={7}>
            <TextField
              style={{ width: '100%' }}
              id="outlined-basic"
              size="small"
              variant="outlined"
              type="number"
              defaultValue={row.original?.Sub_Quantity === '' ? 0 : row.original?.Sub_Quantity}
              onKeyPress={(event) => updateSubQuantity(event, row)}
              onChange={(event) => updateSubQuantity(event, row)}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  // export excel file for local data store
  const handleExportExcel = () => {
    if (productListData.length !== 0) {
      // console.log(productListData);
      const data = [
        {
          sheet: 'filteredTableData',
          columns: [
            { label: 'Platform', value: 'platformName' },
            { label: 'Email', value: 'accountId' },
            { label: 'Category', value: 'category' },
            { label: 'Catelog', value: 'catelog_id' },
            { label: 'Style Id', value: 'style_id' },
            { label: 'SKU Name', value: 'sku_name' },
            { label: 'Self Cost', value: 'self_cost' },
            { label: 'Selling Price', value: 'selling_price' },
            { label: 'SKU Live Status', value: 'sku_live_status' },
            { label: 'Variation', value: 'Variation' },
            { label: 'Supplier Id', value: 'supplierId' },
            { label: 'part_key', value: 'part_key' },
            { label: 'sort_key', value: 'sort_key' },
          ],
          content: productListData,
        },
      ];

      const fileName = `${excelFileName}-products`;

      const settings = {
        fileName,
        extraLength: 6,
        writeOptions: {},
      };
      xlsx(data, settings);
    } else {
      enqueueSnackbar('Please Select Account', { variant: 'error' });
    }
  };

  // import excel file modal pop up open
  const handleExcelModalOpen = () => {
    setOpenImportExcelModal(!openImportExcelModal);
  };
  // import excel file modal pop up close
  const handleExcelModalClose = () => {
    setOpenImportExcelModal(false);
  };

  // import excel file give me alert when sku fields are empty
  const handleSKUAlertModelClose = async (action) => {
    setOpenSKUAlertModal(false);
    if (action) {
      uploadFileData();
    } else {
      setData4Upload([]);
      handleRemoveAllFiles();
      handleExcelModalClose();
      setLoading(false);
    }
  };

  // select multiple files and store
  const handleDropMultiFile = useCallback(
    (acceptedFiles) => {
      const newFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      );
      setFiles([...newFiles]);
    },
    [files],
  );

  // remove file by index
  const handleRemoveFile = (inputFile) => {
    const filtered = files.filter((file) => file !== inputFile);
    setFiles(filtered);
  };

  // remove all selected files
  const handleRemoveAllFiles = () => {
    setFiles([]);
  };

  // 0 Platform
  // 1 Email
  // 2 Supplier Id
  // 3 Category
  // 4 Catelog
  // 5 Style Id
  // 6 SKU Name
  // 7 Self Cost
  // 8 Selling Price
  // 9 SKU Live Status
  // 10 Variation
  // 11 part_key
  // 12 sort_key

  // validation of import excel file
  const validateImportExcel = (header) => {
    let isValid = false;
    try {
      if (
        header[0] === 'Platform' &&
        header[1] === 'Email' &&
        header[2] === 'Category' &&
        header[3] === 'Catelog' &&
        header[4] === 'Style Id' &&
        header[5] === 'SKU Name' &&
        header[6] === 'Self Cost' &&
        header[7] === 'Selling Price' &&
        header[8] === 'SKU Live Status' &&
        header[9] === 'Variation' &&
        header[10] === 'Supplier Id'
      ) {
        isValid = true;
      } else isValid = header[0] === 'Platform' &&
        header[1] === 'Email' &&
        header[2] === 'Supplier Id' &&
        header[3] === 'Category' &&
        header[4] === 'Catelog' &&
        header[5] === 'Style Id' &&
        header[6] === 'SKU Name' &&
        header[7] === 'Self Cost' &&
        header[8] === 'Selling Price' &&
        header[9] === 'SKU Live Status' &&
        header[10] === 'Variation';
      return isValid;
    } catch (e) {
      return false;
    }
  };

  // upload excel api call
  const uploadFileData = async () => {
    setLoading(true);
    const size = 200;
    const items = data4Upload.slice(0, size);
    data4Upload.splice(0, size);

    const body = {
      username: user?.email ? user.email : localUser,
      update: true,
      selfCostData: items,
    };
    const response = await CreatePostAPICall(enqueueSnackbar, MyProduct, body);
    if (response) {
      if (data4Upload.length > 0) {
        uploadFileData();
      } else {
        filterProductListData();
        setData4Upload([]);
        handleRemoveAllFiles();
        handleExcelModalClose();
        setLoading(false);
        enqueueSnackbar('Data Updated Successfully');
      }
      refreshData();
    }
  };

  // after api call show upload document
  const uploadDocument = async () => {
    const file = files[0];
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = async (e) => {
      const excelActualData = ReadExcelFile(e, 0);
      const isValidated = validateImportExcel(excelActualData[0]);
      if (isValidated) {
        const tempData = [];
        let partKey = '';
        let sortKey = '';
        let count = 0;

        excelActualData.forEach((item) => {
          // console.log("item",item)
          const index = excelActualData.indexOf(item);
          if (index >= 1) {
            const selectedFile = {};
            partKey = item[11] ? item[11] : partKey;
            sortKey = item[12] ? item[12] : sortKey;
            selectedFile.part_key = partKey;
            selectedFile.sort_key = sortKey;
            if (excelActualData[0][6] === 'Self Cost') {
              selectedFile.selfCost = item[6]?.length === 0 || item[6] === undefined ? 0 : item[6];
              if (item[6] === 0 || item[6]?.length === 0 || item[6] === undefined) {
                count += 1;
              }
            } else if (excelActualData[0][7] === 'Self Cost') {
              selectedFile.selfCost = item[7]?.length === 0 || item[7] === undefined ? 0 : item[7];
              if (item[7] === 0 || item[7]?.length === 0 || item[7] === undefined) {
                count += 1;
              }
            }

            tempData.push(selectedFile);
          }
        });
        setData4Upload(tempData);
        setSelfCostMsg(count);
        setOpenSKUAlertModal(!openSKUAlertModal);
      } else {
        enqueueSnackbar('Selected File Is Invalid', { variant: 'error' });
      }
    };
  };

  return (
    <Page title="ExploreSkus">
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <HeaderBreadcrumbs
          heading="My Products"
          links={[
            { name: 'Dashboard', href: urlConstants.HOME_PAGE },
            { name: 'My Products', href: urlConstants.MASTER_SKUS_PAGE },
            { name: 'Explore Skus' },
          ]}
        />

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <>
              <Grid sx={{ paddingBottom: 5 }} container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <AccountSelector allData={userData} onChangeHandler={handleSelectedAccount} />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} sx={{ marginBlock: 'auto' }}>
                  <Button
                    variant="outlined"
                    style={{ marginRight: '10px', marginBottom: '10px' }}
                    onClick={getSelectAccountProductDetails}
                    size="large"
                  >
                    Get Product Details
                  </Button>

                  <Button
                    variant="outlined"
                    size="large"
                    style={{ marginRight: '10px', marginBottom: '10px' }}
                    startIcon={<Iconify icon="vscode-icons:file-type-excel" width={25} height={25} />}
                    endIcon={<Iconify icon="material-symbols:download-rounded" width={25} height={25} />}
                    onClick={handleExportExcel}
                  >
                    Export Excel
                  </Button>

                  <Button
                    variant="outlined"
                    size="large"
                    style={{ marginRight: '10px', marginBottom: '10px' }}
                    startIcon={<Iconify icon="vscode-icons:file-type-excel" width={25} height={25} />}
                    endIcon={<Iconify icon="material-symbols:upload-rounded" width={25} height={25} />}
                    onClick={handleExcelModalOpen}
                  >
                    Import Excel
                  </Button>
                </Grid>
              </Grid>

              {productListData.length !== 0 ? (
                <>
                  <Grid sx={{ paddingBottom: 5 }} container spacing={3}>
                    <Grid item xs={6} sm={3} md={3}>
                      <FormControl fullWidth>
                        <Autocomplete
                          multiple
                          limitTags={2}
                          id="multiple-limit-tags"
                          options={[
                            {
                              value: 'self_cost',
                              label: 'Self Cost',
                            },
                          ]}
                          value={filterODRStatus}
                          isOptionEqualToValue={(option, value) => option.label === value.label}
                          renderInput={(params) => <TextField {...params} label={'Filter'} />}
                          onChange={async (e, newValue) => {
                            await new Promise((resolve) => setTimeout(resolve, 500));
                            setProductListData([]);
                            setIsOrderReportTableLoading(true);
                            setFilterODRStatus(newValue);
                            const selfCostCountValue = productListData.filter((item) => item.self_cost <= 0);
                            setProductListData(selfCostCountValue);
                            if (isEmpty(newValue)) {
                              setProductListData(localFilterData);
                            }
                            setIsOrderReportTableLoading(false);
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <DataGridTable
                    enableRowNumbers={true}
                    columns={columns}
                    data={productListData}
                    isLoading={isOrderReportTableLoading}
                    renderDetailPanel={expandRowComponent}
                  />
                </>
              ) : (
                <div>
                  <Divider />
                  <br />
                  {isOrderReportTableLoading === true ? (
                    <div style={{ width: '100%', textAlign: 'center' }}>
                      <CircularProgress sx={{ color: SystemColor.originColor }} size={24} color="inherit" />
                    </div>
                  ) : (
                    <NoDataFound message={'Please Select An Account To See Product Details'} />
                  )}
                </div>
              )}

              <BootstrapDialog
                aria-labelledby="customized-dialog-title"
                open={openSKUAlertModal}
                sx={{ '& .MuiDialog-paper': { width: '100%', maxHeight: 435 } }}
                maxWidth="sm"
              >
                <BootstrapDialogTitle
                  id="customized-dialog-title"
                  onClose={() => {
                    handleSKUAlertModelClose(false);
                  }}
                >
                  Self Cost Update Warning!
                </BootstrapDialogTitle>
                <Divider orientation="horizontal" flexItem />
                <DialogContent>
                  <h3>{selfCostMsg} is not filled by you.</h3>
                </DialogContent>
                <Divider orientation="horizontal" flexItem />
                <DialogActions>
                  <Button
                    variant="contained"
                    style={{ backgroundColor: 'red', paddingRight: '10px' }}
                    onClick={() => {
                      handleSKUAlertModelClose(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    style={{ backgroundColor: 'green' }}
                    onClick={() => {
                      handleSKUAlertModelClose(true);
                    }}
                  >
                    Update
                  </Button>
                </DialogActions>
              </BootstrapDialog>

              <BootstrapDialog
                onClose={handleExcelModalClose}
                aria-labelledby="customized-dialog-title"
                open={openImportExcelModal}
              >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleExcelModalClose}>
                  Import Excel
                </BootstrapDialogTitle>
                <Divider orientation="horizontal" flexItem />
                <DialogContent>
                  {loading ? (
                    <div style={{ width: '500px', textAlign: 'center' }}>
                      <CircularProgress sx={{ color: SystemColor.originColor }} size={24} color="inherit" />
                      <br />
                      Please Wait While Updating Data...
                    </div>
                  ) : (
                    <>
                      <UploadMultiFile
                        acceptTypes={'.xlsx,.xls,.csv'}
                        files={files}
                        title={'Drop Or Select Self Cost Updated Files'}
                        onDrop={(e) => {
                          setFiles([]);
                          handleDropMultiFile(e);
                        }}
                        onRemove={handleRemoveFile}
                        onRemoveAll={handleRemoveAllFiles}
                        onUpload={uploadDocument}
                      />
                    </>
                  )}
                </DialogContent>
              </BootstrapDialog>

              {/* Catalog PopUp */}
              <BootstrapDialog
                onClose={closeCatalogPopUp}
                aria-labelledby="customized-dialog-title"
                open={catalogPopup}
                maxWidth="lg"
              >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={closeCatalogPopUp}>
                  Catalog Id - {catalogRow?.catelog_id}
                </BootstrapDialogTitle>
                <Divider orientation="horizontal" flexItem />
                <DialogContent>
                  {loading ? (
                    <div style={{ width: '500px', textAlign: 'center' }}>
                      <CircularProgress sx={{ color: SystemColor.originColor }} size={24} color="inherit" />
                      <br />
                      Please wait while updating data...
                    </div>
                  ) : (
                    <>
                      <DataGridTable columns={catalogColumn} enablePagination={false} data={catalogData} />
                    </>
                  )}
                </DialogContent>
                <DialogActions>
                  <TextField
                    style={{ maxWidth: '20%', marginRight: 10 }}
                    id="outlined-basic"
                    size="small"
                    variant="outlined"
                    type="number"
                    value={allCost}
                    onChange={(event) => setAllCost(event.target.value)}
                  />
                  <Button
                    disabled={allCost <= 0}
                    variant="contained"
                    style={{ marginRight: 10 }}
                    onClick={updateCostCatalog}
                  >
                    Update All Self Cost
                  </Button>
                  <Button variant="contained" onClick={updateCatalogPopUp}>
                    Save
                  </Button>
                </DialogActions>
              </BootstrapDialog>
            </>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
