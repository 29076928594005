import React, {useEffect, useState} from 'react';
import moment from 'moment';

const CountdownTimer = ({ endTime }) => {
    const endDateTime = moment(endTime,'YYYY-MM-DD HH:mm:ss');
    const [remainingTime, setRemainingTime] = useState(moment.duration(endDateTime.diff(moment())));

    useEffect(() => {
        const interval = setInterval(() => {
            setRemainingTime(prevRemainingTime => {
                return moment.duration(prevRemainingTime.asMilliseconds() - 1000);
            });
        }, 1000);
        return () => clearInterval(interval);
    }, []);
    return (
        remainingTime.asSeconds() > 0 ?
        <div>
            <span><strong>Remaining Time:</strong>  {remainingTime.days()} days, {remainingTime.hours()}:{remainingTime.minutes()}:{remainingTime.seconds()} </span>
        </div>:<></>
    );
};

export default CountdownTimer;
