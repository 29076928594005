/* eslint-disable */
export const LimeroadValidateUploadedFile = (header, type) => {
    // if (type === 'Order') {
    //     try {
    //         if (
    //             header[0] === 'Order Id' &&
    //             header[1] === 'Created Date' &&
    //             header[6] === 'Size' &&
    //             header[7] === 'Sku Id' &&
    //             header[12] === 'Logistics Partner' &&
    //             header[13] === 'AWB'
    //         ) {
    //             return true;
    //         }
    //         return false;
    //     } catch (e) {
    //         return false;
    //     }
    // }
    //
    // if (type === 'shippedOrDeliveredOrCancelledOrder') {
    //     try {
    //         if (
    //             header[0] === 'Order Id' &&
    //             header[1] === 'Created Date' &&
    //             header[2] === 'Shipped Date' &&
    //             header[6] === 'Size' &&
    //             header[7] === 'Sku Id' &&
    //             header[12] === 'Logistics Partner' &&
    //             header[13] === 'AWB'
    //         ) {
    //             return true;
    //         }
    //         return false;
    //     } catch (e) {
    //         return false;
    //     }
    // }
    // if (type === 'RTN') {
    //     try {
    //         if (
    //             header[0] === 'Order Id' &&
    //             header[1] === 'Created Date' &&
    //             header[6] === 'Size' &&
    //             header[7] === 'Sku Id' &&
    //             header[11] === 'Status' &&
    //             header[12] === 'Logistics Partner' &&
    //             header[13] === 'AWB'
    //         ) {
    //             return true;
    //         }
    //         return false;
    //     } catch (e) {
    //         return false;
    //     }
    // }
    if (type === 'Payment') {
        try {
            if (
                header[0] === 'Cycle Date' &&
                header[4] === 'Order ID' &&
                header[6].includes('Total Payment Amount made to the Vendor')
            ) {
                return true;
            }
            return false;
        } catch (e) {
            return false;
        }
    }

    // if (type === 'Inventory') {
    //     try {
    //         if (
    //             header[1] === 'Style Code' &&
    //             header[5] === 'L1 Product Category' &&
    //             header[9] === 'Size' &&
    //             header[11] === 'Selling Price'
    //         ) {
    //             return true;
    //         }
    //         return false;
    //     } catch (e) {
    //         return false;
    //     }
    // }
};
