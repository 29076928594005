// i18n
import './locales/i18n';

// highlight
import './utils/highlight';

// scroll bar
import 'simplebar/src/simplebar.css';

// slick-carousel
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/black-and-white.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';

import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// redux
import { persistor, store } from './redux/store';
// contexts
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';
import { SettingsProvider } from './contexts/SettingsContext';
import { AuthProvider } from './contexts/AwsCognitoContext';
import App from './App';

// ReactDOM.render(
//   <AuthProvider>
//     <HelmetProvider>
//       <ReduxProvider store={store}>
//         <PersistGate loading={null} persistor={persistor}>
//           <LocalizationProvider dateAdapter={AdapterDateFns}>
//             <SettingsProvider>
//               <CollapseDrawerProvider>
//                 <BrowserRouter>
//                   <App />
//                 </BrowserRouter>
//               </CollapseDrawerProvider>
//             </SettingsProvider>
//           </LocalizationProvider>
//         </PersistGate>
//       </ReduxProvider>
//     </HelmetProvider>
//   </AuthProvider>,
//   document.getElementById('root')
// );

const Index = () => {
  console.warn = () => {};
  console.error = () => {};

  return (
    <AuthProvider>
      <HelmetProvider>
        <ReduxProvider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <SettingsProvider>
                <CollapseDrawerProvider>
                  <BrowserRouter>
                    <App />
                  </BrowserRouter>
                </CollapseDrawerProvider>
              </SettingsProvider>
            </LocalizationProvider>
          </PersistGate>
        </ReduxProvider>
      </HelmetProvider>
    </AuthProvider>
  );
};

ReactDOM.render(<Index />, document.getElementById('root'));
