import PropTypes from 'prop-types';
import isString from 'lodash/isString';
import Dropzone from 'react-dropzone';
// @mui
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
//
import Image from '../Image';
import Iconify from '../Iconify';
import RejectionFiles from './RejectionFiles';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  width: 144,
  height: 144,
  margin: 'auto',
  borderRadius: '50%',
  padding: theme.spacing(1),
  border: `1px dashed ${theme.palette.grey[500_32]}`,
}));

const DropZoneStyle = styled('div')({
  zIndex: 0,
  width: '100%',
  height: '100%',
  outline: 'none',
  display: 'flex',
  overflow: 'hidden',
  borderRadius: '50%',
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'center',
  '& > *': { width: '100%', height: '100%' },
  '&:hover': {
    cursor: 'pointer',
    '& .placeholder': {
      zIndex: 9,
    },
  },
});

const PlaceholderStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  color: theme.palette.text.secondary,
  backgroundColor: theme.palette.background.neutral,
  transition: theme.transitions.create('opacity', {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&:hover': { opacity: 0.72 },
}));

// ----------------------------------------------------------------------

UploadAvatar.propTypes = {
  error: PropTypes.bool,
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  helperText: PropTypes.node,
  sx: PropTypes.object,
};

export default function UploadAvatar({ error, file, helperText, sx, ...other }) {
  return (
      <Dropzone
          onDrop={()=>{}}
          multiple
          maxSize={20000000}
          accept={"*"}
      >
        {({ getRootProps, getInputProps, isDragActive, isDragReject, rejectedFiles}) => (
          <>
            <RootStyle
                sx={{
                  ...((isDragReject || error) && {
                    borderColor: 'error.light',
                  }),
                  ...sx,
                }}
            >
              <DropZoneStyle
                  {...getRootProps()}
                  sx={{
                    ...(isDragActive && { opacity: 0.72 }),
                  }}
              >
                <input {...getInputProps()} />

                {file && <Image alt="avatar" src={isString(file) ? file : file.preview} sx={{ zIndex: 8 }} />}

                <PlaceholderStyle
                    className="placeholder"
                    sx={{
                      ...(file && {
                        opacity: 0,
                        color: 'common.white',
                        bgcolor: 'grey.900',
                        '&:hover': { opacity: 0.72 },
                      }),
                      ...((isDragReject || error) && {
                        bgcolor: 'error.lighter',
                      }),
                    }}
                >
                  <Iconify icon={'ic:round-add-a-photo'} sx={{ width: 24, height: 24, mb: 1 }} />
                  <Typography variant="caption">{file ? 'Update photo' : 'Upload photo'}</Typography>
                </PlaceholderStyle>
              </DropZoneStyle>
            </RootStyle>

            {helperText && helperText}

            {rejectedFiles.length > 0 && <RejectionFiles fileRejections={rejectedFiles} />}
          </>
          )}
    </Dropzone>
  );
}
