/* eslint-disable */
export const SnapdealValidateUploadedFile = (header, type) => {
  // console.log("type",type)
  // console.log("header",header)
  if (type === 'RTNINRET' || type === 'RTNDLRET') {
    try {
      if (
        header[0] === 'Name' &&
        header[1] === 'SUPC' &&
        header[2] === 'SKU' &&
        header[3] === 'Sub OrderID' &&
        header[4] === 'Return Initiated On' &&
        header[5] === 'Return Delivered On' &&
        header[7] === 'AWB No' &&
        header[8] === 'Selling Price' &&
        header[10] === 'Order Date'
      ) {
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  }

  if (type === 'Payment') {
    try {
      return header[3] === 'Transaction ID' &&
          header[6] === 'SKU' &&
          header[7] === 'Invoice Number' &&
          header[40] === 'NetPayable' &&
          header[41] === 'Payment Date' &&
          header[45] === 'AWB Number' &&
          header[50] === 'Customer City';

    } catch (e) {
      return false;
    }
  }

  if (type === 'Order') {
    try {
      // console.log(header[0] === 'REFERENCECODE');
      if (
          header[0] === 'REFERENCECODE' &&
          header[2] === 'SUBORDERCODE' &&
          header[3] === 'PRODUCTNAME' &&
          header[4] === "ORDERVERIFIEDDATE" &&
          header[6] === 'AWBNO' &&
          header[7] === 'SHIPPINGPROVIDER' &&
          header[21] === 'SKUCODE' &&
          header[24] === 'ATTRIBUTES' &&
          header[36] === 'MRP'
          // header[16] === 'SUPC' &&

        // header[0] === 'Courier' &&
        // header[1] === 'Product' &&
        // header[2] === 'Reference Code' &&
        // header[3] === 'Suborder Id' &&
        // header[4] === 'SKU Code' &&
        // header[5] === 'AWB Number' &&
        // header[6] === 'Order Verified Date' &&
        // header[10] === 'Selling Price Per Item' &&
        // header[16] === 'SUPC' &&
        // header[17] === 'Attributes'
      ) {
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  }

  if (type === 'shippedOrder') {
    try {
      if (
        header[0] === 'REFERENCECODE' &&
        header[2] === 'SUBORDERCODE' &&
        header[3] === 'PRODUCTNAME' &&
        header[4] === 'ORDERVERIFIEDDATE' &&
        header[6] === 'AWBNO' &&
        header[7] === 'SHIPPINGPROVIDER' &&
        header[8] === 'SHIPPINGCITY' &&
        header[11] === 'INVOICEDATE' &&
        header[15] === 'SHIPPEDON' &&
        header[21] === 'SKUCODE' &&
        header[24] === 'ATTRIBUTES' &&
        header[25] === 'IMAGEURL'
      ) {
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  }

  if (type === 'RTNIn') {
    try {
      if (
        header[1] === 'SUPC' &&
        header[2] === 'SKU' &&
        header[3] === 'Suborder ID' &&
        header[4] === 'Attribute' &&
        header[5] === 'Return Initiated On' &&
        header[6] === 'Return Delivered On' &&
        header[7] === 'Fulfilment Mode' &&
        header[8] === 'AWBNumber' &&
        header[11] === 'Order Date' &&
        header[14] === 'SD Reference Number'
      ) {
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  }

  if (type === 'RTNDL') {
    try {
      if (
        header[1] === 'SUPC' &&
        header[2] === 'SKU' &&
        header[3] === 'Suborder ID' &&
        header[4] === 'Attribute' &&
        header[5] === 'Return Initiated On' &&
        header[6] === 'Return Delivered On' &&
        header[7] === 'Fulfilment Mode' &&
        header[8] === 'AWBNumber' &&
        header[11] === 'Order Date' &&
        header[14] === 'SD Reference Number'
      ) {
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  }

  if (type === 'RTNDL2') {
    try {
      if (
        header[0] === 'Name' &&
        header[1] === 'Attribute' &&
        header[2] === 'SUPC' &&
        header[3] === 'SKU' &&
        header[4] === 'Suborder ID' &&
        header[5] === 'Return Initiated On' &&
        header[6] === 'Return Delivered On' &&
        header[7] === 'Fulfilment Mode' &&
        header[8] === 'AWBNumber' &&
        header[9] === 'Selling Price' &&
        header[11] === 'Order Date'
      ) {
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  }

  if (type === 'RTNDL3') {
    try {
      if (
        header[0] === 'REFERENCECODE' &&
        header[2] === 'SUBORDERCODE' &&
        header[3] === 'PRODUCTNAME' &&
        header[4] === 'ORDERVERIFIEDDATE' &&
        header[6] === 'AWBNO' &&
        header[7] === 'SHIPPINGPROVIDER' &&
        header[17] === 'RETURNINITIATEDON' &&
        header[18] === 'RETURNDELIVEREDON' &&
        header[19] === 'SKUCODE' &&
        header[22] === 'ATTRIBUTES' &&
        header[23] === 'IMAGEURL'
      ) {
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  }

  if (type === 'Inventory') {
    try {
      if (
        header[0] === 'SUPC' &&
        header[1] === 'SKU Code' &&
        header[3] === 'Product Name' &&
        header[6] === 'Old Selling Price'
      ) {
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  }
};
