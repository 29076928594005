import {Container, Divider, Grid, Typography} from '@mui/material';
import {ToggleButton, ToggleButtonGroup} from "@mui/lab";
import React, {useCallback, useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {alpha, useTheme} from "@mui/material/styles";
import Label from "../../components/Label";
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Page from '../../components/Page';
import useSettings from '../../hooks/useSettings';
import urlConstants from '../../routes/urlConstants';
import FlipkartRewardDetails from "./Components/FlipkartRewardDetails";
import Iconify from "../../components/Iconify";
import GetAllRewards from "./GetAllRewards";


export default function FlipkartRewardsReco() {
    const { themeStretch } = useSettings();
    const navigate = useNavigate();
    const theme = useTheme();
    const {flipkartTab} = useParams();

    const [Platform, setPlatform] = useState('reward-offers');
    const platformOptions = [
        {
            platformName: 'All Rewards',
            platformIcon: 'arcticons:rewards',
            value: 'get-all-rewards',
        },
        {
            platformName: 'Reward Offers',
            platformIcon: 'la:medal',
            value: 'reward-offers',
        },
        {
            platformName: 'Mp Fees Recon.',
            platformIcon: 'healthicons:m-outline',
            value: 'mp-fees-recon.',
        },
    ]
    useEffect(() => {
        setPlatform(flipkartTab)
    }, [flipkartTab]);

    const handleChangePlatFormName = useCallback((event, newPlatFormName) => {
        if (newPlatFormName !== null) {
            setPlatform(newPlatFormName);
            const selectedPlatform = platformOptions.find(e => e.value === newPlatFormName);
            if (selectedPlatform) {
                navigate(`${urlConstants.FlipkartRewardReco}/${selectedPlatform.value}`);
            }
        }
    }, []);

    return (
        <Page title="OrderAnalysis">
            <Container maxWidth={themeStretch ? false : 'xl'}>
                <HeaderBreadcrumbs
                    heading="Flipkart Reward Reconciliation"
                    links={[{ name: 'Dashboard', href: urlConstants.HOME_PAGE }, { name: 'Flipkart Reward Reconciliation' }]}
                />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <>
                            <Grid container spacing={3} style={{paddingTop: '1.75%', width: '100%'}}>
                                <ToggleButtonGroup
                                    exclusive
                                    value={Platform}
                                    size="medium"
                                    onChange={handleChangePlatFormName}
                                    sx={{mt: 5, ml: 3, flexWrap: 'wrap'}}
                                >
                                    {platformOptions.map((data, i) => (
                                        <ToggleButton value={data.value} aria-label={data.value}
                                  yarn start                    key={data.value} color={'primary'}>
                                            <Label variant={'soft'} sx={{backgroundColor: alpha(theme.palette.primary.main, 0.15),}} color={'success'}>

                                                    <Iconify sx={{color: "#7635dc"}} icon={data.platformIcon} width={20} height={20}/>

                                            </Label>
                                            &nbsp;&nbsp;{data.platformName}
                                        </ToggleButton>
                                    ))}
                                </ToggleButtonGroup>
                            </Grid>

                            <Divider sx={{p: 1}}/>

                            {Platform === 'get-all-rewards' && <GetAllRewards />}
                            {Platform === 'reward-offers' && <FlipkartRewardDetails/>}
                            {Platform === 'mp-fees-recon.' && <></>}
                        </>
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
}
