/* eslint-disable */
import {LoadingButton} from '@mui/lab';
import {Box, Divider, Grid, IconButton, InputAdornment, Stack, TextField, Tooltip, Typography} from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import {useSnackbar} from 'notistack';
import React, { useEffect, useState } from 'react';
import DataGridTable from '../../../components/Common/DataGridTable';
import Iconify from '../../../components/Iconify';
import TimeAccountModal from './TimeAccountModal';
import useAuth from '../../../hooks/useAuth';
import {UserProfile, CreatePostAPICall, MyLinkedAccount} from '../../../utils/apis';
import {
  BootstrapDialog,
  BootstrapDialogTitle,
  handleOnClickCopy,
  isSuperAdminEnvironment,
} from '../../../utils/utilities';
import AccountStatusButton from './AccountStatusButton';

export default function LimeroadLinkedAccount({linkedAmazonAccounts, getAccountData}) {
    const {user} = useAuth();
    const userId = localStorage.getItem('userId');
    const localUser = window.localStorage.getItem('userEmailId');
    const {enqueueSnackbar} = useSnackbar();
    const [userToBeAdd, setUserToBeAdd] = useState({});
    const [selectedRow, setSelectedRow] = useState(null);
    const [inputFieldModalType, setInputFieldModalType] = useState('password');
    const [openMobileNoModel, setOpenMobileNoModel] = useState(false);
    const [openPasswordModel, setOpenPasswordModel] = useState(false);
    const [openTimeHistoryScheduleModel, setOpenTimeHistoryScheduleModel] = useState(false);
    const [openDeleteUserModel, setOpenDeleteUserModel] = useState(false);
    const [newMobileNumber, setNewMobileNumber] = useState('');
    const [apiKey, setApiKey] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [isAccountUpdating, setIsAccountUpdating] = useState(false);
    const [isInsertingAccountAmazon, setIsInsertingAccountLimeroad] = useState(false);
    const [amazonColumns, setAmazonColumns] = useState([
      {
        accessorKey: 'Email',
        header: 'Info',
        Cell: ({cell}) => (
          <div>
          <span>
            Email : {cell.row.original?.Email}
            <Tooltip title="Copy">
              <IconButton onClick={() => handleOnClickCopy(cell.row.original?.Email, enqueueSnackbar)}>
                <Iconify icon={'eva:copy-fill'} width={15} height={15}/>
              </IconButton>
            </Tooltip>
          </span>
            <br/>
            <span>
            Name : {cell.row.original?.Name}
              <Tooltip title="Copy">
              <IconButton onClick={() => handleOnClickCopy(cell.row.original?.Name, enqueueSnackbar)}>
                <Iconify icon={'eva:copy-fill'} width={15} height={15}/>
              </IconButton>
            </Tooltip>
          </span>
            <br/>
            <span>
            Number : {cell.row.original?.Mobile_No}
              <Tooltip title="Copy">
              <IconButton onClick={() => handleOnClickCopy(cell.row.original?.SupplierId, enqueueSnackbar)}>
                <Iconify icon={'eva:copy-fill'} width={15} height={15}/>
              </IconButton>
            </Tooltip>
          </span>
          </div>
        ),
      },
      {
        accessorKey: 'action',
        header: 'Action',
        Cell: ({cell}) => (
          <div>
            {/* Use for set Timer */}

              <Tooltip title="Timers History" placement="bottom">
                <IconButton
                  onClick={() => {
                    setOpenTimeHistoryScheduleModel(true);
                    setSelectedRow(cell.row.original);
                  }}
                >
                  <Iconify icon={'icon-park:alarm-clock'} width={24} height={24} />
                </IconButton>
              </Tooltip>
            <Tooltip title="Update Mobile Number" placement="bottom">
              <IconButton
                onClick={() => {
                  setOpenMobileNoModel(true);
                  setNewMobileNumber(cell.row.original?.Mobile_No?.replaceAll(' ', '').slice(-10));
                  setSelectedRow(cell.row.original);
                }}
              >
                <Iconify icon={'icon-park:phone-call'} width={24} height={24}/>
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete" placement="bottom">
              <IconButton
                onClick={() => {
                  setOpenDeleteUserModel(true);
                  setSelectedRow(cell.row.original);
                }}
              >
                <Iconify icon={'icon-park:delete-one'} width={24} height={24}/>
              </IconButton>
            </Tooltip>
          </div>
        ),
      },

    ]);


  useEffect(() => {
    if (isSuperAdminEnvironment) {
      setAmazonColumns(prevState => [
        ...prevState,
        {
          accessorKey: 'accountStatus',
          header: 'Account Status',
          size: 10,
          Cell: ({ cell }) => {
            return (
              <AccountStatusButton user={user} localUser={localUser} cell={cell} enqueueSnackbar={enqueueSnackbar} getAccountData={getAccountData} platform={'limeroad'}/>
            );
          },
        },
      ]);
    }
  }, []);
    // call update api for mobile num and password
    const limeroadUpdateMobileNumberAndPassword = async (isMobileNumberUpdate) => {
        setIsAccountUpdating(true);
        const body = {
            username: user?.email ? user.email : localUser,
            tpEmailId: selectedRow.Email,
            subAccountId: selectedRow.SupplierId,
            acType: selectedRow.AcType,
        };

        if (isMobileNumberUpdate) {
            body.mobile_no = newMobileNumber;
            body.actionType = 'updateMobileNo';
        } else {
            body.password = newPassword;
            body.actionType = 'updatePassword';
        }

        const successMessage =
            body.actionType === 'updateMobileNo' ? 'Mobile number updated successfully.' : 'Password updated successfully';
        const response = await CreatePostAPICall(enqueueSnackbar, MyLinkedAccount, body, successMessage);
        if (response) {
            getAccountData();
            clearAccountUpdateModals();
        }
    };

    // handle add account amazon api call and redirect to central panel to add account and refreshing table data
    const addLimeroadAccount = async () => {
        if (!userToBeAdd.tp_emailid || userToBeAdd.tp_emailid === '') {
            enqueueSnackbar('Please enter email.', {variant: 'error'});
            return;
        }

        if (!userToBeAdd.tp_company || userToBeAdd.tp_company === '') {
            enqueueSnackbar('Please enter company name.', {variant: 'error'});
            return;
        }

        if (!userToBeAdd?.mobile_number || userToBeAdd?.mobile_number === '') {
            enqueueSnackbar('Please enter mobile number.', {variant: 'error'});
            return;
        }

        if (userToBeAdd?.mobile_number.length < 10) {
            enqueueSnackbar('Please enter valid mobile number.', {variant: 'error'});
            return;
        }

        if (userToBeAdd?.apikey === '') {
            enqueueSnackbar('Please enter api key.', {variant: 'error'});
            return;
        }

        setIsInsertingAccountLimeroad(true);

        const body = {
            username: user?.email ? user.email : localUser,
            tpEmailId: userToBeAdd?.tp_emailid,
            cName: userToBeAdd?.tp_company,
            apiKey: userToBeAdd?.apikey,
            mobileNo: userToBeAdd?.mobile_number,
            acType: 'limeroad',
            actionType: 'addAccount',
        };
        const response = await CreatePostAPICall(enqueueSnackbar, MyLinkedAccount, body);
        if (response) {
            enqueueSnackbar(response.data.body)
        }
        setIsInsertingAccountLimeroad(false);
        setUserToBeAdd({tp_emailid: '', mobile_number: '', tp_company: '', apikey: ''});
    };

    // for request delete user api call and refreshing table data
    const deleteUser = async () => {
        setIsAccountUpdating(true);
        const body = {
            username: user?.email ? user.email : localUser,
            email: selectedRow.Email,
            supplier_id: selectedRow.SupplierId,
            acType: selectedRow.AcType,
            actionType: 'delete',
        };
        const response = await CreatePostAPICall(enqueueSnackbar, MyLinkedAccount, body, 'Account deleted successfully.');
        if (response) {
            getAccountData();
            clearAccountUpdateModals();
        }
    };

    // handle input change for all text-field
    const onInputChange = (event) => {
        const {name, value} = event.target;
        if (name === 'tp_emailid') {
            setUserToBeAdd({
                ...userToBeAdd,
                [name]: value.toLowerCase(),
            });
        } else {
            setUserToBeAdd({
                ...userToBeAdd,
                [name]: value,
            });
        }
    };

    // for clear all value on modal pop up close
    const clearAccountUpdateModals = () => {
        setOpenMobileNoModel(false);
        setOpenPasswordModel(false);
        setNewMobileNumber('');
        setNewPassword('');
        setSelectedRow(null);
        setOpenTimeHistoryScheduleModel(false);
        setOpenDeleteUserModel(false);
        setIsAccountUpdating(false);
    };

    return (
        <>
            <Divider sx={{p: 1}}/>

            <Grid sx={{p: 3}} container spacing={3}>
                <Grid item xs={12} md={4}>
                    <Typography sx={{pb: 2}} variant="h6">
                        Link New Limeroad Account
                    </Typography>
                    <Box
                        sx={{
                            display: 'grid',
                            rowGap: 2,
                            p: 2,
                        }}
                    >
                        <TextField
                            autoComplete="none"
                            id="amazonTpEmailid"
                            type="email"
                            placeholder="Email Id*"
                            name="tp_emailid"
                            value={userToBeAdd?.tp_emailid}
                            onChange={onInputChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton>
                                            <Iconify icon={'clarity:email-line'} width={24} height={24}/>
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <TextField
                            type="name"
                            placeholder="Company Name*"
                            id="amazonCompanyName"
                            name="tp_company"
                            value={userToBeAdd?.tp_company}
                            onChange={onInputChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton>
                                            <Iconify icon={'carbon:location-company'} width={24} height={24}/>
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <TextField
                            value={userToBeAdd?.mobile_number}
                            name="mobile_number"
                            autoComplete="none"
                            type="number"
                            id="amazonMobileNo"
                            placeholder="Mobile Number*"
                            onChange={(event) => {
                                if (event.target.value.length <= 10) {
                                    onInputChange(event);
                                }
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <div style={{padding: '8px'}}>+91</div>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <TextField
                            value={userToBeAdd?.apikey}
                            name="apikey"
                            autoComplete="none"
                            type="text"
                            id="apikey"
                            placeholder="Api Key*"
                            onChange={(event) => {
                                onInputChange(event);
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton>
                                            <Iconify icon={'bi:key'} width={24} height={24}/>
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <div>
                            <p style={{color: '#FFCC00'}}>Limeroad will contact you by this number.</p>
                        </div>
                        {/*
            <Button
              variant="outlined"
              onClick={() => {
                const link = `https://sellercentral.amazon.in/apps/authorize/consent?application_id=amzn1.sp.solution.213bf21c-d427-4571-a34b-738506cbbe9b&state=1234`;
                window.open(`${link}`, '_blank').focus();
              }}
            >
              Authorize Us
            </Button>
            */}

                        <Grid item xs={12} md={12} lg={12}>
                            <LoadingButton
                                className="mr-xs"
                                id="amazonAddAccount"
                                onClick={addLimeroadAccount}
                                variant="contained"
                                style={{width: '50%'}}
                                loading={isInsertingAccountAmazon}
                            >
                                Link Account
                            </LoadingButton>
                        </Grid>
                    </Box>
                </Grid>

                <Grid item xs={12} md={8}>
                    <Typography sx={{pb: 2}} variant="h6">
                        My Linked Limeroad Accounts
                    </Typography>
                    <DataGridTable
                        columns={amazonColumns}
                        enableRowNumbers={true}
                        data={linkedAmazonAccounts}

                        enablePagination={false}
                    />
                </Grid>
            </Grid>

            <BootstrapDialog
                sx={{'& .MuiDialog-paper': {width: '100%', maxHeight: 435}}}
                maxWidth="sm"
                onClose={clearAccountUpdateModals}
                aria-labelledby="customized-dialog-title"
                open={openMobileNoModel}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={clearAccountUpdateModals}>
                    Update Mobile Number
                </BootstrapDialogTitle>
                <Divider orientation="horizontal" flexItem/>
                <DialogContent>
                    <Box>
                        <Stack spacing={3}>
                            <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>
                                <TextField
                                    value={newMobileNumber}
                                    autoComplete="none"
                                    type="number"
                                    fullWidth
                                    placeholder="Mobile Number"
                                    onChange={(e) => {
                                        if (e.target.value.length <= 10) {
                                            setNewMobileNumber(e.target.value);
                                        }
                                    }}
                                    InputProps={{
                                        maxLength: 10,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <div style={{padding: '8px'}}>+91</div>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Stack>
                        </Stack>
                    </Box>
                </DialogContent>
                <Divider orientation="horizontal" flexItem/>
                <DialogActions>
                    <LoadingButton
                        loading={isAccountUpdating}
                        variant="contained"
                        autoFocus
                        onClick={async () => {
                            await limeroadUpdateMobileNumberAndPassword(true);
                        }}
                    >
                        Submit
                    </LoadingButton>
                </DialogActions>
            </BootstrapDialog>
            <BootstrapDialog
                sx={{'& .MuiDialog-paper': {width: '100%', maxHeight: 435}}}
                maxWidth="sm"
                onClose={clearAccountUpdateModals}
                aria-labelledby="customized-dialog-title"
                open={openPasswordModel}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={clearAccountUpdateModals}>
                    Change Password
                </BootstrapDialogTitle>
                <Divider orientation="horizontal" flexItem/>
                <DialogContent>
                    <Box>
                        <Stack spacing={3}>
                            <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>
                                <TextField
                                    value={newPassword}
                                    placeholder="Password"
                                    fullWidth
                                    type={inputFieldModalType}
                                    onChange={(e) => {
                                        setNewPassword(e.target.value);
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton>
                                                    <Iconify icon={'ri:lock-password-line'} width={24} height={24}/>
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {inputFieldModalType === 'password' ? (
                                                    <IconButton
                                                        onClick={() => {
                                                            setInputFieldModalType('text');
                                                        }}
                                                    >
                                                        <Iconify icon={'ant-design:eye-invisible-filled'} width={24}
                                                                 height={24}/>
                                                    </IconButton>
                                                ) : (
                                                    <IconButton
                                                        onClick={() => {
                                                            setInputFieldModalType('password');
                                                        }}
                                                    >
                                                        <Iconify icon={'ant-design:eye-filled'} width={24} height={24}/>
                                                    </IconButton>
                                                )}
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Stack>
                        </Stack>
                    </Box>
                </DialogContent>
                <Divider orientation="horizontal" flexItem/>
                <DialogActions>
                    <LoadingButton
                        loading={isAccountUpdating}
                        variant="contained"
                        autoFocus
                        onClick={async () => {
                            await limeroadUpdateMobileNumberAndPassword();
                        }}
                    >
                        Submit
                    </LoadingButton>
                </DialogActions>
            </BootstrapDialog>
            <BootstrapDialog
                sx={{'& .MuiDialog-paper': {width: '100%', maxHeight: 435}}}
                maxWidth="sm"
                onClose={() => {
                    setOpenDeleteUserModel(false);
                }}
                aria-labelledby="customized-dialog-title"
                open={openDeleteUserModel}
            >
                <BootstrapDialogTitle
                    id="customized-dialog-title"
                    onClose={() => {
                        setOpenDeleteUserModel(false);
                    }}
                >
                    Delete User
                </BootstrapDialogTitle>
                <Divider orientation="horizontal" flexItem/>
                <DialogContent>
                    <Box>
                        <Stack spacing={3}>
                            <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>
                                <Typography>
                                    Are you sure, want to sent permanently data delete request to admin for this
                                    account?
                                </Typography>
                            </Stack>
                        </Stack>
                    </Box>
                </DialogContent>
                <Divider orientation="horizontal" flexItem/>
                <DialogActions>
                    <LoadingButton
                        variant="contained"
                        loading={isAccountUpdating}
                        autoFocus
                        onClick={async () => {
                            await deleteUser();
                        }}
                    >
                        Yes
                    </LoadingButton>
                </DialogActions>
            </BootstrapDialog>

          {(selectedRow && openTimeHistoryScheduleModel) &&<TimeAccountModal
            closeTimeAccountHistoryModal={clearAccountUpdateModals}
            userEmailId={user?.email}
            platform={'limeroad'}
            openTimeHistoryScheduleModel={openTimeHistoryScheduleModel}
            timeHistoryScheduleData={selectedRow}
            enqueueSnackbar={enqueueSnackbar}
          />}
        </>
    );
}
