import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Button, Container, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
// layouts
import LogoOnlyLayout from '../../layouts/LogoOnlyLayout';
// components
import Iconify from '../../components/Iconify';
import Page from '../../components/Page';
// sections
import urlConstants from '../../routes/urlConstants';
import VerifyCodeForm from './components/VerifyCodeForm';

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '100%',
  alignItems: 'center',
  padding: theme.spacing(12, 0),
}));

export default function VerifyCode() {
  return (
    <Page title="Verify" sx={{ height: 1 }}>
      <RootStyle>
        <LogoOnlyLayout />

        <Container>
          <Box sx={{ maxWidth: 480, mx: 'auto' }}>
            <Button
              size="small"
              component={RouterLink}
              to={urlConstants.LOGIN_PAGE}
              startIcon={<Iconify icon={'eva:arrow-ios-back-fill'} width={20} height={20} />}
              sx={{ mb: 3 }}
            >
              Back
            </Button>

            <Typography variant="h3" paragraph>
              Please check your email!
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              We have emailed a 6-digit confirmation code to <strong>{localStorage.getItem('userEmailId')}</strong>,
              please enter the code in below box to verify your email.
            </Typography>

            <Box sx={{ mt: 5, mb: 3 }}>
              <VerifyCodeForm />
            </Box>
          </Box>
        </Container>
      </RootStyle>
    </Page>
  );
}
