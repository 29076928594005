/* eslint-disable */
import {Autocomplete, Button, IconButton, TextField, Tooltip} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { useTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import DataGridTable from '../../../../../components/Common/DataGridTable';
import useAuth from '../../../../../hooks/useAuth';
import { CreatePostAPICall, Home, MyLinkedAccount, V2Management } from '../../../../../utils/apis';
import {
    CreateExcelFile,
    downloadS3File,
    handleOnClickCopy,
    isSuperAdminEnvironment,
    JSONToCSVConvertor
} from '../../../../../utils/utilities';
import ReactJson from "react-json-view";
import Iconify from "../../../../../components/Iconify";
import DeleteIcon from "@mui/icons-material/Delete";
import {PAYMENT_UPLOAD_BT_ACCOUNT} from "../../../../../utils/Constants";

const SuccessTicket = ({platform}) => {
    const { enqueueSnackbar } = useSnackbar();
    const [autoPaymentTicketData, setAutoPaymentTicketData] = useState([]);
    const [dataLoading, setDataLoading] = useState(false);
    const [createdPayloadJSON, setCreatedPayloadJSON] = useState([]);

    const deletePaymentTicket = async (fileKey) => {
        const body = {
             actionType: "autoPaymentFileDelete",
             panel: "admin",
             subPanel: "adminManagement",
             fileKey : fileKey
         };
        const response = await CreatePostAPICall(enqueueSnackbar, V2Management, body);
        if (response) {
            enqueueSnackbar(response.data.body);
            getAutoPaymentData();
        }
    };
    const AptColumnUser = [
        {
            accessorKey: 'username',
            header: 'User ID',
            size: 20,

        },
        {
            accessorKey: 'acType',
            header: 'Plate from',
            size: 20,

        },
        {
            accessorKey: 'tpEmailIds',
            header: 'Email Id',
            size: 20,


        },
        {
            accessorKey: 'subAccountIds',
            header: 'Supply ID',
            size: 20,


        },
        {
            accessorKey: 'fileType',
            header: 'File Type',
            size: 20,


        },
        {
            accessorKey: 'Last_Modified',
            header: 'Last Modified',
            size: 20,
            Cell: (cell) => {
                const lastModified = new Date(cell?.row.original.Last_Modified);
                const formattedDate = lastModified.toLocaleDateString('en-US');
                const formattedTime = lastModified.toLocaleTimeString('en-US');
                return (
                    <div>
                        {formattedDate} {formattedTime}
                    </div>
                );
            }

        },
        {
            accessorKey: 'fileName',
            header: 'Download',
            size: 20,
            Cell: (cell) => {
                return <div>
                    {/*{cell?.row.original.fileName}*/}
                    <Tooltip title="Download">
                        <IconButton onClick={() => {
                            downloadS3File(cell?.row.original.S3_Key,false, PAYMENT_UPLOAD_BT_ACCOUNT, enqueueSnackbar)
                        }}>
                            <Iconify icon={'eva:download-fill'} width={24} height={24} />
                        </IconButton>
                    </Tooltip>
                </div>
            },
        },
        {
            accessorKey: 'Size',
            header: 'File Size',
            size: 20,
            Cell: (cell) => {
                const conversionFactor = 0.000001; // Replace this with your actual conversion factor
                const sizeInDileSoteag = cell?.row.original.Size;
                const sizeInMB = sizeInDileSoteag * conversionFactor;
                return (
                    <div>
                        {sizeInMB.toFixed(2)} MB
                    </div>
                );
            }

        },
        {
            accessorKey: 'S3_Key',
            header: 'Copy File key',
            size: 20,
            Cell: (cell) => {
                return (
                    <div>
                        {/*{cell?.row.original.File_Key}*/}
                        <Tooltip title="Copy">
                            <IconButton onClick={() => handleOnClickCopy(cell?.row.original.S3_Key, enqueueSnackbar)}>
                                <Iconify icon={'eva:copy-fill'} width={24} height={24} />
                            </IconButton>
                        </Tooltip>
                    </div>
                );
            }

        },
        // {
        //     accessorKey: 'fileName',
        //     header: 'File Name',
        //     size: 20,
        //     Cell: (cell) => {
        //         return <div>
        //             {cell?.row.original.fileName}
        //             <Tooltip title="Copy">
        //                 <IconButton onClick={() => handleOnClickCopy(cell?.row.original.fileName, enqueueSnackbar)}>
        //                     <Iconify icon={'eva:copy-fill'} width={24} height={24} />
        //                 </IconButton>
        //             </Tooltip>
        //         </div>
        //     },
        // },



        // {
        //     accessorKey: 'ticketPayload',
        //     header: 'Ticket Payload',
        //     size: 20,
        //     Cell: (cell) => {
        //         const index = cell.row.index;
        //         return <ReactJson
        //             name={"Payload"}
        //             src={createdPayloadJSON[index]}
        //             displayObjectSize={false}
        //             enableClipboard={true}
        //             displayDataTypes={false}
        //             collapsed
        //         />
        //     },
        // },
        // {
        //     accessorKey: 'S3_Key',
        //     header: 'Delete',
        //     size: 10,
        //     Cell: ({ cell }) => (
        //         <IconButton
        //             aria-label="delete"
        //             onClick={() => {
        //                 deletePaymentTicket(cell.row.original.S3_Key);
        //             }}
        //         >
        //             <DeleteIcon style={{ color: 'red' }} />
        //         </IconButton>
        //     ),
        // },
    ];




    const AptColumnAdmin = [
        {
            accessorKey: 'username',
            header: 'User ID',
            size: 20,

        },
        {
            accessorKey: 'acType',
            header: 'Plate from',
            size: 20,

        },
        {
            accessorKey: 'tpEmailIds',
            header: 'Email Id',
            size: 20,


        },
        {
            accessorKey: 'subAccountIds',
            header: 'Supply ID',
            size: 20,


        },
        {
            accessorKey: 'fileType',
            header: 'File Type',
            size: 20,


        },
        {
            accessorKey: 'Last_Modified',
            header: 'Last Modified',
            size: 20,
            Cell: (cell) => {
                const lastModified = new Date(cell?.row.original.Last_Modified);
                const formattedDate = lastModified.toLocaleDateString('en-US');
                const formattedTime = lastModified.toLocaleTimeString('en-US');
                return (
                    <div>
                        {formattedDate} {formattedTime}
                    </div>
                );
            }

        },
        {
            accessorKey: 'fileName',
            header: 'Download',
            size: 20,
            Cell: (cell) => {
                return <div>
                    {/*{cell?.row.original.fileName}*/}
                    <Tooltip title="Download">
                        <IconButton onClick={() => {
                            downloadS3File(cell?.row.original.S3_Key,false, PAYMENT_UPLOAD_BT_ACCOUNT, enqueueSnackbar)
                        }}>
                            <Iconify icon={'eva:download-fill'} width={24} height={24} />
                        </IconButton>
                    </Tooltip>
                </div>
            },
        },
        {
            accessorKey: 'Size',
            header: 'File Size',
            size: 20,
            Cell: (cell) => {
                const conversionFactor = 0.000001; // Replace this with your actual conversion factor
                const sizeInDileSoteag = cell?.row.original.Size;
                const sizeInMB = sizeInDileSoteag * conversionFactor;
                return (
                    <div>
                        {sizeInMB.toFixed(2)} MB
                    </div>
                );
            }

        },
        {
            accessorKey: 'S3_Key',
            header: 'Copy File key',
            size: 20,
            Cell: (cell) => {
                return (
                    <div>
                        {/*{cell?.row.original.File_Key}*/}
                        <Tooltip title="Copy">
                            <IconButton onClick={() => handleOnClickCopy(cell?.row.original.S3_Key, enqueueSnackbar)}>
                                <Iconify icon={'eva:copy-fill'} width={24} height={24} />
                            </IconButton>
                        </Tooltip>
                    </div>
                );
            }

        },
        // {
        //     accessorKey: 'fileName',
        //     header: 'File Name',
        //     size: 20,
        //     Cell: (cell) => {
        //         return <div>
        //             {cell?.row.original.fileName}
        //             <Tooltip title="Copy">
        //                 <IconButton onClick={() => handleOnClickCopy(cell?.row.original.fileName, enqueueSnackbar)}>
        //                     <Iconify icon={'eva:copy-fill'} width={24} height={24} />
        //                 </IconButton>
        //             </Tooltip>
        //         </div>
        //     },
        // },



        // {
        //     accessorKey: 'ticketPayload',
        //     header: 'Ticket Payload',
        //     size: 20,
        //     Cell: (cell) => {
        //         const index = cell.row.index;
        //         return <ReactJson
        //             name={"Payload"}
        //             src={createdPayloadJSON[index]}
        //             displayObjectSize={false}
        //             enableClipboard={true}
        //             displayDataTypes={false}
        //             collapsed
        //         />
        //     },
        // },
        {
            accessorKey: 'S3_Key',
            header: 'Delete',
            size: 10,
            Cell: ({ cell }) => (
                <IconButton
                    aria-label="delete"
                    onClick={() => {
                        deletePaymentTicket(cell.row.original.S3_Key);
                    }}
                >
                    <DeleteIcon style={{ color: 'red' }} />
                </IconButton>
            ),
        },
    ];

    const getAutoPaymentData = async () => {
        setDataLoading(true);
        const body = {
            "actionType": "autoPaymentUpload",
            "panel": "admin",
            "subPanel": "adminManagement",
            "prefix" : "Success",
            "platform" : platform
        };

        setAutoPaymentTicketData([]);
        const response = await CreatePostAPICall(enqueueSnackbar, V2Management, body);
        // if (response) {
        //     var tempData = [];
        //     const listPayload = [];
        //     for (const responseKey in response.data.body) {
        //         const data = response.data.body[responseKey].split('/');
        //         var userName = data[1];
        //     var tpmailId = data[2];
        //     var filename = response.data.body[responseKey].split('/')[5]
        //     var subAccountId = data[3];
        //     var token = data[4];
        //     const s = data.slice(0, -2);
        //         const fileKeyData = s.join("/");
        //         const ticketPayload = {
        //             'username': userName,
        //             'tpEmailId': tpmailId,
        //             'subAccountId': subAccountId,
        //             'filesToSave': {},
        //             'syncDataType': 'autoPaymentSync'
        //         }
        //
        //         ticketPayload["filesToSave"][filename]= {
        //             'token': token,
        //             'fileKey': response.data.body[responseKey]
        //         }
        //         listPayload.push(ticketPayload);
        //         var resData = {
        //             'FileKey':fileKeyData,
        //             'deleteKey':response.data.body[responseKey],
        //             'fileName':filename,
        //             'ticketPayload':ticketPayload
        //         }
        //         tempData.push(resData);
        //     }
        if (response && response.data.body) {
            const responseData = response.data.body;
            const tempData = [];
            // console.log("responseData == ",responseData)
            // Process each key-value pair in the response data
            for (const i in responseData) {
                if (responseData.hasOwnProperty(i)){
                    // console.log("Key:", i);


                    const item = responseData[i];
                    const key = item.Key;
                    const lastModified = item.LastModified;
                    const size = item.Size;
                    const storageClass = item.StorageClass;



                    // Splitting the key by "/"
                    const keyParts = key.split('/');
                    // cnsole.log("Key:", keyParts);
                    const platformFromKey = keyParts[2]; // Assuming platform is at this index

                    // Check if platform and ac_type match your criteria
                    if (platform === platformFromKey) {
                        const ticketPayload = {
                            "S3_Key": key,
                            'fileStatus': keyParts[0],
                            "username": keyParts[1],
                            "tpEmailIds": keyParts[3],
                            "acType" :platformFromKey,
                            "subAccountIds": keyParts[4],
                            "companyName":keyParts[5],
                            'token': keyParts[6],
                            'fileType': keyParts[7],
                            'fileName': keyParts[8],
                            "Last_Modified": lastModified,
                            "Size": size,
                            "Storage Class": storageClass,
                            'syncDataType': 'autoPaymentSync'
                        };
                        tempData.push(ticketPayload);
                    }
                }
            }
            setCreatedPayloadJSON(tempData);

            setAutoPaymentTicketData(tempData);
        }
        setDataLoading(false);
    };

    //   default api call
    useEffect(() => {
        getAutoPaymentData();
    }, [platform]);

    const exportToCsvData = () => {
        if (autoPaymentTicketData.length !== 0) {
            CreateExcelFile(autoPaymentTicketData, 'Low Balance', true);
        }
    };

    const handleRowSelection = (rowData) => {
        // Check if the row is already selected
        const isSelected = selectedRows.some(row => row.S3_Key === rowData.S3_Key);

        // Update selectedRows based on the selection
        if (isSelected) {
            setSelectedRows(selectedRows.filter(row => row.S3_Key !== rowData.S3_Key));
        } else {
            setSelectedRows([...selectedRows, rowData]);
        }
    };

    // Function to delete selected entries
    const deleteSelectedEntries = async () => {
        // Perform deletion logic for selectedRows
        for (const row of selectedRows) {
            await deletePaymentTicket(row.S3_Key);
        }
        // Clear selectedRows after deletion
        setSelectedRows([]);
    };

    return (
        <>
            <DataGridTable
                columns={isSuperAdminEnvironment ? AptColumnAdmin  : AptColumnUser}
                checkbox
                button1={'Delete Selected'}
                funReported={(value) => selectedDelete(value)}
                data={autoPaymentTicketData}
                sorting isLoading={dataLoading}
            />
        </>
    );
};

export default SuccessTicket;