/* eslint-disable */
import { Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import DataGridTable from '../../../../components/Common/DataGridTable';
import {JSONToCSVConvertor, changeDataFormat, CreateExcelFile} from '../../../../utils/utilities';
import { CreatePostAPICall, V2Management } from '../../../../utils/apis';

const UserStatus = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [userStateData, setUserStateData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);

  const userSts = [
    {
      accessorKey: 'Username',
      header: 'User Name',
      size: 30,
    },
    {
      accessorKey: 'Email',
      header: 'Email',
      size: 40,
    },
    {
      accessorKey: 'Supplier Id',
      header: 'Supplier Id',
      size: 30,
    },
    {
      accessorKey: 'Ready To Ship',
      header: 'Ready To Ship',
      size: 30,
      Cell: (cell) => changeDataFormat(cell.row.original?.['Ready To Ship']),
    },
    {
      accessorKey: 'Total/Billed',
      header: 'Total/Billed',
      size: 30,
    },
    {
      accessorKey: 'Return',
      header: 'Return',
      size: 30,
      Cell: (cell) => changeDataFormat(cell.row.original?.Return),
    },
    {
      accessorKey: 'Cancel',
      header: 'Cancel',
      size: 30,
      Cell: (cell) => changeDataFormat(cell.row.original?.Cancel),
    },
    {
      accessorKey: 'Payment',
      header: 'Payment',
      size: 30,
      Cell: (cell) => changeDataFormat(cell.row.original?.Payment),
    },
    {
      accessorKey: 'Ticket',
      header: 'Ticket',
      size: 30,
      Cell: (cell) => changeDataFormat(cell.row.original?.Ticket),
    },
  ];

  const getUserStatusApiData = async () => {
    setDataLoading(true);
    const body = {
      actionType: 'getAllAccountStatus',
      panel: 'admin',
      subPanel: 'adminManagement',
    };
    setUserStateData([]);
    const response = await CreatePostAPICall(enqueueSnackbar, V2Management, body);
    if (response) {
      setUserStateData(response.data.body);
    }
    setDataLoading(false);
  };

  useEffect(() => {
    getUserStatusApiData();
  }, []);

  const exportToCsvData = () => {
    if (userStateData.length !== 0) {
      CreateExcelFile(userStateData, 'User Request', true);
    }
  };

  return (
    <>
      <div style={{ textAlign: 'end', marginBottom: '15px' }}>
        <Button
          variant="contained"
          onClick={() => {
            exportToCsvData();
          }}
        >
          Export Excel
        </Button>
      </div>

      <DataGridTable columns={userSts} data={userStateData} sorting isLoading={dataLoading} />
    </>
  );
};

export default UserStatus;
