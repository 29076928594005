/* eslint-disable */
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, IconButton } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import DataGridTable from '../../../../components/Common/DataGridTable';
import useAuth from '../../../../hooks/useAuth';
import { CreatePostAPICall, V2Management } from '../../../../utils/apis';

const DeleteMonthlyReports = () => {
  const { user } = useAuth();
  const localUser = window.localStorage.getItem('userEmailId');
  const { enqueueSnackbar } = useSnackbar();
  const [monthlyReportsData, setMonthlyReportsData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);

  const monthlyReportsDelete = [
    {
      accessorKey: 'acType',
      header: 'Platform',
      size: 5,
    },
    {
      accessorKey: 'userName',
      header: 'Account',
      size: 10,
    },
    {
      accessorKey: 'company_name',
      header: 'Company Name',
      size: 10,
    },
    {
      accessorKey: 'subAccountId',
      header: 'Supplier Id',
      size: 10,
    },
    {
      accessorKey: 'reRange',
      header: 'Report Month',
      size: 10,
    },
    {
      accessorKey: 'delete',
      header: 'Delete',
      size: 10,
      Cell: ({ cell }) => (
        <IconButton
          aria-label="delete"
          onClick={() => {
            deleteMonthlyReportApi(cell.row.original.sort_key, cell.row.original.part_key);
          }}
        >
          <DeleteIcon style={{ color: 'red' }} />
        </IconButton>
      ),
    },
  ];

  const allMonthlyReports = async () => {
    setDataLoading(true);
    const body = {
      username: user?.email ? user.email : localUser,
      action: 'getAll',
      panel: 'admin',
      subPanel: 'adminManagement',
      actionType: 'monthlyReports',
    };
    const response = await CreatePostAPICall(enqueueSnackbar, V2Management, body);
    if (response) {
      const summaryData = [];
      response.data.body.forEach((x) => {
        const { stateWiseData } = x;
        const reportData = x.summaryReport;
        reportData.officeExpanse = x.officeExpanse ? x.officeExpanse : 0;
        reportData.stateWiseData = stateWiseData;
        reportData.userName = x.account;
        reportData.token = x.token;
        if (x.ac_type) {
          reportData.acType = x.ac_type;
        } else {
          reportData.acType = x.acType;
        }
        reportData.company_name = x.company_name;
        if (x.supplier_id) {
          reportData.subAccountId = x.supplier_id;
        } else {
          reportData.subAccountId = x.subAccountId;
        }
        reportData.reRange = x.reRange;
        reportData.reDate = x.reDate;
        reportData.executed_time = x.executed_time;
        reportData.error = x.Error;
        reportData.excel = x.excel;
        reportData.part_key = x.part_key;
        reportData.sort_key = x.sort_key;
        summaryData.push(reportData);
      });
      setMonthlyReportsData(summaryData);
      setDataLoading(false);
    }
  };

  const deleteMonthlyReportApi = async (sort_key, part_key) => {
    const body = {
      username: user?.email ? user.email : localUser,
      part_key,
      sort_key,
      actionType: 'monthlyReports',
      action: 'delete',
      panel: 'admin',
      subPanel: 'adminManagement',
    };
    const response = await CreatePostAPICall(enqueueSnackbar, V2Management, body);
    if (response) {
      enqueueSnackbar(response.data.body);
      allMonthlyReports();
    }
  };

  const selectedDeleteMonthlyReports = async (value) => {
    deleteMonthlyReportApi(value.sort_key, value.part_key);
  };

  useEffect(() => {
    allMonthlyReports();
  }, []);

  return (
    <>
      <div style={{ paddingBottom: '20px', textAlign: 'end' }}>
        <Button variant="outlined" onClick={allMonthlyReports}>
          View All Reports
        </Button>
      </div>
      <DataGridTable
        columns={monthlyReportsDelete}
        enablePagination={false}
        data={monthlyReportsData}
        checkbox
        button1={'Delete All Selected Reports'}
        funReported={(value) => selectedDeleteMonthlyReports(value)}
        isLoading={dataLoading}
      />
    </>
  );
};

export default DeleteMonthlyReports;
