/* eslint-disable */
import {
    Box,
    Card,
    Divider, Drawer, FormControlLabel,
    Grid,
    IconButton,
    Stack,
    Tab,
    Table,
    TableBody,
    TableContainer, TablePagination,
    Tabs,
    Tooltip
} from '@mui/material';
import {capitalCase} from 'change-case';
import React, {useEffect, useState} from 'react';

import {InvoiceTableRow, InvoiceTableToolbar} from "./invoice/list";
import useTable, {emptyRows, getComparator} from "../../../hooks/useTable";
import Switch from "@mui/material/Switch";
import {TableEmptyRows, TableHeadCustom, TableNoData, TableSelectedActions} from "./invoice/table";
import {Label} from "@mui/icons-material";
import Scrollbar from "../../../components/Scrollbar";
import InvoiceAnalytic from "./invoice/InvoiceAnalytic";
import {orderBy, sumBy} from "lodash";
import Iconify from "../../../components/Iconify";
import {useTheme} from "@mui/material/styles";
import useTabs from "../../../hooks/useTabs";
import useResponsive from "../../../hooks/useResponsive";
import {Typography} from "antd";
import InvoiceNewEditForm from "./invoice/new-edit-form";
import InvoiceNewEditDetails from "./invoice/new-edit-form/InvoiceNewEditDetails";

const SERVICE_OPTIONS = [
    'all',
    'full stack development',
    'backend development',
    'ui design',
    'ui/ux design',
    'front end development',
];

const TABLE_HEAD = [
    {id: 'userName', label: 'User Name', align: 'left'},
    {id: 'formName', label: 'Form Name', align: 'left'},
    {id: 'gstNo', label: 'GST No', align: 'left'},
    {id: 'id&t', label: 'Invoice - Date & Time', align: 'center', width: 140},
    // {id: 'status', label: 'Status', align: 'left'},
    {id: ''},
];
const InvoiceTable = ({billData, setBillData,editBill}) => {
    const mdUp = useResponsive('up', 'md');
    const theme = useTheme();

    const [filterName, setFilterName] = useState('');
    const [selectedInvoice, setSelectedInvoice] = useState('');

    const [filterService, setFilterService] = useState('all');

    const [filterStartDate, setFilterStartDate] = useState(null);

    const [filterEndDate, setFilterEndDate] = useState(null);
    const [sideDrawer, setSideDrawer] = useState(false);

    const {currentTab: filterStatus, onChangeTab: onFilterStatus} = useTabs('all');


    const {
        dense,
        page,
        order,
        orderBy,
        rowsPerPage,
        setPage,
        //
        selected,
        setSelected,
        onSelectRow,
        onSelectAllRows,
        //
        onSort,
        onChangeDense,
        onChangePage,
        onChangeRowsPerPage,
    } = useTable({defaultOrderBy: 'createDate', defaultDense: true});

    const handleFilterName = (filterName) => {
        setFilterName(filterName);
        setPage(0);
    };

    const handleFilterService = (event) => {
        setFilterService(event.target.value);
    };

    const handleDeleteRow = (sort_key) => {
        setBillData(oldValues => {
            return oldValues.filter((data, i) => {
                return data.sort_key !== sort_key
            })
        })
    };

    const handleDeleteRows = (selected) => {
        setBillData(oldValues => {
            return oldValues.filter((data, i) => {
                return !selected.includes(data.sort_key)
            })
        })
    };

    const handleEditRow = (id) => {
        // navigate(PATH_DASHBOARD.invoice.edit(id));
    };
    const dataFiltered = applySortFilter({
        tableData: billData || [],
        comparator: getComparator(order, orderBy),
        filterName,
        filterStatus,
        filterService,
        filterStartDate,
        filterEndDate,
    });
    const handleViewRow = (id) => {
        // navigate(PATH_DASHBOARD.invoice.view(id));
    };


    const denseHeight = dense ? 56 : 76;

    const isNotFound =
        (!dataFiltered.length && !!filterName) ||
        (!dataFiltered.length && !!filterStatus) ||
        (!dataFiltered.length && !!filterService) ||
        (!dataFiltered.length && !!filterEndDate) ||
        (!dataFiltered.length && !!filterStartDate);

    // const getLengthByStatus = (status) => billData.filter((item) => item.status === status).length;
    const getLengthByStatus = (status) => billData.length;

    const getTotalPriceByStatus = (status) =>
        sumBy(
            billData.filter((item) => item.status === status),
            'totalPrice'
        );

    const getPercentByStatus = (status) => (getLengthByStatus(status) / billData.length) * 100;

    const TABS = [
        {value: 'all', label: 'All', color: 'info', count: billData.length},
        {value: 'paid', label: 'Paid', color: 'success', count: getLengthByStatus('paid')},
        {value: 'unpaid', label: 'Unpaid', color: 'warning', count: getLengthByStatus('unpaid')},
        {value: 'overdue', label: 'Overdue', color: 'error', count: getLengthByStatus('overdue')},
        {value: 'draft', label: 'Draft', color: 'default', count: getLengthByStatus('draft')},
    ];

    return (
        <>

            <Card sx={{mb: 5}}>
                <Scrollbar>
                    <Stack
                        direction="row"
                        divider={<Divider orientation="vertical" flexItem
                                          sx={{borderStyle: 'dashed'}}/>}
                        sx={{py: 2}}
                    >
                        <InvoiceAnalytic
                            title="Total"
                            total={dataFiltered.length}
                            percent={100}
                            price={sumBy(dataFiltered, 'totalPrice')}
                            icon="ic:round-receipt"
                            color={theme.palette.info.main}
                        />
                        <InvoiceAnalytic
                            title="Paid"
                            total={getLengthByStatus('paid')}
                            percent={getPercentByStatus('paid')}
                            price={getTotalPriceByStatus('paid')}
                            icon="eva:checkmark-circle-2-fill"
                            color={theme.palette.success.main}
                        />
                        <InvoiceAnalytic
                            title="Unpaid"
                            total={getLengthByStatus('unpaid')}
                            percent={getPercentByStatus('unpaid')}
                            price={getTotalPriceByStatus('unpaid')}
                            icon="eva:clock-fill"
                            color={theme.palette.warning.main}
                        />
                        <InvoiceAnalytic
                            title="Overdue"
                            total={getLengthByStatus('overdue')}
                            percent={getPercentByStatus('overdue')}
                            price={getTotalPriceByStatus('overdue')}
                            icon="eva:bell-fill"
                            color={theme.palette.error.main}
                        />
                        <InvoiceAnalytic
                            title="Draft"
                            total={getLengthByStatus('draft')}
                            percent={getPercentByStatus('draft')}
                            price={getTotalPriceByStatus('draft')}
                            icon="eva:file-fill"
                            color={theme.palette.text.secondary}
                        />
                    </Stack>
                </Scrollbar>
            </Card>


            <Card>
                <Tabs
                    allowScrollButtonsMobile
                    variant="scrollable"
                    scrollButtons="auto"
                    value={filterStatus}
                    onChange={onFilterStatus}
                    sx={{px: 2, bgcolor: 'background.neutral'}}
                >
                    {TABS.map((tab) => (
                        <Tab
                            disableRipple
                            key={tab.value}
                            value={tab.value}
                            label={
                                <Stack spacing={1} direction="row" alignItems="center">
                                    <div>{tab.label}</div>
                                    <Label color={tab.color}> {tab.count} </Label>
                                </Stack>
                            }
                        />
                    ))}
                </Tabs>

                <Divider/>

                <InvoiceTableToolbar
                    filterName={filterName}
                    filterService={filterService}
                    filterStartDate={filterStartDate}
                    filterEndDate={filterEndDate}
                    onFilterName={handleFilterName}
                    onFilterService={handleFilterService}
                    onFilterStartDate={(newValue) => {
                        setFilterStartDate(newValue);
                    }}
                    onFilterEndDate={(newValue) => {
                        setFilterEndDate(newValue);
                    }}
                    optionsService={SERVICE_OPTIONS}
                />

                <Scrollbar>
                    <TableContainer sx={{minWidth: 800, position: 'relative'}}>
                        {selected.length > 0 && (
                            <TableSelectedActions
                                dense={dense}
                                numSelected={selected.length}
                                rowCount={billData.length}
                                onSelectAllRows={(checked) =>
                                    onSelectAllRows(
                                        checked,
                                        billData.map((row) => row.id)
                                    )
                                }
                                actions={
                                    <Stack spacing={1} direction="row">
                                        <Tooltip title="Sent">
                                            <IconButton color="primary">
                                                <Iconify icon={'ic:round-send'}/>
                                            </IconButton>
                                        </Tooltip>

                                        <Tooltip title="Download">
                                            <IconButton color="primary">
                                                <Iconify icon={'eva:download-outline'}/>
                                            </IconButton>
                                        </Tooltip>

                                        <Tooltip title="Print">
                                            <IconButton color="primary">
                                                <Iconify icon={'eva:printer-fill'}/>
                                            </IconButton>
                                        </Tooltip>

                                        <Tooltip title="Delete">
                                            <IconButton color="primary"
                                                        onClick={() => handleDeleteRows(selected)}>
                                                <Iconify icon={'eva:trash-2-outline'}/>
                                            </IconButton>
                                        </Tooltip>
                                    </Stack>
                                }
                            />
                        )}

                        <Table size={dense ? 'small' : 'medium'}>
                            <TableHeadCustom
                                order={order}
                                orderBy={orderBy}
                                headLabel={TABLE_HEAD}
                                rowCount={billData.length}
                                numSelected={selected.length}
                                onSort={onSort}
                                onSelectAllRows={(checked) =>
                                    onSelectAllRows(
                                        checked,
                                        billData.map((row) => row.id)
                                    )
                                }
                            />

                            <TableBody>
                                {dataFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                                    <InvoiceTableRow
                                        key={row.sort_key}
                                        row={row}
                                        selected={selected.includes(row.sort_key)}
                                        onSelectRow={() => onSelectRow(row.sort_key)}
                                        onViewRow={() => handleViewRow(row.sort_key)}
                                        onEditRow={() => {
                                            editBill(row)
                                        }}
                                        onDeleteRow={() => handleDeleteRow(row.sort_key)}
                                    />
                                ))}

                                <TableEmptyRows height={denseHeight}
                                                emptyRows={emptyRows(page, rowsPerPage, billData.length)}/>

                                <TableNoData isNotFound={isNotFound}/>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Scrollbar>

                <Box sx={{position: 'relative'}}>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={dataFiltered.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={onChangePage}
                        onRowsPerPageChange={onChangeRowsPerPage}
                    />

                    <FormControlLabel
                        control={<Switch checked={dense} onChange={onChangeDense}/>}
                        label="Dense"
                        sx={{px: 3, py: 1.5, top: 0, position: {md: 'absolute'}}}
                    />
                </Box>
            </Card>


            <Drawer
                anchor="right"
                open={sideDrawer}
                onClose={event => setSideDrawer(false)}
                slotProps={{
                    backdrop: {invisible: true},
                }}
                PaperProps={{
                    sx: {width: mdUp ? "65%" : "90%"},
                }}
            >
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{py: 2, pr: 1, pl: 2.5}}
                >
                    <h3>
                        Edit Invoice
                    </h3>

                    <IconButton onClick={() => {
                        setSideDrawer(false)
                    }}>
                        <Iconify icon="mingcute:close-line"/>
                    </IconButton>
                </Stack>
                <Divider/>
                <Scrollbar sx={{px: 2.5, py: 3}}>
                     <InvoiceNewEditForm isEdit={true} currentInvoice={selectedInvoice}/>
                </Scrollbar>
            </Drawer>

        </>
    );
};

export default InvoiceTable;

function applySortFilter({
                             tableData,
                             comparator,
                             filterName,
                             filterStatus,
                             filterService,
                             filterStartDate,
                             filterEndDate,
                         }) {


    if (!tableData) {
        return [];
    }

    const stabilizedThis = tableData.map((el, index) => [el, index]);

    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });


    tableData = stabilizedThis.map((el) => el[0]);

    if (filterName) {
        tableData = tableData.filter(
            (item) =>
                item.sort_key.toLowerCase().split('#')[0].indexOf(filterName.toLowerCase()) !== -1 ||
                item.GstNo.indexOf(filterName) !== -1
        );
    }

    if (filterStatus !== 'all') {
        tableData = tableData.filter((item) => item.status === filterStatus);
    }

    if (filterService !== 'all') {
        tableData = tableData.filter((item) => item.items.some((c) => c.service === filterService));
    }

    if (filterStartDate && filterEndDate) {
        const startDateObject = new Date(filterStartDate);
        startDateObject.setHours(0, 0, 0, 0);

        const endDateObject = new Date(filterEndDate);
        endDateObject.setHours(23, 59, 59, 999);

        tableData = tableData.filter(
            (item) => {
                const invoiceDateTime = new Date(item.InvoiceDateTime);
                invoiceDateTime.setHours(0, 0, 0, 0);
                return invoiceDateTime >= startDateObject && invoiceDateTime <= endDateObject;
            }
        );
    }

    return tableData;
}