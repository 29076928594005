
/* eslint-disable */
import { Box, Divider, Tab, Tabs, Typography } from '@mui/material';
import { capitalCase } from 'change-case';
import React, { useState } from 'react';
import useTabs from '../../../../../hooks/useTabs';
import SuccessTicket from "./successTicket";
import PendingTicket from "./pendingTicket";
import FailedTicket from "./FailedTicket";
import OnProcessTicket from "./onProcessTicket";
import { ToggleButton, ToggleButtonGroup } from "@mui/lab";
import { alpha } from "@mui/material/styles";
import Iconify from "../../../../../components/Iconify";


const PlatformOptions = [
    {
        platformName: 'Meesho',
        platformIcon: 'healthicons:m-outline',
        value: 'meesho',
    },
    {
        platformName: 'Flipkart',
        platformIcon: 'arcticons:flipkart',
        value: 'flipkart',
    },
    {
        platformName: 'Citymall',
        platformIcon: 'tabler:hexagon-letter-m',
        value: 'citymall',
    },
    // {
    //     platformName: 'Flipkart Rewards',
    //     platformIcon: 'arcticons:flipkart',
    //     value: 'flipkart-rewards',
    // },
    // {
    //     platformName: 'Flipkart AdsCost',
    //     platformIcon: 'arcticons:flipkart',
    //     value: 'flipkart-adscost',
    // },
    // {
    //     platformName: 'Flipkart FBF',
    //     platformIcon: 'arcticons:flipkart',
    //     value: 'flipkart-fbf',
    // },
    // {
    //     platformName: 'Glowroad',
    //     platformIcon: 'healthicons:g-outline',
    //     value: 'glowroad',
    // },
    // {
    //     platformName: 'Shopdeck',
    //     platformIcon: 'tabler:circle-letter-s',
    //     value: 'shopdeck',
    // },
    {
        platformName: 'Snapdeal',
        platformIcon: 'tabler:hexagon-letter-s',
        value: 'snapdeal',
    },
    // {
    //     platformName: 'JioMart',
    //     platformIcon: 'arcticons:jiomart',
    //     value: 'jiomart',
    // },
    // {
    //     platformName: 'Myntra',
    //     platformIcon: 'arcticons:myntra',
    //     value: 'myntra',
    // },
    // {
    //     platformName: 'Amazon',
    //     platformIcon: 'grommet-icons:amazon',
    //     value: 'amazon',
    // },
    // {
    //     platformName: 'Limeroad',
    //     platformIcon: 'grommet-icons:amazon',
    //     value: 'limeroad',
    // },
];
const AutoPaymentExcel = (props) => {
    const { currentUploadTab, onChangeUploadTab } = useTabs('Pending');
    const [platform, setPlatform] = useState('meesho');


    const uploadTabs = [
        {
            value: 'Pending',
        },
        {
            value: 'OnProcess',
        },
        {
            value: 'Success',
        },
        {
            value: 'Failed',
        }
    ];

    return (
        <>
            <Divider sx={{ borderStyle: 'dashed' }} />
            <ToggleButtonGroup
                exclusive
                value={platform}
                size="medium"
                onChange={(event) => {
                    setPlatform(event.target.value);
                }}
                sx={{ mt: 1.5, mb: 1.5, ml: 0, flexWrap: 'wrap' }}
            >
                {PlatformOptions.map((data, i) => (
                    <ToggleButton value={data.value} aria-label={data.value}
                        key={data.value} color={'primary'}>
                        {data.platformName}
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>
            <Divider sx={{ borderStyle: 'dashed' }} />

            <Tabs
                allowScrollButtonsMobile
                variant="scrollable"
                scrollButtons="auto"
                value={currentUploadTab}
                onChange={onChangeUploadTab}
            >
                {uploadTabs.map((tab) => (
                    <Tab disableRipple key={tab.value} label={capitalCase(tab.value)} icon={tab.icon} value={tab.value} />
                ))}
            </Tabs>

            <Box sx={{ mb: 2 }} />

            {currentUploadTab === 'Pending' && <PendingTicket platform={platform} />}
            {currentUploadTab === 'OnProcess' && <OnProcessTicket platform={platform} />}
            {currentUploadTab === 'Success' && <SuccessTicket platform={platform} />}
            {currentUploadTab === 'Failed' && <FailedTicket platform={platform} />}
        </>
    );

};
export default AutoPaymentExcel;
